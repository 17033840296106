import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldService } from 'src/app/services/field.service';
import { LegacyService } from 'src/app/services/legacy.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-field-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [FieldService]
})
export class RatingComponent extends FieldType implements OnInit, AfterViewInit {
  prevValue = 0;
  elements = [];
  convertedOnIcon: string;
  convertedOffIcon: string;
  convertedOnColor: string;
  convertedOffColor: string;

  constructor(
    private legacy: LegacyService,
    private fieldService: FieldService,
    private validation: ValidationService,
    private elm: ElementRef
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }

  get onColor(): string {
    return this.to.iconOnColor || 'primary';
  }

  get offColor(): string {
    return this.to.iconOffColor || 'primary';
  }

  get count(): number {
    return this.to.count || 5;
  }

  get iconSize(): string {
    if (this.count > 6)
      return 'default';
    else
      return 'large';
  }

  get colSize(): number {
    if (this.count > 6)
      return 1;
    else
      return 2;
  }

  get value(): number {
    return this.model[this.key as string | number];
  }

  public static getHtml(config: FormlyFieldConfig, value: any = 0) {
    const label = config.templateOptions.label ?? '';
    const count = config.templateOptions.count || 5;
    const filled = value;
    const unFilled = count - filled;
    const onIcon = config.templateOptions.iconOn || 'star';
    const offIcon = config.templateOptions.iconOff || 'star-outline';
    let html = `<ion-card><ion-item lines="none"><div><ion-label position="stacked">${label}</ion-label></div><div>`;
    for (let i = 0; i < filled; i++) {
      html += `<ion-icon size="large" name="${onIcon}" style="display: inline-grid; padding: 8px;"></ion-icon>`;
    }
    for (let i = 0; i < unFilled; i++) {
      html += `<ion-icon size="large" name="${offIcon}" style="display: inline-grid; padding: 8px;"></ion-icon>`;
    }
    html += `</div></ion-item></ion-card>`;
    return html;
  }

  ngOnInit() {
    for (let i = 0; i < (this.count); i++) {
      this.elements.push({index: i});
    }
    this.convertedOnIcon = this.legacy.fixIcon(this.to.iconOn, false, 'star');
    this.convertedOffIcon = this.legacy.fixIcon(this.to.iconOff, false, 'star-outline');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setColors();
    });
  }

  /**
   * Set colors of icons. Would do the following
   *
   * - If it's an old color class it will convert it and use it directly
   * - If it's an new color class it will use it directly
   * - If it's an CSS color it will set the colors on the icons using querySelector and style
   */
  setColors() {
    if (this.onColor.includes('-')) {
      this.convertedOnColor = this.legacy.fixColorClass(this.onColor);
    }
    else if (this.validation.validColor(this.onColor)) {
      this.convertedOnColor = this.onColor;
    }
    else {
      const onIcons = this.elm.nativeElement.querySelectorAll('.onIcon > ion-icon');
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < onIcons.length; i++) {
        (onIcons[i] as HTMLElement).style.color = this.onColor;
      }
    }

    if (this.offColor.includes('-')) {
      this.convertedOffColor = this.legacy.fixColorClass(this.offColor);
    }
    else if (this.validation.validColor(this.offColor)) {
      this.convertedOffColor = this.offColor;
    }
    else {
      const offIcons = this.elm.nativeElement.querySelectorAll('.offIcon > ion-icon');
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < offIcons.length; i++) {
        (offIcons[i] as HTMLElement).style.color = this.offColor;
      }
    }
  }

  ratingsClicked(rating: number) {
    if (this.to.disabled) {
      return;
    }
    this.fieldService.setValue(rating);
    setTimeout(() => {
      this.setColors();
    });
  }

  ratingsUnClicked(rating: number) {
    if (this.to.disabled) {
      return;
    }
    if (this.model[this.key as string | number] === rating) {
      this.fieldService.clearValue(0);
    }
    else {
      this.fieldService.setValue(rating);
    }
    setTimeout(() => {
      this.setColors();
    });
  }
}
