<ion-card *ngIf="text">
  <ion-item lines="none" *ngIf="!useModal">
    <markdown [class.overflowing]="isOverflowing" #markdownEl [data]="text" (swipe)="handleSwipe()" (ready)="checkOverflowing()" (scroll)="hideScrollArrow()"></markdown>
    <ion-icon class="overflow-indicator aniHoverX" name="chevron-forward-sharp" *ngIf="showOverflowArrow"></ion-icon>
  </ion-item>
  <ion-button 
    class="ion-text-wrap ion-padding-start ion-padding-end"
    type="button"
    [color]="color"
    [fill]="fill"
    expand="block"
    (click)="openModal()"
    *ngIf="useModal"
  >
    <ion-label class="ion-padding-top ion-padding-bottom">{{buttonText}}</ion-label>
  </ion-button> 
</ion-card>
