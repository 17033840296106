<ion-list>
  <ion-item lines="none">
    <ion-label *ngIf="!hasError">{{label}}</ion-label>
    <ion-label *ngIf="hasError && !pdf">
        <ion-text color="danger"><b>{{'ImageNotFound' | translate }}</b></ion-text>
    </ion-label>
    <ion-label *ngIf="hasError && pdf">
      <ion-text color="danger"><b>{{'PdfNotFound' | translate }}</b></ion-text>
    </ion-label>
    <ion-spinner *ngIf="loading"></ion-spinner>
    <ion-buttons slot="end" *ngIf="!loading && !hasError && image">
      <ion-button color="dark" (click)="zoomOut()">
          <ion-icon slot="icon-only" name="remove"></ion-icon>
      </ion-button>
      <ion-button color="dark" (click)="reset()">
        <ion-icon  slot="icon-only" name="square-outline"></ion-icon>
      </ion-button>
      <ion-button color="dark" (click)="zoomIn()">
          <ion-icon slot="icon-only" name="add"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>
  <ion-item lines="none" *ngIf="!hasError">
    <canvas #canvas></canvas>
  </ion-item>
  <ion-item lines="none">
    <ion-grid>
      <ion-row *ngIf="pages.length > 0">
        <ion-col>
          <ion-item lines="none" *ngIf="!hasError">
            <ion-label>{{'ChoosePage' | translate}}</ion-label>
            <ion-select [(ngModel)]="pageNumber" (ionChange)="changePage()">
              <ion-select-option *ngFor="let page of pages" value="{{page.number}}">{{page.name}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!hasError && paintFields.length > 0 && image">
        <ion-col>
          <ion-button expand="block" (click)="setPaint()">{{'SetToPaint' | translate}}</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</ion-list>