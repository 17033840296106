import { NgModule } from '@angular/core';
import { CustomFormlyModule, fieldTypes, wrappers } from './formly/custom-formly.module';
import { PageDirectivesModule } from './directives/page-directives/page-directives.module';
import { FormlyDirectivesModule } from './directives/formly-directives/formly-directives.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { SharedDirectivesModule } from './directives/shared-directives/shared-directives.module';
import { requiredValidationMessage } from '../helpers/helperFunctions';



@NgModule({
  declarations: [],
  imports: [
    CustomFormlyModule,
    PageDirectivesModule,
    FormlyDirectivesModule,
    SharedDirectivesModule,
    FormlyIonicModule,
    FormlyModule.forRoot({
      types: fieldTypes,
      wrappers: wrappers,
      validationMessages: [
        { name: 'required', message: requiredValidationMessage }
      ],
      extras: {
        lazyRender: true,
        resetFieldOnHide: false,
        checkExpressionOn: 'changeDetectionCheck'
      }
    }),
  ],
  exports: [
    CustomFormlyModule,
    PageDirectivesModule,
    SharedDirectivesModule
  ]
})
export class CustomComponentsModule { }
