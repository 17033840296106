<ion-item lines="none" class="ion-activatable ripple-parent">
  <ion-label [position]="labelPosition">{{label}}</ion-label>
  <ionic-selectable
    #modalSelectComponent
    [hasConfirmButton]="false"
    [clearButtonText]="clearButtonText"
    [confirmButtonText]="confirmButtonText"
    [formControl]="$any(formControl)"
    modalCssClass="ionic-selectable-css-configuration"
    [items]="allOptions"
    [disabledItems]="disabledOptions"
    [itemValueField]="valueProp"
    [itemTextField]="shownLabelProp"
    [isMultiple]="useMultiple"
    [canClear]="true"
    closeButtonSlot="end"
    [placeholder]="placeholder"
    [searchPlaceholder]="searchPlaceholder"
    [searchFailText]="searchLabelNotFound"
    [searchDebounce]="searchDebounce"
    [shouldStoreItemValue]="!storeAsJson"
    [canSearch]="useSearchbar"
    [shouldFocusSearchbar]="focusSearchbar"
    [groupValueField]="groupProp"
    [groupTextField]="groupProp"
    [hasVirtualScroll]="useVirtualScroll"
    (onClose)="onDone($event)"
    (onSearch)="searchItems($event)"
    (onChange)="itemChanged($event)"
    (onOpen)="opened($event)"
    (onSelect)="itemSelected($event)"
    *ngIf="showModal"
    >
    <ng-template ionicSelectableCloseButtonTemplate>
      <ion-icon name="close-circle-outline" ></ion-icon>
    </ng-template>

    <ng-template ionicSelectableValueTemplate let-values="value" *ngIf="showCountLabel">
      <div *ngIf="optionsLabel">
        {{'ValuesSelectedWithLabel' | translate:valuesParams}}
      </div>
      <div *ngIf="!optionsLabel">
        {{'ValuesSelected' | translate:valuesParams}}
      </div>
    </ng-template>

  </ionic-selectable>
  <ion-label *ngIf="showNoItems">{{'NoListOptions' | translate}}</ion-label>
  <ion-label color="danger" *ngIf="hasError"> {{errorText}}</ion-label>
  <ion-spinner *ngIf="loading"></ion-spinner>
  <ion-ripple-effect></ion-ripple-effect>
</ion-item>