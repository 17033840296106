import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { MarkdownComponent } from 'ngx-markdown';
import { TextModalComponent } from 'src/app/custom-components/directives/shared-directives/text-modal/text-modal.component';
import { IonColor } from 'src/app/models/models';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { EvaluationService } from 'src/app/services/evaluation.service';
import { StateService } from 'src/app/services/state.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WatchgroupService } from 'src/app/services/watchgroup.service';

@Component({
  selector: 'app-field-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  providers: [WatchgroupService]
})
export class TextComponent extends FieldType implements OnInit, AfterViewInit {
  static cssSelector = 'app-field-text';
  @ViewChild('text') text: ElementRef;
  @ViewChild('markdownEl') markdownEl: MarkdownComponent;
  isMarkdown = false;
  markdown = '';
  isOverflowing = false;
  showOverflowArrow = false;
  isValid = true;

  noticeTypes = ['info','warning','danger','success','']; //empty string to allow 'blank'/avoid falsy.
  noticeType = '';

  constructor(
    private domOp: DomOperationsService,
    private validation: ValidationService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private state: StateService,
    private watch: WatchgroupService,
    private evalService: EvaluationService
  ) {
    super();
  }

  get isNotice(): boolean {
    return (this.to.noticeType);
  }
  get noticeClass(): string {
    return (this.isNotice) ? this.noticeType: '';
  }
  get label(): string {
    if (typeof this.to.label !== 'string')
      return '';
    else
      return this.to.label.replace(/\n/g, '<br>');
  }

  get size(): string {
    switch (this.to.size) {
      case 1:
      case '1':
        return '1';
      case 3:
      case '3':
        return '3';
      default:
        return '2';
    }
  }

  get align(): string {
    switch (this.to.align) {
      case 'right':
      case 'center':
        return this.to.align;
      default:
        return 'left';
    }
  }

  get useModal(): boolean {
    return this.to.openInModal ?? false;
  }

  get showPrint(): boolean {
    return this.to.showPrint ?? false;
  }

  get buttonText(): string {
    return this.label || this.translate.instant('Open');
  }

  get color(): IonColor {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Primary;
  }

  get fill(): 'clear' | 'solid' | 'outline' {
    switch (this.to.fill) {
      case 'clear':
      case 'solid':
        return this.to.fill;
      default:
        return 'outline';
    }
  }

  get validationExpression(): string {
    if (typeof this.to.validationExpr === 'string') {
      return this.to.validationExpr;
    }
    else {
      return '';
    }
  }

  get usesValidation(): boolean {
    if (this.isMarkdown) {
      return false;
    }
    else if (this.validationExpression) {
      return true;
    }
    else {
      return false;
    }
  }

  get validText(): string {
    if (typeof this.to.validText === 'string') {
      return this.to.validText.replace(/\n/g, '<br>');
    }
    else {
      return '';
    }
  }

  get invalidText(): string {
    if (typeof this.to.invalidText === 'string') {
      return this.to.invalidText.replace(/\n/g, '<br>');
    }
    else {
      return '';
    }
  }

  get validColor(): IonColor {
    return this.validation.validColor(this.to.validColor) ? this.to.validColor : IonColor.Dark;
  }

  get invalidColor(): IonColor {
    return this.validation.validColor(this.to.invalidColor) ? this.to.invalidColor : IonColor.Dark;
  }

  get textColor(): IonColor {
    return this.isValid ? this.validColor : this.invalidColor;
  }

  get saveLabel(): boolean {
    if (this.usesValidation) {
      return this.to.saveLabel ?? true;
    }
    else {
      return this.to.saveLabel ?? false;
    }
  }

  get textValue(): string {
    if (this.usesValidation) {
      return this.isValid ? this.validText : this.invalidText;
    }
    else {
      return this.label;
    }
  }

  get shownText(): string {
    return this.label ? `<b>${this.label}</b> ${this.textValue}` : this.textValue;
  }

  public static getHtml(config: FormlyFieldConfig, _value: any) {
    if (config.templateOptions.markdown) {
      return config.templateOptions.markdown;
    }
    else if (config.templateOptions.label) {
      const align = config.templateOptions.align ?? 'left';
      // eslint-disable-next-line eqeqeq
      const size = config.templateOptions.size == '3' ? '12px' : config.templateOptions.size == '1' ? '20px' : '16px';
      const label = config.templateOptions.label.replace(/\n/g, '<br>');
      return `<span style="text-align: ${align}; font-size: ${size}; margin: 10px 0; width: 100%;">${label}</span>`;
    }
    else {
      return '';
    }
  }

  ngOnInit() {
    if (this.to.markdown) {
      this.isMarkdown = true;
      this.markdown = this.to.markdown;
    }
    this.checkValue();
    this.checkNoticeType();
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (this.usesValidation && watchGroup.fieldKeys.length > 0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => this.checkValue());
    }
  }

  ngAfterViewInit() {
    if (!this.to.markdown && this.to.color && this.text?.nativeElement) {
      (this.text.nativeElement as HTMLSpanElement).style.color = this.to.color;
    }
  }

  checkValue() {
    if (this.usesValidation) {
      this.isValid = this.evalService.evalBooleanExpression(this.validationExpression, this.model);
    }
    if (this.saveLabel) {
      this.formControl.setValue(this.textValue);
    }
  }

  checkOverflowing() {
    setTimeout(() => {
      this.isOverflowing = this.domOp.isOverflowing(this.markdownEl?.element?.nativeElement);
      this.showOverflowArrow = this.isOverflowing;
    });
  }

  checkNoticeType() {
    if(this.to.noticeType) {
      this.noticeType = (this.noticeTypes.includes(this.to.noticeType))?this.to.noticeType+'Notice':'';
    }
  }

  hideScrollArrow() {
    setTimeout(() => {
      this.showOverflowArrow = false;
    }, 1000);
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: TextModalComponent,
      componentProps: {
        title: this.label,
        text: this.markdown,
        showPrint: this.showPrint
      }
    });
    modal.present();
  }

  handleSwipe() {}
}
