import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { Shape } from 'src/app/models/models';

@Component({
  selector: 'app-shapes-popover',
  templateUrl: './shapes-popover.component.html',
  styleUrls: ['./shapes-popover.component.scss'],
})
export class ShapesPopoverComponent implements OnInit, AfterViewInit {
  @Input() shapes: Shape[];
  @Input() size: number;
  @Input() pickedShape: Shape;
  @Input() image: {url: string, width: number, height: number};

  constructor(
    private popoverCtrl: PopoverController,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.size *= 100;
  }


  ngAfterViewInit() {
    const cols = document.querySelectorAll<HTMLIonColElement>('.shapeCol');
    cols.forEach((element, index) => {
      if (this.pickedShape && this.pickedShape.id === this.shapes[index].id) {
        element.style.border = '2px solid var(--ion-text-color, #000)';
        element.classList.add('selected');
      }

      const shape = this.shapes[index];
      const iconSize = 75;
      const scale = this.getScale(+shape.size.width, iconSize);
      const div = document.createElement('div');
      div.style.width = +shape.size.width * scale  + 'px';
      div.style.height = +shape.size.height * scale  + 'px';
      div.style.background = `url('${this.image.url}') -${+shape.pos.x * scale}px -${+shape.pos.y * scale}px`;
      div.style.backgroundSize = `${this.image.width * scale}px ${this.image.height * scale}px`;
      const that = this;
      div.onclick = () => {
        const el = document.querySelector<HTMLIonColElement>('.selected');
        if (el) {
          el.classList.remove('selected');
          el.style.border = '';
        }
        element.style.border = '2px solid var(--ion-text-color, #000)';
        element.classList.add('selected');
        that.pickedShape = that.shapes[index];
      };
      element.append(div);
    });
  }

  getScale(ssize: number, isize: number) {
    let v = ssize / isize;
    v = 100 / v;
    v = v / 100;
    return v;
  }

  async closePopover(save: boolean) {
    try {
      if (save) {
        await this.popoverCtrl.dismiss({
          shape: this.pickedShape,
          size: this.size / 100
        });
      }
      else {
        await this.popoverCtrl.dismiss();
      }
    }
    catch (err) {
      this.logger.warn('Failed dismissing shapes popover', err);
    }
  }
}
