<ion-header>
    <ion-toolbar color="header">
      <ion-title>{{'Setup' | translate}}</ion-title>
      <ion-button fill="clear" slot="end" (click)="closeModal(false)" *ngIf="!hasChanged">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  
  <app-online-status template="banner" ></app-online-status>
  <div *ngIf="!isOnline" class="banner">
    {{'SetupOffline' | translate}}
  </div>
  

  <ion-content class="in-background">
    <div class="ion-text-center" *ngIf="hasChanged">
      <h2>{{'SetupChanged' | translate}}</h2>
      <span>{{'SetupChangedMsg' | translate}}</span>
    </div>
    <ion-card class="formCard">
    <ion-grid>
      <ion-row id="no-setup-row" *ngIf="!loading && (!fields || fields.length === 0)">
        <ion-col size="12">
          {{'NoSetup' | translate}}
        </ion-col>
      </ion-row>
      <ion-row id="form-row" *ngIf="fields && fields.length > 0">
        <ion-col size="12">
          <form [formGroup]="formGroup" *ngIf="foundForm" [class.disabled]="!isOnline" (click)="disabled($event)">
              <formly-form [form]="formGroup" [fields]="fields" [model]="model"></formly-form>
          </form>
        </ion-col>
      </ion-row>
      <ion-row id="spinner-row" class="ion-justify-content-center" *ngIf="loading">
        <ion-col size="1">
          <ion-spinner></ion-spinner>
        </ion-col>
      </ion-row>
      <ion-row id="button-row" *ngIf="fields && fields.length > 0">
        <ion-col size="6" offset="3">
          <ion-button color="tertiary" expand="block" (click)="closeModal(true)" disabled="{{!isOnline}}">
            {{(hasChanged ? 'SetupConfirm' : 'Save') | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
  </ion-content>
  