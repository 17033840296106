import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AuthenticationState } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class SigninGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  // Can only enter signin while the user is NOT authenticated
  canActivate(_r: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const authState = this.auth.authenticationState.value;
    if (authState === AuthenticationState.NotSet) {
      this.auth.orgRoute = state.url;
      return this.router.parseUrl('/startup');
    }
    else if (authState === AuthenticationState.NotAuthenticated) {
      return true;
    }
    else if (authState === AuthenticationState.NewUser) {
      return this.router.parseUrl('/intro');
    }
    else {
      return this.router.parseUrl('/home');
    }
  }
}
