<ion-item id="language-item" lines="none">
  <ion-label>{{'Language' | translate}}</ion-label>
  <ion-select interface="popover" [(ngModel)]="userLanguage" (ionChange)="setLanguage()">
    <ion-select-option *ngFor="let lang of languages" [value]="lang.code">{{lang.name}}</ion-select-option>
  </ion-select>
</ion-item>
<ion-item id="theme-item" lines="none">
  <ion-segment slot="start" mode="ios" [(ngModel)]="theme" (ionChange)="changeTheme()">
    <ion-segment-button value="light" title="{{'PrimaryTheme' | translate}}">
      <ion-icon name="sunny-outline"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="dark" title="{{'DarkMode' | translate}}">
      <ion-icon name="moon-outline"></ion-icon>
    </ion-segment-button>
  </ion-segment>
</ion-item>
