import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { InStorageService } from './in-storage.service';
import localeNb from '@angular/common/locales/nb';
import localeSv from '@angular/common/locales/sv';
import { Language, DefinedLanguages, TranslationObject } from '../models/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isFormlyFieldWithTranslation } from '../helpers/helperFunctions';
import { TitleService } from './title.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private definedLanguages: Language[];

  constructor(
    private translate: TranslateService,
    private storage: InStorageService,
    private titleService: TitleService
  ) {
    this.definedLanguages = DefinedLanguages;
  }

  /**
   * Initalize language in the app
   */
  async initLanguage() {
    this.translate.setDefaultLang('en');

    let lang = await this.storage.getLanguage();
    const isStored = !!lang;
    if (!isStored) {
      lang = navigator.language;
      switch (lang.toLowerCase()) {
        case 'no':
        case 'nb':
        case 'nb-no':
        case 'nn':
        case 'nn-no':
          lang = 'no';
          break;
        case 'sv':
        case 'sv-fi':
        case 'sv-sv':
          lang = 'sv';
          break;
        default:
          lang = 'en';
      }
    }

    await this.setLanguage(lang, !isStored);

    // Register extra locales for date
    registerLocaleData(localeNb, 'no');
    registerLocaleData(localeSv, 'sv');

    return lang;
  }

  /**
   * Set language in the app
   * @param lang The language to set
   * @param store If the language should be stored
   */
  async setLanguage(lang: string, store: boolean) {
    if (!this.definedLanguages.find(l => l.code === lang)) {
      return;
    }
    if (!this.translate.langs.includes(lang)) {
      this.translate.reloadLang(lang);
    }
    this.translate.use(lang).subscribe(() => {
      environment.requiredMessage = this.translate.instant('RequiredFieldMsg');
    });
    if (store) {
      await this.storage.setLanguage(lang);
    }
    this.titleService.updateTitle();
  }

  /**
   * Get the current language
   */
  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  /**
   * Get the available languages
   */
  getLanguages(): Language[] {
    return this.definedLanguages;
  }

  /**
   * Translate fields with translate values in the definition
   * @param fields The fields to translate
   */
  translateFields(fields: FormlyFieldConfig[]): FormlyFieldConfig[] {
    for (const field of fields) {
      if (isFormlyFieldWithTranslation(field)) {
        for (const [key, translation] of Object.entries(field.templateOptions.fieldTranslations)) {
          field.templateOptions[key] = this.translateValue(translation, field.templateOptions[key]);
        }
      }
    }
    return fields;
  }

  /**
   * Translate value using translate object
   * @param translation The translation object
   * @param defaultValue The default value of the value being translated
   */
  private translateValue(translation: TranslationObject, defaultValue: any): any {
    const lang = this.getCurrentLanguage();
    const newValue = translation[lang];
    return newValue ?? defaultValue;
  }
}
