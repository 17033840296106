import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { TriggerType, Trigger, FormType } from 'src/app/models/models';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { WatchOptions } from 'src/app/models/interfaces/watchOptions';

@Component({
  selector: 'app-field-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.scss'],
  providers: [WatchgroupService]
})
export class WatchComponent extends FieldType implements OnInit, Trigger {
  watchGroup: WatchOptions;
  watchedFields: {[key: string]: boolean} = {};

  constructor(
    private watch: WatchgroupService,
    private ref: ComponentRefService,
    private logger: NGXLogger
  ) {
    super();
  }

  get toggleFields(): string[] {
    if (this.to.toggle && Array.isArray(this.to.toggle.fields)) {
      return this.to.toggle.fields;
    }
    else {
      return this.watchGroup.fieldKeys;
    }
  }

  get className(): string {
    return (this.to.toggle && this.to.toggle.className) ? this.to.toggle.className : 'hide';
  }

  get toggleType(): TriggerType {
    if (this.to.toggle && this.to.toggle.type === 'add') {
      return TriggerType.AddClass;
    }
    else if (this.to.toggle && this.to.toggle.type === 'toggle') {
      return TriggerType.ToggleClass;
    }
    else {
      return TriggerType.RemoveClass;
    }
  }

  get delay(): number {
    return (this.to.toggle && this.to.toggle.delay) ? this.to.toggle.delay : 0;
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);

    this.watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (this.watchGroup.fieldKeys.length > 0) {
      for (const key of this.watchGroup.fieldKeys) {
        this.watchedFields[key] = false;
      }
      this.watch.watchGroup(this.form, this.model, this.watchGroup).subscribe(valChange => {
        if (valChange && valChange.key) {
          this.watchedFields[valChange.key] = true;
          this.checkAndUpdate();
        }
      });
    }
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Update) {
      this.watchedFields[data] = true;
      this.checkAndUpdate();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  checkAndUpdate() {
    let allTriggered = true;
    for (const value of Object.values(this.watchedFields)) {
      if (!value) {
        allTriggered = false;
        break;
      }
    }
    if (allTriggered) {
      this.formControl.setValue(true);
      for (const key of this.toggleFields) {
        this.ref.callReference(key, this.formId, this.formType, this.toggleType, {class: this.className, delay: this.delay});
      }
    }
  }
}
