<ion-header>
  <ion-toolbar color="header">
    <ion-title>
      {{'Feedback' | translate}}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="close()">
      <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="in-background">

  <ion-card>
    <ion-card-content class="content">
      <ion-list>
        <ion-item id="type-item" lines="none">
          <ion-label class="type-label" position="stacked">{{'FeedbackType' | translate}}</ion-label>
          <ion-select [(ngModel)]="feedbackType" interface="popover">
            <ion-select-option *ngFor="let option of types" [value]="option.type">{{option.label | translate}}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item id="form-item" lines="none" *ngIf="showFormSelect">
          <ion-label position="stacked" class="type-label">{{'Form' | translate}}</ion-label>
          <ion-select [(ngModel)]="chosenForm" interface="popover">
            <ion-select-option *ngFor="let form of forms" [value]="form.id">{{form.name}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
      <ion-item id="message-item" lines="none" class="bg-trans">
        <ion-label class="type-label" position="stacked">{{'Message' | translate}}</ion-label>
        <ion-textarea class="messagebox" rows="4" [(ngModel)]="message"></ion-textarea>
      </ion-item>
      <ion-item id="log-item" lines="none" class="bg-trans" *ngIf="showIncludeLog">
        <ion-label class="type-label" >
          <small class="">{{'sendLog' | translate}}</small>
        </ion-label>
        <ion-checkbox class="log-checkbox" [(ngModel)]="includeLog"></ion-checkbox>
      </ion-item>

        
      <ion-button class="submitBtn" expand="block" color="secondary" (click)="sendLog()" [disabled]="!message || loading">
        <ion-label *ngIf="!loading">{{'SendFeedback' | translate}}</ion-label>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>

    </ion-card-content>
  </ion-card>
</ion-content>