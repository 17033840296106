import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ThemeService } from 'src/app/services/theme.service';
import { Theme, InThemes, Language } from 'src/app/models/models';
import { StatisticsService } from 'src/app/services/statistics.service';
import { LanguageService } from 'src/app/services/language.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss'],
})
export class AppSettingsComponent implements OnInit {
  userLanguage: string;
  languages: Language[];
  theme: string;
  themes: Theme[];
  appVersion: string;

  constructor(
    private popoverCtrl: PopoverController,
    private themeService: ThemeService,
    private statistics: StatisticsService,
    private language: LanguageService,
    private logger: NGXLogger
  ) {
    this.appVersion = environment.appVersion;
  }

  ngOnInit() {
    this.userLanguage = this.language.getCurrentLanguage();
    this.languages = this.language.getLanguages();
    this.theme = this.themeService.theme.value;
    this.themes = InThemes;
  }

  setLanguage(event: any) {
    this.userLanguage = event.detail.value;
    this.language.setLanguage(this.userLanguage, true);
    this.statistics.changeLanguage(this.userLanguage);
  }

  changeTheme(event: any) {
    const theme: string = event.detail.value;
    this.themeService.changeTheme(theme);
    this.statistics.changeTheme(theme);
  }

  closeModal() {
    this.popoverCtrl.dismiss().then(
      () => this.logger.debug('Successfully dismissed app settings'),
      (err) => this.logger.warn('Failed dismissing app settings', err)
    );
  }
}
