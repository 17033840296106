<ion-button 
  class="ion-text-wrap"
  [ngClass]="{
    'hasValue': wasPressed,
    'breakWord': shouldBreakWord,
    'ion-padding-start': paddingLeft,
    'ion-padding-end': paddingRight
  }"
  [strong]="wasPressed"
  type="button"
  [color]="color"
  [fill]="fill"
  [expand]="expand"
  [size]="size"
  (click)="click()"
  [disabled]="(to.disabled && !wasPressed) || loading"
>
  <ion-icon [name]="icon" [slot]="iconPlacement" *ngIf="icon && !loading"></ion-icon>
  <ion-label class="ion-padding-top ion-padding-bottom" *ngIf="!loading">{{label}}</ion-label>
  <ion-label *ngIf="clickedAt && showTimeInfo && !loading" class="timeInfo"><ion-icon name="time-outline"></ion-icon>{{clickedAt}}</ion-label>
  &nbsp;<ion-spinner *ngIf="loading"></ion-spinner><ion-label class="ion-padding-top ion-padding-bottom" *ngIf="loading">&nbsp;</ion-label>
</ion-button> 
