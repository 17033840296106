import { AfterViewInit, Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { NGXLogger } from 'ngx-logger';
import { EvaluationService } from 'src/app/services/evaluation.service';

@Component({
  selector: 'app-autocapitalize',
  templateUrl: './autocapitalize.component.html',
  styleUrls: ['./autocapitalize.component.scss'],
})
export class AutocapitalizeComponent extends FieldWrapper implements AfterViewInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;
  constructor(
    private elm: ElementRef,
    private evaluation: EvaluationService,
    private logger: NGXLogger
  ) {
    super();
  }

  get autoCapitalize(): 'sentences' | 'words' | 'characters' | 'none' {
    switch(this.to.autocapitalize) {
      case 'off':
      case 'none':
        return 'none';
      case 'on':
      case 'sentences':
        return 'sentences';
      case 'words':
        return 'words';
      case 'all':
      case 'characters':
        return 'characters';
      default:
        return 'sentences';
    }
  }

  get isHidden(): boolean {
    if (this.field.hideExpression) {
      return this.evaluation.evalHideExpression(this.field.hideExpression, this.field.hide, this.model, this.formState);
    }
    else {
      return false;
    }
  }

  ngAfterViewInit() {
    if (this.autoCapitalize !== 'none') {
      this.setAutoCap(this.autoCapitalize);
    }
  }

  setAutoCap(autoCapType: 'sentences' | 'words' | 'characters') {
    setTimeout(() => {
      if (this.isHidden) {
        const sub = this.field.parent.formControl.valueChanges.subscribe(() => {
          if (!this.isHidden) {
            sub.unsubscribe();
            const elm = this.getElement();
            if (elm) {
              elm.autocapitalize = autoCapType;
            }
            else {
              this.logger.debug('Did not found input or textarea element');
            }
          }
        });
      }
      else {
        const elm = this.getElement();
        if (elm) {
          elm.autocapitalize = autoCapType;
        }
        else {
          this.logger.debug('Did not found input or textarea element');
        }
      }
    }, 500);
  }

  getElement(): HTMLIonTextareaElement | HTMLIonInputElement {
    const elm = this.elm.nativeElement.querySelector('ion-textarea');
    if (elm) {
      return elm;
    }
    else {
      return this.elm.nativeElement.querySelector('ion-input');
    }
  }
}
