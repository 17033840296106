import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

/**
 * Service that handles loading spinner across components/services
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loaders: HTMLIonLoadingElement[] = [];
  private nextId = 1;
  private maxRetries: number;
  private maxDuration: number;

  constructor(
    private loadingCtrl: LoadingController,
    private translate: TranslateService
  ) {
    this.maxDuration = environment.loadingTimeout;
    this.maxRetries = environment.maxLoadingRetries;
  }

  /**
   * Starts up an loader message and returns id of the loader
   * @param translateKey (Optional) The translate key for what should be the loader text. Will show without text if not given
   */
  startLoader(translateKey?: string, useDuration = true): number {
    const id = this.nextId++;

    if (translateKey) {
      const loadingMsg: string = this.translate.instant(translateKey);
      this.loadingCtrl.create({
        message: loadingMsg,
        duration: useDuration ? this.maxDuration : 0
      }).then(loading => {
        this.loaders[id] = loading;
        loading.present();
      });
    }
    else {
      this.loadingCtrl.create({
        duration: useDuration ? this.maxDuration : 0
      }).then(loading => {
        this.loaders[id] = loading;
        loading.present();
      });
    }

    return id;
  }

  /**
   * Dismisses an loader
   * @param id The id of the loader
   */
  dismissLoader(id: number) {
    this.tryDismiss(id, 0);
  }

  /**
   * Try to dismiss the loader. If it failes it tries again up til maxRetries
   * @param id Id of the loader
   * @param nr Current try attemt
   */
  private tryDismiss(id: number, nr: number) {
    if (this.loaders[id]) {
      this.loaders[id].dismiss();
      delete this.loaders[id];
    }
    else if (nr < this.maxRetries) {
      setTimeout(() => {
        this.tryDismiss(id, nr + 1);
      }, 500);
    }
  }
}
