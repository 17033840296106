<ion-list>
  <ion-item lines="none">
    <ion-label class="ion-text-wrap">{{label}}</ion-label>
  </ion-item>
  <ion-item class="input-item" lines="none">
    <ion-button [color]="color" slot="start" fill="clear" (click)="changeMode($event)" [disabled]="error || disableButton || alwaysOn">
      <ion-icon slot="icon-only" name="pulse"></ion-icon>
    </ion-button>
    <ion-input class="inputarea" [placeholder]="to.placeholder" [formControl]="$any(formControl)"></ion-input>
  </ion-item>
</ion-list>