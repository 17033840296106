import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { SubFormComponent } from 'src/app/custom-components/directives/formly-directives/sub-form/sub-form.component';
import { ApiStatus, DataSource, DataStatus, FormType, PageRefs, SwipeDirection, Tabs } from 'src/app/models/models';
import { ApiService } from 'src/app/services/api.service';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { FieldService } from 'src/app/services/field.service';
import { FormService } from 'src/app/services/form.service';
import { LanguageService } from 'src/app/services/language.service';
import { PopupService } from 'src/app/services/popup.service';
import { TimeService } from 'src/app/services/time.service';
import { UtilityService } from 'src/app/services/utility.service';

export interface RegItem {
  id?: string;
  label: string;
  labelHighlight?: string;
  value: number;
  tabs: Tabs;
  disabled?: boolean;
}
@Component({
  selector: 'app-d4-time',
  templateUrl: './d4-time.component.html',
  styleUrls: ['./d4-time.component.scss'],
  providers: [FieldService]
})

export class D4TimeComponent extends FieldType implements OnInit {

  isDisabled = false;
  isLoading = { graph: false, regs: false, tabs: false, dayReport: false };
  localeLang = 'nb'; // locked to nb format.
  showTabs = true;
  sumTotal = 0;
  sumPlanned = 0;
  weeklyReportList = [{ Plan: 0, Arb: 0 }];
  showWeeklyReport = false;
  showWeekend = false; //show sat,sun.
  newWeekSwipe = false;

  highlightPlanDiff = true;
  highlightTab = [];// = ['LArt'];
  mode: 'timefordeling' | 'timeforing' = 'timefordeling';

  tabsLabel = { Tab2: '', Tab3: '', Tab4: '', Tab5: '', Tab6: '', Tab7: '', Tab8: '', Tab9: '' };
  tabsEmpty: Tabs = {
    Tab2: { Nr: '', Navn: '' },
    Tab3: { Nr: '', Navn: '' },
    Tab4: { Nr: '', Navn: '' },
    Tab5: { Nr: '', Navn: '' },
    Tab6: { Nr: '', Navn: '' },
    Tab7: { Nr: '', Navn: '' },
    Tab8: { Nr: '', Navn: '' },
    Tab9: { Nr: '', Navn: '' },
    LArt: { Nr: '', Navn: '' }
  };

  defaultLabels =[
    'Ma',
    'Ti',
    'On',
    'To',
    'Fr',
    'Lø',
    'Sø'
  ];

  reglist: RegItem[] = [];

  format = { time: 'HH:mm', day: 'dddd', date: 'DD.MM.YY', dateformat: 'YYYY-MM-DD', datetime: 'YYYY-MM-DDTHH:mm:ss', weekDayNr: 'E', week: 'W', weekYear: 'WWYYYY' };
  currDateTime = moment().locale(this.localeLang);
  currDay = this.currDateTime.format(this.format.day);
  currDate = this.currDateTime.format(this.format.date);
  currDateVal = this.currDateTime.format(this.format.dateformat);
  currWeek = '0'; //this.currDateTime.format(this.format.week); //Needs to be diff then first week trigger.
  weekdayNr = this.currDateTime.format(this.format.weekDayNr);

  currPeriode = { fromDate: '', toDate: '' };

  constructor(
    private timeService: TimeService,
    private modalCtrl: ModalController,
    private util: UtilityService,
    private translate: TranslateService,
    private api: ApiService,
    private cachedApi: CachedApiService,
    private formService: FormService,
    private popup: PopupService,
    private logger: NGXLogger,
    private langService: LanguageService,
    private fieldService: FieldService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get hoursDoneText() {
    return (this.mode === 'timefordeling') ? 'HoursSplit': 'HoursFilled';
  }
  get getDate() {
    return this.currDay + '<br/>' + this.currDate;
  }

  get sum(): number {
    return this.sumTotal;
  }

  get sumUnused(): number {
    return this.sumPlanned - this.sumTotal;
  }

  get noRegs(): boolean {
    return this.reglist.length === 0;
  }

  get formId(): number {
    return this.to?.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  get pageRef(): PageRefs {
    return this.to?.currentForm?.ref;
  }
  get weekday(): number {
    return parseInt(this.weekdayNr) - 1; // array starts at 0
  }


  ngOnInit() {
    if (this.to?.mode === 'timeforing' || this.to?.mode === 'timefordeling') {
      this.mode = this.to.mode;
    }
    this.logger.debug(this.localeLang);
    const lang = (this.langService.getCurrentLanguage() === 'no') ? 'nb': this.langService.getCurrentLanguage();
    moment.locale(lang);
    this.highlightTab = this.to.highlightTab || (this.mode === 'timeforing' ? ['LArt'] : null);
    this.highlightPlanDiff = this.to.weeklyReport?.colorPlanDiff || true;
    this.showWeeklyReport = this.to.weeklyReport?.show || false;
    //this.showWeekend = this.to.weeklyReport.showWeekend || false;
    this.logger.debug(this.highlightTab);

    this.getTabLabels();
    this.getDayContent();
    this.getWeeklyReport(new Date(this.currDateVal));
    this.calcSum();

  }
  trackbyId(index: number, item: any) {
    if (!item)
      return null;
    if (item.id)
      return item.id;
    else
      return index;
  }

  getTabLabels() {
    if (!this.to.tabsLabelUrl) {
      return false;
    }
    this.isLoading.tabs = true;
    const paths = this.formService.getStylePaths(this.to.tabsLabelProps);
    const keys = Object.keys(this.tabsLabel);
    this.cachedApi.getStyleData(this.to.tabsLabelUrl, paths, keys, this.formId).subscribe(({ value: tabsLabel, status, source }) => {

      if (Array.isArray(tabsLabel) && status === DataStatus.Updated) {
        let foundFirst = false;
        tabsLabel = tabsLabel.filter(t => t.visible);
        tabsLabel.forEach(t => {
          this.tabsLabel[t.key] = t.label;
          if(this.highlightTab?.length === 0 && !foundFirst) {
            this.highlightTab = [t.key];
            foundFirst = true;
          }

        });

        this.reglist.forEach(item => {
          item.label = this.buildLabel(item.tabs, false);
          item.labelHighlight = this.buildLabel(item.tabs, true);
        });
      }

      if (source === DataSource.API) {
        this.isLoading.tabs = false;
      }
      this.fieldService.triggerChange();
    });
  }

  getDayContent() {
    if (this.mode === 'timefordeling')
      this.getPlannedReport();
    this.getRegItems();
  }

  buildLabel(tabs: Tabs, highlights: boolean): string {
    let l = '';
    const top = [];
    const low = [];

    //        this.logger.debug(tabs);
    Object.keys(tabs).forEach(key => {
      if (tabs[key]?.Nr) {
        const t = (this.tabsLabel[key]) ? '<i>' + this.tabsLabel[key] + ':</i> ' : '';
        if (this.highlightTab.includes(key)) {
          top.unshift(t + tabs[key].Navn);
        }
        else {
          low.push(t + tabs[key].Navn);
        }
      }
      else if (key === 'Kommentar' && tabs[key] !== '') {
        low.push('"' + tabs[key] + '"');
      }
    });
    if (highlights) {
      if (top.length > 0)
        l += '<span>' + top.join('<br/>') + '</span>';
    }
    else {
      if (low.length > 0)
        l += '<span>' + low.join('<br/>') + '</span>';
    }

    return l;

  }

  getRegItems() {
    if (!this.to.reglistUrl) {
      this.logger.debug('empty url');
      return;
    }
    this.isLoading.regs = true;
    const url = this.util.parseText(this.to.reglistUrl, { currDate: this.currDateVal });
    this.cachedApi.getWebJSON(url, '', this.to.headers, this.to.useCredentials).subscribe(({ value, status, error }) => {
      if (status === DataStatus.Updated && value) {
        const d = value.map(res => {
          const lart = {LArt: { Nr: res.LArtNr, Navn: res.LArtNavn }};
          let tabsOptions = (this.mode === 'timefordeling') ? {}: lart;
          const tabs = {
            Tab2: { Nr: res.Tab2Nr, Navn: res.Tab2Navn },
            Tab3: { Nr: res.Tab3Nr, Navn: res.Tab3Navn },
            Tab4: { Nr: res.Tab4Nr, Navn: res.Tab4Navn },
            Tab5: { Nr: res.Tab5Nr, Navn: res.Tab5Navn },
            Tab6: { Nr: res.Tab6Nr, Navn: res.Tab6Navn },
            Tab7: { Nr: res.Tab7Nr, Navn: res.Tab7Navn },
            Tab8: { Nr: res.Tab8Nr, Navn: res.Tab8Navn },
            Tab9: { Nr: res.Tab9Nr, Navn: res.Tab9Navn },
            Kommentar: res.Kommentar
          };
          tabsOptions = {...tabsOptions, ...tabs};
          const t: RegItem = { labelHighlight: this.buildLabel(tabsOptions, true), label: this.buildLabel(tabsOptions, false), tabs: tabsOptions, value: res.Tid, id: res.ID };
          return t;
        });
        this.isLoading.regs = false;
        this.reglist = d;
        this.calcSum();
      }

    });

  }

  getPlannedReport() {
    this.isLoading.dayReport = true;
    const url = this.util.parseText(this.to.dayReportUrl, { fromDate: this.currDateVal, toDate: this.currDateVal });
    this.cachedApi.getWebJSON(url, '', this.formId, this.to.headers, this.to.useCredentials).subscribe(res => {
      this.logger.debug(res);
      if (res.status === DataStatus.Updated) {
        if (res.value !== '' && res.value !== undefined)
          this.sumPlanned = res.value[0]?.Tid || 0;
      }

      if (res.source === DataSource.API) {
        this.isLoading.dayReport = false;
      }

      this.fieldService.triggerChange();
    }, error => {
      this.logger.debug(error);
    });
  }


  prepPeriode(date: Date) {
    this.currPeriode.fromDate = moment(this.timeService.getStartOrEndOfPeriod(date, 'week', 'start')).format('YYYY-MM-DD');
    this.currPeriode.toDate = moment(this.timeService.getStartOrEndOfPeriod(date, 'week', 'end')).format('YYYY-MM-DD');
  }

  getWeeklyReport(currDate?: Date) {

    if (this.showWeeklyReport && this.to.weeklyReport?.url !== '') {
      //this.logger.debug('=====Get chartData has url');
      this.prepPeriode(currDate || this.currDateTime.toDate());
      this.isLoading.graph = true;

      const useCustomLabels =  !!(this.to.weeklyReport?.labels && this.to.weeklyReport.labels.length > 0);
      let showWeekend = false;

      const labels = (useCustomLabels) ? this.to.weeklyReport.labels : this.defaultLabels;

      const types = [
        'Konto',
        'Plan',
        'Frv',
        'Arb'
      ];

      const url = this.util.parseText(this.to.weeklyReport.url, this.currPeriode);
      const obs = this.cachedApi.getWebJSON(url, '', this.formId, this.to.headers, this.to.useCredentials);
      obs.subscribe(({ value, status, source }) => {
        if (source === DataSource.API) {
          setTimeout(() => { // small delay. Takes time to build (extra false later in build incase its faster).
            this.isLoading.graph = false;
          }, 2000);
        }
        if (status === DataStatus.Updated && Array.isArray(value)) {
          const list = { Konto: {}, Arb: {}, Frv: {}, Plan: {} };
          const work = { Konto: [], Arb: [], Frv: [], Plan: [] };
          for (const v of value) {
            list[v.Nr] = v;
          }
          for (const d of this.defaultLabels) {
            for (const t of types) {
              work[t].push(list[t][d]);
            }
          }
          const week = [];
          for (const d of labels) {
            const pos = (!this.highlightPlanDiff) ? 1 : (list['Arb'][d] >= list['Plan'][d]);
            const arb = ( list['Frv'][d] ) ? (list['Arb'][d] + list['Frv'][d]) + '' : list['Arb'][d];
            if(!useCustomLabels && ( d === this.defaultLabels[5] || d === this.defaultLabels[6] ) && arb > 0)
                showWeekend = true;

            const i = this.defaultLabels.indexOf(d);
            const dn = moment(this.currPeriode.fromDate).add(i, 'days');
            const name = dn.format('ddd');
            week.push({ Id: d, Nr: i, PositivValue: pos, Name: name, Plan: list['Plan'][d] + '', Arb: arb });
          }
          if(!useCustomLabels && !showWeekend) {
            week.pop();
            week.pop(); // pop after, since we need to check if week has data first.
          }

          if(this.showWeekend !== showWeekend && this.newWeekSwipe) {
            //Fix auto-datepick on swipe/arrow: mon -> sun or fre.
            const step = (this.showWeekend) ? -2 : 2;
            const d = moment(this.timeService.createDate(this.currDateVal, step, 'days'));
            this.newWeekSwipe = false;
            this.setCurrentDate(d.toISOString(true));

          }
          this.showWeekend = showWeekend;

          this.weeklyReportList = week;

          this.fieldService.triggerChange();
        }
      });

    }

  }

  async submitRegItem(index) {

    const promise = new Promise((resolve, reject) => {

    const v = this.reglist[index].tabs;

    if (this.to.submitUrl && v) {
      const tabs = {
        Tab2: v.Tab2?.Nr,
        Tab3: v.Tab3?.Nr,
        Tab4: v.Tab4?.Nr,
        Tab5: v.Tab5?.Nr,
        Tab6: v.Tab6?.Nr,
        Tab7: v.Tab7?.Nr,
        Tab8: v.Tab8?.Nr,
        Tab9: v.Tab9?.Nr,
        LArt: v.LArt?.Nr,
        Kommentar: v?.Kommentar,
      };
      let url = this.util.parseText(this.to.submitUrl, tabs);
      if (this.reglist[index].id)
        url = this.util.parseText(url, { regId: this.reglist[index].id });
      url = this.util.parseText(url, { Dato: this.currDateVal });
      url = this.util.parseText(url, { Tid: this.reglist[index].value || 0 });
      this.logger.debug(url);

      this.api.getWebJSON(url, this.to.header, this.to.useCredentials).subscribe(data => {
        this.logger.debug(data);

        if (data.status === 'failed' || data.status === 'offline') {
          if (data.status === 'offline')
            this.popup.showAlert('Offline', 'WebPostOffline', true);
          else {
            this.popup.showAlert('Error', data.error.error?.Message || data.error.message, true);
            resolve(false);
          }
        }
        else if (data.value) {
          this.reglist[index].id = data.value[0]?.ID;
          this.getWeeklyReport();
        }

        this.fieldService.triggerChange();
        //return true;
        resolve(true);
      }, error => {
        reject(error);
      });

    }
  });
    return promise;

  }

  addRegItem(time?: number) {
    const val = (time && time > 0)? this.util.roundNumber(time, 2): null;
    this.reglist.push({ label: '', tabs: this.util.createCopyOfObject(this.tabsEmpty), value: val });
    this.showTabsModal(this.reglist.length - 1);

  }

  async removeRegItem(index, showConf = true) {

    const reg = this.reglist[index];

    if (reg?.id && this.to.delUrl) {

      if(showConf) {
        const conf = await this.popup.showConfirm('Delete', 'SelectedElementWillBeDeleted', true);
        if (!conf)
        return;
      }

      reg.disabled = true;
      const url = this.util.parseText(this.to.delUrl, { regId: reg.id });
      // hardcoded header to bypass CORS in D4 API. Body not used.
      let headers = this.to.headers;
      if (!headers) {
        headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
      }
      else if (headers && !headers['Content-Type'])
        headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

      this.api.postWebJSON(url, [], headers, this.to.useCredentials).subscribe(({ status, error }) => {
        reg.disabled = false;
        if (status === ApiStatus.Failed) {
          if (error) {
            const title = this.translate.instant('WebPostFailed');
            const text = `${error.status}: ${error.message}`;
            this.popup.showAlert(title, text, false);
          }
          else {
            this.popup.showMessage('WebPostFailed', true, 'danger');
          }
        }
        else if (status === ApiStatus.Offline) {
          this.popup.showMessage('WebPostOffline', true, 'warning');
        }
        else {
          this.reglist.splice(index, 1);
          this.calcSum();
          this.getWeeklyReport();
        }
      });
    }
    else {
      //Will be regs used as examples/offline.
      this.reglist.splice(index, 1);
      this.calcSum();
    }

  }

  updateValue(index, data) {
    this.reglist[index].value = data.target.value;
    this.calcSum();
    this.submitRegItem(index);
  }

  calcSum() {
    let sum = 0;
    this.reglist.forEach(r => {
      sum += r.value * 1;
    });
    this.sumTotal = sum;
    this.fieldService.triggerChange();
  }

  async showTabsModal(index, tabs?) {
    if (!this.showTabs)
      return;

    const val = { ...tabs, value: this.reglist[index].value,
                  regid: this.reglist[index]?.id,
                  date: this.currDateVal,
                  sumPlan: this.sumPlanned,
                  sumUsed: this.util.roundNumber(this.sumTotal - this.reglist[index].value, 2),
                  sumUsedTotal: this.util.roundNumber(this.sumTotal, 2) };

    const modal = await this.modalCtrl.create({
      component: SubFormComponent,
      componentProps: {
        values: val,
        formId: this.to.tabsFormId
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {

      let updateIndex = index;

      const exist = this.hasEqualTabs(index, data);
      if(exist !== null) {
//        console.log('EXIST ALREADY!!');
        const popupText = this.translate.instant('RegExistUpdateValues',{hours: this.reglist[exist]?.value, comment: this.reglist[exist].tabs?.Kommentar});
        const conf = await this.popup.showConfirm('RegExist', popupText, true);
        if (!conf) {
          this.popup.showMessage('UpdateFailed', true);
          this.showTabsModal(index, data);
          return;
        }
        //remove new/updated reg, and move the values to the existing reg.
        updateIndex = exist; //index is reordered.
        this.reglist[updateIndex].tabs.Kommentar += '\n' + data.Kommentar;
        this.reglist[updateIndex].value += data.value;
      }
      else {
        this.reglist[updateIndex].tabs = data;
        this.reglist[updateIndex].value = data.value;
      }
      this.reglist[updateIndex].labelHighlight = this.buildLabel(this.reglist[updateIndex].tabs, true);
      this.reglist[updateIndex].label = this.buildLabel(this.reglist[updateIndex].tabs, false);

 //     console.log(index, exist, updateIndex);
      this.calcSum();

      if (this.reglist[updateIndex].label === '') // not valid reg.
        this.removeRegItem(updateIndex);
      else {
        this.submitRegItem(updateIndex).then(isSuccess => {
          if(isSuccess && exist !== null)
            this.removeRegItem(index, false);
        });
      }

    }
    else if (this.reglist[index].label === '') {
      this.removeRegItem(index);
    }
  }

  hasEqualTabs(index, tabValues: Tabs) {
    let isEqual = false;
    let equalIndex = null;
    for(let i = 0; i<this.reglist.length; i++) {
      if(index === i)
        continue;
      const reg = this.reglist[i];
      isEqual = false;
      for(const key of Object.keys(this.tabsEmpty)) {
        isEqual = (reg.tabs[key]?.Nr ?? '') === (tabValues[key]?.Nr ?? '');
        if(!isEqual) //any diff is allowed
          break;
      }

      if(isEqual) {
        equalIndex = i;
        break;
      }

    }
    this.logger.debug(equalIndex);
    return equalIndex;

  }

  setDate(data: any) { //used by datepicker
    const val = data.target.value; // data is an $event
    this.setCurrentDate(moment(val).toISOString(true));
  }

  setCurrentDate(preset?: string) {
    this.currDateTime = moment(preset).locale(this.localeLang);
    this.currDay = this.currDateTime.format(this.format.day);
    this.currDate = this.currDateTime.format(this.format.date);
    this.currDateVal = this.currDateTime.format(this.format.dateformat);
    this.weekdayNr = this.currDateTime.format(this.format.weekDayNr);
    this.getDayContent();

    if (this.showWeeklyReport) {
      const week = this.currDateTime.format(this.format.week);
      if (week !== this.currWeek) {
        this.currWeek = week;
        this.getWeeklyReport(this.currDateTime.toDate());
      }
    }
  }


  handleSwipe(event: any) {
    const direction = (event.direction === SwipeDirection.LeftToRight) ? -1 : 1;
    this.setNextDate(direction);
  }

  setNextDate(direction) {
    const curr = moment(this.currDateVal);
    const f = curr.isoWeekday();

    if (!this.showWeekend) {
      if ((f >= 5 && direction > 0) || (f === 1 && direction < 0)) {
        let diff = 3;
        if (f === 6 || f === 7)
          diff = 1;
        direction = direction * diff;
      }
    }
    const d = moment(this.timeService.createDate(this.currDateVal, direction, 'days'));
    this.newWeekSwipe = direction < 0 && (curr.format(this.format.weekYear) !== d.format(this.format.weekYear));
    this.setCurrentDate(d.toISOString(true));


  }
  jumpToDate(dayIndex) {
    const d = moment(this.currPeriode.fromDate).add(dayIndex, 'days');
    this.setCurrentDate(d.toISOString(true));

  }

}
