<ion-header>
    <ion-toolbar color="header">
      <ion-title class="ion-text-wrap">{{'ChangePicture' | translate}}</ion-title>
      <ion-button fill="clear" slot="end" (click)="close(false)" [disabled]="isLoading">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  
  <ion-content class="in-background">
    <ion-card class="formCard">
      <ion-list>
        <ion-item lines="none" *ngIf="isDevice">
          <ion-label class="ion-text-wrap">{{'TakeProfilePic' | translate}}</ion-label>
        </ion-item>
        <ion-item lines="none" *ngIf="isDevice">
          <ion-button fill="clear" slot="start" (click)="getFromCamera()">
            <ion-icon slot="icon-only" name="camera"></ion-icon>
          </ion-button>
          <ion-button fill="clear" slot="end" (click)="getFromGallery()">
            <ion-icon slot="icon-only" name="images"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item lines="none" *ngIf="!isDevice">
          <input type="file" accept="image/*" (change)="setImage($event)" />
        </ion-item>
        <ion-item lines="none" *ngIf="imagePath">
          <ion-img [src]="imagePath"></ion-img>
        </ion-item>
        <ion-button id="submitBtn" color="primary" expand="block" (click)="close(true)" [disabled]="!imagePath || isLoading">
          <ion-label *ngIf="!isLoading">{{'Save' | translate}}</ion-label>
          <ion-spinner *ngIf="isLoading"></ion-spinner>
        </ion-button>
      </ion-list>
    </ion-card>
  </ion-content>