import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Network } from '@ionic-native/network/ngx';
import { StatisticsService } from './statistics.service';
import { DeviceService } from './device.service';
import { OnlineInfo, OnlineStatus } from '../models/models';
import { PopupService } from './popup.service';
import { TranslateService } from '@ngx-translate/core';



/**
 * Service that monitors and controls if the app is online and if it has network
 */
@Injectable({
  providedIn: 'root'
})
export class OnlineService {
  online = new BehaviorSubject<boolean>(true);
  showingMessage = new BehaviorSubject<boolean>(false);
  private onlineInfo = new BehaviorSubject<OnlineInfo>({status: OnlineStatus.Online});

  private get currentInfo(): OnlineInfo {
    return this.onlineInfo.value;
  }

  get currentStatus(): OnlineStatus {
    return this.currentInfo.status;
  }

  get offlineBannerText(): string {
    return this.getOfflineText(this.currentStatus, this.currentInfo.code, this.currentInfo.endpoint, true);
  }

  constructor(
    private device: DeviceService,
    private network: Network,
    private statistics: StatisticsService,
    private popup: PopupService,
    private translate: TranslateService
  ) {
    this.onlineInfo.subscribe(({status}) => {
      this.online.next(status === OnlineStatus.Online);
    });
  }

  /**
   * Checks if the app has network connection
   */
  hasNetwork(): boolean {
    if (this.device.isDevice()) {
      return this.network.type !== this.network.Connection.NONE;
    }
    else {
      return navigator.onLine;
    }
  }

  /**
   * Checks if the app is online
   */
  isOnline(): boolean {
    if (!this.hasNetwork() && this.currentStatus !== OnlineStatus.NoNetwork) {
      this.goOffline(OnlineStatus.NoNetwork);
      return false;
    }
    return this.currentStatus === OnlineStatus.Online;
  }

  /**
   * Will set the app to online if the app has network connection
   */
  goOnline() {
    if (this.hasNetwork() && !this.isOnline()) {
      this.onlineInfo.next({status: OnlineStatus.Online});
      this.statistics.logOnline();
    }
    else if (!this.hasNetwork() && this.currentStatus !== OnlineStatus.NoNetwork) {
      this.goOffline(OnlineStatus.NoNetwork);
    }
  }

  /**
   * Will set the app to offline
   * @param status The online status to set
   * @param code (Optional) HttpError status code when reason is API fail
   * @param endpoint (Optional) The endpoint when reason was external API fail
   */
  goOffline(status: OnlineStatus, code?: number, endpoint?: string) {
    if (status === OnlineStatus.Online) {
      return;
    }
    if (!this.hasNetwork()) {
      status = OnlineStatus.NoNetwork;
    }
    const info: OnlineInfo = {status};
    if (code > 0 && (status === OnlineStatus.InternalFail || status === OnlineStatus.ExternalFail)) {
      info.code = code;
    }
    if (endpoint && status === OnlineStatus.ExternalFail) {
      info.endpoint = endpoint;
    }

    const text = this.getOfflineText(status, code, endpoint, false);
    if (text) {
      this.showingMessage.next(true);
      this.popup.showMessage(text, false, 'warning', 2500).then(() => this.showingMessage.next(false));
    }
    this.onlineInfo.next(info);
    this.statistics.logOffline(status);
  }

  private getOfflineText(status: OnlineStatus, code: number, endpoint: string, banner: boolean): string {
    let text: string;
    switch (status) {
      case OnlineStatus.InternalFail:
        text = banner ? this.translate.instant('BannerInternalFail') : this.translate.instant('OfflineInternal');
        break;
      case OnlineStatus.ExternalFail:
        text = banner ? this.translate.instant('BannerExternalFail') : this.translate.instant('OfflineExternal');
        break;
      case OnlineStatus.NoNetwork:
        return banner ? this.translate.instant('BannerNoNetwork') : this.translate.instant('OfflineNoNetwork');
      case OnlineStatus.HasOfflineRegs:
        return banner ? this.translate.instant('BannerOfflineRegs') : '';
      default:
        return '';
    }

    if (endpoint && status === OnlineStatus.ExternalFail) {
      text += ` (${endpoint})`;
    }

    if (code > 0) {
      text += ` - ${code}`;
    }

    return text;
  }
}
