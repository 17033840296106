import { Component, ViewChild, ViewContainerRef, AfterViewInit, ElementRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-wrapper-radio-header',
  templateUrl: './radio-header-wrapper.component.html',
  styleUrls: ['./radio-header-wrapper.component.scss'],
})
export class RadioHeaderWrapperComponent extends FieldWrapper implements AfterViewInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  constructor(
    private el: ElementRef,
    private device: DeviceService
  ) {
    super();
  }

  get mode(): 'ios' | 'md' {
    switch (this.to.mode) {
      case 'ios':
      case 'md':
        return this.to.mode;
      default:
        return null;
    }
  }

  get lines(): 'full' | 'inset' | 'none' {
    switch (this.to.lines) {
      case 'full':
      case 'inset':
      case 'none':
        return this.to.lines;
      default:
        return null;
    }
  }

  get removeLastLine(): boolean {
    return this.to.removeLastLine ?? true;
  }

  ngAfterViewInit() {
    const elRef: HTMLElement = this.el.nativeElement;
    const listHeader = elRef.querySelector('ion-list-header');
    if (!this.to.label) {
      listHeader.setAttribute('hidden', 'true');
    }
    else {
      /*
      listHeader.style.setProperty('--border-style', 'dashed');
      listHeader.lines = 'full';
      */
      if (this.device.isIOS()) {
        listHeader.style.setProperty('padding-bottom', '5px');
      }
      /*
      listHeader.style.setProperty('font-weight', 'normal');
      listHeader.style.setProperty('font-size', '17px');
      */
    }
    const items = elRef.querySelectorAll('ion-item');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < items.length; i++) {
      if (this.lines) {
        items[i].lines = this.lines;
      }
      if (this.mode) {
        items[i].mode = this.mode;
      }
    }
    if (this.removeLastLine) {
      items[items.length - 1].lines = 'none';
    }
  }
}
