import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-field-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent extends FieldType implements AfterViewInit {
  @ViewChild('title') title: ElementRef;
  get size(): string {
    switch (this.to.size) {
      case 1:
      case '1':
        return '1';
      case 3:
      case '3':
        return '3';
      default:
        return '2';
    }
  }

  get label(): string {
    if (!this.to.label) {
      return '';
    }
    else {
      return this.to.label.replace(/\n/g, '<br/>') || '';
    }
  }

  get align(): string {
    switch (this.to.align) {
      case 'right':
      case 'center':
        return this.to.align;
      default:
        return 'left';
    }
  }

  public static getHtml(config: FormlyFieldConfig, _value: any) {
    if (config.templateOptions?.label) {
      // eslint-disable-next-line eqeqeq
      const size = config.templateOptions.size == '1' ? '36px' : config.templateOptions.size == '3' ? '18px' : '25px';
      const align = config.templateOptions.align ?? 'left';
      const label = config.templateOptions.label.replace(/\n/g, '<br/>');
      return `<h1 style="font-size=${size}; text-align: ${align}; margin-top: 20px; width: 100%;">${label}</h1>`;
    }
    else {
      return '';
    }
  }

  ngAfterViewInit() {
    if (this.to.saveLabel) {
      this.formControl.setValue(this.label);
    }
    if (this.to.color && this.title?.nativeElement) {
      (this.title.nativeElement as HTMLHeadingElement).style.color = this.to.color;
    }
  }
}
