import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { IonicSwiper } from '@ionic/angular';

SwiperCore.use([IonicSwiper, Navigation, Pagination]);
@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent implements OnInit {
  @Input() inputUrls: string[];
  @Input() errorMsg: string;

  imageUrls: {url: string}[] = [];
  sizeSm = 6;
  sliderOpts: SwiperOptions = {
    zoom: {
      maxRatio: 5
    },
    allowSlidePrev: false,
    allowSlideNext: false
  };

  constructor(
    private modalCtrl: ModalController,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    for (const url of this.inputUrls) {
      if (url) {
        this.imageUrls.push({url});
      }
    }
    if (this.imageUrls.length === 1) {
      this.sizeSm = 12;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss().then(
      () => this.logger.debug('Sucessfully dismissed image modal'),
      (err) => this.logger.warn('Failed dismissing image modal', err)
    );
  }

}
