<ion-grid>
  <ion-row *ngIf="ranges.length > 1">
    <ion-col size="12">
      <ion-segment mode="ios" [(ngModel)]="displayMode" (ngModelChange)="switchedMode($event)">
        <ion-segment-button *ngFor="let range of ranges" [value]="range.id">{{range.name | translate}}</ion-segment-button>
      </ion-segment>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col size="2" *ngIf="showNavButtons" class="ion-align-self-start">
      <ion-button size="small" fill="clear" color="dark" (click)="back()">
        <ion-icon name="chevron-back" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col [size]="showNavButtons ? 8 : 12" class="ion-align-self-center">
      <ion-title  class="ion-text-center">{{title | titlecase}}</ion-title>
      <ion-spinner name="dots" *ngIf="loadingData"></ion-spinner>
    </ion-col>
    <ion-col size="2" *ngIf="showNavButtons" class="ion-align-self-end">
      <ion-button size="small" fill="clear" color="dark" (click)="next()">
        <ion-icon name="chevron-forward" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <calendar #calendarEl
        [eventSource]="calendar.events"
        [calendarMode]="calendar.mode"
        [currentDate]="calendar.currentDate"
        [allDayLabel]="calendar.allDayLabel"
        [noEventsLabel]="calendar.noEventsLabel"
        [formatMonthTitle]="calendar.formatMonthTitle"
        [formatWeekTitle]="calendar.formatWeekTitle"
        [formatDayTitle]="calendar.formatDayTitle"
        [formatHourColumn]="'HH:mm'"
        [formatDayHeader]="'EEEEEE'"
        [startingDayWeek]="1"
        [startingDayMonth]="1"
        [startHour]="calendar.startHour"
        [endHour]="calendar.endHour"
        [locale]="calendar.locale"
        [queryMode]="calendar.queryMode"
        [step]="15"
        [weekviewNormalEventTemplate]="eventTemplate"
        [weekviewAllDayEventTemplate]="eventTemplate"
        [dayviewNormalEventTemplate]="eventTemplate"
        [dayviewAllDayEventTemplate]="eventTemplate"
        (onTitleChanged)="onViewTitleChanged($event)"
        (onEventSelected)="onEventSelected($any($event))"
        (onRangeChanged)="onRangeChange($event)"
        (onCurrentDateChanged)="onDateSelected($event)"
        *ngIf="calendar"></calendar>
      </ion-col>
    </ion-row>
</ion-grid>

<ng-template #eventTemplate let-displayEvent="displayEvent">
  <div class="calendar-event-inner" [style.background-color]="displayEvent.event.bgColor" [style.color]="displayEvent.event.textColor" style="height: 100%; font-size: 12px;">{{displayEvent.event.title}}</div>
</ng-template>