import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { LoggerModule } from 'ngx-logger';

import { IonicSelectableModule } from 'ionic-selectable';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { EllipsisModule } from 'ngx-ellipsis';
import { GlobalErrorHandler } from './helpers/errrorHandler';
import { CustomComponentsModule } from './custom-components/custom-components.module';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Printer } from '@ionic-native/printer/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Drivers } from '@ionic/storage';
import { PipesModule } from './pipes/pipes.module';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AuthInterceptor } from './helpers/authInterceptor';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/languages/', '.json');
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig  {
  // Fix for iOS scrolling problem. scroll-event is overriden.
  overrides = {
    pinch: {
        enable: false
    },
    rotate: {
        enable: false
    },
    press: {
      threshold: 20 // default 9
    }
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot({
      name: 'tempusin_db',
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
    }),
    HttpClientModule,
    PipesModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot({
      level: environment.consoleLogLevel,
      serverLogLevel: environment.serverLogLeveL,
      serverLoggingUrl: environment.serverLogUrl,
      enableSourceMaps: true
    }),
    IonicSelectableModule,
    CustomComponentsModule,
    EllipsisModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.useServiceWorker }),
    HammerModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Network,
    NativeStorage,
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    Printer,
    InAppBrowser,
    WebView,
    Clipboard,
    AppVersion
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
