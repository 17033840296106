/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
/**
 * Object with mapping from old IonIcons to new IonIcons
 */
export const IconMap = {
    'bar': 'wine',
    'alarm-clock': 'alarm',
    'clock': 'alarm',
    'undo': 'arrow-undo',
    'paper': 'newspaper',
    'chatboxes': 'chatbox',
    'contact': 'person-circle',
    'contacts': 'person-circle',
    'filing': 'file-tray',
    'jet': 'airplane',
    'qr-scanner': 'qr-code',
    'photos': 'image',
    'hand': 'hand-right',
    'favorite': 'bookmark',
    'favorite-outline': 'bookmark-outline',
    'checkbox-blank': 'checkbox',
    'checkbox-outline-blank': 'checkbox-outline',
    'email': 'mail',
    'email-outline': 'mail-outline',
    'gear': 'settings',
    'person-stalker': 'people',
    'compose': 'create',
    'compose-outline': 'create-outline',
    'search-strong': 'search',
    'speakerphone': 'megaphone',
    'upload': 'cloud-upload',
    'arrow-expand': 'expand',
    'more-horizontal': 'ellipsis-horizontal',
    'done-all': 'checkmark-done',
    'shuffle-strong': 'shuffle',
    'alert-circled': 'alert-circle',
    'plane': 'airplane',
    'lock': 'lock-closed',
    'settings-strong': 'options',
    'levels': 'options',
    'arrow-right-a': 'arrow-redo',
    'edit': 'pencil',
    'university': 'school',
    'microphone': 'mic',
    'mic-c': 'mic',
    'cancel': 'close-circle',
    'done': 'checkmark',
    'drafts': 'mail-open',
    'notifications-none': 'notifications',
    'playstore': 'logo-google-playstore',
    'share-alt': 'share',
    'arrow-graph-up-right': 'trending-up',
    'arrow-return-left': 'return-down-back',
    'arrow-up-a': 'arrow-up',
    'bag': 'basket',
    'battery-empty': 'battery-dead',
    'checkmark-circled': 'checkmark-circle',
    'checkmark-round': 'checkmark-circle',
    'erlenmeyer-flask': 'flask',
    'film-marker': 'film',
    'headphone': 'headset',
    'help-circled': 'help-circle',
    'information-circled': 'information-circle',
    'clock-outline': 'alarm-outline',
    'fastforward': 'play-forward',
    'fastforward-outline': 'play-forward-outline',
    'game-controller-b-outline': 'game-controller-outline',
    'minus': 'remove',
    'skipforward': 'play-skip-forward',
    'snowy': 'snow',
    'load-b': 'refresh-circle',
    'model-s': 'car-sport',
    'navicon-round': 'menu',
    'no-smoking': 'logo-no-smoking',
    'paintbucket': 'color-fill',
    'paper-airplane': 'paper-plane',
    'pie-graph': 'pie-chart',
    'pound': 'logo-euro',
    'stats-bars': 'stats-chart',
    'tshirt': 'shirt',
    'tshirt-outline': 'shirt-outline',
    'unlocked': 'lock-open',
    'wand': 'color-wand',
    'waterdrop': 'water',
    'wineglass': 'wine',
    'wrench': 'build',
    'hangout': 'chatbubble-ellipses',
    'drag': 'reorder-three'
};
