/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { OnlineService } from './online.service';
import { Form, FormWithValues, OldReg, User, UserInfo, UserProfile, SortBy, Project, Tenant, Context, Theme, NewReg,
         LookupValue, BoxStatus, LogEntry, ApiEnvironment, StyleData, ApiErrorDict, FormCopyWithData, FormCopy, StorageValue, StoredNotification, AutosavedForm } from '../models/models';
import { UtilityService } from './utility.service';
import { BehaviorSubject } from 'rxjs';
import { PopupService } from './popup.service';
import { DeviceService } from './device.service';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

/* eslint-disable @typescript-eslint/no-shadow */
/**Keys of common things to store */
export enum Keys {
  None = '',
  Form = 'form',
  StoredForms = 'storedForms',
  FormView = 'formView',
  KeepForms = 'keepForms',
  Forms = 'forms',
  FavoriteForms = 'favoriteForms',
  LastUsedForms = 'lastUsedForms',
  User = 'user',
  Setup = 'setup',
  SetupDate = 'setupDate',
  History = 'history',
  Registration = 'reg',
  StoredRegs = 'storedRegs',
  Projects = 'projects',
  CurrentProject = 'currentProject',
  ProjectLabel = 'projectLabel',
  UserData = 'userData',
  Theme = 'theme',
  Tenant = 'tenant',
  OfflineRegs = 'offlineRegs',
  UserInfo = 'userInfo',
  Context = 'context',
  Lookup = 'lookup',
  Logo = 'logo',
  HistorySelect = 'historySelect',
  UserProfile = 'userProfile',
  Autosave = 'autosave',
  AutosaveIds = 'autosaveIds',
  Language = 'language',
  Log = 'log',
  HomeBoxOrder = 'homeBoxOrder',
  FavoriteFormList = 'favoriteFormList',
  ApiEnv = 'apiEnv',
  IntroVersion = 'introVersion',
  LastAppVersion = 'lastAppVersion',
  IonIcons = 'ionIcons',
  HomeTooltips = 'homeTooltips',
  StyleData = 'style',
  ApiErrors = 'apiErrors',
  LastReg = 'lastReg',
  FormCopy = 'formCopy',
  AutosavedFormCopy = 'currentFormCopy',
  FieldCount = 'fieldCount',
  GetData = 'getData',
  UserContext = 'userContext',
  LastNoResultWarn = 'lastNoResultWarn',
  Notifications = 'notifications',
  FirebaseToken = 'firebaseToken',
  AllowHomeEdit = 'allowHomeEdit',
  TenantName = 'tenantName'
}
/* eslint-enable @typescript-eslint/no-shadow */


/**Service for storing in local storage (SQLite, indexedDB, WebSQL) */
@Injectable({
  providedIn: 'root'
})
export class InStorageService {
  finishedCreating = new BehaviorSubject(false);
  finishedInit = new BehaviorSubject(false);
  tenant: string;
  username: string;
  private _storage: Storage;

  constructor(
    private storage: Storage,
    private logger: NGXLogger,
    private online: OnlineService,
    private util: UtilityService,
    private popup: PopupService,
    private device: DeviceService
  ) {
    this.init();
  }

  async init() {
    if (this.device.isDevice()) {
      await this.storage.defineDriver(CordovaSQLiteDriver);
    }
    this._storage = await this.storage.create();
    this.finishedCreating.next(true);
    const regs = await this.getOfflineRegs();
    if (this.online.isOnline() && regs.length === 0) {
      await this.clearOld();
    }
    this.finishedInit.next(true);
  }

  /**
   * Get forms stored in storage
   * @param projectId (Optional) Will get forms stored for an specific project
   */
  async getForms(projectId?: number): Promise<Form[]> {
    const forms: Form[] = projectId ? await this.getWithId(Keys.Forms, projectId)
                                    : await this.get(Keys.Forms);
    return this.fixForms(forms);
  }

  /**
   * Get forms stored in storage with timestamp for last change
   * @param projectId (Optional) Get forms stored for an specific project
   */
  async getFormsWithTimestamp(projectId?: number): Promise<StorageValue<Form[]>> {
    const forms: StorageValue<Form[]> = projectId ? await this.getWithId(Keys.Forms, projectId, true)
                                                  : await this.get(Keys.Forms, true);
    forms.value = this.fixForms(forms.value);
    return forms;
  }

  /**
   * Get last used forms stored in storage
   * @param projectId (Optional) Get forms stored for an specific project
   */
  async getLastUsedForms(projectId?: number): Promise<Form[]> {
    const forms: Form[] = projectId ? await this.getWithId(Keys.LastUsedForms, projectId)
                                    : await this.get(Keys.LastUsedForms);
    return this.fixForms(forms);
  }

  /**
   * Get last used forms stored in storage with timestamp for last change
   * @param projectId (Optional) Get forms stored for an specific project
   */
  async getLastUsedFormsWithTimestamp(projectId?: number): Promise<StorageValue<Form[]>> {
    const forms: StorageValue<Form[]> = projectId ? await this.getWithId(Keys.LastUsedForms, projectId, true)
                                                  : await this.get(Keys.LastUsedForms, true);
    forms.value = this.fixForms(forms.value);
    return forms;
  }

  /**
   * Removes form from stored favorite forms
   * @param id The form id
   * @param projectId (Optional) Project id
   */
  async removeFavoriteForm(id: number, projectId?: number): Promise<void> {
    let forms = await this.getFavoriteForms(projectId);
    forms = forms.filter(form => form.id !== id);
    await this.setFavoriteForms(forms, projectId);
  }

  /**
   * Get favorite forms stored in storage
   * @param projectId (Optional) Get forms stored for an specific project
   */
  async getFavoriteForms(projectId?: number): Promise<Form[]> {
    const forms: Form[] = projectId ? await this.getWithId(Keys.FavoriteForms, projectId)
                                    : await this.get(Keys.FavoriteForms);
    return this.fixForms(forms);
  }

  /**
   * Get favorite forms stored in storage with timestamp for last change
   * @param projectId (Optional) Get forms stored for an specific project
   */
  async getFavoriteFormsWithTimestamp(projectId?: number): Promise<StorageValue<Form[]>> {
    const forms: StorageValue<Form[]> = projectId ? await this.getWithId(Keys.FavoriteForms, projectId, true)
                                                  : await this.get(Keys.FavoriteForms, true);
    forms.value = this.fixForms(forms.value);
    return forms;
  }

  /**
   * Store lasted used forms in storage
   * @param forms The forms to store
   * @param projectId (Optional) Store forms for an specific project
   */
  setLastUsedForms(forms: Form[], projectId?: number): Promise<any> {
    return projectId ? this.setWithId(Keys.LastUsedForms, projectId, forms) : this.set(Keys.LastUsedForms, forms);
  }

  /**
   * Store favorite forms in storage
   * @param forms The forms to store
   * @param projectId (Optional) Store forms for an specific project
   */
  setFavoriteForms(forms: Form[], projectId?: number): Promise<any> {
    return projectId ? this.setWithId(Keys.FavoriteForms, projectId, forms) : this.set(Keys.FavoriteForms, forms);
  }

  /**
   * Store forms in storage
   * @param forms The forms to store
   * @param projectId (Optional) Store forms for an specific project
   */
  setForms(forms: Form[], projectId?: number): Promise<any> {
    return projectId ? this.setWithId(Keys.Forms, projectId, forms) : this.set(Keys.Forms, forms);
  }

  /**
   * Get a property of form stored in forms
   * @param id Id of the form
   * @param propertyKey Key of a property in the form
   * @param projectId (Optional) Get form stored by an specific projectId
   * @param projectNr (Optional) Get form stored by an specific projectId, but use the projectNr to find projectId. Will not be used if `projectId` is given
   */
  async getFormProperty<K extends keyof Form>(id: number, propertyKey: K, projectId?: number, projectNr?: string): Promise<Form[K]> {
    let forms = await this.getForms();
    let form = forms.find(f => f.id === id);
    if (!form) {
      if (!projectId && projectNr) {
        const project = await this.getProjectFromNumber(projectNr);
        projectId = project?.id;
      }
      if (projectId) {
        forms = await this.getForms(projectId);
        form = forms.find(f => f.id === id);
      }
    }
    return (form) ? form[propertyKey]: undefined;
  }

  /**
   * Get an stored form
   * @param id Id of the stored form
   */
  async getForm(id: number): Promise<FormWithValues> {
    const form = await this.getWithId(Keys.Form, id);
    return this.fixFormWithValues(form);
  }

  /**
   * Store an form in storage
   * @param id Id of the form
   * @param form The form to store
   */
  async setForm(id: number, form: FormWithValues): Promise<void> {
    if (form.theForm) {
      await this.setWithId(Keys.Form, id, form);
      const count = this.util.parseFields(form.theForm.definition, true).length;
      await Promise.all([this.refreshStoredFormIds(), this.addFieldCount(id, count)]);
    }
  }

  /**
   * Remove form from storage
   * @param id Id of the form
   */
  async removeForm(id: number): Promise<void> {
    await this.removeWithId(Keys.Form, id);
    await this.refreshStoredFormIds();
  }

  /**
   * Clear values from stored form
   * @param id The id of the form
   */
  async clearValuesOfForm(id: number): Promise<void> {
    const form = await this.getForm(id);
    if (form) {
      form.values = [];
      await this.setForm(id, form);
    }
  }

  /**
   * Get when an form was stored
   * @param formId The id of the form
   */
  async getStoreDateOfForm(formId: number): Promise<Date> {
    const form: StorageValue<Form> = await this.getWithId(Keys.Form, formId, true);
    return form?.timestamp;
  }

  /**
   * Get the ids of the forms that shouldn't be deleted when old
   */
  async getKeepFormIds(): Promise<number[]> {
    const ids = await this.get(Keys.KeepForms);
    return ids || [];
  }

  /**
   * Add id of form that shouldn't be deleted when old
   * @param formId The id of the form
   */
  async addKeepFormId(formId: number): Promise<void> {
    const ids = await this.getKeepFormIds();
    if (!ids.includes(formId)) {
      ids.push(formId);
      await this.set(Keys.KeepForms, ids);
    }
  }

  /**
   * Remove id for form that shouldn't be deleted when old, e.g. allowing it to be deleted again
   * @param formId The id of the form
   */
  async removeKeepFormId(formId: number): Promise<void> {
    const ids = await this.getKeepFormIds();
    await this.set(Keys.KeepForms, ids.filter(id => id !== formId));
  }

  /**
   * Get ids of the form that are stored
   */
  async getStoredFormIds(): Promise<number[]> {
    const ids = await this.get(Keys.StoredForms);
    return ids || [];
  }

  /**
   * Refresh the ids of which forms are stored
   * @param keys (Optional) Keys of stored items in storage, if not given it will call `getKeys`
   */
  private async refreshStoredFormIds(keys?: string[]): Promise<void> {
    if (keys) {
      keys = this.filterKeys(keys, Keys.Form, true);
    }
    else {
      keys = await this.getKeys(Keys.Form, true);
    }
    const ids: number[] = [];
    for (const [id] of keys.map(k => k.split(':'))) {
      if ( id) {
        ids.push(+id);
      }
    }
    await this.set(Keys.StoredForms, ids);
  }

  /**
   * Refresh how many fields the stored forms have
   * @param keys (Optional) Keys of stored items in storage, if not given it will call `getKeys`
   */
  private async refreshFieldCount(keys?: string[]): Promise<void> {
    if (keys) {
      keys = this.filterKeys(keys, Keys.Form, true);
    }
    else {
      keys = await this.getKeys(Keys.Form, true);
    }
    const counts = await this.getAllFieldCounts();
    for (const [id] of keys.map(k => k.split(':'))) {
      if (id) {
        const formWithValues = await this.getForm(+id);
        const form = formWithValues?.theForm;
        if (form) {
          counts[form.id] = this.util.parseFields(form.definition, true).length;
        }
      }
    }
    this.set(Keys.FieldCount, counts);
  }

  /**
   * Add field count for an form
   * @param formId The id of the form
   * @param count How many fields the form have
   */
  async addFieldCount(formId: number, count: number): Promise<void> {
    const counts = await this.getAllFieldCounts();
    counts[formId] = count;
    this.set(Keys.FieldCount, counts);
  }

  /**
   * Get how many fields all the stored forms have
   */
  async getAllFieldCounts(): Promise<{[formId: number]: number}> {
    const counts = await this.get(Keys.FieldCount);
    return counts || {};
  }

  /**
   * Get how many fields a specific form has
   * @param formId The id of the form
   */
  async getFieldCount(formId: number): Promise<number> {
    const counts = await this.getAllFieldCounts();
    return counts[formId];
  }

  /**
   * Get form view id from storage
   * @param projectNo The project number the form view belongs to
   */
  async getFormViewId(projectNo: number): Promise<number> {
    const boxes = await this.getHomeBoxOrder(projectNo);
    const box = boxes.find(b => b.id === 'formview');
    if (box && Array.isArray(box.formIds) && box.formIds.length > 0) {
      return box.formIds[0];
    }
    else {
      return null;
    }
  }

  /**
   * Store form view id in storage
   * @param projectNo The project number the form view belongs to
   * @param formId The form view id
   */
  async setFormViewId(projectNo: number, formId: number): Promise<BoxStatus[]> {
    const boxes = await this.getHomeBoxOrder(projectNo);
    const box = boxes.find(b => b.id === 'formview');
    if (box) {
      box.formIds = [formId];
      await this.setHomeBoxOrder(projectNo, boxes);
    }
    return boxes;
  }

  /**
   * Remove form view id from storage
   * @param projectNo The project number the form view belongs to
   */
  async removeFormViewId(projectNo: number): Promise<BoxStatus[]> {
    const boxes = await this.getHomeBoxOrder(projectNo);
    const box = boxes.find(b => b.id === 'formview');
    if (box) {
      box.formIds = [];
      await this.setHomeBoxOrder(projectNo, boxes);
    }
    return boxes;
  }

  /**
   * Get stored history registrations
   * @param projectId (Optional) Project Id of the project if the history only contains regs from one project
   */
  async getHistory(projectId?: number): Promise<OldReg[]> {
    let history: OldReg[];
    if (projectId) {
      history = await this.getWithId(Keys.History, projectId);
    }
    else {
      history = await this.get(Keys.History);
    }
    return this.fixHistory(history);
  }

  /**
   * Get stored history registrations with timestamp for last change
   * @param projectId (Optional) Project Id of the project if the history only contains regs from one project
   */
  async getHistoryWithTimestamp(projectId?: number): Promise<StorageValue<OldReg[]>> {
    let history: StorageValue<OldReg[]>;
    if (projectId) {
      history = await this.getWithId(Keys.History, projectId, true);
    }
    else {
      history = await this.get(Keys.History, true);
    }
    history.value = this.fixHistory(history.value);
    return history;
  }



  /**
   * Store history registrations in storage
   * @param history The registrations to store
   * @param projectId (Optional) Project Id of the project if the history only contains regs from one project
   */
  setHistory(history: OldReg[], projectId?: number): Promise<any> {
    return projectId ? this.setWithId(Keys.History, projectId, history) : this.set(Keys.History, history);
  }

  /**
   * Get last registration of an form, possibly filtered by project
   * @param formId The id of the form
   * @param projectId (Optional) Project id of the project
   */
  async getLastRegistration(formId: number, projectId?: number): Promise<OldReg> {
    const key = projectId ? `${formId}:${projectId}` : formId;
    const reg = await this.getWithId(Keys.LastReg, key);
    return this.fixRegistration(reg);
  }

  /**
   * Set last registration of an form
   * @param reg The registration
   * @param formId The id of the form
   * @param projectId (Optional) Id of project
   */
  setLastRegistration(reg: OldReg, formId: number, projectId?: number) {
    const key = projectId ? `${formId}:${projectId}` : formId;
    return this.setWithId(Keys.LastReg, key, reg);
  }

  /**
   * Get formname and registration time of registration
   * @param id Id of registration
   */
  async getRegData(id: number): Promise<{name: string, date: Date}> {
    const history = await this.getHistory();
    const reg = history.find(r => r.regId === id);
    if (reg) {
      return {
        name: reg.formName,
        date: reg.signedAt
      };
    }
    else {
      return null;
    }
  }

  /**
   * Get an stored registration
   * @param id Id of the registration
   */
  async getRegistration(id: number): Promise<FormWithValues> {
    const reg = await this.getWithId(Keys.Registration, id);
    return this.fixFormWithValues(reg);
  }

  /**
   * Store an registration in storage
   * @param id Id of the the registration
   * @param reg The registration to store
   */
  async setRegistration(id: number, reg: FormWithValues): Promise<void> {
    await this.setWithId(Keys.Registration, id, reg);
    await this.refreshStoredRegIds();
  }

  /**
   * Get ids of the registrations that are stored
   */
  async getStoredRegIds(): Promise<number[]> {
    const ids = await this.get(Keys.StoredRegs);
    return ids || [];
  }

  /**
   * Refresh the ids of the registrations that are stored
   * @param keys (Optional) Keys of stored items in storage, if not given it will call `getKeys`
   */
  private async refreshStoredRegIds(keys?: string[]): Promise<void> {
    if (keys) {
      keys = this.filterKeys(keys, Keys.Registration, true);
    }
    else {
      keys = await this.getKeys(Keys.Registration, true);
    }
    const ids: number[] = [];
    for (const [id] of keys.map(k => k.split(':'))) {
      if (id) {
        ids.push(+id);
      }
    }
    this.set(Keys.StoredRegs, ids);
  }

  /**
   * Get the setup form from storage
   */
  async getSetup(): Promise<FormWithValues> {
    const form = await this.get(Keys.Setup);
    return this.fixFormWithValues(form);
  }

  /**
   * Store an setup form in storage
   * @param setup The setup form
   */
  setSetup(setup: FormWithValues): Promise<any> {
    return this.set(Keys.Setup, setup);
  }

  /**
   * Get when a setup form was saved/changed on the server
   * @param tenant The tenant of the setup form
   * @param username The current user
   */
  async getSetupStoreDate(tenant: string, username: string): Promise<string> {
    let date: string = await this.getWithId(Keys.SetupDate, `${tenant.toLowerCase()}:${username.toLowerCase()}`);
    if (!date) {
      date = await this.getWithId(Keys.SetupDate, tenant.toLowerCase());
      if (date) {
        await Promise.all([
          this.setSetupStoreDate(date, tenant, username),
          this.removeWithId(Keys.SetupDate, tenant.toLowerCase())
        ]);
      }
    }
    return date;
  }

  /**
   * Set when a setup form was saved/changed on the server
   * @param storeDate The save/change date
   * @param tenant (Optional) The tenant of setup form, if not given it will use the current tenant set
   * @param username (Optional) The current user, if not given it will use the current user set
   */
  setSetupStoreDate(storeDate: string, tenant?: string, username?: string): Promise<any> {
    tenant ||= this.tenant;
    username ||= this.username;
    return (storeDate && tenant && username) ? this.setWithId(Keys.SetupDate, `${tenant.toLowerCase()}:${username.toLowerCase()}`, storeDate) : Promise.resolve();
  }

  /**
   * Get stored user from storage
   */
  getUser(): Promise<User> {
    return this.get(Keys.User);
  }

  /**
   * Store an user in storage
   * @param user The user to store
   */
  setUser(user: User): Promise<any> {
    return this.set(Keys.User, user);
  }

  /**
   * Remove stored user from storage
   */
  removeUser(): Promise<any> {
    return this.remove(Keys.User);
  }

  /**
   * Get user info from storage (e.g. full name, email, profile picture)
   */
  getUserInfo(): Promise<UserInfo> {
    return this.get(Keys.UserInfo);
  }

  /**
   * Store user info in storage (e.g. full name, email, profile picture)
   * @param userInfo The user info to store
   */
  setUserInfo(userInfo: UserInfo): Promise<any> {
    return this.set(Keys.UserInfo, userInfo);
  }

  /**
   * Gets the current users profile from storage (e.g. first name, last name, email, etc)
   */
  getUserProfile(): Promise<UserProfile> {
    return this.get(Keys.UserProfile);
  }

  /**
   * Set the current users profile from storage (e.g. first name, last name, email, etc)
   * @param userProfile The user profile to store
   */
  setUserProfile(userProfile: UserProfile): Promise<any> {
    return this.set(Keys.UserProfile, userProfile);
  }

  /**
   * Get full projects from storage
   * @param (Optional) How to sort the projects, default: NoSort
   */
  async getProjects(sortBy: SortBy = SortBy.NoSort): Promise<Project[]> {
    const projects: Project[] = await this.get(Keys.Projects);
    return this.sortProjects(projects, sortBy);
  }

  /**
   * Get full projects with timestamp
   * @param (Optional) How to sort the projects, default: NoSort
   */
  async getProjectsWithTimestamp(sortBy: SortBy = SortBy.NoSort): Promise<StorageValue<Project[]>> {
    const projects: StorageValue<Project[]> = await this.get(Keys.Projects, true);
    projects.value = this.sortProjects(projects.value, sortBy);
    return projects;
  }

  /**
   * Store full projects in storage
   * @param projects The projects to store
   */
  setProjects(projects: Project[]): Promise<any> {
    return this.set(Keys.Projects, projects);
  }

  /**
   * Get project from project number
   * @param number The project number
   */
  async getProjectFromNumber(number: string): Promise<Project> {
    const projects = await this.getProjects();
    return projects.find(p => p.number === number);
  }

  async getProjectFromId(id: number): Promise<Project> {
    const projects = await this.getProjects();
    return projects.find(p => p.id === id);
  }

  /**
   * Get current project from storage
   */
  getCurrentProject(): Promise<Project> {
    return this.get(Keys.CurrentProject);
  }

  /**
   * Store current project in storage
   * @param project The project to store
   */
  setCurrentProject(project: Project): Promise<any> {
    return this.set(Keys.CurrentProject, project);
  }

  /**
   * Get the label for the project field from storage
   */
  async getProjectLabel(): Promise<string> {
    const label = await this.get(Keys.ProjectLabel);
    return label ?? '';
  }

  /**
   * Get the label for the project field with timestamp from storage
   */
  async getProjectLabelWithTimestamp(): Promise<StorageValue<string>> {
    const label: StorageValue<string> = await this.get(Keys.ProjectLabel, true);
    label.value ??= '';
    return label;
  }

  /**
   * Set the label for the project field in storage
   * @param label The label to store
   */
  setProjectLabel(label: string): Promise<any> {
    return this.set(Keys.ProjectLabel, label);
  }

  /**
   * Get tenant from storage
   * @param (Optional) The id/number of tenant
   */
  getTenant(id?: number | string): Promise<Tenant> {
    if (typeof id === 'undefined' || id === null) {
      return this.get(Keys.Tenant);
    }
    else {
      return this.getWithId(Keys.Tenant, id);
    }
  }

  /**
   * Store tenant in storage
   * @param tenant Tenant to store
   * @param (Optional) The id/number of tenant
   */
  setTenant(tenant: Tenant, id?: number | string): Promise<any> {
    if (typeof id === 'undefined' || id === null) {
      return this.set(Keys.Tenant, tenant);
    }
    else {
      return this.setWithId(Keys.Tenant, id, tenant);
    }
  }

  /**
   * Get the full name of an tenant from storage
   * @param tenant The tenancy name
   */
  async getTenantName(tenant: string): Promise<string> {
    const name = await this.getWithId(Keys.TenantName, tenant);
    return name ?? '';
  }

  /**
   * Store the full name of an tenant from storage
   * @param tenant The tenancy name
   */
  setTenantName(tenant: string, fullName: string): Promise<any> {
    return this.setWithId(Keys.TenantName, tenant, fullName);
  }

  /**
   * Get userdata from storage (e.g. username and email)
   */
  getUserData(): Promise<Context> {
    return this.get(Keys.UserData);
  }

  /**
   * Store userdata in storage (e.g. username and email)
   * @param data The userdata
   */
  setUserData(data: Context): Promise<any> {
    return this.set(Keys.UserData, data);
  }

  /**
   * Get css-theme from storage
   */
  getTheme(): Promise<Theme> {
    return this.get(Keys.Theme);
  }

  /**
   * Store css-theme in storage
   * @param theme The theme to store
   */
  setTheme(theme: Theme): Promise<any> {
    return this.set(Keys.Theme, theme);
  }

  /**
   * Get language from storage
   */
  getLanguage(): Promise<string> {
    return this.get(Keys.Language);
  }

  /**
   * Store language in storage
   * @param lang The language to store
   */
  setLanguage(lang: string): Promise<any> {
    return this.set(Keys.Language, lang);
  }

  /**
   * Get offline registrations from storage
   */
  async getOfflineRegs(): Promise<NewReg[]> {
    const regs: NewReg[] = await this.get(Keys.OfflineRegs);
    return regs || [];
  }

  /**
   * Store offline registrations in storage
   * @param regs The registrations to store
   */
  setOfflineRegs(regs: NewReg[]): Promise<any> {
    return this.set(Keys.OfflineRegs, regs);
  }

  /**
   * Get stored context
   * @param source The source in the body of context call
   */
  getContext(source: string): Promise<Context> {
    return this.getWithId(Keys.Context, source);
  }

  /**
   * Store context in storage
   * @param context The context to store
   * @param source The source in the body of context call
   */
  setContext(context: Context, source: string): Promise<any> {
    return this.setWithId(Keys.Context, source, context);
  }

  /**
   * Get values from lookup table in storage
   * @param table The lookup table to get
   * @param parent (Optional) Name of parent table if it's used
   */
  async getLookups(table: string, parent?: string): Promise<LookupValue[]> {
    const key = parent ? `${table}:${parent}` : table;
    const lookups = await this.getWithId(Keys.Lookup, key);
    return lookups || [];
  }

  /**
   * Store lookup table values in storage
   * @param lookups The lookup values to store
   * @param table Name of the lookup table
   * @param parent (Optional) Name of parent table if it's used
   */
  setLookups(lookups: LookupValue[], table: string, parent?: string): Promise<any> {
    const key = parent ? `${table}:${parent}` : table;
    return this.setWithId(Keys.Lookup, key, lookups);
  }

  /**
   * Get logo url from storage
   */
  async getLogo(): Promise<string> {
    const logo = await this.get(Keys.Logo);
    return logo ?? '';
  }

  /**
   * Store an logo url in storage
   * @param logoUrl The logo url to store
   */
  setLogo(logoUrl: string): Promise<any> {
    return this.set(Keys.Logo, logoUrl);
  }

  /**
   * Get previous values for history select from storage
   * @param formId Id of the form
   * @param key Key of the history select
   */
  async getHistorySelect(formId: number, key: string): Promise<string[]> {
    key = formId + ':' + key;
    const values = await this.getWithId(Keys.HistorySelect, key);
    return values || [];
  }

  /**
   * Store previous values for history select in storage
   * @param values The values to store
   * @param formId Id of the form
   * @param key Key of the history select
   */
  setHistorySelect(values: string[], formId: number, key: string): Promise<any> {
    key = formId + ':' + key;
    return this.setWithId(Keys.HistorySelect, key, values);
  }

  /**
   * Store the box-layout values for home in storage
   * @param projectId The project id for the project the box order belongs to
   * @param list The values to store
   */
  async setHomeBoxOrder(projectId: number, list: BoxStatus[]) {
    list = await this.addFavsAndFV(list, projectId);
    return this.setWithId(Keys.HomeBoxOrder, projectId, list);
  }
  /**
   * Get the box-layout values for home in storage
   * @param projectId The project id for the project the box order belongs to
   */
  async getHomeBoxOrder(projectId: number): Promise<BoxStatus[]> {
    let order = await this.getWithId(Keys.HomeBoxOrder, projectId);
    order = await this.fixOldHomeOrder(order, projectId);
    return order;
  }

  /**
   * Remove an box order
   * @param projectId The project id for the project the box order belongs to
   */
  async removeHomeBoxOrder(projectId: number): Promise<any> {
    return this.removeWithId(Keys.HomeBoxOrder, projectId);
  }

  /**
   * Remove all box orders from storage
   */
  async removeAllHomeBoxOrders(): Promise<any> {
    const [homeKeys, favKeys, fVKeys] = await Promise.all([
      this.getKeys(Keys.HomeBoxOrder),
      this.getKeys(Keys.FavoriteFormList),
      this.getKeys(Keys.FormView)
    ]);
    await Promise.all([...homeKeys, ...favKeys, ...fVKeys].map(key => this.storage.remove(key)));
  }

  /**
   * Store the array-list of favorite forms for home in storage
   * @param list The values to store
   * @param projectId The projectId current selected
   */
  async setfavoriteFormList(list: number[], projectId: number): Promise<BoxStatus[]> {
    const boxes = await this.getHomeBoxOrder(projectId);
    const box = boxes.find(b => b.id === 'favorite');
    if (box) {
      box.formIds = list;
      await this.setHomeBoxOrder(projectId, boxes);
    }
    return boxes;
  }
  /**
   * Get the array-list of favorite forms for home in storage
   * @param projectId The projectId current selected
   */
  async getfavoriteFormList(projectId: number): Promise<number[]> {
    const boxes = await this.getHomeBoxOrder(projectId);
    const box = boxes.find(b => b.id === 'favorite');
    if (box && Array.isArray(box.formIds)) {
      return box.formIds;
    }
    else {
      return [];
    }
  }

  /**
   * Get autosaved state of form
   * @param formId Id of the form that was autosaved
   * @param projectId Id of the project
   */
  getAutosave(formId: number, projectId: number): Promise<{[key: string]: any}> {
    return this.getWithId(Keys.Autosave, formId + ':' + projectId);
  }

  /**
   * Get autosaved state of form with the timestamp for laste save
   * @param formId Id of the form that was autosaved
   * @param projectId Id of the project
   */
  getAutosaveWithTimestamp(formId: number, projectId: number): Promise<StorageValue<{[key: string]: any}>> {
    return this.getWithId(Keys.Autosave, formId + ':' + projectId, true);
  }

  /**
   * Save the state of the model of an form
   * @param formId Id of the form to autosave
   * @param projectId Id of the project
   * @param model Model to save
   */
  async setAutosave(formId: number, projectId: number, model: any): Promise<void> {
    await this.setWithId(Keys.Autosave, formId + ':' + projectId, model);
    await this.refreshAutosaveIds();
  }

  /**
   * Remove autosave state of an form
   * @param formId Id of the autosaved form
   * @param projectId Id of the project
   */
  async removeAutosave(formId: number, projectId: number): Promise<void> {
    await this.removeWithId(Keys.Autosave, formId + ':' + projectId);
    await this.refreshAutosaveIds();
  }

  /**
   * Get formId, projectId of the autosaved forms
   */
  async getAutosaveIds(): Promise<AutosavedForm[]> {
    const ids = await this.get(Keys.AutosaveIds);
    return this.fixAutosaveIds(ids);
  }

  /**
   * Refresh ids (formId, projectId) of all the autosaves in storage
   * @param keys (Optional) Keys of stored items in storage, if not given it will call `getKeys`
   */
  private async refreshAutosaveIds(keys?: string[]): Promise<void> {
    if (keys) {
      keys = this.filterKeys(keys, Keys.Autosave, true);
    }
    else {
      keys = await this.getKeys(Keys.Autosave, true);
    }
    const ids: AutosavedForm[] = [];
    for (const [formId, projectId] of keys.map(k => k.split(':'))) {
      if (formId && projectId) {
        const {value: autosave, timestamp} = await this.getAutosaveWithTimestamp(+formId, +projectId);
        if (autosave) {
          const vals = Object.values(autosave).filter(val => typeof val !== 'undefined' && val !== null && val !== '');
          if (vals.length > 0) {
            ids.push({formId: +formId, projectId: +projectId, timestamp: timestamp});
          }
        }
      }
    }
    await this.set(Keys.AutosaveIds, ids);
  }

  /**
   * Get log for an given date
   * @param date The date of the log
   */
  async getLog(date: string): Promise<LogEntry[]> {
    const log = await this.getWithId(Keys.Log, date);
    return log || [];
  }

  /**
   * Get the full log for all dates stored in descending order
   */
  async getFullLog(): Promise<LogEntry[]> {
    const regex = /^log:\d\d\d\d-\d\d-\d\d/;
    const keys = (await this._storage.keys()).filter(k => regex.test(k)).map(k => k.slice(4)).sort().reverse();
    let log: LogEntry[] = [];
    for (const key of keys) {
      const subLog = await this.getLog(key);
      log = log.concat(subLog);
    }
    return log;
  }

  /**
   * Store log for an given date
   * @param date The date of the log
   * @param log The log to store
   */
  setLog(date: string, log: LogEntry[]): Promise<any> {
    if (log.length > 0) {
      return this.setWithId(Keys.Log, date, log);
    }
  }

  /**
   * Remove log for an given date
   * @param date The date of the log to remove
   */
  removeLog(date: string): Promise<any> {
    return this.removeWithId(Keys.Log, date);
  }

  /**
   * Remove the full log for all dates stored
   */
  async removeFullLog(): Promise<void> {
    const regex = /log:\d\d\d\d-\d\d-\d\d/;
    const keys = (await this._storage.keys()).filter(k => regex.test(k)).map(k => k.slice(4));
    for (const key of keys) {
      this.removeLog(key);
    }
  }

  /**
   * Get the current API environment
   */
  getApiEnv(): Promise<ApiEnvironment> {
    return this.get(Keys.ApiEnv);
  }

  /**
   * Store the current API environment
   * @param env The environment to store
   */
  setApiEnv(env: ApiEnvironment): Promise<any> {
    return this.set(Keys.ApiEnv, env);
  }

  /**
   * Get the latest intro version shown
   */
  async getIntroVersion(): Promise<number> {
    const value = await this.get(Keys.IntroVersion);
    return value || 0;
  }

  /**
   * Set the latest intro version shown
   * @param value The value to set
   */
  setIntroVersion(value: number): Promise<any> {
    return this.set(Keys.IntroVersion, value);
  }

  /**
   * Get the last app version used before update
   */
  async getLastAppVersion(): Promise<string> {
    const value = await this.get(Keys.LastAppVersion);
    return value || '';
  }

  /**
   * Set the last app version used before update
   * @param value App version
   */
  setLastAppVersion(value: string): Promise<any> {
    return this.set(Keys.LastAppVersion, value);
  }

  /**
   * Get list of valid ion icons
   */
  async getIonIcons(): Promise<string[]> {
    const icons = await this.get(Keys.IonIcons);
    return icons || [];
  }

  /**
   * Set list of valid ion icons
   * @param icons The icons to store
   */
  setIonIcons(icons: string[]): Promise<any> {
    return this.set(Keys.IonIcons, icons);
  }

  /**
   * Set if the tooltips on Home-page has been shown or not
   * @param value The value to set
   */
  setHomeTooltips(value: boolean): Promise<any> {
    return this.set(Keys.HomeTooltips, value);
  }

  /**
   * Get if the Home-tooltips has been shown
   */
  async getHomeTooltip(): Promise<boolean> {
    const value = await this.get(Keys.HomeTooltips);
    return value || false;
  }

  /**
   * Get stored style data
   * @param url The url the style data is downloaded from
   */
  async getStyleData(url: string): Promise<StyleData[]> {
    const value = await this.getWithId(Keys.StyleData, url);
    return value || [];
  }

  /**
   * Set style data in storage
   * @param url The url the style data is downloaded from
   * @param data The style data to store
   */
  setStyleData(url: string, data: StyleData[], formId: number): Promise<any> {
    return this.setWithId(Keys.StyleData, url, data, formId);
  }

  /**
   * Get Api Error dictionary from storage
   */
  getApiErrors(): Promise<ApiErrorDict> {
    return this.get(Keys.ApiErrors);
  }

  /**
   * Store Api Error dictionary
   * @param errors The Api Error dictionary to store
   */
  setApiErrors(errors: ApiErrorDict): Promise<any> {
    return this.set(Keys.ApiErrors, errors);
  }

  /**
   * Get the formIds of the stored form copies
   */
  async getFormCopyFormIds(): Promise<number[]> {
    const keys = await this.getKeys(Keys.FormCopy, true);
    const ids: number[] = [];
    for (const [id] of keys.map(k => k.split(':'))) {
      const copies = await this.getAllFormCopies(+id);
      if (copies.length > 0) {
        ids.push(+id);
      }
    }
    return ids;
  }

  /**
   * Get an given copy with data of an form
   * @param formId The id of the form
   * @param copyId The id of the copy
   */
  async getFormCopyWithData(formId: number, copyId: number): Promise<FormCopyWithData> {
    const copies: FormCopyWithData[] = await this.getAllFormCopies(formId);
    return copies.find(copy => copy.id === copyId);
  }

  /**
   * Set form copy with data in storage
   * @param formId The id of the form
   * @param formCopy The copy
   */
  async setFormCopyWithData(formId: number, formCopy: FormCopyWithData): Promise<any> {
    const copies: FormCopyWithData[] = await this.getAllFormCopies(formId);
    const index = copies.findIndex(copy => copy.id === formCopy.id);
    if (index >= 0) {
      copies.splice(index, 1);
    }
    copies.unshift(formCopy);
    return this.setWithId(Keys.FormCopy, formId, copies);
  }

  /**
   * Remove an form copy with data from storage
   * @param formId The id of the form
   * @param copyId The id of the copy
   */
  async removeFormCopyWithData(formId: number, copyId: number): Promise<any> {
    let copies: FormCopyWithData[] = await this.getAllFormCopies(formId);
    copies = copies.filter(copy => copy.id !== copyId);
    return this.setWithId(Keys.FormCopy, formId, copies);
  }

  /**
   * Get all the copies (**without** data) of an form
   * @param formId The id of the form
   */
  async getFormCopies(formId: number): Promise<FormCopy[]> {
    const copies: FormCopyWithData[] = await this.getAllFormCopies(formId);
    return copies.map<FormCopy>(copy => ({id: copy.id, name: copy.name, lastEdit: copy.lastEdit}));
  }

  /**
   * Get all the copies (**with** data) of an form
   * @param formId The id of the form
   */
  private async getAllFormCopies(formId: number): Promise<FormCopyWithData[]> {
    const copies: FormCopyWithData[] = await this.getWithId(Keys.FormCopy, formId);
    if (copies) {
      return copies.map(copy => {
        if (typeof copy.lastEdit === 'string') {
          copy.lastEdit = new Date(copy.lastEdit);
        }
        return copy;
      });
    }
    else {
      return [];
    }
  }

  /**
   * Get copy id of the autosaved form copy
   * @param formId The id of the form
   */
  getAutosavedFormCopy(formId: number): Promise<number> {
    return this.getWithId(Keys.AutosavedFormCopy, formId);
  }

  /**
   * Set copy id of the autosaved form copy
   * @param formId The id of the form
   */
  setAutosavedFormCopy(formId: number, copyId: number): Promise<any> {
    return this.setWithId(Keys.AutosavedFormCopy, formId, copyId);
  }

  /**
   * Remove copy id of the autosaved form copy
   * @param formId The id of the form
   */
  removeAutosavedFormCopy(formId: number): Promise<any> {
    return this.removeWithId(Keys.AutosavedFormCopy, formId);
  }

  /**
   * Get data for Get-button
   * @param url The url for the button
   */
  getGetData(url: string): Promise<any> {
    return this.getWithId(Keys.GetData, url);
  }

  /**
   * Set data from Get-button
   * @param url The url for the button
   * @param data The data to store
   */
  setGetData(url: string, data: any): Promise<any> {
    return this.setWithId(Keys.GetData, url, data);
  }

  /**
   * Get Context with user data
   */
  getUserContext(): Promise<Context> {
    return this.get(Keys.UserContext);
  }

  /**
   * Set context containing user data
   * @param user The user context
   */
  setUserContext(user: Context): Promise<any> {
    return this.set(Keys.UserContext, user);
  }

  /**
   * Get when the last time internal API fail was warned
   */
  async getLastNoResultWarnTime(): Promise<Date> {
    let date: string | Date = await this.get(Keys.LastNoResultWarn);
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return date;
  }

  /**
   * Set when the last time internal API fail was warned
   * @param time The time to set
   */
  setLastNoResultWarnTime(time: Date): Promise<any> {
    return this.set(Keys.LastNoResultWarn, time);
  }

  /**
   * Get stored notifications
   */
  async getNotifications(): Promise<StoredNotification[]> {
    const notifications = await this.get(Keys.Notifications);
    return this.fixNotifiations(notifications);
  }

  /**
   * Store notifications
   * @param notifications The notifications to store
   */
  setNotifications(notifications: StoredNotification[]): Promise<any> {
    notifications = this.util.createCopyOfObject(notifications);
    while (notifications.length > environment.maxStoredNotifications) {
      notifications.pop();
    }
    return this.set(Keys.Notifications, notifications);
  }

  /**
   * Get stored firebase token
   */
  getFirebaseToken(): Promise<string> {
    return this.get(Keys.FirebaseToken);
  }

  /**
   * Store firebase token
   * @param token The token to store
   */
  setFirebaseToken(token: string): Promise<any> {
    return this.set(Keys.FirebaseToken, token);
  }

  /**
   * Get if the user is allowed to edit Home.
   */
  async getAllowHomeEdit(): Promise<boolean> {
    const allowEdit = await this.get(Keys.AllowHomeEdit);
    return allowEdit ?? true;
  }

  getAllowHomeEditWithoutDefault(): Promise<boolean> {
    return this.get(Keys.AllowHomeEdit);
  }

  /**
   * Store if the user is allowed to edit Home.
   * @param allowEdit If the user is allowed to edit home
   */
  setAllowHomeEdit(allowEdit: boolean): Promise<any> {
    return this.set(Keys.AllowHomeEdit, allowEdit);
  }

  /**
   * Get an value in storage with given key
   * @param key Key of the stored value
   */
  getWithKey(key: string): Promise<any> {
    return this.getWithId(Keys.None, key);
  }

  /**
   * Store an value with an given key
   * @param key Key to store the value by
   * @param value The value to store
   */
  setWithKey(key: string, value: any, formId?: number): Promise<any> {
    return this.setWithId(Keys.None, key, value, formId);
  }

  /**
   * Remove an value from storage with given key
   * @param key Key of the value to remove
   */
  removeWithKey(key: string): Promise<any> {
    return this.removeWithId(Keys.None, key);
  }

  /**
   * Clear the storage
   * @param except Don't clear keys that are included in this array
   * (default: `['theme', 'language', 'introVersion', 'lastAppVersion', 'homeBoxOrder', 'setupDate]`)
   */
  async clearStorage(except: string[] = [Keys.Theme, Keys.Language, Keys.IntroVersion,
     Keys.LastAppVersion, Keys.SetupDate]): Promise<void> {
    if (!except) except = [];

    try {
      this.tenant = '';
      this.username = '';
      const keys = await this.getKeys();
      if (keys.length === 0) {
        return;
      }
      for (const [key, ...parts] of keys.map(k => k.split(':'))) {
        if (!except.includes(key)) {
          this._storage.remove([key, ...parts].join(':'));
        }
      }
    }
    catch (err) {
      this.logError('Error clearing storage', err, false);
    }
  }

  /**
   * Internal method used for getting stored values
   * @param key Key of the stored item type
   * @param withTimestamp (Optional) If it should return the full value with timestamp, default: `false`
   */
  private async get(key: Keys, withTimestamp = false): Promise<any> {
    if (key === Keys.None) {
      return null;
    }
    await this.waitForCreate();
    try {
      let val: StorageValue = await this._storage.get(key);
      if (withTimestamp) {
        if (!val) {
          val = {
            value: null,
            timestamp: new Date(0)
          };
        }
        if (typeof val.timestamp === 'string') {
          val.timestamp = new Date(val.timestamp);
        }
        return this.util.createCopyOfObject(val);
      }
      else {
        return this.util.createCopyOfObject(val?.value);
      }
    }
    catch (err) {
      this.logError(`Error getting value of type ${key}`, err, false);
      return null;
    }
  }

  /**
   * Internal method used for storing values
   * @param key Key of the stored value type
   * @param value The value to store
   */
  private async set(key: Keys, value: any): Promise<any> {
    if (key === Keys.None) {
      return Promise.resolve(null);
    }
    await this.waitForCreate();
    const val: StorageValue = {
      value: this.util.createCopyOfObject(value),
      timestamp: new Date()
    };
    try {
      const retVal = await this._storage.set(key, val);
      return retVal;
    }
    catch (err) {
      this.logError(`Error setting value of type ${key}`, err, true);
      return null;
    }
  }

  /**
   * Internal method for removing stored values
   * @param key Key of the stored item type
   */
  private async remove(key: Keys): Promise<any> {
    await this.waitForCreate();
    try {
      const retVal = await this._storage.remove(key);
      return retVal;
    }
    catch (err) {
      this.logError(`Error removing value of type ${key}`, err, false);
      return null;
    }
  }

  /**
   * Internal method used for getting stored values with an id
   * @param key Key of the stored value type
   * @param id Id of the stored value
   * @param withTimestamp (Optional) If it should return the full value with timestamp, default: `false`
   */
  private async getWithId(key: Keys, id: number | string, withTimestamp = false): Promise<any> {
    await this.waitForCreate();
    try {
      let val: StorageValue;
      if (key === Keys.None) {
        id = id.toString();
        val = await this._storage.get(id);
      }
      else {
        val = await this._storage.get(key + ':' + id);
      }
      if (withTimestamp) {
        if (!val) {
          val = {
            value: null,
            timestamp: new Date(0)
          };
        }
        if (typeof val.timestamp === 'string') {
          val.timestamp = new Date(val.timestamp);
        }
        return this.util.createCopyOfObject(val);
      }
      else {
        return this.util.createCopyOfObject(val?.value);
      }
    }
    catch (err) {
      this.logError(`Error getting value of type ${key} with id ${id}`, err, false);
      return null;
    }
  }

  /**
   * Internal method used for storing values with an id
   * @param key Key of the stored value type
   * @param id Id of the stored value
   * @param value The value to store
   */
  private async setWithId(key: Keys, id: number | string, value: any, formId?: number): Promise<any> {
    await this.waitForCreate();

    const val: StorageValue = {
      value: this.util.createCopyOfObject(value),
      timestamp: new Date()
    };
    if (formId) {
      val.formId = formId;
    }
    try {
      let retVal: any;
      if (key === Keys.None) {
        id = id.toString();
        retVal = await this._storage.set(id, val);
      }
      else {
        retVal = await this._storage.set(key + ':' + id, val);
      }
      return retVal;
    }
    catch (err) {
      this.logError(`Error setting value of type ${key} with id ${id}`, err, true);
      return null;
    }
  }


  /**
   * Internal method for removing stored values
   * @param key Key of the stored item type
   * @param id Id of the stored value
   */
  private async removeWithId(key: Keys, id: number | string): Promise<any> {
    await this.waitForCreate();
    try {
      let retVal: any;
      if (key === Keys.None) {
        id = id.toString();
        retVal = await this._storage.remove(id);
      }
      else {
        retVal = await this._storage.remove(key + ':' + id);
      }
      return retVal;
    }
    catch (err) {
      this.logError(`Error removing value of type ${id}`, err, false);
      return null;
    }
  }

  /**
   * Clear old values from storage
   * @param except (Optional) Don't clear these keys, default:
   *
   * `['user', 'offlineRegs', 'introVersion', 'theme', 'language', 'lastAppVersion', 'setupDate', 'keepForms',
   *   'favoriteFormlist, 'formView', 'homeBoxOrder', 'forms', 'favoriteForms', 'lastUsedForms']`
   */
  async clearOld(
    except: string[] = [Keys.User, Keys.OfflineRegs, Keys.IntroVersion, Keys.Theme, Keys.Language, Keys.LastAppVersion, Keys.SetupDate, Keys.KeepForms,
                        Keys.FavoriteFormList, Keys.FormView, Keys.HomeBoxOrder, Keys.Forms, Keys.FavoriteForms, Keys.LastUsedForms]
  ) {
    if (!except) {
      except = [];
    }
    const keys = await this.getKeys();
    const keepForms = await this.getKeepFormIds();
    if (keys.length === 0) {
      return;
    }
    for (const [key, id, ...parts] of keys.map(k => k.split(':'))) {
      if (!except.includes(key)) {
        if (key === Keys.Form && keepForms.includes(+id)) {
          continue;
        }
        const fullKey = id ? [key, id, ...parts].join(':') : key;
        let val: StorageValue;
        try {
          val = await this._storage.get(fullKey);
        }
        catch (err) {
          this.logError(`Error getting old value with key ${key}`, err, false);
        }
        if (val) {
          if (keepForms.includes(val?.formId)) {
            continue;
          }

          if (!val.timestamp) {
            try {
              await this._storage.remove(fullKey);
            }
            catch (err) {
              this.logError(`Error removing old value with key ${key}`, err, false);
            }
          }
          else {
            if (typeof val.timestamp === 'string') {
              val.timestamp = new Date(val.timestamp);
            }
            const diff = moment().diff(val.timestamp);
            if (moment.duration(diff).asDays() > environment.maxStorageAge) {
              try {
                await this._storage.remove(fullKey);
              }
              catch (err) {
                this.logError(`Error removing old value with key ${key}`, err, false);
              }
            }
          }
        }
      }
    }
    await Promise.all([
      this.refreshStoredFormIds(keys),
      this.refreshStoredRegIds(keys),
      this.refreshAutosaveIds(keys),
      this.refreshFieldCount(keys)
    ]);
  }

  /**
   * Get all the (full) keys of the stored items
   */
  private async getKeys(key?: Keys, removeFirstPart = false): Promise<string[]> {
    await this.waitForCreate();
    let keys: string[];
    try {
      keys = await this._storage.keys();
      if (!Array.isArray(keys)) {
        keys = [];
      }
    }
    catch (err) {
      this.logError('Error getting keys from storage', err, false);
      keys = [];
    }
    if (key) {
      keys = this.filterKeys(keys, key, removeFirstPart);
    }
    return keys;
  }

  /**
   * Fix signedAt property of registrations. Will also give empty array for falsy values
   * @param history The registrations
   */
  private fixHistory(history: OldReg[]): OldReg[] {
    if (history?.length > 0) {
      return history.map(reg => {
        if (typeof reg.signedAt === 'string') {
          reg.signedAt = new Date(reg.signedAt);
        }
        return reg;
      });
    }
    else {
      return [];
    }
  }

  /**
   * Fix store date of forms. Will also give empty array for falsy values
   * @param forms The forms
   */
  private fixForms(forms: Form[]): Form[] {
    if (forms?.length > 0) {
      return forms.map(form => {
        if (typeof form.storeDate === 'string') {
          form.storeDate = new Date(form.storeDate);
        }
        return form;
      });
    }
    else {
      return [];
    }
  }

  /**
   * Fix store date of form with values. Will also give empty array for falsy values
   * @param form The form
   */
  private fixFormWithValues(form: FormWithValues): FormWithValues {
    if (typeof form?.theForm?.storeDate === 'string') {
      form.theForm.storeDate = new Date(form.theForm.storeDate);
    }
    return form;
  }

  /**
   * Fix signedAt property of registration. Will also give empty array for falsy values
   * @param reg The registration
   */
  private fixRegistration(reg: OldReg): OldReg {
    if (typeof reg?.signedAt === 'string') {
      reg.signedAt = new Date(reg.signedAt);
    }
    return reg;
  }

  /**
   * Sort projects
   * @param projects The projects to sort
   * @param sortBy What to sort the projects by (name, number)
   */
  private sortProjects(projects: Project[], sortBy: SortBy) {
    if (!projects || projects.length === 0) {
      return [];
    }
    else if (sortBy === SortBy.NoSort) {
      return projects;
    }
    else {
      return projects.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : (b[sortBy] > a[sortBy]) ? -1 : 0);
    }
  }

  /**
   * Fix received time of notifications. Will also return empty array for falsy values
   * @param notifications The notifications to fix
   */
  private fixNotifiations(notifications: StoredNotification[]): StoredNotification[] {
    if (notifications?.length > 0) {
      return notifications.map(n => {
        if (typeof n.received === 'string') {
          n.received = new Date(n.received);
        }
        return n;
      });
    }
    else {
      return [];
    }
  }

  fixAutosaveIds(ids: AutosavedForm[]): AutosavedForm[] {
    if (ids?.length > 0) {
      return ids.map(id => {
        if (typeof id.timestamp === 'string') {
          id.timestamp = new Date(id.timestamp);
        }
        return id;
      });
    }
    else {
      return [];
    }
  }

  /**
   * Log error to console and optional as a popup to user
   * @param message The message to log
   * @param error The error to log
   * @param showPopup If it should show popup to user with error
   */
  private logError(message: string, error: any, showPopup: boolean) {
    this.logger.error(message, error);
    if (showPopup) {
      this.popup.showMessage('StorageError', true, 'danger', 3000);
    }
  }

  private waitForCreate(): Promise<void> {
    if (this.finishedCreating.value) {
      return Promise.resolve();
    }
    else {
      return new Promise<void>(resolve => {
        const sub = this.finishedCreating.subscribe(val => {
          if (val) {
            sub.unsubscribe();
            resolve();
          }
        });
      });
    }
  }

  /**
   * Filter keys
   * @param keys The keys to filter
   * @param key The key to filter by
   * @param removeFirstPart If the first part, e.g. the `Keys` part, should be removed
   */
  private filterKeys(keys: string[], key: Keys, removeFirstPart: boolean) {
    keys = keys.filter(k => k.split(':')[0] === key);
    if (removeFirstPart) {
      keys = keys.map(k => k.split(':').slice(1).join(':'));
    }
    return keys;
  }

  private async addFavsAndFV(list: BoxStatus[], projectId: number): Promise<BoxStatus[]> {
    if (!Array.isArray(list) || list.length === 0) {
      list = this.util.createCopyOfObject(environment.defaultBoxOrder);
    }
    for (const box of list) {
      if (box.id === 'favorite' && (!Array.isArray(box.formIds) || box.formIds.length === 0)) {
        const favs: number[] = await this.getWithId(Keys.FavoriteFormList, projectId);
        this.removeWithId(Keys.FavoriteFormList, projectId);
        if (Array.isArray(favs)) {
          box.formIds = favs;
        }
      }
      else if (box.id === 'formview' && (!Array.isArray(box.formIds) || box.formIds.length === 0)) {
        const project = await this.getProjectFromId(projectId);
        if (project) {
          const fv: number = await this.getWithId(Keys.FormView, project.number);
          this.removeWithId(Keys.FormView, project.number);
          if (fv) {
            box.formIds = [fv];
          }
        }
      }
    }
    return list;
  }

  private async fixOldHomeOrder(list: BoxStatus[], projectId: number): Promise<BoxStatus[]> {
    if (Array.isArray(list) && list.length > 0) {
      return list;
    }
    else {
      list = await this.get(Keys.HomeBoxOrder);
      list = await this.addFavsAndFV(list, projectId);
      this.remove(Keys.HomeBoxOrder);
      if (list.length > 0) {
        this.setHomeBoxOrder(projectId, list);
      }
      return list;
    }
  }
}
