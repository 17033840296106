/**
 * Different actions that can be done in FormPage
 */
export enum FormAction {
    Submit = 'submit',
    Exit = 'exit',
    TmpStore = 'tmpStore',
    ChangedFavOrFV = 'changedFavOrFV',
    ClearForm = 'clearForm',
    NotSet = 'notSet'
}

