import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { InLoggerService } from './in-logger.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StatisticsService } from './statistics.service';
import { InStorageService } from './in-storage.service';
import { ApiErrorDict, ApiType, ApiError, ResultError, FirebaseEvent } from '../models/models';
import { isExternalApi } from '../helpers/helperFunctions';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { StateService } from './state.service';
import { OnlineService } from './online.service';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorLogService {
  errors: ApiErrorDict = {};
  lastNoResultWarning: Date;

  constructor(
    private logger: NGXLogger,
    private inLogger: InLoggerService,
    private statistics: StatisticsService,
    private storage: InStorageService,
    private state: StateService,
    private online: OnlineService
  ) {
    this.storage.getApiErrors().then(errors => {
      if (errors) {
        this.errors = errors;
      }
    });
    this.storage.getLastNoResultWarnTime().then(time => this.lastNoResultWarning = time);
  }

  /**
   * Log empty (but no error) result from internal API to console and file
   * @param location The location in the code that caused the error
   * @param message The message to log
   * @param error The error to log
   * @param logData (Optional) Additional data to log to file
   */
  logNoResult(location: string, message: string, error: ResultError, logData?: any) {
    if (error) {
      if (error.message === 'No user logged in!') {
        this.logger.error(message, location, error);
      }
      else {
        let logMessage = `${location}\nBackend error!\n${error.message}\n${message}`;
        if (logData) {
          logMessage += `\n${JSON.stringify(logData)}`;
        }
        if (this.lastNoResultWarning) {
          const diff = moment().diff(this.lastNoResultWarning);
          if (moment.duration(diff).asHours() > environment.noResultWarningFrequency) {
            this.logger.fatal(logMessage);
            this.lastNoResultWarning = new Date();
            this.storage.setLastNoResultWarnTime(this.lastNoResultWarning);
          }
          else {
            this.logger.error(message, location, error);
          }
        }
        else {
          this.logger.fatal(logMessage);
          this.lastNoResultWarning = new Date();
          this.storage.setLastNoResultWarnTime(this.lastNoResultWarning);
        }
      }
    }
    else {
      this.logger.error(message, location, logData?.result);
    }
    this.inLogger.error(message, error, {data: logData, location: location});
  }

  /**
   * Log http error result from internal API to console and file (and optional Firebase)
   * @param location The location in the code that caused the error
   * @param message The message to log
   * @param error The error to log
   * @param endpointForFirebase (Optional) What to set as endpoint for Firebase logging
   * @param logData (Optional) Additional data to log to file
   */
  logInternalApiFail(location: string, message: string, error: HttpErrorResponse, endpointForFirebase?: string, logData?: any) {
    if (error.status === 0 && !this.online.hasNetwork()) {
      return;
    }
    this.logger.error(message, location, error);
    this.inLogger.error(message, error, {data: logData, location: location});
    if (endpointForFirebase) {
      this.statistics.logEvent(FirebaseEvent.ApiFail, {endpoint: endpointForFirebase,
                                            status: error.status,
                                            message: error.message,
                                            currState: this.state.state,
                                            prevState: this.state.prevState
                                          });
    }
  }

  /**
   * Log http error result from external API to console and file. Will send warning if number of
   * failures on a specific url passes an treshold
   * @param location The location in the code that caused the error
   * @param url The url of the call
   * @param message The message to log
   * @param error The error to log
   * @param type The type of API, e.g. InApir, Azure, External
   * @param logData (Optional) Additional data to log to file
   */
  async logExternalApiFail(location: string, url: string, message: string, error: HttpErrorResponse, type: ApiType, logData?: any) {
    if (error.status === 0 && !this.online.hasNetwork()) {
      return;
    }
    if (type === ApiType.External) {
      if (url.startsWith('https://inapir.azurewebsites.net/api/')) {
        type = ApiType.ExternalInApir;
      }
      else if (url.startsWith('https://infeaturescode.azurewebsites.net/api/')) {
        type = ApiType.ExternalAzure;
      }
    }
    this.logger.error(message, location, error);
    this.inLogger.error(message, error, {data: logData, location: location});
    // eslint-disable-next-line prefer-const
    let [endpoint, query] = url.split('?');
    if (!query) {
      query = 'none';
    }
    query = query.replace(/ansatt=[^\&]+/g, 'ansatt=*****');
    if (this.errors[endpoint]) {
      if (this.errors[endpoint].querys[query]) {
        this.errors[endpoint].querys[query]++;
      }
      else {
        this.errors[endpoint].querys[query] = 1;
      }
      if (!this.errors[endpoint].status.includes(error.status)) {
        this.errors[endpoint].status.push(error.status);
      }
    }
    else {
      this.errors[endpoint] = {
        querys: {[query]: 1},
        type: type,
        status: [error.status]
      };
    }

    this.checkAndSendWarning(this.errors[endpoint], endpoint, location);
    this.storage.setApiErrors(this.errors);
  }

  /**
   * Checks the amount of fails on a url and sends warning (using NGXLoggger.fatal) if it's over the treshold
   * @param error The error
   * @param url The url that caused error
   * @param location The location in the code that caused the error
   */
  checkAndSendWarning(error: ApiError, url: string, location: string) {
    const maxFails = Math.max(...Object.values(error.querys));
    if (maxFails < environment.maxExternalApiFails) {
      return;
    }
    else if (error.lastWarned) {
      const dateDiff = moment().diff(error.lastWarned);
      if (moment.duration(dateDiff).asHours() < environment.externalWarningFrequency) {
        return;
      }
    }

    error.lastWarned = new Date();
    this.logger.error(`Max fails (${maxFails}) reached on ${url} (${error.type})`);

    const form = this.state.currentForm;
    const querys = Object.entries(error.querys).map(([query, count]) => `${query}: ${count}`).join('\n\t');
    if (form && isExternalApi(error.type)) {
      this.logger.fatal(`${location}\nAPI fails (${error.status.join(', ')}) in form ${form.name} (${form.id}): ${url}\n\t${querys}`);
    }
    else {
      this.logger.fatal(`${location}\nAPI fails: ${url}\n\t${querys}`);
    }

    error.querys = {};
  }

  /**
   * Reset failure count of an API call when it succeeds
   * @param url The url of the API call
   */
  externalSuccess(url: string) {
    url = url.split('?')[0];
    if (this.errors[url]) {
      delete this.errors[url];
      this.storage.setApiErrors(this.errors);
    }
  }
}
