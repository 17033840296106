<ion-header>
  <ion-toolbar color="header">
    <ion-title>{{'ChangePassword' | translate}}</ion-title>
    <ion-button fill="clear" slot="end" (click)="close()">
      <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="in-background">
  <ion-card>
      <form [formGroup]="passwordForm" (ngSubmit)="changePassword()">
    <ion-list>
      <ion-item>
        <ion-label position="stacked">{{'CurrentPassword' | translate}}</ion-label>
        <ion-input type="password" formControlName="currentPassword" [autofocus]="true"></ion-input>
      </ion-item>
      <ion-item lines="none" *ngIf="currentPassword.invalid && (currentPassword.dirty || currentPassword.touched)">
        <ion-list>
          <ion-item lines="none" *ngIf="currentPassword.errors['required']">
            <ion-text color="danger"><small>{{'PasswordRequired' | translate}}</small></ion-text>
          </ion-item>
        </ion-list>
      </ion-item> 
      <ion-item>
        <ion-label position="stacked">{{'NewPassword' | translate}}</ion-label>
        <ion-input type="password" formControlName="newPassword" ></ion-input>
      </ion-item>
      <ion-item lines="none" *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
        <ion-list>
          <ion-item lines="none" *ngIf="newPassword.errors['required']">
            <ion-text color="danger"><small>{{'PasswordRequired' | translate}}</small></ion-text>
          </ion-item>
          <ion-item lines="none" *ngIf="newPassword.errors['minlength']">
            <ion-text color="danger"><small>{{'ToShortPassword' | translate}}</small></ion-text>
          </ion-item>
          <ion-item lines="none" *ngIf="newPassword.errors['maxlength']">
            <ion-text color="danger"><small>{{'ToLongPassword' | translate}}</small></ion-text>
          </ion-item>
        </ion-list>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{'NewPasswordRepeat' | translate}}</ion-label>
        <ion-input type="password" formControlName="newPasswordRepeat"></ion-input>
      </ion-item>
      <ion-item lines="none" *ngIf="newPasswordRepeat.invalid && (newPasswordRepeat.dirty || newPasswordRepeat.touched)">
        <ion-list>
          <ion-item lines="none" *ngIf="newPasswordRepeat.errors['required']">
            <ion-text color="danger"><small>{{'ConfirmPasswordRequired' | translate}}</small></ion-text>
          </ion-item>
          <ion-item lines="none" *ngIf="newPasswordRepeat.errors['mustMatch']">
            <ion-text color="danger"><small>{{'PasswordMismatch' | translate}}</small></ion-text>
          </ion-item>
        </ion-list>
      </ion-item>
      <ion-button color="secondary" type="submit" class="submitBtn" expand="block" [disabled]="!passwordForm.valid">{{'Save' | translate}}</ion-button>
    </ion-list>
  </form>

</ion-card>

</ion-content>