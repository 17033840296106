import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { FormType, Trigger, TriggerType } from 'src/app/models/models';
import { FieldWrapper } from '@ngx-formly/core';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-wrapper-open-select',
  templateUrl: './open-select.component.html',
  styleUrls: ['./open-select.component.scss'],
})
export class OpenSelectComponent extends FieldWrapper implements OnInit, AfterViewInit, Trigger, OnDestroy {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;
  valueSub: Subscription;
  resizeSub: Subscription;

  constructor(
    private ref: ComponentRefService,
    private elm: ElementRef,
    private dom: DomOperationsService,
    private logger: NGXLogger,
    private plt: Platform
  ) {
    super();
  }

  get shouldNotCheckOverflow(): boolean {
    return this.to.notChangePosition || this.to.labelPosition === 'stacked' || this.to.labelPosition === 'floating';
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
  }

  async ngAfterViewInit() {
    const res = await this.checkLabel(100);
    if (!res) {
      this.valueSub = this.formControl.valueChanges.subscribe(async () => {
        const newRes = await this.checkLabel(0);
        if (newRes) {
          this.valueSub.unsubscribe();
          this.resizeSub?.unsubscribe();
        }
      });
      this.resizeSub = this.plt.resize.subscribe(async () => {
        const newRes = await this.checkLabel(0);
        if (newRes) {
          this.resizeSub.unsubscribe();
          this.valueSub?.unsubscribe();
        }
      });
    }
  }

  ngOnDestroy() {
    this.resizeSub?.unsubscribe();
    this.valueSub?.unsubscribe();
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Focus) {
      const select: HTMLIonSelectElement = this.elm.nativeElement.querySelector('ion-select');
      this.dom.scrollIntoViewAndExecute(select, () => select.open());
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  async checkLabel(waitTime: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      setTimeout(() => {
        if (this.shouldNotCheckOverflow) {
          resolve(true);
          return;
        }
        const formlyElement = this.dom.findFormlyFieldElement(this.elm.nativeElement);
        const label: HTMLIonLabelElement = formlyElement?.querySelector('ion-label');
        const select: HTMLIonSelectElement = formlyElement?.querySelector('ion-select');
        const text: HTMLDivElement = select?.shadowRoot?.querySelector('div.select-text');
        if (!label || !text) {
          resolve(false);
        }
        else if (this.dom.isOverflowing(label) || this.dom.isOverflowing(text, 5)) {
          this.to.labelPosition = environment.largeLabelPosition as 'stacked' | 'floating';
          select.style.width = '100%';
          select.style.maxWidth = '100%';
          text.style.width = '100%';
          resolve(true);
        }
        else {
          resolve(false);
        }
      }, waitTime);
    });
  }
}
