<ion-header>
  <ion-toolbar color="header">
    <ion-title class="ion-text-center">{{reg.formName}}</ion-title>
    <ion-button fill="clear" slot="end" (click)="close()">
      <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="in-background">
  <div *ngIf="foundReg && reg.signedAt" class="banner banner-info">
    <b>{{'RegTime' | translate}}:</b> {{reg.signedAt | date:'HH:mm dd.MM.yy'}}
  </div>
  <ion-grid>
    <ion-row class="ion-justify-content-center" *ngIf="loading">
      <ion-col size="1">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <form [formGroup]="formGroup" *ngIf="foundReg" class="disabled" (click)="disabled($event)">
    <ion-card class="formCard">
      <formly-form [form]="formGroup" [fields]="fields" [model]="model"></formly-form>
    </ion-card>
  </form>
</ion-content>