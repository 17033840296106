export enum FirebaseEvent {
    NavigatedForm = 'navigated_form',
    NavigatedForms = 'navigated_forms',
    NavigatedReg = 'navigated_reg',
    NavigatedHistory = 'navigated_history',
    NavigatedPreview = 'navigated_preview',
    NavigatedHome = 'navigated_home',
    NavigatedIntro = 'navigated_intro',
    FormViewReg = 'formview_reg',
    D4GoSetTime = 'D4GoSettime',
    D4GoSetDate = 'D4GoSetdate',
    D4GoOpenLastReg = 'D4Go_OpenLastReg',
    D4GoSubmitStart = 'D4Go_Submit_startBtn',
    D4GoSubmitEnd = 'D4Go_Submit_endBtn',
    ChangeLanguage = 'change_lang',
    ChangeTheme = 'change_theme',
    Offline = 'went_offline',
    Online = 'went_online',
    OpenForm = 'opened_form',
    CloseForm = 'closed_form',
    FinishForm = 'finished_form',
    TmpStore = 'tmp_stored',
    OpenReg = 'opened_reg',
    CloseReg = 'closed_reg',
    HomeOrder = 'home_order',
    IntroStart = 'tutorial_begin',
    IntroComplete = 'tutorial_complete',
    ApiFail = 'api_fail',
    Login = 'login',
    FavoritesUpdate = 'favorites_updated',
    FormViewSet = 'formview_set',
    WrongIcon = 'wrong_icon',
    Print = 'print',
    SwipeBack = 'swipe_back'
}
