import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { switchMap, takeUntil, pairwise, map } from 'rxjs/operators';
import { PopoverController, Platform, ModalController } from '@ionic/angular';
import { ColorPopoverComponent } from 'src/app/custom-components/directives/formly-directives/color-popover/color-popover.component';
import { NGXLogger } from 'ngx-logger';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { TranslateService } from '@ngx-translate/core';
import { ShapesPopoverComponent } from 'src/app/custom-components/directives/formly-directives/shapes-popover/shapes-popover.component';
import { TextPopoverComponent } from 'src/app/custom-components/directives/formly-directives/text-popover/text-popover.component';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/services/utility.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { FieldService } from 'src/app/services/field.service';
import { PopupService } from 'src/app/services/popup.service';
import * as pdfjsLib from 'pdfjs-dist';
import { Trigger, Shape, TriggerType, IonColor, FormType } from 'src/app/models/models';
import { SelectPictureComponent } from 'src/app/custom-components/directives/formly-directives/select-picture/select-picture.component';
import { DeviceService } from 'src/app/services/device.service';
import { CameraService } from 'src/app/services/camera.service';
import { StateService } from 'src/app/services/state.service';

/**
 * Set like this to be enable to mock pdfjs in tests
 */
export const paintDeps = {
  pdfjsLib
};

enum PaintState {
  None = 'none',
  Draw = 'draw',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Shape = 'shape',
  Text = 'text'
}

@Component({
  selector: 'app-field-paint',
  templateUrl: './paint.component.html',
  styleUrls: ['./paint.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class PaintComponent extends FieldType implements AfterViewInit, OnInit, Trigger, OnDestroy {
  @ViewChild('canvas') canvas: ElementRef;
  ctx: CanvasRenderingContext2D;
  canvasEl: HTMLCanvasElement;
  drawpoints: string[] = [];
  drawIndex = -1;
  isDrawing = false;
  orgSize: {width: number, height: number};
  hideResetBtn = false;
  state = PaintState.None;
  pickedColor: string;
  pickedShape: {shape: Shape,  size: number};
  shapeImgObj: any;
  offset: { xScale: number, yScale: number, left: number, top: number};
  errorMsg = '';
  bgError = false;
  textSize = 30;
  brushSize = 3;
  highlightBrush = false;
  resizeSubscription: Subscription;

  drawEvent$: Observable<[Event, Event]>;
  endEvents$: Observable<Event>[] = [];
  clickEvent$: Observable<Event>;
  subscriptions: Subscription[] = [];

  private _getVarsDisabled = false;

  constructor(
    private popCtrl: PopoverController,
    private logger: NGXLogger,
    private plt: Platform,
    private watch: WatchgroupService,
    private translate: TranslateService,
    private util: UtilityService,
    private ref: ComponentRefService,
    private fieldService: FieldService,
    private popup: PopupService,
    private device: DeviceService,
    private modalCtrl: ModalController,
    private cameraService: CameraService,
    private stateService: StateService
  ) {
    super();
    this.fieldService.setField(this);
    paintDeps.pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdfjs/pdf.worker.min.js';
  }

  get paddingOffset(): number {
    if (this.to.currentForm.type === FormType.FormView) {
      return 80;
    }
    else {
      return 130;
    }
  }

  get label(): string {
    return this.to.label || '';
  }

  get photo(): boolean {
    if (this.url) {
      return false;
    }
    else if (typeof this.to.photo === 'boolean') {
      return this.to.photo;
    }
    else {
      return false;
    }
  }

  get disablePhoto(): boolean {
    return !this.isDevice && !this.isMobile;
  }

  get gallery(): boolean {
    if (this.url) {
      return false;
    }
    else if (typeof this.to.allowGallery === 'boolean') {
      return this.to.allowGallery;
    }
    else {
      return false;
    }
  }

  get color(): string {
    return this.to.color || '#000';
  }

  get pickColor(): boolean {
    return this.to.pickcolor || false;
  }

  get url(): string {
    return this.to.image || '';
  }

  get isDevice(): boolean {
    return this.device.isDevice();
  }

  get isMobile(): boolean {
    return this.device.isMobile();
  }

  get disabled(): boolean {
    return this.to.disabled || false;
  }

  get hideButtons(): boolean {
    return this._getVarsDisabled || this.to.disabled || false;
  }

  get text(): boolean {
    return this.to.text || false;
  }

  get shapeUrl(): string {
    if (this.to.shapes && this.to.shapes.url) {
      return this.to.shapes.url;
    }
    else {
      return '';
    }
  }

  get shapeList(): Shape[] {
    if (this.to.shapes && Array.isArray(this.to.shapes.list)) {
      return this.to.shapes.list;
    }
    else {
      return [];
    }
  }

  get pdf(): boolean {
    return this.to.pdf || false;
  }

  get pdfScale(): number {
    if (this.to.pdfScale) {
      try {
        return parseFloat(this.to.pdfScale);
      }
      catch {
        return 1;
      }
    }
    else {
      return 1;
    }
  }

  get useCredentials(): boolean {
    return this.to.useCredentials || false;
  }

  get saveFirst(): boolean {
    return this.to.saveFirst || false;
  }

  get drawingOff(): boolean {
    return !this.bgError && this.state === PaintState.None;
  }

  get brushBtnColor(): IonColor {
    if (this.state === PaintState.Draw) {
      return IonColor.Success;
    }
    else if (this.highlightBrush) {
      return IonColor.Warning;
    }
    else {
      return IonColor.Dark;
    }
  }

  get shapeBtnColor(): IonColor {
    return this.state === PaintState.Shape ? IonColor.Success : IonColor.Dark;
  }

  get textBtnColor(): IonColor {
    return this.state === PaintState.Text ? IonColor.Success : IonColor.Dark;
  }

  get canvasWidth(): number {
    return this.to.width || this.orgSize.width;
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.stateService.getFields(this.formType, this.formId);
  }

  public static getHtml(config: FormlyFieldConfig, value: string) {
    const label = config.templateOptions.label ?? '';
    if (value) {
      return `<ion-card><ion-list>
                <ion-item lines="none">
                  <ion-label>${label}</ion-label>
                </ion-item>
                <img src="${value}" style="max-width: 500px; border: 1px solid black; margin-left: 10px;"/>
              </ion-list></ion-card>`;
    }
    else {
      return `<ion-card><ion-list>
                <ion-item lines="none">
                  <ion-label>${label}</ion-label>
                </ion-item>
              </ion-list></ion-card>`;
    }
  }

  ngOnInit() {
    // Load shape image
    if (this.shapeUrl) {
      const image = new Image();
      image.onload = () => {
        this.shapeImgObj = image;
      };
      image.onerror = () => {
        this.bgError = true;
      };
      image.setAttribute('crossorigin', 'anonymous');
      image.src = this.shapeUrl;
    }

    this.errorMsg = this.to.errorMsg || this.translate.instant('PaintError');
  }

  async ngAfterViewInit() {
    const that = this;
    setTimeout(() => that.hideResetBtn = that.disabled);
    this.canvasEl = this.canvas.nativeElement;
    this.ctx = this.canvasEl.getContext('2d');
    this.pickedColor = this.color;

    await this.setAndDrawCanvas(true);
    this.resizeSubscription = this.plt.resize.subscribe(() => {
      this.setAndDrawCanvas(false);
    });
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length >0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => {
        this.clearCanvas();
      });
    }
    this.setReference(this.key as string, this.formId, this.formType);
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  /**
   * If an button should be disabled
   * @param name The name of the button
   */
  disableButton(name: 'undo' | 'redo' | 'reset' | 'camera'): boolean {
    switch(name) {
      case 'camera':
        return !this.isDevice && !this.isMobile;
      case 'undo':
        return this.drawIndex < 0;
      case 'redo':
        return this.drawIndex >= this.drawpoints.length - 1;
      case 'reset':
        return !this.model[this.key as string | number];
      default:
        return false;
    }
  }

  /**
   * Set reference to this component
   */
  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  /**
   * Let external component trigger drawImage with image data
   * @param data Data with image data in model and edit: true if it's from getVars
   */
  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Edit) {
      this.drawImage(data);
      setTimeout(() => {
        this._getVarsDisabled = true;
        this.hideResetBtn = false;
        this.isDrawing = false;
        const image = this.canvasEl.toDataURL();
        this.fieldService.setValue(image);
      });
    }
    else if (type === TriggerType.Set) {
      this.drawImage(data);
      this.addDrawpoint();
    }
    else if (type === TriggerType.Clear) {
      this.reset(true);
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  async setAndDrawCanvas(pushDrawpoint: boolean) {
    const scale = 1;
    const form = document.querySelectorAll('form');

    const contentBound = form[form.length - 1].getBoundingClientRect();
    this.orgSize = {
      width: Math.round((contentBound.width - this.paddingOffset) * scale),
      height: Math.round(window.innerHeight * 0.7)
    };
    this.pickedColor = this.color;
    this.setCanvas(this.orgSize.width, this.orgSize.height);

    this.createEvents(this.canvasEl);

    if (this.model[this.key as string | number]) {
      await this.drawImage(this.model[this.key as string | number]);
      if (pushDrawpoint) {
        setTimeout(() => {
          this.drawpoints.push(this.model[this.key as string | number]);
          this.drawIndex++;
        });
      }
    }
    else if (this.url) {
      const url = this.util.parseText(this.url, this.model, this.fields);
      if (this.pdf) {
        await this.loadPdf(url);
      }
      else {
        await this.drawImage(url, this.saveFirst);
      }
    }
  }

  /**
   * Higlight brush button if canvas is clicked when it's "disabled"
   */
  canvasClicked() {
    if (this.drawingOff && !this.highlightBrush) {
      this.popup.showMessage('DrawingOff', true, 'warning');
      this.highlightBrush = true;
      setTimeout(() => {
        this.highlightBrush = false;
      }, 4000);
    }
  }

  /**
   * Load pdf from url and set to canvas
   * @param url Url to pdf
   */
  async loadPdf(url: string) {
    this.bgError = false;
    try {
      const pdf = await paintDeps.pdfjsLib.getDocument({url: url, withCredentials: this.useCredentials}).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: this.pdfScale});
      this.canvasEl.height = viewport.height;
      this.canvasEl.width = viewport.width;
      this.canvasEl.style.width = '100%';
      this.canvasEl.style.height = '100%';

      const renderCtx = {
        canvasContext: this.ctx,
        viewport: viewport
      };
      await page.render(renderCtx).promise;
      this.ctx.lineWidth = this.brushSize * this.pdfScale;
      this.ctx.lineCap = 'round';
      this.ctx.strokeStyle = this.pickedColor;
      this.ctx.fillStyle = this.pickedColor;
      if (this.saveFirst) {
        this.fieldService.setValue(this.canvasEl.toDataURL(), false);
      }
    }
    catch {
      this.bgError = true;
    }
  }

  /**
   * Resize canvas and set color
   */
  setCanvas(width: number, height: number) {
    this.canvasEl.width = width;
    this.canvasEl.height = height;
    this.ctx.lineWidth = this.brushSize;
    this.ctx.lineCap = 'round';
    this.ctx.strokeStyle = this.pickedColor;
    this.ctx.fillStyle = this.pickedColor;
  }

  /**
   * Calculate offset values
   */
  calculateOffset() {
    const v = this.canvasEl.getBoundingClientRect();
    this.offset = {
      xScale: v.width / this.canvasEl.width,
      yScale: v.height / this.canvasEl.height,
      left: v.left,
      top: v.top
    };
  }

  /**
   * Create all the events that may trigger the different paint modes
   * @param canvasEl The canvas element
   */
  createEvents(canvasEl: HTMLCanvasElement) {
    if (this.device.isMobile()) { // takes all moveevents between touchstart and touchend/touchcancel
      this.drawEvent$ = fromEvent(canvasEl, 'touchstart').pipe(
        map(this.preventMove),
        switchMap(e => fromEvent(canvasEl, 'touchmove').pipe(
            map(this.preventMove),
            takeUntil(fromEvent(canvasEl, 'touchend').pipe(map(this.preventMove))),
            takeUntil(fromEvent(canvasEl, 'touchcancel').pipe(map(this.preventMove))),
            pairwise()
          ))
      );
      this.endEvents$[0] = fromEvent(canvasEl, 'touchend').pipe(map(this.preventMove));
      this.endEvents$[1] = fromEvent(canvasEl, 'touchcancel').pipe(map(this.preventMove));
      this.clickEvent$ = fromEvent(canvasEl, 'touchstart').pipe(map(this.preventMove));
    }
    else { // takes all moveevents between mousedown and mouseleave/mouseup
      this.drawEvent$ = fromEvent(canvasEl, 'mousedown').pipe(
        map(this.preventMove),
        switchMap(e => fromEvent(canvasEl, 'mousemove').pipe(
            map(this.preventMove),
            takeUntil(fromEvent(canvasEl, 'mouseup').pipe(map(this.preventMove))),
            takeUntil(fromEvent(canvasEl, 'mouseleave').pipe(map(this.preventMove))),
            pairwise()
          ))
      );
      this.endEvents$[0] = fromEvent(canvasEl, 'mouseup').pipe(map(this.preventMove));
      this.endEvents$[1] = fromEvent(canvasEl, 'mouseleave').pipe(map(this.preventMove));
      this.clickEvent$ = fromEvent(canvasEl, 'mousedown').pipe(map(this.preventMove));
    }
  }

  /**Prevent touch/mouse from scrolling */
  preventMove(event: Event): Event {
    event.preventDefault();
    return event;
  }

  /**
   * Unsubsribe to all events
   */
  unsubscribeAll() {
    while (this.subscriptions.length > 0) {
      const s = this.subscriptions.pop();
      s.unsubscribe();
    }
  }

  /**
   * Toggle draw mode on/off
   */
  toggleDraw() {
    this.unsubscribeAll();
    if (this.state === PaintState.Draw) {
      this.state = PaintState.None;
    }
    else {
      this.state = PaintState.Draw;
      let s: Subscription;
      if (this.device.isMobile()) {
          s = this.drawEvent$.subscribe((res: [TouchEvent, TouchEvent]) => {
            this.isDrawing = true;
            this.calculateOffset();
            const prevPos = {
              x: (res[0].touches[0].clientX - this.offset.left) / this.offset.xScale,
              y: (res[0].touches[0].clientY - this.offset.top) / this.offset.yScale
            };

            const currentPos = {
              x: (res[1].touches[0].clientX - this.offset.left) / this.offset.xScale,
              y: (res[1].touches[0].clientY - this.offset.top) / this.offset.yScale
            };

            this.drawOnCanvas(prevPos, currentPos);
          });
        this.subscriptions.push(s);
      }
      else {
          s = this.drawEvent$.subscribe((res: [MouseEvent, MouseEvent]) => {
          this.isDrawing = true;
          this.calculateOffset();
          const prevPos = {
            x: (res[0].clientX - this.offset.left) / this.offset.xScale,
            y: (res[0].clientY - this.offset.top) / this.offset.yScale
          };

          const currentPos = {
            x: (res[1].clientX - this.offset.left) / this.offset.xScale,
            y: (res[1].clientY - this.offset.top) / this.offset.yScale
          };

          this.drawOnCanvas(prevPos, currentPos);
        });
        this.subscriptions.push(s);
      }
      s = this.endEvents$[0].subscribe(() => {
        if (this.isDrawing) {
          this.addDrawpoint();
          this.isDrawing = false;
        }
      });
      this.subscriptions.push(s);
      s = this.endEvents$[1].subscribe(() => {
        if (this.isDrawing) {
          this.addDrawpoint();
          this.isDrawing = false;
        }
      });
      this.subscriptions.push(s);
    }
  }

  /**
   * Toggle text mode on/off
   */
  toggleText() {
    this.unsubscribeAll();
    if (this.state === PaintState.Text) {
      this.state = PaintState.None;
    }
    else {
      this.state = PaintState.Text;
      if (this.device.isMobile()) {
        const s = this.clickEvent$.subscribe((event: TouchEvent) => {
          this.calculateOffset();
          const x = (event.touches[0].clientX - this.offset.left) / this.offset.xScale;
          const y = (event.touches[0].clientY - this.offset.top) / this.offset.yScale;
          this.setText(x, y);
        });
        this.subscriptions.push(s);
      }
      else {
        const s = this.clickEvent$.subscribe((event: MouseEvent) => {
          this.calculateOffset();
          const x = (event.clientX - this.offset.left) / this.offset.xScale;
          const y = (event.clientY - this.offset.top) / this.offset.yScale;
          this.setText(x, y);
        });
        this.subscriptions.push(s);
      }
    }
  }

  /**
   * Toggle shape mode on/off
   */
  async toggleShape() {
    this.unsubscribeAll();
    if (this.state === PaintState.Shape) {
      this.state = PaintState.None;
    }
    else {
      this.state = PaintState.Shape;
      if (!this.pickedShape) {
        const res = await this.selectShape();
        if (!res) {
          this.state = PaintState.None;
          return;
        }
      }
      if (this.device.isMobile()) {
        const s = this.clickEvent$.subscribe((event: TouchEvent) => {
          this.calculateOffset();
          const x = (event.touches[0].clientX - this.offset.left) / this.offset.xScale;
          const y = (event.touches[0].clientY - this.offset.top) / this.offset.yScale;
          this.setShape(x, y);
        });
        this.subscriptions.push(s);
      }
      else {
        const s = this.clickEvent$.subscribe((event: MouseEvent) => {
          this.calculateOffset();
          const x = (event.clientX - this.offset.left) / this.offset.xScale;
          const y = (event.clientY - this.offset.top) / this.offset.yScale;
          this.setShape(x, y);
        });
        this.subscriptions.push(s);
      }
    }
  }

  /**
   * Get text from user and draw it on the canvas
   * @param x X position on the canvas
   * @param y Y position on the canvas
   */
  async setText(x: number, y: number) {
    const popover = await this.popCtrl.create({
      component: TextPopoverComponent,
      componentProps: {
        size: this.textSize
      },
      cssClass: 'pop-over-large'
    });
    await popover.present();
    const { data }  = await popover.onWillDismiss();
    if (data && data.text && data.size) {
      this.textSize = data.size;
      this.ctx.font = `${data.size}pt serif`;
      this.ctx.fillText(data.text, x, y);
      this.addDrawpoint();
    }
  }

  /**
   * Get selected shape from user
   */
  async selectShape() {
    const popover = await this.popCtrl.create({
      component: ShapesPopoverComponent,
      componentProps: {
        shapes: this.shapeList,
        size: this.pickedShape ? this.pickedShape.size : 1,
        pickedShape: this.pickedShape ? this.pickedShape.shape : null,
        image: {url: this.shapeUrl, width: this.shapeImgObj.width, height: this.shapeImgObj.height}
      }
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();
    this.logger.debug('Chosen shape', data);
    if (data && data.size && data.shape) {
      this.pickedShape = { shape: data.shape, size: data.size};
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Draw selected shape on the canvas
   * @param x X position on the canvas
   * @param y Y position on the canvas
   */
  setShape(x: number, y: number) {
    if (!this.pickedShape || !this.shapeImgObj) return;

    const shapeWidth = parseInt(this.pickedShape.shape.size.width, 10);
    const shapeHeight = parseInt(this.pickedShape.shape.size.height, 10);
    const shapeScale = parseFloat(this.pickedShape.shape.size.scale);
    const shapeX = parseInt(this.pickedShape.shape.pos.x, 10);
    const shapeY = parseInt(this.pickedShape.shape.pos.y, 10);

    const width = shapeWidth * shapeScale * this.pickedShape.size;
    const height = shapeHeight * shapeScale * this.pickedShape.size;

    this.ctx.drawImage(this.shapeImgObj, shapeX, shapeY, shapeWidth, shapeHeight, x - width / 2, y - height / 2, width, height);
    this.addDrawpoint();
  }

  /**
   * Draws line between position. Used in drawing state
   * @param prevPos Previous position of drawing
   * @param currentPos Current position of drawing
   */
  drawOnCanvas(
    prevPos: { x: number, y: number},
    currentPos: { x: number, y: number}
  ) {
    if (!this.ctx) return;

    this.ctx.beginPath();
    if (prevPos) {
      this.ctx.moveTo(prevPos.x, prevPos.y);
      this.ctx.lineTo(currentPos.x, currentPos.y);
      this.ctx.stroke();
    }
  }

  /**
   * Adds current drawn image to history
   */
  addDrawpoint() {
    if (this.drawIndex < this.drawpoints.length - 1) {
      this.drawpoints = this.drawpoints.slice(0, this.drawIndex + 1);
    }
    const image = this.canvasEl.toDataURL();
    this.drawpoints.push(image);
    this.drawIndex++;
    this.fieldService.setValue(image);
  }

  /**
   * Resets and clears the canvas
   */
  clearCanvas() {
    this._getVarsDisabled = false;
    this.hideResetBtn = false;
    this.setCanvas(this.orgSize.width, this.orgSize.height);
    this.ctx.clearRect(0, 0, this.orgSize.width, this.orgSize.height);
    this.drawpoints = [];
    this.drawIndex = -1;
    this.fieldService.clearValue();
    if (this.url) {
      const url = this.util.parseText(this.url, this.model, this.fields);
      if (this.pdf) {
        this.loadPdf(url);
      }
      else {
        this.drawImage(url);
      }
    }
  }

  /**
   * Go to previous drawpoint in history
   */
  undo() {
    if (this.drawIndex > 0) {
      this.drawIndex--;
      const curImage = this.drawpoints[this.drawIndex];
      this.fieldService.setValue(curImage);
      this.drawImage(curImage, false, true);
    }
    else {
      this.clearCanvas();
    }
  }

  /**
   * Go forward in drawpoints if the user has undone and not drawn again
   */
  redo() {
    if (this.drawIndex < this.drawpoints.length - 1) {
      this.drawIndex++;
      const curImage = this.drawpoints[this.drawIndex];
      this.fieldService.setValue(curImage);
      this.drawImage(curImage, false, true);
    }
  }

  /**
   * Clears canvas if user confirms
   */
  async reset(confirmed = false) {
    if (!confirmed) {
      confirmed = await this.popup.showConfirm('ClearPaint', 'ClearPaintMsg', true);
    }
    if (confirmed) {
      this.clearCanvas();
    }
  }

  /**
   * Draws an image onto the canvas
   * @param imageUrl Url of the image to draw, may be base64 url
   * @param save If the new drawn image should be stored in model
   * @param previousPoint If this is an previous drawpoint in history
   */
  drawImage(imageUrl: string, save = false, previousPoint = false): Promise<void> {
    return new Promise<void>(resolve => {
      this.bgError = false;
      this.ctx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
      const image = new Image();
      image.setAttribute('crossorigin', 'anonymous');
      image.onload = () => {
        const width = this.canvasWidth; // this.orgSize.width;
        const prop = width / image.width;
        const height = image.height * prop;
        this.ctx.lineWidth = this.brushSize * prop;
        this.setCanvas(width, height);
        this.ctx.drawImage(image, 0, 0, width, height);
        if (save) {
          this.fieldService.setValue(this.canvasEl.toDataURL(), false);
        }
        resolve();
      };
      image.onerror = () => {
        this.bgError = true;
        resolve();
      };
      image.src = imageUrl;
    });
  }

  /**
   * Opens popover for user to select draw color
   */
  async openColorPopover() {
    const popover = await this.popCtrl.create({
      component: ColorPopoverComponent,
      componentProps: {
        defaultColor: this.color,
        brushSize: this.brushSize
      }
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();
    this.logger.debug(data);
    if (data && data.color && data.brushSize) {
      this.pickedColor = data.color;
      this.brushSize = data.brushSize;
      this.ctx.strokeStyle = data.color;
      this.ctx.fillStyle = data.color;
      this.ctx.lineWidth = data.brushSize;
    }
  }

  /**
   * Get picture to use as background
   * @param fromCamera If the picture should be taken using camera (or from gallery)
   */
  getPicture(fromCamera: boolean) {
    if (fromCamera) {
      if (this.isDevice) {
        this.takePicture(fromCamera);
      }
      else if (this.isMobile) {
        this.selectPicture(true);
      }
    }
    else {
      if (this.isDevice) {
        this.takePicture(fromCamera);
      }
      else {
        this.selectPicture(false);
      }
    }
  }

  /**
   * Takes photo from camaera or gallery to set as background on canvas
   * @param source The source to get photo from. CAMERA (1) or PHOTOLIBRARY (0)
   */
  async takePicture(useCamera: boolean) {

    const {url} = useCamera ? await this.cameraService.getFromCamera()
                            : await this.cameraService.getFromGallery();
    if (url) {
      this.clearCanvas();
      await this.drawImage(url);
      this.addDrawpoint();
    }
    else {
      this.logger.debug('Camera failed');
    }

  }

  /**
   * Get picture using using input with file picker to set as background on canvas
   * @param useCamera If it should set to use the camera
   */
  async selectPicture(useCamera: boolean) {
    const modal =  await this.modalCtrl.create({
      component: SelectPictureComponent,
      componentProps: {
        useCamera: useCamera
      }
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();
    if (data?.imagePath) {
      this.clearCanvas();
      await this.drawImage(data.imagePath);
      this.addDrawpoint();
    }
  }
}
