import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FirebaseEvent, GestureData, SwipeDirection } from '../models/models';
import { DeviceService } from './device.service';
import { StatisticsService } from './statistics.service';
import { TimeService } from './time.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class SwiperService {
  private posMaxLeft = 10; //in %.

  private get screenWidth(): number {
    return this.device.deviceWidth;
  }

  private get screenHeight(): number {
    return this.device.deviceHeight;
  }

  constructor(
    private device: DeviceService,
    private fireLogger: StatisticsService,
    private timeService: TimeService
  ) { }

  shouldClosePage(event: any) {
    const p = (this.getStartPoint(event).x / this.screenWidth) * 100;
    const res = (event.direction === SwipeDirection.LeftToRight && p <= this.posMaxLeft);

    const os = this.device.isIOS(false);
    if(!os || !res) {
      return false;
    }
    else {
      this.fireLogger.logEvent(FirebaseEvent.SwipeBack, {isIOS: os, pct: p, screenWidth: this.screenWidth});
      return true;
    }
  }

  /**
   * Calculate new date for date field on horizontal swipe
   * @param event The swipe event
   * @param data The gesture data
   * @param currentVal The current date value
   * @param field The field to change datre on
   * @returns The new time formatted correctly for the field if horizontal swipe, `null` for other swipes or if the field isn't an date field.
   */
  swipeDate(event: {direction: SwipeDirection}, data: GestureData, currentVal: string, field: FormlyFieldConfig): string {
    const date = moment(currentVal);
    if (!date.isValid() || !this.isHorizontalSwipe(event.direction)) {
      return null;
    }
    const unit: any = data.unit || 'days';
    let step: any = data.step || 1;
    if (event.direction === SwipeDirection.LeftToRight) {
      step *= -1;
    }
    const val = this.timeService.createDate(date.toDate(), step, unit);
    const format = this.timeService.getTimeType(field) || 'datetime';
    //console.log(format);
    return this.timeService.formatDatetime(val, format);
  }

  private getStartPoint(event: any) {
    const pos = {x:0, y:0};
    pos.x = event.center.x - event.deltaX;
    pos.y = event.center.y - event.deltaY;
    return pos;
  }

  private isHorizontalSwipe(direction: SwipeDirection) {
    return direction === SwipeDirection.LeftToRight || direction === SwipeDirection.RightToLeft;
  }

}
