import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Options } from 'src/app/models/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-wrapper-history',
  templateUrl: './history-wrapper.component.html',
  styleUrls: ['./history-wrapper.component.scss'],
})
export class HistoryWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.to.disabled) {
      this.route.paramMap.subscribe(async params => {
        await this.auth.waitForAuth();
        const formId = (this.to.historyForm) ? this.to.historyForm : params.get('id');
        this.api.getPreviousValues(formId, this.key as string).subscribe(({value: history}) => {
          if (this.to.options instanceof Observable) {
            this.to.options = this.to.options.pipe(
              map(options => this.mergeHistory(options, history))
            );
          }
          else {
            this.to.options = this.mergeHistory(this.to.options, history);
          }
        });
      });
    }
  }

  mergeHistory(options: Options[], history: string[]): Options[] {
    const newOpts: Options[] = [];
    const valueProp = this.to.valueProp || 'value';
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < history.length; i++) {
      for (let j = 0; j < options.length; j++) {
        if (history[i] === options[j][valueProp]) {
          newOpts.push(options[j]); // Add option in history to new
          options.splice(j, 1); // Remove it from old history
          j--; // Old is one shorter
        }
      }
    }
    // Add elements not in history
    return newOpts.concat(options);
  }

}
