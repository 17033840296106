import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { PickerController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PickerColumn, PickerColumnOption } from '@ionic/core';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';
import { FieldService } from 'src/app/services/field.service';
import { AutosaveService } from 'src/app/services/autosave.service';
import { Subscription } from 'rxjs';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { FormType, Trigger, TriggerType } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-field-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  providers: [FieldService]
})
export class TimepickerComponent extends FieldType implements OnInit, OnDestroy, Trigger {
  shouldUpdateTime = false;
  intervalId: any;
  timeooutId: any;
  resumeSub: Subscription;
  showMarker = true;
  templateValue: string;

  constructor(
    private pickerController: PickerController,
    private translate: TranslateService,
    private logger: NGXLogger,
    private fieldService: FieldService,
    private autosave: AutosaveService,
    private plt: Platform,
    private ref: ComponentRefService,
    private elm: ElementRef,
    private dom: DomOperationsService,
    private formService: FormService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }

  get modelValue(): string {
    if (this.checkFormat(this.model[this.key as string | number])) {
      return this.model[this.key as string | number];
    }
    else {
      return '00:00';
    }
  }

  get displayValue(): string {
    if (this.checkFormat(this.model[this.key as string | number])) {
      if (this.showMarker || !this.shouldUpdateTime) {
        return this.model[this.key as string | number];
      }
      else {
        return this.model[this.key as string | number].replace(':', '\u00A0');
      }
    }
    else {
      return '00:00';
    }
  }

  get timeIsSet(): boolean {
    return this.checkFormat(this.model[this.key as string | number]) && !this.shouldUpdateTime;
  }

  get setNowTime(): boolean {
    if (!this.isEmpty && this.checkFormat(this.model[this.key as string | number])) {
      return false;
    }
    else {
      return this.hasNowDefault;
    }
  }

  get hasNowDefault(): boolean {
    return this.to.default === 'now' || this.to.defaultValue === 'now' || this.model[this.key as string | number] === 'now';
  }

  get step(): number {
    if (typeof this.to.step === 'string') {
      return parseInt(this.to.step, 10) || 5;
    }
    else if (typeof this.to.step === 'number') {
      return this.to.step || 5;
    }
    else {
      return 5;
    }
  }

  get position(): string {
    switch (this.to.labelPosition) {
      case 'stacked':
      case 'inline':
        return this.to.labelPosition;
      default:
        return 'undefined';
    }
  }

  get isStacked(): boolean {
    return (this.position === 'stacked');
  }

  get hasLabel() {
    return (this.to.label);
  }

  get isLiveUpdating(): boolean {
    return this.shouldUpdateTime;
  }

  get formId(): number {
    return this.to.currentForm.id ?? 0;
  }

  get projectId(): number {
    return this.to.currentForm.projectId ?? 0;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  get notAutosave(): boolean {
    return this.to.notAutosave ?? false;
  }

  get hasSteps(): boolean {
    return this.formService.hasSteps;
  }

  get isHiddenWithSteps(): boolean {
    if (!this.field.className) {
      return false;
    }
    else {
      return this.field.className.includes('hide') && this.hasSteps;
    }
  }

  get isEmpty(): boolean {
    if (this.isHiddenWithSteps) {
      return true;
    }
    else {
      return !this.model[this.key as string | number];
    }
  }

  public static getHtml(config: FormlyFieldConfig, value: string) {
    if (config.templateOptions.label) {
      return `<ion-card><ion-item lines="none">
                <ion-label>${config.templateOptions.label}</ion-label>
                ${value}
              </ion-item></ion-card>`;
    }
    else {
      return `<ion-card><ion-item lines="none">
                ${value}
              </ion-item></ion-card>`;
    }
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);

    this.resumeSub = this.plt.resume.subscribe(() => {
      if (this.shouldUpdateTime) {
        this.setCurrentTime(this.hasSteps);
        this.startLiveUpdate();
      }
    });

    this.templateValue = this.displayValue;

    if (this.isEmpty) {
      if (this.checkFormat(this.field.defaultValue)) {
        this.setValue(this.field.defaultValue);
      }
      else if (this.checkFormat(this.to.defaultValue)) {
        this.setValue(this.to.defaultValue);
      }
    }
    else {
      const value = this.fixOldValue(this.model[this.key as string | number]);
      if (this.checkFormat(value) && value !== this.model[this.key as string | number]) {
        this.setValue(value);
      }
    }

    if (this.setNowTime) {
      if (!this.hasSteps) {
        this.autosave.addKey(this.formId, this.projectId, this.key as string);
      }
      this.setCurrentTime(this.hasSteps);
      if (!this.to.noUpdate) {
        this.startLiveUpdate();
      }
    }
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Focus) {
      const button: HTMLIonButtonElement = this.elm.nativeElement.querySelector('ion-button');
      this.dom.scrollIntoViewAndExecute(button, () => this.openPicker());
    }
    else if (type === TriggerType.StopUpdate) {
      this.stopUpdating();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  startLiveUpdate() {
    this.clearIntervals();
    this.shouldUpdateTime = true;
    this.intervalId = setInterval(() => {
      this.showMarker = !this.showMarker;
      if (this.shouldUpdateTime) {
        this.setCurrentTime(this.hasSteps);
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.clearIntervals();
    this.resumeSub?.unsubscribe();
  }

  clearIntervals() {
    if (this.timeooutId) {
      clearTimeout(this.timeooutId);
      this.timeooutId = null;
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  setCurrentTime(emitEvent: boolean) {
    const value = moment().format('HH:mm');
    this.setValue(value, emitEvent, emitEvent);
  }

  async openPicker() {
    const h = parseInt(this.modelValue.split(':')[0] || '00', 10);
    const m = parseInt(this.modelValue.split(':')[1] || '00', 10);
    const hours: PickerColumn = {
      name: 'hours',
      options: [],
      suffix: ':',
      columnWidth: '100px',
      optionsWidth: '80px',
      align: 'right',
      suffixWidth: '5px',
      prefixWidth: '0px',
      selectedIndex: h,
      cssClass: 'timePickerColumn'
    };
    const minutes: PickerColumn = {
      name: 'minutes',
      options: [],
      columnWidth: '100px',
      optionsWidth: '80px',
      align: 'left',
      selectedIndex: Math.floor(m / this.step),
      cssClass: 'timePickerColumn'
    };

    for (let i = 0; i < 24; i++) {
      const text = (i < 10) ? '0' + i : i.toString();
      const option: PickerColumnOption = {
        value: text,
        text: text
      };
      hours.options.push(option);
    }
    for (let i = 0; i < 60; i += this.step) {
      const text = (i < 10) ? '0' + i : i.toString();
      const option: PickerColumnOption = {
        value: text,
        text: text
      };
      minutes.options.push(option);
    }
    const trans = this.translate.instant(['Cancel', 'Now', 'Confirm', 'RealTime']);
    const buttons = [
      {
        text: trans['Cancel'],
        role: 'cancel'
      },
      {
        text: trans['Now'],
        handler: () => {
          this.logger.debug('User clicked now');
          this.stopUpdating();
          this.setCurrentTime(true);
        }
      },
      {
        text: trans['Confirm'],
        handler: (val: any) => {
          this.logger.debug('Timepicker value', val);
          const value = `${val['hours']?.value || '00'}:${val['minutes']?.value || '00'}`;
          this.stopUpdating();
          this.setValue(value);
        }
      }
    ];
    if (this.hasNowDefault && !this.to.noUpdate) {
      buttons.splice(1, 0, {
        text: trans['RealTime'],
        handler: () => {
          this.logger.debug('User clicked Real time');
          this.setCurrentTime(this.hasSteps);
          if (!this.shouldUpdateTime) {
            if (!this.hasSteps) {
              this.autosave.addKey(this.formId, this.projectId, this.key as string);
            }
            this.startLiveUpdate();
          }
        }
      });
    }
    const picker = await this.pickerController.create({
      columns: [hours, minutes],
      buttons: buttons
    });
    await picker.present();
  }

  stopUpdating() {
    if (!this.notAutosave) {
      this.autosave.removeKey(this.formId, this.projectId, this.key as string);
    }
    this.shouldUpdateTime = false;
    this.clearIntervals();
  }

  /**
   * Checks if the format of an time is correct
   * @param value The value to check
   */
  checkFormat(value: any): boolean {
    if (!value || typeof value !== 'string' || !value.match(/^\d\d:\d\d$/)) {
      return false;
    }
    else {
      const hours = parseInt(value.slice(0, 2), 10);
      const minutes = parseInt(value.slice(3), 10);

      if (isNaN(hours) || hours < 0 || hours > 23 || isNaN(minutes) || minutes < 0 || minutes > 59) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  /**
   * Fix possible wrong values for time, e.g. '8:4' -> '08:04'
   * @param value The value to fix
   */
  fixOldValue(value: string): string {
    if (value.match(/^\d\d:\d$|^\d:\d\d$|^\d:\d$/)) {
      return value.split(':').map(v => v.length === 1 ? '0' + v : v).join(':');
    }
    else {
      return value;
    }
  }

  private setValue(value: string, markAsDirty?: boolean, emitEvent?: boolean) {
    this.fieldService.setValue(value, markAsDirty, emitEvent);
    this.templateValue = this.displayValue;
  }
}
