import { Component, OnInit } from '@angular/core';
import { Language } from 'src/app/models/models';
import { LanguageService } from 'src/app/services/language.service';
import { StatisticsService } from 'src/app/services/statistics.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-app-settings-item',
  templateUrl: './app-settings-item.component.html',
  styleUrls: ['./app-settings-item.component.scss'],
})
export class AppSettingsItemComponent implements OnInit {
  userLanguage: string;
  languages: Language[];
  theme: string;
  constructor(
    private language: LanguageService,
    private themeService: ThemeService,
    private statistics: StatisticsService
  ) { }

  ngOnInit() {
    this.userLanguage = this.language.getCurrentLanguage();
    this.languages = this.language.getLanguages();
    this.theme = this.themeService.theme.value;
  }

  setLanguage() {
    this.language.setLanguage(this.userLanguage, true);
    this.statistics.changeLanguage(this.userLanguage);
  }

  changeTheme() {
    this.themeService.changeTheme(this.theme);
    this.statistics.changeTheme(this.theme);
  }
}
