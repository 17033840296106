<ion-header>
    <ion-toolbar color="header">
      <ion-button fill="clear" slot="start" (click)="delete()" *ngIf="showDelete">
        <ion-icon slot="icon-only" name="trash"></ion-icon>
      </ion-button>
      <ion-title *ngIf="formName && reg"><b>{{formName}}:</b>&nbsp;<span>{{reg.RegTime | date:'dd.MM HH:mm'}}</span></ion-title>
      <ion-button fill="clear" slot="end" (click)="close()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  
  <ion-content class="in-background">
    <ion-grid>
      <ion-row class="ion-justify-content-center" *ngIf="loading">
        <ion-col size="1">
          <ion-spinner></ion-spinner>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-button expand="block" color="success" *ngIf="foundReg && reg.IsSigned" disabled>
      <ion-icon slot="start" name="checkmark"></ion-icon>
      <ion-label class="ion-text-wrap">{{'Signed' | translate}}</ion-label>
    </ion-button>
    <ion-button expand="block" color="warning" *ngIf="foundReg && !reg.IsSigned" disabled>
      <ion-icon slot="start" name="close"></ion-icon>
      <ion-label class="ion-text-wrap">{{'UnSigned' | translate}}</ion-label>
    </ion-button>
    <form [formGroup]="formGroup" *ngIf="foundReg" class="disabled" (click)="disabled($event)">
      <ion-card class="formCard">
        <formly-form [form]="formGroup" [fields]="fields" [model]="model"></formly-form>
      </ion-card>
    </form>
  </ion-content>