import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { BrowserService } from 'src/app/services/browser.service';

@Component({
  selector: 'app-select-picture',
  templateUrl: './select-picture.component.html',
  styleUrls: ['./select-picture.component.scss'],
})
export class SelectPictureComponent {
  @Input() useCamera = false;
  image: any;
  fileName: string;
  imagePath: string;

  constructor(
    private modalCtrl: ModalController,
    private logger: NGXLogger,
    private browser: BrowserService
  ) { }

  async close(save: boolean) {
    try {
      if (save) {
        await this.modalCtrl.dismiss({
          image: this.image,
          imagePath: this.imagePath,
          fileName: this.fileName
        });
      }
      else {
        await this.modalCtrl.dismiss();
      }
    }
    catch (err) {
      this.logger.warn('Failed dismissing select picture modal', err);
    }
  }

  setImage(event: any) {
    this.image = event.target.files[0];
    this.fileName = this.image.name;
    this.imagePath = this.browser.createImageUrl(this.image);
  }

}
