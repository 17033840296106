<ion-app class="scanner-hide">
  <ion-split-pane when="xl" contentId="main">
    <ion-menu [disabled]="!loggedIn || disableMenu" contentId="main" >

      <ion-content class="content">
        
        <ion-item class="sidemenu-item userProfil" button detail lines="none" (click)="openUserSettings()" [ngClass]="state === 'usersettings' ? 'highlight' : ''">
          <ion-avatar class="profilAvatar" slot="start">
              <ion-img [src]="user.profilePicture"></ion-img>
          </ion-avatar>
          <ion-label>
            <div id="userName">{{user.name}}</div>
            <div id="userTenant">{{user.tenant}}</div>
            <p></p>
          </ion-label>
        </ion-item>
        <ion-item-divider class="sidemenu-divider"></ion-item-divider>
        <ion-list>
          <ng-container *ngFor="let p of pages; let index = index; trackBy: trackByName">
            <ng-container *ngTemplateOutlet="page; context: p"></ng-container>
          </ng-container>
          <ion-item-divider class="sidemenu-divider"></ion-item-divider>
          <ion-item id="setup" class="sidemenu-item" detail="false" button lines="none" (click)="openSetup()" [ngClass]="state === 'setup' ? 'highlight' : ''">
            <ion-icon name="build-outline" slot="start"></ion-icon>
            <ion-label>{{'Setup' | translate}}</ion-label>
          </ion-item>
          
          <ion-item-divider class="sidemenu-divider sidemenu-border-divider"></ion-item-divider>

          <ion-item *ngIf="hasNotifications" id="notifications" class="sidemenu-item" detail="false" button lines="none" (click)="openNotifications()" [ngClass]="state === 'notifications' ? 'highlight' : ''">
            <ion-icon name="notifications-outline" slot="start"></ion-icon>
            <ion-label>{{'Notifications' | translate}}</ion-label>
            <ion-badge color="danger" class="notification-badge" *ngIf="notificationsCount > 0"><small>{{notificationsCount}}</small></ion-badge>
          </ion-item>
          <!--<ion-item id="app-settings" class="sidemenu-item item-small" detail="false" button lines="none" (click)="openAppSettings()" [ngClass]="state === 'settings' ? 'highlight' : ''">
            <ion-icon name="settings-outline" slot="start"></ion-icon>
            <ion-label>{{'AppSettings' | translate}}</ion-label>
          </ion-item>
          <ion-item id="feedback" class="sidemenu-item item-small" detail="false" button lines="none" (click)="showFeedback()" [ngClass]="state === 'feedback' ? 'highlight' : ''">
            <ion-icon name="mail-outline" slot="start"></ion-icon>
            <ion-label>{{'SendFeedback' | translate}}</ion-label>
          </ion-item>-->
          <ion-item id="user-doc" class="sidemenu-item item-small" detail="false" button lines="none" (click)="openManual()">
            <ion-icon name="help-circle-outline" slot="start"></ion-icon>
            <ion-label>{{'UserManual' | translate}}</ion-label>
          </ion-item>

          <!--Add app to home screen as PWA-->
          <ion-item-divider class="sidemenu-divider" *ngIf="installPrompt || hasUpdate"></ion-item-divider>
          <ion-item class="sidemenu-item" id="install-app" detail="false" button detail="false" lines="none" *ngIf="installPrompt" (click)="addToHome()" class="sidemenu-item ion-text-wrap">
            <ion-icon name="star-outline" slot="start"></ion-icon>
            <ion-label *ngIf="isMobile" class="ion-text-wrap">{{'AddToHome' | translate}}</ion-label>
            <ion-label *ngIf="!isMobile" class="ion-text-wrap">{{'InstallApp' | translate}}</ion-label>
          </ion-item>
          <ion-item class="sidemenu-item" id="update-app" detail="false" button detail="false" lines="none" *ngIf="hasUpdate || isOld" (click)="updateApp()" class="sidemenu-item ion-text-wrap">
            <ion-icon name="refresh-circle-outline" slot="start"></ion-icon>
            <ion-label class="ion-text-wrap">{{'UpdateApp' | translate}}</ion-label>
            <ion-badge color="danger" class="notification-badge" *ngIf="showUpdateBadge"><small>&nbsp;</small></ion-badge>
          </ion-item>

          <ion-item-divider class="sidemenu-divider"></ion-item-divider>
          <app-app-settings-item *ngIf="!init"></app-app-settings-item>
        </ion-list>

      <ion-footer class="menuFooter" (pressup)="clearLog($event)">
        <ion-grid>
          <ion-row>
            <ion-col size="7">
              <ion-label class="privacyInfo ion-text-start" (click)="openPrivacyPopover()">{{'PrivacyInfo' | translate}}</ion-label>
            </ion-col>
            <ion-col size="5">
              <ion-label class="app-version" (click)="setBuildNr($event)" (pressup)="showFeedback($event)">{{apiEnvironment}} v{{appVersion}}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-footer>
      
      </ion-content>

    </ion-menu>

    <ion-router-outlet id="main" [swipeGesture]="false"></ion-router-outlet>
  </ion-split-pane>
</ion-app>

<div class="scanner-ui">
  <div class="barcode-scanner--area--container">
    <div class="relative">
      <ion-button fill="clear" color="primary" (click)="stopScanner()">
        <ion-icon name="arrow-back" slot="start"></ion-icon>
        <ion-label>{{'CancelScan' | translate}}</ion-label>
      </ion-button>
      <p>
        {{'ScanBarcode' | translate}}
      </p>
    </div>
    <div class="square surround-cover">
      <div class="barcode-scanner--area--outer surround-cover">
        <div class="barcode-scanner--area--inner"></div>
      </div>
    </div>
  </div>
</div>

<ng-template #page let-url="urlOrData" let-formState="pageState", let-name="name", let-icon="icon" let-open="open" let-direction="direction">
    <ion-menu-toggle auto-hide="false">
      <ion-item lines="none" class="sidemenu-item" [detail]="false" button [routerDirection]="direction" [routerLink]="url"  [queryParams]="{fromMenu: true}"  [ngClass]="formState === state ? 'highlight' : ''" >
        <ion-icon slot="start" [name]="icon"></ion-icon>
        <ion-label slot="start" class="ion-text-start">{{name | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
</ng-template>
