<ion-header>
  <ion-toolbar mode="ios" color="header">
    <ion-title>
      {{label}}
    </ion-title>
    <ion-button class="ion-no-margin" fill="clear" slot="end" (click)="closeModal()">
      <ion-icon name="close-circle-outline" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="front-background">
  <ngx-extended-pdf-viewer #viewer [src]="url" [useBrowserLocale]="true" *ngIf="isMobile && url && !hasError" (pdfLoadingFailed)="pdfError($event)" (pagesLoaded)="checkSize()"></ngx-extended-pdf-viewer>
  <div *ngIf="isMobile && hasError && url" class="pdf-error ion-text-center ion-padding-top">
    <ion-title color="danger"><b>{{'PdfError' | translate}}</b></ion-title>
    <br>
    <a (click)="openPdfInBrowser(url)">{{'DownloadPdf' | translate}}</a>
  </div>
  <object [data]="trustedUrl" type="application/pdf" width="100%" height="100%" *ngIf="!isMobile && url">
    <p>{{'PdfNoSupport' | translate}} <a (click)="openPdfInBrowser(url)">{{'DownloadPdf' | translate}}</a>.</p>
  </object>
  <div *ngIf="!url" class="no-url ion-text-center ion-padding-top">
    <ion-title color="danger"><b>{{'NoUrl' | translate}}</b></ion-title>
  </div>
</ion-content>