<ion-header>
  <ion-toolbar color="header">
    <ion-title>{{'Notifications' | translate}}</ion-title>
    <ion-button fill="clear" slot="end" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="in-background">
    <ion-list>
      <ng-container *ngFor="let n of notifications">
        <ng-container *ngTemplateOutlet="notification; context: n"></ng-container>
      </ng-container>
      <ion-card *ngIf="notifications.length === 0">
        <ion-card-header>
          <ion-card-title>{{'NoNotifications' | translate}}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-text>{{'NoNotificationsMsg' | translate}}</ion-text>
        </ion-card-content>
      </ion-card>
    </ion-list>
</ion-content>

<ng-template #notification let-id="id" let-received="received" let-title="title" let-text="body" let-type="type" let-opened="opened" let-handler="handler">

  <ion-card>
    <ion-item lines="none" class="notifications-bar">
      <ion-badge slot="start" color="danger" class="notification-badge" *ngIf="!opened">&nbsp;</ion-badge>
      <ion-card-subtitle slot="start">{{received | date:'dd.MM.yy HH:mm'}}</ion-card-subtitle>
      <ion-button slot="end" size="small" fill="clear" color="danger" (click)="deleteNotification(id)">
        <ion-icon name="trash-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-card-header class="card-header">
      <ion-card-title [class.clickable]="type !== 'message'" (click)="handleClick(id)">
        {{title}}
        <ion-icon class="linkIcon" name="chevron-forward-sharp" *ngIf="type !== 'message'"></ion-icon>
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-text>{{text}}</ion-text>
    </ion-card-content>
  </ion-card>

</ng-template>