import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { PopupService } from 'src/app/services/popup.service';
import { FieldService } from 'src/app/services/field.service';
import { FormType, IonColor, Trigger, TriggerType } from 'src/app/models/models';
import { ValidationService } from 'src/app/services/validation.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { environment } from 'src/environments/environment';
import { DomOperationsService } from 'src/app/services/dom-operations.service';

@Component({
  selector: 'app-field-valuelist',
  templateUrl: './valuelist.component.html',
  styleUrls: ['./valuelist.component.scss'],
  providers: [FieldService]
})
export class ValuelistComponent extends FieldType implements OnInit, Trigger {
  @ViewChild('itemTable', {static: true}) itemTable: ElementRef;
  @ViewChild('flowDiv', {static: true}) flowDiv: ElementRef;
  showOverflowArrow = false;
  listIndex = 1;
  values = [];

  constructor(
    private logger: NGXLogger,
    private translate: TranslateService,
    private popup: PopupService,
    private fieldService: FieldService,
    private validation: ValidationService,
    private ref: ComponentRefService,
    private domOp: DomOperationsService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }

  get color(): string {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Success;
  }

  get splitmarker_item(): string {
    return this.to.splitRow || ';';
  }

  get splitmarker_input(): string {
    return this.to.splitValue || '|';
  }

  get json(): boolean {
    return this.to.json || false;
  }

  get keys(): {key: string, label: string, required?: boolean}[] {
    return Array.isArray(this.to.keys) ? this.to.keys : [];
  }

  get clearKeys(): string[] {
    if (typeof this.to.clearKeys === 'string') {
      return this.to.clearKeys.split(',');
    }
    else if (Array.isArray(this.to.clearKeys)) {
      return this.to.clearKeys;
    }
    else {
      return [];
    }
  }

  get goUp(): boolean {
    return this.to.goUp || false;
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  public static getHtml(config: FormlyFieldConfig, value: any) {
    const label = config.templateOptions.label ?? '';
    const labels = (config.templateOptions.keys as any[] ?? []).map(k => k.label as string);
    let html = `<ion-card><ion-card-header>${label}</ion-card-header>`;
    if (labels.length === 0) {
      html += '</ion-card>';
      return html;
    }

    if (config.templateOptions.json && Array.isArray(value) && value.length > 0) {
      html += '<table class="fullsize valuelist">';
      html += '<tr><td><b>#</b></td>';
      html += labels.map(l => `<td><b>${l}</b></td>`).join('');
      html += '</tr>';
      for (let i = 0; i < value.length; i++) {
        const val = value[i];
        html += `<tr><td>#${i + 1}</td>`;
        html += labels.map(l => `<td>${val[l]}</td>`).join('');
        html += '</tr>';
      }
      html += '</table>';
    }
    else if (!config.templateOptions.json && typeof value === 'string' && value !== '') {
      const splitRow = config.templateOptions.splitRow || ';';
      const splitValue = config.templateOptions.splitValue || '|';
      html += '<table class="fullsize valuelist">';
      html += '<tr><td><b>#</b></td>';
      html += labels.map(l => `<td><b>${l}</b></td>`).join('');
      html += '</tr>';
      const items = value.split(splitRow);
      for (let i = 0; i < items.length; i++) {
        const parts = items[i].split(splitValue);
        html += `<tr><td>#${i + 1}</td>`;
        html += parts.map(part => `<td>${part}</td>`).join('');
        html += '</tr>';
      }
      html += '</table>';
    }
    html += '</ion-card>';
    return html;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    this.buildHeader();
    if (this.model[this.key as string | number]) {
      this.rebuildFromModel();
    }
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Clear) {
      this.clearField();
    }
    else if (type === TriggerType.Rebuild) {
      this.clearField();
      this.rebuildFromModel();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  clearField() {
    this.listIndex = 1;
    this.values = [];
    this.itemTable.nativeElement.innerHTML = '';
    this.buildHeader();
  }

  checkOverflowing() {
    setTimeout(() => {
      this.showOverflowArrow = this.domOp.isOverflowing(this.flowDiv?.nativeElement);
    });
  }

  hideScrollArrow() {
    setTimeout(() => {
      this.showOverflowArrow = false;
    }, 1000);
  }

  buildHeader() {
    if (this.keys.length === 0) return;

    const header = this.itemTable.nativeElement.createTHead();
    const row = header.insertRow(0);
    row.insertCell(0).innerHTML = '<b>#</b>';
    for (let i = 0; i < this.keys.length; i++) {
      const a = (i + 1);
      const r = (this.keys[i].required) ? '*' : '';
      row.insertCell(a).innerHTML = '<b>' + this.keys[i].label + r + '</b>';
    }
    this.checkOverflowing();
  }

  rebuildFromModel() {
    this.logger.debug('Rebuild from Model');
    const items = this.model[this.key as string | number];
    if (Array.isArray(items)) {
      this.values = items;
      for (const item of items) {
        this.addToView(item);
      }
      this.logger.debug('Rebuild complete.');
    }
    else if (typeof items === 'string') {
      for (const item of items.split(this.splitmarker_item)) {
        this.addToView(item);
      }
    }
  }

  getKeyValues() {
    let values: {[key: string]: any} | string;
    let isNotSet = '';
    if (this.json) {
      values = {};
      for (const key of this.keys) {
        const val = this.model[key.key];
        if (key.required && (typeof val === 'undefined' || val === '')) {
          isNotSet = key.label;
          break;
        }
        else if (typeof val === 'undefined')
          values[key.label] = '';
        else
          values[key.label] = val;
      }
    }
    else {
      values = '';
      for (let i = 0; i < this.keys.length; i++) {
        if (i > 0)
          values += this.splitmarker_input;
        let val = '';
        // Will not add row if required field/key is empty.
        // eslint-disable-next-line max-len
        if (this.keys[i].required === true && (typeof this.model[this.keys[i].key] === 'undefined' || this.model[this.keys[i].key] === '')) {
          isNotSet = this.keys[i].label;
          break;
        }
        else if (typeof this.model[this.keys[i].key] === 'undefined') {
          val = '';
        }
        else {
          val = this.model[this.keys[i].key];
        }

        values += val;
      }
    }

    this.logger.debug(values);
    if (isNotSet === '') {
      this.addToModel(values); // Add values to list.
      this.clearValues(); // Clear value after collected.
    }
    else {
      const translations = this.translate.instant(['NoValue', 'NoValueMsg']);
      this.logger.debug('Key is not set: ' + isNotSet);
      this.popup.showAlert(translations['NoValue'], `${translations['NoValueMsg']} <b>${isNotSet}</b>`, false);
    }
  }

  clearValues() {
    if (this.clearKeys.length === 0) return;

    const modelKeys: string[] = Object.keys(this.model);
    const newVals = {};
    for (const key of this.clearKeys) {
      if (modelKeys.find(k => k === key)) {
        newVals[key] = '';
      }
    }
    (this.formControl.parent as UntypedFormGroup).patchValue(newVals);
  }

  addToModel(values: any) {
    const item = values;
    let value: string | any[];
    if (this.json) {
      this.values.push(item);
      value = this.values;
    }
    else {
      this.logger.debug(this.model[this.key as string | number]);
      if (!this.model[this.key as string | number])
        value = item;
      else
        value = this.model[this.key as string | number] + this.splitmarker_item + item;
    }
    this.fieldService.setValue(value);

    this.logger.debug(item);
    this.addToView(item);

    if (this.goUp)
      this.scrollTop();
  }

  addToView(item: any) {
    // itemTable.addChild()..
    const index = this.itemTable.nativeElement.rowIndex;
    this.logger.debug(index);
    const row = this.itemTable.nativeElement.insertRow(index);
    row.class = 'barcodeItem';
    const that = this;
    row.onclick = function() {
      that.removeItem(this);
    };
    // Insert new cells (<td> elements) at the 1st and 2nd position of the "new" <tr> element:
    row.insertCell(0).innerHTML = '#' + this.listIndex++;
    if (this.json) {
      for (let i = 0; i < this.keys.length; i++) {
        const t = item[this.keys[i].label];
        const a = i + 1;
        row.insertCell(a).innerHTML = t;
      }
    }
    else {
      const t = item.split(this.splitmarker_input);
      this.logger.debug(t);
      for (let i = 0; i < t.length; i++) {
        const a = (i + 1);
        row.insertCell(a).innerHTML = t[i];
      }
    }
    this.checkOverflowing();
  }

  async removeItem(element: HTMLTableRowElement) {
    const parent = element.parentNode;
    // The equivalent of parent.children.indexOf(child)
    const index = Array.prototype.indexOf.call(parent.children, element);
    this.logger.debug('Index ' + index);
    element.className = 'picked';
    let vals = '';
    for (let i = 1; i < element.cells.length; i++) {
      if (i > 1)
        vals += '<br/>';
      vals += '<b>' + this.keys[i - 1].label + ':</b>' + ' ';
      vals += element.cells[i].innerHTML;
    }
    const remove = this.translate.instant('Remove');
    const header = `${remove} ${element.cells[0].innerHTML}?`;
    const message = `<span>${vals}</span>`;
    const confirmed = await this.popup.showConfirm(header, message, false);
    if (confirmed) {
      this.itemTable.nativeElement.deleteRow(index);
      this.removeElementFromModel(index);
    }
  }

  removeElementFromModel(index: number) {
    this.logger.debug('Remove from model called');
    index -= 1; // correct for header row

    let value: string | any[];
    if (this.json) {
      this.values.splice(index, 1);
      value = this.values;
    }
    else {
      const arr = this.model[this.key as string | number].split(this.splitmarker_item);
      if (index > -1) {
        arr.splice(index, 1);
      }
      value = arr.join(this.splitmarker_item);
    }
    this.fieldService.setValue(value);
  }

  scrollTop() {
    const items = document.querySelectorAll('ion-content');
    if (items.length > 0) {
      const content: HTMLIonContentElement = items[items.length - 1];
      content.scrollToTop(500);
    }
  }
}
