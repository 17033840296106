import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import * as moment from 'moment';
import { UtilityService } from 'src/app/services/utility.service';
import { ApiService } from 'src/app/services/api.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { PopupService } from 'src/app/services/popup.service';
import { NGXLogger } from 'ngx-logger';
import { StateService } from 'src/app/services/state.service';
import { FormPage } from 'src/app/pages/form/form.page';
import { FormViewComponent } from 'src/app/custom-components/directives/page-directives/form-view/form-view.component';
import { TranslateService } from '@ngx-translate/core';
import { FieldService } from 'src/app/services/field.service';
import { ValidationService } from 'src/app/services/validation.service';
import { Trigger, IonColor, TriggerType, State, ApiStatus, FormType, PageRefs, Fields } from 'src/app/models/models';
import { TimeService } from 'src/app/services/time.service';
import { environment } from 'src/environments/environment';
import { BrowserService } from 'src/app/services/browser.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SubFormComponent } from 'src/app/custom-components/directives/formly-directives/sub-form/sub-form.component';

@Component({
  selector: 'app-field-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class ButtonComponent extends FieldType implements OnInit, Trigger {
  /**Fields that have to trigger clear when they are cleared */
  fieldsToClear = [Fields.Signature, Fields.Paint, Fields.Photo, Fields.ValueList, Fields.ItemList, Fields.Barcode];
  loading = false;

  constructor(
    private watch: WatchgroupService,
    private util: UtilityService,
    private api: ApiService,
    private ref: ComponentRefService,
    private popup: PopupService,
    private logger: NGXLogger,
    private stateService: StateService,
    private translate: TranslateService,
    private fieldService: FieldService,
    private validation: ValidationService,
    private time: TimeService,
    private browser: BrowserService
  ) {
    super();
    this.fieldService.setField(this);
  }


  get clickedAt(): string {
    const val = this.fieldService.getValue();
    return (val) ? moment(val).format('HH:mm') : '';
  }
  get wasPressed(): boolean {
    return (!!this.model && this.model['pressed'] === this.key && this.formControl.disabled);
  }
  get label(): string {
    if (this.formControl.value) {
      return this.to.clickedLabel || this.to.label || '';
    }
    else {
      return this.to.label || '';
    }
  }

  get color(): IonColor {
    if (this.formControl.value && this.validation.validColor(this.to.clickedColor)) {
      return this.validation.invisibleColor(this.to.clickedColor, this.fill) ? IonColor.Medium : this.to.clickedColor;
    }
    else if (this.validation.validColor(this.to.colorType)) {
      return this.validation.invisibleColor(this.to.colorType, this.fill) ? IonColor.Medium : this.to.colorType;
    }
    else {
      return IonColor.Primary;
    }
  }

  get expand(): 'small' | 'full' | 'block' {
    switch (this.to.expand) {
      case 'small':
        case 'full':
        return this.to.expand;
      default:
        return 'block';
    }
  }

  get size(): 'large' | 'small' | 'default' {
    switch (this.to.size) {
      case 'large':
      case 'small':
        return this.to.size;
      default:
        return 'default';
    }
  }

  get fill(): 'clear' | 'outline' | 'solid' {
    switch (this.to.fill) {
      case 'clear':
      case 'outline':
      case 'solid':
        return this.to.fill;
      default:
        return this.isFinalSubmit ? 'solid' : 'outline';
    }
  }

  get buttonFunction(): 'post' | 'reg' | 'tmp' | 'get' | 'mod' | 'getVars' | 'link' | 'webPost' | 'webGet' | 'webPut' | 'webDelete' | '' {
    switch (this.to.buttonFunction) {
      case 'post':
      case 'reg':
      case 'tmp':
      case 'get':
      case 'mod':
      case 'getVars':
      case 'link':
      case 'webPost':
      case 'webGet':
      case 'webPut':
      case 'webDelete':
        return this.to.buttonFunction;
      default:
        return '';
    }
  }

  get isFinalSubmit(): boolean {
    switch (this.buttonFunction) {
        case 'reg':
        case 'post':
          return true;
        case 'webPost':
        case 'webPut':
        case 'webDelete':
          return this.createReg;
        default:
          return false;
      }
  }

  get icon(): string {
    return this.to.icon;
  }

  get iconPlacement(): 'start' | 'end' | 'icon-only' {
    switch (this.to.iconPlacement) {
      case 'end':
      case 'icon-only':
        return this.to.iconPlacement;
      default:
        return 'start';
    }
  }

  get shouldSubmit(): boolean {
    switch (this.buttonFunction) {
      case 'reg':
      case 'post':
      case 'get':
      case 'tmp':
        return true;
      default:
        return false;
    }
  }

  get clearKeys(): string[] {
    if (typeof this.to.clearKeys === 'string') {
      return this.to.clearKeys.split(',');
    }
    else if (Array.isArray(this.to.clearKeys)) {
      return this.to.clearKeys;
    }
    else {
      return [];
    }
  }

  get setKeys(): {key: string, value: any}[] {
    if (Array.isArray(this.to.setKeys)) {
      return this.to.setKeys;
    }
    else if (typeof this.to.setKeys === 'object') {
      return Object.entries(this.to.setKeys).map(e => ({key: e[0], value: e[1]}));
    }
    else {
      return [];
    }
  }

  get focusKey(): string {
    return this.to.focusKey || '';
  }

  get thenMessage(): string {
    return this.to.thenMessage ?? this.to.onDoneMsg ?? '';
  }

  get thenButton(): string {
    return this.to.thenButton || '';
  }

  get showMsg(): {msg: string, duration: number, delay: number} {
    if (typeof this.to.showMsg === 'object' && this.to.showMsg.msg) {
      const msg = {
        msg: this.to.showMsg.msg,
        duration: this.to.showMsg.duration || 1000,
        delay: this.to.showMsg.delay || 0
      };
      return msg;
    }
    else {
      return null;
    }
  }

  get confirmTitle(): string {
    return this.to.confirmTitle || '';
  }

  get confirmMsg(): string {
    return this.to.confirmMsg || '';
  }

  get path(): string {
    return this.to.jsonPath ?? 'result';
  }

  get getBtnPath(): string {
    return this.to.jsonPath ?? '0.Info';
  }

  get errorPath(): string {
    if (this.to.errorPath) {
      return this.to.errorPath;
    }
    else if (this.buttonFunction === 'webGet') {
      return '0.Error';
    }
    else {
      return 'Message';
    }
  }

  get withErrorStatus(): boolean {
    return this.to.withErrorStatus ?? true;
  }

  get setKey(): string {
    return this.to.setKey || 'servermessage';
  }

  get varMap(): any {
    return this.to.variableMap || {};
  }

  get excludeVars(): string[] {
    return this.to.excludeVariables || [];
  }

  get url(): string {
    return this.to.url || '';
  }

  get goUp(): boolean {
    return this.to.goUp || false;
  }

  get goDown(): boolean {
    return this.to.goDown || false;
  }

  get requiredFields(): string[] {
    if (this.to.requiredKeys) {
      return this.to.requiredKeys.split(',');
    }
    else {
      return [];
    }
  }

  get shouldBreakWord(): boolean {
    return this.label && !this.label.trim().includes(' ');
  }

  get paddingLeft(): boolean {
    return !this.field.className?.includes('floatRight');
  }

  get paddingRight(): boolean {
    return !this.field.className?.includes('floatLeft');
  }

  get disabled(): boolean {
    return this.to.disabled ?? false;
  }

  get autotrigger(): boolean {
    return this.disabled ? false : this.to.autotrigger ?? false;
  }

  get triggerData(): {type: TriggerType, key: string, data?: any} {
    return this.to.trigger;
  }

  get showTimeInfo(): boolean {
    return this.to.showTimeInfo ?? this.shouldSubmit;
  }

  get bodyMap(): {[key: string]: string} {
    return this.to.bodyMap || {};
  }

  get postBody(): any {
    return this.to.postBody || {};
  }

  get postData(): any {
    if (this.to.useModelInPost) {
      return this.model;
    }
    else {
      let body = this.util.createCopyOfObject(this.postBody);
      for (const [bodyKey, formKey] of Object.entries(this.bodyMap)) {
        body = this.util.dotSet(body, bodyKey, this.model[formKey]);
      }
      return body;
    }
  }

  get exitOnDone(): boolean {
    return this.to.exitOnDone ?? false;
  }

  get createReg(): boolean {
    return this.to.createReg ?? false;
  }

  get formId(): number {
    return this.to?.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  get pageRef(): PageRefs {
    return this.to?.currentForm?.ref;
  }

  get fields(): FormlyFieldConfig[] {
    return this.stateService.getFields(this.formType, this.formId);
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    if (this.autotrigger) {
      setTimeout(() => {
        this.click();
      });
    }
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length > 0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => this.click());
    }
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Click || type === TriggerType.Update) {
      this.click();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async click() {
    if (this.to.disabled) return;

    if (this.confirmTitle) {
      const confirmed = await this.popup.showConfirm(this.confirmTitle, this.confirmMsg, false);
      if (confirmed) {
        this.doClick();
      }
    }
    else {
      this.doClick();
    }
  }

  async doClick() {
    const date = moment().format('YYYY-MM-DDTHH:mm:ss');
//    this.clickedAt = moment().format('HH:mm');
    await this.fieldService.setValue(date);

    console.log('click');

    if (this.shouldSubmit) {
      this.doSubmit();
    }
    else if (this.buttonFunction === 'mod') {
      this.modButton();
    }
    else if (this.buttonFunction === 'getVars') {
      this.getVars();
    }
    else if (this.buttonFunction === 'link') {
      this.openLink();
    }
    else if (this.buttonFunction === 'webPost') {
      this.doWebPost();
    }
    else if (this.buttonFunction === 'webGet') {
      this.doWebGet();
    }
    else if (this.buttonFunction === 'webPut') {
      this.doWebPut();
    }
    else if (this.buttonFunction === 'webDelete') {
      this.doWebDelete();
    }
  }

  async doSubmit() {
    if (!this.pageRef || !this.formId || !this.formType) return;

      const page: FormPage | FormViewComponent | SubFormComponent = this.ref.getReference(this.pageRef, this.formId, this.formType)[0];
      if (!page) {
        return;
      }

      if (this.buttonFunction === 'get' && this.isMissingRequiredValues()) {
        return;
      }

      this.model['buttonfunction'] = this.buttonFunction;
      this.model['pressed'] = this.key;
      this.loading = true;
      await page.submit(this.model);
      this.loading = false;
      this.fieldService.triggerChange();
  }

  /**
   * "Modify" form based on values in button
   */
  async modButton() {
    if (this.clearKeys.length > 0) {
      const clearVals = {};
      for (const key of this.clearKeys) {
        clearVals[key] = '';
        const field = this.stateService.findField(key, this.formType, this.formId);
        if (this.fieldsToClear.includes(field?.type as any)) {
          this.ref.callReference(key, this.formId, this.formType, TriggerType.Clear);
        }
      }
      this.setVars(clearVals);
    }
    else if (this.to.clearForm) {
      this.clearForm();
    }
    if (this.setKeys.length > 0) {
      const setVals = {};
      for (const keyVal of this.setKeys) {
        setVals[keyVal.key] = keyVal.value;
      }
      this.setVars(setVals);
    }
    if (this.showMsg) {
      const msg = this.showMsg.msg;
      const duration = this.showMsg.duration;
      const delay = this.showMsg.delay;
      const that = this;
      setTimeout(() => {
        that.popup.showMessage(msg, false, '', duration);
      }, delay);
    }
    else if (this.thenMessage) {
      this.popup.showAlert(this.thenMessage, '', false);
    }

    if (this.thenButton) {
      this.ref.callReference(this.thenButton, this.formId, this.formType, TriggerType.Click);
    }
    if (this.focusKey) {
      const page: FormPage | FormViewComponent | SubFormComponent = this.ref.getReference(this.pageRef, this.formId, this.formType)[0];
      this.ref.callReference(this.focusKey, this.formId, this.formType, TriggerType.Focus, null, page);
    }
    else if (this.goUp) {
      const items = document.querySelectorAll('ion-content');
      if (items.length > 0) {
        const content: HTMLIonContentElement = items[items.length - 1];
        content.scrollToTop(500);
        if (this.stateService.state === State.Form && this.to && this.to.currentForm) {
          const page: FormPage = this.ref.getReference(this.pageRef, this.formId, this.formType)[0];
          if (page) {
            page.goToFirstStep();
          }
        }
      }
    }
    else if (this.goDown) {
      const items = document.querySelectorAll('ion-content');
      if (items.length > 0) {
        const content: HTMLIonContentElement = items[items.length - 1];
        content.scrollToBottom(500);
        if (this.stateService.state === State.Form && this.to && this.to.currentForm) {
          const page: FormPage = this.ref.getReference(this.pageRef, this.formId, this.formType)[0];
          if (page) {
            page.goToLastStep();
          }
        }
      }
    }

    if (this.triggerData) {
      await this.ref.callReference(this.triggerData.key, this.formId, this.formType, this.triggerData.type, this.triggerData.data);
    }
  }

  setVars(vars: any) {
    if (!this.pageRef) return;

    const page: FormPage | FormViewComponent | SubFormComponent = this.ref.getReference(this.pageRef, this.formId, this.formType)[0];
    if (page) {
      page.setVars(vars);
    }
  }

  clearForm() {
    if (!this.pageRef) return;

    const page: FormPage | FormViewComponent | SubFormComponent = this.ref.getReference(this.pageRef, this.formId, this.formType)[0];
    if (page) {
      page.clearForm(false);
    }
  }

  /**
   * Get data from API and set to the fields of the form
   */
  getVars() {
    if (!this.url) return;

    const fields = this.stateService.getFields(this.formType, this.formId);
    if (!fields) return;

    this.loading = true;

    const varMap = this.varMap;
    if (Object.keys(varMap).length === 0) {
      for (const field of fields) {
        if (!this.excludeVars.includes(field.key as string)) {
          varMap[field.key as string] = field.key;
        }
      }
    }

    if (Object.keys(varMap).length > 0) {
      const url = this.util.parseText(this.url, this.model, this.fields);
      this.api.getWebJSON(url, this.formId, this.to.headers, this.to.useCredentials).subscribe(res => {
        this.loading = false;
        const result = this.util.dotRef(res.value, this.path);
        if (!result) return;

        for (const key in result) {
          result[key.toLowerCase()] = result[key];
        }

        const data = {};
        for (const [key, value] of Object.entries(varMap)) {
          if (typeof value !== 'string') {
            continue;
          }
          data[key] = this.util.dotRef(result, value.toLowerCase());

          const field = fields.find(f => f.key === key);

          if (data[key] && this.time.isTimeOrDate(field)) {
            const type = this.time.getTimeType(field);
            data[key] = this.time.formatDatetime(data[key], type);
          }
          else if (data[key] && (field?.type === 'paint' || field?.type === 'signature')) {
            this.api.imageDownload(data[key]).subscribe(({value: image, error}) => {
              if (image) {
                this.setExternal(key, image);
              }
              else {
                this.logger.error('Error downloading image', error);
              }
            });
            delete data[key];
          }
          else if (data[key] && field?.type === 'photo') {
            this.setExternal(key, data[key]);
            delete data[key];
          }
        }
        for (const key in data) {
          if (data[key] == null) {
            delete data[key];
          }
        }
        this.setVars(data);
      });
    }
  }

  /**
   * Open link to external web site
   */
  openLink() {
    const url = this.util.parseText(this.url, this.model, this.fields);
    if (this.util.textIsParsed(url)) {
      this.browser.openUrl(url);
    }
  }

  /**
   * Do POST request to API directly from app, instead of using `doReg`.
   */
  doWebPost() {
    if (!this.url || this.isMissingRequiredValues()) {
      return;
    }
    this.loading = true;
    const url = this.util.parseText(this.url, this.model, this.fields);
    this.api.postWebJSON(url, this.postData, this.to.headers, this.to.useCredentials).subscribe(({status, error}) => {
      this.loading = false;
      if (status === ApiStatus.Failed) {
        if (error) {
          const title = this.translate.instant('WebPostFailed');
          const text = this.getErrorMessage(error);
          this.popup.showAlert(title, text, false);
        }
        else {
          this.popup.showMessage('WebPostFailed', true, 'danger');
        }
      }
      else if (status === ApiStatus.Offline) {
        this.popup.showMessage('WebPostOffline', true, 'warning');
      }
      else {
        if (this.createReg) {
          this.doSubmit();
        }
        else if (this.exitOnDone) {
          this.clearForm();
          if (this.formType === FormType.Form) {
            this.ref.callReference(this.pageRef, this.formId, this.formType, TriggerType.GoBack);
          }
        }
        else {
          this.modButton();
        }
      }
    });
  }

  /**
   * Do PUT request to API directly from app
   */
  doWebPut() {
    if (!this.url || this.isMissingRequiredValues()) {
      return;
    }
    this.loading = true;
    const url = this.util.parseText(this.url, this.model, this.fields);
    this.api.putWebJSON(url, this.postData, this.to.headers, this.to.useCredentials).subscribe(({status, error}) => {
      this.loading = false;
      if (status === ApiStatus.Failed) {
        if (error) {
          const title = this.translate.instant('WebPostFailed');
          const text = this.getErrorMessage(error);
          this.popup.showAlert(title, text, false);
        }
        else {
          this.popup.showMessage('WebPostFailed', true, 'danger');
        }
      }
      else if (status === ApiStatus.Offline) {
        this.popup.showMessage('WebPostOffline', true, 'warning');
      }
      else {
        if (this.createReg) {
          this.doSubmit();
        }
        else if (this.exitOnDone) {
          this.clearForm();
          if (this.formType === FormType.Form) {
            this.ref.callReference(this.pageRef, this.formId, this.formType, TriggerType.GoBack);
          }
        }
        else {
          this.modButton();
        }
      }
    });
  }

  /**
   * Do PUT request to API directly from app
   */
  doWebDelete() {
    if (!this.url || this.isMissingRequiredValues()) {
      return;
    }
    this.loading = true;
    const url = this.util.parseText(this.url, this.model, this.fields);
    this.api.deleteWebJSON(url, this.to.headers, this.to.useCredentials).subscribe(({status, error}) => {
      this.loading = false;
      if (status === ApiStatus.Failed) {
        if (error) {
          const title = this.translate.instant('WebPostFailed');
          const text = this.getErrorMessage(error);
          this.popup.showAlert(title, text, false);
        }
        else {
          this.popup.showMessage('WebPostFailed', true, 'danger');
        }
      }
      else if (status === ApiStatus.Offline) {
        this.popup.showMessage('WebPostOffline', true, 'warning');
      }
      else {
        if (this.createReg) {
          this.doSubmit();
        }
        else if (this.exitOnDone) {
          this.clearForm();
          if (this.formType === FormType.Form) {
            this.ref.callReference(this.pageRef, this.formId, this.formType, TriggerType.GoBack);
          }
        }
        else {
          this.modButton();
        }
      }
    });
  }

  /**
   * Do GET request to API directly from app and set the data to an `servermessage` field, instead of using `doReg`.
   */
  doWebGet() {
    if (!this.url || this.isMissingRequiredValues()) {
      return;
    }
    this.loading = true;
    const url = this.util.parseText(this.url, this.model, this.fields);
    this.api.getWebJSON(url, this.formId, this.to.headers, this.to.useCredentials).subscribe(({value: data, status, error}) => {
      this.loading = false;
      const result = this.util.dotRef(data, this.getBtnPath);
      if (status === ApiStatus.Failed || !result) {
        const title = this.translate.instant('WebGetFailed');
        if (error) {
          const text = `${error.status}: ${error.message}`;
          this.popup.showAlert(title, text, false);
        }
        else {
          const errorMsg = this.util.dotRef(data, this.errorPath);
          if (errorMsg) {
            this.popup.showAlert(title, errorMsg, false);
          }
          else {
            this.popup.showMessage(title, false, 'danger');
          }
        }
      }
      else {
        this.ref.callReference(this.setKey, this.formId, this.formType, TriggerType.Set, result);
        this.modButton();
      }
    });
  }

  setExternal(key: string, data: string) {
    this.ref.callReference(key, this.formId, this.formType, TriggerType.Edit, data);
  }

  /**
   * Checks if there are missing required values for doing GET
   */
  private isMissingRequiredValues() {
    if (this.requiredFields.length === 0) {
      return false;
    }
    const reqFields: string[] = [];
    for (const key of this.requiredFields) {
      if (typeof this.model[key] === 'undefined' || this.model[key] === '' || this.model[key] === null) {
        const field = this.stateService.findField(key, this.formType, this.formId);
        const label = field?.templateOptions.label ? `${field.templateOptions.label} (${key})` : key;
        reqFields.push(label);
      }
    }
    if (reqFields.length > 0) {
      const title = this.translate.instant('GetFieldsRequired');
      this.popup.showAlert(title, reqFields.join('<br>'), false);
      return true;
    }
    else {
      return false;
    }
  }

  private getErrorMessage(error: HttpErrorResponse) {
    const message = this.util.dotRef(error.error, this.errorPath) || error.message;
    return this.withErrorStatus ? `${error.status}: ${message}` : message;
  }
}
