import { UpdateKeyReg } from './../models/interfaces/registration';
import { Injectable } from '@angular/core';
import { Observable, of, from, forkJoin, zip, firstValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { map, catchError, mergeMap, tap, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
import { OnlineService } from './online.service';
import { InStorageService } from './in-storage.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { InLoggerService } from './in-logger.service';
import { StateService } from './state.service';
import * as moment from 'moment';
import { LegacyService } from './legacy.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiErrorLogService } from './api-error-log.service';
import { ApiType, ApiEnvironment, Result, PasswordChange, ApiData, Form, ApiStatus, FormWithValues,
         Context, UserInfo, UserProfile, Tenant, OldReg, NewReg, SortBy, Project, StylePaths, StyleData, LookupValue,
         LogEntry, SignInData, RegData, FeedbackBody, AppVersion, BoxStatus, OnlineStatus } from '../models/models';
import { ThemeService } from './theme.service';
import { DeviceService } from './device.service';
import { BrowserService } from './browser.service';


/**
 * Service for doing all API calls
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  currentEnv: ApiEnvironment;
  options: HttpHeaders;
  inBase: string;
  serviceUrl: string;
  apirUrl: string;
  maxRetries: number;

  httpEnd: HttpClient;

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private util: UtilityService,
    private online: OnlineService,
    private storage: InStorageService,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private inLogger: InLoggerService,
    private stateService: StateService,
    private legacy: LegacyService,
    private apiError: ApiErrorLogService,
    private theme: ThemeService,
    private device: DeviceService,
    private browser: BrowserService
  ) {
    this.options = new HttpHeaders().set('Content-Type', 'application/json');
    this.setApiEnv(ApiEnvironment.Prod, false);
    this.apirUrl = environment.apirUrl;
    this.maxRetries = environment.maxApiRetries;
    this.httpEnd = new HttpClient(httpBackend); //Needed for login with interceptor - not watched.
  }

  /**
   * Change the backend API url
   * @param env The environment to change to: Prod, Test, Dev or Custom
   * @param save (Optional) If the environment should be saved in storage, default: true
   */
  async setApiEnv(env: ApiEnvironment, save: boolean = true) {
    this.currentEnv = env;
    if (save) {
      this.storage.setApiEnv(env);
    }
    switch (env) {
      case ApiEnvironment.Test:
        this.inBase = environment.inBaseUrlTest;
        break;
      case ApiEnvironment.Dev:
        this.inBase = environment.inBaseUrlDev;
        break;
      case ApiEnvironment.Custom:
        this.inBase = await this.setCustomEnv();
        break;
      default:
        this.inBase = environment.inBaseUrl;
    }
    this.serviceUrl = `${this.inBase}/api/services/app`;
  }

  /**
   * Sign in an user
   * @param tenant The users tenant
   * @param username The users username
   * @param password The users password
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  signIn(tenant: string, username: string, password: string, allowOffline: boolean = true): Observable<ApiData<SignInData>> {
    if (this.online.isOnline()) {
      const body = {
        TenancyName: tenant,
        UsernameOrEmailAddress: username,
        Password: password
      };
      const url = `${this.inBase}/Account/Login`;
      return this.postEnd(url, body).pipe(
        map<Result, ApiData<SignInData>>((res: Result) => {
          if (res.success) {
            return {
              status: ApiStatus.Success,
              value: {
                redirect: res.targetUrl.startsWith('/Account/ResetPassword'),
                url: res.targetUrl,
                password: res.result
              }
            };
          }
          else {
            return {value: {}, status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          if (allowOffline && err.status !== 400) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.signIn(tenant, username, password);
          }
          else {
            return of({value: {}, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return new Observable<ApiData<SignInData>> (obs => {
        this.storage.getUser().then(user => {
          let success = false;
          if (user && user.tenant === tenant && user.username === username && user.password === password) {
            success = true;
          }
          const res: ApiData<SignInData> = {
            value: {},
            status: success ? ApiStatus.Offline : ApiStatus.Failed
          };
          if (success) {
            res.value.password = password;
          }
          obs.next(res);
        });
      });
    }
    else {
      return of({value: {}, status: ApiStatus.Failed});
    }
  }

  /**
   * Sign out user (will clear session cookie)
   */
  signOut(): Observable<ApiData<boolean>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.hasNetwork()) {
      const url = `${this.inBase}/Account/Logout`;
      return this.post(url, {responseType: 'text', withCredentials: true}).pipe(
        map(() => ({value: true, status: ApiStatus.Success})),
        catchError((err) => {
          if (err.status !== 200) {
            this.apiError.logInternalApiFail(location, 'Error signing out', err, 'Logout');
            return of({value: false, status: ApiStatus.Failed, error: err});
          }
          else {
            return of({value: true, status: ApiStatus.Success});
          }
        })
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Set an new password for an user
   * @param password The new password
   * @param urlId The URL id
   */
  resetPassword(password: string, urlId: string): Observable<ApiData<boolean>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.inBase}${urlId}`;
      const body = {
        Password: password,
        confirmPassword: password,
        url: urlId
      };
      return this.post(url, body).pipe(
        map<Result, ApiData<boolean>>(res => res?.success ? {value: true, status: ApiStatus.Success}
                                                          : {value: false, status: ApiStatus.Failed}
        ),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 200 && err.url && err.url === 'https://in.tempus.no/Application') {
            return of({value: true, status: ApiStatus.Success});
          }
          else {
            this.apiError.logInternalApiFail(location, 'Reset password failed', err, 'ResetPassword');
            return of({value: false, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Change the users password
   * @param change The password change
   */
  changePassword(change: PasswordChange): Observable<ApiData<boolean>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/profile/ChangePassword`;
      return this.post(url, change).pipe(
        map<Result, ApiData<boolean>>((res) => res?.success ? {value: true, status: ApiStatus.Success}
                                                            : {value: false, status: ApiStatus.Failed}
        ),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error changing password', err, 'ChangePassword');
          return of({value: false, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Get username and password from D4 LogInfo string
   * @param logInfo The LogInfo string
   */
  getUsernameAndPassword(logInfo: string): Observable<ApiData<{username: string, password: string}>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = 'https://d4functions.azurewebsites.net/api/GetUserInfo';
      const body = {
        logInfo: logInfo
      };
      const headers = {
        'x-functions-key': 'aCPsXUDLCYbTzn5QRSC01rFTDJDxXnvYoVRrb7cDCG/E2Mddcza0fw=='
      };
      return this.httpEnd.post(url, body, {headers}).pipe(
        map<any, ApiData<{username: string, password: string}>>(res => {
          if (res?.username && res?.password) {
            return {value: {username: res.username, password: res.password}, status: ApiStatus.Success};
          }
          else {
            return {value: {username: '', password: ''}, status: ApiStatus.Failed};
          }
        }),
        catchError((err) => {
          this.apiError.logExternalApiFail(location, url, 'Error sending log', err, ApiType.InAzure);
          return of({value: {username: '', password: ''}, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: {username: '', password: ''}, status: ApiStatus.Offline});
    }
  }

  /**
   * Change profile picture of user
   * @param picture The new profile picture
   */
  changeProfilePicture(picture: Blob): Observable<ApiData<boolean>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.inBase}/Profile/ChangeProfilePicture`;
      return this.postImage(url, picture, 'profilepic.jpg').pipe(
        map((response) => {
          // It returns login-page as string if user isn\'t logged in
          if (typeof response === 'string' && response.length > 0) {
            return {value: false, status: ApiStatus.Failed};
          }
          else {
            return {value: true, status: ApiStatus.Success};
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error changing profile picture', err, 'ChangeProfilePicture');
          return of({value: false, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Download profile picture and return local url
   */
  getProfilePicture(): Observable<ApiData<string>> {
    if (this.online.isOnline()) {
      const url = `${this.inBase}/Profile/GetProfilePicture`;
      return this.http.get(url, {responseType: 'blob', withCredentials: true}).pipe(
        map<Blob, ApiData<string>>(res => ({value: this.browser.createImageUrl(res), status: ApiStatus.Success})),
        catchError(err => of({value: '', status: ApiStatus.Failed, error: err}))
      );
    }
    else {
      return of({value: '', status: ApiStatus.Offline});
    }
  }

  /**
   * Get last used forms for the users for the current project
   * @param projectId (Optional) Project id to filter by
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getLastUsedForms(projectId?: number, allowOffline: boolean = true): Observable<ApiData<Form[]>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    const urlAddon = (typeof projectId === 'undefined' || projectId === null) ? '' : `?projectId=${projectId}`;
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/xform/GetLastUsedForms${urlAddon}`;
      return this.post(url, null).pipe(
        map<Result, ApiData<Form[]>>(res => {
          if (res?.success) {
            const forms: Form[] = this.fixForms(res?.result);
            if (allowOffline) {
              this.storage.setLastUsedForms(forms);
            }
            return {value: forms, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error getting forms', res.error);
            return {value: [], status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error getting forms', err, 'GetLastUsedForms');
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getLastUsedForms(projectId);
          }
          else {
            return of({value: [], status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getLastUsedForms()).pipe(
        map<Form[], ApiData<Form[]>>(forms => ({value: forms, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: [], status: ApiStatus.Offline});
    }
  }
  /**
   * Get all the users forms for the current project
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getForms(allowOffline: boolean = true): Observable<ApiData<Form[]>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/xform/GetFormsWithStoreDate`;
      return this.post(url, null).pipe(
        map<Result, ApiData<Form[]>>(res => {
          if (res?.success) {
            const forms: Form[] = this.fixForms(res?.result);
            if (allowOffline) {
              this.storage.setForms(forms);
            }
            return {value: forms, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error getting forms', res.error);
            return {value: [], status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error getting forms', err, 'GetFormsWithStoreDate');
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getForms();
          }
          else {
            return of({value: [], status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getForms()).pipe(
        map<Form[], ApiData<Form[]>>(form => ({value: form, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: [], status: ApiStatus.Offline});
    }
  }
  /**
   * Get forms with given id for the current project (currently used for getting favorite forms)
   * @param ids Get a list of forms with given formIds.
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getFormsById(ids: string, allowOffline: boolean = true): Observable<ApiData<Form[]>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/xform/GetFormsListByIds?ids=${ids}`;
      return this.post(url, null).pipe(
        map<Result, ApiData<Form[]>>(res => {
          if (res?.success) {
            const forms: Form[] = this.fixForms(res?.result);
            if (allowOffline) {
              this.storage.setFavoriteForms(forms);
            }
            return {value: forms, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error getting forms', res.error);
            return {value: [], status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error getting forms', err, 'GetFormsListByIds');
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getFormsById(ids);
          }
          else {
            return of({value: [], status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getFavoriteForms()).pipe(
        map<Form[], ApiData<Form[]>>(forms => ({value: forms, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: [], status: ApiStatus.Offline});
    }
  }

  /**
   * Gets an specific form
   * @param id FormId of the form to get
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getForm(id: number, allowOffline: boolean = true): Observable<ApiData<FormWithValues>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/xform/getNewOrUnsignedFormWithValues?formId=${id}`;
      return this.post(url, null).pipe(
        mergeMap(async res => {
          if (res?.success && res?.result) {
            const form: FormWithValues = res.result;
            if (!form.theForm?.isActive) {
              this.apiError.logNoResult(location, `Form ${id} is not active`, {message: 'Form is not active'}, {formId: id});
              return {value: null, status: ApiStatus.Failed};
            }
            form.theForm = this.fixForm(form.theForm);
            if (allowOffline ) {
              await this.storage.setForm(id, form);
            }
            return {value: form, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, `Error getting form ${id}`, res.error, {formId: id});
            return {value: null, status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, `Error getting form ${id}`, err, 'getNewOrUnsignedFormWithValues', {formId: id});
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getForm(id);
          }
          else {
            return of({value: null, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getForm(id)).pipe(
        map<FormWithValues, ApiData<FormWithValues>>((form) => ({value: form, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Get how many fields an form has
   * @param formId The id of the form
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getFieldCount(formId: number, allowOffline = true): Observable<ApiData<number>> {
    if (this.online.isOnline()) {
      return this.getForm(formId, false).pipe(
        map<ApiData<FormWithValues>, ApiData<number>>(data => {
          if (data.value) {
            const count = this.util.parseFields(data.value?.theForm?.definition, true).length;
            if (allowOffline) {
              this.storage.addFieldCount(formId, count);
            }
            return {
              value: count,
              status: ApiStatus.Success
            };
          }
          else {
            const newData: ApiData<number> = {
              value: null,
              status: data.status
            };
            if (data.error) {
              newData.error = data.error;
            }
            return newData;
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getFieldCount(formId)).pipe(
        map<number, ApiData<number>>(value => ({value: value, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Updates the given forms in storage
   * @param formIds The ids of the forms to update
   */
  getAndStoreUpdatedForms(formIds: number[]): Observable<ApiData<FormWithValues>[]> {
    if (this.online.isOnline()) {
      const downloads: Observable<ApiData<FormWithValues>>[] = [];
      for (const id of formIds) {
        const obs = this.getForm(id, false).pipe(
          tap(data => {
            if (data.status === ApiStatus.Success) {
              this.storage.setForm(id, data.value);
            }
          })
        );
        downloads.push(obs);
      }
      return forkJoin(downloads);
    }
    else {
      return of([]);
    }
  }

  /**
   * Get user context
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getUserContext(allowOffline: boolean = true): Observable<ApiData<Context>> {
    if (this.online.isOnline()) {
      return this.getContext({source: 'https://in.tempus.no/?app=IN'}, false).pipe(
        tap(({value: user, status}) => {
          if (status === ApiStatus.Success && allowOffline) {
            this.storage.setUserContext(user);
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getUserContext()).pipe(
        map<Context, ApiData<Context>>(user => ({value: user, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Gets information for the user (e.g. full name etc.)
   * @param tenant The tenant of the user
   * @param username The username of the user
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getUserInfo(tenant: string, username: string, allowOffline: boolean = true): Observable<ApiData<UserInfo>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.apirUrl}/users?tenant=${tenant}&user=${username}&includeAdmin=true`;
      return this.http.get(url).pipe(
        map<any[], ApiData<UserInfo>>(res => {
          this.apiError.externalSuccess(url);
          if (Array.isArray(res) && res.length > 0) {
            const userInfo: UserInfo = res[0];
            if (allowOffline) {
              this.storage.setUserInfo(userInfo);
            }
            return {value: userInfo, status: ApiStatus.Success};
          }
          else {
            return {value: null, status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logExternalApiFail(location, url, 'Error getting userinfo', err, ApiType.InApir, {tenant, username});
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.ExternalFail, err.status, 'InApir');
            return this.getUserInfo(tenant, username);
          }
          else {
            return of({value: null, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getUserInfo()).pipe(
        map<UserInfo, ApiData<UserInfo>>(res => ({value: res, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Gets the current users profile
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getCurrentUserProfile(allowOffline: boolean = true): Observable<ApiData<UserProfile>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/profile/GetCurrentUserProfileForEdit`;
      return this.post(url, null).pipe(
        map<Result, ApiData<UserProfile>>((res) => {
          if (res?.success) {
            if (allowOffline) {
              this.storage.setUserProfile(res.result);
            }
            return {value: res.result, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error getting user profile', res.error);
            return {value: null, status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err) => {
          this.apiError.logInternalApiFail(location, 'Error getting user profile', err, 'GetCurrentUserProfileForEdit');
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getCurrentUserProfile();
          }
          else {
            return of({value: null, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getUserProfile()).pipe(
        map<UserProfile, ApiData<UserProfile>>(user => ({value: user, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Update the current user profile
   * @param user The new user profile
   */
  updateCurrentUserProfile(user: UserProfile): Observable<ApiData<boolean>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/profile/UpdateCurrentUserProfile`;
      return this.post(url, user).pipe(
        map<Result, ApiData<boolean>>(res => {
          if (res?.success) {
            return {value: true, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error updating user profile', res.error, user);
            return {value: false, status: ApiStatus.Failed};
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error updating, user profile', err, 'UpdateCurrentUserProfile', user);
          return of({value: false, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Get tenant by id
   * @param id Id of tenant
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getTenant(id: number, allowOffline: boolean = true): Observable<ApiData<Tenant>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.apirUrl}/tenant/${id}`;
      return this.http.get<Tenant>(url).pipe(
        map<Tenant, ApiData<Tenant>>(tenant => {
          this.stateService.currentTenant = tenant;
          if (tenant) {
            this.apiError.externalSuccess(url);
            if (allowOffline) {
              this.storage.setTenant(tenant, id);
              this.storage.setTenant(tenant);
            }
            return {value: tenant, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error getting tenant', null, {tenantId: id});
            return {value: null, status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logExternalApiFail(location, url, 'Error getting tenant', err, ApiType.InApir, {tenantId: id});
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.ExternalFail, err.status, 'InApir');
            return this.getTenant(id);
          }
          else {
            return of({value: null, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getTenant(id)).pipe(
        map<Tenant, ApiData<Tenant>>(tenant => ({value: tenant, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * @deprecated Use `getTenantName` instead
   *
   * Get information of the tenant using tenancyname
   * @param tenant TenancyName
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getTenantInfo(tenant: string, allowOffline: boolean = true): Observable<ApiData<Tenant>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.apirUrl}/tenant`;
      tenant = tenant.toLowerCase();
      return this.http.get(url).pipe(
        map<any[], ApiData<Tenant>>((res: any[]) => {
          this.apiError.externalSuccess(url);
          if (!res) res = [];
          const tenantInfo = res.find(el => el.TenancyName && el.TenancyName.toLowerCase() === tenant);
          if (tenantInfo) {
            const ten: Tenant = {Id: tenantInfo.Id, Name: tenantInfo.Name, TenancyName: tenantInfo.TenancyName};
            this.stateService.currentTenant = ten;
            if (allowOffline) {
              this.storage.setTenant(ten, tenant);
              this.storage.setTenant(ten);
            }
            return {value: ten, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, `Error getting ${tenant}`, null, {tenancyname: tenant, result: res});
            return {value: null, status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logExternalApiFail(location, url, `Error getting tenant ${tenant}`, err, ApiType.InApir, {tenancyname: tenant});
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.ExternalFail, err.status, 'InApir');
            return this.getTenantInfo(tenant);
          }
          else {
            return of({value: null, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getTenant(tenant)).pipe(
        map<Tenant, ApiData<Tenant>>(t => ({value: t, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Get the full name of an tenant
   * @param tenant The tenancy name
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getTenantName(tenant: string, allowOffline: boolean = true): Observable<ApiData<string>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.apirUrl}/tenantname?tenancyName=${tenant}`;
      return this.http.get(url).pipe(
        map<any, ApiData<string>>(res => {
          if (Array.isArray(res) && res.length > 0 && res[0]['Name']) {
            this.apiError.externalSuccess(url);
            if (allowOffline) {
              this.storage.setTenantName(tenant, res[0]['Name']);
            }
            return {value: res[0]['Name'], status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, `Error getting name of tenant ${tenant}`, null, {tenancyname: tenant, result: res});
            return {value: '', status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logExternalApiFail(location, url, `Error getting tenant name of ${tenant}`, err, ApiType.InApir, {tenancyname: tenant});
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.ExternalFail, err.status, 'InApir');
            return this.getTenantName(tenant);
          }
          else {
            return of({value: '', status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getTenantName(tenant)).pipe(
        map<string, ApiData<string>>(name => ({value: name, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: '', status: ApiStatus.Offline});
    }
  }

  /**
   * Get earlier registrations for history
   * @param projectId (Optional) Project id to filter by
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getRegistrations(projectId?: number, allowOffline: boolean = true): Observable<ApiData<OldReg[]>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    const urlAddon = (typeof projectId === 'undefined' || projectId === null) ? '' : `?projectId=${projectId}`;
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/xform/getHistory${urlAddon}`;
      return this.post(url, null).pipe(
        map<Result, ApiData<OldReg[]>>(res => {
          if (res?.success) {
            const regs = this.fixRegistrations(res.result);
            if (allowOffline) {
              this.storage.setHistory(regs, projectId);
            }

            return {value: regs, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error getting registrations', res.error);
            return {value: [], status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error getting registrations', err, 'getHistory');
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getRegistrations(projectId);
          }
          else {
            return of({value: [], status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getHistory(projectId)).pipe(
        map<OldReg[], ApiData<OldReg[]>>(regs => ({value: regs, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: [], status: ApiStatus.Offline});
    }
  }

  /**
   * Find the last registration for an given form
   * @param formId Id of the form
   * @param projectId (Optional) Give this to filter registrations by project id
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getLastRegistration(formId: number, projectId?: number, allowOffline = true): Observable<ApiData<OldReg>> {
    return this.getRegistrations(projectId, allowOffline).pipe(
      map<ApiData<OldReg[]>, ApiData<OldReg>>(data => {
        let reg: OldReg = null;
        if (data.error) {
          return {value: null, status: ApiStatus.Failed, error: data.error};
        }
        if (data.value.length > 0) {
          reg = data.value.find(r => r.formId === formId) ?? null;
          if (reg && allowOffline) {
            this.storage.setLastRegistration(reg, formId, projectId);
          }
        }
        return {value: reg, status: data.status};
      })
    );
  }

  /**
   * Get the content of an earlier registration
   * @param regId Id of the registration
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getRegistration(regId: number, allowOffline: boolean = true): Observable<ApiData<FormWithValues>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/xform/getFormWithReg?regId=${regId}&matchVersion=true`;
      return this.post(url, null).pipe(
        map<Result, ApiData<FormWithValues>>(res => {
          if (res && res.success) {
            if (allowOffline) {
              this.storage.setRegistration(regId, res.result);
            }
            return {value: res.result, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, `Error getting registration ${regId}`, res.error, {regId: regId});
            return {value: null, status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, `Error getting registration ${regId}`, err, 'getFormWithReg', {regId: regId});
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getRegistration(regId);
          }
          else {
            return of({value: null, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getRegistration(regId)).pipe(
        map<FormWithValues, ApiData<FormWithValues>>(reg => ({value: reg, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Submits an registration
   * @param registration The registration to submit
   */
  doRegistration(registration: NewReg): Observable<ApiData<RegData>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      this.inLogger.log('Doing registration', {formId: registration.FormId, buttonfunction: registration.ButtonFunction});
      const url = `${this.serviceUrl}/xform/DoReg`;
      return this.post(url, registration).pipe(
        map<Result, ApiData<RegData>>(result => {
          if (result?.success) {
            return {value: {data: result.result}, status: ApiStatus.Success};
          }
          else {
            if (result?.error?.message === 'Error') {
              this.apiError.logNoResult(location, 'Error doing registration', result?.error, registration);
            }
            return {value: {errorMessage: result?.error?.message}, status: ApiStatus.Failed};
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error doing registration', err, 'DoReg', registration);
          return of({value: {errorMessage: err.message}, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Delete all unsigned registrations that are newer than the last signed registration
   * @param formId The id of the form
   */
  deleteUnsignedRegistrations(formId: number): Observable<ApiData<boolean>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/xform/DeleteUnsigned?formId=${formId}`;
      return this.delete(url).pipe(
        map((data: Result) => {
          if (data?.success) {
            return {value: true, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, `Error deleting unsigned regs of form ${formId}`, data?.result);
            return {value: false, status: ApiStatus.Failed};
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, `Error deleting unsigned regs of form ${formId}`, err, 'DeleteUnsigned');
          return of({value: false, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Get the users projects
   * @param sortBy (Optional) How to sort the projects (default: NoSort)
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getProjects(sortBy: SortBy = SortBy.NoSort, allowOffline: boolean = true): Observable<ApiData<Project[]>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/project/getSetupProjects`;
      return this.post(url, null).pipe(
        map<Result, ApiData<Project[]>>(res => {
          if (!res?.success) {
            this.apiError.logNoResult(location, 'Error getting projects', res.error);
            return {value: [], status: ApiStatus.Failed};
          }
          let projects: Project[] = res.result ?? [];
          projects = projects.filter(p => p.active);
          if (sortBy !== SortBy.NoSort) {
            projects.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : (b[sortBy] > a[sortBy]) ? -1 : 0);
          }
          if (allowOffline) {
            this.storage.setProjects(projects);
          }
          return {value: projects, status: ApiStatus.Success};
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error getting projects', err, 'GetSetupProjects');
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getProjects(sortBy);
          }
          else {
            return of({value: [], status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getProjects(sortBy)).pipe(
        map<Project[], ApiData<Project[]>>(projects => ({value: projects, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: [], status: ApiStatus.Offline});
    }
  }

  /**
   * Gets the current project of the user
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getCurrentProject(allowOffline: boolean = true): Observable<ApiData<Project>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/project/getCurrentProject`;
      return this.post(url, null).pipe(
        mergeMap(async res => {
          if (res?.success) {
            let project: Project = res.result;
            if (project && !project.active) {
              const projects = await this.getProjects().toPromise();
              project = projects.value[0];
              if (project) {
                await this.setCurrentProject(project).toPromise();
              }
              else {
                this.apiError.logNoResult(location, 'Error getting current project', res.error);
                return {value: null, status: ApiStatus.Failed};
              }
            }
            this.stateService.currentProject = project;
            if (allowOffline) {
              this.storage.setCurrentProject(project);
            }
            return {value: project, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error getting current project', res.error);
            return {value: null, status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error getting current project', err, 'getCurrentProject');
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getCurrentProject();
          }
          else {
            return of({value: null, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getCurrentProject()).pipe(
        map<Project, ApiData<Project>>(project => ({value: project, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Set the current project
   * @param project The project number or Project object
   */
  setCurrentProject(project: Project): Observable<ApiData<{order: BoxStatus[], projects: Project[]}>> {
    const projectNr = typeof project === 'string' ? project : project?.number;
    if(!projectNr) { //Preview fix. Should not nullify project.
      return of();
    }

    if (this.online.isOnline()) {
      return this.getSetupForm(false).pipe(
        mergeMap<ApiData<FormWithValues>, Observable<ApiData<{order: BoxStatus[], projects: Project[]}>>>(data => {
          const form = data.value;
          if (!form) {
            return data.error ? of({value: null, status: data.status, error: data.error})
                              : of({value: null, status: data.status});
          }
          const values = form.values;
          const index = values.findIndex(v => v.key === 'Prosjekt');
          if (index === -1) {
            values.push({
              formId: form.theForm.id,
              key: 'Prosjekt',
              value: projectNr
            });
          }
          else {
            values[index].value = projectNr;
          }
          const reg: NewReg = {
            FormId: form.theForm.id,
            ValueList: values
          };
          return this.doRegistration(reg).pipe(
            mergeMap(async res => {
              if (res.status === ApiStatus.Success) {
                this.stateService.currentProject = project;
                const [order, projects] = await Promise.all([
                  firstValueFrom(this.getBoxOrder(project.id), {defaultValue: {value: null, status: ApiStatus.Failed}}),
                  firstValueFrom(this.getProjects(SortBy.Name), {defaultValue: {value: [], status: ApiStatus.Failed}}),
                  this.storage.setCurrentProject(project)
                ]);

                return{value: {order: order.value, projects: projects.value}, status: res.status};
              }
              else if (res.error) {
                return {value: null, status: res.status, error: res.error};
              }
              else {
                return {value: null, status: res.status};
              }
            })
          );
        })
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Get label for the project field
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getProjectlabel(allowOffline: boolean = true): Observable<ApiData<string>> {
    if (this.online.isOnline()) {
      return this.getSetupForm(allowOffline).pipe(
        map<ApiData<FormWithValues>, ApiData<string>>(data => {
          if (data.status === ApiStatus.Success) {
            const fields: FormlyFieldConfig[] = this.util.parseFields(data.value?.theForm?.definition);
            const label = fields.find(field => field.key === 'Prosjekt')?.templateOptions?.label;
            if (allowOffline && label) {
              this.storage.setProjectLabel(label);
            }
            return {value: label, status: ApiStatus.Success};
          }
          else if (data.error) {
            return {value: '', status: ApiStatus.Failed, error: data.error};
          }
          else {
            return {value: '', status: ApiStatus.Failed};
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getProjectLabel()).pipe(
        map<string, ApiData<string>>(label => ({value: label, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: '', status: ApiStatus.Offline});
    }
  }

  /**
   * Get the context for an source
   * @param body The body to get the context for
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getContext(body: {source: string}, allowOffline: boolean = true): Observable<ApiData<Context>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/lookup/getContext`;
      return this.post(url, body).pipe(
        map<Result, ApiData<Context>>(res => {
          if (res?.success) {
            if (allowOffline) {
              this.storage.setContext(res.result, body.source);
            }
            return {value: res.result, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error getting context', res.error, body);
            return {value: null, status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error getting context', err, 'getContext', body);
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getContext(body);
          }
          else {
            return of({value: null, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getContext(body.source)).pipe(
        map<Context, ApiData<Context>>(context => ({value: context, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Get the setup form
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getSetupForm(allowOffline: boolean = true): Observable<ApiData<FormWithValues>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/xform/GetSetupForm`;
      return this.post(url, null).pipe(
        map<Result<FormWithValues>, ApiData<FormWithValues>>(res => {
          if (res && res.success) {
            if (allowOffline) {
              this.storage.setSetup(res.result);
            }
            return {value: res.result, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error getting setup form', res.error);
            return {value: null, status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error getting setup form', err, 'GetSetupForm');
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getSetupForm();
          }
          else {
            return of({value: null, status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getSetup()).pipe(
        map<FormWithValues, ApiData<FormWithValues>>(form => ({value: form, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Check if an setup form has changed
   * @param tenant The current tenant
   */
  isSetupChanged(tenant: string, username: string): Observable<ApiData<boolean>> {
    if (this.online.isOnline()) {
      return zip(from(this.storage.getSetupStoreDate(tenant, username)), this.getSetupForm(false)).pipe(
        map(([storedVal, apiVal]) => {
          if (apiVal.status === ApiStatus.Success && apiVal.value?.formSavedAt) {
            return {
              value: storedVal !== apiVal.value.formSavedAt,
              status: ApiStatus.Success
            };
          }
          else {
            const res: ApiData<boolean> = {
              value: false,
              status: apiVal.status
            };
            if (apiVal.error) {
              res.error = apiVal.error;
            }
            return res;
          }
        })
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Get data for use when styling with API from styleForm
   * @param url The url to where to find style data
   * @param stylePaths The paths to the different parts of the style
   * @param keys Keys of the fields in the form
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getStyleData(url: string, stylePaths: StylePaths, keys: string[], formId: number, allowOffline = true): Observable<ApiData<StyleData[]>> {
    if (this.online.isOnline()) {
      return this.getWebJSON(url, formId, undefined, undefined, false).pipe(
        map<ApiData, ApiData<StyleData[]>>(webData => {
          if (webData.status === ApiStatus.Failed) {
            webData.value = [];
            return webData;
          }
          const result = this.util.dotRef(webData.value, stylePaths.data);
          if (Array.isArray(result) && result.length > 0) {
            const styleData: StyleData[] = result.map(element => {
              const key = this.util.dotRef(element, stylePaths.key);
              const label = this.util.dotRef(element, stylePaths.label);
              const value = this.util.dotRef(element, stylePaths.value);
              let visible = this.util.dotRef(element, stylePaths.visible) ?? true;
              const options = this.util.dotRef(element, stylePaths.options);
              const valueName = this.util.dotRef(element, stylePaths.valueName);
              const serverSearch = this.util.dotRef(element, stylePaths.serverSearch);
              if (typeof visible === 'number') {
                visible = visible === 1;
              }
              const data: StyleData = {key, visible, serverSearch};
              if (label) {
                data.label = label;
              }
              if (value) {
                data.value = value;
              }
              if (options) {
                data.options = options;
              }
              if (valueName) {
                data.valueName = valueName;
              }
              if (typeof serverSearch === 'boolean') {
                data.serverSearch = serverSearch;
              }
              else if (typeof serverSearch === 'number') {
                data.serverSearch = serverSearch === 1;
              }
              return data;
            }).filter(element => keys.includes(element.key));
            if (allowOffline) {
              this.storage.setStyleData(url, styleData, formId);
            }
            return {value: styleData, status: ApiStatus.Success};
          }
          else {
            return {value: [], status: ApiStatus.Failed};
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getStyleData(url)).pipe(
        map<StyleData[], ApiData<StyleData[]>>(data => ({value: data, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: [], status: ApiStatus.Offline});
    }
  }

  /**
   * POST data to an API
   * @param url The url to the API
   * @param data The data to POST
   * @param headers Optional headers to use
   * @param useCredentials (Optional) If the call should use credentials
   */
  postWebJSON(url: string, data: any, headers?: any, useCredentials?: boolean): Observable<ApiData> {
    headers = this.fixHeaders(headers);
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      return this.getContext({source: url}).pipe(
        mergeMap<ApiData<Context>, Observable<ApiData>>(ctx => {
          if (ctx.status === ApiStatus.Failed) {
            return of({value: null, status: ApiStatus.Failed, error: ctx.error});
          }
          else if (this.online.isOnline()) {
            const newUrl = this.util.parseText(ctx.value.result, '');
            return this.http.post(newUrl, data, {headers: headers, withCredentials: this.checkUseCredentials(useCredentials, newUrl)}).pipe(
              map<any, ApiData>(res => ({value: res, status: ApiStatus.Success})),
              catchError((err: HttpErrorResponse) => {
                const logData = {url: url, parsedUrl: ctx.value.result, headers: headers};
                this.apiError.logExternalApiFail(location, ctx.value.result, 'Error posting data to web API', err, ApiType.External, logData);
                return of<ApiData>({value: null, status: ApiStatus.Failed, error: err});
              }),
              tap((res) => {
                if (res.status === ApiStatus.Success) {
                  this.apiError.externalSuccess(ctx.value.result);
                }
              })
            );
          }
          else {
            return of({value: null, status: ApiStatus.Offline});
          }
        })
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * PUT data to an API
   * @param url The url to the API
   * @param data The data to PUT
   * @param headers Optional headers to use
   * @param useCredentials (Optional) If the call should use credentials
   */
  putWebJSON(url: string, data: any, headers?: any, useCredentials?: boolean): Observable<ApiData> {
    headers = this.fixHeaders(headers);
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      return this.getContext({source: url}).pipe(
        mergeMap<ApiData<Context>, Observable<ApiData>>(ctx => {
          if (ctx.status === ApiStatus.Failed) {
            return of({value: null, status: ApiStatus.Failed, error: ctx.error});
          }
          else if (this.online.isOnline()) {
            const newUrl = this.util.parseText(ctx.value.result, '');
            return this.http.put(newUrl, data, {headers: headers, withCredentials: this.checkUseCredentials(useCredentials, newUrl)}).pipe(
              map<any, ApiData>(res => ({value: res, status: ApiStatus.Success})),
              catchError((err: HttpErrorResponse) => {
                const logData = {url: url, parsedUrl: ctx.value.result, headers: headers};
                this.apiError.logExternalApiFail(location, ctx.value.result, 'Error posting data to web API', err, ApiType.External, logData);
                return of<ApiData>({value: null, status: ApiStatus.Failed, error: err});
              }),
              tap((res) => {
                if (res.status === ApiStatus.Success) {
                  this.apiError.externalSuccess(ctx.value.result);
                }
              })
            );
          }
          else {
            return of({value: null, status: ApiStatus.Offline});
          }
        })
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Do DELETE request to an API
   * @param url The url to the API
   * @param headers Optional headers to use
   * @param useCredentials (Optional) If the call should use credentials
   */
  deleteWebJSON(url: string, headers?: any, useCredentials?: boolean): Observable<ApiData> {
    headers = this.fixHeaders(headers);
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      return this.getContext({source: url}).pipe(
        mergeMap<ApiData<Context>, Observable<ApiData>>(ctx => {
          if (ctx.status === ApiStatus.Failed) {
            return of({value: null, status: ApiStatus.Failed, error: ctx.error});
          }
          else if (this.online.isOnline()) {
            const newUrl = this.util.parseText(ctx.value.result, '');
            return this.http.delete(newUrl, {headers: headers, withCredentials: this.checkUseCredentials(useCredentials, newUrl)}).pipe(
              map<any, ApiData>(res => ({value: res, status: ApiStatus.Success})),
              catchError((err: HttpErrorResponse) => {
                const logData = {url: url, parsedUrl: ctx.value.result, headers: headers};
                this.apiError.logExternalApiFail(location, ctx.value.result, 'Error posting data to web API', err, ApiType.External, logData);
                return of<ApiData>({value: null, status: ApiStatus.Failed, error: err});
              }),
              tap((res) => {
                if (res.status === ApiStatus.Success) {
                  this.apiError.externalSuccess(ctx.value.result);
                }
              })
            );
          }
          else {
            return of({value: null, status: ApiStatus.Offline});
          }
        })
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Get JSON from an API with GET
   * @param url Url to the API
   * @param headers Optional headers to use
   * @param useCredentials (Optional) If the call should use credentials
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getWebJSON(url: string, formId: number, headers?: any, useCredentials?: boolean, allowOffline: boolean = true): Observable<ApiData> {
    headers = this.fixHeaders(headers);
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      return this.getContext({source: url}, allowOffline).pipe(
        mergeMap<ApiData<Context>, Observable<ApiData>>(ctx => {
          if (ctx.status === ApiStatus.Failed) {
            return of({value: null, status: ApiStatus.Failed, error: ctx.error});
          }
          else if (this.online.isOnline()) {
            const newUrl = this.util.parseText(ctx.value.result, '');
            return this.http.get(newUrl, {headers: headers, withCredentials: this.checkUseCredentials(useCredentials, newUrl)}).pipe(
              map<any, ApiData>(res => ({value: res, status: ApiStatus.Success})),
              catchError((err: HttpErrorResponse) => {
                const data = {url: url, parsedUrl: ctx.value.result, headers: headers};
                this.apiError.logExternalApiFail(location, ctx.value.result, 'Error getting data from web API', err, ApiType.External, data);
                return of<ApiData>({value: null, status: ApiStatus.Failed, error: err});
              }),
              tap((res) => {
                if (res.status === ApiStatus.Success) {
                  if (allowOffline) {
                    this.storage.setWithKey(url, res.value, formId);
                  }
                  this.apiError.externalSuccess(ctx.value.result);
                }
              })
            );
          }
          else if (allowOffline) {
            return from(this.storage.getWithKey(url)).pipe(
              map<any, ApiData>(data => ({value: data, status: ApiStatus.Offline}))
            );
          }
          else {
            return of({value: null, status: ApiStatus.Offline});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getWithKey(url)).pipe(
        map<any, ApiData>(data => ({value: data, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Get JSON from an lookup table
   * @param table Name of the lookup table
   * @param parent (Optional) Name of parent table to filter by
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getLookupJson(table: string, parent?: string, allowOffline: boolean = true): Observable<ApiData<LookupValue[]>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      let url = `${this.serviceUrl}/lookup/getMyLookups?tab=${table}`;
      if (parent) {
        url += `&parent=${parent}`;
      }
      return this.post(url, null).pipe(
        map<Result, ApiData<LookupValue[]>>(res => {
          if (res?.success && Array.isArray(res.result)) {
            if (allowOffline) {
              this.storage.setLookups(res.result, table, parent);
            }
            return {value: res.result, status: ApiStatus.Success};
          }
          else {
            this.apiError.logNoResult(location, 'Error getting table lookup', res.error, {table});
            return {value: [], status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error getting table lookup', err, 'getMyLookups', {table});
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getLookupJson(table, parent);
          }
          else {
            return of({value: [], status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getLookups(table, parent)).pipe(
        map<LookupValue[], ApiData<LookupValue[]>>(values => ({value: values, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: [], status: ApiStatus.Offline});
    }
  }

  /**
   * Gets logourl for tenant
   * @param tenant Tenancyname
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getLogoUrl(tenant: string, allowOffline: boolean = true): Observable<ApiData<string>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.apirUrl}/insetting?tenant=${tenant}&settingName=App.General.LogoUri`;
      return this.http.get(url).pipe(
        map<any[], ApiData<string>>(res => {
          this.apiError.externalSuccess(url);
          if (Array.isArray(res) && res.length > 0 && res[0].number) {
            if (allowOffline) {
              this.storage.setLogo(res[0].number);
            }
            return {value: res[0].number, status: ApiStatus.Success};
          }
          else {
            return {value: '', status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logExternalApiFail(location, url, 'Error getting logourl', err, ApiType.InApir, {tenant});
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getLogoUrl(tenant);
          }
          else {
            return of({value: '', status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getLogo()).pipe(
        map<string, ApiData<string>>(res => ({value: res, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: '', status: ApiStatus.Offline});
    }
  }

  /**
   * Get previous values for historyselect
   * @param formId Id of the form the historyselect is in
   * @param key Key of the historyselect
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getPreviousValues(formId: number, key: string, allowOffline: boolean = true): Observable<ApiData<string[]>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/xform/getPreviousValues?historyForm=${formId}&historyKey=${key}`;
      return this.post(url, null).pipe(
        map<Result, ApiData<string[]>>(res => {
          if (res.success && Array.isArray(res.result) && res.result.length > 0) {
            if (allowOffline) {
              this.storage.setHistorySelect(res.result, formId, key);
            }
            return {value: res.result, status: ApiStatus.Success};
          }
          else {
            return {value: [], status: ApiStatus.Failed};
          }
        }),
        retry(this.maxRetries),
        catchError((err: HttpErrorResponse) => {
          // eslint-disable-next-line max-len
          this.apiError.logInternalApiFail(location, 'Error getting historyselect values', err, 'getPreviousValues', {form: formId, key: key});
          if (allowOffline) {
            this.online.goOffline(OnlineStatus.InternalFail, err.status);
            return this.getPreviousValues(formId, key);
          }
          else {
            return of({value: [], status: ApiStatus.Failed, error: err});
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getHistorySelect(formId, key)).pipe(
        map<string[], ApiData<string[]>>(res => ({value: res, status: ApiStatus.Offline}))
      );
    }
    else {
      return of({value: [], status: ApiStatus.Offline});
    }
  }

  /**
   * Downloads an image as Base64
   * @param url Url to image
   */
  imageDownload(url: string): Observable<ApiData<string>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      let failed = false;
      return this.http.get(url, { responseType: 'blob' }).pipe(
        mergeMap<Blob, Observable<ApiData<string>>>(res => {
          if (!failed) {
            this.apiError.externalSuccess(url);
          }
          if (res) {
            return new Observable<ApiData<string>>(obs => {
              const reader = new FileReader();
              reader.onloadend = () => {
                obs.next({value: reader.result as string, status: ApiStatus.Success});
              };
              reader.onerror = () => {
                obs.next({value: '', status: ApiStatus.Failed});
              };
              reader.readAsDataURL(res);
            });
          }
          else {
            return of({value: '', status: ApiStatus.Failed});
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logExternalApiFail(location, url, 'Error downloading image', err, ApiType.External);
          failed = true;
          return of({value: '', status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: '', status: ApiStatus.Offline});
    }
  }

  /**
   * Uploads an image to the backend
   * @param image The image to upload
   * @param filename Name of the image
   */
  uploadPhoto(image: Blob, filename: string): Observable<ApiData<string>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.inBase}/Photo/UploadPhoto`;

      return this.postImage(url, image, filename).pipe(
        map<string, ApiData<string>>(res => ({value: res, status: res ? ApiStatus.Success : ApiStatus.Failed})),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error uploading image', err, 'UploadPhoto');
          return of({value: null, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Update key to the backend reg. Note. added as a fix to offline photos not uploaded.
   * @param image The image to upload
   * @param filename Name of the image
   */
  updateRegKey(keyValue: UpdateKeyReg): Observable<ApiData> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/xform/UpdateRegValue`;

      return this.post(url, keyValue).pipe(
        map<{error: any, result: any, success: boolean, unAuthorizedRequest: boolean}, ApiData>(res => ({value: res.result, status: res.success ? ApiStatus.Success : ApiStatus.Failed})),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logInternalApiFail(location, 'Error updating key', err, 'UpdateKey');
          return of({value: null, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Upload the user's FCM token to server
   *
   * @param token The token to upload
   * @param tenant The current tenant
   * @param username Username of the user
   */
  uploadFirebaseToken(token: string): Observable<ApiData<boolean>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/profile/UpdatePushToken?pushToken=${token}`;
      return this.post(url, null).pipe(
        map<Result, ApiData<boolean>>((res: Result) => res.success ? {value: true, status: ApiStatus.Success} : {value: false, status: ApiStatus.Failed}),
        catchError((err) => {
          this.apiError.logInternalApiFail(location, 'Error updating token', err, 'UpdatePushToken');
          return of({value: false, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Delete the user's FCM token from server
   *
   * @param tenant The current tenant
   * @param username Username of the user
   */
  deleteFirebaseToken(): Observable<ApiData<boolean>> {
    return this.uploadFirebaseToken('');
  }

  /**
   * Get if the user is allowed to edit Home. And remove home orders from storage if it has changed from true -> false
   * @param storageEdit (Optional) If given it will use this to compare with the API value, if not given it will get it from storage
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
   getAllowHomeEdit(storageEdit?: boolean, allowOffline: boolean = true): Observable<ApiData<boolean>> {
    if (this.online.isOnline()) {
      return this.getContext({source: 'https://in.tempus.no/?app=IN'}).pipe(
        mergeMap(async res => {
          if (res.status === ApiStatus.Failed) {
            return {value: false, status: ApiStatus.Failed, error: res.error};
          }
          else {
            const allowEdit = res?.value?.canEditHomeOrder ?? false;
            if (!allowEdit) {
              if (typeof storageEdit !== 'boolean') {
                storageEdit = await this.storage.getAllowHomeEdit();
              }
              if (storageEdit) {
                await this.storage.removeAllHomeBoxOrders();
              }
            }
            if (allowOffline) {
              this.storage.setAllowHomeEdit(allowEdit);
            }
            return {value: allowEdit, status: ApiStatus.Success};
          }
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getAllowHomeEdit()).pipe(map(allowEdit => ({value: allowEdit, status: ApiStatus.Offline})));
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Upload home box order to server. Will only upload if user is allowed to edit Home.
   * @param order The home box order to upload
   */
  uploadBoxOrder(order: BoxStatus[], projectId: number): Observable<ApiData<boolean>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      let url = `${this.serviceUrl}/user/UpdateOneHomeOrder?projectId=${projectId}&data=`;
      if (Array.isArray(order) && order.length > 0) {
        url += JSON.stringify(order);
      }
      return this.getAllowHomeEdit().pipe(
        mergeMap(({value}) => {
          if (value) {
            if (this.online.isOnline()) {
              return this.post(url, null).pipe(
                map<Result, ApiData<boolean>>(res => {
                  if (res.success) {
                    return {value: true, status: ApiStatus.Success};
                  }
                  else {
                    return {value: false, status: ApiStatus.Failed};
                  }
                }),
                catchError((err) => {
                  this.apiError.logInternalApiFail(location, 'Error uploading home order', err, 'UpdateOneHomeOrder');
                  return of({value: false, status: ApiStatus.Failed, error: err});
                })
              );
            }
            else {
              return of({value: false, status: ApiStatus.Offline});
            }
          }
          else {
            return of({value: false, status: ApiStatus.Failed});
          }
        })
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Delete home box order on server. Will only delete if user is allowed to edit Home.
   * @param order The home box order to upload
   */
  deleteBoxOrder(projectId: number): Observable<ApiData<boolean>> {
    return this.uploadBoxOrder(null, projectId);
  }

  /**
   * Get home box order from server
   * @param projectId The id of the current project
   * @param allowOffline (Optional) To allow the return of stored value if offline (default: true)
   */
  getBoxOrder(projectId: number, allowOffline: boolean = true): Observable<ApiData<BoxStatus[]>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = `${this.serviceUrl}/user/GetHomeOrder?projectId=${projectId}`;
      return this.post(url, null).pipe(
        mergeMap<Result<{projectId: number, data: string}>, Observable<ApiData<BoxStatus[]>>>(res => {
          if (res?.result?.data) {
            try {
              const order = JSON.parse(res.result.data);
              if (Array.isArray(order) && order.length > 0) {
                if (allowOffline) {
                  return from(this.storage.setHomeBoxOrder(projectId, order)).pipe(
                    map(() => ({value: order, status: ApiStatus.Success}))
                  );
                }
                return of({value: order, status: ApiStatus.Success});
              }
              else {
                this.deleteBoxOrder(projectId).subscribe();
                return of({value: null, status: ApiStatus.Failed});
              }
            }
            catch {
              this.deleteBoxOrder(projectId).subscribe();
              return of({value: null, status: ApiStatus.Failed});
            }
          }
          else if (res?.success) {
            return of( {value: null, status: ApiStatus.Success});
          }
          else {
            return of({value: null, status: ApiStatus.Failed});
          }
        }),
        catchError((err) => {
          this.apiError.logInternalApiFail(location, 'Error getting home order', err, 'GetHomeOrder');
          return of({value: null, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else if (allowOffline) {
      return from(this.storage.getHomeBoxOrder(projectId)).pipe(map(order => ({value: order, status: ApiStatus.Offline})));
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Sends feedback with optional log to an given email address
   * @param name The name of the user
   * @param tenant The tenant of the user
   * @param sender The email of the user
   * @param message Message to send
   * @param type Type of feedback
   * @param formId (Optional) Id of the form if the feedback is for a form
   * @param log (Optional) Log to send
   */
  sendFeedback(name: string, tenant: string, sender: string, message: string, type: string, formId?: number, log?: LogEntry[]): Observable<ApiData<boolean>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = 'https://infeaturescode.azurewebsites.net/api/SendLog';
      const body: FeedbackBody = {
        user: name,
        tenant: tenant,
        sender: sender,
        message: message,
        type: type,
        device: this.device.getType(),
        version: environment.appVersion,
        theme: this.theme.theme.value
      };
      if (formId) {
        body.formId = formId;
      }
      if (log) {
        body.log = JSON.stringify(log);
      }
      const headers = {
        'x-functions-key': 'k11YbEHg3ydDfLmKzwoRt4GKqo/Ki3oKKbOKfk21sFgp2Qg7WbBTHQ=='
      };
      return this.http.post(url, body, {headers: headers, responseType: 'text'}).pipe(
        map(() => {
          this.apiError.externalSuccess(url);
          return {value: true, status: ApiStatus.Success};
        }),
        catchError((err: HttpErrorResponse) => {
          this.apiError.logExternalApiFail(location, url, 'Error sending log', err, ApiType.InAzure);
          return of({value: false, status: ApiStatus.Failed, error: err});
        }),
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Get the current/newest app version
   */
  getCurrentAppVersion(): Observable<ApiData<AppVersion>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = 'https://infeaturescode.azurewebsites.net/api/App3Version';
      const headers = {
        'x-functions-key': '5MPkd8GcmcArdAaaHa6CanGq5kVhmIqwqsMs4ashkxDgM3e9nVFLhA=='
      };
      return this.http.get(url, {headers: headers}).pipe(
        map((result: AppVersion) => ({value: result, status: result ? ApiStatus.Success : ApiStatus.Failed})),
        catchError(err => {
          this.apiError.logExternalApiFail(location, url, 'Error getting app version', err, ApiType.InAzure);
          return of({value: null, status: ApiStatus.Failed});
        })
      );
    }
    else {
      return of({value: null, status: ApiStatus.Offline});
    }
  }

  /**
   * Check password against "master password" in Azure function for allowing the user to switch to dev mode
   * @param tenant The current tenant
   * @param username The current user's username
   * @param password The password to check against "master password"
   */
  checkMasterPassword(tenant: string, username: string, password: string): Observable<ApiData<boolean>> {
    const location = new Error().stack?.split('\n')[1]?.trim();
    if (this.online.isOnline()) {
      const url = 'https://infeaturescode.azurewebsites.net/api/CheckMasterPassword';
      const headers = {
        'x-functions-key': '9LZyKVkSE/BQMooDtGH6HVTptSSaUkUTLxyP6v1q3avEV//0UPVHeQ=='
      };
      const body = {
        user: `${tenant.toLowerCase()}:${username.toLowerCase()}`,
        password: password
      };
      return this.http.post(url, body, {headers: headers}).pipe(
        map((result: {success: boolean}) => {
          if (result?.success) {
            return {value: true, status: ApiStatus.Success};
          }
          else {
            return {value: false, status: ApiStatus.Failed};
          }
        }),
        catchError(err => {
          this.apiError.logExternalApiFail(location, url, 'Error checking Master Password', err, ApiType.InAzure);
          return of({value: false, status: ApiStatus.Failed, error: err});
        })
      );
    }
    else {
      return of({value: false, status: ApiStatus.Offline});
    }
  }

  /**
   * Set custom url for backend API
   */
  private async setCustomEnv() {
    const trans = this.translate.instant(['CustomApi', 'CustomApiMsg', 'Cancel', 'Confirm']);
    const alert = await this.alertCtrl.create({
      header: trans['CustomApi'],
      message: trans['CustomApiMsg'],
      inputs: [{
        type: 'url',
        name: 'url'
      }],
      buttons: [
        {
          text: trans['Cancel'],
          role: 'cancel'
        },
        {
          text: trans['Confirm']
        }
      ]
    });
    await alert.present();
    const {data} = await alert.onWillDismiss();
    if (data && data.values && data.values.url) {
      return data.values.url;
    }
    else {
      return this.inBase;
    }
  }

  /**
   * Used for calling POST to the TempusIN API
   * @param url Url to post to
   * @param body Body to post
   */
  private post(url: string, body: any): Observable<any> {
    return this.http.post<any>(url, body, {headers: this.options, withCredentials: true});
  }

  /**
   * Separate HttpClient (avoid interceptor-watcher).
   * Used for calling POST to the TempusIN API.
   * @param url Url to post to
   * @param body Body to post
   */
  private postEnd(url: string, body: any): Observable<any> {
    return this.httpEnd.post<any>(url, body, {headers: this.options, withCredentials: true});
  }

  /**
   * Used for calling DELETE to the TempusIN API
   * @param url Url to call delete to
   */
  private delete(url: string): Observable<any> {
    return this.http.delete(url, {headers: this.options, withCredentials: true});
  }

  /**
   * Upload an image to an external web server using XHR
   * @param url The url to upload the image to
   * @param image The image to upload
   * @param filename Name of the image
   */
  private postImage(url: string, image: Blob, filename: string) {
    const formData = new FormData();
    formData.append('file', image, filename);

    return new Observable<any>(obs => {
      const xhr = new XMLHttpRequest();
      xhr.onloadend = () => obs.next(xhr.response);
      xhr.onerror = (err) => obs.error(err);
      xhr.open('POST', url);
      xhr.withCredentials = true;
      xhr.send(formData);
    });
  }

  /**
   * Fix format and data of forms
   * @param forms The forms to fix
   */
  private fixForms(forms: Form[]): Form[] {
    if (forms?.length > 0) {
      return forms.filter(form => form.isActive).map(form => this.fixForm(form));
    }
    else {
      return [];
    }
  }

  /**
   * Fix format and data of an form
   * @param form The form to fix
   */
  private fixForm(form: Form): Form {
    form.iconClass = this.legacy.fixIcon(form.iconClass);
    if (typeof form.storeDate === 'string') {
      form.storeDate = moment(form.storeDate).toDate();
    }
    if (form.about && form.about.trim().length === 0) {
      form.about = null;
    }
    return form;
  }

  /**
   * Fix format and data of registrations
   * @param regs The registrations to fix
   */
  private fixRegistrations(regs: OldReg[]): OldReg[] {
    if (regs?.length > 0) {
      return regs = regs.map(reg => {
        reg.iconClass = this.legacy.fixIcon(reg.iconClass);
        if (typeof reg.signedAt === 'string') {
          reg.signedAt = moment(reg.signedAt).toDate();
        }
        return reg;
      });
    }
    else {
      return [];
    }
  }

  /**
   * Remove empty or not-parsed headers
   * @param headers The headers to fix
   */
  private fixHeaders(headers: {[key: string]: string}) {
    if (!headers) {
      return {};
    }
    for (const [key, value] of Object.entries(headers)) {
      if (!value || value.startsWith('{{')) {
        delete headers[key];
      }
    }
    return headers;
  }

  private checkUseCredentials(useCredentials: boolean, url: string): boolean {
    if (typeof useCredentials === 'boolean') {
      return useCredentials;
    }
    else if (url.startsWith('https://in.tempus.no') || url.startsWith('https://tempusinprod-instaging.azurewebsites.net')
          || url.startsWith('localhost') || url.startsWith('http://localhost') || url.startsWith('https://localhost')
    ) {
      return true;
    }
    else {
      return false;
    }
  }
}
