import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { UtilityService } from 'src/app/services/utility.service';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { FieldService } from 'src/app/services/field.service';
import { StateService } from 'src/app/services/state.service';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { Trigger, TriggerType, DataStatus, CachedData, Fields, SourceType, DataSource, ModelMetaData, FormType, InitMapping, Options } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { WatchOptions } from 'src/app/models/interfaces/watchOptions';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-field-modalselect',
  templateUrl: './modalselect.component.html',
  styleUrls: ['./modalselect.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class ModalselectComponent extends FieldType implements OnInit, AfterViewInit, Trigger, OnDestroy {
  @ViewChild('modalSelectComponent') selectComponent: IonicSelectableComponent;

  watchGroup: WatchOptions;
  useVirtualScroll = false;
  fieldIsVisible = true;
  errorText = '';
  loading = true;
  changedValue = false;
  resizeSub: Subscription;
  doingInit = new BehaviorSubject<boolean>(true);
  prevSearch: string;
  selectedOptions = [];
  missedOptions = [];
  disabledOptions = [];
  filterSearchParam: string;
  private _listOptions = [];
  private _allOptions: any[];


  get allOptions(): any[] {
    return Array.isArray(this._allOptions) ? this._allOptions : [];
  }

  get listOptions(): any[] {
    return Array.isArray(this._listOptions) ? this._listOptions : [];
  }

  set listOptions(options: any[]) {
    options = this.util.uniqueObjects(options, this.valueProp);
    let missing = this.createMissingOptions(options, this.model[this.key as string]);
    if(missing.length > 0 ) {
      if(this.rmMissingOptions) {
        this.removeMissingValues(missing, this.model[this.key as string]);
        missing = [];
      }
      else
        options = missing.concat(options);
    }

    for (const option of options) {
      let label: string;
      if (this.useTemplate) {
        label = this.parseTemplate(option);
      }
      else {
        label = this.getProperty(option, this.labelProp);
        if (this.showValueInLabel) {
          label += ` (${this.getProperty(option, this.valueProp)})`;
        }
      }
      option[this.shownLabelProp] = label;
    }
    this._listOptions = options;
    this.setAllOptions();
  }


  constructor(
    private util: UtilityService,
    private watch: WatchgroupService,
    private translate: TranslateService,
    private logger: NGXLogger,
    private ref: ComponentRefService,
    private fieldService: FieldService,
    private stateService: StateService,
    private elm: ElementRef,
    private dom: DomOperationsService,
    private cachedApi: CachedApiService,
    private plt: Platform,
    private auth: AuthService,
    private route: ActivatedRoute,
    private api: ApiService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }
  get allowToggleAll(): boolean {
    return this.to.allowToggleAll || false;
  }
  get storeAsJson(): boolean {
    return this.to.storeAsJson || false;
  }
  get useSearchbar(): boolean {
    return typeof this.to.search === 'boolean' ? this.to.search : true;
  }
  get focusSearchbar(): boolean {
    return typeof this.to.focusSearch === 'boolean' ? this.to.focusSearch : false;
  }
  get searchMinLength(): number {
    return this.to.lookupMinLength ?? 3;
  }
  get useMultiple(): boolean {
    if (this.field.type === Fields.ServerQueryModal) {
      return (this.to.multiple || this.to.optionsize !== 1) ? true : false;
    }
    else {
      return this.to.multiple || false;
    }
  }
  get table(): string {
    return this.to.lookupName || '';
  }
  get url(): string {
    return this.to.lookupUrl || '';
  }
  get fieldInitUrl(): string {
    return this.to.fieldInitUrl || '';
  }
  get initMapping(): InitMapping {
    return this.to.initMapping || {};
  }
  get shouldDoApiInit(): boolean {
    return this.fieldInitUrl !== '' && !this.to.haveDoneInit;
  }
  get shouldCallApi(): boolean {
    return !this.to.haveDoneInit || !this.field.hide;
  }
  get sourceType(): SourceType {
    if (this.field.type === Fields.WebLookup) {
      return SourceType.API;
    }
    else if (this.field.type === Fields.Lookup) {
      return SourceType.Lookup;
    }
    else if (this.field.type === Fields.ServerQueryModal) {
      return SourceType.API;
    }
    else if (this.table) {
      return SourceType.Lookup;
    }
    else if (this.url) {
      return SourceType.API;
    }
    else {
      return SourceType.Local;
    }
  }

  get useHistory(): boolean {
    return this.to.useHistory && !this.to.disabled && this.sourceType === SourceType.Local;
  }

  get fullValueProp(): string {
    switch (this.sourceType) {
      case SourceType.Local:
        return 'value';
      default:
        return this.to.valueProp || 'number';
    }
  }

  get valueProp(): string {
    if (this.hasValuePath) {
      const parts = this.fullValueProp.split('.');
      return parts[parts.length - 1] + '_inmapped_value';
    }
    else {
      return this.fullValueProp;
    }
  }

  get hasValuePath(): boolean {
    return this.fullValueProp.includes('.');
  }

  get fullLabelProp(): string {
    switch (this.sourceType) {
      case SourceType.Local:
        return 'label';
      default:
        return typeof this.to.labelProp === 'string' ? this.to.labelProp : 'name';
    }
  }

  get labelProp(): string {
    if (this.hasLabelPath) {
      const parts = this.fullLabelProp.split('.');
      return parts[parts.length - 1] + '_inmapped_label';
    }
    else {
      return this.fullLabelProp;
    }
  }

  get shownLabelProp(): string {
    return '_InLabel_';
  }

  get labelSplit(): string {
    return this.to.labelSplit ?? ', ';
  }

  get isLabelPropArray(): boolean {
    return Array.isArray(this.to.labelProp) && this.to.labelProp.length > 0;
  }

  get missingProp(): string {
    return this.to.missing;
  }

  get labelPropArray(): string[] {
    if (this.isLabelPropArray) {
      return this.to.labelProp;
    }
    else {
      return null;
    }
  }

  get hasLabelPath(): boolean {
    return this.fullLabelProp.includes('.');
  }

  get templateProp(): string {
    return this.to.templateProp ?? '';
  }
  get groupProp(): string {
    return this.to.groupProp || '';
  }
  get isAutoServerSearch(): boolean {
    return this.to.autoServerSearch ?? false;
  }
  get useServerSearch(): boolean {
    return this.to.serverSearch ?? this.isAutoServerSearch ?? false;
  }
  get appendQuery(): string {
    if (this.isAutoServerSearch) {
      return this.to.serverSearchName  || 'filter';
    }
    else {
      return this.to.serverSearchName || null;
    }
  }
  get serverSearchModeParam(): string {
    return this.to.serverSearchModeParam || 'brukFilter';
  }
  get lookupMinLength(): number {
    return this.to.lookupMinLength || 3;
  }
  get objPath(): string {
    return this.to.jsonPath || '';
  }
  get virtualScrollSize(): number {
    return this.to.virtualScrollSize || 50;
  }
  get showValueInLabel(): boolean {
    return this.to.showValueInLabel || false;
  }
  get placeholder(): string {
    return this.to.placeholder || '';
  }
  get confirmButtonText(): string {
    return this.to.confirmButtonText || this.translate.instant('ConfirmSelectBtnText');
  }
  get clearButtonText(): string {
    return this.to.clearButtonText || this.translate.instant('ClearSelectBtnText');
  }
  get selectButtonText(): string {
    return this.to.selectButtonText || '';
  }
  get deselectButtonText(): string {
    return this.to.deselectButtonText || '';
  }
  get searchPlaceholder(): string {
    const ml = (this.useServerSearch && this.searchMinLength > 1 ) ? '['+this.translate.instant('searchPlaceholderMinLenght', {lookupMinLength: this.searchMinLength})+ ']' + ' ': ''; // Sets space when used.
    return ml + (this.to.searchPlaceholder || this.translate.instant('searchPlaceholder'));
  }
  get searchLabelNotFound(): string {
    return this.to.searchLabelNotFound || this.translate.instant('NoData');
  }
  get searchDebounce(): number {
    return this.useServerSearch ? 1000 : 250;
  }
  get useTemplate(): boolean {
    return this.isLabelPropArray || this.templateProp !== '';
  }
  get watchKey(): string {
    return this.to.watchKey || 'watch';
  }

  get hasParent(): boolean {
    return this.to.parent ?? false;
  }

  get parentValue(): string {
    if (this.hasParent) {
//      const key = this.watchGroup.fieldKeys[0];
      const value = this.model[this.to.parent] ?? this.model[this.watchGroup.fieldKeys[0]] ?? null; //if parent is just marked boolean 'true', get the first in watch.
      return value || null;
    }
    else {
      return null;
    }
  }

  get showModal(): boolean {
    if (this.useServerSearch) {
      return !this.errorText && !this.loading;
    }
    else {
      return !this.errorText && !this.loading && this.listOptions.length > 0;
    }
  }

  get showNoItems(): boolean {
    if (this.useServerSearch) {
      return false;
    }
    else {
      return this.listOptions.length === 0 && !this.errorText && !this.loading;
    }
  }

  get hasError(): boolean {
    return !!this.errorText && !this.loading;
  }

  get searchKey(): string {
    return ModelMetaData.ModalSearch + `${this.key as string}_`;
  }

  get allSearches(): string[] {
    const search = this.model[this.searchKey];
    if (Array.isArray(search)) {
      return search.filter(s => !!s);
    }
    else if (search) {
      return [search];
    }
    else {
      return [];
    }
  }

  get searchText(): string {
    if (this.allSearches.length > 0) {
      return this.allSearches[this.allSearches.length - 1];
    }
    else {
      return '';
    }
  }

  set searchText(text: string) {
    const search = this.model[this.searchKey];
    if (this.useMultiple && this.useServerSearch) {
      if (!Array.isArray(search)) {
        this.model[this.searchKey] = [text];
      }
      else if (!search.includes(text)) {
        search.push(text);
      }
    }
    else {
      this.model[this.searchKey] = text;
    }
  }

  get shownOptionsLimit(): number {
    return this.to.optionsShown ?? 2;
  }

  get optionsLabel(): string {
    return this.to.optionsLabel ?? '';
  }

  get valuesParams() {
    return {
      count: this.selectedCount,
      label: this.optionsLabel
    };
  }

  get selectedCount(): number {
    return this.selectedOptions.length + this.missedOptions.length;
  }

  get showCountLabel(): boolean {
    return this.to.currentForm.type !== FormType.Registration && this.useMultiple && this.selectedCount > this.shownOptionsLimit;

  }

  get labelPosition(): 'fixed' | 'floating' | 'stacked' | '' {
    switch (this.to.labelPosition) {
      case 'fixed':
      case 'floating':
      case 'stacked':
        return this.to.labelPosition;
      default:
        return '';
    }
  }

  set labelPosition(position: 'fixed' | 'floating' | 'stacked' | '') {
    if (position === 'fixed' || position === 'floating' || position === 'stacked' || position === '') {
      this.to.labelPosition = position;
    }
  }

  get shouldNotCheckOverflow(): boolean {
    return this.to.notChangePosition || this.labelPosition === 'stacked' || this.labelPosition === 'floating';
  }

  get httpType(): 'GET' | 'POST' | 'PUT' | 'DELETE' {
    const type = this.to.httpType?.toUpperCase();
    switch (type) {
      case 'POST':
      case 'PUT':
      case 'DELETE':
        return type;
      default:
        return 'GET';
    }
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.stateService.getFields(this.formType, this.formId);
  }

  get defaultItemName(): string {
    return this.to.defaultInitValueName || '';
  }

  get rmMissingOptions(): boolean {
    return this.to.removeMissingOptions ?? false;
  }

  public static getHtml(config: FormlyFieldConfig, value: any) {
    const isJson = config.templateOptions.storeAsJson ?? false;
    const useMultiple = !!config.templateOptions.multiple
      || (config.type === Fields.ServerQueryModal && config.templateOptions.optionsize !== 1);
    const valueProp = (config.templateOptions.lookupUrl || config.templateOptions.lookupName) ? config.templateOptions.valueProp ?? 'number'
      : 'value';
    const labelProp = (config.templateOptions.lookupUrl || config.templateOptions.lookupName) ? config.templateOptions.labelProp ?? 'name'
      : 'label';
    let valText = value;
    if (isJson) {
      if (Array.isArray(value)) {
        valText = value.map(val => val[labelProp]).join('<br>');
      }
      else if (typeof value === 'object') {
        valText = value[labelProp];
      }
    }
    else if (useMultiple && Array.isArray(value)) {
      if (Array.isArray(config.templateOptions.options) && config.templateOptions.options.length > 0) {
        const options = config.templateOptions.options;
        value = value.map(val => {
          const option = options.find(opt => opt[valueProp] === val);
          if (option && option[labelProp]) {
            return option[labelProp];
          }
          else {
            return val;
          }
        });
      }
      valText = value.join('<br>');
    }
    else {
      if (Array.isArray(config.templateOptions.options) && config.templateOptions.options.length > 0) {
        const option = config.templateOptions.options.find(opt => opt[valueProp] === value);
        if (option && option[labelProp]) {
          valText = option[labelProp];
        }
      }
    }
    return `<ion-card><ion-item lines="none">
              <ion-label>${config.templateOptions.label}</ion-label>
              ${valText}
            </ion-item></ion-card>`;
  }



  async ngOnInit() {
    this.watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    this.fixSearchText();
    this.setReference(this.key as string, this.formId, this.formType);
    this.logger.debug(this.formControl.value);
    if (this.useServerSearch && !this.useMultiple) {
      this.prevSearch = this.searchText;
      setTimeout(() => {
        if (this.selectComponent) {
          this.selectComponent.searchText = this.searchText;
        }
      }, 500);
    }

    let value = this.fieldService.getValue();
    if (this.sourceType === SourceType.Lookup) {
      await this.runLookupCall(this.table, this.searchText);
    }
    else if (this.sourceType === SourceType.API) {
      if (this.shouldDoApiInit) {
        await this.initAndRunApi(this.searchText);
      }
      else if (this.shouldCallApi) {
        await this.runApiCall(this.searchText, true, this.isAutoServerSearch);
      }
    }
    else {
      this.loading = false;
      this.setLocalList();
    }
    if (this.useMultiple && Array.isArray(value) && value.length > 1) {
      await this.fixOldValues(value);
    }
    if (value) {
      if (this.storeAsJson) {
        const option = this.getOption(this.listOptions, value);
        if (typeof value !== 'object') {
          const oldValue = value;
          const label = `${value} ${this.defaultItemName}`;
          value = {};
          this.copyProperty(option, value, this.valueProp, oldValue);
          this.copyProperty(option, value, this.labelProp, label);
          this.copyProperty(option, value, this.shownLabelProp, label);
        }
        else if (!this.getProperty(value, this.shownLabelProp)) {
          const defaultValue = this.getProperty(value, this.labelProp, this.defaultItemName);
          this.copyProperty(option, value, this.shownLabelProp, defaultValue);
        }
      }
      this.fieldService.setValue(value);
    }

    if (this.watchGroup.fieldKeys.length > 0) {
      this.watch.watchGroup(this.form, this.model, this.watchGroup).subscribe(vc => {
        if (vc === null) return;

        this.clearModal();
        if (this.sourceType === SourceType.API) {
          this.runApiCall();
        }
        else if (this.sourceType === SourceType.Lookup) {
          const table = this.hasParent ? this.table : this.table + vc.newValue;
          this.runLookupCall(table);
        }

      });
    }
    this.resizeSub = this.plt.resize.subscribe(async () => {
      const res = await this.checkLabelAndData();
      if (res) {
        this.resizeSub.unsubscribe();
      }
    });
    this.doingInit.next(false);
  }

  async ngAfterViewInit() {
    await this.waitForInit();
    if (!this.shouldNotCheckOverflow) {
      this.checkLabelAndData();
    }
    else if (this.labelPosition === 'stacked' || this.labelPosition === 'floating') {
      this.setOverheadLabel(this.labelPosition);
    }
  }

  ngOnDestroy() {
    this.resizeSub?.unsubscribe();
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.CloseModal) {
      this.closeModal();
    }
    else if (type === TriggerType.Focus) {
      const label: HTMLIonLabelElement = this.elm.nativeElement.querySelector('ion-label');
      this.dom.scrollIntoViewAndExecute(label, () => this.openModal());
    }
    else if (type === TriggerType.GetOptions) {
      return this.allOptions;
    }
    else if (type === TriggerType.Update) {
      if (this.sourceType === SourceType.API) {
        this.clearModal();
        this.runApiCall();
      }
      else {
        this.logger.warn(`Will only update modalselect (${this.key}) for SourceType.API (is SourceType ${this.sourceType})`);
      }
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  closeModal() {
    if (this.selectComponent && this.selectComponent.isEnabled && this.selectComponent.isOpened) {
      this.selectComponent.close();
    }
  }

  async openModal() {
    if (this.selectComponent && this.selectComponent.isEnabled && !this.selectComponent.isOpened) {
      this.selectComponent.open();
    }
  }

  fixSearchText() {
    const search = this.model[this.searchKey];
    if (this.useMultiple && typeof search === 'string' && search.startsWith('[')) {
      try {
        const parsed: string[] = JSON.parse(search);
        this.model[this.searchKey] = parsed;
      }
      catch {
        this.logger.error('Error parsing modal search', search);
      }
    }
  }

  async fixOldValues(values: any[], prevMissing?: number, index = 0) {
    prevMissing ??= values.length + 1;
    if (this.storeAsJson) {
      this.selectedOptions = values;
      this.setAllOptions();
      return;
    }
    const missing = [];
    for (const value of values) {
      const option = this.getOption(this.listOptions, value);
      if (option && !option['_notfound_']) {
        this.selectedOptions.push(option);
      }
      else if (this.useServerSearch) {
        missing.push(value);
      }
    }
    if (missing.length > 0) {
      if (index < this.allSearches.length) {
        const search = this.allSearches[index];
        await this.searchOld(search);
        await this.fixOldValues(missing, prevMissing, index + 1);
      }
      else {
        if (missing.length === prevMissing) {
          let val = {};
          val = this.setProperty(val, this.valueProp, missing[0]);
          val = this.setProperty(val, this.labelProp, missing[0]);
          this.missedOptions.push(val);
          missing.shift();
        }
        const search = missing[0];
        await this.searchOld(search);
        await this.fixOldValues(missing, missing.length, index + 1);
      }
    }
  }

  async searchOld(search: string) {
    if (this.sourceType === SourceType.Lookup) {
      await this.runLookupCall(this.table, search);
    }
    else if (this.sourceType === SourceType.API) {
      await this.runApiCall(search, true, false);
    }
  }

  /**
   * Parses templateProp with data from item objects
   * @param obj The object to get data from
   */
  parseTemplate(obj: any) {
    if (this.isLabelPropArray) {
      let label = '';
      for (const prop of this.labelPropArray) {
        const value = this.getProperty(obj, prop);
        if (value && !label) {
          label += value;
        }
        else if (value) {
          label += `${this.labelSplit}${value}`;
        }
      }
      return label;
    }
    else {
      return this.util.parseText(this.templateProp, obj, this.fields, this.missingProp);
    }
  }

  filterItems(items: any[], text: string, onlyVal = false) {
    return items.filter(item => {
      const itmVal = this.getProperty(item, this.valueProp) ?? '';
      let itmLabel: string;
      if (this.useTemplate || this.isLabelPropArray) {
        itmLabel = this.parseTemplate(item) ?? '';
      }
      else {
        itmLabel = this.getProperty(item, this.labelProp) ?? '';
      }
      if (onlyVal)
        return itmVal.toLowerCase().indexOf(text) !== -1;
      else
        return itmLabel.toLowerCase().indexOf(text) !== -1 ||
          itmVal.toLowerCase().indexOf(text) !== -1;
    });
  }

  async searchItems(event: {
    component: IonicSelectableComponent,
    text: string
  }) {

    const text = (event.text || '').trim().toLowerCase();
    this.logger.debug('Search..' + text);

    if (this.useServerSearch) {
      this.logger.debug('run api lookup..');
      event.component.startSearch();

      if (this.sourceType === SourceType.API) {
        await this.runApiCall(text, false);
      }
      else if (this.sourceType === SourceType.Lookup) {
        await this.runLookupCall(this.table, text, false);
      }
      event.component.endSearch();
    }
    else {
      this.logger.debug('filtersearch');
      event.component.startSearch();
      const items = this.filterItems(this.allOptions, text);
      event.component.items = items;
      event.component.endSearch();
    }

  }


  initAndRunApi(search?: string): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async resolve => {
      const status = await this.fieldService.initFieldFromStorage(this.fieldInitUrl);
      this.fieldIsVisible = status.visible;
      this.to.autoServerSearch = status.serverSearch;
      if (status.changed) {
        await this.runApiCall(search, true, this.isAutoServerSearch);
      }
      this.fieldService.initFieldFromApi(this.fieldInitUrl, this.formId, this.initMapping, this.to.headers, this.to.useCredentials).subscribe(async newStatus => {
        this.fieldIsVisible = newStatus.visible;
        this.to.autoServerSearch = newStatus.serverSearch;
        if (!status.changed || newStatus.changed) {
          await this.runApiCall(search, true, this.isAutoServerSearch);
        }
        resolve();
      });
    });
  }

  runApiCall(search = '', shouldBeLoading = true, init = false): Promise<any[]> {
    // should not call API if field init hides field or if it's missing url or if search is too short in serversearch
    if (!this.fieldIsVisible || !this.url || (this.useServerSearch && search?.length < this.searchMinLength && !init)) {
      if (!this.fieldIsVisible || !this.url) {
        this.listOptions = [];
      }
      this.loading = false;
      this.searchText = '';
      return;
    }
    this.errorText = '';
    this.loading = shouldBeLoading;
    if (search) {
      this.searchText = search;
    }
    else {
      this.searchText = '';
    }

    let url = this.util.parseText(this.url, this.model, this.fields);
    if (this.appendQuery) {
      if (url.includes('?')) {
        url += '&' + this.appendQuery + '=' + search;
      }
      else {
        url += '?' + this.appendQuery + '=' + search;
      }
    }
    if (this.isAutoServerSearch) {
      if (url.includes('?')) {
        url += '&' + this.serverSearchModeParam + '=' + this.useServerSearch;
      }
      else {
        url += '?' + this.serverSearchModeParam + '=' + this.useServerSearch;
      }
    }

    return new Promise<any[]>(resolve => {
      let obs: Observable<CachedData<any>>;
      if (this.httpType === 'POST') {
        obs = this.cachedApi.getWebJsonWithPost(url, this.objPath, this.formId, this.to.headers, this.to.useCredentials);
      }
      else if (this.httpType === 'PUT') {
        obs = this.cachedApi.getWebJsonWithPut(url, this.objPath, this.formId, this.to.headers, this.to.useCredentials);
      }
      else if (this.httpType === 'DELETE') {
        obs = this.cachedApi.getWebJsonWithDelete(url, this.objPath, this.formId, this.to.headers, this.to.useCredentials);
      }
      else {
        obs = this.cachedApi.getWebJSON(url, this.objPath, this.formId, this.to.headers, this.to.useCredentials);
      }
      obs.subscribe((data: CachedData) => {
        const res = data.value;
        if (data.status === DataStatus.Error) {
          this.errorText = typeof data.error?.status === 'number' ? this.translate.instant('ApiErrorWithCode', { code: data.error.status })
            : this.translate.instant('ApiError');
          this.listOptions = [];
        }
        else if (data.status === DataStatus.Updated) {
          if (res) {
            if (Array.isArray(res) && res.length > 0) {
              this.loading = false;
              this.listOptions = res.map(r => {
                if (this.hasValuePath) {
                  r[this.valueProp] = this.util.dotRef(r, this.fullValueProp);
                }
                if (this.hasLabelPath) {
                  r[this.labelProp] = this.util.dotRef(r, this.fullLabelProp);
                }
                return r;
              });
            }
            else {
              this.listOptions = [];
            }
            this.ref.callReference(this.watchKey, this.formId, this.formType, TriggerType.Update, this.field.key);
          }
          else {
            this.listOptions = [];
          }
        }
        this.to.options = this.listOptions;
        this.validateVirtualList();
        this.checkMissingOptions();
        if (data.source === DataSource.API) {
          this.loading = false;
          resolve(this.listOptions);
        }
      });
    });
  }

  runLookupCall(table: string, search?: string, shouldBeLoading = false): Promise<any[]> {
    if (!this.table || (this.hasParent && !this.parentValue) || (this.useServerSearch && search?.length < this.searchMinLength)) {
      this.listOptions = [];
      this.loading = false;
      this.searchText = '';
      return;
    }
    this.errorText = '';
    this.loading = shouldBeLoading;
    if (search) {
      this.searchText = search;
    }
    else {
      this.searchText = '';
    }

    table = this.util.parseText(table, this.model, this.fields);

    return new Promise<any[]>(resolve => {
      this.cachedApi.getLookupJson(table, this.parentValue).subscribe(data => {
        if (data.status === DataStatus.Error) {
          this.errorText = typeof data.error?.status === 'number' ? this.translate.instant('ApiErrorWithCode', { code: data.error.status })
            : this.translate.instant('ApiError');
          this.listOptions = [];
        }
        else if (data.status === DataStatus.Updated) {
          if (Array.isArray(data.value) && data.value.length > 0) {
            this.loading = false;
            this.listOptions = data.value;
          }
          else {
            this.listOptions = [];
          }
        }
        this.to.options = this.listOptions;
        this.validateVirtualList();
        this.checkMissingOptions();
        if (data.source === DataSource.API) {
          this.loading = false;
          resolve(this.listOptions);
        }
      });

    });
  }

  /**
   * Set local list, and check for history values if it should
   */
  setLocalList() {
    this.listOptions = Array.isArray(this.to.options) ? this.to.options : [];
    this.validateVirtualList();
    if (this.useHistory) {
      this.route.paramMap.subscribe(async params => {
        await this.auth.waitForAuth();
        const formId = (this.to.historyForm) ? this.to.historyForm : params.get('id');
        this.api.getPreviousValues(formId, this.key as string).subscribe(({value: history}) => {
          this.listOptions = this.mergeHistory(this.listOptions, history);
          this.validateVirtualList();
        });
      });
    }
  }

  validateVirtualList() {
    this.useVirtualScroll = (this.listOptions.length >= this.virtualScrollSize);
  }

  checkMissingOptions() {
    if (this.missedOptions.length === 0) {
      return;
    }
    const missedVals = this.missedOptions.map(o => this.getProperty(o, this.valueProp));
    const listVals = this.listOptions.map(o => this.getProperty(o, this.valueProp));
    for (const val of missedVals) {
      if (listVals.includes(val)) {
        this.missedOptions = this.missedOptions.filter(o => val !== this.getProperty(o, this.valueProp));
        const option = this.getOption(this.listOptions, val);
        this.selectedOptions.push(option);
      }
    }
    this.setAllOptions();
  }

  setAllOptions() {
    let options = this.listOptions;
    this.disabledOptions = this.findDisabledOptions(options, this.model[this.key as string]);
    if (this.selectedOptions.length > 0) {
      const vals = this.selectedOptions.map(o => this.getProperty(o, this.valueProp));
      options = options.filter(o => !vals.includes(this.getProperty(o, this.valueProp)));
    }
    this._allOptions = this.util.uniqueObjects(this.selectedOptions.concat(this.missedOptions).concat(options), this.valueProp);
  }

  clearModal() {
    this.logger.debug('==CLEAR==');
    this.fieldService.clearValue();
    this.selectComponent?.clear();
  }

  itemSelected(event: {
    component: IonicSelectableComponent,
    item: any,
    isSelected: boolean
  }) {
    if (!this.useMultiple) {
      return;
    }
    if (event.isSelected && !this.selectedOptions.find(o => o === event.item)) {
      this.selectedOptions.push(event.item);
    }
    else {
      this.selectedOptions = this.selectedOptions.filter(e => e !== event.item);
    }
  }

  itemChanged(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    if (this.model[this.key as string] !== event.value) {
      this.fieldService.setValue(event.value);
    }
    this.logger.debug('selected:', event.value);
    this.changedValue = true;
    this.prevSearch = this.searchText;
    const values = Array.isArray(event.value) ? event.value : [event.value];
    if (this.storeAsJson && this.useMultiple) {
      this.selectedOptions = values;
    }
    else if (this.useMultiple) {
      this.selectedOptions = values.map(value => {
        let option = this.selectedOptions.find(o => this.getProperty(o, this.valueProp) === value);
        if (!option) {
          option = this.getOption(this.listOptions, value);
        }
        if (!option) {
          const v = event.component._selectedItems.find(o => o === value);
          option = {};
          option[this.valueProp] = v;
          option[this.labelProp] = v;
          option[this.shownLabelProp] = v;
        }
        return option;
      }).filter(o => o);
    }
    this.setAllOptions();
    this.checkLabelAndData();
  }

  onDone(event: { component: IonicSelectableComponent }) {
    this.logger.debug('onDone!');
    if (!this.useServerSearch || this.useMultiple) {
      event.component.searchText = '';
    }
    event.component.items = this.allOptions;
    this.stateService.modalselectClosed(this.key as string, this.formId, this.formType);
    if (this.useServerSearch && !this.useMultiple && !this.changedValue && this.searchText !== this.prevSearch) {
      event.component.searchText = this.prevSearch;
      this.searchItems({ component: event.component, text: this.prevSearch });
    }
    else if (this.useServerSearch && this.useMultiple && !this.changedValue && this.searchText !== this.prevSearch) {
      //event.component.searchText = this.searchText;
      this.prevSearch = this.searchText;
      this.searchItems({ component: event.component, text: this.prevSearch });
    }
  }

  async opened(event: { component: IonicSelectableComponent }) {
    this.changedValue = false;
    // event.component.items = this.allOptions;
    if ((this.useServerSearch && !this.useMultiple) && this.searchText && !event.component.searchText) {
      event.component.searchText = this.searchText;
      event.component.startSearch();
    }
    else if (!this.useMultiple && this.allOptions.length > 0 && this.fieldService.getValue()) {
      let value = this.fieldService.getValue();
      if (this.storeAsJson) {
        value = this.util.dotRef(value, this.valueProp);
      }
      const index = this.allOptions.findIndex(option => this.util.dotRef(option, this.valueProp) === value);
      if (index >= 0) {
        setTimeout(() => {
          const items = document.querySelectorAll<HTMLIonItemElement>('ionic-selectable-modal ion-item.ionic-selectable-item');
          if (items[index]) {
            items[index].focus();
          }
          else {
            this.selectedOptions.push(this.allOptions[index]);
            this.setAllOptions();
          }
        }, 20);
      }
    }
    this.stateService.modalselectOpened(this.key as string, this.formId, this.formType);
  }

  // Footer btnOptions
  toggleItems() {
    this.selectComponent.toggleItems(this.selectComponent.itemsToConfirm.length ? false : true);
    //    this.confirm();
  }

  checkLabelAndData(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      setTimeout(() => {
        const label: HTMLIonLabelElement = this.elm.nativeElement.querySelector('ion-label');
        if (this.shouldNotCheckOverflow) {
          setTimeout(() => {
            resolve(true);
          });
          return;
        }
        const select: HTMLElement = this.elm.nativeElement.querySelector('ionic-selectable');
        const value: HTMLDivElement = select?.querySelector('div.ionic-selectable-value-item');
        if (!label || !value) {
          setTimeout(() => {
            resolve(false);
          });
        }
        else if (this.dom.isOverflowing(label) || this.dom.isOverflowing(value, 5)) {
          this.setOverheadLabel(environment.largeLabelPosition as 'floating' | 'stacked', select).then(() => resolve(true));
        }
        else {
          setTimeout(() => {
            resolve(false);
          });
        }
      });
    });
  }

  /**
   * Sort the options with the recent history values first in the list
   * @param options The current options
   * @param history The history values
   */
  private mergeHistory(options: Options[], history: string[]): Options[] {
    const newOpts: Options[] = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < history.length; i++) {
      for (let j = 0; j < options.length; j++) {
        const value = this.getProperty(options[j], this.valueProp);
        if (history[i] === value) {
          newOpts.push(options[j]); // Add option in history to new
          options.splice(j, 1); // Remove it from old history
          j--; // Old is one shorter
        }
      }
    }
    // Add elements not in history
    return newOpts.concat(options);
  }

  private setOverheadLabel(position: 'floating' | 'stacked', select?: HTMLElement): Promise<void> {
    return new Promise<void>(resolve => {
      select ??= this.elm.nativeElement.querySelector('ionic-selectable');
      this.labelPosition = position;
      if (select?.style) {
        select.style.maxWidth = '100%';
        select.style.width = '100%';
      }
      setTimeout(() => {
        resolve();
      });
    });
  }

  private waitForInit(): Promise<void> {
    if (!this.doingInit.value) {
      return Promise.resolve();
    }

    return new Promise<void>(resolve => {
      const sub = this.doingInit.subscribe(status => {
        if (!status) {
          sub.unsubscribe();
          resolve();
        }
      });
    });
  }

  /**
   * Get an property from an object based on path
   * @param obj The object to get the property from
   * @param path The path to the property
   * @returns The property
   */
  private getProperty(obj: any, path: string, defaultValue?: any) {
    return this.util.dotRef(obj, path, defaultValue);
  }

  /**
   * Set an property with an given value based on path
   * @param obj The object to set an property on
   * @param path The path for the property
   * @param value The value to set as the property
   * @returns The object with the new property
   */
  private setProperty(obj: any, path: string, value: any) {
    return this.util.dotSet(obj, path, value);
  }

  /**
   * Copy an property from an object to an other based on path
   * @param obj1 The object to copy the property from
   * @param obj2 The object to copy the property to
   * @param path The path to the property
   */
  private copyProperty(obj1: any, obj2: any, path: string, defaultValue?: any): void {
    const prop = this.getProperty(obj1, path, defaultValue);
    this.setProperty(obj2, path, prop);
  }

  /**
   * Find the property that matches an given value
   * @param options The options to search
   * @param value The value to match with
   * @returns The option that matches, else `null`
   */
  private getOption(options: any[], value: any) {
    if (!value) {
      return null;
    }
    else if (typeof value === 'object') {
      return options.find(o => this.getProperty(o, this.valueProp) === this.getProperty(value, this.valueProp));
    }
    else {
      return options.find(o => this.getProperty(o, this.valueProp) === value);
    }
  }

  private removeMissingValues(missingValues: any[], values: any | any[]) {
    if (!values || missingValues.length === 0) {
      return;
    }

    const isArray = Array.isArray(values);
    if (!isArray) {
      values = [values];
    }
    const valuesLeft = values.filter(value => {
      const key = (typeof value === 'object') ? this.getProperty(value, this.valueProp) : value;
      if (missingValues.findIndex(m => m[this.valueProp] === key) < 0) {
        return true;
      }
    });
    if(valuesLeft.length === 0) {
      this.clearModal();
    }
    else {
      const value = (isArray) ? valuesLeft: valuesLeft[0];
      this.fieldService.setValue(value);
    }

  }

  private createMissingOptions(options: any[], values: any | any[]): any[] {
    if (!values) {
      return [];
    }
    if (!Array.isArray(values)) {
      values = [values];
    }
    const keys = options.map(o => this.getProperty(o, this.valueProp));
    const result: any[] = [];
    for (const value of values) {
      if (typeof value === 'object') {
        const optionVal = this.getProperty(value, this.valueProp);
        if (!keys.includes(optionVal)) {
          if (this.defaultItemName) {
            const label = `${optionVal} ${this.defaultItemName}`;
            const option: any = {};
            this.setProperty(option, this.valueProp, optionVal);
            this.setProperty(option, this.labelProp, label);
            result.push(option);
          }
          else {
            const optionLabel = this.getProperty(value, this.labelProp);
            const option = {['_notfound_']: true, ['_selected_']: true};
            this.setProperty(option, this.valueProp, optionVal);
            this.setProperty(option, this.labelProp, this.translate.instant('OptionNotFound', {value: optionLabel}));
            result.push(option);
          }
        }
      }
      else {
        if (!keys.includes(value)) {
          if (this.defaultItemName) {
            const label = `${value} ${this.defaultItemName}`;
            const option: any = {};
            this.setProperty(option, this.valueProp, value);
            this.setProperty(option, this.labelProp, label);
            result.push(option);
          }
          else {
            const option = {['_notfound_']: true, ['_selected_']: true};
            this.setProperty(option, this.valueProp, value);
            this.setProperty(option, this.labelProp, this.translate.instant('OptionNotFound', {value: value}));
            result.push(option);
          }
        }
      }
    }
    return result;
  }

  private findDisabledOptions(options: any[], values: any | any[]): any[] {
    const missing = options.filter(o => o['_notfound_']);
    if (!this.useMultiple || !values) {
      return missing;
    }
    else {
      if (!Array.isArray(values)) {
        values = [values];
      }
      const result = [];
      for (const option of missing) {
        if (!option['_selected_']) {
          result.push(option);
        }
        else {
          const value = this.getProperty(option, this.valueProp);
          if (!values.includes(value)) {
            option['_selected_'] = false;
            result.push(option);
          }
        }
      }
      return result;
    }
  }

}
