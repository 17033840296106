import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LastRegComponent } from './last-reg/last-reg.component';
import { RegistrationModalComponent } from './registration-modal/registration-modal.component';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { FormlyModule } from '@ngx-formly/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoggerModule } from 'ngx-logger';
import { TextModalComponent } from './text-modal/text-modal.component';
import { MarkdownModule } from 'ngx-markdown';
import { environment } from 'src/environments/environment';



@NgModule({
  declarations: [
    LastRegComponent,
    RegistrationModalComponent,
    TextModalComponent
  ],
  imports: [
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FormlyIonicModule,
    FormlyModule.forChild(),
    TranslateModule.forChild(),
    LoggerModule.forChild(),
    MarkdownModule.forRoot(environment.markdownConfig),
  ],
  exports: [
    LastRegComponent,
    RegistrationModalComponent
  ]
})
export class SharedDirectivesModule { }
