import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { Geolocation, GeolocationOptions, Geoposition, PositionError, } from '@ionic-native/geolocation/ngx';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { NGXLogger } from 'ngx-logger';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { FieldService } from 'src/app/services/field.service';
import { PopupService } from 'src/app/services/popup.service';
import { TriggerType, Trigger, IonColor, FormType } from 'src/app/models/models';
import { ValidationService } from 'src/app/services/validation.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-field-gpsposition',
  templateUrl: './gpsposition.component.html',
  styleUrls: ['./gpsposition.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class GpspositionComponent extends FieldType implements OnInit, Trigger {
  gettingPosition = false;
  disableButton = false;
  positionSubsription: Subscription;
  geoOptions: GeolocationOptions = {
    enableHighAccuracy: true,
    timeout: 10000
  };
  accuracy: number;

  constructor(
    private position: Geolocation,
    private watch: WatchgroupService,
    private logger: NGXLogger,
    private ref: ComponentRefService,
    private fieldService: FieldService,
    private popup: PopupService,
    private validation: ValidationService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label() {
    const label = this.to.label || '';
    return (this.showAccuracyInLabel && this.accuracy) ? `${label} (±${this.accuracy}m)` : label;
  }

  get splitter() {
    return this.to.splitter || ',';
  }

  get disableInput(): boolean {
    return this.to.disableInput ?? this.autotrigger;
  }

  get disabled(): boolean {
    return this.to.disabled ?? false;
  }

  get autotrigger(): boolean {
    return this.disabled ? false : this.to.autotrigger ?? false;
  }

  get color(): string {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Primary;
  }

  get showAccuracyInValue(): boolean {
    return this.to.showAccuracyInValue ?? false;
  }

  get showAccuracyInLabel(): boolean {
    return this.to.showAccuracyInLabel ?? false;
  }

  get decimals(): number {
    return this.to.decimals ?? 7;
  }

  get formId(): number {
    return this.to?.currentForm?.id;
  }
  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  public static getHtml(config: FormlyFieldConfig, value: string) {
    const label = config.templateOptions.label ?? '';
    const borderColor = getComputedStyle(document.documentElement).getPropertyValue(`--ion-color-step-300`);
    return `<ion-card><ion-list>
              <ion-item lines="none">
                <ion-label class="ion-text-wrap">${label}</ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-button color="primary" slot="start" fill="clear" disabled="true">
                  <ion-icon slot="icon-only" name="location-outline"></ion-icon>
                </ion-button>
                <ion-input value="${value}" type="text" style="border-bottom: 1px solid ${borderColor}"></ion-input>
              </ion-item>
            </ion-list></ion-card>`;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    if (this.autotrigger) {
      this.findPosition(true);
    }
    if (this.disabled || this.to.disableButton) {
      this.disableButton = true;
    }
    if (this.disableInput) {
      this.formControl.disable();
    }

    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length >0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => this.findPosition());
    }
  }

  /**
   * Find position of device, either once or watch changes
   * @param init (Optional) If it is called by autotrigger, and not by user. Default: `false`
   * @param event (Optional) Event when caused by click on button
   */
  findPosition(init = false, event?: Event): Promise<string> {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    return this.getPosition(init);
  }

  /**
   * Get position once
   * @param init (Optional) If it is called by autotrigger, and not by user. Default: `false`
   */
  async getPosition(init = false): Promise<string> {
    this.gettingPosition = true;
    try {
      this.logger.debug('Finding position ...');
      const pos = await this.position.getCurrentPosition(this.geoOptions);
      return this.setPosition(pos, init);
    }
    catch (err) {
      return this.positionError(err);
    }
  }

  /**
   * Set position to model
   * @param position The position to set
   * @param init If it was found by autotrigger
   */
  setPosition(position: Geoposition, init = false): string {
    this.gettingPosition = false;
    const latitude = this.decimals >= 0 ? position.coords.latitude.toFixed(this.decimals) : position.coords.latitude;
    const longitude = this.decimals >= 0 ? position.coords.longitude.toFixed(this.decimals) : position.coords.longitude;
    let value = `${latitude}${this.splitter}${longitude}`;
    this.accuracy = +position.coords.accuracy.toFixed(1);
    if (this.showAccuracyInValue) {
      value += ` (±${this.accuracy}m)`;
    }
    this.fieldService.setValue(value);
    if (init) {
      this.to.initValue = value;
    }

    return value;
  }

  /**
   * Give warning to user when it fails
   * @param err The position error
   */
  positionError(err: PositionError) {
    this.logger.error('Error getting position', err);
    this.gettingPosition = false;
    let errorMsg: string;
    if (err.code === 1) {
      errorMsg = 'NoPositionAccess';
      this.popup.showAlert(errorMsg, 'NoPositionAccessMsg', true);
    }
    else {
      errorMsg = 'NoPosition';
      this.popup.showAlert(errorMsg, 'NoPositionMsg', true);
    }
    return this.field.defaultValue || null;
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.GPS) {
      const value = await this.findPosition();
        this.model[this.key as string | number] = value;
    }
    else if (type === TriggerType.Focus) {
      await this.findPosition();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  private isGeoposition(position: any): position is Geoposition {
    if (!position) {
      return false;
    }
    else if (position.coords && position.timestamp) {
      return true;
    }
    else {
      return false;
    }
  }
}
