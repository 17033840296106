<ion-list>
  <ion-item lines="none" *ngIf="label">
    <ion-label class="ion-text-wrap">{{label}}</ion-label>
  </ion-item>

  <ion-item lines="none" *ngIf="!hideButtons && !bgError">
    <ion-buttons slot="start" class="paint-buttons">
      <ion-button [class.aniShake]="highlightBrush" slot="start" [color]="brushBtnColor" fill="clear" (click)="toggleDraw()">
        <ion-icon slot="icon-only" name="brush-outline"></ion-icon>
      </ion-button>
      <ion-button slot="start" [color]="textBtnColor" fill="clear" (click)="toggleText()" *ngIf="text">
        <ion-icon slot="icon-only" name="text-outline"></ion-icon>
      </ion-button>
      <ion-button slot="start" [color]="shapeBtnColor" fill="clear" (click)="toggleShape()" *ngIf="shapeUrl && shapeList.length > 0">
        <ion-icon slot="icon-only" name="color-fill-outline"></ion-icon>
      </ion-button>
      <ion-button slot="start" color="dark" fill="clear" (click)="openColorPopover()" *ngIf="pickColor && (state === 'draw' || state === 'text')">
        <ion-icon slot="icon-only" name="color-palette-outline"></ion-icon>
      </ion-button>
      <ion-button slot="start" color="dark" fill="clear" (click)="selectShape()" *ngIf="state === 'shape'">
        <ion-icon slot="icon-only" name="albums-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>
  
  <!--<ion-item lines="none" *ngIf="drawingOff">
    <ion-text color="warning">{{'DrawingOff' | translate}}</ion-text>
  </ion-item>-->
  <ion-item lines="none" *ngIf="bgError">
    <ion-text color="danger"><b>{{errorMsg}}</b></ion-text>
  </ion-item>
  <ion-item id="canvas-wrapper" lines="none" *ngIf="!bgError">
    <canvas #canvas (click)="canvasClicked()"></canvas>
  </ion-item>

  <ion-item lines="none">
    <ion-buttons slot="start" class="camera-buttons" *ngIf="!hideButtons">
      <ion-button slot="end" color="dark" fill="clear" (click)="getPicture(true)" *ngIf="photo" [disabled]="disableButton('camera')">
        <ion-icon slot="icon-only" name="camera-outline"></ion-icon>
      </ion-button>
      <ion-button slot="end" color="dark" fill="clear" (click)="getPicture(false)" *ngIf="gallery">
        <ion-icon slot="icon-only" name="images-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons *ngIf="!bgError" slot="end" class="undo-buttons">
      <ion-button color="dark" slot="end" fill="clear" (click)="undo()" *ngIf="!hideButtons" [disabled]="disableButton('undo')" class="ion-float-rigth">
        <ion-icon slot="icon-only" name="arrow-undo-sharp"></ion-icon>
      </ion-button>
      <ion-button color="danger" slot="end" fill="clear" (click)="reset()" *ngIf="!hideResetBtn" [disabled]="disableButton('reset')" class="ion-float-rigth">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
      <ion-button color="dark" slot="end" fill="clear" (click)="redo()" *ngIf="!hideButtons" [disabled]="disableButton('redo')" class="ion-float-rigth">
        <ion-icon slot="icon-only" name="arrow-redo-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>
</ion-list>

