export enum LinkSource {
    Forms = 'forms',
    History = 'history',
    HomeFormView = 'home:formview',
    HomeForms = 'home:forms',
    HomeLastUsed = 'home:lastused',
    HomeFavorites = 'home:favorites',
    HomeHistory = 'home:history',
    LastReg = 'lastreg',
    Reg = 'reg',
    Unknown = 'unknown'
}
