<ion-list *ngIf="!multiscan">
  <ion-item lines="none">
    <ion-label class="ion-text-wrap">{{label}}</ion-label>
  </ion-item>
  <ion-item class="input-item" lines="none">
    <ion-button [color]="color" slot="start" fill="clear" (click)="startScan($event)" [disabled]="disableButton">
      <ion-icon slot="icon-only" name="barcode-sharp"></ion-icon>
    </ion-button>
    <ion-input [placeholder]="to.placeholder" class="inputarea" [formControl]="$any(formControl)"></ion-input>
  </ion-item>
</ion-list>
<ion-button [color]="color" slot="start" fill="outline" (click)="startScan($event)" *ngIf="multiscan" [disabled]="disableButton">
  <ion-icon slot="start" name="barcode-sharp"></ion-icon>
  <ion-label>{{label}}</ion-label>
</ion-button>
<table #itemTable class="fullsize valuelist" *ngIf="multiscan"></table>
