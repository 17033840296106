import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { AuthService } from 'src/app/services/auth.service';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { DataStatus } from 'src/app/models/models';

@Component({
  selector: 'app-wrapper-user',
  templateUrl: './user-wrapper.component.html',
  styleUrls: ['./user-wrapper.component.scss'],
})
export class UserWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;
  userType: string;

  constructor(
    private cachedApi: CachedApiService,
    private auth: AuthService
  ) {
    super();
   }

  async ngOnInit() {
    this.userType = (this.to.type === 'user' || this.to.type === 'email') ? 'user' : 'username';
    this.to.type = 'text';
    if (this.to.label && !this.to.labelPosition) {
      setTimeout(() => {
        this.to.labelPosition = 'fixed';
      });
    }
    await this.auth.waitForAuth();
    this.cachedApi.getUserContext().subscribe(({value: user, status}) => {
      if (status === DataStatus.Updated && user && user[this.userType]) {
        this.formControl.setValue(user[this.userType]);
        this.to.initValue = user[this.userType];
      }
    });
  }
}
