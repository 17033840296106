  <ion-grid>
  <ion-row *ngIf="showLastReg && lastRegFound">
    <ion-col>
      <div id="lastReg" class="clickable" [class.highlightColor]="subLastRegUpdated" (click)="openRegistration()">
        <ion-spinner class="hover" *ngIf="lastRegLoading"></ion-spinner>
        <span *ngIf="lastRegFound">
          <span id="lastRegTitle">{{'LastReg' | translate}}:</span>&nbsp;<span class="lastRegValues">{{lastRegSumValues}}</span>
        </span>
      </div>
    </ion-col>
  </ion-row>
  <ion-row class="justify-content-center ion-align-items-center">
        <ion-col size="4" class="ion-text-center">
          <ion-button [disabled]="isDisabled" [ngClass]="{'wasPressed': btnPressed.type === 'startBtn' && isDisabled}" color="success" expand="block" (click)="doSubmit('startBtn')">
            <ion-label>
              {{startBtnLabel}}
            </ion-label>
            <ion-label *ngIf="!showLastReg && !isDisabled && btnPressed.type === 'startBtn' && btnPressed.time !== ''" class="timeInfo"><ion-icon name="time-outline"></ion-icon>{{clickedAt}}</ion-label>
          </ion-button>
        </ion-col>
        <ion-col size="4" class="ion-text-center">
          <ion-row>
            <ion-col >
              <span class="datepicker-toggle">
                <span class="datepicker-toggle-button"></span>
                <input type="time" [disabled]="isDisabled || !allowEditDatetime" [ngClass]="{'disabled': isDisabled || !allowEditDatetime}" value="{{currTime}}" (change)="setTime('time',$event)" class="datepicker-input">
                <span id="timeOnly" >{{timeHour | async}}<span [ngClass]="{'hidden':!showMarker}">:</span>{{timeMin | async}}</span>
              </span>
              <span class="datepicker-toggle">
                <span class="datepicker-toggle-button"></span>
                <input type="date" [disabled]="isDisabled || !allowEditDatetime" [ngClass]="{'disabled': isDisabled || !allowEditDatetime}" value="{{currDateVal}}" (change)="setTime('date',$event)" class="datepicker-input">
                <span id="dateOnly" >{{getDate}}</span>
              </span>
              
            </ion-col>
          </ion-row>

        </ion-col>
        <ion-col size="4" class="ion-text-center">
          <ion-button [disabled]="isDisabled" [ngClass]="{'wasPressed': btnPressed.type === 'endBtn' && isDisabled}" color="danger" expand="block" (click)="doSubmit('endBtn')">
            <ion-label>
              {{endBtnLabel}}
            </ion-label>
            <ion-label *ngIf="!showLastReg && !isDisabled && btnPressed.type === 'endBtn' && btnPressed.time !== ''" class="timeInfo"><ion-icon name="time-outline"></ion-icon>{{clickedAt}}</ion-label>
          </ion-button>
        </ion-col>

  </ion-row>
  <ion-row *ngIf="showTabs">
    <ion-col>
        <div id="sumTabs" class="clickable" [class.highlightColor]="subTabsUpdated" (click)="showTabsModal()" >
          <ion-spinner class="hover" *ngIf="lastRegLoading"></ion-spinner>
          <span *ngIf="sumTabs" [class.btnStyle]="!sumTabsLabel" [innerHTML]="sumTabs"></span>
        </div>
    </ion-col>
    
  </ion-row>
  <ion-row *ngIf="showPrevChoices">
    <ion-col>
        <div #prevChoicesContainer id="prevChoices" >
          <div class="flexbox">
            <div *ngIf="prevChoices.length < 1" class="noData">
              {{'NoListOptions' | translate}}
            </div>

          <ng-container
            *ngFor="let prev of prevChoices; let i = index; trackBy: trackbyId"
          >
            <ng-container
              *ngTemplateOutlet="history; context: { item: prev, index: i }"
            ></ng-container>
          </ng-container>
          
          <ion-spinner class="hover" *ngIf="prevChoicesLoading"></ion-spinner>

        </div>
      </div>
      <div id="prevChoicesBtnToggle" >
          <span class="clickable" (click)="toggleView()">{{toggleBtnText}}</span>
          <!-- <ion-icon class="aniHover" [name]="partialView ? 'caret-down-sharp' : 'caret-up-sharp'"></ion-icon>-->
      </div>

    </ion-col>
    
  </ion-row>

</ion-grid>

<ng-template
  button
  #history
  let-index="index"
  let-label="item.label"
  let-type="item.type"
  let-regId="item.regId"
  let-tabs="item.tabs"
  let-deviceType="item.deviceType"
  let-submitted="item.submitted"
>
  <span class="prevChoice" [class.typeTerminal]="deviceType === 'TERMINAL'" [class.typeIN]="deviceType === 'IN'" (click)="setTabs($event, index, false)">
  <!--  <ion-icon *ngIf="showTabs" class="editIcon" name="create-outline" (click)="setTabs($event, index, true)"></ion-icon> -->
    <span class="submitted">{{submitted | date: 'dd.MM.yy, HH:mm' }}</span>
    <span class="tabs" [innerHtml]="label"></span>
  </span>
</ng-template>
