import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-color-modal',
  templateUrl: './color-popover.component.html',
  styleUrls: ['./color-popover.component.scss'],
})
export class ColorPopoverComponent implements OnInit {
  @Input() defaultColor: string;
  @Input() brushSize: number;
  chosenColor: string;
  colors: string[];

  constructor(
    private popCtrl: PopoverController,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.chosenColor = this.defaultColor;
    this.colors = [this.defaultColor, '#000000', '#FFFFFF', '#009955',
                   '#EE0000', '#0000FF', '#FFFF00', '#FFB6C1',
                   '#D2691E', '#00CED1', '#696969', '#800080'];
  }

  setColor(event: any) {
    this.chosenColor = event.color.hex;
  }

  async closePopover(save: boolean) {
    try {
      if (save) {
        await this.popCtrl.dismiss({color: this.chosenColor, brushSize: this.brushSize});
      }
      else {
        await this.popCtrl.dismiss();
      }
    }
    catch (err) {
      this.logger.warn('Failed dismissing color popover', err);
    }
  }
}
