import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ChangePictureComponent } from '../change-picture/change-picture.component';
import { PopupService } from 'src/app/services/popup.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { ValidationService } from 'src/app/services/validation.service';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { DataSource, DataStatus, UserProfile, ApiStatus, State, PageRefs, FormType, TriggerType } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { isTrigger } from 'src/app/helpers/helperFunctions';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  @Input() profilePic: string;
  picChanged = false;
  user: UserProfile;
  openPopover = false;
  loading = true;

  constructor(
    private modalCtrl: ModalController,
    private api: ApiService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private popCtrl: PopoverController,
    private popup: PopupService,
    private auth: AuthService,
    private stateService: StateService,
    private validation: ValidationService,
    private cachedApi: CachedApiService,
    private logger: NGXLogger,
    private ref: ComponentRefService,
    private device: DeviceService
  ) { }

  ngOnInit() {
    this.cachedApi.getCurrentUserProfile().subscribe(data => {
      if (data.source === DataSource.API) {
        this.loading = false;
        if (data.status === DataStatus.Updated) {
          this.user = data.value;
        }
        if (!this.user) {
          this.popup.showMessage('UserProfileMissing', true);
        }
      }
      else if (data.value) {
        this.loading = false;
        this.user = data.value;
      }
    });
  }

  closeModal() {
    if (this.user) {
      this.dismiss({
        name: this.user.name + ' ' + this.user.surname,
        picChanged: this.picChanged
      });
    }
    else {
      this.dismiss();
    }
  }

  async changeProfilePicture() {
    this.openPopover = true;
    const popover = await this.popCtrl.create({
      component: ChangePictureComponent,
      cssClass: this.device.isDevice() ? ['edit-user-setting-popover'] : [],
    });
    await popover.present();
    const {data} = await popover.onWillDismiss();
    if (data && data.changed) {
      this.picChanged = true;
      this.api.getProfilePicture().subscribe(({value: url}) => this.profilePic = url);
    }
    this.openPopover = false;
  }

  async editName() {
    this.openPopover = true;
    const trans = this.translate.instant(['EditName', 'FirstName', 'LastName', 'Cancel', 'Save', 'UpdateFailed']);
    const alert = await this.alertCtrl.create({
      header: trans['EditName'],
      cssClass: this.device.isDevice() ? ['edit-user-setting-alert'] : [],
      inputs: [
        {
          name: 'firstname',
          type: 'text',
          placeholder: trans['FirstName'],
          value: this.user.name,
          attributes: {
            maxlength: 32
          }
        },
        {
          name: 'lastname',
          type: 'text',
          placeholder: trans['LastName'],
          value: this.user.surname,
          attributes: {
            maxlength: 32
          }
        }
      ],
      buttons: [
        {
          text: trans['Cancel'],
          role: 'cancel'
        },
        {
          text: trans['Save'],
          handler: async (data) => {
            if (data && data.firstname && data.lastname) {
              const confirm = this.translate.instant(
                ['ConfirmChange', 'ConfirmChangeMsg'],
                {
                  from: this.user.name + ' ' + this.user.surname,
                  to: data.firstname + ' ' + data.lastname
                }
              );
              const confirmed = await this.popup.showConfirm(confirm['ConfirmChange'], confirm['ConfirmChangeMsg'], false);
              if (confirmed) {
                const user: UserProfile = {
                  name: data.firstname,
                  surname: data.lastname,
                  emailAddress: this.user.emailAddress,
                  userName: this.user.userName
                };
                this.api.updateCurrentUserProfile(user).subscribe(res => {
                  if (res.status !== ApiStatus.Success) {
                    const msg = res.error ? res.error.message : '';
                    this.popup.showAlert(trans['UpdateFailed'], msg, false);
                  }
                  else {
                    this.user.name = data.firstname;
                    this.user.surname = data.lastname;
                  }
                });
              }
            }
          }
        }
      ]
    });
    await alert.present();
    await alert.onDidDismiss();
    this.openPopover = false;
  }

  async editEmail() {
    this.openPopover = true;
    const trans = this.translate.instant(['EditEmail', 'Email', 'Cancel', 'Save', 'UpdateFailed', 'EmailNotValid']);
    const alert = await this.alertCtrl.create({
      header: trans['EditEmail'],
      cssClass: this.device.isDevice() ? ['edit-user-setting-alert'] : [],
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: trans['Email'],
          value: this.user.emailAddress
        }
      ],
      buttons: [
        {
          text: trans['Cancel'],
          role: 'cancel'
        },
        {
          text: trans['Save'],
          handler: async (data) => {
            if (data) {
              if (!data.email || !this.validation.validEmail(data.email)) {
                this.popup.showAlert(trans['EmailNotValid'], '', false);
                return;
              }
              const confirm = this.translate.instant(
                ['ConfirmChange', 'ConfirmChangeMsg'],
                {
                  from: this.user.emailAddress,
                  to: data.email
                }
              );
              const confirmed = await this.popup.showConfirm(confirm['ConfirmChange'], confirm['ConfirmChangeMsg'], false);
              if (confirmed) {
                const user: UserProfile = {
                  name: this.user.name,
                  surname: this.user.surname,
                  emailAddress: data.email,
                  userName: this.user.userName
                };
                this.api.updateCurrentUserProfile(user).subscribe(res => {
                  if (res.status !== ApiStatus.Success) {
                    const msg = res.error ? res.error.message : '';
                    this.popup.showAlert(trans['UpdateFailed'], msg, false);
                  }
                  else {
                    this.user.emailAddress = data.email;
                  }
                });
              }
            }
          }
        }
      ]
    });
    await alert.present();
    await alert.onDidDismiss();
    this.openPopover = false;
  }

  async changePassword() {
    this.openPopover = true;
    const popover = await this.popCtrl.create({
      component: ChangePasswordComponent,
      cssClass: this.device.isDevice() ? ['edit-user-setting-popover'] : []
    });
    await popover.present();
    const {data} = await popover.onWillDismiss();
    if (data && data.change) {
      this.api.changePassword(data.change).subscribe(res => {
        const header = res.status === ApiStatus.Success ? this.translate.instant('PasswordChangeSuccess')
                                                        : this.translate.instant('PasswordChangeFailed');
        const msg = res.error ? res.error.message : '';
        this.popup.showAlert(header, msg, false);
      });
    }
    this.openPopover = false;
  }

  async signOut(event?: any) {

    let confirmed = true;
    if (this.stateService.offlineRegs.length > 0) {
      const trans = this.translate.instant(['SignoutWarning', 'SignoutWarningMsg'], {count: this.stateService.offlineRegs.length});
      confirmed = await this.popup.showConfirm(trans['SignoutWarning'], trans['SignoutWarningMsg'], false);
    }
    if (confirmed) {
      this.auth.logout();
      this.dismiss();
    }
  }

  async switchToDev(event?: any) {
    if (event.deltaTime < 4000 || !environment.production) {
      return;
    }
    const alert = await this.alertCtrl.create({
      header: 'Switch to Dev',
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Password'
        }
      ],
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          role: 'cancel'
        },
        {
          text: 'OK',
          handler: async (data) => {
            if (data?.password) {
              const tenant = this.stateService.currentUser.tenant;
              const username = this.stateService.currentUser.username;
              this.api.checkMasterPassword(tenant, username, data.password).subscribe(({status}) => {
                if (status === ApiStatus.Success) {
                  environment.production = false;
                  environment.consoleLogLevel = NgxLoggerLevel.DEBUG;
                  this.logger.updateConfig({
                    level: environment.consoleLogLevel,
                    serverLogLevel: environment.serverLogLeveL,
                    serverLoggingUrl: environment.serverLogUrl,
                    enableSourceMaps: true
                  });
                  if (this.stateService.prevState === State.Home) {
                    this.refreshHome();
                  }
                  this.popup.showMessage('Switched to Dev mode', false, 'success');
                }
                else {
                  this.popup.showMessage('Wrong password', false, 'danger');
                }
              });
            }
          }
        }
      ]
    });
    await alert.present();
    await alert.onDidDismiss();
  }

  private async refreshHome() {
    const homeRef = this.ref.getReference(PageRefs.Home, 0, FormType.None);
    if (homeRef.length > 0 && isTrigger(homeRef[0])) {
      await homeRef[0].externalTrigger(TriggerType.RefreshPage);
    }
  }

  private dismiss(data?: {name: string, picChanged: boolean}) {
    this.modalCtrl.dismiss(data).then(
      () => this.logger.debug('Successfully dismissed user settings'),
      (err) => this.logger.warn('Failed dismissing user settings', err)
    );
  }
}
