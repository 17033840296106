<span *ngIf="template === 'button'">  
  <ion-button color="primary" [slot]="slot" [fill]="fill" *ngIf="!showStatus && online">
      <ion-icon name="" slot="icon-only"></ion-icon>
    </ion-button>
  <ion-button color="success" [slot]="slot" [fill]="fill" (click)="toggleStatus(true, false)" *ngIf="showStatus && online">
    <ion-icon name="cloud-outline" slot="icon-only"></ion-icon>
  </ion-button>
  <ion-button color="danger" [slot]="slot" [fill]="fill" (click)="toggleStatus(true, false)" *ngIf="!online">
    <ion-icon name="cloud" [slot]="unsavedRegs > 0 ? 'start' : 'icon-only'"></ion-icon>
    <ion-badge color="danger" slot="start" *ngIf="unsavedRegs > 0">{{unsavedRegs}}</ion-badge>
  </ion-button>
</span>

<div *ngIf="template === 'banner' && !online" (click)="toggleStatus(false, true)" class="banner">
    <span>{{'OfflineAppTitle' | translate}}</span>
</div>

<div *ngIf="template === 'bannerWithMsg' && !online" (click)="toggleStatus(false, true)" class="banner">
  <span>{{'OfflineAppTitle' | translate}}</span> <br>
  <small>{{bannerSubText}}</small>
</div>

<div *ngIf="template === 'bannerWithBtn' && !online" (click)="toggleStatus(false, true)" class="banner banner-btn" [class.subtext]="bannerSubText">
  <span>{{'OfflineAppTitle' | translate}}</span> 
  <ion-icon name="information-circle-outline" color="tertiary" (click)="showInfo($event)" *ngIf="bannerSubText"></ion-icon>
</div>