import { Component, OnInit } from '@angular/core';
import { OnlineService } from 'src/app/services/online.service';
import { FormService } from 'src/app/services/form.service';
import { ModalController } from '@ionic/angular';
import { ApiStatus, FormType, NewReg, PageRefs, State, TriggerType } from 'src/app/models/models';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { InStorageService } from 'src/app/services/in-storage.service';
import { NGXLogger } from 'ngx-logger';
import { OfflineRegComponent } from '../offline-reg/offline-reg.component';
import { PopupService } from 'src/app/services/popup.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { TimeService } from 'src/app/services/time.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-offline-modal',
  templateUrl: './offline-modal.component.html',
  styleUrls: ['./offline-modal.component.scss'],
  providers: [FormService]
})
export class OfflineModalComponent implements OnInit {
  regs: {name: string, icon: string, time: Date, index: number, unknown: boolean, formId: number}[] = [];
  translateParam = {
    count: 0
  };

  get offlineRegs(): NewReg[] {
    return this.stateService.offlineRegs;
  }

  get hasNetwork(): boolean {
    return this.onlineService.hasNetwork();
  }

  constructor(
    private onlineService: OnlineService,
    private formService: FormService,
    private stateService: StateService,
    private modalCtrl: ModalController,
    private api: ApiService,
    private translate: TranslateService,
    private storage: InStorageService,
    private logger: NGXLogger,
    private popup: PopupService,
    private auth: AuthService,
    private ref: ComponentRefService,
    private timeService: TimeService
  ) { }

  ngOnInit() {
    this.setData();
  }

  /**
   * Get offline registrations, find form names and then set them to component
   */
  async setData() {
    this.regs = [];
    this.translateParam.count = this.offlineRegs.length;
    for (const reg of this.offlineRegs) {
      const form = await this.storage.getForm(reg.FormId);
      let r: {name: string, icon: string, time: Date, index: number, unknown: boolean, formId: number};
      if (form && form.theForm) {
        r = {
          time: reg.RegTime,
          index: this.regs.length,
          name: form.theForm.name,
          icon: form.theForm.iconClass,
          unknown: false,
          formId: reg.FormId
        };
      }
      else {
        r = {
          time: reg.RegTime,
          index: this.regs.length,
          name: this.translate.instant('UnknownForm'),
          icon: '',
          unknown: true,
          formId: reg.FormId
        };
      }
      this.regs.push(r);
    }
  }

  /**
   * Close the modal
   */
  closeModal() {
    this.modalCtrl.dismiss().then(
      () => this.logger.debug('Successfully dismissed offline modal'),
      (err) => this.logger.warn('Failed dismissing offline modal', err)
    );
  }

  /**
   * Remove an registration
   * @param index The index of the registration
   * @param event (Optional) Event when clicked
   */
  async removeReg(index: number, event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const confirmed = await this.popup.showConfirm('DeleteOfflineReg', 'DeleteOfflineRegMsg', true);
    if (confirmed) {
      this.stateService.removeOfflineRegistration(index);
      this.regs.splice(index, 1);
      this.storage.setOfflineRegs(this.offlineRegs);
      for (let i = 0; i < this.regs.length; i++) {
        this.regs[i].index = i;
      }
      this.checkAndRefreshHistory();
    }
  }

  /**
   * Open an registration
   * @param index The index of the registration
   */
  async openReg(index: number) {
    if (this.regs[index].unknown) {
      return;
    }
    this.logger.debug(`Open registration ${index}`);
    const modal = await this.modalCtrl.create({
      component: OfflineRegComponent,
      componentProps: {
        reg: this.offlineRegs[index],
        formName: this.regs[index].name
      }
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();
    if (data && data.delete) {
      this.removeReg(index);
    }
  }

  /**
   * Check user authentication, and then upload regs if user is authenticated
   * @param remainOffline If the app should remain offline
   */
  async doAuthAndUpload(remainOffline: boolean) {
    if (this.onlineService.hasNetwork()) {
      if (this.auth.isOfflineAuth()) {
        const doUpload = await this.auth.signInFromOffline();
        if (doUpload) {
          this.uploadRegs(remainOffline);
        }
        else {
          this.closeModal();
        }
      }
      else {
        this.uploadRegs(remainOffline);
      }
    }
    else {
      this.popup.showAlert('NoNetwork', '', true);
    }
  }


  /**
   * Upload registrations
   * @param remainOffline If the app should remain offline
   */
  private async uploadRegs(remainOffline: boolean) {
    const orgStatus = this.onlineService.currentStatus;
    this.onlineService.goOnline();
    const {success, errorMessage, failed} = await this.formService.uploadOfflineRegs();
    if (success) {
      if (remainOffline) {
        await firstValueFrom(this.api.getRegistrations(), {defaultValue: {value: [], status: ApiStatus.Failed}});
        this.onlineService.goOffline(orgStatus);
      }
      this.closeModal();
    }
    else {
      const title = this.translate.instant('RegistrationFailed');
      let msg: string;
      if (failed) {
        const formName = this.regs.find(r => r.formId === failed.FormId)?.name || this.translate.instant('UnknownForm');
        const regTime = this.timeService.formatDatetime(failed.RegTime, 'DD.MM HH:mm');
        msg = `<b>${formName}</b><br>${regTime}<br><br>${errorMessage}`;
      }
      else {
        msg = errorMessage;
      }
      this.popup.showAlert(title, msg, false);
      await firstValueFrom(this.api.getRegistrations(), {defaultValue: {value: [], status: ApiStatus.Failed}});
      this.onlineService.goOffline(orgStatus);
      await this.setData();
    }
    this.checkAndRefreshHistory();
  }

  private checkAndRefreshHistory() {
    if (this.stateService.state === State.History) {
      this.ref.callReference(PageRefs.History, 0, FormType.None, TriggerType.RefreshPage);
    }
  }
}
