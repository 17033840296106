<ion-header>
  <ion-toolbar color="header">
    <ion-title>{{'PrivacyInfo' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-card>
      <ion-card-header>
        {{'PrivacyLocalStorageTitle' | translate}}
      </ion-card-header>
      <ion-card-content>
        {{'PrivacyLocalStorageDesc' | translate}}<br><br>
        {{'PrivacyLocalStorageReason' | translate}}<br><br>
        {{'PrivacyLocalStorageDeletion' | translate}}
      </ion-card-content>
    </ion-card>
    <ion-card>
      <ion-card-header>
        {{'PrivacyGoogleAnalyticsTitle' | translate}}
      </ion-card-header>
      <ion-card-content>
        {{'PrivacyGoogleAnalyticsDesc' | translate}}<br><br>
        {{'PrivacyGoogleAnalyticsReason' | translate}}
      </ion-card-content>
    </ion-card>
    <ion-card>
      <ion-card-header>
        {{'PrivacyCookiesTitle' | translate}}
      </ion-card-header>
      <ion-card-content>
        {{'PrivacyCookiesDesc' | translate}}
      </ion-card-content>
    </ion-card>
  </ion-list>
</ion-content>