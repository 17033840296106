import { Component, OnInit, ViewContainerRef, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { NGXLogger } from 'ngx-logger';
import { FieldService } from 'src/app/services/field.service';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { Trigger, TriggerType, FormType } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { DeviceService } from 'src/app/services/device.service';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SwiperService } from 'src/app/services/swiper.service';

@Component({
  selector: 'app-wrapper-input-ref',
  templateUrl: './input-ref.component.html',
  styleUrls: ['./input-ref.component.scss'],
  providers: [FieldService]
})
export class InputRefComponent extends FieldWrapper implements OnInit, Trigger, AfterViewInit, OnDestroy {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;
  hasGesture = false;
  isActive = false;
  gestureType = null;
  valueSub: Subscription;
  resizeSub: Subscription;

  constructor(
    private ref: ComponentRefService,
    private logger: NGXLogger,
    private fieldService: FieldService,
    private elm: ElementRef,
    private dom: DomOperationsService,
    private device: DeviceService,
    private plt: Platform,
    private swiperService: SwiperService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get shouldNotCheckOverflow(): boolean {
    return this.to.notChangePosition || this.to.labelPosition === 'stacked' || this.to.labelPosition === 'floating';
  }

  get clearOnFocus(): boolean {
    if (this.to.type === 'time' || this.to.type === 'date' || this.to.type === 'datetime-local') {
      return false;
    }
    else {
      return this.to.clearOnFocus ?? false;
    }
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }


  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    const gesture = this.field.templateOptions.gesture;
    if (gesture && gesture.type) {
      this.gestureType = gesture.type;
      this.hasGesture = true;
      this.logger.debug(this.gestureType);
      if (!this.device.isMobile()) {
        this.fieldComponent.element.nativeElement.parentElement.style.setProperty('--gesture-icon-placement', '37px');
      }
    }
  }

  async ngAfterViewInit() {
    if (this.clearOnFocus) {
      const formlyElement = this.dom.findFormlyFieldElement(this.elm.nativeElement);
      const input: HTMLIonInputElement = formlyElement?.querySelector('ion-input');
      input?.addEventListener('ionFocus', () => {
        this.formControl.setValue(null);
      });
    }

    const res = await this.checkLabel(100);
    if (!res) {
      this.valueSub = this.formControl.valueChanges.subscribe(async () => {
        const newRes = await this.checkLabel(0);
        if (newRes) {
          this.valueSub.unsubscribe();
          this.resizeSub?.unsubscribe();
        }
      });
      this.resizeSub = this.plt.resize.subscribe(async () => {
        const newRes = await this.checkLabel(0);
        if (newRes) {
          this.resizeSub.unsubscribe();
          this.valueSub?.unsubscribe();
        }
      });
    }

  }

  ngOnDestroy() {
    this.valueSub?.unsubscribe();
    this.resizeSub?.unsubscribe();
  }

  async externalTrigger(type: TriggerType, data?: any): Promise<void> {
    if (type === TriggerType.Swipe) {
      this.isActive = true;

      const val = this.swiperService.swipeDate(data.event, data.data, this.field.formControl.value, this.field);
      if (val) {
        await this.ref.callReference(this.key as string, this.formId, this.formType, TriggerType.StopUpdate);
        this.fieldService.setValue(val);
      }
    }
    else if (type === TriggerType.Focus) {
      const input: HTMLIonInputElement = this.elm.nativeElement.querySelector('ion-input');
      if (!input) {
        return;
      }
      this.dom.scrollIntoViewAndExecute(input, () => input.setFocus());
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  setReference(key: string, id: number, type: FormType): void {
    this.ref.addReference(key, id, type, this);
  }

  checkLabel(waitTime: number): Promise<boolean> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<boolean>(async resolve => {
      if (this.shouldNotCheckOverflow) {
        resolve(true);
        return;
      }
      const formlyElement = this.dom.findFormlyFieldElement(this.elm.nativeElement);
      const label: HTMLIonLabelElement = formlyElement?.querySelector('ion-label');
      const ionInput: HTMLIonInputElement = formlyElement?.querySelector('ion-input');
      const input = await ionInput?.getInputElement();
      if (!label || !input) {
        resolve(false);
        return;
      }
      setTimeout(() => {
        if (label.clientHeight > 30 || this.dom.isOverflowing(label) || this.dom.isOverflowing(input, 5)) {
          this.to.labelPosition = environment.largeLabelPosition as 'floating' | 'stacked';
          resolve(true);
        }
        else {
          resolve(false);
        }
      }, waitTime);
    });
  }
}
