<ion-item lines="none" class="transparent">
  <div class="transparent wrapper">
    <span *ngIf="displayTemplate && !labelSize" class="datepicker-toggle" [innerHTML]="displayTemplate"></span>
    <h1 *ngIf="displayTemplate && labelSize" class="datepicker-toggle" ngClass="{{labelSize}}" [innerHTML]="displayTemplate"></h1>

    <span id="date" *ngIf="showDate">{{lastDate | date:'mediumDate'}}</span>
    <span *ngIf="!isDisabled && showDatepicker" class="datepicker-toggle-button" [ngClass]="{'button-float': !btnNextToLabel}">
      <input type="date" [disabled]="isDisabled || !showDatepicker" [ngClass]="{'disabled': isDisabled || !showDatepicker}" [(ngModel)]="lastDate" (change)="setDate($event)" class="datepicker-input">
      <ion-icon class="icon" name="calendar"></ion-icon>
    </span>
<!--    <span *ngIf="!isDisabled && clearKeys.length > 0" class="datepicker-toggle-button" [ngClass]="{'button-float': !btnNextToLabel}" (click)="triggerClear()">
      <ion-icon class="icon" name="close-outline"></ion-icon>
    </span>
-->  <!--  <ion-icon class="icon" *ngIf="hasSwipeGesture" name="swap-horizontal-outline"></ion-icon> -->
  </div>
</ion-item>