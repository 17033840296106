import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { ValidationService } from 'src/app/services/validation.service';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IonColor, DataSource, CachedData, DataStatus, TriggerType, FormType, Trigger, PageRefs } from 'src/app/models/models';
import { StateService } from 'src/app/services/state.service';
import { FormService } from 'src/app/services/form.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-field-open-form',
  templateUrl: './open-form.component.html',
  styleUrls: ['./open-form.component.scss'],
  providers: [WatchgroupService]
})
export class OpenFormComponent extends FieldType implements OnInit, Trigger {
  formId: number;
  formData: any;

  constructor(
    private watch: WatchgroupService,
    private util: UtilityService,
    private ref: ComponentRefService,
    private validation: ValidationService,
    private cachedApi: CachedApiService,
    private state: StateService,
    private formService: FormService,
    private logger: NGXLogger
  ) {
    super();
  }

  get color(): string {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Primary;
  }

  get fill(): 'clear' | 'outline' | 'solid' {
    switch (this.to.fill) {
      case 'clear':
      case 'outline':
      case 'solid':
        return this.to.fill;
      default:
        return 'solid';
    }
  }

  get label(): string {
    return this.to.label || 'Open form';
  }

  get formIdProp(): string {
    return this.to.formIdProp || 'formId';
  }

  get formDataProp(): string {
    return this.to.formDataProp || 'values';
  }

  get url(): string {
    return this.to.url || '';
  }

  get path(): string {
    return this.to.path || 'result';
  }

  get sendKeys(): string[] {
    if (typeof this.to.sendKeys === 'string') {
      return this.to.sendKeys.split(',');
    }
    else if (Array.isArray(this.to.sendKeys)) {
      return this.to.sendKeys;
    }
    else {
      return [];
    }
  }

  get json(): boolean {
    return this.to.json || false;
  }

  get disabled(): boolean {
    return this.to.disabled ?? false;
  }

  get autoTrigger(): boolean {
    return this.disabled ? false : this.to.autotrigger ?? false;
  }

  get httpType(): 'GET' | 'POST' | 'PUT' | 'DELETE' {
    const type = this.to.httpType?.toUpperCase();
    switch (type) {
      case 'POST':
      case 'PUT':
      case 'DELETE':
        return type;
      default:
        return 'GET';
    }
  }

  get currentFormId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get pageRef(): PageRefs {
    return this.to?.currentForm?.ref;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.currentFormId);
  }

  ngOnInit() {
    this.setReference(this.key as string, this.currentFormId, this.formType);
    this.formId = parseInt(this.to.formId, 10);

    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length >0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(vc => {
        if (this.url) {
          this.getDataFromAPI().subscribe(source => {
            if (source === DataSource.API && this.autoTrigger && this.formId) {
              this.goToForm();
            }
          });
        }
        else if (this.json && vc) {
          let data: any;
          try {
            data = JSON.parse(vc.newValue);
          }
          catch {
            data = vc.newValue;
          }

          if (this.path) {
            data = this.util.dotRef(data, this.path);
          }

          const tmp = parseInt(this.util.dotRef(data, this.formIdProp), 10);
          if (tmp) {
            this.formId = tmp;
          }

          this.formData = this.util.dotRef(data, this.formDataProp);
          if (this.autoTrigger && this.formId) {
            this.goToForm();
          }
        }
        else if (vc) {
          this.formId = parseInt(vc.newValue, 10);
          if (this.autoTrigger && this.formId) {
            this.goToForm();
          }
        }
      });
    }
    if (this.url)  {
      this.getDataFromAPI().subscribe(source => {
        if (source === DataSource.API && this.autoTrigger && this.formId) {
          this.goToForm();
        }
      });
    }
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Click) {
      await this.goToForm();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  getDataFromAPI(): Observable<DataSource> {
    const url = this.util.parseText(this.url, this.model, this.fields);
    let obs: Observable<CachedData<any>>;
    if (this.httpType === 'POST') {
      obs = this.cachedApi.getWebJsonWithPost(url, this.path, this.currentFormId, this.to.headers, this.to.useCredentials);
    }
    else if (this.httpType === 'PUT') {
      obs = this.cachedApi.getWebJsonWithPut(url, this.path, this.currentFormId, this.to.headers, this.to.useCredentials);
    }
    else if (this.httpType === 'DELETE') {
      obs = this.cachedApi.getWebJsonWithDelete(url, this.path, this.currentFormId, this.to.headers, this.to.useCredentials);
    }
    else {
      obs = this.cachedApi.getWebJSON(url, this.path, this.currentFormId, this.to.headers, this.to.useCredentials);
    }
    return obs.pipe(
      map<CachedData, DataSource>(data => {
        const result = data.value;
        if (data.status === DataStatus.Updated && result) {
          this.formData = this.util.dotRef(result, this.formDataProp);
          const tmp = parseInt(this.util.dotRef(result, this.formIdProp));
          if (tmp) {
            this.formId = tmp;
          }
        }
        return data.source;
      })
    );
  }

  async goToForm() {
    if (!this.formData) {
      this.formData = {};
    }

    for (const key of this.sendKeys) {
      this.formData[key] = this.model[key];
    }
    await this.formService.openForm(this.formId, this.formData);
  }
}
