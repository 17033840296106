import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormType, IonColor } from 'src/app/models/models';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent extends FieldType implements AfterViewInit {
  get isForm(): boolean {
    return this.to.currentForm?.type === FormType.Form;
  }

  constructor(
    private validation: ValidationService,
    private elm: ElementRef
  ) {
    super();
  }

  public static getHtml(config: FormlyFieldConfig, _value: any) {
    const colorClass = Object.values(IonColor).includes(config.templateOptions.colorType as IonColor) ? config.templateOptions.colorType
                                                                                                      : IonColor.Primary;
    const color = getComputedStyle(document.documentElement).getPropertyValue(`--ion-color-${colorClass}`);
    return `<div style="border-bottom: 2px dashed ${color}; margin: 50px 20px;"></div>`;
  }

  ngAfterViewInit() {
    if (this.validation.validColor(this.to.colorType)) {
      const color = getComputedStyle(document.documentElement).getPropertyValue(`--ion-color-${this.to.colorType}`);
      const div: HTMLDivElement = this.elm.nativeElement.querySelector('div.stepSpace');
      if (div) {
        div.style.borderBottomColor = color;
      }
    }
  }
}
