import { Component, OnInit } from '@angular/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import * as moment from 'moment';
import { LegacyService } from 'src/app/services/legacy.service';
import { TranslateService } from '@ngx-translate/core';
import { FieldService } from 'src/app/services/field.service';
import { WatchOptions } from 'src/app/models/interfaces/watchOptions';

@Component({
  selector: 'app-field-timediff',
  templateUrl: './timediff.component.html',
  styleUrls: ['./timediff.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class TimediffComponent extends FieldType implements OnInit {
  watchGroup: WatchOptions;

  get label(): string {
    return this.to.label || '';
  }

  get unit(): string {
    return this.to.unit || '';
  }

  get diff(): number {
    return (typeof this.model[this.key as string | number] === 'undefined') ? 0 : this.model[this.key as string | number];
  }

  get result(): string {
    if (isNaN(this.diff)) {
      return this.translate.instant('Error');
    }
    else {
      return `${this.diff} ${this.unit}`;
    }
  }

  get type(): moment.unitOfTime.Diff {
    switch (this.to.type) {
      case 'years':
      case 'months':
      case 'weeks':
      case 'days':
      case 'hours':
      case 'minutes':
      case 'seconds':
        return this.to.type;
      default:
        return 'hours';
    }
  }

  get decimal(): number {
    return (typeof this.to.decimal === 'undefined') ? 2 : parseInt(this.to.decimal);
  }

  constructor(
    private watch: WatchgroupService,
    private legacy: LegacyService,
    private translate: TranslateService,
    private fieldService: FieldService
  ) {
    super();
    this.fieldService.setField(this);
  }

  public static getHtml(config: FormlyFieldConfig, value: string) {
    const label = config.templateOptions.label ?? '';
    return `<ion-card><ion-item>
              <ion-label>${label}</ion-label>
              <span style="text-align: left">${value}</span>
            </ion-item></ion-card>`;
  }

  ngOnInit() {
    this.watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (this.watchGroup.fieldKeys.length >= 2) {
      this.watch.watchGroup(this.form, this.model, this.watchGroup).subscribe(() => {
        this.runCalc();
      });
      this.runCalc();
    }
  }

  runCalc() {
    const key1 = this.watchGroup.fieldKeys[0];
    const key2 = this.watchGroup.fieldKeys[1];
    if (!this.model[key1] || !this.model[key2]) return;

    const time1 = moment(this.model[key1],  ['YYYY-MM-DDTHH-mm-ss', 'YYYY-MM-DDTHH-mm', 'YYYY-MM-DD', 'HH:mm']);
    const time2 = moment(this.model[key2],  ['YYYY-MM-DDTHH-mm-ss', 'YYYY-MM-DDTHH-mm', 'YYYY-MM-DD', 'HH:mm']);
    if (time1.isValid() && time2.isValid()) {
      const value = +time2.diff(time1, this.type, true).toFixed(this.decimal);
      this.fieldService.setValue(value);
    }
    else {
      this.fieldService.setValue(NaN);
    }
  }

}
