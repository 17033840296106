import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-privacy-info',
  templateUrl: './privacy-info.component.html',
  styleUrls: ['./privacy-info.component.scss'],
})
export class PrivacyInfoComponent {

  constructor(
    private popCtrl: PopoverController,
    private logger: NGXLogger
  ) { }

  close() {
    this.popCtrl.dismiss().then(
      () => this.logger.debug('Successfull dismissed privacy popover'),
      (err) => this.logger.warn('Failed dismissing privacy popover', err)
    );
  }

}
