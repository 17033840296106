import { Component, OnInit } from '@angular/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { UtilityService } from 'src/app/services/utility.service';
import { BrowserService } from 'src/app/services/browser.service';
import { FormType } from 'src/app/models/models';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-field-documentlink',
  templateUrl: './documentlink.component.html',
  styleUrls: ['./documentlink.component.scss'],
  providers: [WatchgroupService]
})
export class DocumentlinkComponent extends FieldType implements OnInit {
  parsedUrl: string;
  parsedLabel: string;
  isParsed: boolean;
  constructor(
    private watch: WatchgroupService,
    private util: UtilityService,
    private browser: BrowserService,
    private state: StateService
  ) {
    super();
  }

  get label(): string {
    return this.to.label || '';
  }

  get url(): string {
    return this.to.url || '';
  }

  get missing(): string {
    return this.to.missing || '[missing]';
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  ngOnInit() {
    this.parseData();
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length >0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => this.parseData());
    }
  }

  parseData() {
    this.parsedLabel = this.util.parseText(this.label, this.model, this.fields, this.missing);
    this.parsedUrl = this.util.parseText(this.url, this.model, this.fields, this.missing);
    this.isParsed = this.util.textIsParsed(this.parsedUrl, this.missing);
  }

  openLink(url: string) {
    if (url && this.isParsed) {
      this.browser.openUrl(url);
    }
  }
}
