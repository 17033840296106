import { Injectable } from '@angular/core';
import { InThemes, Theme } from '../models/models';
import { InStorageService } from './in-storage.service';
import { DeviceService } from './device.service';
import { StatusBar, Style } from '@capacitor/status-bar';

/**
 * Service that inits and changes color theme
 */
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  theme: Theme;

  constructor(
    private storage: InStorageService,
    private device: DeviceService
  ) { }

  /**
   * If the current theme is dark mode
   */
    get isDarkmode(): boolean {
      return (typeof this.theme !== 'undefined') ? this.theme.value === 'dark' : false;
    }

  /**
   * Change the theme of the app
   * @param newTheme The new theme
   */
  changeTheme(newTheme: Theme | string, isInit = false) {
    if (typeof newTheme === 'string') {
      newTheme = this.getThemeByValue(newTheme);
    }
    const appClasses = document.body.classList;
    if (this.theme && this.theme.className) {
      appClasses.remove(this.theme.className);
    }
    if (newTheme.className) {
      appClasses.add(newTheme.className);
    }
    this.theme = newTheme;

    if (this.device.isDevice()) {
      const headerColor = getComputedStyle(document.documentElement.children[1]).getPropertyValue(`--ion-color-header`).trim();
      StatusBar.setBackgroundColor({color: headerColor});

      if (isInit) {
        this.setStatusBar(0);
      }
      else {
        this.setStatusBar(5);
      }
    }

    this.storage.setTheme(newTheme);
  }

  /**
   * Inits the theme of the app
   */
  async initTheme(): Promise<Theme> {
    if (this.device.isIOS()) {
      // StatusBar.setOverlaysWebView({overlay: false});
    }
    const preferDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    let theme = await this.storage.getTheme();
    if (!theme) {
      if (preferDarkMode) {
        theme = this.getThemeByValue('dark');
      }
      else {
        theme = this.getThemeByValue('light');
      }
    }
    this.changeTheme(theme, true);
    return theme;
  }



  /**
   * Get theme based on it's value
   * @param value The theme value
   */
  private getThemeByValue(value: string) {
    return InThemes.find(t => t.value === value);
  }

  private setStatusBar(count: number) {
    if (this.isDarkmode) {
      StatusBar.setStyle({style: Style.Dark});
    }
    else {
      StatusBar.setStyle({style: Style.Light});
    }
    if (count < 4) {
      setTimeout(() => {
        this.setStatusBar(++count);
      }, 500);
    }
  }
}
