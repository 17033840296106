import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { OnlineService } from 'src/app/services/online.service';
import { ModalController } from '@ionic/angular';
import { OfflineModalComponent } from '../offline-modal/offline-modal.component';
import { environment } from 'src/environments/environment';
import { PopupService } from 'src/app/services/popup.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { OnlineStatus } from 'src/app/models/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-online-status',
  templateUrl: './online-status.component.html',
  styleUrls: ['./online-status.component.scss'],
})
export class OnlineStatusComponent implements OnInit, OnDestroy {
  @Input() template: 'button' | 'banner' | 'bannerWithMsg' | 'bannerWithBtn' = 'button';
  @Input() slot = 'end';
  @Input() fill = 'clear';
  @Input() show = false;
  @Input() noClick = false;
  unsavedRegs = 0;
  showStatus: boolean;
  online = true;
  onlineSub: Subscription;
  regSub: Subscription;
  bannerSubText = '';

  constructor(
    private onlineService: OnlineService,
    private stateService: StateService,
    private modalCtrl: ModalController,
    private popup: PopupService,
    private auth: AuthService,
    private logger: NGXLogger,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.showStatus = this.show || !environment.production;
    this.onlineSub = this.onlineService.online.subscribe(status => this.updateStatusAndText(status));
    this.regSub = this.stateService.offlineRegCount.subscribe(count => this.unsavedRegs = count);
  }

  ngOnDestroy() {
    this.onlineSub?.unsubscribe();
    this.regSub?.unsubscribe();
  }


  async openModal() {
    const modal = await this.modalCtrl.create({
      component: OfflineModalComponent
    });
    await modal.present();
  }

  toggleStatus(fromButton: boolean, popupStatus: boolean) {
    if (fromButton && this.noClick) return;

    if (this.online) {
      this.goOffline();
    }
    else if (this.unsavedRegs > 0) {
      this.openModal();
    }
    else {
      this.goOnline(popupStatus);
    }
  }

  goOffline() {
    this.onlineService.goOffline(OnlineStatus.ManualOffline);
  }

  async goOnline(popupStatus: boolean) {
    this.logger.debug(popupStatus);
    if (this.onlineService.hasNetwork()) {
      if (this.auth.isOfflineAuth()) {
        await this.auth.signInFromOffline();
      }
      else {
        this.onlineService.goOnline();
        if (popupStatus) {
          this.popup.showMessage('OnlineStatusSetOnline', true, 'success');
        }

      }
    }
    else {
      this.popup.showMessage('NoNetwork', true);
    }

  }

  showInfo(event?: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();
    const title = this.translate.instant('OfflineAppTitle');
    this.popup.showAlert(title, this.bannerSubText, false);
  }

  private updateStatusAndText(status: boolean) {
    this.bannerSubText = this.onlineService.offlineBannerText;
    if (this.template === 'button') {
      this.online = status;
    }
    else {
      this.onlineService.showingMessage.subscribe(val => {
        if (!val) {
          this.online = status;
        }
      });
    }
  }
}
