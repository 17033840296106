import { SecurityContext } from '@angular/core';
import { MarkedOptions } from 'ngx-markdown';

export const commonEnvironment = {
    appVersion: '3.4.9',
    inBaseUrl: 'https://in.tempus.no', // Base URL of prod Tempus IN backend
    inBaseUrlDev: 'https://tempusinprod-tempusinprod-test.azurewebsites.net', // Base URL of dev Tempus IN backend
    inBaseUrlTest: 'https://tempusinprod-instaging.azurewebsites.net', // Base URL of staging Tempus IN backend
    apirUrl: 'https://inapir.azurewebsites.net/api', // Base URL of Apir for TempusIN prod
    currentIntroVersion: 1,
    lastHintsVersion: '3.0.0',
    photoQuality: 25,
    defaultIcon: 'alert', // What icon to use if an icon isn't found
    formViewLimit: 50, // Max number of fields in FormView
    markdownConfig: {
        sanitize: SecurityContext.NONE,
        markedOptions: {
            provide: MarkedOptions,
            useValue: {
                gfm: true,
                breaks: true
            }
          }
    },
    // eslint-disable-next-line max-len
    serverLogUrl: 'https://prod-171.westeurope.logic.azure.com:443/workflows/c229b8b9f3034627aa8202b965953c8a/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=QQoW_2DzX-SGcSnAnXg1lVB3ajcZsREKW7WpSEEq8Xo', // URL to Azure function logging to slack
    firebaseConfig: {
        apiKey: 'AIzaSyABplwRUo3MFzpgOhm82rUnTpBOasIzgHU',
        authDomain: 'tempusin17.firebaseapp.com',
        databaseURL: 'https://tempusin17.firebaseio.com',
        projectId: 'tempusin17',
        storageBucket: 'tempusin17.appspot.com',
        messagingSenderId: '753040640898',
        appId: '1:753040640898:web:417681d03b6a46b48d1d9b',
        measurementId: 'G-4LYKV75C1Y'
    },
    webVapidKey: 'BOSq8oJS6zPvM7vOj5gBDuqOqtnWySkXWmR3vzrLhgIHnca5YtzPuzbNrxO2BA7xPulaEqsxIFr6mgcOApj4BPs',
    requiredMessage: 'This field is required',
    appStoreLink: 'https://itunes.apple.com/us/app/tempus-in/id1083882869',
    googlePlayLink: 'https://play.google.com/store/apps/details?id=no.tempusas.in',
    largeLabelPosition: 'stacked',  // labelPosition used when label and/or value is large, must be 'floating' or 'stacked',
    defaultBoxOrder: [
        {id: 'forms', order: 0, show: true},
        {id: 'lastused', order: 1, show: true},
        {id: 'history', order: 2, show: false},
        {id: 'favorite', order: 3, show: true, formIds: []},
        {id: 'formview', order: 4, show: true, formIds: [], height: 300},
        {id: 'emptybox1', order: 5, show: false, height: 100},
        {id: 'emptybox2', order: 6, show: false, height: 100}
    ],
    fullHeightFV: 100000
};

