<div class="calendar-container" >
  <div class="calError" *ngIf="false" (click)="toggleErrorDetails()">
    <ion-icon name="alert-circle-outline" color="warning"></ion-icon>
    <div class="calError--details" *ngIf="errorInfo.showDetails" >
      <p>{{errorInfo.title}}</p>
      <p>{{errorInfo.text}}</p>
    </div>
  </div>
  <div class="calendar-header" *ngIf="!settings.hideHeader" >
    <ion-button *ngIf="!settings.hideMonthsArrows" class="left" fill="clear" (click)="changeMonth(-1)">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-button>
    <ion-button *ngIf="!settings.hideMonthsArrows" class="right" fill="clear" (click)="changeMonth(1)">
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </ion-button>
    
    <h1>
      <!-- TODO: should look like months menu?.. -->
      <ion-select *ngIf="eventTypeListOption.isList" [compareWith]="compareWith" interface="popover"  [(ngModel)]="eventTypeSelected" [multiple]="eventTypeListOption.multiSelect" (ionChange)="updateEventType()">
        <ion-select-option *ngFor="let type of eventTypeList" [value]="type">{{type.label}}</ion-select-option>
      </ion-select>

      <span *ngIf="!eventTypeListOption.isList">{{eventTypeSelectedLabel}}</span>
    </h1>
    <span class="months__date" [ngClass]="{'months--bold': displayMonthSelection}" (click)="toggleMonthView()">{{monthName(calendar.periode.month, false) | titlecase}}, {{calendar.periode.year}} <span class="spinner" *ngIf="loading.month"><ion-spinner></ion-spinner></span></span>
  </div>
  
  <div #monthContainer class="months" [ngClass]="{'months__shadow': displayMonthSelection}">
    <div *ngFor="let item of months; let i = index" class="month--name" (click)="changeMonth((i+1) - calendar.periode.month); toggleMonthView()" >
      <div [ngClass]="{'month--selected': item.selected, 'month--hasData': item.days > 0}">
        <span >{{item.name}}</span>
<!--        <span class="data">({{item.days}})</span>  -->
      </div>
    </div>
  </div>

  <div class="calendar" #calendarContainer>
    <div class="day--name" *ngFor="let item of weekdayNames; let i = index">{{weekdayNames[i]}}</div>

    <div *ngFor="let item of days; let i = index" class="day" (click)="selectEvent($event, 'day', item, i)" [ngClass]="{'day--disabled': !item.isCurrentMonth, 'day--today': item.isToday, 'day--holiday': item.isHoliday, 'day--selected': selectMode.mode === 'active' && i === ((selectedDayInView ? daySelected.from?.index : false) ?? daySelected.to?.index), 'day--highlight': item.isHighlighted}">
      <span >{{item.dayOfMonth}} <span class="monthName" *ngIf="item.dayOfMonth === 1">{{monthName(item.month, true)}}</span></span>
      <span class="week" *ngIf="item.dayOfWeek === 1">{{item.week}}</span>
      
    </div>
<!--
    <div _ngcontent-btk-c333="" ng-reflect-ng-style="[object Object]" style="grid-area: 6 / 5 / auto / span 1;z-index: 3;display: grid;/* display: table-row; */margin-top: 30px;"><section _ngcontent-btk-c333="" ng-reflect-ng-style="[object Object]" style="/* grid-area: 2 / 1 / auto / span 3; *//* z-index: 13; */background: aquamarine;display: inline-grid;">Row 1 col 1<section _ngcontent-btk-c333="" ng-reflect-ng-style="[object Object]" style="/* grid-area: 2 / 1 / auto / span 3; *//* z-index: 13; */background: aquamarine;display: inline-grid;">Row 1 col 1</section></section><section _ngcontent-btk-c333="" ng-reflect-ng-style="[object Object]" style="/* grid-area: 2 / 2 / auto / span 1; */background: antiquewhite;display: inline-block;">Row 1 col 2<section _ngcontent-btk-c333="" ng-reflect-ng-style="[object Object]" style="/* grid-area: 2 / 2 / auto / span 1; */background: antiquewhite;display: inline-block;">Row 1 col 2</section></section></div>
  -->
    <section *ngFor="let item of events; let i = index" (click)="selectEvent($event, 'event', item, i)" [ngStyle]="{'grid-column': item.col + ' / span ' + item.span, 'grid-row': item.row}" class="event event--default event--{{item.type}} event--{{item.placement}}" [ngClass]="{'event--selected': item.selected, 'event--highlight': item.highlight}">
      <span class="event--label">
        {{item.label}}
      </span>
      <div class="event__detail " (click)="openSubform('edit', item)" [ngClass]="{'event__detail-hide': !item.selected, 'event__detail-top': item.offset.top,'event__detail-left': item.offset.left}">
        <h2>{{item.label}}</h2>
        <p>{{eventInfo[item.id].periodeWeek}}<br/>{{eventInfo[item.id].periodeDate}}</p>
        <p *ngIf="eventInfo[item.id].data" class="event__detail__info">{{eventInfo[item.id].data}}</p>
        <ion-icon *ngIf="eventInfo[item.id].hasSubform" name="create-outline"></ion-icon>

      </div>

    </section>

    <section #calendarPeriodeMenu class="event event--default event--selected event--highlight calendar-menu" [ngClass]="{'event__detail-hide': !calendarMenu.show || selectMode.mode !== 'done'}" [ngStyle]="{'grid-column': calendarMenu.col + ' / span ' + 1, 'grid-row': calendarMenu.row}">
      <div class="event__detail" (click)="openSubform('new', calendarMenu)" [ngClass]="{'event__detail-top': calendarMenu.offset.top,'event__detail-left': calendarMenu.offset.left}">
        <h2 class="event--label">{{'selectedPeriode' | translate}}</h2>
        <p>{{calendarMenu.data.weeks}}</p>
        <p>{{calendarMenu.data.dates}}</p>
        <ion-icon *ngIf="hasSubformNew" name="create-outline"></ion-icon>
      </div>
    </section>

    <div class="calendar-overlay" (click)="toggleMonthView(false)" [ngClass]="{'calendar-overlay-show': loading.items || displayMonthSelection}"></div>
  </div>
  
</div>

