import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { NGXLogger } from 'ngx-logger';
import { DomOperationsService } from 'src/app/services/dom-operations.service';

@Component({
  selector: 'app-wrapper-focus-input',
  templateUrl: './focus-input.component.html',
  styleUrls: ['./focus-input.component.scss'],
})
export class FocusInputComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  constructor(
    private el: ElementRef,
    private dom: DomOperationsService,
    private logger: NGXLogger
  ) {
    super();
  }

  ngOnInit() {
    const elm: Element = this.el.nativeElement;
    const formlyField: Element = this.dom.findFormlyFieldElement(elm);
    formlyField.addEventListener('click', () => {
      const input: HTMLIonInputElement = formlyField.getElementsByTagName('ion-input')[0];
      if (input) {
        input.setFocus();
      }
      else {
        this.logger.debug('No input element found');
      }
    });
  }
}
