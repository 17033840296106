<ion-header>
  <ion-toolbar color="header">
    <ion-buttons slot="start">
      <ion-button class="print-btn" fill="clear" slot="start" (click)="print()" *ngIf="showPrint">
        <ion-icon slot="icon-only" name="print-outline"></ion-icon>
      </ion-button>
      <ion-button class="copy-btn" fill="clear" slot="start" (click)="copyToClipboard(textToCopy)" *ngIf="allowCopy">
        <ion-icon slot="icon-only" name="copy-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">{{title}}</ion-title>
    <ion-button class="close-btn" fill="clear" slot="end" (click)="close()">
      <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="in-background">
  <ion-card>
    <ion-item lines="none">
      <markdown #markdownEl [class.overflowing]="isOverflowing" [data]="text" *ngIf="text && hasOpened" (ready)="checkOverflowing()" (scroll)="hideScrollArrow()"></markdown>
      <ion-icon class="overflow-indicator aniHoverX" name="chevron-forward-sharp" *ngIf="showOverflowArrow"></ion-icon>
    </ion-item>
  </ion-card>
</ion-content>