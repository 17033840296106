import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  passwordForm = this.formBuilder.group({
    currentPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(32)]],
    newPasswordRepeat: ['', Validators.required]
  }, {
    validator: this.validation.mustMatch('newPassword', 'newPasswordRepeat')
  });

  constructor(
    private popCtrl: PopoverController,
    private formBuilder: UntypedFormBuilder,
    private validation: ValidationService,
    private logger: NGXLogger
  ) { }

  get currentPassword() {
    return this.passwordForm.get('currentPassword');
  }

  get newPassword() {
    return this.passwordForm.get('newPassword');
  }

  get newPasswordRepeat() {
    return this.passwordForm.get('newPasswordRepeat');
  }

  changePassword() {
    this.dismiss({
      change: this.passwordForm.value
    });
  }

  close() {
    this.dismiss();
  }

  private dismiss(data?: {change: string}) {
    this.popCtrl.dismiss(data).then(
      () => this.logger.debug('Successfully dismissed change password popover'),
      (err) => this.logger.warn('Failed dismissing change password popover', err)
    );
  }

}
