import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { NGXLogger } from 'ngx-logger';
import { FormType, Trigger, TriggerType } from 'src/app/models/models';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { SwiperService } from 'src/app/services/swiper.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wrapper-gesture',
  templateUrl: './gesture-wrapper.component.html',
  styleUrls: ['./gesture-wrapper.component.scss']
})
export class GestureWrapperComponent extends FieldWrapper implements OnInit, Trigger {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;
  hasGesture = false;
  isActive = false;
  gestureType = null;

  constructor(
    private ref: ComponentRefService,
    private logger: NGXLogger,
    private swiperService: SwiperService,
    private util: UtilityService
  ) {
    super();
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }


  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    const gesture = this.field.templateOptions.gesture;
    if (gesture && gesture.type) {
      this.gestureType = gesture.type;
      this.hasGesture = true;

    }
  }

  async externalTrigger(type: TriggerType, data?: any): Promise<void> {
    if (type === TriggerType.Swipe) {
      this.isActive = true;

      const fieldValue = this.util.dotRef(this.field.formControl.value, this.to.gesture.jsonPath);
      const val = this.swiperService.swipeDate(data.event, data.data, fieldValue, this.field);
      if (val) {
        await this.ref.callReference(this.key as string, this.formId, this.formType, TriggerType.Set, val);
      }
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  setReference(key: string, id: number, type: FormType): void {
    this.ref.addReference(key, id, type, this);
  }
}
