import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, HostListener } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { TimeService } from 'src/app/services/time.service';
import * as moment from 'moment';
import { FieldService } from 'src/app/services/field.service';
import { AutosaveService } from 'src/app/services/autosave.service';
import { FormType, Trigger, TriggerType } from 'src/app/models/models';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { FormService } from 'src/app/services/form.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-wrapper-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
  providers: [FieldService, WatchgroupService]
})
export class InputTimeComponent extends FieldWrapper implements OnInit, OnDestroy, Trigger {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;
  timeoutId: any;
  intervalId: any;
  shouldUpdateTime = true;
  correctTime: string;
  resumeSub: Subscription;

  constructor(
    private timeService: TimeService,
    private fieldService: FieldService,
    private autosave: AutosaveService,
    private plt: Platform,
    private formService: FormService,
    private ref: ComponentRefService,
    private logger: NGXLogger,
    private watch: WatchgroupService,
    private state: StateService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get formId(): number {
    return this.to.currentForm.id ?? 0;
  }

  get projectId(): number {
    return this.to.currentForm.projectId ?? 0;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  get notAutosave(): boolean {
    if (this.to.currentForm?.type === FormType.Form) {
      return this.to.noAutosave ?? false;
    }
    else {
      return this.to.noAutosave ?? true;
    }
  }

  get isEmpty(): boolean {
    if (this.field.className?.includes('hide')) {
      return true;
    }
    else {
      return !this.model[this.key as string | number];
    }
  }

  get hasSteps(): boolean {
    return this.formService.hasSteps;
  }

  get watchGroup(): string[] {
    if (Array.isArray(this.to.watchGroup)) {
      if (this.timeService.isJustDate(this.field)) {
        return this.to.watchGroup.filter(key => {
          const field = this.state.findField(key, this.formType, this.formId);
          return this.timeService.isJustDate(field);
        });
      }
      else if (this.timeService.isDatetime(this.field, false)) {
        return this.to.watchGroup.filter(key => {
          const field = this.state.findField(key, this.formType, this.formId);
          return this.timeService.isDatetime(field, false);
        });
      }
      else if (this.timeService.isTime(this.field)) {
        return this.to.watchGroup.filter(key => {
          const field = this.state.findField(key, this.formType, this.formId);
          return this.timeService.isTime(field);
        });
      }
      else if (this.timeService.isMonth(this.field)) {
        return this.to.watchGroup.filter(key => {
          const field = this.state.findField(key, this.formType, this.formId);
          return this.timeService.isMonth(field);
        });
      }
      else {
        return [];
      }
    }
    else {
      return [];
    }
  }

  get daysFromNow(): number {
    return this.parseProperty('daysFromNow');
  }

  get weeksFromNow(): number {
    return this.parseProperty('weeksFromNow');
  }

  get monthsFromNow(): number {
    return this.parseProperty('monthsFromNow');
  }

  get yearsFromNow(): number {
    return this.parseProperty('yearsFromNow');
  }

  /**
   * Stop updating time when user does input
   */
  @HostListener('ionInput')
  inputEvent() {
    this.stopUpdating();
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    if (!this.timeService.isTimeDateOrMonth(this.field)) {
      this.shouldUpdateTime = false;
      return;
    }
    if (this.to.notInit) {
      this.shouldUpdateTime = false;
    }
    else if (!this.to.noUpdate) {
      this.resumeSub = this.plt.resume.subscribe(() => {
        if (this.shouldUpdateTime) {
          this.setCurrentTime(this.hasSteps);
          this.startLiveUpdate();
        }
      });
    }
    if (this.isEmpty && this.shouldUpdateTime) {
      if (!this.hasSteps) {
        this.autosave.addKey(this.formId, this.projectId, this.key as string);
      }
      this.setCurrentTime(this.hasSteps);

      if (!this.to.noUpdate) {
        this.startLiveUpdate();
      }
      else {
        this.autosave.removeKey(this.formId, this.projectId, this.key as string);
        this.shouldUpdateTime = false;
      }
    }
    if (this.watchGroup.length > 0) {
      this.watch.watchGroup(this.form, this.model, this.watchGroup).subscribe(val => {
        if (val?.newValue) {
          let value = val.newValue;
          this.stopUpdating();
          if (this.daysFromNow && this.timeService.isDateOrMonth(this.field)) {
            const format = this.timeService.getTimeType(this.field);
            value = this.timeService.formatDatetime(value, format, false, this.daysFromNow, 'days');
          }
          else if (this.monthsFromNow && this.timeService.isDateOrMonth(this.field)) {
            const format = this.timeService.getTimeType(this.field);
            value = this.timeService.formatDatetime(value, format, false, this.monthsFromNow, 'months');
          }
          else if (this.yearsFromNow && this.timeService.isDateOrMonth(this.field)) {
            const format = this.timeService.getTimeType(this.field);
            value = this.timeService.formatDatetime(value, format, false, this.yearsFromNow, 'years');
          }
          else if (this.weeksFromNow && this.timeService.isDateOrMonth(this.field)) {
            const format = this.timeService.getTimeType(this.field);
            value = this.timeService.formatDatetime(value, format, false, this.weeksFromNow, 'weeks');
          }
          this.fieldService.setValue(value);
        }
      });
    }
  }

  ngOnDestroy() {
    this.clearIntervals();
    this.resumeSub?.unsubscribe();
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.StopUpdate) {
      this.stopUpdating();
    }
    else if (type === TriggerType.Set) {
      this.stopUpdating();
      this.fieldService.setValue(data);
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  stopUpdating() {
    if (this.shouldUpdateTime) {
      this.shouldUpdateTime = false;
      this.clearIntervals();
      if (!this.notAutosave) {
        this.autosave.removeKey(this.formId, this.projectId, this.key as string);
      }
    }
  }

  clearIntervals() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  setCurrentTime(emitEvent: boolean) {
    let date: moment.Moment;
    if (this.daysFromNow) {
      date = moment().add(this.daysFromNow, 'days');
    }
    else if (this.monthsFromNow) {
      date = moment().add(this.monthsFromNow, 'months');
    }
    else if (this.yearsFromNow) {
      date = moment().add(this.yearsFromNow, 'years');
    }
    else if (this.weeksFromNow) {
      date = moment().add(this.weeksFromNow, 'weeks');
    }
    else {
      date = moment();
    }

    const value = this.timeService.formatDatetime(date, this.to.type);
    this.fieldService.setValue(value, emitEvent, emitEvent);
    this.to.initValue = value;
  }

  startLiveUpdate() {
    this.clearIntervals();
    this.intervalId = setInterval(() => {
      if (this.shouldUpdateTime) {
        this.setCurrentTime(this.hasSteps);
      }
      else {
        this.clearIntervals();
      }
    }, 1000);
  }

  private parseProperty(property: string) {
    if (typeof this.to[property] === 'number') {
      return this.to[property] ;
    }
    else if (typeof this.to[property]  === 'string') {
      const days = parseInt(this.to[property] );
      return isNaN(days) ? null : days;
    }
    else {
      return 0;
    }
  }
}
