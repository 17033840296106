import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { FormType, Trigger, TriggerType } from 'src/app/models/models';

@Component({
  selector: 'app-wrapper-toggle-class',
  templateUrl: './toggle-class.component.html',
  styleUrls: ['./toggle-class.component.scss'],
})
export class ToggleClassComponent extends FieldWrapper implements OnInit, Trigger {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;
  constructor(
    private ref: ComponentRefService
  ) {
    super();
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    const delay = (data && data.delay) || 0;
    if (data) {
      setTimeout(() => {
        this.setClasses(type, data.class);
      }, delay);
    }
  }


  private setClasses(type: TriggerType, toggleClass: string) {
    if (type === TriggerType.ToggleClass) {
      let classes = this.field.className;
      if (!classes) {
        classes = toggleClass;
      }
      else if (classes.includes(toggleClass)) {
        classes = classes.replace(toggleClass, '').trim();
      }
      else {
        classes += ` ${toggleClass}`;
      }
      this.field.className = classes;
    }
    else if (type === TriggerType.AddClass) {
      let classes = this.field.className;
      if (!classes) {
        classes = toggleClass;
        this.field.className = classes;
      }
      else if (!classes.includes(toggleClass)) {
        classes += ` ${toggleClass}`;
        this.field.className = classes;
      }
    }
    else if (type === TriggerType.RemoveClass) {
      let classes = this.field.className;
      if (classes.includes(toggleClass)) {
        classes = classes.replace(toggleClass, '').trim();
        this.field.className = classes;
      }
    }
  }
}
