import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ColorPopoverComponent } from './color-popover/color-popover.component';
import { ColorCircleModule } from 'ngx-color/circle';
import { PdfModalComponent } from './pdf-modal/pdf-modal.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { ShapesPopoverComponent } from './shapes-popover/shapes-popover.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextPopoverComponent } from './text-popover/text-popover.component';
import { PaintSelectComponent } from './paint-select/paint-select.component';
import { PhotoModalComponent } from './photo-modal/photo-modal.component';
import { LoggerModule } from 'ngx-logger';
import { SelectPictureComponent } from './select-picture/select-picture.component';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { FormlyModule } from '@ngx-formly/core';
import { SubFormComponent } from './sub-form/sub-form.component';
import { SwiperModule } from 'swiper/angular';


@NgModule({
  declarations: [
    ColorPopoverComponent,
    PdfModalComponent,
    ImageModalComponent,
    ShapesPopoverComponent,
    TextPopoverComponent,
    PaintSelectComponent,
    PhotoModalComponent,
    SelectPictureComponent,
    SubFormComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ColorCircleModule,
    NgxExtendedPdfViewerModule,
    LoggerModule.forChild(),
    FormlyIonicModule,
    FormlyModule.forChild(),
    SwiperModule
  ],
  exports: [
    ColorPopoverComponent,
    PdfModalComponent,
    ImageModalComponent,
    ShapesPopoverComponent,
    TextPopoverComponent,
    PaintSelectComponent,
    PhotoModalComponent,
    SelectPictureComponent,
    SubFormComponent
  ]
})
export class FormlyDirectivesModule { }
