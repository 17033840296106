import { Injectable } from '@angular/core';
import { FieldType, FieldWrapper } from '@ngx-formly/core';
import { InStorageService } from './in-storage.service';
import { ApiService } from './api.service';
import { UrlInit, InitStatus, FormType, InitMapping } from '../models/models';
import { TimeService } from './time.service';
import { mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UtilityService } from './utility.service';

/**
 * Service for setting, clearing and getting values from an field in an form
 *
 * **Important:** Must be included in providers in the field component
 */
@Injectable()
export class FieldService {
  private field: FieldType | FieldWrapper;

  constructor(
    private api: ApiService,
    private storage: InStorageService,
    private timeService: TimeService,
    private util: UtilityService
  ) {}

  /**
   * Set reference to field/wrapper
   * @param field The field to reference
   */
  setField(field: FieldType | FieldWrapper) {
    this.field = field;
  }

  get isRegistration(): boolean {
    return this.field?.to?.currentForm?.type === FormType.Registration;
  }

  get isDirty(): boolean {
    return this.field.formControl.dirty;
  }

  triggerChange() {
    if (this.field?.field.templateOptions) {
      this.field.field.templateOptions = {...this.field.field.templateOptions};
    }
  }

  /**
   * Set an value to the field and optional mark as dirty
   * @param value The value
   * @param markAsDirty (Optional) If the control should be marked as dirty, default: true
   * @param emitEvent (Optional) *Only if markAsDirty=false*: If the event should be emitted, default: false
   */
  setValue(value: any, markAsDirty: boolean = true, emitEvent: boolean = false): Promise<void> {
    return new Promise<void>(resolve => {
      this.field.model[this.field.key as string | number] = value;
      if (markAsDirty) {
        setTimeout(() => {
          this.field.formControl.markAsDirty();
          this.field.formControl.setValue(value, {emitEvent: true});
          resolve();
        });
      }
      else {
        setTimeout(() => {
          this.field.formControl.setValue(value, {emitEvent: emitEvent});
          resolve();
        });
      }
    });
  }

  /**
   * Clear the value of the field and mark as pristine
   * @param emptyValue (Optiona) The value to set it to when it clears, default: ''
   */
  clearValue(emptyValue: any = ''): Promise<void> {
    return new Promise<void>(resolve => {
      this.field.model[this.field.key as string | number] = emptyValue;
      setTimeout(() => {
        this.field.formControl.markAsPristine();
        this.field.formControl.setValue(emptyValue);
        resolve();
      });
    });
  }

  /**
   * Get the value of the field
   */
  getValue(): any {
    return this.field.formControl.value;
  }

  /**
   * Init field with data from storage. Returns true if it changed something
   * @param url The url the data was stored on
   */
  async initFieldFromStorage(url: string): Promise<InitStatus> {
    if (!url) {
      return {changed: false, visible: true};
    }
    const data = await this.storage.getWithKey(url);
    const status = await this.setUrlData(data);
    return status;
  }

  /**
   * Init field with data from url. Returns true if it changed something
   * @param url The url to init from
   */
  initFieldFromApi(url: string, formId: number, mapping: InitMapping = {}, headers?: any, useCredentials?: boolean): Observable<InitStatus> {
    if (!url) {
      return of({changed: false, visible: true});
    }
    const oldVisible = typeof this.field.field.hide === 'boolean' ? !this.field.field.hide : null;
    return this.api.getWebJSON(url, formId, headers, useCredentials, false).pipe(
      mergeMap(async data => {
        const initData = this.mapInitData(data.value, mapping);
        if (initData) {
          this.storage.setWithKey(url, initData);
        }
        const status = await this.setUrlData(initData);
        if (oldVisible === status.visible) {
          status.changed = false;
        }
        return status;
      })
    );
  }

  /**
   * Set field init url data to the field. Returns true if it changed the field
   * @param data The data to set
   */
  private setUrlData(data: UrlInit): Promise<InitStatus> {
    const status: InitStatus = {
      changed: false,
      visible: true
    };

    if (!data) return Promise.resolve(status);

    if (data.ServerSearch) {
      status.serverSearch = data.ServerSearch;
    }

    if (data.DefaultValue) {
      const type = this.timeService.getTimeType(this.field.field);
      if (type) {
        data.DefaultValue = this.timeService.formatDatetime(data.DefaultValue, type);
      }
    }

    if (typeof data.DefaultValue !== 'undefined' && !this.isRegistration && !this.isDirty) {
      if (this.getValue() !== data.DefaultValue) {
        status.changed = true;
      }
      this.setValue(data.DefaultValue, false);
      this.field.to.initValue = data.DefaultValue;
    }
    if (data.DefaultName) {
      this.field.to.defaultInitValueName = data.DefaultName;
    }
    if (data.Label) {
      if (this.field.to.required && !this.field.to.hideRequiredMarker && !data.Label.endsWith('*')) {
        data.Label += '*';
      }
      if (this.field.to.label !== data.Label) {
        status.changed = true;
      }
      this.field.to.label = data.Label;
    }
    if (typeof data.Visible === 'boolean') {
      if (this.field.field.hide !== !data.Visible) {
        status.changed = true;
      }
      status.visible = data.Visible;
      if (!data.Visible || this.field.field.hide) {
        return new Promise<InitStatus>(resolve => {
          setTimeout(() => {
            this.field.field.hide = !(data as UrlInit).Visible;
            resolve(status);
          });
        });
      }
    }
    return Promise.resolve(status);
  }

  private mapInitData(data: any, mapping: InitMapping): UrlInit {
    data = this.util.dotRef(data, mapping.path ?? '');
    if (Array.isArray(data)) {
      data = data[0];
    }
    if (!data) {
      return null;
    }
    const initData: UrlInit = {};
    const value = this.util.dotRef(data, mapping.valueProp || 'DefaultValue');
    if (value !== null) {
      initData.DefaultValue = value;
    }
    const name = this.util.dotRef(data, mapping.defaultNameProp || 'DefaultName');
    if (name !== null) {
      initData.DefaultName = name;
    }
    const label = this.util.dotRef(data, mapping.labelProp || 'Label');
    if (label !== null) {
      initData.Label = label;
    }
    const visible = this.util.dotRef(data, mapping.visibleProp || 'Visible');
    if (visible !== null) {
      initData.Visible = visible;
    }
    const serverSearch = this.util.dotRef(data, mapping.serverSearchProp || 'Server');
    if (typeof serverSearch === 'boolean') {
      initData.ServerSearch = serverSearch;
    }
    else if (typeof serverSearch === 'number') {
      initData.ServerSearch = serverSearch === 1;
    }
    return initData;
  }
}
