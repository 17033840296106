import { Injectable } from '@angular/core';
import { isTrigger } from '../helpers/helperFunctions';
import { TriggerType, FormType, FormlyPage, Trigger, PageRefs } from '../models/models';

interface CallData {
  type: TriggerType;
  data?: any;
}

interface ComponentReference {
  [key: string]: any[];
}

interface ComponentCalls {
  [key: string]: CallData[];
}

/**Used for getting and setting references to components */
@Injectable({
  providedIn: 'root'
})
export class ComponentRefService {
  references: ComponentReference = {};
  componentCalls: ComponentCalls = {};

  /**
   * Add a reference to instance of an component. Will check for calls that happened before creation
   * @param key Key of component
   * @param formId The id of the form
   * @param formType The type of form the component is in
   * @param component The component to add
   */
  addReference(key: string, formId: number, formType: FormType, component: any) {
    const newKey = this.getRefKey(key, formId, formType);
    if (!this.references[newKey]) {
      this.references[newKey] = [component];
    }
    else {
      const index = this.getReferenceIndex(key, formId, formType, component);
      if (index === -1) {
        this.references[newKey].push(component);
      }
      else {
        this.references[newKey][index] = component;
      }
    }
    if (isTrigger(component)) {
      const calls = this.componentCalls[newKey];
      if (calls) {
        for (const callData of calls) {
          setTimeout(() => {
            component.externalTrigger(callData.type, callData.data);
          });
        }
        setTimeout(() => {
          delete this.componentCalls[newKey];
        }, 100);
      }
    }
  }

  /**
   * Get reference to instance of an component
   * @param key Key of component
   * @param formId The id of the form
   * @param formType The type of form the component is in
   */
  getReference(key: string, formId: number, formType: FormType): any[] {
    key = this.getRefKey(key, formId, formType);
    return this.references[key] ?? [];
  }

  /**
   * Check whether an given form is opened as an Form or FormView
   * @param formId The id of the form
   * @param formType The type of form
   * @returns If the given form is opened as the speficied form type
   */
  formIsOpen(formId: number, formType: FormType.Form | FormType.FormView) {
    return this.getOpenForms(formType).includes(formId);
  }


  /**
   * Removes an component from references
   * @param key The key to the component
   * @param formId The id of the form
   * @param formType The type of form the component is in
   */
  removeReference(key: string, formId: number, formType: FormType) {
    key = this.getRefKey(key, formId, formType);
    if (this.references[key]) {
      delete this.references[key];
    }
  }
  /**
   * Clear all references and calls
   * @param formId The id of the form
   * @param formType The type of form the component is in
   */
  clearReferences(formId: number, formType: FormType) {
    const newRefs = {};
    const newCalls = {};
    for (const [key, value] of Object.entries(this.references)) {
      if (!key.endsWith(`${formId}:${formType}`)) {
        newRefs[key] = value;
      }
    }
    for (const [key, value] of Object.entries(this.componentCalls)) {
      if (!key.endsWith(`${formId}:${formType}`)) {
        newCalls[key] = value;
      }
    }
    this.references = newRefs;
    this.componentCalls = newCalls;
  }

  /**
   * Calls externalTrigger of reference if it's initialized, else it stores the call for when it becomes initialized
   * @param key Key of the component
   * @param formId The id of the form
   * @param formType The type of form the component is in
   * @param type The trigger type
   * @param data (Optional) Data to pass to externalTrigger
   * @param page (Optional) Add reference to Form/FormView if all fields should be loaded first incase the triggered field isn't loaded
   * @param storeCall (Optional) If the call should be stored if no reference is found, default: `true`
   */
  async callReference(key: string, formId: number, formType: FormType, type: TriggerType, data?: any, page?: FormlyPage, storeCall = true): Promise<any[]> {
    const newKey = this.getRefKey(key, formId, formType);
    const refs: Trigger[] = this.getReference(key, formId, formType);
    if (refs.length === 0 && storeCall) {
      if ((formType === FormType.Form || formType === FormType.FormView) && page && !page.loadedAllFields) {
        page.loadFieldsUntilKey(key);
      }
      const callData: CallData = {type, data};
      if (!this.componentCalls[newKey]) {
        this.componentCalls[newKey] = [callData];
      }
      else {
        this.componentCalls[newKey].push(callData);
      }
      return [];
    }
    else {
      const result = [];
      for (const ref of refs) {
        if (isTrigger(ref)) {
          const res = await ref.externalTrigger(type, data);
          if (res) {
            result.push(res);
          }
        }
      }
      return result;
    }
  }

  /**
   * Create the key for saving the reference
   * @param key The key of field/page
   * @param formId The id of the form, if falsy or negative values is given it will not be used
   * @param formType The form type
   * @returns The reference key
   */
  private getRefKey(key: string, formId: number, formType: FormType) {
    return formId > 0 ? `${key}:${formId}:${formType}` : `${key}:${formType}`;
  }

  /**
   * Check if the service already has an reference to the component
   * @param key Key of component
   * @param formId The id of the form
   * @param formType The type of form the component is in
   * @param component The component
   */
  private hasReference(key: string, formId: number, formType: FormType, component: any) {
    const refs = this.getReference(key, formId, formType);
    for (const ref of refs) {
      if (isTrigger(ref) && ref.constructor === component.constructor) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check if the service already has an reference to the component
   * @param key Key of component
   * @param formId The id of the form
   * @param formType The type of form the component is in
   * @param component The component
   */
  private getReferenceIndex(key: string, formId: number, formType: FormType, component: any) {
    if (this.hasReference(key, formId, formType, component)) {
      const refs = this.getReference(key, formId, formType);
      for (let i = 0; i < refs.length; i++) {
        if (isTrigger(refs[i]) && refs[i].constructor === component.constructor) {
          return i;
        }
      }
      return -1;
    }
    else {
      return -1;
    }
  }

  /**
   * Get all the open forms filtered by form type
   * @param formType The form type
   * @returns An array of ids of the open forms
   */
  private getOpenForms(formType: FormType): number[] {
    if (formType === FormType.Form || formType === FormType.FormView) {
      const pageRef = formType === FormType.Form ? PageRefs.Form : PageRefs.FormView;
      return Object.keys(this.references).filter(k => k.startsWith(`${pageRef}:`)).map(k => parseInt(k.split(':')[2])).filter(k => !isNaN(k));
    }
    else {
      return [];
    }
  }
}
