import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OnlineStatusComponent } from '../online-status/online-status.component';
import { OnlineService } from 'src/app/services/online.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { PageMenuItem } from 'src/app/models/models';
import { assertPageMenu } from 'src/app/helpers/helperFunctions';
import { of, Subscription } from 'rxjs';


@Component({
  selector: 'app-page-menu',
  templateUrl: './page-menu.component.html',
  styleUrls: ['./page-menu.component.scss']
})
export class PageMenuComponent implements OnInit, OnDestroy {
  @ViewChild('online') onlineComp: OnlineStatusComponent;

  @Input() title: any;
  @Input() showOnline = true;

  onlineSub: Subscription;
  onlineText = 'Online';
  showOnlineButton = false;
  private _items: PageMenuItem[];

  constructor(
    private popoverCtrl: PopoverController,
    private onlineService: OnlineService,
    private logger: NGXLogger
  ) { }

  @Input()
  set items(items: PageMenuItem[]) {
    for (const item of items) {
      if (typeof item.disabled === 'undefined') {
        item.disabled = of(false);
      }
      else if (typeof item.disabled === 'boolean') {
        item.disabled = of(item.disabled);
      }
      if (typeof item.name === 'function') {
        item.name = item.name();
      }
      if (typeof item.active === 'function') {
        item.active = item.active();
      }
    }
    this._items = items;
  }

  get items(): PageMenuItem[] {
    return this._items;
  }

  ngOnInit() {
    this.onlineSub = this.onlineService.online.subscribe(status => this.updateOnlineStatus(status));
    try {
      assertPageMenu(this.items);
    }
    catch (err) {
      this.items = [];
      this.logger.error('Menu Items is wrongly formatted', err);
      this.dismiss();
    }
  }

  ngOnDestroy() {
    this.onlineSub?.unsubscribe();
  }

  itemClick(index: number) {
    this.logger.debug(this.items[index]);
    this.dismiss(this.items[index]);
  }

  toggleOnline() {
    this.onlineComp.toggleStatus(false, false);
  }

  private dismiss(item?: PageMenuItem) {
    this.popoverCtrl.dismiss(item).then(
      () => this.logger.debug('Successfully dismissed page menu'),
      (err) => this.logger.warn('Failed dismissing page menu', err)
    );
  }

  private updateOnlineStatus(status: boolean) {
    this.onlineText = status ? 'Online' : 'Offline';
    this.showOnlineButton = !this.showOnline ? false : !environment.production || !status;
  }

}
