<ion-header>
  <ion-toolbar>
    
    <ion-title>
      {{'EnterText' | translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="in-background">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-item id="text-item">
          <ion-label position="fixed">{{'Text' | translate}}</ion-label>
          <ion-input type="text" [(ngModel)]="text"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-item id="size-item">
          <ion-label position="fixed">{{'Size' | translate}}</ion-label>
          <ion-range min="5" max="100" step="5" [(ngModel)]="size"></ion-range>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="3" offset="2">
        <ion-button id="cancel-text" expand="block" color="primary" fill="clear" (click)="closePopover(false)">{{'Cancel' | translate}}</ion-button>
      </ion-col>
      <ion-col size="2"></ion-col>
      <ion-col size="3">
        <ion-button id="save-text" expand="block" color="primary" fill="clear" (click)="closePopover(true)">{{'Confirm' | translate}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>