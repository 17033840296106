import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { firstValueFrom, Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { Fields, SourceType } from 'src/app/models/models';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-wrapper-set-label',
  templateUrl: './set-label-wrapper.component.html',
  styleUrls: ['./set-label-wrapper.component.scss'],
})
export class SetLabelWrapperComponent extends FieldWrapper implements AfterViewInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  constructor(private util: UtilityService) {
    super();
  }

  get sourceType(): SourceType {
    if (this.field.type === Fields.WebLookup) {
      return SourceType.API;
    }
    else if (this.field.type === Fields.Lookup) {
      return SourceType.Lookup;
    }
    else if (this.field.type === Fields.ServerQueryModal) {
      return SourceType.API;
    }
    else if (this.field.type === Fields.Modalselect) {
      if (this.to.lookupName) {
        return SourceType.Lookup;
      }
      else if (this.to.lookupUrl) {
        return SourceType.API;
      }
      else {
        return SourceType.Local;
      }
    }
    else {
      return SourceType.Local;
    }
  }

  get labelProp(): string {
    return this.sourceType === SourceType.Local ? 'label' : this.to.labelProp || 'name';
  }

  get valueProp(): string {
    return this.sourceType === SourceType.Local ? 'value' : this.to.valueProp || 'number';
  }

  get delimiter(): string {
    return this.to.delimiter ?? ';';
  }

  ngAfterViewInit() {
    if (this.to.setLabel) {
      this.findAndSetLabel(this.to.options, this.formControl.value);
      this.formControl.valueChanges.subscribe((val) => {
        this.findAndSetLabel(this.to.options, val);
      });
    }
  }



  async findAndSetLabel(options: any[] | Observable<any[]>, val: any) {
    if (options instanceof Observable) {
      try {
        options = await firstValueFrom(options, {defaultValue: []});
      }
      catch {
        return;
      }
    }
    if (!Array.isArray(options) || options.length === 0 || typeof val === 'undefined' || val === null) {
      return;
    }
    let label: string;
    if (Array.isArray(val)) {
      label = val.map<string>(v => {
        if (typeof v === 'object') {
          return this.util.dotRef(v, this.labelProp);
        }
        else {
          return this.getLabel(options as any[], v);
        }
      }).join(this.delimiter);
    }
    else if (typeof val === 'object') {
      label = this.util.dotRef(val, this.labelProp);
    }
    else {
      label = this.getLabel(options, val);
    }
    const labelObj = {};
    labelObj[this.to.setLabel] = label;
    (this.formControl.parent as UntypedFormGroup).patchValue(labelObj);
  }

  /**
   * Get the corresponding label in the options from the value
   * @param options The options to check
   * @param val The value
   */
  getLabel(options: any[], val: any) {
    const value = options.find(o => this.util.dotRef(o, this.valueProp) === val);
    return this.util.dotRef(value, this.labelProp, '');
  }
}
