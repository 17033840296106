/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { ApiType, Trigger, Fields, PageMenuItem, PageMenuType, StorageValue, FormWithValues, Form, ModelMetaData, FormlyFieldWithTranslation } from '../models/models';
import { environment } from 'src/environments/environment';
import { FormlyFieldConfig } from '@ngx-formly/core';

/**
 * Gives the required message when an field that is required is not filled out
 */
export function requiredValidationMessage() {
    return environment.requiredMessage;
  }

/**
 * Checks if an object implements interface Trigger
 * @param object The object to check
 */
export function isTrigger(object: any): object is Trigger {
    const trigger = object as Trigger;
    if (!object) {
        return false;
    }
    else {
        return trigger.externalTrigger !== undefined && trigger.setReference !== undefined;
    }
}

/**
 * Check if the ApiType is an 'external' API, e.g. Extenral, ExternalApir, ExternalAzure
 * @param apiType The type to check
 */
export function isExternalApi(apiType: ApiType) {
    return apiType === ApiType.ExternalAzure || apiType === ApiType.ExternalInApir || apiType === ApiType.External;
}

/**
 * Convert an ApiType to one of the base types: Internal, External, InApir, InAzure
 * @param apiType The type to convert
 */
export function getBaseApiType(apiType: ApiType) {
    switch (apiType) {
        case ApiType.ExternalAzure:
            return ApiType.InAzure;
        case ApiType.ExternalInApir:
            return ApiType.InApir;
        default:
            return apiType;
    }
}

/**
 * Check if an string is in the Fields enum
 * @param fieldType The field type to check
 * @param includeOld (Optional) Include old fields ('labelselect' and 'labelradio'), default: false
 */
export function isValidField(fieldType: string, includeOld = false): fieldType is Fields {
    if (!includeOld && (fieldType === Fields.LabelSelect || fieldType === Fields.LabelRadio)) {
        return false;
    }
    else {
        return Object.values(Fields).includes(fieldType as Fields);
    }
}

/**
 * Check if an object is StorageValue
 * @param object The object to check
 */
export function isStorageValue(object: any): object is StorageValue {
    if (!object) {
        return false;
    }
    else if (typeof object.value !== 'undefined' && object.timestamp instanceof Date) {
        return true;
    }
    else {
        return false;
    }
}

/**
 * Asserts that all the items in an menu is correct
 * @param menuItems The page menu to check
 */
export function assertPageMenu(menuItems: PageMenuItem[]): asserts menuItems is PageMenuItem[] {
    if (!menuItems) {
        throw new TypeError('PageMenu is null');
    }
    for (const item of menuItems) {
        assertPageMenuItem(item);
    }
}

/**
 * Assert that an Page Menu Item is correct
 * @param item The item to check
 */
export function assertPageMenuItem(item: PageMenuItem): asserts item is PageMenuItem {
    if (item.type === PageMenuType.Button) {
        if (!item.style) {
            throw new TypeError(`PageMenuItem '${item.name}' of type Button must have style`);
        }
        if (!item.callback) {
            throw new TypeError(`PageMenuItem '${item.name}' of type Button must have callback`);
        }
        if (typeof item.active !== 'boolean' && (typeof item.active !== 'function' || typeof item.active() !== 'boolean')) {
            throw new TypeError(`PageMenuItem '${item.name}' of type Button must have active`);
        }
    }
}

/**
 * Checks if an object is of type Form
 * @param object The object to check
 */
export function isForm(object: Form): object is Form {
    if (!object || typeof object.id !== 'number' || typeof object.number !== 'string' || typeof object.name !== 'string'
        || typeof object.definition !== 'string' || typeof object.iconClass !== 'string' || typeof object.isActive !== 'boolean'
    ) {
        return false;
    }
    else {
        return true;
    }
}

/**
 * Checks if an object is of type FormWithValues
 * @param object The object to check
 */
export function isFormWithValues(object: FormWithValues): object is FormWithValues {
    if (!object || !Array.isArray(object.values) || !isForm(object.theForm)) {
        return false;
    }
    else {
        return true;
    }
}

export function shouldKeepMetaData(key: string | ModelMetaData) {
    if (key.includes(ModelMetaData.ModalSearch)) {
        return true;
    }
    else {
        return false;
    }
}

/**
 * Checks if an field contains translation information
 * @param field The field to check
 */
export function isFormlyFieldWithTranslation(field: FormlyFieldConfig): field is FormlyFieldWithTranslation {
    if (!field?.templateOptions?.fieldTranslations) {
        return false;
    }
    else if (Object.keys(field.templateOptions.fieldTranslations).length > 0) {
         return true;
    }
    else {
        return false;
    }
}

