import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { FieldService } from 'src/app/services/field.service';
import { filter } from 'rxjs/operators';
import { Project, SortBy, DataStatus, Trigger, TriggerType, FormType } from 'src/app/models/models';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-field-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [FieldService]
})
export class ProjectsComponent extends FieldType implements OnInit, Trigger {
  projects: Project[] = [];
  loadingCurrent = true;
  loadingAll = true;
  label: string;
  interface: string;
  interfaceOptions: any;
  constructor(
    private translate: TranslateService,
    private cachedApi: CachedApiService,
    private fieldService: FieldService,
    private ref: ComponentRefService,
    private logger: NGXLogger
  ) {
    super();
    this.fieldService.setField(this);
  }

  get sortByProp(): SortBy {
    switch (this.to.sortBy) {
      case SortBy.Value:
      case SortBy.Number:
        return SortBy.Number;
      case SortBy.NoSort:
        return SortBy.NoSort;
      default:
        return SortBy.Name;
    }
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  public static getHtml(config: FormlyFieldConfig, value: any) {
    const label = config.templateOptions.label ?? '';
    const options = config.templateOptions.options as Project[];
    if (options?.length > 0) {
      const opt = options.find(o => o.number === value);
      value = opt?.name || value;
    }
    return `<ion-card><ion-item lines="none">
              <ion-label>${label}</ion-label>
              <span style="text-align: right">${value}</span>
            </ion-item></ion-card>`;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    this.setLabelAndInterface();
    this.cachedApi.getCurrentProject().pipe(filter(data => data.status === DataStatus.Updated))
    .subscribe(({value: project}) => {
      if (project) {
        this.fieldService.setValue(project.number);
        this.to.initValue = project.number;
      }
      this.loadingCurrent = false;
    });
    this.cachedApi.getProjects(this.sortByProp).pipe(filter(data => data.status === DataStatus.Updated))
    .subscribe(data => {
      this.projects = data.value;
      this.loadingAll = false;
    });
  }

  setLabelAndInterface() {
    const label = this.to.label || this.translate.instant('Project');
    this.label = this.to.required ? `${label} *` : label;

    this.interface = this.to.interface ?? 'popover';

    this.interfaceOptions = (this.interface === 'popover' && this.label) ? {header: this.label} : {};
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.GetOptions) {
      return this.projects;
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }
}
