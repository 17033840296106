import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { NFC } from '@ionic-native/nfc/ngx';
import { NGXLogger } from 'ngx-logger';
import { FieldService } from 'src/app/services/field.service';
import { InLoggerService } from 'src/app/services/in-logger.service';
import { IonColor } from 'src/app/models/models';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-field-nfc',
  templateUrl: './nfc.component.html',
  styleUrls: ['./nfc.component.scss'],
  providers: [FieldService]
})
export class NfcComponent extends FieldType implements OnInit {
  active = false;
  orgPlaceholder = '';
  disableButton = false;
  error = false;

  constructor(
    private device: DeviceService,
    private translate: TranslateService,
    private nfc: NFC,
    private logger: NGXLogger,
    private fieldService: FieldService,
    private inLogger: InLoggerService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }

  get alwaysOn(): boolean {
    return this.to.alwaysOn ? true : false;
  }

  get decimal(): boolean {
    return this.to.decimal ? true : false;
  }

  get reverse(): boolean {
    return this.to.reverse ? true : false;
  }

  get minLength(): number {
    return this.to.minLength ? this.to.minLength : 0;
  }

  get color(): string {
    return this.error ? IonColor.Danger : this.active ? IonColor.Success : IonColor.Dark;
  }

  get disableInput(): boolean {
    return this.to.disableInput ?? true;
  }

  public static getHtml(config: FormlyFieldConfig, value: string) {
    const label = config.templateOptions.label ?? '';
    const borderColor = getComputedStyle(document.documentElement).getPropertyValue(`--ion-color-step-300`);
    return `<ion-card><ion-list>
              <ion-item lines="none">
                <ion-label class="ion-text-wrap">${label}</ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-button color="primary" slot="start" fill="clear" disabled="true">
                  <ion-icon slot="icon-only" name="pulse"></ion-icon>
                </ion-button>
                <ion-input value="${value}" type="text" style="border-bottom: 1px solid ${borderColor}"></ion-input>
              </ion-item>
            </ion-list></ion-card>`;
  }

  ngOnInit() {
    if (this.to.placeholder) {
      this.orgPlaceholder = this.to.placeholder;
    }

    if (!this.device.isAndroid()) {
      setTimeout(() => {
        this.formControl.disable({onlySelf: true});
        this.to.placeholder = this.translate.instant('NfcNotAndroid');
        this.to.disabled = true;
        this.disableButton = true;
      });
    }
    else if (this.to.disabled) {
      this.disableButton = true;
    }
    else {
      if (this.alwaysOn) {
        this.active = true;
        this.to.placeholder = this.translate.instant('NfcListening');
      }
      if (this.disableInput) {
        this.formControl.disable();
      }

      this.addListener();
    }
  }

  changeMode(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.alwaysOn) {
      this.active = !this.active;
      if (this.active) {
        this.to.placeholder = this.translate.instant('NfcListening');
      }
      else {
        this.to.placeholder = this.orgPlaceholder;
      }
    }
  }

  addListener() {
    this.nfc.addTagDiscoveredListener(
      () => this.logger.debug('Listening for nfc tags'),
      (err: any) => {
        this.logger.error('Error starting nfc listener', err);
        this.error = true;
        this.formControl.disable({onlySelf: true});
        if (err === 'NFC_DISABLED') {
          this.to.placeholder = this.translate.instant('NfcDisabled');
        }
        else {
          this.to.placeholder = this.translate.instant('NfcError');
        }
      }
    ).subscribe(event => {
      if (event) {
        this.logger.debug('Got NFC event', event);
        if (this.active) {
          this.logger.debug('NFC is active');
          let id = this.nfc.bytesToHexString(event.tag.id);
          if (this.reverse) {
            id = id.match(/[a-fA-F0-9]{2}/g).reverse().join('');
          }
          if (this.decimal) {
            if (typeof BigInt === 'undefined') {
              const nr = parseInt(id, 16);
              if (nr > Number.MAX_SAFE_INTEGER) {
                this.logger.warn('Too large number in NFC, using it anyway since BigInt is undefined');
                this.inLogger.error('Too large number for NFC', null, {number: nr, tagId: id, key: this.key});
              }
              id = nr.toString();
            }
            else {
              id = BigInt(`0x${id}`).toString();
            }
            while (id.length < this.minLength) {
              id = '0' + id;
            }
          }

          this.fieldService.setValue(id);
          if (!this.alwaysOn) {
            this.active = false;
          }
        }
      }
    });
  }
}
