import { Component, OnInit, ElementRef, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { TriggerType, Trigger, FormType } from 'src/app/models/models';
import { NGXLogger } from 'ngx-logger';
import { EvaluationService } from 'src/app/services/evaluation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-text-expand',
  templateUrl: './text-expand.component.html',
  styleUrls: ['./text-expand.component.scss'],
})
export class TextExpandComponent extends FieldWrapper implements OnInit, AfterViewInit, Trigger {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  constructor(
    private elm: ElementRef,
    private ref: ComponentRefService,
    private dom: DomOperationsService,
    private logger: NGXLogger,
    private evaluation: EvaluationService
  ) {
    super();
  }

  get autoExpand(): boolean {
    return this.to.autoExpand ?? true;
  }

  get isHidden(): boolean {
    if (this.field.hideExpression) {
      return this.evaluation.evalHideExpression(this.field.hideExpression, this.field.hide, this.model, this.formState);
    }
    else {
      return false;
    }
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
  }

  ngAfterViewInit() {
    setTimeout(() => {
        this.setAutoExpand();
    }, 1000);
  }

  async externalTrigger(type: TriggerType, data?: any): Promise<void> {
    if (type === TriggerType.Focus) {
      const textArea: HTMLIonTextareaElement = this.elm.nativeElement.querySelector('ion-textarea');
      if (!textArea) {
        return;
      }
      this.dom.scrollIntoViewAndExecute(textArea, () => textArea.setFocus());
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  setReference(key: string, id: number, type: FormType): void {
    this.ref.addReference(key, id, type, this);
  }

  private setAutoExpand() {
    if (this.autoExpand) {
      if (this.isHidden) {
        const sub = this.field.parent.formControl.valueChanges.subscribe(() => {
          if (!this.isHidden) {
            sub.unsubscribe();
            const textArea: HTMLIonTextareaElement = this.elm.nativeElement.querySelector('ion-textarea');
            if (textArea) {
              textArea.autoGrow = true;
            }
            else {
              this.logger.debug('No textarea found');
            }
          }
        });
      }
      else {
        const textArea: HTMLIonTextareaElement = this.elm.nativeElement.querySelector('ion-textarea');
        if (textArea) {
          textArea.autoGrow = true;
        }
        else {
          this.logger.debug('No textarea found');
        }
      }
    }
  }

}
