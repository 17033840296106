export enum BoxType {
    FormList = 'formlist',
    RegList = 'reglist',
    FormView = 'formview',
    Empty = 'empty'
}

export enum BoxStyle {
    List = 'list',
    Form = 'form',
    Empty = 'empty'
}
