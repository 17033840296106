import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { DataSource, DataStatus, Form, FormType, Gesture, GestureEvent, PageRefs, Trigger, TriggerType } from 'src/app/models/models';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { FormService } from 'src/app/services/form.service';
import { InStorageService } from 'src/app/services/in-storage.service';
import { SwiperService } from 'src/app/services/swiper.service';

@Component({
  selector: 'app-sub-form',
  templateUrl: './sub-form.component.html',
  styleUrls: ['./sub-form.component.scss'],
  providers: [FormService]
})
export class SubFormComponent implements OnInit, AfterViewInit, Trigger {

  @Input() values: any;
  @Input() formId: number;
  @Input() showSaveBtn = true;
  @Input() showClearBtn = true;

  isLoading = true;
  formFound = false;
  formNotFound = false;
  formData: any = null;
  untouchedForm: Form;
  form: Form;
  fields: FormlyFieldConfig[];
  activeFields: FormlyFieldConfig[];
  formGroup = new UntypedFormGroup({});
  options: FormlyFormOptions = {};
  model = {};
  formName: string;

  constructor(
    private modalCtrl: ModalController,
    private logger: NGXLogger,
    private formService: FormService,
    private translate: TranslateService,
    private ref: ComponentRefService,
    private storage: InStorageService,
    private swipeService: SwiperService
  ) {
    this.formService.init(this, FormType.SubForm);
  }

  externalTrigger(type: TriggerType, data?: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  get saveBtnLabel(): string {
    return 'Save'; // this.translate.instant('Save');
  }
  get formNameTitle(): string {
    return this.formName || ''; //this.translate.instant('SubForm');
  }

  ngOnInit() {
    this.logger.debug(this.values);
    this.formData = this.values;
  }
  ngAfterViewInit() {
    this.getForm(this.formId);

  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  setVars(vars: any) {
    this.formService.setVars(vars);
  }

  closeModal(sendData?: boolean) {
    this.logger.debug(this.model);
    if(sendData) {
      const reqFields = this.formService.findRequiredFields(this.fields, this.model, true);
    //  console.log(reqFields);
      if(reqFields.length > 0)
        return;
    }
    const tabs = (sendData) ? this.model: null;
    this.modalCtrl.dismiss(tabs);
  }

  async getForm(id: number) {
    this.logger.debug('Getting form #' + id);
    this.setReference(PageRefs.SubForm, id, FormType.SubForm);
    this.isLoading = true;
    this.formFound = false;
    this.formNotFound = false;
    const project = await this.storage.getCurrentProject();
    this.formService.getCachedFormAndInit(id, project, this.formData).subscribe(data => {
      this.logger.debug(data);
      if (data.source === DataSource.API) {
        if (data.status === DataStatus.Error) {
          //this.emitFormNotFound(id, true);
          this.formFound = false;
          this.formNotFound = true;
        }
        else if (data.status === DataStatus.Updated) {
    //      this.emitFormFound(data.value);
          this.formFound = true;
          this.formName = data.value.theForm.name;
        }
        else if (!data.value && !this.formFound) {
  //        this.emitFormNotFound();
          this.formFound = false;
          this.formNotFound = true;
        }
      }
      else if (data.value) {
//        this.emitFormFound(data.value);
        this.formFound = true;
        this.formName = data.value.theForm.name;
      }
    });
  }

  clearForm() {
    this.formService.clearForm();
  }

  async submit() {
    const data = await this.formService.createReg(this.model, this.fields, true);
    const reg = await this.formService.doReg(data.reg, false);
    if(reg.success && data.goBack)
      this.closeModal(true);

  }

  handleSwipe(event: any) {
    if (event.target instanceof HTMLCanvasElement) {
      return;
    }
    this.logger.debug('handleSwipe');

    if(this.swipeService.shouldClosePage(event)){
      this.closeModal(false);
    }
    else if (this.formService.gestureSwipefield.value && typeof this.formService.gestureSwipefield.value !== 'string') {
      const key = this.formService.gestureSwipefield.value.key;
      const gesture: Gesture = this.formService.gestureSwipefield.value.templateOptions.gesture;
      const data: GestureEvent = {event: event, data: gesture.data};
      this.ref.callReference(key as string, this.formId, FormType.SubForm, gesture.type, data);
    }
  }


}
