import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { FieldService } from 'src/app/services/field.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-wrapper-subselect',
  templateUrl: './subselect-wrapper.component.html',
  styleUrls: ['./subselect-wrapper.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class SubselectWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;
  orgOptions: any[];

  constructor(
    private watch: WatchgroupService,
    private fieldService: FieldService,
    private util: UtilityService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get parent(): string {
    return this.to.parent || '';
  }

  ngOnInit() {
    this.orgOptions = this.util.createCopyOfObject(this.to.options) as any[];
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length >0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(vc => {
        if (vc && vc.oldValue && vc.oldValue !== vc.newValue && this.model[this.key as string | number]) {
          this.fieldService.setValue('');
        }
        this.runFilter();
      });
    }
    this.runFilter();
  }

  runFilter() {
    const parentVal = this.model[this.parent];
    if (!parentVal) {
      return;
    }
    this.to.options = this.orgOptions.filter(opt => opt.parent === parentVal);
  }
}
