import { Injectable } from '@angular/core';
import { IonColor } from '../models/models';
import { UntypedFormGroup } from '@angular/forms';
/**
 * - **validEmail**: Checks if an email is an valid email address
 * - **validColor**: Checks if an color class is valid
 * - **invisibleColor**: Checks if an color will cause an button to be 'invisible'
 * - **mustMatch**: Custom validator for checking that password and confirm password are equal
 */
@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  /**
   * Checks if an email is an valid email address
   * @param email The email to check
   */
  validEmail(email: string): boolean {
    // eslint-disable-next-line max-len
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  }

  /**
   * Checks if an color is an valid color class in Ionic
   * @param color The color to check
   */
  validColor(color: string | IonColor): color is IonColor {
    if (!color) {
      return false;
    }
    else {
      return Object.values(IonColor).includes(color as IonColor);
    }
  }

  /**
   * Checks if an color will cause an button to be "invisible"
   * @param color The color to check
   * @param fill The fill of the button
   */
  invisibleColor(color: string | IonColor, fill: string): boolean {
    return color === IonColor.Light && (fill === 'outline' || fill === 'clear');
  }

  /**
   * Custom validator for checking that password and confirm password are equal
   * @param controlName Name of password field
   * @param matchingControlName Name of repeat password field
   */
  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      }
      else {
        matchingControl.setErrors(null);
      }
    };
  }
}
