/**
 * Reference keys to be used for the different Page components when used with ComponentRef service and Trigger
 */
export enum PageRefs {
    Form = 'IN:Form',
    FormView = 'IN:FormView',
    SubForm = 'IN:SubForm',
    Setup = 'IN:Setup',
    Home = 'IN:Home',
    Forms = 'IN:Forms',
    History = 'IN:History'
}
