  <ion-item lines="none">
    <div>
    <ion-label position="stacked" class="ion-text-wrap ion-padding-bottom">
      {{label}}
    </ion-label>
    </div>
    <br/>
    <div>
      <ng-container *ngFor="let el of elements">
        <ng-container *ngTemplateOutlet="element; context: el"></ng-container>
      </ng-container>
    </div>

  </ion-item>

  <ng-template #element let-el="index">


        <ion-icon slot="icon-only" [name]="convertedOffIcon"
          class="offIcon clickBox"
          [size]="iconSize" 
          fill="clear"
          [color]="convertedOffColor"
          *ngIf="!value || value < el+1" 
          (click)="ratingsClicked(el+1)"
        ></ion-icon>

        <ion-icon slot="icon-only" [name]="convertedOnIcon"
          class="onIcon clickBox" 
          [size]="iconSize" 
          fill="clear" 
          [color]="convertedOnColor" 
          *ngIf="value > el" 
          (click)="ratingsUnClicked(el+1)" 
        ></ion-icon>

  </ng-template>