import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NGXLogger } from 'ngx-logger';
import { FormType, PageRefs, Trigger, TriggerType } from 'src/app/models/models';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { FieldService } from 'src/app/services/field.service';
import { TimeService } from 'src/app/services/time.service';
import { UtilityService } from 'src/app/services/utility.service';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import * as moment from 'moment';
import { TimeAgoPipe } from 'src/app/pipes/time-ago.pipe';
import { WatchOptions } from 'src/app/models/interfaces/watchOptions';

interface DateOption {
  key: string;
  periode?: string;
  add?: {duration: number, unit: moment.unitOfTime.DurationConstructor};
  format?: string;
  preFormat?: string;
}

@Component({
  selector: 'app-date-helper',
  templateUrl: './date-helper.component.html',
  styleUrls: ['./date-helper.component.scss'],
  providers: [WatchgroupService, FieldService, TimeAgoPipe]
})
export class DateHelperComponent extends FieldType implements OnInit, Trigger {

  currentDate = new Date();
  lastDate = '';

  dateFormat = 'YYYY-MM-DD';
  format = {
    date: this.dateFormat,
    day1900: 'day1900',
    day: {date: this.dateFormat, displayDate: 'DD.MM.YY', nameOfDay: 'dddd', dayOfWeek: 'E', dayOfMonth: 'D', dayOfYear: 'DDD'},
    week: {no: 'W', weekYear: 'WWYYYY'},
    month: {no: 'M', nameOfMonth: 'MMMM', nameOfMonthShort: 'MMM'},
    year: {no: 'YYYY'},
    quarter: {no: 'Q'}
  };


  mergeValues = ''; //no json , just the formatet value.
  dateKeys: DateOption[];
  template: string;
  displayTemplate: string;
  labelSize: 'small' | 'mid' | 'lagre' ;

  showDate = false;
  showDatepicker = true;
  hasSwipeGesture = false;
  isDisabled = false;
  allowDatepick = false;
  btnNextToLabel = false;

  constructor(
    private fieldService: FieldService,
    private util: UtilityService,
    private timeService: TimeService,
    private watch: WatchgroupService,
    private ref: ComponentRefService,
    private logger: NGXLogger
  ) {
    super();
    this.fieldService.setField(this);
  }

  get formId(): number {
    return this.to?.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  get pageRef(): PageRefs {
    return this.to?.currentForm?.ref;
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Set) {
      if(moment(data).isValid())
        this.buildAndSetResult(data);
    }
  }
  setReference(key: string, id: number, type: FormType): void {
    this.ref.addReference(key, id, type, this);
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);

    this.isDisabled = this.to.disabled || false;
    this.hasSwipeGesture = (!this.isDisabled && this.to.gesture?.type === 'swipe');
    this.template = this.to.label || '';
    this.dateKeys = this.to.dateKeys || [];
    this.mergeValues = this.to.mergeValues || '';
    this.labelSize = this.to.labelSize || 'mid';
    this.btnNextToLabel = this.to.btnNextToLabel || false;
    this.showDate = this.to.showDate || false;
    this.showDatepicker = (!this.isDisabled && this.to.showDatepicker) || false;
    this.dateFormat = this.to.dateFormat || 'YYYY-MM-DD';

    if (this.model[this.key as string]) {
      const model = this.model[this.key as string];
      this.lastDate = (model._currentDate && moment(model._currentDate).isValid()) ? model._currentDate: (model && moment(model).isValid()) ? model : '';
      this.buildAndSetResult(moment(this.lastDate).toDate());
      this.parseTemplate();
    }

    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if(this.to.autotrigger && !this.isDisabled && this.lastDate === '') {
      this.buildAndSetResult(this.currentDate);
    }
    //console.log(watchGroup);
    this.initWatch(watchGroup);

  }

  initWatch(watchGroup: WatchOptions) {

    if(watchGroup.fieldKeys.length > 0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe((v) => {
        this.logger.debug('WatchGroup trigger!');
        this.logger.debug(v);
        if (v === null) return;
        const newDate = moment(v.newValue);
        if(newDate.isValid()) {
          this.buildAndSetResult(newDate.toDate());
        }
      });
    }

  }

  parseTemplate() {
    setTimeout(() => {
      if(this.template)
        this.displayTemplate = this.util.parseText(this.template,this.fieldService.getValue());
    }, 0);
  }

  buildAndSetResult(date: Date | number) {
    if(this.isDisabled)
      return;

    if(typeof date === 'number')
      date = this.timeService.convertFromDay1900(date);

    const res = {};
    this.lastDate = moment(date).format(this.dateFormat);
    res['_currentDate'] = this.lastDate;

    for(const o of this.dateKeys) {
      let val: any;

      if(o.add)
        date = this.timeService.createDate(date, o.add.duration, o.add.unit);

      const d = moment(date);

      if(o.periode) {
        val = {};
        const format = o.format ?? this.dateFormat;
        const period = o.periode as 'week' | 'year' | 'month' | 'work-week';
        val['start'] = moment(this.timeService.getStartOrEndOfPeriod(d.toDate(), period, 'start')).format(format);
        val['end'] = moment(this.timeService.getStartOrEndOfPeriod(d.toDate(), period, 'end')).format(format);
      }
      else {
        const format = (o.preFormat) ? this.util.dotRef(this.format, o.preFormat): (o.format) ? o.format: this.dateFormat;
        val = (format === this.format.day1900) ? this.timeService.convertToDay1900(d.toDate()) : d.format(format);
      }
      res[o.key] = val;

    }

    let temp = '';
    if(this.mergeValues) {
      Object.entries(res).forEach(([key, value]) => {
        temp += (temp) ? this.mergeValues + value: value;
      });
    }

    const data = (this.mergeValues ?? false) ? temp : res;
    this.fieldService.setValue(data);

    this.parseTemplate();

  }

  setDate(data) {
    const val = data.target.value; // data is an $event
    this.buildAndSetResult(val);
  }

}
