import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FieldType } from '@ngx-formly/core';
import { SubFormComponent } from 'src/app/custom-components/directives/formly-directives/sub-form/sub-form.component';
import { DataSource, DataStatus, FormType, PageRefs, SwipeDirection, Trigger, TriggerType } from 'src/app/models/models';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { FieldService } from 'src/app/services/field.service';
import { FormService } from 'src/app/services/form.service';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import Chart from 'chart.js/auto';
import { UtilityService } from 'src/app/services/utility.service';
import * as moment from 'moment';
import { TimeService } from 'src/app/services/time.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

/**
 * Set as this to be enable to mock event source
 */
export const saldiChart = {
  chart: Chart
};
@Component({
  selector: 'app-d4-saldi',
  templateUrl: './d4-saldi.component.html',
  styleUrls: ['./d4-saldi.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class D4SaldiComponent extends FieldType implements OnInit, Trigger {
  @ViewChild('grafCanvas') private grafCanvas: ElementRef;


  colSize = 6;
  chartObj: any;
  showChart = false;

  isLoading = {
    vacation: true,
    flexTime: true,
    graf: true
  };

  boxList = {
    saldi: { show: false, url: '', formId: '' },
    vacation: { show: false, url: '', formId: '' },
    flexitime: { show: false, url: '', formId: '' },
    edit: { show: false, url: '', formId: '' }
  };
  graf = {
    show: false, url: '', title: '', labels: []
  };

  saldiTime = { name: '', title: '', value: 'Åpne skjema', valType: '' };
  flexiTime = { name: '', title: '', value: '', weekValue: 0, valType: '' };
  vacationTime = { name: '', title: '', value: '', valType: '' };

  currPeriode = { fromDate: '', toDate: '' };
  hideSaldi = false;
  lookupDelay = 0;
  displayChart = true;
  localDate = new Date();

  calcRestOfFirst = true;

  callReferenceTriggered;
  swipeTriggered = null;

  loadingTimer = null;
  chartUpdated = null;
  chartIsUpdating = false;

  constructor(
    private cachedApi: CachedApiService,
    private formService: FormService,
    private modalCtrl: ModalController,
    private fieldService: FieldService,
    private util: UtilityService,
    private timeService: TimeService,
    private watch: WatchgroupService,
    private ref: ComponentRefService,
    private logger: NGXLogger,
    private translate: TranslateService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get showFlexiBox(): boolean {
    return this.boxList?.flexitime?.show || false;
  }
  get flexiWeekValue(): string {
    const hasPlus = (this.flexiTime.weekValue>0) ? '+' : '';
    return hasPlus + this.flexiTime.weekValue;
  }

  get showVacationBox(): boolean {
    return this.boxList?.vacation?.show || false;
  }
  get showSaldiBox(): boolean {
    return this.boxList?.saldi?.show || false;
  }
  get showEditBox(): boolean {
    return this.boxList?.edit?.show || false;
  }
  get isDisabled(): boolean {
    return this.hideSaldi || false;
  }

  get displayBoxline(): boolean {
    return (this.showVacationBox || this.showFlexiBox || this.showSaldiBox);
  }

  get vacHasFormId(): boolean {
    return (this.boxList?.vacation?.formId !== '');
  }
  get flexHasFormId(): boolean {
    return (this.boxList?.flexitime?.formId !== '');
  }
  get saldiHasFormId(): boolean {
    return (this.boxList?.saldi?.formId !== '');
  }
  get editHasFormId(): boolean {
    return (this.boxList?.edit?.formId !== '');
  }

  get formId(): number {
    return this.to?.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  get pageRef(): PageRefs {
    return this.to?.currentForm?.ref;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    this.hideSaldi = this.to.disabled ?? false;
    //this.logger.debug('hideSaldi: ', this.hideSaldi);
    if (this.hideSaldi)
      return;

    this.boxList = this.to?.boxlist || this.boxList;
    this.graf = this.to?.graf || this.graf;
    if (typeof this.graf?.show === 'string') // inline field returns string instead of boolean.
      this.graf.show = !(this.graf?.show === 'false');
    this.displayChart = (this.graf?.url && (!!this.graf?.show ?? true));
    //this.logger.debug('DisplayChart: ', this.displayChart);
    this.lookupDelay = this.to?.lookupDelay || this.lookupDelay;

    this.updateColSize();
    this.localDate = new Date();
    //    this.prepPeriode(new Date());

    if (this.to?.graf?.calcRestOfFirst === false)
      this.calcRestOfFirst = this.to.graf.calcRestOfFirst;

    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    // console.log('watchGroup', watchGroup);
    if (watchGroup.fieldKeys.length >0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe((v) => {
        // console.log('saldi watch',v);
        if (v === null) return;
        //this.logger.debug('WatchGroup trigger!');
        //this.logger.debug(v);
        if (this.callReferenceTriggered && moment(new Date()).diff(this.callReferenceTriggered, 'seconds') <= 1) {
          //this.logger.debug('too close between callRef and watchTrigger', moment(new Date()).diff(this.callReferenceTriggered, 'seconds'));
          this.callReferenceTriggered = null;
          return;
        }
        if(moment(v.newValue).isValid) {
          this.triggerLoading(); //show loading while on delay.. Something is about to happen.
          setTimeout(() => {
            this.localDate = moment(v.newValue).toDate();
            this.updateData();
          }, this.lookupDelay);
        }
      });
    }

    setTimeout(() => {
      if (this.displayChart)
        this.buildChart([], [], [], [], [], [], [], []); // just show an empty graf/template while loading.
      this.updateData();
    }, 0);
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }


  clearActiveElements() {
    if(!this.chartObj)
      return;
//    console.log('clearActive!!');
    this.chartObj.setActiveElements([]);
    this.chartObj.tooltip._active = [];
    this.chartObj.tooltip.update();

//    console.log(this.chartObj);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    //console.log('external trigger', type, data);
    if (type === TriggerType.Update) {
      this.triggerLoading(); //show lading while on delay.. Something is about to happen.
      setTimeout(() => {
        this.updateData();
      }, this.lookupDelay);
    }
    else if (type === TriggerType.Set) {
      if(moment(data).isValid())
        this.localDate = moment(data).toDate();
      this.updateData();
    }
    else if (type === TriggerType.ShowLoading) {
      this.triggerLoading(data);
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  // removes annoying loadingspinner on short updates.
  triggerLoading(show = true, obj: any = ['flextime', 'vaction', 'graf'], startdelay: number = 2000) {
//console.log('loading', show, obj);
    if(!show) {
      startdelay = 0;

    clearTimeout(this.loadingTimer);
    this.loadingTimer = setTimeout(() => {
      obj.forEach(key => {
        this.isLoading[key] = show;
      });
      this.fieldService.triggerChange();
    }, startdelay);

  }
}

  updateColSize() {
    const c = 12;
    let i = 0;
    if (this.boxList?.saldi?.show)
      i++;
    if (this.boxList?.vacation?.show)
      i++;
    if (this.boxList?.flexitime?.show)
      i++;


    this.colSize = Math.floor(c / i);

  }

  updateData() {
    this.prepPeriode(this.localDate);
    if (this.displayChart)
      this.getGrafData();
    if(this.displayBoxline)
    this.getBoxData();
  }

  handleSwipe(event: any) {
   // console.log('===========SWIPE===========');
    //console.log(event);
    this.swipeTriggered = new Date();

    const direction = (event.direction === SwipeDirection.LeftToRight) ? -1 : 1;
    this.localDate = this.timeService.createDate(this.localDate, 1 * direction, 'weeks');
    if (this.to?.externalUpdate) {
      const d = moment(this.localDate).format('YYYY-MM-DD');
      this.ref.callReference(this.to.externalUpdate, this.formId, this.formType, TriggerType.Set, d);
      this.callReferenceTriggered = new Date();
    }
    this.updateData();
  }


  prepPeriode(date: Date) {

    this.currPeriode.fromDate = moment(this.timeService.getStartOrEndOfPeriod(date, 'week', 'start')).format('YYYY-MM-DD');
    this.currPeriode.toDate = moment(this.timeService.getStartOrEndOfPeriod(date, 'week', 'end')).format('YYYY-MM-DD');

  }

  getBoxData() {
    if(!this.displayBoxline)
      return;

    if (this.to?.url && this.to.url !== '') {
      this.triggerLoading(true, ['flexTime', 'vacation']);
      const obs = this.cachedApi.getWebJSON(this.to.url, '', this.formService.formId, this.to.headers, this.to.useCredentials);
      obs.subscribe(({ value, status, source }) => {
        if (source === DataSource.API) {
          this.triggerLoading(false, ['flexTime', 'vacation']);
        }
        if (status === DataStatus.Updated && Array.isArray(value)) {
          for (const k of value) {
            if (k.Type === 'Konto') {
              this.flexiTime.name = k.Navn;
              this.flexiTime.value = k.Saldo;
              this.flexiTime.valType = k.Enhet;
            }
            else if (k.Type === 'Ferie') {
              this.vacationTime.name = k.Navn;
              this.vacationTime.value = k.Saldo;
              this.vacationTime.valType = k.Enhet;
            }
          }
        }
      });

    }
  }

  getGrafData() {
    ////this.logger.debug('=====Get chartData');

    if (this.graf?.url !== '') {
      ////this.logger.debug('=====Get chartData has url');
      //      this.prepPeriode(new Date());
      this.triggerLoading(true, ['graf']);
      const url = this.util.parseText(this.graf.url, this.currPeriode);
      const useCustomLabels =  !!(this.to.graf?.labels && this.to.graf.labels.length > 0);
      let showWeekend = false;
      const defaultLabels =[
        'Ma',
        'Ti',
        'On',
        'To',
        'Fr',
        'Lø',
        'Sø'
      ];

      const labels = (useCustomLabels) ? this.to.graf.labels : defaultLabels;

      const types = [
        'Konto',
        'Plan',
        'BetFrv',
        'UbetFrv',
        'Arb',
        'Dagtype'
      ];

      const obs = this.cachedApi.getWebJSON(url, '', this.formService.formId, this.to.headers, this.to.useCredentials);
      obs.subscribe(({ value, status, source }) => {
        if (source === DataSource.API) {
          setTimeout(() => { // small delay. Takes time to build (extra false later in build incase its faster).
//            this.isLoading.graf = false;
            this.triggerLoading(false, ['graf']);
          }, 1000);
        }
        if (status === DataStatus.Updated && Array.isArray(value)) {
          const list = {};
          const work = {};
          for(const t of types) {
            list[t] = {};
            work[t] = [];
          }
          for (const v of value) {
            list[v.Nr] = v;
          }
          for (const d of defaultLabels) {
            for (const t of types) {
              let time = (list[t][d] !== 0) ? list[t][d] :
                          (t!==types[0] && t!==types[1]) ? null : 0; //hide empty/zero bars. Keep Konto & Plan as is.
              if(!useCustomLabels && ( d === defaultLabels[5] || d === defaultLabels[6] ) && time > 0)
                showWeekend = true;
              if(t === types[5])
                time = (list[t][d] === 8) ? 1: 0; // holiday - true/false.

              work[t].push(time);
            }

          }

          const lab = [];
          const labV = [];
          // calc time for every day, but only display selected ones. Flextime fix, in case user works outside selected weekdays.
          for(const d of labels) {
            const i = defaultLabels.indexOf(d);
            lab.push(moment(this.currPeriode.fromDate).add(i, 'days').format('DD.MM'));
            labV.push(moment(this.currPeriode.fromDate).add(i, 'days').format('YYYY-MM-DD'));
          }

          if(!useCustomLabels && !showWeekend) { //lab length is used for display of graph.
            lab.pop();
            lab.pop();
          }

          //this.logger.debug(lab);
          this.buildChart(lab, labV, work['Plan'], work['Arb'], work['BetFrv'], work['UbetFrv'], work['Konto'], work['Dagtype']);

          if(this.showFlexiBox) {
            this.flexiTime.weekValue = 0;
            for(const s of work['Konto']) {
              this.flexiTime.weekValue += s;
            }
            this.flexiTime.weekValue = this.util.roundNumber(this.flexiTime.weekValue, 2);
          }
        }
      });

    }
  }

  clickHandler(evt) {

    this.swipeTriggered = null;
    const points = this.chartObj.getElementsAtEventForMode(evt, 'index', { intersect: false }, true);

    if (points.length) {
      const firstPoint = points[0];
      const data = this.chartObj.data.labelsValue[firstPoint.index];
      const res = {periode: {from: this.currPeriode.fromDate, to: this.currPeriode.toDate}, value: data};
      this.fieldService.setValue(res);
    }
    else {
      this.fieldService.setValue(null);
    }
  }

  buildChart(labels, labelsValue, workExpected, worked, offtime, unpayedOfftime, flexAcc, dayType) {
    const color = [];
    const colorsBorder = [];
    const labelColor = ['#666'];
    const borderWidth = [1];
    const today = moment().format('YYYY-MM-DD');
    let todayIndex = null;

    for (let i = 0; i < worked.length; i++) {
      color[i] = flexAcc[i] >= 0 ? '#2E8B5766' : '#ff111166';
      colorsBorder[i] = flexAcc[i] >= 0 ? '#2E8B57' : '#ff1111';
      labelColor[i] = dayType[i] === 0 ? '#777': '#f00';
      if (today === labelsValue[i])
        todayIndex = i;
    }

    const data: any = {
      labels: labels,
      labelsValue: labelsValue,
      datasets: [{
        label: this.translate.instant('NormLabel'),
        backgroundColor: '#aaaaaa',
        borderColor: '#cccccc',
        borderWidth: 0,
        data: workExpected,
        type: 'line',
        flexTime: flexAcc, //used in tooltip footer
        order: 0,
        stack: 'Stack 0'
      }, {
        label: this.translate.instant('WorkLabel'),
        backgroundColor: color,
        borderColor: colorsBorder,
        borderWidth: borderWidth,
        data: worked,
        order: 2,
        stack: 'Stack 1',
        skipNull: true
      }, {
        label: this.translate.instant('AbsenceLabel'),
        backgroundColor: '#6dbbff66',
        borderColor: colorsBorder,//'#6dbbff',
        borderWidth: borderWidth,
        data: offtime,
        order: 1,
        stack: 'Stack 1',
        skipNull: true
      }, {
        label: this.translate.instant('UnPayedAbsenceLabel'),
        backgroundColor: '#6dbbff66',
        borderColor: '#6dbbff',
        borderWidth: borderWidth,
        data: unpayedOfftime,
        order: 3,
        stack: 'Stack 2',
        skipNull: true
      }]
    };

    //this.logger.debug('data', data);
    const footText = this.translate.instant('FlexTime');
    const hourUnit = this.translate.instant('TimeAgoHour');

    const tooltipCallback = {
      footer: (ctx) => {
        if(!ctx || ctx.length < 1)
          return '';

        let sum = 0;
        if (!!ctx[0] && ctx[0].chart.data.datasets[0].flexTime) {
          sum = ctx[0].chart.data.datasets[0].flexTime[ctx[0].dataIndex];
        }
        const frontPlus = (sum>0)? '+' : '';
        return footText + ': ' + frontPlus + sum + hourUnit;
      },
      label: (ctx) => {
          let label = ctx.dataset.label || '';

          if (label) {
              label += ': ';
          }
          if (ctx.parsed.y !== null) {
              label += ctx.parsed.y + hourUnit;
          }
          return label;
      }
    };


    const eventHandler = {
      id: 'eventHandler',
      beforeEvent: (chart, args, options) => {
        if(args.event.type === 'click') {
         // console.log('CLICK event');
          if(options.skipClick())
            return false;
        }
      },
      beforeUpdate: (chart, args, options) => {
        options.chartIsLoading();
      },
      afterUpdate: (chart, args, options) => {
       // console.log(args);
        options.updateTime();
      }
    };

    const chartOptions: any = {
      responsive: true, // Instruct chart js to respond nicely.
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: 'index',
      },
      animation: {
        duration: 0
      },
      onClick: (evt) => {
       // console.log(evt);
        this.clickHandler(evt);
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: labelColor,
            font: {
              weight: (t) => (t.index===todayIndex)? 'bold' : ''
            },
          }
        },
        y: {
          stacked: true,
          beginAtZero: true,
          suggestedMax: 8.5,
          ticks: {
            stepSize: 0.5
          }
        }
      },
      events: ['click', 'mouseout'],
      plugins: {
        tooltip: {
          enabled: true,
          filter: (tooltipItem) => (tooltipItem.raw !== null),
          callbacks: tooltipCallback
        },
        legend: {
          display: false,
          labels: {
            boxWidth: 20
          }
        },
        eventHandler: {
          clearActiveElements: () => {
              this.clearActiveElements();
            }
          ,
          chartIsLoading: () => {
            // console.log('chart loading');
            this.chartIsUpdating = true;
          },
          updateTime: () => {
            // console.log('chart loading - done');
            this.chartIsUpdating = false;
          },
          skipClick: () => {
            if(!this.swipeTriggered)
              return false;
            // console.log('skipClick ', new Date(), this.swipeTriggered, this.chartIsUpdating);
            if(this.chartIsUpdating)
              return false;
            const s = moment(new Date()).diff(this.swipeTriggered, 'second', true);
            // console.log(s);
            return (s < 0.3 || this.chartIsUpdating);
          }
        }
      }
    };

    const config: any = {
      type: 'bar',
      data,
      options: chartOptions,
      plugins: [eventHandler]
    };

    if (this.chartObj) {
      if(this.chartObj.options?.animation?.duration===1000) {
        this.updateChartData(config);
  //      this.triggerLoading(false, {graf: this.isLoading.graf});
        return;
      }
      //this.logger.debug(this.chartObj);
      this.chartObj.data = config.data;
      chartOptions.animation.duration = 1000; // set animation after first init.
      this.chartObj.options = chartOptions;
      this.chartObj.update();
    }
    else {
      this.chartObj = new saldiChart.chart(
        this.grafCanvas.nativeElement,
        config
      );
      //this.logger.debug(this.chartObj);
      this.showChart = true;
    }
  //  this.triggerLoading(false, {graf: this.isLoading.graf});

  }

  updateChartData(config) {
//    this.logger.debug(this.chartObj.data.datasets);
//    this.logger.debug(config.data.datasets);

    this.chartObj.data.labels = config.data.labels;
    this.chartObj.data.labelsValue = config.data.labelsValue;
    this.chartObj.options.scales.x.ticks.font.weight = config.options.scales.x.ticks.font.weight;
    this.chartObj.options.scales.x.ticks.color = config.options.scales.x.ticks.color;

    for(let i = 0; i < config.data.datasets.length; i++) {
      Object.keys(config.data.datasets[i]).forEach((key) => {
        this.chartObj.data.datasets[i][key] = config.data.datasets[i][key];
    });
    }

    // console.log('update ');
    this.chartObj.update();
    this.clearActiveElements();

  }


  getFormId(subType: string) {
    let formId;
    if (subType === 'vacation' && this.boxList?.vacation?.formId)
      formId = this.boxList.vacation.formId;
    else if (subType === 'flextime' && this.boxList?.flexitime?.formId)
      formId = this.boxList.flexitime.formId;
    else if (subType === 'saldi' && this.boxList?.saldi?.formId)
      formId = this.boxList.saldi.formId;
    else if (subType === 'edit' && this.boxList?.edit?.formId) {
      formId = this.boxList.edit.formId;
    }
    else
      return undefined;

    return formId;
  }

  goToForm(subType: string) {
    const formId = this.getFormId(subType);
    this.formService.openForm(formId);
  }

  async showModal(subType: string, val?: string) {
    const formId = this.getFormId(subType);
    if (!formId)
      return;

    const values = {};

    const modal = await this.modalCtrl.create({
      component: SubFormComponent,
      componentProps: {
        values: values,
        formId: formId,
        showSaveBtn: false,
        showClearBtn: false
      }
    });
    await modal.present();
  }


}
