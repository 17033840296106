
<ion-list>
  <ion-item lines="none">
    <ion-label class="ion-text-wrap">{{label}}</ion-label>
  </ion-item>
  <ion-item lines="none" class="input-item">
    <ion-button *ngIf="!gettingPosition" [color]="color" slot="start" fill="clear" (click)="findPosition(false, $event)" [disabled]="disableButton">
      <ion-icon slot="icon-only" name="location-outline"></ion-icon>
    </ion-button>
    <ion-spinner slot="start" *ngIf="gettingPosition"></ion-spinner>
    <ion-input class="inputarea" [formControl]="$any(formControl)" type="text"></ion-input>
  </ion-item>
</ion-list>