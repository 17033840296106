import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { FieldService } from 'src/app/services/field.service';
import { TranslateService } from '@ngx-translate/core';
import { EvalFunction } from 'mathjs';
import { StateService } from 'src/app/services/state.service';
import { IonColor } from 'src/app/models/models';
import { ValidationService } from 'src/app/services/validation.service';
import { EvaluationService } from 'src/app/services/evaluation.service';

@Component({
  selector: 'app-field-sum',
  templateUrl: './sum.component.html',
  styleUrls: ['./sum.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class SumComponent extends FieldType implements OnInit {
  compiledCalc: EvalFunction;
  fieldsUsed: string[] = [];
  isValid = true;
  get sum(): number {
    return this.model[this.key as string | number] ?? 0;
  }
  set sum(val: number) {
    if (typeof val === 'string') {
      val = parseFloat(val);
    }
    if (typeof val !== 'number') {
      this.model[this.key as string | number] = 0;
    }
    else {
      this.model[this.key as string | number] = +val.toFixed(this.decimal);
    }
  }

  get label(): string {
    return this.to.label || '';
  }

  get unit(): string {
    return this.to.unit || '';
  }

  get result(): string {
    if (isNaN(this.sum)) {
      return this.translate.instant('Error');
    }
    else {
      return `${this.sum} ${this.unit}`;
    }
  }

  get decimal(): number {
    return (typeof this.to.decimal === 'undefined') ? 2 : parseInt(this.to.decimal);
  }

  get calc(): string {
    if (typeof this.to.calc === 'string') {
      return this.to.calc;
    }
    else {
      return '';
    }
  }

  get validationExpr(): string {
    if (typeof this.to.validationExpr === 'string') {
      return this.to.validationExpr;
    }
    else {
      return '';
    }
  }

  get validColor(): IonColor {
    return this.validation.validColor(this.to.validColor) ? this.to.validColor : IonColor.Dark;
  }

  get invalidColor(): IonColor {
    return this.validation.validColor(this.to.invalidColor) ? this.to.invalidColor : IonColor.Danger;
  }

  constructor(
    private watch: WatchgroupService,
    private fieldService: FieldService,
    private translate: TranslateService,
    private state: StateService,
    private validation: ValidationService,
    private evalService: EvaluationService
  ) {
    super();
    this.fieldService.setField(this);
  }

  public static getHtml(config: FormlyFieldConfig, value: any) {
    const label = config.templateOptions.label ?? '';
    const unit = config.templateOptions.unit ?? '';
    return `<ion-card><ion-item>
              <ion-label>${label}</ion-label>
              <span style="text-align: left">${value} ${unit}</span>
            </ion-item></ion-card>`;
  }

  ngOnInit() {
    const fields = this.state.getFields(this.to.currentForm.type, this.to.currentForm.id);
    if (this.calc) {
      const {compiledExpr, fieldsUsed} = this.evalService.compileMathExpression(this.calc, fields);
      if (!compiledExpr) {
        this.sum = NaN;
        return;
      }
      this.compiledCalc = compiledExpr;
      this.fieldsUsed = fieldsUsed;

      const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
      if (watchGroup.fieldKeys.length > 0) {
        this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() =>  {
          this.runCalc(this.compiledCalc, this.model, this.fieldsUsed);
          this.evalValidation(this.validationExpr, this.model);
        });
      }

      this.runCalc(this.compiledCalc, this.model, this.fieldsUsed);
      this.evalValidation(this.validationExpr, this.model);
    }
  }

  /**
   * Find value of an calculation function based on model
   * @param calc The calculation function
   * @param model The model to use as context
   */
  runCalc(calc: EvalFunction, model: any, fieldsUsed: string[]) {
    this.sum = this.evalService.evalCompiledMathExpression(calc, model, fieldsUsed, 0);
    this.fieldService.setValue(this.sum);
  }

  /**
   * Validate the current result using an validation function
   * @param expression The validation function
   * @param model The model to use as context
   */
  evalValidation(expression: string, model: any) {
    if (expression) {
      this.isValid = this.evalService.evalBooleanExpression(expression, model);
    }
  }
}
