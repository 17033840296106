<ion-header>
  <ion-toolbar>
    <ion-title>{{'ChooseShape' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="6" *ngFor="let shape of shapes" class="shapeCol">
        <ion-label>{{shape.label}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label position="stacked">{{'Size'| translate}}</ion-label>
          <ion-range [min]="50" [max]="200" [step]="50" [snaps]="true" [pin]="true" [ticks]="true" [(ngModel)]="size"></ion-range>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6">
          <ion-button color="secondary" expand="block" (click)="closePopover(false)">{{'Cancel' | translate}}</ion-button>
      </ion-col>
      <ion-col size="6">
          <ion-button color="primary" expand="block" (click)="closePopover(true)" [disabled]="!pickedShape">{{'Save' | translate}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>