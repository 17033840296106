
<ion-item lines="none">

<ion-label class="ion-text-wrap" *ngIf="!isLoading">{{label}}</ion-label>
<ion-spinner *ngIf="isLoading"></ion-spinner>

  <ion-buttons slot="end">
    <ion-button fill="clear" slot="end" color="tertiary" (click)="takePicture()" *ngIf="!disableCamera">
      <ion-icon slot="icon-only" name="camera-outline"></ion-icon>
    </ion-button>
    <ion-button fill="clear" slot="end" color="tertiary" (click)="getFromGallery()" *ngIf="allowGallery">
      <ion-icon slot="icon-only" name="images-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-item>

<ion-item lines="none" *ngIf="photos.length > 0">
<ion-grid >
  <ion-row >
    <ng-container *ngFor="let p of photos">
      <ng-container *ngTemplateOutlet="photo; context: p"></ng-container>
    </ng-container>
  </ion-row>
</ion-grid>
</ion-item>


<ng-template #photo let-src="src" let-id="id">
  <ion-col size="6">
    <ion-img [src]="src" (click)="openPreview(id, src)"></ion-img>
  </ion-col>
</ng-template>