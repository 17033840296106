import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopupService } from 'src/app/services/popup.service';
import { ApiService } from 'src/app/services/api.service';
import { InLoggerService } from 'src/app/services/in-logger.service';
import { StateService } from 'src/app/services/state.service';
import { InStorageService } from 'src/app/services/in-storage.service';
import { FeedbackOption, FeedbackType } from 'src/app/models/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-send-feedback',
  templateUrl: './send-feedback.component.html',
  styleUrls: ['./send-feedback.component.scss'],
})
export class SendFeedbackComponent implements OnInit {
  @Input() email: string;
  @Input() name: string;
  @Input() tenant: string;
  message = '';
  loading = true;
  showSegment = false;
  hasLog = false;
  feedbackType = FeedbackType.Support;
  types: FeedbackOption[] = [
    {type: FeedbackType.Support, label: 'SupportRequest'},
    {type: FeedbackType.AppBug, label: 'AppBugOrFeatureRequest'},
    {type: FeedbackType.FormBug, label: 'FormBugOrFeatureRequest'}
    ];
  forms: {id: number, name: string}[] = [];
  chosenForm: number;
  includeLog = false;

  get showFormSelect(): boolean {
    return (this.feedbackType === 'form-bug' || this.feedbackType === 'form-feature') && this.forms.length > 0;
  }

  get showIncludeLog(): boolean {
    return this.hasLog;
  }

  constructor(
    private popCtrl: PopoverController,
    private popup: PopupService,
    private api: ApiService,
    private inLogger: InLoggerService,
    private state: StateService,
    private storage: InStorageService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.inLogger.hasError().then(res => {
      this.hasLog = res;
      this.loading = false;
    });
    this.storage.getForms(this.state.currentProject?.id).then(forms => {
      this.forms = forms.map(f => ({id: f.id, name: f.name}));
    });
  }

  close() {
    this.popCtrl.dismiss().then(
      () => this.logger.debug('Successfully dismissed feedback popover'),
      (err) => this.logger.warn('Failed dismissing feedback popover', err)
    );
  }

  async sendLog() {
    this.loading = true;
    const log = this.includeLog ? await this.inLogger.getLog() : null;
    this.api.sendFeedback(this.name, this.tenant, this.email, this.message, this.feedbackType, this.chosenForm, log).subscribe(async ({value: res}) => {
      if (res) {
        this.popup.showMessage('SendFeedbackSuccess', true, 'light');
        this.inLogger.clearLog();
      }
      else {
        this.popup.showMessage('SendFeedbackFailed', true, 'danger');
      }
      this.close();
    });
  }
}
