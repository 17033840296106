import { Injectable } from '@angular/core';
import { InStorageService } from './in-storage.service';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { UtilityService } from './utility.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { shouldKeepMetaData } from '../helpers/helperFunctions';


/**
 * Service for managing autosaving values of forms for recovery purposes
 */
@Injectable({
  providedIn: 'root'
})
export class AutosaveService {
  autosaves: {
    [key: string]: {
      subscription: Subscription,
      notInclude: string[],
      hiddenFields: string[],
      projectId: number,
      model?: any
    }
  } = {};
  keysToAdd: {[key: string]: string[]} = {};
  activeSave: string; // save key on format 'formId:projectId'

  constructor(
    private storage: InStorageService,
    private util: UtilityService
  ) { }

  /**
   * Start autosaving the contents of an form
   * @param formId The id of the form to autosave
   * @param projectId The id of the project form is opened in
   * @param formGroup The formgroup of the form
   * @param model The model of the form
   * @param fields The fields of the form
   * @param saveHidden If hidden fields should be saved, default: `false`
   */
  async startAutosave(formId: number, projectId: number, formGroup: UntypedFormGroup, model: any, fields: FormlyFieldConfig[] = [], saveHidden = false) {
    const saveKey = this.getSaveKey(formId, projectId);
    if (this.activeSave && this.activeSave !== saveKey) {
      const {formId: oldFormId, projectId: oldProjectId} = this.splitSaveKey(this.activeSave);
      await this.stopAutosave(oldFormId, oldProjectId);
    }
    this.activeSave = saveKey;
    if (this.autosaves[saveKey]) {
      this.switchSubscription(formId, projectId, formGroup, model, fields);
    }
    else {
      const notInclude = this.keysToAdd[saveKey] ?? [];
      const hiddenFields = saveHidden ? [] : fields.filter(field => field.className?.includes('hide')).map(field => field.key as string);
      this.autosaves[saveKey] = {
        subscription: formGroup.valueChanges.subscribe(() => {
          this.autosaves[saveKey].model = this.checkAndStoreModel(formId, projectId, this.util.createCopyOfObject(model), fields);
        }),
        notInclude: notInclude,
        projectId: projectId,
        hiddenFields: hiddenFields
      };
      if (this.keysToAdd[saveKey]) {
        delete this.keysToAdd[saveKey];
      }
    }
  }

  /**
   * Stop autosaving form if it matches formType, will by default clear the values from storage if it's in FormPage
   * @param formId The id of the form to autosave
   * @param projectId The id of the project form is opened in
   * @param clear (Optional) If the autosave in storage should be cleared. Default: `false`
   */
  async stopAutosave(formId: number, projectId: number, clear: boolean = false) {
    const saveKey = this.getSaveKey(formId, projectId);
    if (this.autosaves[saveKey]) {
      this.autosaves[saveKey].subscription.unsubscribe();
    }
    this.activeSave = null;
    if (clear) {
      delete this.autosaves[saveKey];
      await this.clearAutosave(formId, projectId);
    }
  }

  /**
   * Get the autosaved values from storage
   * @param formId The id of the form
   * @param projectId The id of the project the form was opened in
   */
  getAutosave(formId: number, projectId: number) {
    return this.storage.getAutosave(formId, projectId);
  }

  /**
   * Check if there exists an autosave of the form
   * @param formId The id of the form
   * @param projectId The id of the project the form was opened in
   */
  async hasAutosave(formId: number, projectId: number) {
    const values = await this.getAutosave(formId, projectId);
    if (!values) {
      return false;
    }
    else {
      return Object.values(values).filter(val => !this.emptyValue(val)).length > 0 ? true : false;
    }
  }

  /**
   * Clear the autosaved values from storage
   * @param formId The id of the form
   * @param projectId The id of the project the form was opened in
   */
  clearAutosave(formId: number, projectId: number) {
    return this.storage.removeAutosave(formId, projectId);
  }

  /**
   * Check if an field is being saved
   * @param formId The id of the form
   * @param projectId The id of the project the form was opened in
   * @param key The key of the field
   */
  isSavingField(formId: number, projectId: number, key: string) {
    const saveKey = this.getSaveKey(formId, projectId);
    const autosave = this.autosaves[saveKey];
    if (autosave) {
      return !autosave.notInclude.includes(key) && !autosave.hiddenFields.includes(key);
    }
    else {
      return false;
    }
  }

  /**
   * Add key of field that is **not** going to be autosaved
   * @param formId The id of the form
   * @param projectId The id of the project the form was opened in
   * @param key The key of the field
   */
  addKey(formId: number, projectId: number, key: string) {
    const saveKey = this.getSaveKey(formId, projectId);
    if (this.autosaves[saveKey]) {
      const autosave = this.autosaves[saveKey];
      if (!autosave.notInclude.includes(key)) {
        autosave.notInclude.push(key);
      }
      if (autosave.model && autosave.model[key]) {
        delete this.autosaves[saveKey].model[key];
        this.storage.setAutosave(formId, autosave.projectId, autosave.model);
      }
    }
    else if (!this.keysToAdd[saveKey]) {
      this.keysToAdd[saveKey] = [key];
    }
    else if (!this.keysToAdd[saveKey].includes(key)) {
      this.keysToAdd[saveKey].push(key);
    }
  }

  /**
   * Remove key of field that isn't autosaved, allowing it to be autosaved again
   * @param formId The id of the form
   * @param projectId The id of the project the form was opened in
   * @param key The key of the field
   */
  removeKey(formId: number, projectId: number, key: string) {
    const saveKey = this.getSaveKey(formId, projectId);
    if (this.autosaves[saveKey]) {
      this.autosaves[saveKey].notInclude = this.autosaves[saveKey].notInclude.filter(k => k !== key);
    }
    else if (this.keysToAdd[saveKey]) {
      this.keysToAdd[saveKey] = this.keysToAdd[saveKey].filter(k => k !== key);
    }
  }

  /**
   * Switch the subscription from one formgroup to a new formgroup
   * @param formId The id of the form to autosave
   * @param projectId The id of the project form is opened in
   * @param formGroup The new formgroup
   * @param model The model of the form
   * @param fields The fields of the form
   */
  private switchSubscription(formId: number, projectId: number, formGroup: UntypedFormGroup, model: any, fields: FormlyFieldConfig[]) {
    const saveKey = this.getSaveKey(formId, projectId);
    this.autosaves[saveKey].subscription.unsubscribe();
    this.autosaves[saveKey].subscription = formGroup.valueChanges.subscribe(() => {
      this.autosaves[saveKey].model = this.checkAndStoreModel(formId, projectId, this.util.createCopyOfObject(model), fields);
    });
    // this.autosaves[saveKey].model = this.checkAndStoreModel(formId, projectId, formGroup.value, this.autosaves[saveKey].notInclude);
  }

  /**
   * Check the model (remove unwanted values) and store it
   * @param formId The id of the form to autosave
   * @param projectId The id of the project form is opened in
   * @param model The model of the form
   * @param fields The fields of the form
   */
  private checkAndStoreModel(formId: number, projectId: number, model: any, fields: FormlyFieldConfig[]) {
    const saveKey = this.getSaveKey(formId, projectId);
    const notInclude = this.autosaves[saveKey]?.notInclude;
    const hiddenFields = this.autosaves[saveKey]?.hiddenFields;
    const fieldKeys  = fields.map(field => field.key as string).filter(key => !notInclude.includes(key) && !hiddenFields.includes(key));
    for (const [key, value] of Object.entries(model)) {
      if (this.emptyValue(value) || (!fieldKeys.includes(key) && !shouldKeepMetaData(key))) {
        delete model[key];
      }
    }
    this.storage.setAutosave(formId, projectId, model);
    return model;
  }

  /**
   * Get the key for saving autosave
   * @param formId The id of the form to autosave
   * @param projectId The id of the project form is opened in
   */
  private getSaveKey(formId: number, projectId: number) {
    return `${formId}:${projectId}`;
  }

  /**
   * Split save key to formId and projectId
   * @param saveKey The save key to split
   */
  private splitSaveKey(saveKey: string): {formId: number, projectId: number} {
    const parts =  saveKey.split(':').map(k => +k);
    return {
      formId: parts[0],
      projectId: parts[1]
    };
  }

  /**
   * Checks if an value is "empty"
   */
  private emptyValue(value: any): boolean {
    return typeof value === 'undefined' || value === null || value === '' || (Array.isArray(value) && value.length === 0);
  }
}
