/**
 * The different states/pages the app may be in.
 */
export enum State {
    Form = 'form',
    Forms = 'forms',
    Preview = 'preview',
    History = 'history',
    Reg = 'reg',
    Home = 'home',
    Setup = 'setup',
    Settings = 'settings',
    UserSettings = 'usersettings',
    Intro = 'intro',
    Feedback = 'feedback',
    Notifications = 'notifications',
    Other = 'other',
}
