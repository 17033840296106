import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import * as EventSource from 'ng-event-source';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { UtilityService } from 'src/app/services/utility.service';
import { NGXLogger } from 'ngx-logger';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { MarkdownComponent } from 'ngx-markdown';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { Observable } from 'rxjs';
import { CachedData, FormType } from 'src/app/models/models';
import { StateService } from 'src/app/services/state.service';

/**
 * Set as this to be enable to mock event source
 */
export const streamDeps = {
  EventSource: EventSource.EventSourcePolyfill
};

@Component({
  selector: 'app-field-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss'],
})
export class StreamComponent extends FieldType implements OnInit, OnDestroy {
  @ViewChild('markdownEl') markdownEl: MarkdownComponent;
  headers: any;
  data: string;
  eventSource: EventSource.EventSourcePolyfill;
  isOverflowing = false;
  showOverflowArrow = false;

  constructor(
    private util: UtilityService,
    private logger: NGXLogger,
    private cachedApi: CachedApiService,
    private domOp: DomOperationsService,
    private state: StateService
  ) {
    super();
  }

  get label(): string {
    return this.to.label || '';
  }

  get template(): string {
    return this.to.template || '';
  }

  get streamUrl(): string {
    return this.to.streamUrl || '';
  }

  get initUrl(): string {
    return this.to.initUrl || '';
  }

  get streamPath(): string {
    return this.to.streamPath || '';
  }

  get initPath(): string {
    return this.to.initPath || '';
  }

  get timeout(): number {
    return (typeof this.to.timeout === 'number') ? this.to.timeout : 45000;
  }

  get apiKey(): string {
    return this.to.apiKey || '';
  }

  get apiSecret(): string {
    return this.to.apiSecret || '';
  }

  get httpType(): 'GET' | 'POST' | 'PUT' | 'DELETE' {
    const type = this.to.httpType?.toUpperCase();
    switch (type) {
      case 'POST':
      case 'PUT':
      case 'DELETE':
        return type;
      default:
        return 'GET';
    }
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  ngOnInit() {
    if (this.apiKey && this.apiSecret) {
      const encodedString = window.btoa(`${this.apiKey}:${this.apiSecret}`);
      this.headers = {
        Authorization: `Basic ${encodedString}`
      };
    }
    else {
      this.headers = this.to.headers;
    }

    if (this.initUrl) {
      this.init();
    }
    if (this.streamUrl) {
      this.startStreaming();
    }
  }

  ngOnDestroy() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  checkOverflowing() {
    setTimeout(() => {
        this.isOverflowing = this.domOp.isOverflowing(this.markdownEl?.element?.nativeElement);
        this.showOverflowArrow = this.isOverflowing;
    });
  }

  hideScrollArrow() {
    setTimeout(() => {
      this.showOverflowArrow = false;
    }, 1000);
  }

  init() {
    const url = this.util.parseText(this.initUrl, this.model, this.fields);
    let obs: Observable<CachedData<any>>;
    if (this.httpType === 'POST') {
      obs = this.cachedApi.getWebJsonWithPost(url, this.initPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    else if (this.httpType === 'PUT') {
      obs = this.cachedApi.getWebJsonWithPut(url, this.initPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    else if (this.httpType === 'DELETE') {
      obs = this.cachedApi.getWebJsonWithDelete(url, this.initPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    else {
      obs = this.cachedApi.getWebJSON(url, this.initPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    obs.subscribe(data => {
      if (data.value) {
        this.getData(data.value, '');
      }
    });
  }

  startStreaming() {
    if (this.headers) {
      this.eventSource = new streamDeps.EventSource(this.streamUrl, {
        headers: this.headers,
        heartbeatTimeout: this.timeout
      });
    }
    else {
      this.eventSource = new streamDeps.EventSource(this.streamUrl, {
        heartbeatTimeout: this.timeout
      });
    }
    this.eventSource.onmessage = (event: EventSource.OnMessageEvent) => {
      if (event && event.data) {
        try {
          let data: any = event.data;
          if (typeof data === 'string') {
            data = JSON.parse(data);
          }
          this.getData(data, this.streamPath);
        }
        catch {
          this.logger.error('Error getting stream data');
        }
      }
    };
  }

  getData(result: any, path: string) {
    const data = this.util.dotRef(result, path);
    const regEx = /__(.*?)__/g;
    setTimeout(() => {
      this.data = this.util.parseText(this.template, data, this.fields, '', regEx);
      this.formControl.setValue(this.data);
    });
  }

  handleSwipe() {}
}
