import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { FieldType } from '@ngx-formly/core';
import { FieldService } from 'src/app/services/field.service';
import { FormService } from 'src/app/services/form.service';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { DataStatus } from 'src/app/models/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class ChartComponent extends FieldType implements OnInit, AfterViewInit {
  @ViewChild('chartCanvas') private chartCanvas: ElementRef;

  showChart = true;
  chartObj: any;
  isLoading: boolean;

  useLocalDataset: boolean;

  resetOnUpdate = false;
  datasetsUrl = '';
  datasetProps = { label: 'Navn', unit: 'Enhet', data: 'Data', chartLabels: 'Labels', chartLabelsValue: 'LabelsValue', splitter: '|' };
  footerFn = { fn: null, label: '' };

  labels = [];
  values = [];
  datasets = [];
  chartOptions = null;
  cfg = {
    showLabels: false,
    title: '',
    footerFn: { type: '', label: '' }
  };

  trans = '66';
  transRnd = '40';
  colorPositive = { bg: '#58b471' + this.trans, border: '#58b471' };
  colorNegative = { bg: '#ff1111' + this.trans, border: '#ff1111' };
  randomColorlist = { bg: [], border: [] };


  constructor(
    private fieldService: FieldService,
    private formService: FormService,
    private watch: WatchgroupService,
    private cachedApi: CachedApiService,
    private logger: NGXLogger
  ) {
    super();
    this.fieldService.setField(this);
  }

  ngOnInit() {

    this.labels = this.to.labels || [];
    this.datasets = this.to.datasets || [];
    this.chartOptions = this.to.options || null;
    this.resetOnUpdate = this.to.resetOnUpdate || false;

    this.colorPositive = (this.to.colorPositive) ? this.to.colorPositive : this.colorPositive;
    this.colorNegative = (this.to.colorNegative) ? this.to.colorNegative : this.colorNegative;

    this.cfg = this.to.cfg;

    this.datasetsUrl = this.to.datasetsUrl || '';
    this.datasetProps = this.to.datasetProps || this.datasetProps;
    this.useLocalDataset = (this.datasetsUrl === '');

    this.isLoading = !(this.useLocalDataset);
    if (this.to.cfg?.footerFn) {
      if (this.to.cfg.footerFn.type === 'restOfFirst')
        this.footerFn = { fn: this.footerFnRestOfFirst, label: this.to.cfg.footerFn.label || '' };
    }

    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length >0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe((v) => {
        this.getDatasets();
      });
    }

  }

  getDatasets() {

    this.logger.debug('get data..');
    this.isLoading = true;
    const obs = this.cachedApi.getWebJSON(this.datasetsUrl, '', this.formService.formId, this.to.headers, this.to.useCredentials);
    obs.subscribe(({ value, status }) => {
      if (status === DataStatus.Updated) {
        this.buildDataset(value);
      }
    });

  }


  ngAfterViewInit() {

    this.logger.debug('init');
    if (!this.useLocalDataset)
      this.getDatasets();
    else if (this.datasets?.length > 0) {
      const data = { labels: this.labels, datasets: this.datasets };
      //  console.log('data',data);
      this.formatDataset(data);
    }
    else {
      this.logger.debug('no data!');
    }

  }

  buildDataset(value) {

    let chartLabels = [];
    let chartLabelsValue = [];
    if (Array.isArray(value) && value.length > 0) {
      const datasets = [];

      for (const v of value) {
        let labels = [];
        let labelsVal = [];
        let datas = [];

        if (!this.datasetProps?.chartLabels) {
          this.isLoading = false; //TODO check if from api..
          this.fieldService.triggerChange();
          return;
        }
        const isArray = (Array.isArray(v[this.datasetProps.chartLabels])); // everything must uses same format

        const lab = (isArray) ? v[this.datasetProps.chartLabels] : v[this.datasetProps.chartLabels].split(this.datasetProps.splitter);
        const lval = (isArray) ? v[this.datasetProps.chartLabelsValue] : (v[this.datasetProps.chartLabelsValue]) ? v[this.datasetProps.chartLabelsValue]?.split(this.datasetProps.splitter) : [];
        const dval = (isArray) ? v[this.datasetProps.data] : v[this.datasetProps.data].split(this.datasetProps.splitter);

        //Pick out selected labels, or use everything.
        if (this.to.labels) {
          for (const l of this.to.labels) {
            const a = lab.indexOf(l);
            if (a >= 0) {
              labels.push(lab[a]);
              if (lval.length > 0)
                labelsVal.push(lval[a]);
              datas.push(dval[a]);
            }
          }
        }
        else {
          labels = lab;
          labelsVal = lval;
          datas = dval;
        }

        if (chartLabels.length === 0)
          chartLabels = labels;
        if (chartLabelsValue.length === 0)
          chartLabelsValue = labelsVal;

        //v foreach row - dataset

        let dataset = {};

        //Use config of datasets, replace data and labels.
        if (this.datasets.length > 0) {
          for (const d of this.datasets) {
            if (d.label === '' || d.label === v[this.datasetProps.label]) {

              if (this.to.type === 'pie' || this.to.type === 'doughnut')
                d.label = labels;
              d.data = datas;
              dataset = d;
            }
          }
        }
        else {
          dataset = {
            label: v[this.datasetProps.label],
            data: datas,
            rndColors: true
          };

        }
        datasets.push(dataset);

      }
      const data = { labels: chartLabels, labelsValue: chartLabelsValue, datasets: datasets };
      this.formatDataset(data);

    }
  }

  formatDataset(data) {
    //    const data = {labels: this.labels, datasets: this.datasets};
    let c = { color: [], border: [] };
    for (const d of data.datasets) {
      if (d.colorSplit) {
        c = this.buildColorList('split', d.data, d.colorSplit);
      }
      else if (d.rndColors) {
        if (this.randomColorlist.bg.length === 0) {
          this.labels.forEach((l) => {
            this.randomColorlist.bg.push(this.getRandomRgba(this.transRnd));
            this.randomColorlist.border.push(this.getRandomRgba(0));
          });
        }
        c = this.buildColorList('rnd', d.data, []);
      }
      if (c.color.length > 0) {
        d.backgroundColor = c.color;
        d.borderColor = c.border;
      }
    }
    //    this.isLoading = false; //TODO check if from api..
    this.buildChart(this.to.type, data);

  }


  footerFnRestOfFirst = (tooltipItems) => {
    let sum = 0;
    let isFirst = true;
    const label = this?.cfg?.footerFn?.label || '';

    tooltipItems.forEach((tooltipItem) => {
      if (isFirst)
        sum -= tooltipItem.parsed.y; //forventet
      else
        sum += tooltipItem.parsed.y; //jobbet
      isFirst = false;
    });
    return label + ' ' + sum;
  };

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  getRandomRgba(trans) {
    return 'rgb(' + this.getRandomInt(150) + ' ' + this.getRandomInt(150) + ' ' + this.getRandomInt(150) + ' / ' + trans + '%)';
  }

  buildColorList(type: string, values, splitNumber: Array<number> | number) {
    const colors = [];
    const colorsBorder = [];
    const colorList = { color: colors, border: colorsBorder };
    const useArray = (splitNumber instanceof Array);

    for (let i = 0; i < values.length; i++) {
      if (type === 'split') {
        const val = (useArray) ? splitNumber[i] : splitNumber;
        colors[i] = (values[i] >= val) ? this.colorPositive.bg : this.colorNegative.bg;
        colorsBorder[i] = (values[i] >= val) ? this.colorPositive.border : this.colorNegative.border;
      }
      else if (type === 'rnd') {
        colors[i] = this.randomColorlist.bg[i];
        colorsBorder[i] = this.randomColorlist.border[i];
      }
    }

    return colorList;
  }

  clickHandler(evt) {
    const points = this.chartObj.getElementsAtEventForMode(evt, 'index', { intersect: false }, true);

    if (points.length) {
      const firstPoint = points[0];
      const data = (this.chartObj.data.labelsValue?.length > firstPoint.index) ? this.chartObj.data.labelsValue[firstPoint.index] : this.chartObj.data.labels[firstPoint.index];
      //        const label = this.chartObj.data.labels[firstPoint.index];
      //        const value = this.chartObj.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      this.fieldService.setValue(data);
    }
  }

  getDefaultOptions(chartType) {

    let options = {};
    let delayed = false;

    const animation = {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    };

    const callbacksFooter = {
      tooltip: {
        callbacks: {
          footer: this.footerFn.fn
        }
      },
      legend: {
        labels: {
          boxWidth: 20
        }
      }
    };
    const scales = {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    };

    if (chartType === 'bar') {
      options = {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        onClick: (evt) => {
          this.clickHandler(evt);
        },
        animation: (this.to?.cfg?.aniDelay) ? animation : {},
        plugins: (this.footerFn.fn !== null) ? callbacksFooter : {
          legend: {
            labels: {
              boxWidth: 20
            }
          }
        },
        scales: scales
      };
    }
    else if (chartType === 'pie' || chartType === 'doughnut') {
      options = {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index'
        },
        onClick: (evt) => {
          this.clickHandler(evt);
        },
        plugins: {
          legend: {
            display: (this.cfg?.showLabels),
            labels: {
              boxWidth: 20
            }
          },
          title: {
            display: (this.cfg?.title) ? true : false,
            text: this.cfg?.title
          }
        }
      };
    }
    return options;
  }
  buildChart(chartType, data) {

    const options = (this.chartOptions) ? this.chartOptions : this.getDefaultOptions(chartType);
    this.logger.debug(options);
    const config = {
      type: chartType,
      data,
      options: options
    };

    setTimeout(() => {
      if (this.chartObj) {
        this.chartObj.data.datasets = config.data.datasets;
        this.chartObj.data.labels = config.data.labels;
        this.chartObj.update();
      }
      else {
        this.chartObj = new Chart(
          this.chartCanvas.nativeElement,
          config
        );
        //  console.log(this.chartObj);
        this.showChart = true;
      }
      this.chartObj.update();

      //    this.displayChart(data.datasets, this.showValuesWithDelay);
      this.isLoading = false; //TODO check if from api..

      this.fieldService.triggerChange();
    }, 0);
  }

}
