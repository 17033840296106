<ion-header>
    <ion-toolbar>
      <ion-title>
        {{'ChooseColor' | translate}}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  
  <ion-content>
    <ion-card>
      <ion-grid>
        <ion-row>
          <ion-col size="10" offset="1">
            <color-circle [colors]="colors" width="300px" (onChangeComplete)="setColor($event)"></color-circle>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-item lines="none">
              <ion-label position="stacked">{{'Size' | translate}}</ion-label>
              <ion-range min="1" max="10" [(ngModel)]="brushSize"></ion-range>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
              <ion-button id="color-cancel" color="secondary" expand="block" (click)="closePopover(false)">{{'Cancel' | translate}}</ion-button>
          </ion-col>
          <ion-col size="6">
              <ion-button id="color-save" color="primary" expand="block" (click)="closePopover(true)">{{'Save' | translate}}</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </ion-content>
