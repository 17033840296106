  <ion-card *ngIf="isDisabled" class="extraPadding notis">
    <span>{{'SaldiIsDisabled' | translate}}</span>
  </ion-card>

  <ion-grid *ngIf="!isDisabled">
    
  <ion-row>

    <ion-col id="flexiBox" [size]="colSize" class="" (click)="showModal('flextime')" *ngIf="showFlexiBox">
      <div class="clickable box">
          <div class="header">
          <ion-icon class="icon" name="time-outline"></ion-icon> <span class="iconText">{{'TimeAccount' | translate}}</span>
          <br>{{flexiTime.name}}
        </div>
        <div class="value">
          <ion-spinner class="boxLoading" *ngIf="isLoading.flexTime"></ion-spinner>
          {{flexiTime.value}}{{flexiTime.valType}} <span ng-if="displayChart">({{flexiWeekValue}}{{flexiTime.valType}})</span>
        </div>
      </div>
    </ion-col>

    <ion-col id="saldiBox" [size]="colSize" class="" (click)="showModal('saldi')" *ngIf="showSaldiBox">
      <div class="clickable box">
        <div class="header">
          <ion-icon class="icon" name="time-outline"></ion-icon> <span class="iconText">{{'Balance' | translate}}</span>
          <br>{{saldiTime.name}}
        </div>
        <div class="value">
          {{saldiTime.value}}{{saldiTime.valType}}
        </div>
      </div>

    </ion-col>
    <ion-col id="vacationBox" [size]="colSize" class="" (click)="showModal('vacation')" *ngIf="showVacationBox">
      <div class="clickable box">
        <div class="header">

          <ion-icon class="icon" name="airplane-outline"></ion-icon> <span class="iconText">{{'Vacation' | translate}}</span>
          <br>{{vacationTime.name}}
        </div>
        <div class="value">
          <ion-spinner class="boxLoading" *ngIf="isLoading.vacation"></ion-spinner>
          {{vacationTime.value}}{{vacationTime.valType}}
        </div>

      </div>

    </ion-col>
    
  </ion-row>

  <ion-row >
    <ion-col id="chartBox" (swipe)="handleSwipe($event)">
      <div class="chartWrapper" [ngClass]="{'hideChart' : !showChart}">
        <ion-spinner class="grafLoading" *ngIf="isLoading.graf"></ion-spinner>
        <canvas #grafCanvas style="position: relative;"></canvas>
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col *ngIf="showEditBox" class="flexBoxcenterContent">
      <span id="editBox" class="clickable" (click)="goToForm('edit')">
        <ion-icon class="icon" name="cog-outline"></ion-icon> <span class="iconText">{{'ShowRegistrations' | translate}}</span>
      </span>
    </ion-col>
  </ion-row>
</ion-grid>
