import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RegistrationModalComponent } from '../../shared-directives/registration-modal/registration-modal.component';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { DataStatus, DataSource, Form, Project, OldReg } from 'src/app/models/models';

@Component({
  selector: 'app-last-reg',
  templateUrl: './last-reg.component.html',
  styleUrls: ['./last-reg.component.scss'],
})
export class LastRegComponent implements OnChanges {
  get formId(): number {
    return this._formId;
  }

  @Input('formId')
  set formId(id: number) {
    this._formId = id;
  }

  get projectId(): number {
    return this._projectId;
  }

  @Input('projectId')
  set projectId(id: number) {
    this._projectId = id;
  }

  @Input('form')
  set form(form: Form)  {
    this._formId = form?.id;
  }

  @Input('project')
  set project(project: Project) {
    this._projectId = project?.id;
  }
  @Input() lastRefresh: Date;
  @Output() foundReg = new EventEmitter<number>();
  reg: OldReg;
  loading = false;
  private _formId: number;
  private _projectId: number;

  constructor(
    private cachedApi: CachedApiService,
    private modalCtrl: ModalController
  ) { }

  ngOnChanges() {
    this.loading = true;
    if (this.projectId && this.formId) {
      this.cachedApi.getLastRegistration(this.formId, this.projectId).subscribe(data => {
        if (!data.value) {
          this.reg = null;
        }
        else if (data.value && data.status === DataStatus.Updated) {
          this.reg = data.value;
          this.foundReg.emit(this.reg.regId);
        }
        if (data.source === DataSource.API) {
          this.loading = false;
        }
      });
    }
  }

  async openRegistration() {
    if (this.loading) {
      return;
    }
    const modal = await this.modalCtrl.create({
      component: RegistrationModalComponent,
      componentProps: {
        reg: this.reg
      }
    });
    await modal.present();
  }

}
