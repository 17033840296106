import { NgModule } from '@angular/core';
import { TimeAgoPipe } from './time-ago.pipe';

@NgModule({
declarations: [TimeAgoPipe],
imports: [],
exports: [TimeAgoPipe],
})

export class PipesModule {}
