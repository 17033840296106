import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TextModalComponent } from '../custom-components/directives/shared-directives/text-modal/text-modal.component';
import { ApiStatus, FirebaseNotification, StoredNotification, NotificationType } from '../models/models';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { DeviceService } from './device.service';
import { FirebaseService } from './firebase.service';
import { InStorageService } from './in-storage.service';
import { PopupService } from './popup.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  refreshSub: Subscription;
  msgSub: Subscription;
  currentToken: string;
  notifications: StoredNotification[] = [];
  hasUpdate: boolean;
  private _subscribedTopics: string[] = [];

  get alertCount(): number {
    let count = this.notOpened;
    if (this.hasUpdate) {
      count++;
    }
    return count;
  }

  get notOpened(): number {
    return this.useNotifications ? this.notifications.filter(n => !n.opened).length
                                        : 0;
  }

  get notificationCount(): number {
    return this.useNotifications ? this.notifications.length
                                        : 0;
  }

  get hasNotifications(): boolean {
    return this.notificationCount > 0;
  }

  get subscribedTopics(): string[] {
    return this._subscribedTopics;
  }

  private get useNotifications(): boolean {
    return environment.useNotifications && this.device.isDevice();
  }

  constructor(
    private util: UtilityService,
    private firebase: FirebaseService,
    private logger: NGXLogger,
    private popup: PopupService,
    private api: ApiService,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private router: Router,
    private storage: InStorageService,
    private device: DeviceService,
    private browser: BrowserService
  ) { }

  /**
   * Will initalize notifications
   *
   * @param tenant The current tenant
   * @param username Username of user
   */
  async init() {
    if (this.useNotifications && !this.refreshSub) {
      this.notifications = await this.storage.getNotifications();
      const res = await this.firebase.initNotifications();
      if (res) {
        await this.checkVersionOfUpdates();
        await this.getAndUploadToken();
        await this.subscribeToDefaultTopics();
      }
    }
  }

  /**
   * Will stop listening and delete token
   *
   * @param tenant The current tenant
   * @param username Username of user
   */
  async stop() {
    if (this.useNotifications) {
      this.refreshSub?.unsubscribe();
      this.msgSub?.unsubscribe();
      this.refreshSub = null;
      this.msgSub = null;
      this.currentToken = null;
      await this.unsubscribeFromAllTopics();
      await this.firebase.unregister();
      await firstValueFrom(this.api.deleteFirebaseToken(), {defaultValue: {value: null, status: ApiStatus.Failed}});
    }
  }

  /**
   * Start listening for notifications from Firebase
   */
  listenForNotifications() {
    if (this.useNotifications) {
      if (this.msgSub) {
        this.msgSub.unsubscribe();
      }
      this.msgSub = this.firebase.onMessageReceived().subscribe(msg => {
        this.logger.debug(msg);
        this.handleMessage(msg);
      });
    }
  }

  /**
   * Add an notification to the list of notifications
   *
   * @param notification The notification to add
   */
  async addNotification(notification: StoredNotification) {
    this.notifications.unshift(notification);
    await this.storage.setNotifications(this.notifications);
  }

  /**
   * Remove an notification from the list
   *
   * @param id The id of the notification to remove
   */
  async removeNotification(id: string) {
    this.notifications = this.notifications.filter(n => n.id !== id);
    await this.storage.setNotifications(this.notifications);
  }

  /**
   * Mark all message type notifications as opened
   *
   * @param waitTime (Optional) How long to wait (in ms) before they are marked as opened, default: 3000
   */
  markMessagesAsOpened(waitTime: number = 3000) {
    return new Promise<void>(resolve => {
      setTimeout(async () => {
        for (const notification of this.notifications) {
          if (notification.type === NotificationType.Message) {
            notification.opened = true;
          }
        }
        await this.storage.setNotifications(this.notifications);
        resolve();
      }, waitTime);
    });
  }

  /**
   * Check the appVersion of the update notifications. Will change the type to report/message if it isn't newer.
   */
  async checkVersionOfUpdates() {
    for (const notification of this.notifications) {
      if (notification.type === NotificationType.Update && !this.util.isNewerVersion(environment.appVersion, notification.appVersion)) {
        notification.type = notification.markdown ? NotificationType.Report : NotificationType.Message;
      }
    }
    await this.storage.setNotifications(this.notifications);
  }

  /**
   * Subscribe to an topic
   * @param topic The topic to subscribe to
   */
  async subscribeToTopic(topic: string): Promise<void> {
    if (this._subscribedTopics.includes(topic)) {
      return;
    }
    const res = await this.firebase.subscribeToTopic(topic);
    if (res) {
      this._subscribedTopics.push(topic);
    }
  }

  /**
   * Unsubscribe from an topic
   * @param topic The topic to unsubscribe from
   */
  async unsubscribeFromTopic(topic: string): Promise<void> {
    if (!this._subscribedTopics.includes(topic)) {
      return;
    }
    await this.firebase.unsubscribeFromTopic(topic);
    this._subscribedTopics = this._subscribedTopics.filter(t => t !== topic);
  }

  /**
   * Get token and upload it. If it succeeds it will start listening for notifications
   *
   * @param tenant The current tenant
   * @param username Username of the user
   */
  private async getAndUploadToken() {
    this.currentToken = await this.storage.getFirebaseToken();
    const token = await this.firebase.getToken();
    const validToken = await this.uploadToken(token);
    if (validToken) {
      this.listenForNotifications();
    }
  }

  /**
   * Subscribe to default topics relevant for the user.
   */
  private async subscribeToDefaultTopics() {
    const topics = await this.getDefaultTopics();
    await Promise.all(topics.map(topic => this.subscribeToTopic(topic)));
  }

  /**
   * Unsubscribe from all subscribed topics.
   */
  private async unsubscribeFromAllTopics() {
    await Promise.all(this._subscribedTopics.map(topic => this.unsubscribeFromTopic(topic)));
  }

  /**
   * Get the default topics relevant for the user. E.g. tenant, device type, and general
   */
  private async getDefaultTopics(): Promise<string[]> {
    let tenant = (await this.storage.getUser())?.tenant?.toLowerCase();
    tenant = tenant ? `in_tenant_${tenant}` : '';

    let deviceType = this.device.getType().toLowerCase();
    deviceType = `in_device_${deviceType}`;

    return [tenant, deviceType, 'in_general'];
  }

  /**
   * Create and store notification. Will do notification action if it was an tapped notification
   *
   * @param msg The message to handle
   */
  private async handleMessage(msg: FirebaseNotification) {
    if (msg.tap === 'background') {
      const notification = await this.createNotification(msg, true);
      await this.addNotification(notification);
      if (notification.doAction) {
        await notification.doAction(true);
      }
    }
    else if (msg.tap === 'foreground') {
      const notification = await this.createNotification(msg, true);
      await this.addNotification(notification);
      if (notification.doAction) {
        await notification.doAction(false);
      }
    }
    else {
      const notification = await this.createNotification(msg, false);
      await this.addNotification(notification);
    }
  }

  /**
   * Chekcks and uploads firebase token to server
   *
   * @param token The token to upload
   * @param tenant The tenant of the user
   * @param username The username of the user
   */
  private async uploadToken(token: string): Promise<boolean> {
    if (!token) {
      return false;
    }
    if (token !== this.currentToken) {
      this.currentToken = token;
      const {status} = await firstValueFrom(this.api.uploadFirebaseToken(token), {defaultValue: {value: false, status: ApiStatus.Failed}});
      if (status === ApiStatus.Success) {
        this.storage.setFirebaseToken(token);
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  /**
   * Creates an notification
   *
   * @param msg The notification/message from Firebase
   * @param opened If it has been opened
   */
  private async createNotification(msg: FirebaseNotification, opened: boolean): Promise<StoredNotification> {
    const type = this.getType(msg);
    const notification: StoredNotification = {
      id: this.util.generateId('msg'),
      received: new Date(),
      type: type,
      opened: opened,
      title: this.getTitle(msg.title, type),
      body: msg.body ?? ''
    };
    if (type === NotificationType.Report) {
      notification.markdown = msg.markdown;
      notification.doAction = async (background?: boolean) => {
        await this.openReport(notification);
        notification.opened = true;
        this.storage.setNotifications(this.notifications);
      };
    }
    else if (type === NotificationType.OpenForm) {
      notification.formId = msg.formId;
      notification.formName = await this.getFormName(msg);
      notification.projectNr = msg.projectNr;
      notification.doAction = async (background?: boolean) => {
        const remove = await this.openForm(notification, background);
        if (remove) {
          this.removeNotification(notification.id);
        }
        else {
          notification.opened = true;
          this.storage.setNotifications(this.notifications);
        }
      };
    }
    else if (type === NotificationType.Update) {
      const link = this.device.storeLink;
      notification.appVersion = msg.appVersion;
      notification.markdown = msg.markdown;
      notification.doAction = async (background?: boolean) => {
        await this.updateApp(notification, link, background);
        notification.opened = true;
        this.storage.setNotifications(this.notifications);
      };
    }
    return notification;
  }

  /**
   * Gets the name of the form referenced in an open_form notification
   *
   * @param msg The notification/message from Firebase
   */
  private async getFormName(msg: FirebaseNotification) {
    if (msg.formName) {
      return msg.formName;
    }
    const id = parseInt(msg.formId);
    const name = await this.storage.getFormProperty(id, 'name', null, msg.projectNr);
    return name;
  }

  /**
   * Triggers update app. Will navigate to store if it isn't newer, else it will open report if has it
   *
   * @param notification The update notification
   * @param link Link to App Store/Google Play
   * @param confirmed (Optional) If navigating to store is already confirmed, default: `false`
   */
  private async updateApp(notification: StoredNotification, link: string, confirmed: boolean = false) {
    if (notification.type !== NotificationType.Update) {
      return;
    }
    if (this.util.isNewerVersion(environment.appVersion, notification.appVersion)) {
      if (!confirmed) {
        const title = this.translate.instant('UpdateApp');
        const msg = this.device.isAndroid() ? this.translate.instant('UpdateAndroidMessage')
                                            : this.translate.instant('UpdateIOSMessage');
        confirmed = await this.popup.showConfirm(title, msg, false);
      }
      if (confirmed) {
        this.browser.openUrl(link);
      }
    }
    else if (notification.markdown) {
      notification.type = NotificationType.Report;
      await this.openReport(notification);
    }
    else {
      notification.type = NotificationType.Message;
    }
  }

  /**
   * Opens markdown report from report notification
   *
   * @param notification The report notification
   */
  private async openReport(notification: StoredNotification) {
    if (notification.type !== NotificationType.Report) {
      return;
    }
    const modal = await this.modalCtrl.create({
      component: TextModalComponent,
      componentProps: {
        title: notification.title,
        text: notification.markdown,
        showPrint: false
      }
    });
    await modal.present();
  }

  /**
   * Opens an form specified in the notification, will change project (if neccessary) if projectNr is specified
   *
   * @param notification The open_form notification
   * @param confirmed (Optional) If it has already been confirmed, default: `false`
   */
  private async openForm(notification: StoredNotification, confirmed: boolean = false) {
    const validProject = await this.checkAndChangeProject(notification.projectNr);
    if (validProject) {
      if (!confirmed) {
        const title = this.translate.instant('OpenForm');
        const msg = this.translate.instant('OpenFormMsg', {form: notification.formName || notification.formId});
        confirmed = await this.popup.showConfirm(title, msg, false);
      }
      if (confirmed) {
        await this.router.navigate([`/forms/${notification.formId}`]);
      }
      return confirmed;
    }
    else {
      return true;
    }
  }

  /**
   * Checks current project and changes if neccessary
   *
   * @param projectNr The project number
   */
  private async checkAndChangeProject(projectNr: string): Promise<boolean> {
    if (!projectNr) {
      return true;
    }
    let project = await this.storage.getCurrentProject();
    if (project.number === projectNr) {
      return true;
    }
    const projects = await this.storage.getProjects();
    project = projects.find(p => p.number === projectNr);
    if (!project) {
      return false;
    }
    const {status} = await firstValueFrom(this.api.setCurrentProject(project), {defaultValue: {value: null, status: ApiStatus.Failed}});
    return status === ApiStatus.Success;
  }

  /**
   * Gets the type of notification
   *
   * @param msg The notification/message from Firebase
   */
  private getType(msg: FirebaseNotification): NotificationType {
    if (msg.notificationType === NotificationType.OpenForm) {
      return msg.formId ? NotificationType.OpenForm : NotificationType.Message;
    }
    else if (msg.notificationType === NotificationType.Report) {
      return msg.markdown ? NotificationType.Report : NotificationType.Message;
    }
    else if (msg.notificationType === NotificationType.Update) {
      if (this.util.isNewerVersion(environment.appVersion, msg.appVersion) && this.device.isDevice()) {
        return NotificationType.Update;
      }
      else if (msg.markdown) {
        return NotificationType.Report;
      }
      else {
        return NotificationType.Message;
      }
    }
    else {
      return NotificationType.Message;
    }
  }

  /**
   * Get the title of the notification, with defaults if it doesn't exist
   *
   * @param title The title in the notification/message from Firebase
   * @param type The type of notification
   */
  private getTitle(title: string, type: NotificationType): string {
    if (title) {
      return title;
    }
    else {
      switch(type) {
        case NotificationType.Update:
          return this.translate.instant('Update');
        case NotificationType.Report:
          return this.translate.instant('Report');
        case NotificationType.OpenForm:
          return this.translate.instant('OpenForm');
        default:
          return this.translate.instant('Message');
      }
    }
  }
}
