import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { SubFormComponent } from 'src/app/custom-components/directives/formly-directives/sub-form/sub-form.component';
import { FormViewComponent } from 'src/app/custom-components/directives/page-directives/form-view/form-view.component';
import { FormType, PageRefs } from 'src/app/models/models';
import { FormPage } from 'src/app/pages/form/form.page';
import { ComponentRefService } from 'src/app/services/component-ref.service';

@Component({
  selector: 'app-field-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss'],
})
export class SpaceComponent extends FieldType {

  constructor(
    private ref: ComponentRefService
    ) {
    super();
  }
  get type(): string {
    switch (this.to.type) {
      case 'dotted':
      case 'solid':
      case 'dashed':
      case 'double':
      case 'groove':
      case 'ridge':
      case 'inset':
      case 'outset':
        return this.to.type;
      default:
        return 'dotted';
    }
  }

  get color(): string {
    return (typeof this.to.color === 'string') ? this.to.color : '';
  }

  get size(): number {
    return parseInt(this.to.size) || 1;
  }

  get height(): number {
    return parseInt(this.to.height) || 1;
  }

  get margin(): string {
    return 10 * this.height + 'px';
  }

  get border(): string {
    return this.color ? this.size + 'px ' + this.type + ' ' + this.color : '';
  }

  get isDisabled(): boolean {
    return this.to.disabled || false;
  }

  get formId(): number {
    return this.to?.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  get pageRef(): PageRefs {
    return this.to?.currentForm?.ref;
  }

  get hasClearKeys(): boolean {
    return (this.to.clearKeys && Array.isArray(this.to.clearKeys) && this.to.clearKeys.length > 0);
  }

  get btnLeft(): boolean {
    return this.to.btnLeft || false;
  }


  public static getHtml(config: FormlyFieldConfig, _value: any) {
    const type = config.templateOptions.type || 'dotted';
    const size = parseInt(config.templateOptions.size) || 1;
    const height = parseInt(config.templateOptions.height) || 1;
    const margin = 10 * height + 'px';
    const color = (typeof config.templateOptions.color === 'string') ? config.templateOptions.color : '';
    const border = color ? size + 'px ' + type + ' ' + color : '';
    if (border) {
      return `<div style="border-bottom: ${border}; margin-top: ${margin}; margin-bottom: ${margin}; width: 100%; clear: both;"></div>`;
    }
    else {
      return `<div style="margin-top: ${margin}; margin-bottom: ${margin}; width: 100%; clear: both;"></div>`;
    }
  }


  triggerClear() {
    if(!this.to.clearKeys || this.isDisabled)
      return;

    if (this.to.clearKeys.length > 0) {
      const clearVals = {};
      for (const key of this.to.clearKeys) {
        clearVals[key] = '';
      }
      this.setVars(clearVals);
    }
    else if (this.to.clearForm) {
      this.clearForm();
    }
  }


  setVars(vars: any) {
    if (!this.pageRef) return;

    const page: FormPage | FormViewComponent | SubFormComponent = this.ref.getReference(this.pageRef, this.formId, this.formType)[0];
    if (page) {
      page.setVars(vars);
    }
  }

  clearForm() {
    if (!this.pageRef) return;

    const page: FormPage | FormViewComponent | SubFormComponent = this.ref.getReference(this.pageRef, this.formId, this.formType)[0];
    if (page) {
      page.clearForm(false);
    }
  }

}
