import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Printer } from '@ionic-native/printer/ngx';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { MarkdownComponent, MarkdownService } from 'ngx-markdown';
import { BrowserService } from 'src/app/services/browser.service';
import { DeviceService } from 'src/app/services/device.service';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-text-modal',
  templateUrl: './text-modal.component.html',
  styleUrls: ['./text-modal.component.scss'],
})
export class TextModalComponent implements AfterViewInit {
  @ViewChild('markdownEl') markdownEl: MarkdownComponent;
  @Input() title: string;
  @Input() text: string;
  @Input() copyText: string;
  @Input() showPrint = false;
  @Input() allowCopy = false;
  hasOpened = false;
  isOverflowing = false;
  showOverflowArrow = false;

  get textToCopy(): string {
    return this.copyText || this.text;
  }

  constructor(
    private modalCtrl: ModalController,
    private domOp: DomOperationsService,
    private printer: Printer,
    private device: DeviceService,
    private markdown: MarkdownService,
    private popup: PopupService,
    private browser: BrowserService,
    private logger: NGXLogger
  ) { }

  close() {
    this.modalCtrl.dismiss().then(
      () => this.logger.debug('Successfully dismissed text modal'),
      (err) => this.logger.warn('Failed dismissing text modal', err)
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.hasOpened = true;
    });
  }

  print() {
    const element = document.getElementById('print-area');
    const div = document.createElement('div');
    div.innerHTML = this.markdown.parse(this.text);
    div.classList.add('markdown');
    element.appendChild(div);
    setTimeout(async () => {
      if (this.device.isDevice()) {
        await this.printer.print();
      }
      else {
        window.print();
      }
      element.innerHTML = '';
    }, 100);
  }

  copyToClipboard(text: string) {
    this.browser.copyToClipboard(text).then(
      () => {
        this.popup.showMessage('CopiedToClipboardSuccess', true, 'light', 1000);
      },
      err => {
        this.popup.showMessage('CopiedToClipboardFailed', true, 'danger');
        this.logger.error('Error copying text to clipboard', err);
      }
    );
  }

  checkOverflowing() {
    setTimeout(() => {
      this.isOverflowing = this.domOp.isOverflowing(this.markdownEl?.element?.nativeElement);
      this.showOverflowArrow = this.isOverflowing;
    });
  }

  hideScrollArrow() {
    setTimeout(() => {
      this.showOverflowArrow = false;
    }, 1000);
  }


}
