import { Injectable } from '@angular/core';

/**
 * Service for interacting with DOM
 *
 * - **scrollIntoViewAndExecute**: Scroll to an element and execute callback
 * - **findFieldElement**: Find the HTML element containing the field when starting from an wrapper
 * - **findFormlyFieldElement**: Find the root formly-field containing all the wrappers and the field
 */
@Injectable({
  providedIn: 'root'
})
export class DomOperationsService {

  /**
   * Scroll to an element and execute and optional callback
   * @param element The element to scroll to
   * @param callback (Optional) An callback function to execute after scrolling
   * @param delay (Optional) How long, in ms, to wait before executing callback, default: 1000
   */
  scrollIntoViewAndExecute(element: Element, callback?: () => void, delay: number = 1000) {
    if (!element) {
      return;
    }
    try {
      element.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
    catch {
      // No action needed
    }
    if (callback) {
      setTimeout(() => {
        callback();
      }, delay);
    }
  }

  /**
   * Find the HTML element containing the field when starting from an wrapper
   * @param element The element containing the wrapper
   */
  findFieldElement(element: Element): Element {

    if (element?.localName?.startsWith('app-field')) {
      return element;
    }
    else if (element?.children?.length > 0) {
      let child: Element;
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < element.children.length; i++) {
        child = element.children[i];
        if (child.localName.startsWith('app-field')) {
          return child;
        }
        else if (child.localName.startsWith('app-wrapper')) {
          return this.findFieldElement(child);
        }
      }
      child = element.children[0];
      if (child.localName.startsWith('ion-card') || child.localName.startsWith('ion-item')) {
        return this.findFieldElement(child);
      }
      return null;
    }
    else {
      return null;
    }
  }

  /**
   * Find the root formly-field containing all the wrappers and the field
   * @param element The starting element (wrapper/field)
   */
  findFormlyFieldElement(element: Element): Element {
    if (!element) {
      return null;
    }
    else if (element.localName === 'formly-field') {
      return element;
    }
    else {
      return this.findFormlyFieldElement(element.parentElement);
    }
  }

  /**
   * Check if an element is overflowing
   * @param The element to check
   * @param diff (Optional) What margin to use for defining overflow, default: `0`
   */
  isOverflowing(element: Element, diff = 0) {
    if (!element) {
      return false;
    }
    else {
      return element.scrollHeight - element.clientHeight > diff || element.scrollWidth - element.clientWidth > diff;
    }
  }
}
