  /* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from './fields/text/text.component';
import { TitleComponent } from './fields/title/title.component';
import { SpaceComponent } from './fields/space/space.component';
import { ImageComponent } from './fields/image/image.component';
import { MarkdownModule } from 'ngx-markdown';
import { IonicModule } from '@ionic/angular';
import { RatingComponent } from './fields/rating/rating.component';
import { GpspositionComponent } from './fields/gpsposition/gpsposition.component';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NFC } from '@ionic-native/nfc/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './fields/button/button.component';
import { SumComponent } from './fields/sum/sum.component';
import { RequiredWrapperComponent } from './wrappers/required-wrapper/required-wrapper.component';
import { FormlyModule } from '@ngx-formly/core';
import { UserWrapperComponent } from './wrappers/user-wrapper/user-wrapper.component';
import { InfolookupComponent } from './fields/infolookup/infolookup.component';
import { StyleFormComponent } from './fields/style-form/style-form.component';
import { SignatureComponent } from './fields/signature/signature.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { CardWrapperComponent } from './wrappers/card-wrapper/card-wrapper.component';
import { LabelWrapperComponent } from './wrappers/label-wrapper/label-wrapper.component';
import { TimediffComponent } from './fields/timediff/timediff.component';
import { TextmergeComponent } from './fields/textmerge/textmerge.component';
import { InitWrapperComponent } from './wrappers/init-wrapper/init-wrapper.component';
import { LoggerModule } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { UnknownComponent } from './fields/unknown/unknown.component';
import { ModalselectComponent } from './fields/modalselect/modalselect.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { ValuelistComponent } from './fields/valuelist/valuelist.component';
import { SetLabelWrapperComponent } from './wrappers/set-label-wrapper/set-label-wrapper.component';
import { HistoryWrapperComponent } from './wrappers/history-wrapper/history-wrapper.component';
import { AdminVerifyComponent } from './fields/admin-verify/admin-verify.component';
import { NfcComponent } from './fields/nfc/nfc.component';
import { TimepickerComponent } from './fields/timepicker/timepicker.component';
import { ServermessageComponent } from './fields/servermessage/servermessage.component';
import { OpenFormComponent } from './fields/open-form/open-form.component';
import { StepComponent } from './fields/step/step.component';
import { PaintComponent } from './fields/paint/paint.component';
import { PdfviewerComponent } from './fields/pdfviewer/pdfviewer.component';
import { ImageViewerComponent } from './fields/image-viewer/image-viewer.component';
import { BarcodescannerComponent } from './fields/barcodescanner/barcodescanner.component';
import { ItemlistComponent } from './fields/itemlist/itemlist.component';
import { TableComponent } from './fields/table/table.component';
import { SubselectWrapperComponent } from './wrappers/subselect-wrapper/subselect-wrapper.component';
import { DocumentlinkComponent } from './fields/documentlink/documentlink.component';
import { PhotoComponent } from './fields/photo/photo.component';
import { DrawingSelectComponent } from './fields/drawing-select/drawing-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { DisableRadioComponent } from './wrappers/disable-radio/disable-radio.component';
import { InputRefComponent } from './wrappers/input-ref/input-ref.component';
import { OpenSelectComponent } from './wrappers/open-select/open-select.component';
import { StreamComponent } from './fields/stream/stream.component';
import { WatchComponent } from './fields/watch/watch.component';
import { ToggleClassComponent } from './wrappers/toggle-class/toggle-class.component';
import { FocusInputComponent } from './wrappers/focus-input/focus-input.component';
import { ProjectsComponent } from './fields/projects/projects.component';
import { RadioHeaderWrapperComponent } from './wrappers/radio-header-wrapper/radio-header-wrapper.component';
import { InputTimeComponent } from './wrappers/input-time/input-time.component';
import { ScrollWrapperComponent } from './wrappers/scroll-wrapper/scroll-wrapper.component';
import { LastRegFieldComponent } from './fields/last-reg-field/last-reg-field.component';
import { SharedDirectivesModule } from '../directives/shared-directives/shared-directives.module';
import { TextExpandComponent } from './wrappers/text-expand/text-expand.component';
import { SegmentButtonsComponent } from './fields/segment-buttons/segment-buttons.component';
import { NgCalendarModule  } from 'ionic2-calendar';
import { CalendarFieldComponent } from './fields/calendar-field/calendar-field.component';
import { AutocapitalizeComponent } from './wrappers/autocapitalize/autocapitalize.component';
import { D4GoComponent } from './fields/d4-go/d4-go.component';
import { D4SaldiComponent } from './fields/d4-saldi/d4-saldi.component';
import { ChartComponent } from './fields/chart/chart.component';
import { D4TimeComponent } from './fields/d4-time/d4-time.component';
import { StatusComponent } from './fields/status/status.component';
import { DateHelperComponent } from './fields/date-helper/date-helper.component';
import { GestureWrapperComponent } from './wrappers/gesture-wrapper/gesture-wrapper.component';
import { CalendarViewComponent } from './fields/calendar-view/calendar-view.component';
import { VersionCheckComponent } from './fields/version-check/version-check.component';
import { FormlyFieldRadio } from '@ngx-formly/ionic/radio';
import { FormlyFieldInput } from '@ngx-formly/ionic/input';
import { FormlyFieldSelect } from '@ngx-formly/ionic/select';
import { FormlyFieldTextArea } from '@ngx-formly/ionic/textarea';
import { TypeOption, WrapperOption } from '@ngx-formly/core/lib/models';

// !! fieldType must also be added to models/enums/fields.ts. Using the same name in both files.
export const fieldTypes: TypeOption[] = [
  { name: 'radio', component: FormlyFieldRadio, wrappers: ['card-wrapper', 'setLabel-wrapper', 'disable-radio', 'toggle-class', 'radio-header-wrapper', 'scroll-to-field']},
  { name: 'title', component: TitleComponent, wrappers: ['toggle-class', 'scroll-to-field']},
  { name: 'text', component: TextComponent, wrappers: ['toggle-class', 'scroll-to-field'] },
  { name: 'space', component: SpaceComponent, wrappers: ['toggle-class', 'scroll-to-field'] },
  { name: 'image', component: ImageComponent, wrappers: ['toggle-class', 'scroll-to-field'] },
  { name: 'input', component: FormlyFieldInput, wrappers: ['form-field', 'autocapitalize', 'input-ref', 'toggle-class', 'focus-input', 'input-time']},
  { name: 'rating', component: RatingComponent, wrappers: ['card-wrapper', 'required-field', 'toggle-class', 'scroll-to-field'] },
  { name: 'gpsposition', component: GpspositionComponent, wrappers: ['card-wrapper', 'required-field', 'toggle-class', 'scroll-to-field']},
  { name: 'button', component: ButtonComponent, wrappers: ['toggle-class', 'scroll-to-field'] },
  { name: 'sum', component: SumComponent, wrappers: ['card-wrapper', 'toggle-class', 'scroll-to-field']},
  { name: 'projects', component: ProjectsComponent, wrappers: ['card-wrapper', 'toggle-class', 'scroll-to-field']},
  { name: 'user', component: FormlyFieldInput, wrappers: ['form-field', 'user-wrapper', 'toggle-class', 'scroll-to-field']},
  { name: 'infoLookup', component: InfolookupComponent, wrappers: ['card-wrapper', 'toggle-class', 'scroll-to-field'] },
  { name: 'styleForm', component: StyleFormComponent },
  { name: 'signature', component: SignatureComponent, wrappers: ['card-wrapper', 'required-field', 'toggle-class', 'scroll-to-field'] },
  { name: 'timediff', component: TimediffComponent, wrappers: ['card-wrapper', 'toggle-class', 'scroll-to-field'] },
  { name: 'textmerge', component: TextmergeComponent, wrappers: ['card-wrapper', 'toggle-class', 'scroll-to-field'] },
  { name: 'init-input', component: FormlyFieldInput, wrappers: ['form-field', 'input-ref', 'init-wrapper', 'toggle-class', 'scroll-to-field']},
  { name: 'unknown', component: UnknownComponent, wrappers: ['card-wrapper', 'toggle-class']},
  { name: 'lookup', component: ModalselectComponent, wrappers: ['card-wrapper', 'required-field', 'setLabel-wrapper', 'toggle-class']},
  { name: 'modalselect', component: ModalselectComponent, wrappers: ['card-wrapper', 'required-field', 'setLabel-wrapper', 'toggle-class']},
  { name: 'webLookup', component: ModalselectComponent, wrappers: ['card-wrapper', 'required-field', 'setLabel-wrapper', 'toggle-class']},
  { name: 'serverQueryModal', component: ModalselectComponent, wrappers: ['card-wrapper', 'required-field', 'setLabel-wrapper', 'toggle-class']},
  { name: 'valuelist', component: ValuelistComponent, wrappers: ['card-wrapper', 'required-field', 'toggle-class', 'scroll-to-field']},
  { name: 'historyselect', component: FormlyFieldSelect, wrappers: ['form-field', 'history-wrapper', 'setLabel-wrapper', 'open-select', 'toggle-class']},
  { name: 'adm_verify', component: AdminVerifyComponent},
  { name: 'nfc', component: NfcComponent, wrappers: ['card-wrapper', 'required-field', 'toggle-class', 'scroll-to-field']},
  { name: 'timepicker', component: TimepickerComponent, wrappers: ['card-wrapper', 'required-field', 'toggle-class']},
  { name: 'servermessage', component: ServermessageComponent, wrappers: ['toggle-class', 'scroll-to-field']},
  { name: 'openForm', component: OpenFormComponent, wrappers: ['toggle-class', 'scroll-to-field']},
  { name: 'step', component: StepComponent, wrappers: ['toggle-class']},
  { name: 'paint', component: PaintComponent, wrappers: ['card-wrapper', 'required-field', 'toggle-class', 'scroll-to-field']},
  { name: 'pdfModal', component: PdfviewerComponent, wrappers: ['toggle-class', 'scroll-to-field']},
  { name: 'imageModal', component: ImageViewerComponent, wrappers: ['toggle-class', 'scroll-to-field']},
  { name: 'barcodescanner', component: BarcodescannerComponent, wrappers: ['card-wrapper', 'required-field', 'toggle-class', 'scroll-to-field']},
  { name: 'itemlist', component: ItemlistComponent, wrappers: ['card-wrapper', 'required-field', 'toggle-class', 'scroll-to-field']},
  { name: 'table', component: TableComponent, wrappers: ['card-wrapper', 'toggle-class', 'scroll-to-field']},
  { name: 'subselect', component: FormlyFieldSelect, wrappers: ['form-field', 'subselect-wrapper', 'setLabel-wrapper', 'open-select', 'toggle-class']},
  { name: 'select', component: FormlyFieldSelect, wrappers: ['form-field', 'setLabel-wrapper', 'open-select', 'toggle-class']},
  { name: 'documentLink', component: DocumentlinkComponent, wrappers: ['card-wrapper', 'toggle-class', 'scroll-to-field']},
  { name: 'photo', component: PhotoComponent, wrappers: ['card-wrapper', 'required-field', 'toggle-class', 'scroll-to-field']},
  { name: 'drawingSelect', component: DrawingSelectComponent, wrappers: ['card-wrapper', 'toggle-class', 'scroll-to-field']},
  { name: 'stream', component: StreamComponent, wrappers: ['card-wrapper', 'toggle-class', 'scroll-to-field']},
  { name: 'watch', component: WatchComponent},
  { name: 'lastReg', component: LastRegFieldComponent},
  { name: 'textarea', component: FormlyFieldTextArea, wrappers: ['form-field', 'text-expand', 'autocapitalize']},
  { name: 'segmentButtons', component: SegmentButtonsComponent, wrappers: ['card-wrapper', 'toggle-class', 'scroll-to-field']},
  { name: 'calendar', component: CalendarFieldComponent, wrappers: ['card-wrapper']},
  { name: 'd4Go', component: D4GoComponent, wrappers: ['card-wrapper']},
  { name: 'd4Saldi', component: D4SaldiComponent, wrappers: ['card-wrapper']},
  { name: 'chart', component: ChartComponent, wrappers: ['card-wrapper']},
  { name: 'd4Time', component: D4TimeComponent, wrappers: ['card-wrapper']},
  { name: 'status', component: StatusComponent, wrappers: ['card-wrapper']},
  { name: 'dateHelper', component: DateHelperComponent, wrappers: ['card-wrapper', 'gesture-wrapper']},
  { name: 'calendarView', component: CalendarViewComponent, wrappers: ['card-wrapper', 'gesture-wrapper']},
  { name: 'versionCheck', component: VersionCheckComponent, wrappers: ['card-wrapper']}
];

export const wrappers: WrapperOption[] = [
  { name: 'required-field', component: RequiredWrapperComponent },
  { name: 'user-wrapper', component: UserWrapperComponent},
  { name: 'card-wrapper', component: CardWrapperComponent},
  { name: 'form-field', component: LabelWrapperComponent},
  { name: 'init-wrapper', component: InitWrapperComponent},
  { name: 'setLabel-wrapper', component: SetLabelWrapperComponent},
  { name: 'history-wrapper', component: HistoryWrapperComponent},
  { name: 'subselect-wrapper', component: SubselectWrapperComponent},
  { name: 'disable-radio', component: DisableRadioComponent},
  { name: 'input-ref', component: InputRefComponent},
  { name: 'open-select', component: OpenSelectComponent},
  { name: 'toggle-class', component: ToggleClassComponent},
  { name: 'radio-header-wrapper', component: RadioHeaderWrapperComponent},
  { name: 'focus-input', component: FocusInputComponent},
  { name: 'input-time', component: InputTimeComponent},
  { name: 'scroll-to-field', component: ScrollWrapperComponent},
  { name: 'text-expand', component: TextExpandComponent},
  { name: 'autocapitalize', component: AutocapitalizeComponent},
  { name: 'gesture-wrapper', component: GestureWrapperComponent}
];

@NgModule({
  declarations: [
    TextComponent,
    TitleComponent,
    SpaceComponent,
    ImageComponent,
    RatingComponent,
    GpspositionComponent,
    ButtonComponent,
    SumComponent,
    RequiredWrapperComponent,
    RadioHeaderWrapperComponent,
    UserWrapperComponent,
    InfolookupComponent,
    StyleFormComponent,
    SignatureComponent,
    CardWrapperComponent,
    LabelWrapperComponent,
    TimediffComponent,
    TextmergeComponent,
    InitWrapperComponent,
    UnknownComponent,
    ValuelistComponent,
    SetLabelWrapperComponent,
    HistoryWrapperComponent,
    AdminVerifyComponent,
    NfcComponent,
    TimepickerComponent,
    ServermessageComponent,
    OpenFormComponent,
    StepComponent,
    PaintComponent,
    ModalselectComponent,
    PdfviewerComponent,
    ImageViewerComponent,
    BarcodescannerComponent,
    ItemlistComponent,
    TableComponent,
    SubselectWrapperComponent,
    DocumentlinkComponent,
    PhotoComponent,
    DrawingSelectComponent,
    DisableRadioComponent,
    InputRefComponent,
    OpenSelectComponent,
    StreamComponent,
    WatchComponent,
    ToggleClassComponent,
    FocusInputComponent,
    ProjectsComponent,
    InputTimeComponent,
    ScrollWrapperComponent,
    LastRegFieldComponent,
    TextExpandComponent,
    SegmentButtonsComponent,
    CalendarFieldComponent,
    AutocapitalizeComponent,
    D4GoComponent,
    D4SaldiComponent,
    ChartComponent,
    D4TimeComponent,
    StatusComponent,
    DateHelperComponent,
    GestureWrapperComponent,
    CalendarViewComponent,
    VersionCheckComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    FormlyModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MarkdownModule.forRoot(environment.markdownConfig),
    AngularSignaturePadModule,
    IonicSelectableModule,
    LoggerModule.forChild(),
    SharedDirectivesModule,
    NgCalendarModule
  ],
  providers: [
    Geolocation,
    NFC
  ]
})
export class CustomFormlyModule { }
