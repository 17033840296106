import {Pipe, PipeTransform} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'timeAgo',
    pure: true
})
export class TimeAgoPipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ){}

    transform(value: any, args?: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

            if (seconds < 10) // less than 10 seconds ago will show as 'Just now'
                return this.translate.instant('TimeAgoJustNow');
            const intervals = {
                TimeAgoYear: 31536000,
                TimeAgoMonth: 2592000,
                TimeAgoWeek: 604800,
                TimeAgoDay: 86400,
                TimeAgoHour: 3600,
                TimeAgoMinute: 60,
                TimeAgoSecond: 1
            };
            let counter: number;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);

                if (counter > 0) {
                  return counter + ' ' + this.translate.instant(i) + ' ' + this.translate.instant('TimeAgoSingular'); // singular (1 day ago)
                  /*
                  // not in use
                    if (counter === 1) {
                        return counter + ' ' + this.translate.instant(i) + ' ' + this.translate.instant('TimeAgoSingular'); // singular (1 day ago)
                    } else {
                      let type = this.translate.instant(i);
//                      if(this.translate.currentLang != 'en') // wrong ending on plural on swe and nor (time, timeer).
//                        type = type.substring(0, type.length -1);
                      return counter + ' ' + type + this.translate.instant('TimeAgoPlural');; // plural (2 days ago)
                    }
                    */
                }
            }
        }
        return value;
    }

}
