<ion-header>
  <ion-toolbar>
    <ion-title>{{'SelectPaint' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="front-background">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label>{{'Field' | translate}}</ion-label>
          <ion-select [(ngModel)]="selectedPaint">
            <ion-select-option *ngFor="let field of paintfields" value="{{field.key}}">{{field.name}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6">
        <ion-button color="secondary" expand="block" (click)="closePopover(false)">{{'Cancel' | translate}}</ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button color="primary" expand="block" (click)="closePopover(true)" [disabled]="!selectedPaint">{{'Set' | translate}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>