import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { AlertController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { FieldService } from 'src/app/services/field.service';
import { PopupService } from 'src/app/services/popup.service';
import { Subscription } from 'rxjs';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { Trigger, TriggerType, IonColor, FormType } from 'src/app/models/models';
import { ValidationService } from 'src/app/services/validation.service';
import { DeviceService } from 'src/app/services/device.service';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

@Component({
  selector: 'app-field-barcodescanner',
  templateUrl: './barcodescanner.component.html',
  styleUrls: ['./barcodescanner.component.scss'],
  providers: [FieldService]
})
export class BarcodescannerComponent extends FieldType implements AfterViewInit, Trigger, OnDestroy {
  @ViewChild('itemTable') itemTable: ElementRef;
  disableButton = false;
  items: {input: string, data: string}[] = [];
  backButtonSubscription: Subscription;
  scannerActive = false;
  private hasPermission: boolean;

  constructor(
    private device: DeviceService,
    private logger: NGXLogger,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private fieldService: FieldService,
    private popup: PopupService,
    private ref: ComponentRefService,
    private validation: ValidationService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }

  get multiscan(): boolean {
    return this.to.multiscan || false;
  }

  get popupText(): string {
    return this.to.popupText || '';
  }

  get popupInputType(): 'text' | 'number' | 'url' | 'email' | 'date' | 'time' | 'tel' {
    switch (this.to.popupInputType) {
      case 'number':
      case 'url':
      case 'email':
      case 'date':
      case 'time':
      case 'tel':
        return this.to.popupInputType;
      default:
        return 'text';
    }
  }

  get autotrigger(): boolean {
    return this.disabled ? false : this.to.autotrigger ?? false;
  }

  get json(): boolean {
    return this.to.json || false;
  }

  get splitItem(): string {
    return this.to.splitItem || ';';
  }

  get splitInput(): string {
    return this.to.splitInput || '|';
  }

  get disabled(): boolean {
    return this.to.disabled ?? false;
  }

  get disableInput(): boolean {
    return this.to.disableInput ?? false;
  }

  get color(): string {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Primary;
  }

  get allowEmptyInput(): boolean {
    return this.to.allowEmptyInput ?? false;
  }

  get singleInput(): boolean {
    return this.to.singleInput ?? false;
  }

  get currentFormId(): number {
    return this.to.currentForm.id;
  }

  get currentFormType(): FormType {
    return this.to.currentForm.type;
  }

  get isDevice(): boolean {
    return this.device.isDevice();
  }

  public static getHtml(config: FormlyFieldConfig, value: any) {
    const label = config.templateOptions.label ?? '';
    const borderColor = getComputedStyle(document.documentElement).getPropertyValue(`--ion-color-step-300`);
    if (config.templateOptions.multiscan) {
      let html = `<ion-card><ion-card-header>${label}</ion-card-header>`;
      if (config.templateOptions.json && Array.isArray(value)) {
        html += '<table class="fullsize valuelist">';
        for (const obj of value) {
          html += `<tr><td>${obj.data}</td><td>${obj.input}</td></tr>`;
        }
        html += '</table>';
      }
      else if (!config.templateOptions.json && typeof value === 'string' && value !== '') {
        const splitItem = config.templateOptions.splitItem || ';';
        const splitInput = config.templateOptions.splitInput || '|';
        const items = value.split(splitItem);
        html += '<table class="fullsize valuelist">';
        for (const item of items) {
          const parts = item.split(splitInput);
          html += `<tr><td>${parts[0]}</td><td>${parts[1]}</td></tr>`;
        }
        html += '</table>';
      }
      html += '</ion-card>';
      return html;
    }
    else {
      return `<ion-card><ion-list>
                <ion-item lines="none">
                  <ion-label class="ion-text-wrap">${label}</ion-label>
                </ion-item>
                <ion-item lines="none">
                  <ion-button color="primary" slot="start" fill="clear" disabled="true">
                    <ion-icon slot="icon-only" name="pulse"></ion-icon>
                  </ion-button>
                  <ion-input value="${value}" type="text" style="border-bottom: 1px solid ${borderColor}"></ion-input>
                </ion-item>
              </ion-list></ion-card>`;

    }
  }

  ngAfterViewInit() {
    this.setReference(this.key as string, this.currentFormId, this.currentFormType);
    setTimeout(() => {
      if (this.to.disabled) {
        this.disableButton = true;
      }
      if (this.disableInput) {
        this.formControl.disable();
      }
      if (!this.to.disabled && environment.production && !this.isDevice) {
        this.to.placeholder = this.translate.instant('NotOnMobile');
        this.to.multiscan = false;
        this.to.disabled = true;
        this.disableButton = true;
      }
    });
    if (this.autotrigger) {
      this.startScan();
    }
    if (this.multiscan && this.model[this.key as string | number]) {
      this.rebuildFromModel();
    }
  }

  ngOnDestroy() {
    this.stopScan();
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Clear) {
      if (this.multiscan) {
        this.clearField();
      }
    }
    else if (type === TriggerType.Rebuild) {
      if (this.multiscan) {
        this.clearField();
        this.rebuildFromModel();
      }
    }
    else if (type === TriggerType.StopScan) {
      this.stopScan();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  clearField() {
    this.items = [];
    this.itemTable.nativeElement.innerHtml = '';
  }

  startScan(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.multiscan) {
      this.doMultiScan();
    }
    else {
      this.doSingleScan();
    }
  }

  async scan(): Promise<string> {
    let result: string;
    if (!this.isDevice) {
      this.logger.warn('Barcode scanner only works on mobile devices');
      result = (+(Math.random() * 64).toFixed(0)).toString(2); // test data
    }
    else {
      try {
        await this.setScannerActive();
        const data = await BarcodeScanner.startScan();
        result = data.hasContent ? data.content : this.translate.instant('ScanError');
        await this.stopScannerActive();
      }
      catch (error) {
        this.logger.error('Error doing scan', error);
        result = this.translate.instant('ScanError');
      }
    }
    return result;
  }

  async doSingleScan() {
    const data = await this.scan();
    this.logger.debug('Barcode data', data);
    if (data) {
      this.fieldService.setValue(data);
    }
  }

  async doMultiScan() {
    const scanData = await this.scan();
    this.logger.debug('Barcode data', scanData);
    if (!scanData) return;

    let canceled = false;
    const trans = this.translate.instant(['Cancel', 'Confirm']);
    const alert = await this.alertCtrl.create({
      header: scanData,
      inputs: [
        {
          name: 'input',
          type: this.popupInputType,
          placeholder: this.popupText
        }
      ],
      buttons: [
        {
          text: trans['Cancel'],
          role: 'cancel',
          handler: () => {
            canceled = true;
          }
        },
        {
          text: trans['Confirm']
        }
      ]
    });
    await alert.present();
    const {data} = await alert.onWillDismiss();
    if (!canceled && data && data.values) {
      const input = data.values.input;
      this.logger.debug(input);
      if ((typeof input === 'undefined' || input === null || input === '') && !this.allowEmptyInput) {
        let text: string;
        if (this.popupText) {
          text = this.translate.instant('NoBarcodeInputWithText', {text: this.popupText});
        }
        else {
          text = this.translate.instant('NoBarcodeInput');
        }
        this.popup.showMessage(text, false, 'warning', 2000);
      }
      else {
        this.addToModel(scanData, input);
        if (!this.singleInput) {
          this.doMultiScan();
        }
      }
    }
  }

  addToModel(data: string, input: string) {
    if (this.json) {
      const item = {input: input, data: data};
      this.items.push(item);
      this.fieldService.setValue(this.items);
      this.addToView(item);
    }
    else {
      const item = data + this.splitInput + input;
      let newModel = item;
      if (this.model[this.key as string | number]) {
        newModel = this.model[this.key as string | number] + this.splitItem + item;
      }
      this.fieldService.setValue(newModel);
      this.addToView(item);
    }
  }

  addToView(item: string | {input: string, data: string}) {
    const index = this.itemTable.nativeElement.rowIndex;
    this.logger.debug(index);
    const row = this.itemTable.nativeElement.insertRow(index);
    row.class = 'barcodeItem';
    const that = this;
    row.onclick = function() {
      that.removeItem(this);
    };
    // Insert new cells (<td> elements) at the 1st and 2nd position of the "new" <tr> element:
    const cell1 = row.insertCell(0);
    const cell2 = row.insertCell(1);
    if (typeof item === 'string') {
      const t = item.split(this.splitInput);
      cell1.innerHTML = t[0];
      cell2.innerHTML = t[1];
    }
    else {
      cell1.innerHTML = item.data;
      cell2.innerHTML = item.input;
    }

  }

  removeItem(element: HTMLTableRowElement) {
    if (this.to.disabled) return;

    const parent = element.parentNode;
    // The equivalent of parent.children.indexOf(child)
    const index = Array.prototype.indexOf.call(parent.children, element);
    this.logger.debug('Index ' + index);
    element.className = 'picked';
    const vals = `<b>${element.cells[0].innerHTML}:</b> ${element.cells[1].innerHTML}`;
    this.translate.get('Remove').subscribe(async remove => {
      const header = `${remove}?`;
      const message = `<span>${vals}</span>`;
      const that = this;
      const confirmed = await this.popup.showConfirm(header, message, false);
      if (confirmed) {
        that.itemTable.nativeElement.deleteRow(index);
        that.removeElementFromModel(index);
      }
    });
  }

  removeElementFromModel(index: number) {
    this.logger.debug('Remove from model called');

    let value: string | any[];
    if (this.json) {
      this.items.splice(index, 1);
      value = this.items;
    }
    else {
      const arr = this.model[this.key as string | number].split(this.splitItem);
      if (index > -1) {
        arr.splice(index, 1);
      }
      value = arr.join(this.splitItem);
    }
    this.fieldService.setValue(value);
  }

  rebuildFromModel() {
    if (this.json) {
      this.items = this.model[this.key as string | number];
      for (const item of this.items) {
        this.addToView(item);
      }
    }
    else {
      const items: string[] = this.model[this.key as string | number].split(this.splitItem);
      for (const item of items) {
        this.addToView(item);
      }
    }
  }

  private async setScannerActive() {
    const hasPermission = await this.checkForPermission();
    if (!hasPermission) {
      return;
    }
    if (this.isDevice) {
      await BarcodeScanner.hideBackground();
    }
    this.scannerActive = true;
    document.querySelector('body').classList.add('scanner-active');
    this.device.openedBarcodeScanner(this.key as string, this.currentFormType, this.currentFormId);
  }

  private async stopScannerActive() {
    if (this.isDevice) {
      await BarcodeScanner.showBackground();
    }
    this.scannerActive = false;
    document.querySelector('body').classList.remove('scanner-active');
    this.device.closedBarcodeScanner();
  }

  private stopScan() {
    this.stopScannerActive();
    if (this.isDevice) {
      BarcodeScanner.stopScan();
    }
  }

  private async checkForPermission(): Promise<boolean> {
    if (this.hasPermission) {
      return this.hasPermission;
    }

    const status = await BarcodeScanner.checkPermission({force: true});
    if (status.granted) {
      this.hasPermission = true;
      return true;
    }

    if (status.denied && !status.asked) {
      const confirmed = await this.popup.showConfirm('NoCameraPermission', 'CameraOpenAppSettings', true);
      if (confirmed) {
        BarcodeScanner.openAppSettings();
      }
      this.hasPermission = false;
      return false;
    }

    if (status.restricted || status.unknown) {
      this.hasPermission = false;
      return false;
    }

    this.hasPermission = false;
    return false;
  }
 }
