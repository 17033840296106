import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { StoredNotification } from 'src/app/models/models';
import { NotificationsService } from 'src/app/services/notifications.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss'],
})
export class NotificationsModalComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    private notificationService: NotificationsService,
    private popup: PopupService,
    private translate: TranslateService,
    private logger: NGXLogger
  ) { }

  get notifications(): StoredNotification[] {
    return this.notificationService.notifications;
  }

  ngOnInit() {
    this.notificationService.markMessagesAsOpened();
    this.notificationService.checkVersionOfUpdates();
  }

  closeModal() {
    this.modalCtrl.dismiss().then(
      () => this.logger.debug('Successfully dismissed notifications modal'),
      (err) => this.logger.warn('Failed dismissing notifications modal', err)
    );
  }

  handleClick(id: string) {
    const notification = this.notifications.find(n => n.id === id);
    if (notification?.doAction) {
      notification.doAction();
    }
  }

  async deleteNotification(id: string) {
    const notificationTitle = this.notifications.find(n => n.id === id)?.title;
    if (notificationTitle) {
      const translations = this.translate.instant(['DeleteNotification', 'DeleteNotificationMsg'], {title: notificationTitle});
      const confirmed = await this.popup.showConfirm(translations['DeleteNotification'], translations['DeleteNotificationMsg'], false);
      if (confirmed) {
        this.notificationService.removeNotification(id);
      }
    }
  }
}
