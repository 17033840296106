import { Injectable } from '@angular/core';

import { FirebaseEvent, FirebaseUserProperties, InThemes, LinkSource, OnlineStatus } from '../models/models';
import { NGXLogger } from 'ngx-logger';
import { UtilityService } from './utility.service';
import { DeviceService } from './device.service';
import { FirebaseService } from './firebase.service';
import { environment } from 'src/environments/environment';

/**
 * Service that handles logging and statistics to Firebase
 */
@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  openedForms: {id: number, time: number}[] = [];
  openedRegs: {regId: number, time: number}[] = [];
  userProps: FirebaseUserProperties;
  hasSetId = false;
  logQueue: {event: string, data: any}[] = [];
  wentOffline: number;

  constructor(
    private device: DeviceService,
    private logger: NGXLogger,
    private util: UtilityService,
    private firebase: FirebaseService
  ) { }

  get buildEnvironment(): string {
    return environment.production ? 'prod' : 'dev';
  }

  get deviceType(): string {
    return this.device.getType().toLowerCase();
  }

  /**
   * Log event to firebase analytics
   * @param event The event name
   * @param data (Optional) Event data
   */
  logEvent(event: FirebaseEvent, data: any = {}) {
    if (!Object.values(FirebaseEvent).includes(event)) {
      return;
    }
    data.environment = this.buildEnvironment;
    data.device = this.deviceType;
    data.version = environment.appVersion;
    if (this.hasSetId) {
      this.firebase.logEvent(event, data);
    }
    else {
      this.logQueue.push({event, data});
    }
  }

  /**
   * Init analytics with user properties
   * @param theme The theme for the user
   * @param language The language for the user
   */
  initUserProps(theme: string, language: string) {
    if (!this.validTheme(theme)) {
      this.logger.warn(`Illegal theme for statistics: '${theme}'`);
      theme = 'light';
    }
    if (!this.validLanguage(language)) {
      this.logger.warn(`Illegal language for statistics: '${language}'`);
      language = 'en';
    }
    this.userProps = {
      app_version: environment.appVersion,
      device_type: this.deviceType,
      environment: this.buildEnvironment,
      theme: theme,
      language: language
    };
    if (this.hasSetId) {
      this.firebase.setUserProperties(this.userProps);
    }
  }

  /**
   * Set Id of user for statistics purposes
   * @param tenant The tenant of the user
   * @param username Username of the user
   */
  async setUserId(tenant: string, username: string) {
    // const id = btoa(`${tenant}:${username}`);
    const id = this.util.hashString(`${tenant}:${username}`);
    await this.firebase.setUserId(id);

    this.hasSetId = true;
    if (this.userProps) {
      await this.firebase.setUserProperties(this.userProps);
    }
    while (this.logQueue.length > 0) {
      const log = this.logQueue.shift();
      this.firebase.logEvent(log.event, log.data);
    }
  }

  /**
   * Update user properties with new theme
   * @param theme The new theme
   */
  changeTheme(theme: string) {
    if (!this.userProps || !this.validTheme(theme)) {
      return;
    }
    this.userProps.theme = theme;
    this.logEvent(FirebaseEvent.ChangeTheme, {theme: theme});
    if (this.hasSetId) {
      this.firebase.setUserProperties(this.userProps);
    }
  }

  /**
   * Update user properties with new language
   * @param lang The new language
   */
  changeLanguage(lang: string) {
    if (!this.userProps || !this.validLanguage(lang)) {
      return;
    }
    this.userProps.language = lang;
    this.logEvent(FirebaseEvent.ChangeLanguage, {lang: lang});
    if (this.hasSetId) {
      this.firebase.setUserProperties(this.userProps);
    }
  }

  /**
   * Log event that the app went offline
   *
   * **Important**: Will only log if the app isn't already offline
   */
  logOffline(status: OnlineStatus) {
    if (typeof this.wentOffline === 'undefined' || this.wentOffline < 0) {
      this.logEvent(FirebaseEvent.Offline, {status});
      this.wentOffline = Date.now();
    }
  }

  /**
   * Log event that the app went online and how long the app was offline
   *
   * **Important**: Will only calculate duration if the app has logged offline
   */
  logOnline() {
    let diff = 0;
    if (this.wentOffline >= 0) {
      diff = Math.round((Date.now() - this.wentOffline) / 1000);
      this.wentOffline = -1;
    }
    this.logEvent(FirebaseEvent.Online, {duration: diff});
  }

  /**
   * Log to firebase analytics that an form was opened
   * @param formId The formId
   * @param origin From where the form was opened
   */
  openForm(formId: number, origin: LinkSource) {
    this.logEvent(FirebaseEvent.OpenForm, {id: formId, form_origin: origin});
    const index = this.openedForms.findIndex(f => f.id === formId);
    if (index === -1) {
      this.openedForms.push({
        id: formId,
        time: Date.now()
      });
    }
    else {
      this.openedForms[index].time = Date.now();
    }
  }

  /**
   * Log to firebase that an form was closed an how long it was opened
   * **IMPORTANT:** Must be called after openForm
   * @param formId The formId
   */
  closedForm(formId: number) {
    const diff = this.findTimeDiffAndRemoveForm(formId);
    if (diff !== -1) {
      this.logEvent(FirebaseEvent.CloseForm, {time: diff, id: formId});
    }
  }

  /**
   * Log to firebase that an form was registered an how long it was opened
   * **IMPORTANT:** Must be called after openForm
   * @param formId The formId
   */
  didRegistration(formId: number) {
    const diff = this.findTimeDiffAndRemoveForm(formId);
    if (diff !== -1) {
      this.logEvent(FirebaseEvent.FinishForm, {time: diff, id: formId});
    }
  }

  /**
   * Log to firebase that an form was tmp stored an how long it was opened
   * **IMPORTANT:** Must be called after openForm
   * @param formId The formId
   */
  tmpStore(formId: number) {
    const diff = this.findTimeDiffAndRemoveForm(formId);
    if (diff !== -1) {
      this.logEvent(FirebaseEvent.TmpStore, {time: diff, id: formId});
    }
  }

  /**
   * Log to firebase analytics that an registration was opened
   * @param formId The formId
   * @param regId The regId
   * @param origin From where the registration was opened
   */
  openedReg(formId: number, regId: number, origin: LinkSource) {
    this.logEvent(FirebaseEvent.OpenReg, {formId: formId, regId: regId, reg_origin: origin});
    const index = this.openedRegs.findIndex(r => r.regId === regId);
    if (index === -1) {
      this.openedRegs.push({
        regId: regId,
        time: Date.now()
      });
    }
    else {
      this.openedRegs[index].time = Date.now();
    }
  }

  /**
   * Log to firebase that an registration was closed an how long it was opened
   * **IMPORTANT:** Must be called after openedReg
   * @param formId The formId
   * @param regId The regId
   */
  closedReg(formId: number, regId: number) {
    const diff = this.findTimeDiffAndRemoveReg(regId);
    if (diff !== -1) {
      this.logEvent(FirebaseEvent.CloseReg, {time: diff, formId: formId, regId: regId});
    }
  }

  /**
   * Find an opened form, calculate time since it was opened and then remove it
   * @param formId The id of the form
   */
  private findTimeDiffAndRemoveForm(formId: number) {
    const index = this.openedForms.findIndex(f => f.id === formId);
    if (index !== -1) {
      const now = Date.now();
      let diff = now - this.openedForms[index].time;
      diff = Math.round(diff / 1000);
      this.openedForms.splice(index, 1);
      return diff;
    }
    else {
      return -1;
    }
  }

  /**
   * Find an opened form, calculate time since it was opened and then remove it
   * @param regId The id of the registration
   */
  private findTimeDiffAndRemoveReg(regId: number) {
    const index = this.openedRegs.findIndex(r => r.regId === regId);
    if (index !== -1) {
      const now = Date.now();
      let diff = now - this.openedRegs[index].time;
      diff = Math.round(diff / 1000);
      this.openedRegs.splice(index, 1);
      return diff;
    }
    else {
      return -1;
    }
  }

  /**
   * Check if an theme is valid
   * @param theme The theme to check
   */
  private validTheme(theme: string): boolean {
    return InThemes.map(t => t.value).includes(theme);
  }

  /**
   * Check if an langauge is valid
   * @param language The language to check
   */
  private validLanguage(language: string): boolean {
    return language === 'en' || language === 'no' || language === 'sv';
  }
}
