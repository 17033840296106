import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Platform, AlertController } from '@ionic/angular';
import { LoadingService } from './loading.service';
import { Router } from '@angular/router';
import { InStorageService } from './in-storage.service';
import { NGXLogger } from 'ngx-logger';
import { OnlineService } from './online.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from './popup.service';
import { StateService } from './state.service';
import { environment } from 'src/environments/environment';
import { StatisticsService } from './statistics.service';
import { LegacyService } from './legacy.service';
import { AuthenticationState, ApiEnvironment, ApiStatus, SignInData, NewReg, SortBy, FirebaseEvent, User } from '../models/models';
import { mergeMap } from 'rxjs/operators';
import { InLoggerService } from './in-logger.service';
import { DeviceService } from './device.service';
import { NotificationsService } from './notifications.service';
import { BrowserService } from './browser.service';

/**Handling authentication */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authenticationState = new BehaviorSubject<AuthenticationState>(AuthenticationState.NotSet);
  orgRoute: string;
  formData: any;
  constructor(
    private api: ApiService,
    private storage: InStorageService,
    private plt: Platform,
    private loading: LoadingService,
    private router: Router,
    private logger: NGXLogger,
    private online: OnlineService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private popup: PopupService,
    private stateService: StateService,
    private statistics: StatisticsService,
    private legacy: LegacyService,
    private inLogger: InLoggerService,
    private device: DeviceService,
    private notifications: NotificationsService,
    private browser: BrowserService
  ) {
    this.plt.ready().then(() => {
      const url: string = this.plt.url();
      this.orgRoute = this.getUrlPath(url);
      this.formData = this.getFormData(url);
      if (!url.includes('preview') && !url.includes('showreg')) {
        this.checkUser(url);
      }
    }, error => {
      this.logger.error('Error checking for platform ready', error);
    });
  }

  /**
   * Login user from storage to refresh/get new session-cookie.
   * Logic in login-call will throw user out if not correct.
   */
  async refreshSession() {
    const user = await this.checkStorage();
    return this.login(user?.tenant || '', user?.username || '', user?.password || '');
  }

  /**
   * Checks if there is an user in the storage and login if it exists
   */
  async checkUser(url: string) {
    let user = await this.checkUrl(url);
    if (user) {
      this.login(user.tenant, user.username, user.password).subscribe();
      return;
    }

    user = await this.checkStorage();
    if (user) {
      const authState = await this.offlineAuth(user.tenant, user.username, {}, true, true);
      this.authenticationState.next(authState);
      this.login(user.tenant, user.username, user.password, null, true).subscribe();
    }
    else {
      this.authenticationState.next(AuthenticationState.NotAuthenticated);
    }
  }

  /**
   * Signs an user in
   * @param tenant The tenant of the user
   * @param username The username of the user
   * @param password The password of the user
   * @param loadingId (Optional) Id of loader to dismiss when finished
   */
  login(tenant: string, username: string, password: string, loadingId?: number, isStored: boolean = false): Observable<boolean> {
      return this.api.signIn(tenant, username, password).pipe(
        mergeMap(async ({value: data, status}) => {
          let authState: AuthenticationState;
          if (status === ApiStatus.Success) {
            authState = await this.onlineAuth(tenant, username, data);
          }
          else if (status === ApiStatus.Offline) {
            authState = await this.offlineAuth(tenant, username, data, false, true);
          }
          else {
            let newData: SignInData;
            if (isStored) {
              newData = await this.tryNewPassword(tenant, username);
            }
            if (newData) {
              authState = await this.onlineAuth(tenant, username, newData);
            }
            else {
              authState = AuthenticationState.NotAuthenticated;
              this.storage.removeUser();
            }
          }

          if (loadingId) {
            this.loading.dismissLoader(loadingId);
          }
          this.authenticationState.next(authState);
          return status !== ApiStatus.Failed;
        })
      );
  }

  /**
   * Sign in using API, when user only have been authenticated offline. Returns true if it succeeded
   */
  async signInFromOffline(): Promise<boolean> {
    if (this.authenticationState.value !== AuthenticationState.OfflineAuthenticated) return true;

    const user = await this.storage.getUser();
    if (!user || !user.tenant || !user.username) {
      this.authenticationState.next(AuthenticationState.NotAuthenticated);
      this.online.goOnline();
      return false;
    }
    const password = await this.getPassword(user.tenant, user.username);
    if (!password) {
      return false;
    }

    const orgStatus = this.online.currentStatus;
    try {
      this.online.goOnline();
      const result = await firstValueFrom(this.api.signIn(user.tenant, user.username, password), {defaultValue: {value: {}, status: ApiStatus.Failed}});
      if (result.status !== ApiStatus.Success) {
        this.popup.showMessage('FailedLogin', true);
        this.online.goOffline(orgStatus);
        return false;
      }
      else {
        this.statistics.setUserId(user.tenant, user.username);
        this.notifications.init();
        this.authenticationState.next(AuthenticationState.Authenticated);
        return true;
      }
    }
    catch {
      this.popup.showMessage('FailedLogin', true);
      this.online.goOffline(orgStatus);
      return false;
    }
  }

  /**
   * Signs an user out
   */
  async logout() {
    await this.notifications.stop();
    this.api.signOut().subscribe();
    await this.storage.clearStorage();
    this.stateService.clearState();
    this.orgRoute = null;
    this.authenticationState.next(AuthenticationState.NotAuthenticated);
  }

  /**
   * Checks if there is an authenticated user
   * @param state (Optional) The state to check. Will use the current state if not given
   */
  isAuthenticated(state?: AuthenticationState): boolean {
    if (typeof state === 'undefined') {
      state = this.authenticationState.value;
    }
    switch (state) {
      case AuthenticationState.Authenticated:
      case AuthenticationState.OfflineAuthenticated:
      case AuthenticationState.NewUser:
      case AuthenticationState.InitAuthenticated:
        return true;
      default:
        return false;
    }
  }

  /**
   * Checks if the user is only authenticated offline
   */
  isOfflineAuth() {
    return this.authenticationState.value === AuthenticationState.OfflineAuthenticated;
  }

  isInitAuth() {
    return this.authenticationState.value === AuthenticationState.InitAuthenticated;
  }

  /**
   * Wait for authentication to finish
   */
  waitForAuth(): Promise<boolean> {
    if (this.isInitAuth()) {
      return new Promise(resolve => {
        this.authenticationState.subscribe((state) => {
          if (state === AuthenticationState.NewUser) {
            resolve(false);
          }
          else if (!this.isInitAuth()) {
            resolve(true);
          }
        });
      });
    }
    else {
      return Promise.resolve(this.authenticationState.value !== AuthenticationState.InitAuthenticated);
    }
  }

  private async tryNewPassword(tenant: string, username: string): Promise<SignInData> {
    for (let i = 0; i < 3; i++) {
      const password = await this.getPassword(tenant, username);
      if (password) {
        try {
          const result = await firstValueFrom(this.api.signIn(tenant, username, password), {defaultValue: {value: {}, status: ApiStatus.Failed}});
          if (result.status !== ApiStatus.Success) {
            this.popup.showMessage('FailedLogin', true);
          }
          else {
            return result.value;
          }
        }
        catch {
          this.popup.showMessage('FailedLogin', true);
        }
      }
    }
    return null;
  }

  private async getPassword(tenant: string, username: string): Promise<string> {
    const trans = this.translate.instant(['EnterPassword', 'EnterPasswordSub', 'EnterPasswordMsg', 'Cancel', 'Confirm'], {user: username, tenant: tenant});
    const alert = await this.alertCtrl.create({
      header: trans['EnterPassword'],
      subHeader: trans['EnterPasswordSub'],
      message: trans['EnterPasswordMsg'],
      inputs: [
        {
          type: 'password',
          name: 'password'
        }
      ],
      buttons: [
        {
          text: trans['Cancel'],
          role: 'cancel'
        },
        {
          text: trans['Confirm']
        }
      ]
    });
    await alert.present();
    const {data} = await alert.onWillDismiss();
    return data?.values?.password;
  }

  /**
   * Check storage for stored user for login
   * @returns An user object
   */
  private async checkStorage(): Promise<User> {
    await this.legacy.getAndStoreLocalUser();

    const user = await this.storage.getUser();
    if (user && user.tenant && user.username && user.password) {
      const env = await this.storage.getApiEnv();
      if (env && env !== ApiEnvironment.Prod) {
        await this.api.setApiEnv(env);
      }
      return user;
    }
    else {
      return null;
    }
  }

  /**
   * Get forms or history related path
   * @param url The url to check
   */
  private getUrlPath(url: string) {
    if (!url) {
      return null;
    }
    if (url.includes('?')) {
      url = url.split('?')[0];
    }
    if (url.includes('/forms')) {
      const index = url.indexOf('/forms');
      return url.slice(index);
    }
    else if (url.includes('/history')) {
      const index = url.indexOf('/history');
      return url.slice(index);
    }
    else {
      return null;
    }
  }

  /**
   * Get formData from query string if the url is to an form
   * @param url The url to check
   */
  private getFormData(url: string): any {
    if (url?.includes('/forms/') && url.includes('?') && url.includes('formData=')) {
      const formData = this.browser.getQueryParameter(url, 'formData');
      if (formData) {
        return JSON.parse(decodeURIComponent(formData));
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  /**
   * Check URL for tenant and LogInfo string, and convert them to username and password if the exists
   * @returns An user object with the correct tenant, username and password from the LogInfo string
   */
  private async checkUrl(url: string): Promise<User> {
    if (url.includes('?') && url.includes('tenant') && url.includes('LogInfo')) {
      const parts = url.split('?').slice(1).join('?').split('&');
      const tenant = parts.find(p => p.startsWith('tenant='))?.split('=')[1];
      const logInfo = parts.find(p => p.startsWith('LogInfo='))?.split('=')[1];
      if (tenant && logInfo) {
        const {value, status} = await this.api.getUsernameAndPassword(logInfo).toPromise();
        if (status === ApiStatus.Success) {
          return {
            tenant: tenant,
            username: value.username,
            password: value.password
          };
        }
        else {
          return null;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  /**
   * Set app for an online authenticated user
   * @param tenant The current tenant name
   * @param username The current username
   * @param data The sign in data received from API
   */
  private async onlineAuth(tenant: string, username: string, data: SignInData): Promise<AuthenticationState> {
    if (data.redirect) {
      this.router.navigate(['reset-password'], { state: {tenant, username, url: data.url}});
      return AuthenticationState.ResetPassword;
    }

    const regs = await this.legacy.readOfflineRegs();
    if (regs.length > 0) {
      await this.uploadRegs(regs);
    }
    await this.initWhenAuthenticated(tenant, username, data.password, true);
    if (!this.online.isOnline()) {
      this.offlineAuth(tenant, username, data, false, false);
      return AuthenticationState.OfflineAuthenticated;
    }
    const [{value: project}, {value: projects}, latestIntro] = await Promise.all([
      firstValueFrom(this.api.getCurrentProject(false), {defaultValue: {value: null, status: ApiStatus.Failed}}),
      firstValueFrom(this.api.getProjects(SortBy.Name, false), {defaultValue: {value: [], status: ApiStatus.Failed}}),
      this.storage.getIntroVersion()
    ]);
    this.statistics.setUserId(tenant, username);
    this.notifications.init();
    if (projects.length === 0) {
      this.popup.showAlert('IntroError', 'IntroNoProjects', true);
      this.logout();
      return null;
    }
    if (project) {
      await this.api.getAllowHomeEdit().toPromise();
      await Promise.all([
        firstValueFrom(this.api.getBoxOrder(project.id), {defaultValue: {value: null, status: ApiStatus.Failed}}),
        this.storage.setCurrentProject(project),
        this.storage.setProjects(projects)
      ]);
      if (latestIntro >= environment.currentIntroVersion) {
        this.statistics.logEvent(FirebaseEvent.Login, {type: 'normal'});
        return AuthenticationState.Authenticated;
      }
      else {
        this.statistics.logEvent(FirebaseEvent.Login, {type: 'newuser'});
        return AuthenticationState.NewUser;
      }
    }
    else {
      this.statistics.logEvent(FirebaseEvent.Login, {type: 'newuser'});
      return AuthenticationState.NewUser;
    }
  }

  /**
   * Set app for an offline authenticated user
   * @param tenant The current tenant name
   * @param username The current username
   * @param data The sign in data received from API
   * @param init If is should set auth state to `InitAuthenticated` instead of `OfflineAuthenticated`
   */
  private async offlineAuth(tenant: string, username: string, data: SignInData, init: boolean, doInitAuth: boolean): Promise<AuthenticationState> {
    if (doInitAuth) {
      await this.initWhenAuthenticated(tenant, username, data.password, false);
    }
    if (init) {
      return AuthenticationState.InitAuthenticated;
    }
    else {
      this.statistics.logEvent(FirebaseEvent.Login, {type: 'offline'});
      return AuthenticationState.OfflineAuthenticated;
    }
  }

  /**
   * Init State Service with user and offlineregs, and set http headers for logger
   * @param tenant The current tenant name
   * @param username The current username
   * @param password Users (encrypted) password
   * @param storeUser If it should store the user
   */
  private async initWhenAuthenticated(tenant: string, username: string, password: string, storeUser: boolean) {
    const deviceType = this.device.getType().toLowerCase();
    this.stateService.setSlackBotname(`${tenant}|${environment.appVersion}|${deviceType}`);
    this.logger.updateConfig({
      level: environment.consoleLogLevel,
      serverLogLevel: environment.serverLogLeveL,
      serverLoggingUrl: environment.serverLogUrl,
      enableSourceMaps: true,
      customHttpHeaders: this.stateService.logHeaders
    });
    await this.stateService.getOfflineRegs();
    this.stateService.currentUser = {tenant, username, password};
    this.storage.tenant = tenant;
    this.storage.username = username;
    if (storeUser) {
      await this.storage.setUser({tenant, username, password});
    }
  }

  /**
   * Upload old offline registrations
   * @param regs The offline registrations to upload
   */
  private async uploadRegs(regs: NewReg[]) {
    const failed: string[] = [];
    for (const reg of regs) {
      const {status, error} = await firstValueFrom(this.api.doRegistration(reg), {defaultValue: {status: ApiStatus.Failed, error: null}});
      if (status !== ApiStatus.Success) {
        if (typeof reg.RegTime === 'string') {
          reg.RegTime = new Date(reg.RegTime);
        }
        const date = reg.RegTime?.toLocaleDateString() ?? 'No Date';
        failed.push(`${date} - ${reg.FormId}`);
        this.inLogger.error('Error uploading old offline registration', error, {reg, status});
      }
    }
    if (failed.length > 0) {
      const trans = this.translate.instant(['OldOfflineFail', 'OldOfflineFailMsg']);
      const title = trans['OldOfflineFail'];
      const msg = `${trans['OldOfflineFailMsg']}:<br>${failed.join('<br>')}`;
      await this.popup.showAlert(title, msg, false);
    }
  }
}
