import { Injectable, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LegacyService } from './legacy.service';
import { ValueChange } from '../models/models';
import { UtilityService } from './utility.service';
import { WatchOptions } from '../models/interfaces/watchOptions';


/**
 * For creating an watchGroup
 *
 * **Important:** Must be included in providers in the component that uses it
 */
@Injectable()
export class WatchgroupService {
  oldModel: KeyValueDiffer<string, any>;
  keys: string[];
  paths: any = {};

  constructor(
    private diff: KeyValueDiffers,
    private legacy: LegacyService,
    private util: UtilityService
  ) { }

  /**
   * Get watchGroup from an options object
   * @param options The options object
   * @returns Returns array of watchGroup keys
   */
  getWatchGroupFromOptions(options: any) {
    const res: WatchOptions = {fieldKeys: [], paths: {}};
    if (!options) {
      return res;
    }
    const keys: string | string[] = options.watchGroup || options.watch;
    if (!keys) {
      return res;
    }
    else if (typeof keys === 'string') {
      res.fieldKeys = [keys];
    }
    else {
      res.fieldKeys = keys;
    }

    const valuePath: any = options.watchPath || options.watchGroupPath || {};
    res.paths = valuePath;
    res.fieldKeys = this.legacy.fixWatchGroup(res.fieldKeys);

    return res;
  }

  /**
   * Creates an watchGroup that watches for changes on specified fields
   * @param form The formgroup of the form to watch on
   * @param model The model of the form
   * @param keys The keys to fields to watch for changes on
   */
  watchGroup(form: FormGroup<any> | FormArray<any>, model: any, keys: WatchOptions | string[]): Observable<ValueChange> {
    const isArray = Array.isArray(keys);
    this.keys = (isArray) ? keys as string[]: (keys as WatchOptions).fieldKeys;
    this.paths =  (isArray) ? {}: (keys as WatchOptions).paths;
    this.oldModel = this.diff.find(model).create();
    this.oldModel.diff(model);
    return form.valueChanges.pipe(
      map<any, ValueChange>(() => this.check(model)),
      filter(vc => vc !== null)
    );
  }

  /**
   * Used by watchGroup to check model for changes
   * @param model The model to check
   */
  private check(model: any): ValueChange {
    const changes = this.oldModel.diff(model);

    if (!changes) return null;

    let returnVal: ValueChange = null;
    changes.forEachChangedItem(item => {
      if (this.keys.includes(item.key) && !this.util.deepEqual(this.getValue(item.currentValue, item.key), this.getValue(item.previousValue, item.key))) {
        returnVal = {
          key: item.key,
          newValue: this.getValue(item.currentValue, item.key),
          oldValue: this.getValue(item.previousValue, item.key)
        };
      }
    });
    changes.forEachAddedItem(item => {
      if (this.keys.includes(item.key) && this.getValue(item.currentValue, item.key) !== null) {
        returnVal = {
          key: item.key,
          newValue: this.getValue(item.currentValue, item.key),
          oldValue: this.getValue(item.previousValue, item.key)
        };
      }
    });
    changes.forEachRemovedItem(item => {
      if (this.keys.includes(item.key)) {
        returnVal = {
          key: item.key,
          newValue: this.getValue(item.currentValue, item.key),
          oldValue: this.getValue(item.previousValue, item.key)
        };
      }
    });

    return returnVal;
  }


  private getValue(obj: any, pathKey: string) {
    return (!this.paths[pathKey]) ? obj: this.util.dotRef(obj, this.paths[pathKey]);
  }

}
