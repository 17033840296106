<ion-card class="transparent" id="wrapper" class="{{noticeType}}" [ngClass]="{'notice': isNotice}" *ngIf="!useModal">
  <span #text [class]="'text'+size" [innerHTML]="label" *ngIf="!isMarkdown && !usesValidation"  [style.text-align]="align"></span>
  <ion-text  [color]="textColor" [class]="'text'+size" [innerHTML]="shownText" *ngIf="usesValidation"  [style.text-align]="align"></ion-text>
  <markdown [class.overflowing]="isOverflowing" #markdownEl [data]="markdown" *ngIf="isMarkdown" (swipe)="handleSwipe()" (ready)="checkOverflowing()" (scroll)="hideScrollArrow()"></markdown>
  <ion-icon class="overflow-indicator aniHoverX" name="chevron-forward-sharp" *ngIf="showOverflowArrow"></ion-icon>
</ion-card>
<ion-button 
  class="ion-text-wrap ion-padding-start ion-padding-end"
  type="button"
  [color]="color"
  [fill]="fill"
  expand="block"
  (click)="openModal()"
  *ngIf="useModal"
  [disabled]="!markdown"
>
  <ion-label class="ion-padding-top ion-padding-bottom">{{buttonText}}</ion-label>
</ion-button> 