import { Injectable } from '@angular/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private logger: NGXLogger) {}

  async readFile(path: string, directory?: Directory): Promise<string> {
    try {
      if (directory) {
        const result = await Filesystem.readFile({path, directory});
        const data = await this.checkResult(result.data);
        return data;
      }
      else {
        const result = await Filesystem.readFile({path});
        const data = await this.checkResult(result.data);
        return data;
      }
    }
    catch (e) {
      this.logger.error('Failed reading file', e);
      return '';
    }
  }

  async writeFile(data: string, path: string, directory?: Directory): Promise<string> {
    try {
      if (directory) {
        const result = await Filesystem.writeFile({data, path, directory});
        return result.uri;
      }
      else {
        const result = await Filesystem.writeFile({data, path});
        return result.uri;
      }
    }
    catch (e) {
      this.logger.error('Failed writing file', e);
      return '';
    }
  }

  async deleteFile(path: string, directory?: Directory) {
    try {
      if (directory) {
        await Filesystem.deleteFile({path, directory});
      }
      else {
        await Filesystem.deleteFile({path});
      }
    }
    catch (e) {
      this.logger.error('Failed deleting file', e);
      return '';
    }
  }

  async copyFile(path1: string, path2: string, directory1: Directory, directory2: Directory): Promise<string> {
    const data = await this.readFile(path1, directory1);
    if (data) {
      const result = await this.writeFile(data, path2, directory2);
      return result;
    }
    else {
      return '';
    }
  }

  private async checkResult(result: string | Blob): Promise<string> {
    if (typeof result !== 'string') {
      result = await result.text();
    }
    return result;
  }
}
