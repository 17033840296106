/**
 * The different color classes in Ionic
 */
export enum IonColor {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    Success = 'success',
    Warning = 'warning',
    Danger = 'danger',
    Dark = 'dark',
    Medium = 'medium',
    Light = 'light'
}
