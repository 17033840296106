import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private currentTitle: string;
  private shouldTranslate: boolean;

  constructor(
    private title: Title,
    private translate: TranslateService
  ) { }

  setTitle(title: string, translate: boolean) {
    this.currentTitle = title;
    this.shouldTranslate = translate;
    this.updateTitle();
  }

  updateTitle() {
    if (!this.currentTitle) {
      return;
    }

    if (this.shouldTranslate) {
      this.title.setTitle(`${this.translate.instant(this.currentTitle)} - TempusIN`);
    }
    else {
      this.title.setTitle(`${this.currentTitle} - TempusIN`);
    }
  }
}
