import { Component } from '@angular/core';
import { Platform, PopoverController, ModalController, NavController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { ApiService } from './services/api.service';
import { InStorageService } from './services/in-storage.service';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { AppSettingsComponent } from './custom-components/directives/page-directives/app-settings/app-settings.component';
import { UserSettingsComponent } from './custom-components/directives/page-directives/user-settings/user-settings.component';
import { ThemeService } from './services/theme.service';
import { SendFeedbackComponent } from './custom-components/directives/page-directives/send-feedback/send-feedback.component';
import { StateService } from './services/state.service';
import { StatisticsService } from './services/statistics.service';
import { SetupModalComponent } from './custom-components/directives/page-directives/setup-modal/setup-modal.component';
import { ComponentRefService } from './services/component-ref.service';
import { SwUpdate } from '@angular/service-worker';
import { PopupService } from './services/popup.service';
import { InLoggerService } from './services/in-logger.service';
import { ApiEnvironment, ApiStatus, AuthenticationState, FirebaseEvent, FormType, PageRefs, State, TriggerType, User } from './models/models';
import { UtilityService } from './services/utility.service';
import { Observable, of } from 'rxjs';
import { PrivacyInfoComponent } from './custom-components/directives/page-directives/privacy-info/privacy-info.component';
import { LanguageService } from './services/language.service';
import { DeviceService } from './services/device.service';
import { BrowserService } from './services/browser.service';
import { NotificationsService } from './services/notifications.service';
import { NotificationsModalComponent } from './custom-components/directives/page-directives/notifications-modal/notifications-modal.component';
import { AppVersion as AppVersionPlugin } from '@ionic-native/app-version/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';

/**Page button in menu */
interface Page {
  name: string;
  urlOrData: string;
  icon: string;
  pageState: State;
  direction: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentAuthState: AuthenticationState;
  loggedIn = false;
  disableMenu = false;
  user = {
    tenant: '',
    name: '',
    profilePicture: '',
    email: ''
  };
  hasCheckedSetup = false;
  // The pages to link to in the menu
  public pages: Page[] = [
    {
      name: 'Home',
      urlOrData: '/home',
      icon: 'home-outline',
      pageState: State.Home,
      direction: 'root'
    },
    {
      name: 'Forms',
      urlOrData: '/forms',
      icon: 'documents-outline',
      pageState: State.Forms,
      direction: 'forward'
    },
    {
      name: 'History',
      urlOrData: '/history',
      icon: 'albums-outline',
      pageState: State.History,
      direction: 'forward'
    }
  ];

  installPrompt: any; // Install prompt for installing PWA
  hasUpdate = false; // If the PWA has a new update
  isOld = false;
  storeLink: string;
  showUpdateBadge = false;
  apiEnv: string;
  buildNr: number;
  init = true;

  constructor(
    private platform: Platform,
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService,
    private storage: InStorageService,
    private api: ApiService,
    private logger: NGXLogger,
    private popCtrl: PopoverController,
    private modalCtrl: ModalController,
    private themeService: ThemeService,
    private navCtrl: NavController,
    private stateService: StateService,
    private statistics: StatisticsService,
    private ref: ComponentRefService,
    private updates: SwUpdate,
    private popup: PopupService,
    private inLogger: InLoggerService,
    private util: UtilityService,
    private language: LanguageService,
    private device: DeviceService,
    private browser: BrowserService,
    private notification: NotificationsService,
    private appVersionPlugin: AppVersionPlugin
  ) {
    this.platform.ready().then(() => {
      this.initializeApp();
    }, error => {
      this.logger.error('Error getting platform ready', error);
    });
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPrompt = e;
    });
  }

  /**Number of offline registrations */
  get offlineCount(): number {
    return this.stateService.offlineRegs.length;
  }

  get appVersion(): string {
    const version = this.buildNr ? `${environment.appVersion}.${this.buildNr}` : environment.appVersion;
    return environment.production ? version : `${version} (dev)`;
  }

  /**The current API environment, only show if **not** Prod */
  get apiEnvironment(): string {
    return (this.apiEnv === ApiEnvironment.Prod) ? '' : this.apiEnv ;
  }

  /**Current state of App, e.g. Home, Forms, etc. */
  get state(): State {
    return this.stateService.state;
  }

  /**If the app is on an mobile device */
  get isMobile(): boolean {
    return this.device.isMobile();
  }

  get notificationsCount(): number {
    return this.notification.notOpened;
  }

  get hasNotifications(): boolean {
    return this.notification.hasNotifications;
  }

  /**Get API environment from Storage */
  getApiEnv() {
    this.storage.getApiEnv().then( env => {
      this.logger.debug(env);
      this.apiEnv = env;
    });
  }

  /**
   * Track Page buttons in menu by name
   * @param _i Not used
   * @param page The page item to track
   */
  trackByName(_i: number, page: Page) {
    if (!page)
      return null;
    if (page.name)
      return page.name;
  }

  /**
   * Initalize App with settings, authState, navigation
   */
  async initializeApp() {
    if (this.device.isPWA()) {
      this.updates.versionUpdates.pipe(filter((evt) => evt.type === 'VERSION_DETECTED')).subscribe(() => {
        this.hasUpdate = true;
        this.showUpdateBadge = true;
        this.notification.hasUpdate = true;
      });
    }
    Promise.all([
      this.language.initLanguage(),
      this.themeService.initTheme(),
    ]).then(([lang, theme]) => {
      this.init = false;
      this.statistics.initUserProps(theme.value, lang);
      this.checkAppVersion().subscribe(link => {
        this.isOld = !!link;
        if (this.isOld) {
          this.storeLink = link;
          this.notification.hasUpdate = true;
          this.showUpdateBadge = true;
        }
      });
    });

    this.listenForDeeplinks();

    // Set up navigation based on Auth State changes
    this.auth.authenticationState.subscribe(authState => {
      if (this.currentAuthState === authState) return;

      if (authState === AuthenticationState.NewUser) {
        this.router.navigate(['intro'], { replaceUrl: true });
        this.loggedIn = true;
      }
      else if (this.auth.isAuthenticated(authState)
               && (!this.auth.isAuthenticated(this.currentAuthState) || this.currentAuthState === AuthenticationState.NewUser)
      ) {
        this.hasCheckedSetup = false;
        this.logger.debug(this.auth.orgRoute);
        this.navCtrl.navigateRoot('home');
        this.loggedIn = true;
        this.getApiEnv();
        if (!this.auth.isInitAuth()) {
          this.api.getProfilePicture().subscribe(({value: url}) => this.user.profilePicture = url);
        }
        if (this.stateService.currentUser) {
          this.getUserAndTenantInfo(this.stateService.currentUser);
        }
        else {
          this.storage.getUser().then(user => {
            this.getUserAndTenantInfo(user);
          });
        }
      }
      else if (this.auth.isAuthenticated(authState) && !this.auth.isInitAuth() && !this.user.profilePicture) {
        this.api.getProfilePicture().subscribe(({value: url}) => this.user.profilePicture = url);
      }
      else if (authState === AuthenticationState.NotAuthenticated) {
        this.user = {
          tenant: '',
          name: '',
          profilePicture: '',
          email: ''
        };
        this.router.navigate(['sign-in'], { replaceUrl: true });
        this.loggedIn = false;
      }

      this.currentAuthState = authState;
    });

    // Set up subscription on navigation for setting correct State and if menu should be disabled
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((ev: NavigationEnd) => {
      this.disableMenu = false;
      if (ev.url.includes('/forms/')) {
        this.stateService.state = State.Form;
        this.statistics.logEvent(FirebaseEvent.NavigatedForm, {currState: this.stateService.state, prevState: this.stateService.prevState});
        this.disableMenu = true;
      }
      else if (ev.url.includes('/forms')) {
        this.stateService.state = State.Forms;
        this.statistics.logEvent(FirebaseEvent.NavigatedForms, {currState: this.stateService.state, prevState: this.stateService.prevState});
      }
      else if (ev.url.includes('/history/')) {
        this.stateService.state = State.Reg;
        this.statistics.logEvent(FirebaseEvent.NavigatedReg, {currState: this.stateService.state, prevState: this.stateService.prevState});
        this.disableMenu = true;
      }
      else if (ev.url.includes('/history')) {
        this.stateService.state = State.History;
        this.statistics.logEvent(FirebaseEvent.NavigatedHistory, {currState: this.stateService.state, prevState: this.stateService.prevState});
      }
      else if (ev.url.includes('/preview/') || ev.url.includes('/showreg/')) {
        this.stateService.state = State.Preview;
        this.statistics.logEvent(FirebaseEvent.NavigatedPreview, {currState: this.stateService.state, prevState: this.stateService.prevState});
        this.disableMenu = true;
      }
      else if (ev.url.includes('/home')) {
        this.stateService.state = State.Home;
        this.statistics.logEvent(FirebaseEvent.NavigatedHome, {currState: this.stateService.state, prevState: this.stateService.prevState});
      }
      else if (ev.url.includes('/intro')) {
        this.stateService.state = State.Intro;
        this.disableMenu = true;
        this.statistics.logEvent(FirebaseEvent.NavigatedIntro, {currState: this.stateService.state, prevState: this.stateService.prevState});
      }
      else {
        this.stateService.state = State.Other;
      }
    });
  }



  /**
   * Open Setup modal and update correct pages if project changed
   */
  async openSetup(hasChanged = false) {
    this.stateService.state = State.Setup;
    this.popup.closeMenu();
    const modal = await this.modalCtrl.create({
      component: SetupModalComponent,
      componentProps: {
        hasChanged: hasChanged
      }
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();
    this.stateService.state = this.stateService.prevState;
    if (data && data.changed) {
      if (this.stateService.state === State.Forms) {
        this.ref.callReference(PageRefs.Forms, 0, FormType.None, TriggerType.Update);
      }
      else if (this.stateService.state === State.Home) {
        this.ref.callReference(PageRefs.Home, 0, FormType.None, TriggerType.Update);
      }
      else if (this.stateService.state === State.Other) {
        this.ref.callReference(PageRefs.Home, 0, FormType.None, TriggerType.Update, null, null, false);
        this.ref.callReference(PageRefs.Forms, 0, FormType.None, TriggerType.Update, null, null, false);
      }
    }

  }

  /**
   * Open notificaitons modal with an overview of stored notifications
   */
  async openNotifications() {
    this.stateService.state = State.Notifications;
    const modal = await this.modalCtrl.create({
      component: NotificationsModalComponent
    });
    await modal.present();
    await modal.onWillDismiss();
    this.stateService.state = this.stateService.prevState;
  }

  /**
   * Open app settings popover with theme and language settings
   */
  async openAppSettings() {
    this.stateService.state = State.Settings;
    const popover = await this.popCtrl.create({
      mode: 'md',
      component: AppSettingsComponent
    });
    await popover.present();
    await popover.onWillDismiss();
    this.stateService.state = this.stateService.prevState;
  }

  /**
   * Open User Settings modal with name, email, profile picture and password settings
   */
  async openUserSettings() {
    this.stateService.state = State.UserSettings;
    this.popup.closeMenu();
    const modal = await this.modalCtrl.create({
      component: UserSettingsComponent,
      componentProps: {
        profilePic: this.user.profilePicture
      },
      backdropDismiss: false
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();
    if (data && data.name) {
      this.user.name = data.name;
    }
    if (data && data.picChanged) {
      this.api.getProfilePicture().subscribe(({value: url}) => this.user.profilePicture = url);
    }
    this.stateService.state = this.stateService.prevState;
  }

  /**
   * Gets additional info about the user and tenant
   * @param user The logged in user
   */
  getUserAndTenantInfo(user: User) {
    if (!user) {
      return;
    }
    this.api.getUserInfo(user.tenant, user.username).subscribe(({value: userInfo}) => {
      if (userInfo) {
        this.user.name = userInfo.name;
        this.user.email = userInfo.email;
      }
    });
    this.api.getTenantName(user.tenant).subscribe(({value: tenant}) => {
      if (tenant) {
        this.user.tenant = tenant;
      }
    });
  }

  /**
   * Shows feedback popover
   */
  async showFeedback(event?: any) {
    if (event?.deltaTime < 1000) return;

    this.stateService.state = State.Feedback;
    const popover = await this.popCtrl.create({
      mode: 'md',
      component: SendFeedbackComponent,
      componentProps: {
        name: this.user.name,
        email: this.user.email,
        tenant: this.user.tenant
      },
      backdropDismiss: false
    });
    await popover.present();
    await popover.onWillDismiss();
    this.stateService.state = this.stateService.prevState;
  }

  /**
   * Opens the user documentation in browser/new tab
   */
  async openManual() {
    const url = this.translate.instant('UserManualLink');
    this.browser.openUrl(url);
  }

  /**
   * Clear log
   */
  async clearLog(event: any) {
    if (event.deltaTime < 4000) {
      return;
    }
    event.preventDefault();
    const confirm = await this.popup.showConfirm('ClearLog', 'ClearLogMsg', true);
    if (confirm) {
      this.inLogger.clearLog();
      this.popup.showMessage('LogCleared', true, 'light');
    }
  }

  /**
   * Get build number from version code, and set it so it's shown in GUI
   * @param event The click event
   */
  async setBuildNr(event: MouseEvent) {
    if (!this.device.isDevice()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (this.buildNr) {
      this.buildNr = undefined;
    }
    else {
      const versionCode = (await this.appVersionPlugin.getVersionCode()) as number;
      this.buildNr = +versionCode.toString().slice(-2);
    }
  }

  /**
   * Checks what the current/newest app version is and compares it with it's own. Returns link to store if app is old.
   */
  checkAppVersion(): Observable<string> {
    // return of(true);
    if (this.device.isDevice()) {
      return this.api.getCurrentAppVersion().pipe(
        map(({value: version, status}) => {
          if (status === ApiStatus.Success) {
            const link = this.device.isIOS() ? version.ios : version.android;
            if (this.util.isNewerVersion(environment.appVersion, version.criticalVersion)) {
              this.forceUpdate(link);
              return '';
            }
            else if (this.util.isNewerVersion(environment.appVersion, version.appVersion)) {
              return link;
            }
            else {
              return '';
            }
          }
          else {
            return '';
          }
        })
      );
    }
    else {
      return of('');
    }
  }

  /**
   * Trigger PWA install prompt
   */
  addToHome() {
    if (this.installPrompt) {
      this.installPrompt.prompt();
      this.installPrompt.userChoice.then((choice: any) => {
        if (choice.outcome === 'accepted') {
          this.logger.debug('User added to home screen');
        }
        else {
          this.logger.debug('User didn\'t add to home screen');
        }
        this.installPrompt = null;
      });
    }
  }

  /**
   * Update PWA
   */
  async updateApp() {
    if (this.showUpdateBadge) {
      this.notification.hasUpdate = false;
      this.showUpdateBadge = false;
    }
    const translations = await this.translate.instant(['UpdateApp', 'UpdatePWAMessage', 'UpdateAndroidMessage', 'UpdateIOSMessage']);
    const title = translations['UpdateApp'];
    let msg: string;
    if (this.device.isPWA()) {
      msg = translations['UpdatePWAMessage'];
    }
    else if (this.device.isIOS()) {
      msg = translations['UpdateIOSMessage'];
    }
    else {
      msg = translations['UpdateAndroidMessage'];
    }

    const confirmed = await this.popup.showConfirm(title, msg, false);
    if (!confirmed) {
      return;
    }
    if (this.hasUpdate) {
      this.updates.activateUpdate().then(() => {
        this.hasUpdate = false;
        this.browser.reload();
      });
    }
    else if (this.isOld) {
      const link = this.storeLink || this.device.storeLink;
      if (link) {
        this.browser.openUrl(link);
      }
    }
  }

  async openPrivacyPopover() {
    const popover = await this.popCtrl.create({
      component: PrivacyInfoComponent
    });
    await popover.present();
  }

  listenForDeeplinks() {
    if (!this.device.isDevice()) {
      return;
    }
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.checkDeepLink(event.url);
    });
  }

  checkDeepLink(url: string) {
    const [path, query] = url.split(':/').pop().split('.no').pop().split('?');
    if (!path || (!path.startsWith('/forms') && !path.startsWith('/history') && !path.startsWith('/home')) ) {
      return;
    }
    const formData = this.tryParseFormData(query, path);
    if (this.auth.isAuthenticated() && this.auth.authenticationState.value !== AuthenticationState.NewUser) {
      if (formData) {
        this.router.navigateByUrl(path, {state: {formData: formData}});
      }
      else {
        this.router.navigateByUrl(path);
      }
    }
    else {
      this.auth.orgRoute = path;
      this.auth.formData = formData;
    }
  }

  stopScanner() {
    const scanner = this.device.openScanner;
    if (scanner) {
      this.ref.callReference(scanner.key, scanner.formId, scanner.formType, TriggerType.StopScan);
    }
  }

  private tryParseFormData(query: string, path: string): any {
    if (!path.startsWith('/forms/')) {
      return null;
    }
    const data = this.browser.getQueryParameter(query, 'formData');
    if (!data) {
      return null;
    }
    try {
      const json = JSON.parse(data);
      return json;
    }
    catch {
      this.logger.error('Failed JSON parsing', data);
      return null;
    }
  }

  private async forceUpdate(link: string) {
    const translations = await this.translate.instant(['UpdateAppCritical', 'UpdateCriticalMessage', 'GoToGoogle', 'GoToAppStore', 'ExitApp']);
    const title = translations['UpdateAppCritical'];
    const msg = translations['UpdateCriticalMessage'];
    const cancel = translations['ExitApp'];
    let confirm: string;
    if (this.device.isIOS()) {
      confirm = translations['GoToAppStore'];
    }
    else {
      confirm = translations['GoToGoogle'];
    }

    const confirmed = await this.popup.showConfirm(title, msg, false, cancel, confirm, null, false);
    if (confirmed) {
      link ||= this.device.storeLink;
      this.browser.openUrl(link);
      setTimeout(() => {
        this.device.exitApp(true);
      }, 1000);
    }
    else {
      this.device.exitApp(true);
    }
  }
}
