import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { InStorageService } from 'src/app/services/in-storage.service';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { FieldService } from 'src/app/services/field.service';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { filter } from 'rxjs/operators';
import { DataStatus, InitMapping } from 'src/app/models/models';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-wrapper-init',
  templateUrl: './init-wrapper.component.html',
  styleUrls: ['./init-wrapper.component.scss'],
  providers: [FieldService]
})
export class InitWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;
  tenantApiStatus = 0;

  constructor(
    private api: ApiService,
    private storage: InStorageService,
    private route: ActivatedRoute,
    private fieldService: FieldService,
    private cachedApi: CachedApiService,
    private auth: AuthService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get url(): string {
    return this.to.fieldInitUrl || '';
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get initMapping(): InitMapping {
    return this.to.initMapping || {};
  }

  async ngOnInit() {
    if (this.url) {
      this.fieldService.initFieldFromStorage(this.url).then(async () => {
        await this.auth.waitForAuth();
        this.fieldService.initFieldFromApi(this.url, this.formId, this.initMapping, this.to.headers, this.to.useCredentials).subscribe();
      });
    }
    else if (this.to.tenant === 'id' || this.to.tenant === 'name') {
      await this.auth.waitForAuth();
      this.api.getCurrentProject().subscribe(data => {
        if (data.value) {
          this.api.getTenant(data.value.tenantId).subscribe(({value: tenant}) => {
            if (tenant) {
              if (this.to.tenant === 'id') {
                this.fieldService.setValue(tenant.Id, false);
                this.to.initValue = tenant.Id;
              }
              else {
                this.fieldService.setValue(tenant.TenancyName, false);
                this.to.initValue = tenant.TenancyName;
              }
            }
          });
        }
      });
    }
    else if (this.to.project === 'id' || this.to.project === 'number' || this.to.project === 'name') {
      this.cachedApi.getCurrentProject().pipe(filter(data => data.status === DataStatus.Updated))
      .subscribe(data => {
        const project = data.value;
        if (project) {
          switch (this.to.project) {
            case 'id':
              this.fieldService.setValue(project.id, false);
              this.to.initValue = project.id;
              break;
            case 'number':
                this.fieldService.setValue(project.number, false);
                this.to.initValue = project.number;
              break;
            default:
              this.fieldService.setValue(project.name, false);
              this.to.initValue = project.name;
          }
        }
      });
    }
    else if (this.to.form === 'id' || this.to.form === 'number' || this.to.form === 'name') {
      this.route.paramMap.subscribe(params => {
        const id = parseInt(params.get('id'), 10);
        if (this.to.form === 'id') {
          this.fieldService.setValue(id || 0, false);
          this.to.initValue = id || 0;
        }
        else {
          this.storage.getForm(id).then(form => {
            if (form) {
              if (this.to.form === 'number') {
                this.fieldService.setValue(form.theForm.number, false);
                this.to.initValue = form.theForm.number;
              }
              else {
                this.fieldService.setValue(form.theForm.name, false);
                this.to.initValue = form.theForm.name;
              }
            }
          });
        }
      });
    }
  }
}
