/**States for authorization */
export enum AuthenticationState {
    NotSet,
    NotAuthenticated,
    Authenticated,
    OfflineAuthenticated,
    InitAuthenticated,
    NewUser,
    ResetPassword
}

