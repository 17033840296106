import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { FieldService } from 'src/app/services/field.service';
import { PopupService } from 'src/app/services/popup.service';
import { FormType, IonColor, Trigger, TriggerType } from 'src/app/models/models';
import { ValidationService } from 'src/app/services/validation.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-field-itemlist',
  templateUrl: './itemlist.component.html',
  styleUrls: ['./itemlist.component.scss'],
  providers: [FieldService]
})
export class ItemlistComponent extends FieldType implements AfterViewInit, Trigger {
  @ViewChild('itemTable') itemTable: ElementRef;
  items: {item: string, value: string}[] = [];

  constructor(
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private logger: NGXLogger,
    private fieldService: FieldService,
    private popup: PopupService,
    private validation: ValidationService,
    private ref: ComponentRefService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }

  get color(): string {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Primary;
  }

  get itemText(): string {
    return this.to.itemText || '';
  }

  get itemType(): 'text' | 'number' | 'url' | 'email' | 'date' | 'time' | 'tel' {
    switch (this.to.itemInputType) {
      case 'number':
      case 'url':
      case 'email':
      case 'date':
      case 'time':
      case 'tel':
        return this.to.itemInputType;
      default:
        return 'text';
    }
  }

  get valueText(): string {
    return this.to.valueText || '';
  }

  get valueType(): 'text' | 'number' | 'url' | 'email' | 'date' | 'time' | 'tel' {
    switch (this.to.valueInputType) {
      case 'number':
      case 'url':
      case 'email':
      case 'date':
      case 'time':
      case 'tel':
        return this.to.valueInputType;
      default:
        return 'text';
    }
  }

  get json(): boolean {
    return this.to.json || false;
  }

  get splitItem(): string {
    return this.to.splitItem || ';';
  }

  get splitInput(): string {
    return this.to.splitInput || '|';
  }

  get allowEmptyValue(): boolean {
    return this.to.allowEmptyValue ?? false;
  }

  get formId(): number {
    return this.to?.currentForm?.id;
  }
  get formType(): FormType {
    return this.to?.currentForm?.type;
  }
  get singleInput(): boolean {
    return this.to.singleInput ?? false;
  }

  public static getHtml(config: FormlyFieldConfig, value: any) {
    const label = config.templateOptions.label ?? '';
    let html = `<ion-card><ion-card-header>${label}</ion-card-header>`;
    if (config.templateOptions.json && Array.isArray(value) && value.length > 0) {
      html += '<table class="fullsize valuelist">';
      for (const val of value) {
        html += `<tr><td>${val.item}</td><td>${val.value}</td></tr>`;
      }
      html += '</table>';
    }
    else if (!config.templateOptions.json && typeof value === 'string' && value !== '') {
      html += '<table class="fullsize valuelist">';
      const splitItem = config.templateOptions.splitItem || ';';
      const splitInput = config.templateOptions.splitInput || '|';
      const items = value.split(splitItem);
      for (const item of items) {
        const input = item.split(splitInput);
        html += `<tr><td>${input[0]}</td><td>${input[1]}</td></tr>`;
      }

      html += '</table>';
    }
    html += '</ion-card>';
    return html;
  }

  ngAfterViewInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    if (this.model[this.key as string | number]) {
      this.rebuildFromModel();
    }
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Clear) {
      this.clearField();
    }
    else if (type === TriggerType.Rebuild) {
      this.clearField();
      this.rebuildFromModel();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  clearField() {
    this.itemTable.nativeElement.innerHTML = '';
    this.items = [];
  }

  async startReading() {
    const id = `${this.key}-focus`;
    const trans = this.translate.instant(['Cancel', 'Confirm']);
    const alert = await this.alertCtrl.create({
      header: this.label,
      inputs: [
        {
          name: 'item',
          type: this.itemType,
          placeholder: this.itemText,
          id: id
        },
        {
          name: 'value',
          type: this.valueType,
          placeholder: this.valueText
        }
      ],
      buttons: [
        {
          text: trans['Cancel'],
          role: 'cancel'
        },
        {
          text: trans['Confirm'],
          handler: (data) => {
            if (data) {
              const continueReading = this.addToModel({item: data.item, value: data.value});
              if (continueReading) {
                this.startReading();
              }
            }
          }
        }
      ]
    });
    await alert.present();
    document.getElementById(id).focus();
  }

  addToModel(item: {item: string, value: string}) {
    if (typeof item.item === 'undefined' || item.item === null || item.item === '') {
      let text: string;
      if (this.itemText) {
        text = this.translate.instant('ItemListNoItemWithText', {text: this.itemText});
      }
      else {
        text = this.translate.instant('ItemListNoItem');
      }
      this.popup.showMessage(text, false, 'warning', 2000);
      return false;
    }
    if ((typeof item.value === 'undefined' || item.value === null || item.value === '') && !this.allowEmptyValue) {
      let text: string;
      if (this.valueText) {
        text = this.translate.instant('ItemListNoValueWithText', {text: this.valueText});
      }
      else {
        text = this.translate.instant('ItemListNoValue');
      }
      this.popup.showMessage(text, false, 'warning', 2000);
      return false;
    }
    this.items.push(item);
    if (this.json) {
      this.fieldService.setValue(this.items);
    }
    else {
      const newModel = this.itemsToString();
      this.fieldService.setValue(newModel);
    }
    this.addToView(item);
    return !this.singleInput;
  }

  itemsToString() {
    const newModel: string[] = [];
    for (const item of this.items) {
      const text = item.item + this.splitInput + item.value;
      newModel.push(text);
    }
    return newModel.join(this.splitItem);
  }

  addToView(item: {item: string, value: string}) {
    const index = this.itemTable.nativeElement.rowIndex;
    this.logger.debug(index);
    const row = this.itemTable.nativeElement.insertRow(index);
    row.class = 'barcodeItem';
    const that = this;
    row.onclick = function() {
      that.removeItem(this);
    };
    // Insert new cells (<td> elements) at the 1st and 2nd position of the "new" <tr> element:
    const cell1 = row.insertCell(0);
    const cell2 = row.insertCell(1);

    cell1.innerHTML = item.item;
    cell2.innerHTML = item.value;
  }

  removeItem(element: HTMLTableRowElement) {
    if (this.to.disabled) return;

    const parent = element.parentNode;
    // The equivalent of parent.children.indexOf(child)
    const index = Array.prototype.indexOf.call(parent.children, element);
    this.logger.debug('Index ' + index);
    element.className = 'picked';
    const vals = `<b>${element.cells[0].innerHTML}:</b> ${element.cells[1].innerHTML}`;
    this.translate.get('Remove').subscribe(async remove => {
      const header = `${remove}?`;
      const message = `<span>${vals}</span>`;
      const confirmed = await this.popup.showConfirm(header, message, false);
      if (confirmed) {
        this.itemTable.nativeElement.deleteRow(index);
        this.removeElementFromModel(index);
      }
    });
  }

  removeElementFromModel(index: number) {
    this.logger.debug('Remove from model called');

    let value: string | any[];
    this.items.splice(index, 1);
    if (this.items.length === 0) {
      value = '';
    }
    if (this.json) {
      value = this.items;
    }
    else {
      value = this.itemsToString();
    }
    this.fieldService.setValue(value);
  }

  rebuildFromModel() {
    if (this.json) {
      this.items = this.model[this.key as string | number];
    }
    else {
      const items: string[] = this.model[this.key as string | number].split(this.splitItem);
      for (const item of items) {
        const t = item.split(this.splitInput);
        this.items.push({item: t[0], value: t[1]});
      }
    }
    for (const item of this.items) {
      this.addToView(item);
    }
  }
}
