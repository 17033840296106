import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { TimeService } from 'src/app/services/time.service';
import { UtilityService } from 'src/app/services/utility.service';
import * as moment from 'moment';
import { DataStatus, FormType, Trigger, TriggerType } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { FieldService } from 'src/app/services/field.service';
import { FieldType } from '@ngx-formly/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { SubFormComponent } from 'src/app/custom-components/directives/formly-directives/sub-form/sub-form.component';
import { ModalController } from '@ionic/angular';

export interface EventData {
  title: string;
  fromDate: string;
  toDate: string;
  type?: string;
  tag?: string;
  refId?: string;
  data?: any;
}

export interface EventListType {
  label: string;
  value: string;
  extValue?: string;
  color?: string;
  hide?: boolean;
  selected?: boolean;
}

export interface DayInfo {
  date: string;
  week: number;
  month: number;
  year: number;
  dayOfWeek: number;
  dayOfMonth: number;
  dayOfYear: number;
  isHoliday: boolean;
  isCurrentMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
  hasData: boolean;
  isHighlighted: boolean;

}

export interface CalendarPeriode {
  date: string;
  yearWeek: string;
  yearMonth: string;
  monthName: string;
  year: string;
  month: number;
  days: number;
  start: DayInfo;
  end: DayInfo;
}

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class CalendarViewComponent extends FieldType implements OnInit, Trigger {
  @ViewChild('monthContainer', { static: false }) monthContainer: ElementRef;
  @ViewChild('calendarContainer', { static: false }) calendarContainer: ElementRef;
  @ViewChild('calendarPeriodeMenu', { static: false }) calendarPeriodeMenu: ElementRef;


  dateRange: {
    startDay: number, endDay: number,  // dayOfWeek
    startDate, endDate, daysOfMonth: number
  };
  weekdayNames = [];
  days = [];
  weeks = [];
  months = [];
  events = [];
  eventInfo = {};
  holidays = [];

  //  currPeriode = this.calcPeriode(new Date());

  subformTags = {};

  weekLength = 7;
  calendar = {
    periode: this.calcPeriode(new Date()),
    subformTags: {},
    size: { height: 0, width: 0, left: 0, right: 0, top: 0, bottom: 0 }
  };
  dateFormat = 'YYYY-MM-DD';
  today = moment(new Date()).format(this.dateFormat);
  displayMonthSelection = false;
  loading = { month: false, items: false, overview: false, init: true };

  fromDateProp = 'fraDato';
  toDateProp = 'tilDato';
  dateObjProp = 'Fra';
  ferieTittelProp = ['Ferie, betalt', 'Ferie, ubetalt', 'Ferie, forspørsel'];
  currentDate = new Date();
  daySelected = {
    from: null,
    to: null
  };

  errorInfo = {
    show: true,
    showDetails: false,
    type: 'url',
    title: 'EventsInfo',
    text: 'No url/data found'
  };

  localData = {
    dayInfo: [],
    eventInfo: [],
    monthInfo: []
  };

  calendarMenu = {
    show: false,
    mode: '',
    row: 2,
    col: 1,
    data: { weeks: null, dates: null },
    offset: { left: false, top: false, updated: false }
  };

  selectActiveMode: 'off' | 'active' | 'done' = 'off';
  selectMode = { mode: this.selectActiveMode, selected: this.daySelected, validView: true };

  eventSelected = null;

  eventTypeList: EventListType[] = [
    { label: 'All Fravær', value: '', hide: true, selected: false, color: '' },
    { label: 'Feriedager', value: '2', color: 'green' },
    { label: 'Egenmelding', value: '8', hide: true, color: 'blue' }
  ];
  eventTypeSelected: EventListType | EventListType[] = [this.eventTypeList[1]];

  // build in opensubform, replaced with this.to.subformProps.
  subformProps = {
    regID: 'id',
    FrvKode: 'type',
    FraDato: 'fromDate',
    TilDato: 'toDate',
    Dato: 'fromDate'
  };

  urlProps = { //variableprops used in url.
    fromDate: 'fraDato',
    toDate: 'tilDate',
    type: 'type',
    year: 'aar'
  };
  apiDataProps = { //variableprops from api result.
    fromDate: 'FraDato',
    toDate: 'TilDato',
    id: 'FrvID',
    type: 'FrvKode',
    tag: 'Tag',
    info: 'Attestasjon',
    title: 'FrvNavn',
    ownerId: 'AnsattNr',
    ownerName: 'AnsattNavn',

    valueSplit : ',',
    valueMerge : ',',

    monthData: 'Data',
    monthNr: 'Nr',
    monthPeriode: 'Periode',
    monthDataValue: 'Data'
  };

  eventTypeListOption = {
    multiSelect: false,
    isList: true
  };

  hasSubformNew = false;

  allow = { selectDays: true, selectEvent: true, changeMonth: true };
  settings = { typelistMultiselect: false, hideHeader: false, hideMonthsArrows: false};

  urls = {datesData: '', eventsData: '', monthsData: ''};

  firstDaysRow = (this.settings.hideHeader) ? 1 : 2; //header label = 2, no-label = 1

  constructor(
    private util: UtilityService,
    private timeService: TimeService,
    private ref: ComponentRefService,
    private logger: NGXLogger,
    private cachedApi: CachedApiService,
    private fieldService: FieldService,
    private watch: WatchgroupService,
    private translate: TranslateService,
    private langService: LanguageService,
    private modalCtrl: ModalController
  ) {
    super();
    this.fieldService.setField(this);
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get selectedDayInView(): boolean {
    return this.daySelected.from && this.daySelected.from?.data && this.daySelected.from.data.month === this.calendar.periode.month;
  }

  get eventTypeSelectedLabel(): string {
    return (Array.isArray(this.eventTypeSelected)) ? this.eventTypeSelected[0].label : this.eventTypeSelected.label;
  }

  selectedEventTypeValues(useExtValue = false): string {
    let typeValue = '';
    if(!Array.isArray(this.eventTypeSelected)) {
      typeValue = (useExtValue) ? (this.eventTypeSelected.extValue ?? this.eventTypeSelected.value) : this.eventTypeSelected.value;
    }
    else {
      this.eventTypeSelected.forEach(type => {
        typeValue += (typeValue) ? this.apiDataProps.valueMerge : '';
        typeValue += (useExtValue) ? (type.extValue ?? type.value) : type.value;
      });
    }
      return typeValue;
  }

  compareWith(o1: EventListType, o2: EventListType | EventListType[]) {
    if (!o1 || !o2) {
      return o1 === o2;
    }

    if (Array.isArray(o2)) {
      return o2.some((u: EventListType) => u.value === o1.value);
    }

    return o1.value === o2.value;
  }

  validEventTypeSelected() {

    if(!Array.isArray(this.eventTypeSelected))
      return true;

    if (this.eventTypeSelected.length === 1)
      return true;
    else if (this.eventTypeSelected.length === 0) {
      this.eventTypeSelected.push(this.eventTypeList[0]);
    }
    else if (this.eventTypeSelected.length > 1) { //empty value will not work together with other values, but would still be listed as selected.
      const validList = this.eventTypeSelected.filter(type => type.value !== '');
      if (validList.length !== this.eventTypeSelected.length) {
        //console.log('remove empty options');
        this.eventTypeSelected = validList;
      }
    }

  }

  updateEventType() {
    //console.log(this.eventTypeSelected);
    this.validEventTypeSelected();
    this.getEvents();
    this.buildMonths();
  }

  monthName(monthNo: number, short = true): string {
    const m = moment(this.calendar.periode.date).add(monthNo - this.calendar.periode.month, 'months');
    const format = (short) ? 'MMM' : 'MMMM';
    return this.translate.instant(m.format(format));
  }
  dayName(weekday: number, short = true): string {
    const m = moment(this.calendar.periode.date).add(weekday - parseInt(moment(this.calendar.periode.date).format('E')), 'days');
    const format = (short) ? 'ddd' : 'dddd';
    return this.translate.instant(m.format(format));
  }

  buildDayNames() {
    this.weekdayNames = [];
    for (let i = 1; i < 8; i++) {
      this.weekdayNames.push(this.dayName(i));
    }
  }


  updateCalendarSize() {
    setTimeout(() => {
      this.calendar.size = this.calendarContainer.nativeElement.getBoundingClientRect();
      //console.log(this.calendar.size);
    }, 0);
  }

  updateObjectValues(obj: any, newRules: any) {
    if (!newRules)
      return;
    Object.entries(obj).forEach(([key, value]) => {
      if (newRules[key] !== undefined)
        obj[key] = newRules[key];
    });

  }

  errorValues(type, info, show = null) {

    this.errorInfo.title = type;
    this.errorInfo.text = info;
    this.errorInfo.show = show ?? true;

    this.logger.log(this.errorInfo);
  }

  ngOnInit() {
  //  console.log('========CalendarView========');
    const lang = (this.langService.getCurrentLanguage() === 'no') ? 'nb' : this.langService.getCurrentLanguage();
    moment.locale(lang);
    //console.log(this.langService.getCurrentLanguage());
    this.setReference(this.key as string, this.formId, this.formType);
    this.buildDayNames();
    if (typeof this.to.settings === 'object')
      this.updateObjectValues(this.settings, this.to.settings);
    if (typeof this.to.allow === 'object')
      this.updateObjectValues(this.allow, this.to.allow);

//    this.calendar.label = this.to.label ?? '';
    this.subformTags = this.to.subformTags ?? null;

    this.buildWatch();
    if (this.to.apiDataProps)
      this.apiDataProps = { ...this.apiDataProps, ...this.to.apiDataProps };
    if (this.to.urlProps)
      this.urlProps = { ...this.urlProps, ...this.to.urlProps };
    if (this.to.subformProps)
      this.subformProps = { ...this.subformProps, ...this.to.subformProps };
    if (this.to.urls)
      this.urls = { ...this.urls, ...this.to.urls };
//    console.log(this.urls);
    this.buildCalenderDays(new Date());
    this.buildEventTypeList();

    this.hasSubformNew = this.hasSubform('new', null);
  }


  buildEventTypeList() {
    const elist = this.to.eventTypeList ?? null;
    if (Array.isArray(elist)) {
      //console.log(elist);
      //remove hidden items
      const list = elist.filter(item => !item.hide);
      //console.log(list);
      //build itemlist

      if(!list)
        this.errorValues('FormSetup', 'EventTypeListNoOptions');

      const eventTypes = list.map(e => ({
        label: e.label ?? '',
        value: e.value ?? '',
        extValue: e.extValue ?? '',
        color: e.color ?? '',
        hide: e.hide ?? false,
        selected: e.selected ?? false
      }));
      //console.log(eventTypes);

      this.eventTypeList = eventTypes;
      this.eventTypeListOption.isList = this.eventTypeList.length > 1;
      this.eventTypeListOption.multiSelect = this.settings.typelistMultiselect;
      const selected = eventTypes.filter(t => t.selected);
      if(!selected)
        this.eventTypeSelected = this.eventTypeList[0];
      else {

        if(this.eventTypeListOption.multiSelect) {
          this.eventTypeSelected = selected;
        }
        else {
          this.eventTypeSelected = selected[0];
        }
      }
    }
    else {
      this.errorValues('FormSetup', 'EventTypelistMissingArray');
    }
  }

  calcPeriode(currDate, periode = 'month'): CalendarPeriode {
    const val = { date: '', yearWeek: '', yearMonth: '', monthName: '', year: '', month: 0, days: 0, start: null, end: null };
    const d = moment(currDate);
    const format = 'YYYY-MM-DD';
    const period = periode as 'week' | 'year' | 'month' | 'work-week';
    val.date = d.format(format);
    val.yearWeek = d.format('YYYY-W');
    val.yearMonth = d.format('YYYY-MM');
    val.monthName = d.format('MMMM');
    val.month = parseInt(d.format('M'));
    val.year = d.format('YYYY');
    const start = moment(this.timeService.getStartOrEndOfPeriod(d.toDate(), period, 'start')).format(format);
    const end = moment(this.timeService.getStartOrEndOfPeriod(d.toDate(), period, 'end')).format(format);

    val.days = moment(end).diff(moment(start), 'days') + 1;
    //  val['curr'] = this.dayInfo(d.format(format));
    val.start = this.dayInfo(start);
    val.end = this.dayInfo(end);

    return val;
  }

  dayInfo(date: string | Date): DayInfo {
    const o: DayInfo = {
      date: '2022-12-31', week: 1, month: 1, year: 2022,
      dayOfWeek: 1, dayOfMonth: 1, dayOfYear: 1,
      isHoliday: false, isCurrentMonth: true, isToday: true,
      isSelected: false, hasData: false, isHighlighted: false
    };
    const d = moment(date);

    o.date = d.format('YYYY-MM-DD');
    o.week = parseInt(d.format('W'));
    o.month = parseInt(d.format('M'));
    o.year = parseInt(d.format('YYYY'));
    o.dayOfWeek = parseInt(d.format('E'));
    o.dayOfMonth = parseInt(d.format('D'));
    o.dayOfYear = parseInt(d.format('DDD'));
    o.isCurrentMonth = (d.format('YYYY-MM') === this.calendar?.periode.yearMonth) ?? true;
    o.isToday = (d.format(this.dateFormat) === this.today);

    o.hasData = false;
    o.isSelected = false;
    o.isHighlighted = false;

    return o;
  }

  buildCalenderDays(currDate: string | Date, padding = true) {
    //Clear current tasks drawn
    //console.log(currDate);
    this.clearCalendar();
    //Start/end colume
    const cDate = moment(currDate);
    this.calendar.periode = this.calcPeriode(cDate);
    //console.log(this.calendar);
    this.days = [];

    const start = (this.calendar.periode.start.dayOfWeek > 1) ? ((this.calendar.periode.start.dayOfWeek - 1) * -1) : 0;
    const end = (this.calendar.periode.end.dayOfWeek < 7) ? (7 - this.calendar.periode.end.dayOfWeek) + this.calendar.periode.end.dayOfMonth : this.calendar.periode.end.dayOfMonth;

    //console.log(start + ' ' + end);
    for (let i = start; i < end; i++) {
      const d = moment(this.calendar.periode.start.date).add(i, 'days').toDate();
      this.days.push(this.dayInfo(d));
    }

    //console.log(this.days);
    //console.log(this.calendar.periode);

    this.fieldService.setValue(this.calendar.periode);

    this.buildWeeks();
    //console.log(this.weeks);

    if (this.selectMode.mode === 'active') {
      const isValidDirection = moment(this.selectMode.selected.from.data.date).isBefore(this.calendar.periode.start.date);
      this.selectMode.validView = isValidDirection;

      if (this.selectMode.validView) {
        this.markDays(0, start * -1);
      }
      else
        this.calendarMenu.show = false; //reset if set

    }
    else if (this.selectMode.mode === 'done') {
      this.selectMode.mode = 'off';
      this.calendarMenu.show = false;
    }

    this.getHolidays();
    this.getEvents();
    this.buildMonths();

    this.updateCalendarSize();


  }

  buildWeeks() {
    this.weeks = [];
    //simple fix for next year week 52/53>1
    if ((this.calendar.periode.start.week > this.calendar.periode.end.week))
      this.weeks.push(this.calendar.periode.start.week);

    const startWeek = (this.calendar.periode.start.week > this.calendar.periode.end.week) ? 1 : this.calendar.periode.start.week;
    for (let i = startWeek; i <= this.calendar.periode.end.week; i++) {
      this.weeks.push(i);
    }
    //console.log(this.weeks);
  }

  buildWatch() {
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length > 0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe((v) => {
        //console.log(v);
        if (v === null) return;
        this.buildCalenderDays(v.newValue);
      });
    }
  }

  getDayPos(date) {
    const curr = this.dayInfo(date);
    const beforeCal = this.days[0].date > curr.date;
    const afterCal = this.days[this.days.length - 1].date < curr.date;

    const pos = { row: 0, col: 0, monthDiff: 0 };
    //console.log(curr);

    if (beforeCal) {     //Starts before current view
      pos.row = this.firstDaysRow;
      pos.col = 1;
      pos.monthDiff = -1;
    }
    else if (afterCal) {     //ends after current view
      pos.row = (this.weeks.length - 1) + this.firstDaysRow;
      pos.col = 7;
      pos.monthDiff = 1;
    }
    else {
      pos.row = this.weeks.indexOf(curr.week) + this.firstDaysRow;
      pos.col = curr.dayOfWeek;
      pos.monthDiff = 0;
    }

    return pos;
  }

  getEventPos(from, to) {
    const f = this.getDayPos(from);
    const t = this.getDayPos(to);

    const res = [];

    let span = 1;
    if (f.row === t.row) {
      span += (t.col - f.col);
      res.push({ row: f.row, col: f.col, span: span, placement: (f.monthDiff === 0 && t.monthDiff === 0) ? 'same' : (f.monthDiff < 0) ? 'end' : 'start' });
    }
    else {

      for (let i = f.row; i <= t.row; i++) {

        const placement = (f.monthDiff === 0 && i === f.row) ? 'start' : ((t.monthDiff === 0 && i === t.row)) ? 'end' : 'mid';

        if (i === f.row) { //first week
          span = (7 - f.col) + 1;
          res.push({ row: f.row, col: f.col, span: span, placement: placement });
        }
        else if (i === t.row) { //last week
          span = t.col;
          res.push({ row: t.row, col: 1, span: span, placement: placement });
        }
        else { // full week
          res.push({ row: i, col: 1, span: 7, placement: placement });
        }
      }
    }

    return res;

  }

  periodeInfo(from, to) {
    let periodeString = '';
    const fromDate = moment(from);
    const toDate = moment(to);

    const weeks = this.translate.instant('Week') + ' ' + ((fromDate.isSame(toDate, 'week')) ? fromDate.format('W') : fromDate.format('W') + ' - ' + toDate.format('W'));

    periodeString += (fromDate.isSame(toDate, 'month')) ? fromDate.format('D') : fromDate.format('Do MMM');
    periodeString += ` - `;
    periodeString += toDate.format('Do MMM');

    return { dates: periodeString, weeks: weeks };
  }

  drawEvent(event: EventData) {
    const pos = this.getEventPos(event.fromDate, event.toDate);
    const localId = Date.now() + event.fromDate + event.toDate;
    for (let i = 0; i < pos.length; i++)
      this.events.push({ id: localId, index: i, label: event.title, type: event.type, selected: false, offset: { updated: false, top: false, left: false }, ...pos[i] });

    const periodeInfo = this.periodeInfo(event.fromDate, event.toDate);

    this.eventInfo[localId] = {
      id: event.refId, fromDate: event.fromDate, toDate: event.toDate, periodeWeek: periodeInfo.weeks, periodeDate: periodeInfo.dates,
      rows: pos.length, label: event.title, type: event.type, tag: event.tag, data: event.data, selected: false, hasSubform: this.hasSubform('edit', event.tag)
    };

    //console.log(this.events);

  }

  clearCalendar() {
    this.events = [];
    this.holidays = [];
    //this.months = [];
    //  this.clearMarkedDays();
  }

  getHolidays(type = 8) {
    //https://inb.tempus.no/api/inbdager?selskap=1&fraDato=2022-05-01&tilDato=2022-05-31&type=8

    if (!this.urls.datesData) {
      this.errorValues('FormSetup', 'DateInfoUrlMissing');
      return;
    }
/*
    const urlAdd = `&${this.fromDateProp}=${fromDate}&${this.toDateProp}=${toDate}&type=${type}`;
    const url = this.urls.dayInfo + urlAdd;
*/
    const url = this.util.parseText(this.urls.datesData,
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        CW_fromDate: this.days[0].date,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        CW_toDate: this.days[this.days.length - 1].date,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        CW_type: type
      }); //no direct url format, just provieds data to url to fill in. type = '' - returns alle types.

    this.cachedApi.getWebJSON(url, this.to.jsonPath, this.formId, this.to.header, this.to.useCredentials).subscribe(({ value, status, source }) => {

      if (status === DataStatus.Error) {
        //console.log(status);
        this.errorValues('Url', 'DateInfoAPIError');
      }
      else if (status === DataStatus.Updated) {
        //    const holidays = [];
        if (Array.isArray(value)) {
          value.forEach(v => {
            this.holidays.push(v['DatoYMD']);
          });
        }

        this.days.forEach(day => {
          day.isHoliday = this.holidays.includes(day.date); //Reset set everything to 0 or add/set holidays.
        });

      }
    });
  }

  getEvents() {
    if (!this.urls.eventsData) {
      this.errorValues('FormSetup', 'EventsUrlMissing');
      return;
    }

    //https://inb.tempus.no/api/inbrptregkalender?selskap=1&ansatt=Kim&fraDato=2022-06-27&tilDato=2022-08-08
    // https://inb.tempus.no/api/inbfrvperioder?selskap=1&logon=kim
    // &type=2&fraDato=2022-02-01&tilDato=2022-08-01

    /*  let urlAdd = `&${this.urlProps.fromDate}=${fromDate}&${this.urlProps.toDate}=${toDate}`;
      if(type && type > 0)
        urlAdd += `&${this.urlProps.type}=${type}`;
      const url = this.to.url + urlAdd;
    */
    //reset event
    this.events = [];
    this.loading.items = true;

    const url = this.util.parseText(this.urls.eventsData,
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        CW_fromDate: this.days[0].date,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        CW_toDate: this.days[this.days.length - 1].date,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        CW_type: this.selectedEventTypeValues()
      }); //no direct url format, just provieds data to url to fill in. type = '' - returns alle types.

    const obs = this.cachedApi.getWebJSON(url, '', this.formId, this.to.headers, this.to.useCredentials);
    obs.subscribe(({ value, status, source }) => {

      this.loading.items = false;
      if (status === DataStatus.Error) {
        //console.log(status);
        this.errorValues('Url', 'EventsAPIError');
      }
      else if (status === DataStatus.Updated) {
        //console.log('update!');
        if (value) {
          value.forEach(event => {
            const e = {
              fromDate: event[this.apiDataProps.fromDate],
              toDate: event[this.apiDataProps.toDate],
              title: event[this.apiDataProps.title],
              refId: event[this.apiDataProps.id],
              type: event[this.apiDataProps.type],
              tag: event[this.apiDataProps.tag]
            };
            this.drawEvent(e);
          });
        }
      }

      this.fieldService.triggerChange();
    });

  }

  buildMonths() {
    const months = [];
    //Build month structure without data.
    for (let i = 0; i < 12; i++) {
      months[i] = { name: this.monthName(i + 1), days: 0, selected: (this.calendar.periode.month === (i + 1)) };
    }
    this.months = this.util.createCopyOfObject(months);
    //add externalData.
    this.getMonthsData();
  }

  getMonthsData() {
    if (!this.urls.monthsData) {
      this.errorValues('FormSetup', 'MonthsUrlMissing');
      return;
    }
    //console.log(this.months);

    this.loading.month = true;

    //https://inb.tempus.no/api/inbsaldiferie?selskap=1&ansatt=Kim&type=Mnd
    //https://inb.tempus.no/api/inbfrvperioderfinnes?selskap=1&aar=2022&logon=kim

    const url = this.util.parseText(this.urls.monthsData,
                                      // eslint-disable-next-line @typescript-eslint/naming-convention
                                      { CW_periodeYear: this.calendar.periode.year,
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        CW_type: this.selectedEventTypeValues() }); //no direct url format, just provides data to url to fill in. type = '' returns all types.
    const obs = this.cachedApi.getWebJSON(url, '', this.formId, this.to.headers, this.to.useCredentials);
    obs.subscribe(({ value, status, source }) => {

      //console.log(status);
      if (status === DataStatus.Error) {
        //console.log(status);
        this.errorValues('Url', 'MonthsAPIError');
      }
      else if (status === DataStatus.Updated) {

        if (value && Array.isArray(value)) {
          value.forEach(ft => {
            //console.log(ft);
            if (ft[this.apiDataProps.monthData]) {
              const data = ft[this.apiDataProps.monthData].split(this.apiDataProps.valueSplit);
              for (let i = 0; i < 12; i++) {
                this.months[i].days += parseInt(data[i]) ?? 0;
              }
            }
          });
        }

      }
      this.loading.month = false;

    });

  }



  changeMonth(steps = 1) {
    if (!this.allow.changeMonth)
      return;
    const d = moment(this.calendar.periode.start.date).add(steps, 'months').toDate();
    this.buildCalenderDays(d);
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    //console.log('externalTrigger', type, data);
    if (type === TriggerType.Update) {
      const d = data || new Date(); // If watchData is empty (when triggerAPI is used with no data), a new Date() is added to trigger Watch (newValue).
      if (moment(d).isValid()) {
        this.buildCalenderDays(data);
        this.toggleMonthView(false);
      }
    }
    else if (type === TriggerType.Set) {
      //console.log('set');
      if (moment(data).isValid()) {
        this.buildCalenderDays(data);
        this.toggleMonthView(false);
      }
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }
  markDays(fromIndex: number, toIndex: number) {
    //console.log(fromIndex, toIndex);
    for (let i = 0; i < this.days.length; i++) {
      this.days[i].isHighlighted = (i >= fromIndex && i <= toIndex);
    }

  }
  markDay(dayIndex) {
    const selected = this.days[dayIndex] ?? null;
    //console.log(this.days);
    this.days.forEach(day => {
      //      //console.log(id, task);
      day.isSelected = (day.id === selected?.id && day.index === selected.index);
      day.isHighlighted = (day.id === selected?.id && day.isHighlighted) ? false : (day.id === selected?.id);
    });

    return selected;
  }


  pickEvent(eventsIndex) {
    const selected = this.events[eventsIndex] ?? null;
    //console.log(this.events);
    this.events.forEach(event => {
      //      //console.log(id, task);
      event.selected = (event.id === selected?.id && event.index === selected.index);
      event.highlight = (event.id === selected?.id && event.highlight) ? false : (event.id === selected?.id);
    });

    return selected;
  }
  calcOverflowPosInCalendar(element) {
    if (this.calendar.size.bottom === 0)
      this.updateCalendarSize();
    const box = this.calendar.size;
    const item = element.getBoundingClientRect();

    //Outside of area in direction X.
    //Minus is within the box. Plus value is X overflow (item needs to be moved/offset to fit inside box).
    const offset = {
      left: box.left - item.left,
      top: box.top - item.top,
      right: item.right - box.right,
      bottom: item.bottom - box.bottom
    };
    const validMove = {
      left: (offset.left < 0 && offset.left * -1 > item.width),
      top: (offset.top < 0 && offset.top * -1 > item.height),
      right: (offset.right < 0 && offset.right * -1 > item.width),
      bottom: (offset.bottom < 0 && offset.bottom * -1 > item.height),
    };
    const hasOverflow = (offset.left > 0 || offset.right > 0 || offset.top > 0 || offset.bottom > 0) ? true : false;
    return { hasOverflow: hasOverflow, validMove, ...offset };

  }

  calcMenuPos(element, valueObject, rechecks = 1) {

    const offset = this.calcOverflowPosInCalendar(element);
    //console.log(offset);
    if (!valueObject.offset.updated || (valueObject.offset.updated && offset.hasOverflow)) {
      if (offset.right > 0 || offset.left > 0 || offset.validMove.right)
        valueObject.offset.left = (offset.right > 0);
      if (offset.bottom > 0 || offset.top > 0 || offset.validMove.bottom)
        valueObject.offset.top = (offset.bottom > 0);
      valueObject.offset.updated = true;
    }
  }

  selectEvent(eventAction, type, itemData, index: number = null) {
    //console.log(eventAction, type, itemData, index);
    if (!this.allow.selectEvent)
      return;
    else
      eventAction?.stopPropagation();

    if (type === 'event') {
      this.clearMarkedDays(true);
      const selected = this.pickEvent(index);
      this.eventSelected = selected;
      //console.log(selected);

      if (selected && !selected.offset.updated) {
        const child = eventAction.currentTarget.children[1];
        this.calcMenuPos(child, selected);
      }

    }
    else {
      this.pickEvent(null);
    }

    if (type !== 'event' && itemData.isCurrentMonth) {
      if (type === 'weekdayNo') {
        //this.openSubform('new', itemData);
      }
      else if (type === 'day') {
        //  itemData.isSelected = !itemData.isSelected;
        //  this.daySelected.to = (itemData.isSelected) ? index: null;

        //        if FROM -> set TO
        //        if FROM NULL > set FROM
        //        if TO NULL -> set FROM
        this.selectDay(index);

        if (this.daySelected.from && this.daySelected.to) {
          this.markDays((this.selectedDayInView) ? this.daySelected.from.index : 0, this.daySelected.to.index);
          const pos = this.getEventPos(this.daySelected.to.data.date, this.daySelected.to.data.date);
          this.calendarMenu.row = pos[0].row;
          this.calendarMenu.col = pos[0].col;
          this.calendarMenu.data = this.periodeInfo(this.daySelected.from.data.date, this.daySelected.to.data.date);

          setTimeout(() => {
            //wait a gui update before check
            const child = this.calendarPeriodeMenu.nativeElement.children[0];
            this.calcMenuPos(child, this.calendarMenu);

          }, 0);

          setTimeout(() => {
            this.calendarMenu.show = true;
          }, 0);

        }
        else {
          this.clearMarkedDays(false);
        }
      }
    }
    else if (type !== 'event' && !itemData.isCurrentMonth) {
      //this.selectDay();
      const step = (moment(itemData.date).isAfter(this.calendar.periode.date)) ? 1 : -1;
      this.changeMonth(step);
    }

  //  console.log(type, itemData);

  }

  clearAllSelections() {
    this.clearMarkedDays(true);
    this.pickEvent(null);
  }

  clearMarkedDays(clearSelected = false) {
  //  console.log('clearSelected');
    this.markDays(0, -1);
    this.calendarMenu.show = false;
    if (clearSelected)
      this.selectDay();
  }
  selectDay(index = null) {
    if (!this.allow.selectDays)
      return;
    if (index === null) {
      this.daySelected.from = null;
      this.daySelected.to = null;
      this.updateSelectMode();
      return;
    }

    //console.log(this.daySelected);

    //Clear/reset when marking the same toDate twice.
    const sameDay = (this.daySelected.to) ? moment(this.daySelected.to.data.date).isSame(this.days[index].date, 'days') : false;
    if(sameDay) {
      this.daySelected.from = null;
      this.daySelected.to = null;
      this.updateSelectMode();
      return;
    }

    const pastDay = (this.daySelected.from) ? moment(this.daySelected.from.data.date).isAfter(this.days[index].date, 'days') : false;

    if (this.daySelected.to !== null || this.daySelected.from === null || pastDay) {
      this.daySelected.to = null;
      this.daySelected.from = { index: index, data: this.util.createCopyOfObject(this.days[index]) };
    }
    else if (this.daySelected.to === null) {
      this.daySelected.to = { index: index, data: this.util.createCopyOfObject(this.days[index]) };
      //  this.daySelected.sameMonth = (moment(this.daySelected.from.data.date).isSame(this.daySelected.to.data.date, 'month'));
    }
    else {
      this.daySelected.from = null;
      this.daySelected.to = null;
    }

    //console.log(this.daySelected);
    this.updateSelectMode();
  }

  updateSelectMode() {
    this.selectMode.mode = (this.daySelected.from && this.daySelected.to === null) ? 'active' : (this.daySelected.to) ? 'done' : 'off';
  }

  hasSubform(type, event) {
    let formId = false;
    if(this.subformTags[event?.tag] && !isNaN(+this.subformTags[event?.tag]))
      formId = this.subformTags[event?.tag];
    else if(this.subformTags[this.selectedEventTypeValues(true)] && !isNaN(+this.subformTags[this.selectedEventTypeValues(true)]))
      formId = this.subformTags[this.selectedEventTypeValues(true)];
    else if(this.subformTags[type] && !isNaN(this.subformTags[type]))
      formId = this.subformTags[type];

    //console.log(formId);
    return formId;
  }

  async openSubform(type, itemData) {
    //console.log(type, itemData);
    const event = (itemData?.id) ? this.eventInfo[itemData.id] : null; // no id = daySelected.
    //console.log(event);
    const subformid = this.hasSubform(type, event);

    // 1=Vanlig, 2=Ferie, 3=Permisjon, 4=Avspasering, 7=Sykemelding 1-16, 8=Egenmelding, 9=Egenmelding barn, 10=Aktiv sykemelding, 11=NAV

    if (!subformid)
      return;

    const values = {
      fromDate: (event) ? event.fromDate : this.selectMode.selected.from.data.date,
      toDate: (event) ? event.toDate : this.selectMode.selected.to.data.date,
      type: (event) ? event?.type : this.selectedEventTypeValues(true),
      listType: this.selectedEventTypeValues(true),
      id: event?.id,
      data: event?.data
    };

    //build object - keys sendt to subform.
    const keyValues = {};
    if (this.subformProps) {
      Object.keys(this.subformProps).forEach((key) => {
        if (values[this.subformProps[key]])
          keyValues[key] = values[this.subformProps[key]];
        //        delete Object.assign(valueKeys, {[this.to.subformProps[key]]: valueKeys[key] })[key];
      });
    }

    //console.log(this.to.subformProps);
    //console.log(keyValues);


/*
  let val = { ...itemData };
  if(type === 'new')
    val = { from: this.selectMode.selected.from.data.date, to: this.selectMode.selected.from.data.date, ...itemData};
*/  //console.log(keyValues);

    const modal = await this.modalCtrl.create({
      component: SubFormComponent,
      componentProps: {
        values: keyValues,
        formId: subformid,
        showSaveBtn: false
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      //console.log(data);
      this.buildCalenderDays(this.calendar.periode.date); // refresh list.
    }
  }

  toggleMonthView(show?: boolean) {
    if (!this.allow.changeMonth)
      return;

    this.displayMonthSelection = show ?? !this.displayMonthSelection;
    this.monthContainer.nativeElement.style.maxHeight = (this.displayMonthSelection) ? '100%' : '0px';
    //console.log(this.monthContainer);
  }

  toggleErrorDetails(show?: boolean) {
    this.errorInfo.showDetails = show ?? !this.errorInfo.showDetails;
  }


}
