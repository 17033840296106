import { Injectable, Injector } from '@angular/core';
import { FilePath, ModelMetaData } from '../models/models';
import * as _ from 'lodash';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntypedFormGroup } from '@angular/forms';
import { EvaluationService } from './evaluation.service';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';

/**
 * Different methods for doing tasks:
 * - **dotRef**: Get data from object
 * - **parseText**: Parse an text and replace with data from model
 * - **textIsParsed**: Checks if an text is fully parsed (e.g. not containing any {{...}} )
 * - **getNameAndPath**: Split an filepath into folderpath and filename
 */
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private injector: Injector) {}

  /**
   * Get data from object
   *
   * @param obj The object to get data from
   * @param path Path to the data in the object
   * @param defaultValue (Optional) Value to give if path is unresolver, default: `null`
   * @param replaceLineBreaks (Optional) If linebreaks should be replaced with `<br>`, default: `false`
   */
  dotRef(obj: any, path: string, defaultValue = null, replaceLineBreaks = false) {
    if (!obj) {
      return defaultValue;
    }
    else if (!path) {
      return obj;
    }
    path = path.trim();

    let res = _.get(obj, path, defaultValue);
    if (!res) {
      if (path === 'data') {
        res = obj;
      }
      else if (path.startsWith('data.')) {
        path = path.slice(5);
        res = _.get(obj, path, defaultValue);
      }
    }
    if (replaceLineBreaks && typeof res === 'string') {
      res = res.replace(/(?:\\r\\n|\\r|\\n)|(?:\r\n|\r|\n)/g, '<br>');
    }
    return res;
  }

  /**
   * Set data in object with path
   *
   * @param obj The object to set value in
   * @param path The path to where to set the value
   * @param value The value to set
   */
  dotSet(obj: any, path: string, value: any) {
    if (!obj || !path) {
      return obj;
    }
    return _.set(obj, path, value);
  }

  /**
   * Parse an text and replace with data from model
   *
   * @param text The text to parse
   * @param model The model with data, or string with text to replace all occurences with
   * @param fields (Optional) The fields of the current form
   * @param missing (Optional) Replacement value when the data doesn't exist
   * @param regEx (Optional) Regex to search with (default: `/{{(.*?)}}/g`)
   * @param replaceLineBreaks (Optional) If linebreaks should be replaced with `<br>`, default: `false`
   */
  parseText(text: string, model: any | string, fields: FormlyFieldConfig[] = [], missing?: string, regEx = /{{(.*?)}}/g, replaceLineBreaks = false): string {
    if (model && typeof model !== 'string') {
      const translate = this.injector.get(TranslateService);
      model = this.createCopyOfObject(model);
      model[ModelMetaData.Language] = translate.currentLang;
    }
    return text?.replace(regEx, (s: string, m1: string) => {
      let rep = (typeof missing === 'undefined' || missing === null) ? s : missing;
      const [key, prop, propMiss] = m1.split(':');
      if (typeof propMiss !== 'undefined') {
        rep = propMiss;
      }
      let value: any;
      if (typeof model === 'string') {
        value = model;
      }
      else {
        value = this.dotRef(model, key, null, replaceLineBreaks);
      }

      if (typeof value === 'object' && prop) {
        value = this.dotRef(value, prop, null, replaceLineBreaks);
      }
      if (Array.isArray(value)) {
        value = this.convertArrayValue(value, key, fields);
      }
      if (typeof value === 'undefined' || value === null) {
        value = rep;
      }
      return value;
    });
  }

  /**
   * Checks if an text is fully parsed (e.g. not containing any {{...}} )
   *
   * @param text The url to check
   * @param missing (Optional) Use this for searching instead of RegEx
   * @param regEx (Optional) Regex to search with (default: `/{{(.*?)}}/g`)
   */
  textIsParsed(text: string, missing?: string, regEx = /{{(.*?)}}/g): boolean {
    const matches =  text.match(regEx);
    if (missing && text.includes(missing)) {
      return false;
    }
    else if (!matches || matches.length === 0) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Convert an array value to string if the field exists and it should not be stored as JSON
   * @param value The value to convert
   * @param key The key of the fields the value belong to
   * @param fields The fields of the form
   */
  convertArrayValue(value: any[], key: string, fields: FormlyFieldConfig[]) {
    const field = fields.find(f => f.key === key);
    if (field && !field.templateOptions?.storeAsJson) {
      const splitter = field.templateOptions?.splitter || ',';
      return value.join(splitter);
    }
    return value.toString();
  }

  /**
   * Split an filepath into folderpath and filename
   *
   * @param filepath The full file path
   */
  getNameAndPath(filepath: string): FilePath {
    if (!filepath || !filepath.includes('/')) {
      return null;
    }
    const name = filepath.substr(filepath.lastIndexOf('/') + 1);
    const path = filepath.substr(0, filepath.lastIndexOf('/') + 1);
    return {
      filename: name,
      path: path
    };
  }

  /**
   * Create an copy of an object.
   *
   * @param object The object to copy
   */
  createCopyOfObject<T>(object: T): T {
    return _.cloneDeep(object);
  }

  /**
   * Performs deep equality check on objects
   *
   * @param value1 The first value
   * @param value2 The second value
   */
  deepEqual(value1: any, value2: any) {
    return _.isEqual(value1, value2);
  }

  uniqueValues<T>(values: T[]): T[] {
    return _.uniq(values);
  }

  uniqueObjects<T>(values: T[], path: string): T[] {
    return _.uniqBy(values, (x) => this.dotRef(x, path));
  }

  /**
   * Wait for a length of time
   *
   * @param length How long to wait, in ms
   */
  wait(length: number): Promise<void> {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, length);
    });
  }

  /**
   * Checks if an version is newer than an other. Returns true if new supposed newer version is newer
   *
   * @param oldVersion The supposed older version
   * @param newVersion The supposed newer version
   */
  isNewerVersion(oldVersion: string, newVersion: string) {
    if (!oldVersion || !newVersion) {
      return false;
    }
    const oldParts = this.getVersionParts(oldVersion);
    const newParts = this.getVersionParts(newVersion);
    for (let i = 0; i < 3; i++) {
      if (newParts[i] > oldParts[i]) {
        return true;
      }
      else if (newParts[i] < oldParts[i]) {
        return false;
      }
    }
    return false;
  }

  /**
   * Checks if an version is newer or the same as an other. Returns true if new supposed newer version is newer
   *
   * @param oldVersion The supposed older version
   * @param newVersion The supposed newer version
   */
  isNewerOrSameVersion(oldVersion: string, newVersion: string) {
    if (!oldVersion || !newVersion) {
      return false;
    }
    if (this.isNewerVersion(oldVersion, newVersion)) {
      return true;
    }
    const oldParts = this.getVersionParts(oldVersion);
    const newParts = this.getVersionParts(newVersion);
    return oldParts[0] === newParts[0] && oldParts[1] === newParts[1] && oldParts[2] === newParts[2];
  }

  /**
   * Create hash of string
   *
   * @param str The string to create hash from
   */
  hashString(str: string): string {
    let hval = 0x811c9dc5;

    for (let i = 0, l = str.length; i < l; i++) {
        // eslint-disable-next-line no-bitwise
        hval ^= str.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    // eslint-disable-next-line no-bitwise
    return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
  }

  /**
   * Parses an definition of an form into fields
   *
   * @param definition The form definition to parse
   * @param onlyVisible (Optional) If it should only return the fields that are visible from start, default: false
   */
  parseFields(definition: string, onlyVisible: boolean = false) {
    if (!definition) {
      return [];
    }
    const evalService = this.injector.get(EvaluationService);
    const logger = this.injector.get(NGXLogger);
    try {
      let fields: FormlyFieldConfig[] = JSON.parse(definition);
      if (onlyVisible) {
        fields = fields.filter((field) => {
          if (field.className?.includes('hide') || field.hide) {
            return false;
          }
          else if (typeof field.hideExpression !== 'undefined') {
            return !evalService.evalHideExpression(field.hideExpression, field.hide, {}, new UntypedFormGroup({}));
          }
          else {
            return true;
          }
        });
      }
      return fields;
    }
    catch {
      logger.error('Error parsing form definition', definition);
      return [];
    }
  }

  /**
   * Generate an unique id. On the form `<nr>_<nr>` if no prefix, or `prefix_<nr>_<nr> if prefix is given
   * @param prefix (Optional) Prefix to use for the id
   */
  generateId(prefix: string = '') {
    const id = _.uniqueId();
    const time = Date.now();
    return prefix ? `${prefix}_${time}_${id}` : `${time}_${id}`;
  }

  /**
   * Get the main part of an endpoint from the url.
   *
   * @example getEndpoint('https://inb.tempus.no/api/inbfieldinit?selskap=1') === 'tempus.no'
   *
   * @param url The url
   */
  getEndpoint(url: string) {
    if (!url || !url.includes('.')) {
      return '';
    }
    const partIndex = url.startsWith('http') ? 1 : 0;
    const parts = url.split('/').filter(p => !!p)[partIndex]?.split('.');
    return (parts.length > 2) ? parts.slice(-3).join('.') : parts.join('.');
  }

  /**
   * Round number to a more userfriendly value. fixes round of problems like: 0.002 -> 0,019999999999999574
   * @param val Value to round of
   * @param maxDecimals Number of max decimals allowed. Will not append extra 0.
   * @returns Rounded of value.
   */
  roundNumber(val: number, maxDecimals: number = 0): number {
    if(maxDecimals === 0)
      return Math.round(val);

    const s = val.toString().split('.');
    const currDecimals = (s.length > 1) ? s[1].length : 0;
    if(currDecimals <= maxDecimals)
        return val;

    const addZero = (10 * 10) ** maxDecimals;
    const whole = Math.round(val);
    let left = val - whole;
    left = Math.round(left * addZero);
    const r = (left / addZero);
    let res = whole + r;
    //console.log(res);

    // extra check if too many decimals. E.g. -1 + 0.42 = -0.5800000000000001 ...
    const s2 = res.toString().split('.');
    const currDecimals2 = (s2.length > 1) ? s2[1].length : 0;
    if(currDecimals2 > maxDecimals) {
      const st = s2[0] + '.' + s2[1].substring(0, maxDecimals);
      res = parseFloat(st);
    }


    return res;
  }

  /**
   * Simple one key option filter.
   * @param data arraylist
   * @param keyValues array of keyvalues json object to filter on.
   * @returns filtered data.
   */
  filterList(data: any[], keyValues: {
    'key': string,
    'values': any[],
    'notValid': boolean
  }[]) {
    if(!Array.isArray(data) || !Array.isArray(keyValues) || (Array.isArray(keyValues) && keyValues.length === 0))
      return data;

    const groupFilter = this.groupByKey(keyValues, 'groupId');
    const gArr = Object.entries(groupFilter);

    return data.filter((d: any) => {
      let isValid = true;

      for(const [groupKey, group] of gArr) {
        let groupValid = false;
        for(const f of group) {
          groupValid = (f.notValid ?? false) ?
                !f.values.includes(d[f.key] || ''):
                f.values.includes(d[f.key] || '');

          if((groupKey !== '_noKey_' && groupValid) || (groupKey === '_noKey_' && !groupValid) ) // groups uses OR (any true is valid), non-groups uses AND (any false is invalid).
            break;
        }
        isValid = groupValid;

        if(!isValid)
          break;
      }

      return isValid;
    });

  }

  groupByKey(array, key): any[] {
    if(array && !Array.isArray(array))
      array = [array];

    return array.reduce((result, currentValue) => {
      const arrKey = currentValue[key] || '_noKey_';
      (result[arrKey] = result[arrKey] || []).push(
        currentValue
      );
      return result;
    }, {});
  }

  async createBlob(image: string, isBase64 = true) {
    const url = isBase64 ? `data:image/jpeg;base64,${image}` : image;
    const data = await fetch(url);
    return data.blob();
  }

  private getVersionParts(version: string): number[] {
    const parts = version.split('.').map(part => part ? +part : NaN);
    if (parts.length !== 3 || parts.includes(NaN)) {
      throw new Error(`Version number is not on correct form: ${version}`);
    }
    return parts;
  }

}
