import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { CameraErrorType } from '../models/enums/cameraErrorType';
import { PopupService } from './popup.service';
import { UtilityService } from './utility.service';
import { ApiStatus, CameraResult, FilePath } from '../models/models';
import { BrowserService } from './browser.service';
import { Camera, CameraDirection, CameraResultType, CameraSource, ImageOptions, Photo } from '@capacitor/camera';
import { InStorageService } from './in-storage.service';
import { ApiService } from './api.service';
import { FileService } from './file.service';
import { Directory } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  options: ImageOptions = {
    quality: environment.photoQuality,
    saveToGallery: false,
    correctOrientation: true,
    direction: CameraDirection.Rear,
    resultType: CameraResultType.Uri,
  };

  constructor(
    private popup: PopupService,
    private logger: NGXLogger,
    private translate: TranslateService,
    private util: UtilityService,
    private browser: BrowserService,
    private storage: InStorageService,
    private api: ApiService,
    private file: FileService
  ) { }

  /**
   * Get picture file from camera.
   */
  async getFromCamera(useFrontCamera = false): Promise<CameraResult> {
    const hasPermission = await this.checkPermissions('camera');
    if (!hasPermission) {
      return {path: null, image: null, url: null};
    }
    const direction = useFrontCamera ? CameraDirection.Front : CameraDirection.Rear;
    const options: ImageOptions = {...this.options, ...{source: CameraSource.Camera, direction: direction}};
    const result = await this.takePhoto(options);
    return result;
  }

  /**
   * Get picture file from gallary
   */
  async getFromGallery(): Promise<CameraResult> {
    const hasPermission = await this.checkPermissions('photos');
    if (!hasPermission) {
      return {path: null, image: null, url: null};
    }
    const options: ImageOptions = {...this.options, ...{source: CameraSource.Photos}};
    const result = await this.takePhoto(options);
    return result;
  }

  async storePhoto(filePath: FilePath): Promise<string> {
    const storedPhoto = await this.file.copyFile(`${filePath.path}/${filePath.filename}`, filePath.filename, null, Directory.Data);
    this.logger.debug('File url:', storedPhoto);

    const displayPhoto = this.browser.getBrowserUrlFromFile(storedPhoto);
    this.logger.debug('Converted url:', displayPhoto);

    const offlinePhoto = {path: '', isNew: true, filename: filePath.filename };
    this.storage.setWithKey(displayPhoto, offlinePhoto);
    return displayPhoto;
  }

  async readAndUploadPhoto(key: string): Promise<string> {
    const photo: {path: string, isNew: boolean, filename: string} = await this.storage.getWithKey(key);
    if (photo?.filename && (photo.isNew || photo.path)) {
      try {
        const dir = photo.isNew ? Directory.Data : null;
        const path = photo.isNew ? photo.filename : `${photo.path}/${photo.filename}`;
        const file = await this.file.readFile(path, dir);
        const blob = await this.util.createBlob(file);
        const url: string = await this.uploadPhoto(blob, photo.filename);
        if (url) {
          await this.file.deleteFile(path, dir);
          await this.storage.removeWithKey(key); // remove uploaded photo from storage
          return url;
        }
        else {
          return null;
        }
      }
      catch (err) {
        this.logger.error('Failed reading/uploading offline photo', err);
        return null;
      }
    }
    else {
      return null;
    }
  }

  private uploadPhoto(photo: Blob, filename: string): Promise<string> {
    return new Promise<string>(resolve => {
      this.api.uploadPhoto(photo, filename).subscribe(({status, value}) => {
        if (status === ApiStatus.Success) {
          resolve(value);
        }
        else {
          resolve(null);
        }
      });
    });
  }

  private async checkPermissions(type: 'camera' | 'photos') {
      let data = await Camera.checkPermissions();
      if (data[type] === 'granted') {
        return true;
      }
      data = await Camera.requestPermissions({permissions: [type]});

      if(data[type] === 'granted')
        return true;
      else {
        this.popup.showMessage(this.translate.instant('MissingPermission'), false, 'danger');
        return false;
      }

  }

  private async takePhoto(cameraOptions?: ImageOptions): Promise<CameraResult> {
    const options: ImageOptions = {...this.options, ...cameraOptions};
    try {
      const image: Photo = await Camera.getPhoto(options);
      const filePath = this.util.getNameAndPath(image?.path);
      const blob = await this.util.createBlob(image.webPath, false);
      const url = this.browser.createImageUrl(blob);
      return {
        path: filePath,
        image: blob,
        url: url
      };
    }
    catch (err) {
      const txt = this.checkError(err);
      if (txt) {
        this.logger.error(`cameraError: `, err);
        this.popup.showMessage(txt, false, 'danger');
      }
      return {path: null, image: null, url: null};
    }
  }

  private checkError(error: string | Error): string {
    let errMsg: string;
    if (typeof error === 'string') {
      errMsg = error.toLowerCase();
    }
    else {
      errMsg = error.message.toLowerCase();
    }

    if (errMsg.includes(CameraErrorType.Canceled) || errMsg.includes(CameraErrorType.NoPick)) {
      // Do nothing. Not an error.
      return null;
    }
    // eslint-disable-next-line eqeqeq
    else if (errMsg == CameraErrorType.PermissionMissingAndroid || errMsg === CameraErrorType.PermissionMissingIos) {
      return this.translate.instant('MissingPermission');
    }
    else if (errMsg === CameraErrorType.NoCamera) {
      return this.translate.instant('MissingCamera');
    }
    else {
      return 'Error: ' + errMsg;
    }
  }
}
