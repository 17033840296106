import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { LegacyService } from './legacy.service';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LoadingService } from './loading.service';
import { OnlineService } from './online.service';
import { NGXLogger } from 'ngx-logger';
import { InStorageService } from './in-storage.service';
import { environment } from 'src/environments/environment';
import { TimeService } from './time.service';
import { ComponentRefService } from './component-ref.service';
import { PopupService } from './popup.service';
import { UntypedFormGroup } from '@angular/forms';
import { StateService } from './state.service';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { AutosaveService } from './autosave.service';
import { ValidationService } from './validation.service';
import { UtilityService } from './utility.service';
import { CachedApiService } from './cached-api.service';
import { map, mergeMap } from 'rxjs/operators';
import { FormlyPage, FormType, Step, Project, CachedData, FormWithValues, DataStatus, Value, PageRefs,
         IonColor, DataSource, StyleData, NewReg, TriggerType, RegSuccess, Fields, ApiStatus,
         ModelMetaData, StylePaths, StepRequired, OnlineStatus } from '../models/models';
import { isValidField, shouldKeepMetaData } from '../helpers/helperFunctions';
import { EvaluationService } from './evaluation.service';
import { AuthService } from './auth.service';
import { DeviceService } from './device.service';
import { LanguageService } from './language.service';
import { SetVarsService } from './set-vars.service';
import { Router } from '@angular/router';
import { CameraService } from './camera.service';
import { UpdateKeyReg } from '../models/interfaces/registration';

interface ApiTrigger {
  keys?: string[] | string;
  formId?: number | string;
  formType?: FormType;
}

/**
 * Service for doing tasks related to forms
 *
 * **Important:** Must be included in providers in the component that uses it
 */
@Injectable()
export class FormService implements OnDestroy {
  /**Fields that have to trigger clear when they are cleared */
  fieldsToClear = [Fields.Signature, Fields.Paint, Fields.Photo, Fields.ValueList, Fields.ItemList, Fields.Barcode];
  page: FormlyPage;
  formType: FormType;
  steps: Step[] = [{
    nr: 0,
    index: 0,
    label: 'Start'
  }];
  currentStep = 0;
  lastSeenStep = 0;
  formId: number;
  currentProject: Project;
  doAutosave = true;

//  gestureSwipefield: FormlyFieldConfig;
  gestureSwipefield = new BehaviorSubject<FormlyFieldConfig | string>(null);

  pageObserver: IntersectionObserver; // load more fields
  fieldsLoading = true;
  isEmpty = false;
  reachedEnd = false;
  currPage = 0;
  addedFieldIndex = 0;
  pageSize = 20;
  pageLoadTrigger = 6;
  watchElement: any;
  isInitalizingForm: BehaviorSubject<boolean> = new BehaviorSubject(false);
  formData: any;

  lastRegFields: string[] = [];
  hadServerValues = false;

  checkRequiredOnStepForward: StepRequired = StepRequired.Warn;

  get hasSteps(): boolean {
    return this.steps.length > 1;
  }

  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private loading: LoadingService,
    private online: OnlineService,
    private logger: NGXLogger,
    private storage: InStorageService,
    private device: DeviceService,
    private legacy: LegacyService,
    private timeService: TimeService,
    private ref: ComponentRefService,
    private popup: PopupService,
    private stateService: StateService,
    private autosave: AutosaveService,
    private validation: ValidationService,
    private cachedApi: CachedApiService,
    private util: UtilityService,
    private evalService: EvaluationService,
    private auth: AuthService,
    private language: LanguageService,
    private setVarsService: SetVarsService,
    private router: Router,
    private ngZone: NgZone,
    private camera: CameraService
  ) { }

  // Clean up references, state and subscriptions
  ngOnDestroy() {
    this.clearRefs();
    this.stopAutosave(false);
    this.setVarsService.popForm();

    this.stateService.currentForm = null;

    if (this.pageObserver) {
      this.pageObserver.disconnect();
    }
  }


  /**
   * Disable fields that should be disabled
   * @param fields The fields of the form
   * @param status Status to set
   */
  disableFields(fields: FormlyFieldConfig[], status: boolean) {
    const disableFields = [Fields.Button, Fields.Photo, Fields.Paint, Fields.GPS, Fields.Signature,
                           Fields.Rating, Fields.TimePicker, Fields.Barcode, Fields.NFC, Fields.D4Go, Fields.D4Saldi];
    for (const field of fields) {
      const type = field.type as string;
      if (isValidField(type) && field.templateOptions && disableFields.includes(type)) {
        field.templateOptions.disabled = status;
      }
    }
  }

  /**
   * Init the service with component info
   * @param page The component using the service
   * @param formType The type of Form
   */
  init(page: any, formType: FormType) {
    this.page = page;
    this.formType = formType;
  }

  /**
   * Get form from cached API and then initalize it
   * @param id The id of the form to get
   * @param project Current project
   * @param formData FormData to include as values
   * @param offlineValues (Optional) Offline values to include as values
   */
  getCachedFormAndInit(id: number, project: Project, formData: any, offlineValues?: Value[]): Observable<CachedData<FormWithValues>> {
    this.currentProject = project;
    let failed = false;
    return this.cachedApi.getForm(id).pipe(
      mergeMap(async ({value: form, status, source}) => {
        if (form && (failed || status === DataStatus.Updated)) {
          try {
            this.clearForm();
            await this.initForm(form, formData, offlineValues);
            failed = false;
          }
          catch (err) {
            this.logger.error(err);
            failed = true;
          }
        }
        else if (source === DataSource.API) {
          await this.waitForInit(false, 100);
        }
        if (failed) {
          if (source === DataSource.API) {
            this.popup.showMessage('InitError', true, 'danger');
          }
          return {value: null, source: source, status: DataStatus.Error};
        }
        else {
          return {value: form, source, status};
        }
      })
    );
  }

  /**
   * Initalize an form in an page
   * @param form The form to initalize on the page
   * @param formData Optional data to init the form with
   * @param offlinevalues (Optional) Stored offline values to add to the form
   */
  async initForm(form: FormWithValues, formData?: any, offlinevalues: Value[] = []) {
    this.waitForInit(true);

    this.isInitalizingForm.next(true);
    // this.page.formGroup = new FormGroup({});
    this.hadServerValues = form.values.length > 0;
    this.stateService.currentForm = {id: form.theForm.id, name: form.theForm.name};
    this.doAutosave = true;
    this.formId = form.theForm.id;
    this.formData = formData;
    this.currentStep = 0;
    this.lastSeenStep = 0;
    this.steps = [{
      nr: 0,
      index: 0,
      label: 'Start'
    }];
    this.steps[0].label = this.translate.instant('StartOfForm');
    this.page.untouchedForm = this.util.createCopyOfObject(form.theForm);
    this.page.form = form.theForm;
    let fields = this.util.parseFields(this.page.form.definition);
    fields = this.language.translateFields(fields);
    fields = this.legacy.fixFields(fields);
    this.fixUnknownFields(fields, form.theForm.id); // sets unknown fields to the 'unknown' field type
    this.fixMonthInput(fields);
    // Gets values from eventual tmp stored offline reg
    if (this.formType === FormType.Form && !this.online.isOnline()) {
      const offline = this.stateService.offlineRegs.filter(r => r.FormId === form.theForm.id);
      if (offline.length > 0) {
        const lastReg = offline[offline.length - 1];
        if (!lastReg.IsSigned) {
          form.values = lastReg.ValueList;
        }
        else {
          form.values = [];
        }
      }
    }

    // Disabling fields in registration page
    if (this.formType === FormType.Registration) {
      // Disable fields with button-triggers.. rest is disabled in css.
      this.disableFields(fields, true);
    }
    for (const field of fields) {
      if (!field.templateOptions) {
        field.templateOptions = {};
      }
      field.templateOptions.currentForm = {
        id: this.formId,
        type: this.formType,
        projectId: this.currentProject?.id
      };
      if (this.formType === FormType.Form) {
        field.templateOptions.currentForm.ref = PageRefs.Form;
      }
      else if (this.formType === FormType.FormView) {
        field.templateOptions.currentForm.ref = PageRefs.FormView;
      }
      else if (this.formType === FormType.SubForm) {
        field.templateOptions.currentForm.ref = PageRefs.SubForm;
      }
    }

    this.stateService.addFields(this.formType, this.formId, fields);

    // Finding steps in form page
    if (this.formType === FormType.Form) {
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].type === Fields.Step) {
          if (!fields[i].templateOptions.notInclude) {
            this.steps.push({
              nr: this.steps.length,
              index: i + 1,
              label: fields[i].templateOptions.label,
              color: this.validation.validColor(fields[i].templateOptions.colorType)
                        ? fields[i].templateOptions.colorType
                        : IonColor.Primary,
              fill: fields[i].templateOptions.fill || 'outline',
              swipe: fields[i].templateOptions.gesture?.type?.toLocaleLowerCase() === 'swipe'
            });
          }
        }
      }
      if (this.steps.length > 1) {
        const noLastStep = [...fields].reverse().find(f => f.type === Fields.StyleForm && f?.templateOptions.noLastStep);
        if (noLastStep) {
          this.page.haveLastStep = false;
        }
      }
    }

    // Get values from styleform
    await this.setStyle(this.page, fields, form.values);


    // Adds formdata to values
    if (formData) {
      for (const [key, value] of Object.entries<any>(formData)) {
        this.addValue(key, value, form.values, true);
      }
    }

    if (offlinevalues.length > 0) {
      for (const value of offlinevalues) {
        this.addValue(value.key, value.value, form.values, true);
      }
    }

    if (this.doAutosave && (this.formType === FormType.Form || this.formType === FormType.FormView) && this.currentProject) {
      const autosave = await this.autosave.getAutosave(form.theForm.id, this.currentProject.id);
      if (autosave) {
        if (this.formType === FormType.Form) {
          this.page.hadAutosave = true;
        }
        for (const [key, value] of Object.entries(autosave)) {
          const index = form.values.findIndex(v => v.key === key);
          if (index !== -1) {
            form.values[index].value = value;
          }
          else {
            const val = {
              key: key,
              value: value,
              formId: 0
            };
            form.values.push(val);
          }
        }
      }
      else if (this.formType === FormType.Form) {
        this.page.hadAutosave = false;
      }
    }

    // Set init values if they exist
    if (form.values.length > 0) {
      this.lastSeenStep = this.steps.length - 1;
      this.initValues(fields, form.values);
      if (this.formType === FormType.Registration) {
        const pressedButton = form.values.find(v => v.key === 'pressed')?.value;
        if (pressedButton) {
          this.page.model['pressed'] = pressedButton;
        }
      }
    }

    if (this.doAutosave && (this.formType === FormType.Form || this.formType === FormType.FormView) && this.currentProject) {
      this.autosave.startAutosave(this.formId, this.currentProject.id, this.page.formGroup, this.page.model, fields, this.hasSteps);
    }

    if (this.formType === FormType.Form) {
      this.page.allFields = fields;
      const stepFields = (this.steps.length > 1) ? fields.slice(0, this.steps[1].index) : fields;
      this.initFields(stepFields);
    }
    else if (this.formType === FormType.Setup) {
      this.page.fields = fields;
    }
    else {
      this.initFields(fields);
    }
    this.findGestureKeys();
    this.setVarsService.pushForm(this.formType, form.theForm.id);
    this.isInitalizingForm.next(false);
  }

  /**
   * Will ask user to confirm, and then clear form and reinitialize form without server values
   * @param values (Optional) Values to use as values when doing init, default: `[]`
   * @param confirmed (Optional) If the reinit is already confirmed, default: false
   */
  async reInitForm(values: Value[] = [], confirmed = false): Promise<boolean> {
    confirmed ||= await this.popup.showConfirm('ClearForm', 'ClearFormMsg', true);
    if (confirmed) {
      if (this.formType === FormType.Form || this.formType === FormType.FormView) {
        await this.clearStoredValues(this.formId, this.currentProject.id, this.hadServerValues, true);
      }
      this.clearForm(true);
      const form: FormWithValues = {
        theForm: this.page.untouchedForm,
        values: values
      };
      await this.initForm(form);
    }
    return confirmed;
  }

  /**
   * Clear stored values of an form
   * @param formId The id of the form to clear values from
   * @param projectId The id of project the form belongs t
   * @param clearTmpStore If tmp stored values on server should be cleared
   * @param clearAutosave If autosaved values should be cleared
   */
  async clearStoredValues(formId: number, projectId: number, clearTmpStore: boolean, clearAutosave: boolean) {
    if (clearAutosave) {
      await this.autosave.stopAutosave(formId, projectId, true);
    }
    if (clearTmpStore) {
      await this.storage.clearValuesOfForm(formId);
      const {status} = await firstValueFrom(this.api.deleteUnsignedRegistrations(formId), {defaultValue: {value: false, status: ApiStatus.Failed}});
      if (status === ApiStatus.Failed) {
        this.popup.showMessage('FailedDeletingTmpStore', true, 'danger');
      }
    }
  }

  /**
   * Init lazy loading of fields
   * @param fields The fields of the form/step
   */
  initFields(fields: FormlyFieldConfig[]) {
    this.isEmpty = (fields.length === 0);
    this.reachedEnd = this.isEmpty;

    // Move styleForm fields to top to ensure they are loaded at start.
    let searchIndex = 0;
    let styleIndex = fields.map(f => f.type).indexOf(Fields.StyleForm, searchIndex);
    while (styleIndex >= 0) {
      const styleForm = fields.splice(styleIndex, 1)[0];
      fields.unshift(styleForm);
      searchIndex++;
      styleIndex = fields.map(f => f.type).indexOf(Fields.StyleForm, searchIndex);
    }

    this.page.activeFields = [];
    this.page.fields = fields;
    if (fields.length > 0) {
      this.currPage = 0;
      this.addedFieldIndex = 0;
      this.pagingFields();
    }
  }

  /**
   * Update active fields on init or scroll
   */
  pagingFields() {
    const from = 0;
    this.addedFieldIndex = this.findVisibleFields(this.page.fields,
                                                  this.page.model,
                                                  this.page.formGroup,
                                                  this.addedFieldIndex,
                                                  this.pageSize);

    if (this.addedFieldIndex < this.page.fields.length) {
      this.page.activeFields = this.page.fields.slice(from, this.addedFieldIndex);
      setTimeout(() => {
        const fieldEls = document.querySelectorAll('.ion-page:not(ion-app):not(.ion-page-hidden) form formly-group formly-field');
        this.logger.debug(`Field elements: ${fieldEls.length}`);
        const watchEl = this.findVisibleElement(fieldEls,
                                                this.page.fields,
                                                fieldEls.length - this.pageLoadTrigger,
                                                this.page.model,
                                                this.page.formGroup);
        if (!watchEl) {
          this.pagingFields();
        }
        else {
          this.addPageObserver(watchEl);
        }
      });
    }
    else {
      this.page.activeFields = this.page.fields;
      this.reachedEnd = true;
    }
  }

  /**
   * Add intersection observer on given element that will add fields when it comes into view
   * @param element The element to watch for. It is optional if it's already set
   */
  addPageObserver(element?: any) {
    if (element) {
      this.watchElement = element;
    }
    if (!this.watchElement) {
      return;
    }
    if (this.pageObserver) {
      this.pageObserver.disconnect(); // rebuild.
    }

    this.pageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
        //  this.logger.debug('Leaving');
        }
        else {
        //  this.logger.debug('Entering');
          this.pagingFields();
        }
      });

    });

    this.pageObserver.observe(this.watchElement);
  }

  /**
   * Find a given number of **visible** fields starting from an index
   * @param fields The fields to check
   * @param model The current model
   * @param formGroup The current formGroup
   * @param from  Start index
   * @param pageSize How many fields should be found
   */
  findVisibleFields(fields: FormlyFieldConfig[], model: any, formGroup: UntypedFormGroup, from: number, pageSize: number): number {
    let count = 0;
    for (let i = from; i < fields.length; i++) {
      const field = fields[i];
      if (!this.evalService.evalHideExpression(field.hideExpression, field.hide, model, formGroup)) {
        count++;
        if (count === pageSize) {
          return i + 1;
        }
      }
    }
    return fields.length;
  }

  /**
   * Find element of first visible field
   * @param elements The elements to check
   * @param fields The fields of the form
   * @param index Index to start from
   * @param model Model of the form
   * @param formGroup FormGroup of the form
   */
  findVisibleElement(elements: NodeListOf<Element>, fields: FormlyFieldConfig[], index: number, model: any, formGroup: UntypedFormGroup): Element {
    for (let i = index; i >= 0; i--) {
      const field = fields[i];
      if (!field || field.type === Fields.AdmVerify || field.type === Fields.StyleForm || field.type === Fields.Step) {
        continue;
      }
      if (field.className?.includes('hide')) {
        continue;
      }
      const hide = this.evalService.evalHideExpression(field.hideExpression, field.hide, model, formGroup);
      if (!hide) {
        return elements[i];
      }
    }
    return null;
  }

  /**
   * Add fields to page until wanted field is shown
   * @param key Key of field
   */
  loadFieldsUntilKey(key: string) {
    if (this.page.fields.map(field => field.key).includes(key)) {
      while (!this.page.activeFields.map(field => field.key).includes(key)) {
        this.pagingFields();
      }
    }
  }

  /**
   * Load fields to page until end is reached
   *
   * @param waitLength (Optional) Give this (in ms) to wait a bit for the fields to load
   */
  async loadFieldsUntilEnd(waitLength?: number) {
    while (!this.reachedEnd) {
      this.pagingFields();
    }
    if (waitLength) {
      await this.util.wait(waitLength);
    }
  }

  /**
   * Set styling for form from styleForm fields
   * @param page The form to style
   * @param fields Fields of the form
   * @param values The values of the form
   */
  async setStyle(page: FormlyPage, fields: FormlyFieldConfig[], values: Value[]) {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.type === Fields.StyleForm && !field.hide && field.templateOptions) {
        if (this.formType === FormType.Form) {
          if (field.templateOptions.showAllSteps) {
            this.lastSeenStep = this.steps.length - 1;
          }
          if (field.templateOptions.allowFormCopies) {
            page.allowFormCopies = true;
          }
          if (field.templateOptions.hideSignArea && i >= this.steps[this.steps.length - 1].index) {
            this.page.hideSignButtons = true;
          }
          if (field.templateOptions.checkRequiredOnStep === 'off') {
            this.checkRequiredOnStepForward = StepRequired.Off;
          }
          else if (field.templateOptions.checkRequiredOnStep === 'error') {
            this.checkRequiredOnStepForward = StepRequired.Error;
          }
        }
        if (this.formType === FormType.FormView) {
          if (field.templateOptions.hideSignArea) {
            this.page.hideSignButtons = true;
          }
          if (typeof field.templateOptions.clearFormBoxOnReg === 'boolean') {
            page.clearFormOnReg = field.templateOptions.clearFormBoxOnReg;
          }
        }
        if (this.formType === FormType.Form || this.formType === FormType.FormView) {
          if (field.templateOptions.noTmpStore) {
            this.page.noTmpStore = true;
            this.doAutosave = false;
          }
          if (typeof field.templateOptions.noAutosave === 'boolean') {
            this.doAutosave = !field.templateOptions.noAutosave;
          }
          else if (this.doAutosave) {
            this.doAutosave = this.canRegForm(fields, this.page.hideSignButtons);
          }
          if (!this.doAutosave) {
            await this.stopAutosave(true);
          }
        }
        if (this.formType === FormType.Form || this.formType === FormType.Registration) {
          if (typeof field.templateOptions.showPrint === 'boolean') {
            page.showPrint = field.templateOptions.showPrint;
          }
        }
        if (this.formType === FormType.Registration) {
          if (Array.isArray(field.templateOptions.copyFieldsFromReg)) {
            page.copyFields = field.templateOptions.copyFieldsFromReg;
          }
        }
        if (field.templateOptions.initFields?.url) {
          await this.getAndWaitForFirstStyleValue(field.templateOptions.initFields, fields, values);
        }
      }
    }
  }

  /**
   * Checks if an form has the possibility to do registration
   * @param fields The fields of the form
   * @param hideSignButtons The value of hideSignButtons
   */
  canRegForm(fields: FormlyFieldConfig[], hideSignButtons: boolean) {
    if (!hideSignButtons) {
      return true;
    }
    else {
      for (const field of fields) {
        if (field.type === Fields.Button
            && (field.templateOptions?.buttonFunction === 'post' || field.templateOptions?.buttonFunction === 'reg')
        ) {
          return true;
        }
      }
      return false;
    }
  }

  /**
   * Get Style Paths from an init definition object, if not given it will use D4 defaults
   * @param initDef (Optional) The init definition object
   * @returns An StylePaths object with paths form init object, or D4 defaults
   */
  getStylePaths(initDef?: any): StylePaths {
    return {
      data: initDef?.jsonPath ?? '',
      key: initDef?.keyProp ?? 'Key',
      label: initDef?.labelProp ?? 'Label',
      value: initDef?.valueProp ?? 'DefaultValue',
      visible: initDef?.visibleProp ?? 'Visible',
      serverSearch: initDef?.serverSearch ?? 'Server',
      options: initDef?.optionsProp ?? 'Options',
      valueName: initDef?.valueNameProp ?? 'DefaultName'
    };
  }

  /**
   * Get style values from API as promise
   * @param initDef The definition for getting data from API
   * @param fields The fields of the form
   * @param values The values of the form
   */
  getAndWaitForFirstStyleValue(initDef: any, fields: FormlyFieldConfig[], values: Value[]) {
    return new Promise<void>(resolve => {
      this.getValuesFromAPI(initDef, fields, values).subscribe(() => resolve());
    });
  }

  /**
   * Get value, label and visible from API and set to fields
   * @param initDef The definition for getting data from API
   * @param fields The fields of the form
   * @param values The values of the form
   */
  getValuesFromAPI(initDef: any, fields: FormlyFieldConfig[], values: Value[]): Observable<DataSource> {
    const url = initDef.url;
    if (!url) {
      return;
    }
    const stylePaths = this.getStylePaths(initDef);
    const keys: string[] = fields.filter(field => field.key).map(field => field.key as string);
    return this.cachedApi.getStyleData(url, stylePaths, keys, this.formId)
    .pipe(
      map<CachedData<StyleData[]>, DataSource>(cachedData => {
        const styleData = cachedData.value;
        if (cachedData.source === DataSource.API) {
          if (cachedData.status === DataStatus.Updated) {
            this.setStyleData(styleData);
          }
          return DataSource.API;
        }
        for (const data of styleData) {
          const field = this.findField(fields, data.key);
          if (!field) {
            continue;
          }
          if (!field.templateOptions) {
            field.templateOptions = {};
          }
          field.hide = !data.visible;
          if (data.label) {
            field.templateOptions.label = data.label;
          }
          if (typeof data.value !== 'undefined' && data.value !== null && this.formType !== FormType.Registration) {
            const timeType = this.timeService.getTimeType(field);
            const value = timeType ? this.timeService.formatDatetime(data.value, timeType) : data.value;
            this.addValue(data.key, value, values, false);
            field.templateOptions.initValue = value;
          }
          if (Array.isArray(data.options)) {
            field.templateOptions.options = data.options;
          }
          if (typeof data.serverSearch === 'boolean') {
            field.templateOptions.autoServerSearch = data.serverSearch;
          }
          if (data.valueName) {
            field.templateOptions.defaultInitValueName = data.valueName;
          }
          field.templateOptions.haveDoneInit = true;
        }
        return DataSource.Storage;
      })
    );
  }

  /**
   * Set style date to fields
   * @param styleData The style data to set
   */
  async setStyleData(styleData: StyleData[]) {
    await this.waitForInit();

    const model = {};
    for (const data of styleData) {
      const field = this.findField(this.page.fields, data.key);
      if (field) {
        field.hide = !data.visible;
        if (!field.templateOptions) {
          field.templateOptions = {};
        }
        if (data.label) {
          field.templateOptions.label = data.label;
        }
        if (this.page.model[data.key] === field.templateOptions.initValue && typeof data.value !== 'undefined') {
          const timeType = this.timeService.getTimeType(field);
          model[data.key] = timeType ? this.timeService.formatDatetime(data.value, timeType) : data.value;
        }
        if (Array.isArray(data.options)) {
          field.templateOptions.options = data.options;
        }
        if (data.serverSearch) {
          field.templateOptions.filterSearchParam = data.serverSearch;
        }
        if (data.valueName) {
          field.templateOptions.defaultInitValueName = data.valueName;
        }
      }
    }
    if (this.formType !== FormType.Registration) {
      this.page.formGroup.patchValue(model);
    }
  }

  /**
   * Add value to form values
   * @param key The key of the field the value belongs to
   * @param value The value to add
   * @param values The forms values
   * @param replace If it should replace the value if it already exists
   */
  addValue(key: string, value: any, values: Value[], replace: boolean) {
    const val = this.getValue(key, values);
    if (!val) {
      values.push({
        key: key,
        value: value,
        formId: 0
      });
    }
    else if (replace) {
      val.value = value;
    }
  }

  /**
   * Get value from list of values
   * @param key Key of value to find
   * @param values The values to search
   */
  getValue(key: string, values: Value[]) {
    return values.find(v => v.key === key);
  }

  /**
   * Set existing values to the fields in the form
   * @param page this-object of the calling page
   * @param values The values to set
   */
  initValues(fields: FormlyFieldConfig[], values: Value[]) {
    for (const val of values) {
      let foundField = false;
      for (const field of fields) {
        if (val.key === field.key) {
          let value: any;
          if (this.timeService.isTimeOrDate(field)) {
            if (field.templateOptions.type === 'time') {
              value = this.timeService.formatDatetime(val.value, 'time');
            }
            else if (field.templateOptions.type === 'date') {
              value = this.timeService.formatDatetime(val.value, 'date');
            }
            else {
              value = this.timeService.formatDatetime(val.value, 'datetime');
            }
          }
          else if (this.isArrayField(field) && typeof val.value === 'string') {
            const delimiter = field.templateOptions.splitter || ',';
            value = val.value.split(delimiter);
          }
          else {
            if (typeof val.value === 'string') {
              try {
                value = JSON.parse(val.value);
                if (typeof value === 'number') {
                  value = value.toString();
                }
              }
              catch {
                value = val.value;
              }
            }
            else {
              value = val.value;
            }

          }
          this.page.model[val.key] = value;
          field.templateOptions.initValue = value;
          foundField = true;
          break;
        }
      }
      if (!foundField && shouldKeepMetaData(val.key)) {
        this.page.model[val.key] = val.value;
      }
    }
  }

  /**
   * Stop autosaving form
   * @param clear If autosave should be cleard from storage
   */
  async stopAutosave(clear: boolean) {
    if (this.formId && this.currentProject) {
      await this.autosave.stopAutosave(this.formId, this.currentProject.id, clear);
    }
  }

  /**
   * Clear autosave from form
   */
  async clearAutosave() {
    await this.autosave.clearAutosave(this.formId, this.currentProject.id);
  }

  /**
   * Restart an autosave
   */
  async restartAutosave() {
    if (!this.doAutosave) {
      return;
    }
    const vals = await this.autosave.getAutosave(this.formId, this.currentProject.id);
    this.page.formGroup.reset();
    if (vals) {
      this.page.formGroup.patchValue(vals);
    }
    const fields = this.page.allFields ? this.page.allFields : this.page.fields;
    this.autosave.startAutosave(this.formId, this.currentProject.id, this.page.formGroup, this.page.model, fields, this.hasSteps);
  }

  /**
   * Checks if the field has an array as result
   * @param field The field to check
   */
  isArrayField(field: FormlyFieldConfig) {
    const type = field?.type as string;
    if (!field || !isValidField(type, true)) return false;

    const fields = [Fields.Select, Fields.HistorySelect, Fields.LabelSelect, Fields.Modalselect, Fields.Lookup,
                    Fields.WebLookup, Fields.SubSelect, Fields.ServerQueryModal];
    return (fields.includes(type) && this.usesMultiple(field));
  }

  /**
   * Check if an field uses multiple values
   * @param field The field to check
   */
  usesMultiple(field: FormlyFieldConfig) {
    if (field.templateOptions?.multiple && !field.templateOptions?.storeAsJson) {
      return true;
    }
    else if (field.type === Fields.ServerQueryModal && field.templateOptions?.optionsize !== 1 && !field.templateOptions?.storeAsJson) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Go to a given step in an form
   * @param stepNr The number of the step to go to
   */
  async goToStep(stepNr: number) {

    // Validate required fields on current step. Only checkes when going forward.
    if (this.checkRequiredOnStepForward !== StepRequired.Off && this.currentStep < stepNr) {
      const reqFields = this.findRequiredFields(this.page.fields, this.page.model, true);
      const reqLabels = reqFields.map(f => f.label ? `${f.label} (${f.key})` : f.key).join('<br>');
      const title = this.translate.instant('FieldsRequired');
      if (reqFields.length > 0) {
        if (this.checkRequiredOnStepForward === StepRequired.Error) {
          this.popup.showAlert(title, reqLabels, false);
          return;
        }
        else {
          const translations = this.translate.instant(['Yes', 'No', 'StepRequiredContinue']);
          const text = `${reqLabels}<br><br>${translations['StepRequiredContinue']}`;
          const confirmed = await this.popup.showConfirm(title, text, false, translations['No'], translations['Yes']);
          if (!confirmed) {
            return;
          }
        }
      }
    }

    // Scroll to top
    const items = document.querySelectorAll('ion-content');
    if (items.length > 0) {
      const content: HTMLIonContentElement = items[items.length - 1];
      content.scrollToTop(500);
    }

    if (stepNr < 0) {
      stepNr = 0;
    }
    else if (stepNr >= this.steps.length) {
      stepNr = this.steps.length - 1;
    }
    const start = this.steps[stepNr].index;
    let end: number;
    if (stepNr + 1 < this.steps.length) {
      end = this.steps[stepNr + 1].index;
    }

    const newFields = this.page.allFields.slice(start, end);
    this.currPage = 0;
    this.initFields(newFields);
    // this.addPageObserver();
    this.currentStep = stepNr;
    this.lastSeenStep = Math.max(this.lastSeenStep, this.currentStep);
    this.findGestureKeys();
  }

  /**
   * Goes to the next step in the form
   */
  async nextStep() {
    await this.goToStep(this.currentStep + 1);
  }

  /**
   * Goes to the previous step in the form
   */
  async previousStep() {
    await this.goToStep(this.currentStep - 1);
  }

  /**
   * Go to the last step in the form
   */
  goToLastStep() {
    if (!this.lastStep()) {
      this.goToStep(this.steps.length - 1);
    }
  }

  /**
   * Go to the first step in the form
   */
  goToFirstStep() {
    if (!this.firstStep()) {
      this.goToStep(0);
    }
  }

  /**
   * Checks if the current step is the last step
   */
  lastStep(): boolean {
    return this.currentStep === this.steps.length - 1;
  }

  /**
   * Checks if the current step is the first step
   */
  firstStep(): boolean {
    return this.currentStep === 0;
  }

  /**
   * Get label and stepnr of previous step
   */
  getPreviousStep(): Step {
    if (this.firstStep()) {
      return {nr: 0, label: '', index: 0};
    }

    else {
      return this.steps[this.currentStep - 1];
    }
  }
  /**
   * Get label and stepnr of current step
   */
  getCurrentStep(): Step {
    return this.steps[this.currentStep];
  }

  /**
   * Get label and stepNr of next step
   */
  getNextStep(): Step {
    if (this.lastStep()) {
      return {
        nr: this.currentStep,
        label: '',
        index: -1
      };
    }
    else {
      return this.steps[this.currentStep + 1];
    }
  }

  /**
   * Get all the steps to be shown
   */
  getStepList(): Step[] {
    if (this.lastSeenStep === 0) {
      return [];
    }
    else {
      return this.steps.slice(0, this.lastSeenStep + 1);
    }
  }

  /**
   * Find field with gestures. Currently only swipe
   */
  findGestureKeys() {
    const field = this.getGestureField('swipe');
    if (field) {
      this.gestureSwipefield.next(field);
    }
    else if (this.steps.length > 1) {
      this.gestureSwipefield.next('step');
    }
  }

  /**
   * Find fild with gesture option with the given type
   * @param gestureType The gesture type
   */
  getGestureField(gestureType: string): FormlyFieldConfig {
    const fields: FormlyFieldConfig[] = this.page.fields;
    gestureType = gestureType.toLocaleLowerCase();
    for (const field of fields) {
      if (field.type !== 'step' && field?.templateOptions?.gesture?.type?.toLocaleLowerCase() === gestureType) {
        return field;
      }
    }
    return null;
  }

  /**
   * Create an registration from model.
   *
   * Also returns if the form should go back and the button that was clicked
   * @param model The model to create registration from
   * @param fields The fields in the form
   * @param signed If the form is signed
   */
  async createReg(
    model: any,
    fields: FormlyFieldConfig[],
    signed: boolean
  ): Promise<{reg: NewReg, goBack: boolean, button: FormlyFieldConfig}> {
    if (!this.reachedEnd) {
      await this.loadFieldsUntilEnd(500);
    }
    let buttonFunction = 'post';
    let buttonField: FormlyFieldConfig;
    let url: string;
    let goBack = true;
    const projectId = this.currentProject && this.currentProject.id;
    const projectNumber = this.currentProject && this.currentProject.number;
    if (model['pressed']) {
      buttonFunction = model['buttonfunction'];
      signed = (buttonFunction === 'get' || buttonFunction === 'tmp') ? false : true;

      buttonField = this.findField(fields, model['pressed']);
      if (buttonField.templateOptions.url && this.shouldHaveUrl(buttonFunction)) {
        url = buttonField.templateOptions.url;
        url = this.util.parseText(url, model);
      }
      if (buttonFunction === 'get') {
        goBack = false;
      }
      else if (buttonFunction === 'reg' || buttonFunction === 'webPost' || buttonFunction === 'webPut' || buttonFunction === 'webDelete') {
        if (!buttonField.templateOptions.exitOnDone) {
          goBack = false;
        }
        const triggerUpdate = buttonField.templateOptions.triggerUpdate;
        if (typeof triggerUpdate === 'string' && triggerUpdate === 'gps') {
          const gpsKeys = fields.filter(f => f.type === Fields.GPS).map(f => f.key);
          await this.triggerGPSFields(gpsKeys, fields);

        }
        else if (Array.isArray(triggerUpdate)) {
          await this.triggerGPSFields(triggerUpdate, fields);
        }
      }

      if ((this.formType === FormType.Setup || signed) && !this.page.formGroup.valid) {
        const reqFields = this.findRequiredFields(fields, model, true);
        if (reqFields.length > 0) {
          const reqLabels = reqFields.map(f => f.label ? `${f.label} (${f.key})` : f.key);
          const title = this.translate.instant('FieldsRequired');
          await this.popup.showAlert(title, reqLabels.join('<br>'), false);
          return null;
        }
      }

      return {
        reg: {
          FormId: this.formId,
          ValueList: this.createValuelist(model, this.formId, fields),
          ProjectId: projectId,
          ProjectNumber: projectNumber,
          IsSigned: signed,
          Url: url,
          ButtonFunction: buttonFunction
        },
        goBack: goBack,
        button: buttonField
      };
    }
    else {
      if ((this.formType === FormType.Setup || signed) && !this.page.formGroup.valid) {
        const reqFields = this.findRequiredFields(fields, model, true);
        if (reqFields.length > 0) {
          const reqLabels = reqFields.map(f => f.label ? `${f.label} (${f.key})` : f.key);
          const title = this.translate.instant('FieldsRequired');
          await this.popup.showAlert(title, reqLabels.join('<br>'), false);
          return null;
        }
      }

      return {
        reg: {
          FormId: this.formId,
          ValueList: this.createValuelist(model, this.formId, fields),
          ProjectId: projectId,
          ProjectNumber: projectNumber,
          IsSigned: signed,
          Url: url,
          ButtonFunction: buttonFunction
        },
        goBack: goBack,
        button: buttonField
      };
    }
  }

  /**
   * Find required fields that aren't filled in
   * @param fields The fields to search in
   * @param model The model
   * @param markAsTouched If the fields should be marked as touch to show required text
   */
  findRequiredFields(fields: FormlyFieldConfig[], model: any, markAsTouched: boolean): {key: string, label?: string}[] {
    const requiredFields: {key: string, label?: string}[] = [];
    for (const field of fields) {
      if (field.templateOptions.required && (!model[field.key as string | number] && model[field.key as string | number] !== 0)) {
        if (field.templateOptions.label) {
          let label = field.templateOptions.label;
          if (label.endsWith(' *')) {
            label = label.slice(0, -2);
          }
          requiredFields.push({label: label, key: field.key as string});
        }
        else {
          requiredFields.push({key: field.key as string});
        }
        if (markAsTouched) {
          this.page.formGroup.get(field.key as string)?.markAsTouched();
        }
      }
    }
    return requiredFields;
  }

  /**
   * Call this on reg error. Will check if it was caused by missing required
   * @param fields The fields
   * @param model The model
   */
  checkRegError(fields: FormlyFieldConfig[], model: any) {
    const reqFields = this.findRequiredFields(fields, model, true);
    if (reqFields.length > 0) {
      const reqLabels = reqFields.map(f => f.label ? `${f.label} (${f.key})` : f.key);
      const title = this.translate.instant('FieldsRequired');
      this.popup.showAlert(title, reqLabels.join('<br>'), false);
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Submit an form registration
   * @param reg The registration to submit
   * @param stayInForm If it will stay in the form after doing registration is finished
   */
  async doReg(reg: NewReg, stayInForm: boolean): Promise<RegSuccess> {
    await this.auth.waitForAuth();
    if (this.formType === FormType.Form || this.formType === FormType.FormView) {
      const {value: apiProject} = await firstValueFrom(this.api.getCurrentProject(), {defaultValue: {value: null, status: ApiStatus.Failed}});
      if (this.currentProject?.id !== apiProject?.id) {
        await firstValueFrom(this.api.setCurrentProject(this.currentProject), {defaultValue: {value: null, status: ApiStatus.Failed}});
      }
    }
    if (reg.ButtonFunction === 'get') {
      const fields = this.page.allFields || this.page.fields;
      const buttonKey = reg.ValueList.find(v => v.key === 'pressed').value;
      const buttonField = this.findField(fields, buttonKey);

      try {
        const res = await firstValueFrom(this.api.doRegistration(reg), {defaultValue: {value: null, status: ApiStatus.Failed}});
        const url = this.util.parseText(buttonField.templateOptions.url, this.page.model, fields);
        const setKey = buttonField.templateOptions.setKey || 'servermessage';
        if (res.status === ApiStatus.Success) {
          const result = JSON.parse(res.value.data);
          const path = buttonField.templateOptions.jsonPath ?? '0.Info';
          const data = this.util.dotRef(result, path);
          if (data) {
            if (url) {
              this.storage.setGetData(url, data);
            }
            this.ref.callReference(setKey, this.formId, this.formType, TriggerType.Set, data);
          }
          else {
            const errorPath = buttonField.templateOptions.errorPath ?? '0.Error';
            const error = this.util.dotRef(result, errorPath);
            if (error) {
              return {success: false, errorMessage: error, type: 'get'};
            }
          }
          return {success: true};
        }
        else if (res.status === ApiStatus.Offline && url) {
          const data = await this.storage.getGetData(url);
          if (data) {
            this.ref.callReference(setKey, this.formId, this.formType, TriggerType.Set, data);
            return {success: true};
          }
          else {
            return {success: false, type: 'get'};
          }
        }
        else {
          return {success: false, type: 'get'};
        }
      }
      catch {
        return {success: false, type: 'get'};
      }
    }

    if (!this.online.isOnline() && this.online.hasNetwork() && this.stateService.offlineRegs.length === 0) {
      const confirmed = await this.popup.showConfirm('DoingOffline', 'DoingOfflineMsg', true, 'No', 'Yes');
      if (confirmed) {
        this.online.goOnline();
      }
    }

    // Add flag for if the registration is offline or not
    reg.ValueList.push({
      formId: reg.FormId,
      key: ModelMetaData.OfflineReg,
      value: !this.online.isOnline()
    });

    if (this.online.isOnline()) {
      let loadId: number;
      if (this.formType !== FormType.FormView) {
        loadId = this.loading.startLoader(null, false);
      }

      const photosUploaded = await this.checkAndDoUpload(reg);
      if(!photosUploaded.success) {
        if (loadId) {
          this.loading.dismissLoader(loadId);
        }
        return {success: false, errorMessage: this.translate.instant('FailedPhotoUpload')};
      }
      const result = await this.uploadReg(reg, loadId);
      if (result.success && !stayInForm && this.formType !== FormType.Setup) {
        await this.clearAutosave();
      }
      else if (!result.success && reg.Url && result.errorMessage === 'Error' && this.formType !== FormType.Setup) {
        this.online.goOffline(OnlineStatus.ExternalFail, 0, this.util.getEndpoint(reg.Url));
        await this.saveOfflineReg(reg, stayInForm);
        return {success: true};
      }
      return result;
    }
    else if (this.formType !== FormType.Setup) {
        await this.saveOfflineReg(reg, stayInForm);
        return {success: true};
    }
    else return {success: true};
  }


  /**
   * Do things after registration if not exiting form
   * @param buttonField The button field that triggered submit
   */
  async afterReg(buttonField: FormlyFieldConfig) {
    const fields = this.page.allFields ? this.page.allFields : this.page.fields;
    delete this.page.model['pressed'];
    if (!buttonField || !buttonField.templateOptions) {
      this.logger.debug('No valid button field');
      return;
    }

    if (buttonField.templateOptions.clearKeys) {
      let clearKeys: string[] = [];
      if (Array.isArray(buttonField.templateOptions.clearKeys)) {
        clearKeys = buttonField.templateOptions.clearKeys;
      }
      else if (typeof buttonField.templateOptions.clearKeys === 'string') {
        clearKeys = buttonField.templateOptions.clearKeys.split(',');
      }
      const clearVals = {};
      for (const key of clearKeys) {
        clearVals[key] = '';
        const field = this.findField(fields, key);
        if (this.fieldsToClear.includes(field?.type as any)) {
          this.ref.callReference(key, this.formId, this.formType, TriggerType.Clear);
        }
      }
      this.setVars(clearVals);
    }
    else if (buttonField.templateOptions.clearForm) {
      this.clearForm();
    }
    if (Array.isArray(buttonField.templateOptions.setKeys)) {
      const setVals = {};
      for (const keyVal of buttonField.templateOptions.setKeys) {
        setVals[keyVal.key] = keyVal.value;
      }
      this.setVars(setVals);
    }
    else if (typeof buttonField.templateOptions.setKeys === 'object') {
      this.setVars(buttonField.templateOptions.setKeys);
    }
    if (buttonField.templateOptions.showMsg) {
      const msg = buttonField.templateOptions.showMsg.msg;
      const duration = buttonField.templateOptions.showMsg.duration || 1000;
      const delay = buttonField.templateOptions.showMsg.delay || 0;
      if (delay > 0) {
        const that = this;
        setTimeout(() => {
          that.popup.showMessage(msg, false, '', duration);
        }, delay);
      }
      else {
        this.popup.showMessage(msg, false, '', duration);
      }
    }
    else if (buttonField.templateOptions.thenMessage) {
      this.popup.showAlert(buttonField.templateOptions.thenMessage, '', false);
    }
    else if (buttonField.templateOptions.onDoneMsg) {
      this.popup.showAlert(buttonField.templateOptions.onDoneMsg, '', false);
    }
    if (buttonField.templateOptions.thenButton) {
      this.ref.callReference(buttonField.templateOptions.thenButton, this.formId, this.formType, TriggerType.Click);
    }
    if (buttonField.templateOptions.focusKey) {
      this.ref.callReference(buttonField.templateOptions.focusKey, this.formId, this.formType, TriggerType.Focus, null, this.page);
    }
    else if (buttonField.templateOptions.goUp) {
      const items = document.querySelectorAll('ion-content');
      if (items.length > 0) {
        const content: HTMLIonContentElement = items[items.length - 1];
        content.scrollToTop(500);
        this.goToFirstStep();
      }
    }
    else if (buttonField.templateOptions.goDown) {
      const items = document.querySelectorAll('ion-content');
      if (items.length > 0) {
        const content: HTMLIonContentElement = items[items.length - 1];
        content.scrollToBottom(500);
        this.goToLastStep();
      }
    }
    if (buttonField.templateOptions.triggerApi) {
      await this.triggerApiFields(buttonField.templateOptions.triggerApi);
    }
    this.triggerLastRegs();
    this.autosave.clearAutosave(this.formId, this.currentProject.id);
  }

  /**
   * Triger update of last reg fields
   */
  triggerLastRegs() {
    for (const key of this.lastRegFields) {
      this.ref.callReference(key, this.formId, this.formType, TriggerType.Update);
    }
  }

  /**
   * Trigger new API call on fields
   * @param apiTrigger The object containing settings for the trigger (i.e. keys, formId and formType)
   */
  async triggerApiFields(apiTrigger: ApiTrigger | ApiTrigger[]) {
    if (!apiTrigger) {
      return;
    }
    else if (!Array.isArray(apiTrigger)) {
      apiTrigger = [apiTrigger];
    }
    for (const trigger of apiTrigger) {
      let keys: string[];
      if (Array.isArray(trigger.keys) && trigger.keys.length > 0) {
        keys = trigger.keys;
      }
      else if (typeof trigger.keys === 'string' && trigger.keys !== '') {
        keys = [trigger.keys];
      }
      else {
        continue;
      }

      let formId: number;
      if (typeof trigger.formId === 'number') {
        formId = trigger.formId;
      }
      else if (typeof trigger.formId === 'string') {
        formId = parseInt(trigger.formId);
      }
      if (!formId || isNaN(formId)) {
        formId = this.formId;
      }
      let formType: FormType;
      if (trigger.formType === FormType.Form || trigger.formType === FormType.FormView) {
        formType = trigger.formType;
      }
      else if (formId === this.formId) {
        formType = this.formType;
      }
      else {
        formType = FormType.FormView;
      }
      const fields = this.stateService.getFields(formType, formId).filter(f => keys.includes(f.key as string));
      for (const field of fields) {
        await this.ref.callReference(field.key as string, formId, formType, TriggerType.Update, null, null, false);
      }
    }
  }

  /**
   * Create an value list for registration
   * @param model The model to create value list from
   * @param formId Id of the form
   * @param fields The fields of the form
   */
  createValuelist(model: any, formId: number, fields: FormlyFieldConfig[]): Value[] {
    model[ModelMetaData.DeviceInfo] = this.device.getType();
    model[ModelMetaData.AppVersion] = environment.appVersion;
    model[ModelMetaData.SubmitDateTime] = this.timeService.dateToLocalISO();
    model[ModelMetaData.Language] = this.translate.currentLang;
    const valueList: Value[] = [];
    for (const entry of Object.entries(model)) {
      const [key] = entry;
      let [, val] = entry;
      if (this.isEmptyValue(key, val)) {
        continue; // Not register empty values
      }
      const field = this.findField(fields, key);
      if (typeof val === 'object') {
        if (Array.isArray(val) && this.isArrayField(field) && !field.templateOptions?.storeAsJson) {
          const splitter = field.templateOptions.splitter || ',';
          val = val.join(splitter);
        }
        else {
          val = JSON.stringify(val);
        }
      }
      else if (typeof val === 'string' && val !== '') {
        if (field?.templateOptions?.convertedMonthInput) {
          val = this.timeService.formatDatetime(val, 'month', false);
        }
        else if (this.timeService.isTimeOrDate(field) && (field.templateOptions.useIsoFormat ?? true) ) {
          const addCurrentTime = this.timeService.isJustDate(field) && (field.templateOptions.addCurrentTime ?? true);
          val = this.timeService.formatDatetime(val, 'full', addCurrentTime);
        }
      }
      const value: Value = {
        formId: formId,
        key: key,
        value: val
      };
      valueList.push(value);
    }
    return valueList;
  }

  /**
   * Upload all offline registrations
   */
  async uploadOfflineRegs(): Promise<{success: boolean, errorMessage?: string, failed?: NewReg}> {
    if (this.stateService.offlineRegs.length === 0) return {success: true};

    const failedUploads: NewReg[] = [];
    const formIds: number[] = [];
    const loadId = this.loading.startLoader(null, false);
    let errorMessage = '';
    while (this.stateService.offlineRegs.length > 0) {
      const reg = this.stateService.offlineRegs.shift();
      if (!formIds.includes(reg.FormId)) {
        formIds.push(reg.FormId);
      }
      const checked = await this.checkAndDoUpload(reg);
      let success = false;
      if (checked.success) {
        const res: RegSuccess = await this.uploadReg(reg);
        success = res.success;
        errorMessage = res.errorMessage;
      }
      else {
        errorMessage = this.translate.instant('FailedPhotoUpload');
      }
      if (!success) {
        failedUploads.push(reg);
        break;
      }
    }
    this.loading.dismissLoader(loadId);
    this.stateService.offlineRegs = failedUploads.concat(this.stateService.offlineRegs);
    this.storage.setOfflineRegs(this.stateService.offlineRegs);
    this.api.getAndStoreUpdatedForms(formIds).subscribe(); // Update the forms in storage
    if (this.stateService.offlineRegs.length > 0)
      return {success: false, errorMessage: errorMessage, failed: failedUploads[0]};
    else
      return {success: true};
  }

  /**
   * Checks if an offline registrations has offline photos and uploads them
   * @param reg The registration to check
   */
  async checkAndDoUpload(reg: NewReg): Promise<{photosFound: boolean, keysUpdated: string[], success: boolean}> {
    const keysUpdated = {};
    const keysFailed: string[] = [];
    let photosFound = false;

    for (const val of reg.ValueList) {
      if (typeof val.value === 'string' && val.value.startsWith('offlinePhoto:')) {
        photosFound = true;
        const src = val.value.replace('offlinePhoto:', '').split('|');
        const newUrls: string[] = [];
        for (const s of src) {
          if (s.includes('Photo/GetPhoto')) {
            newUrls.push(s);
          }
          else {
            const url = await this.camera.readAndUploadPhoto(s);
            if (url) {
              keysUpdated[val.key] = 1;
              newUrls.push(url);
            }
            else {
              keysFailed.push(s);
            }
          }
        }
        val.value = newUrls.join('|');
      }
    }
    return {photosFound: photosFound, keysUpdated: Object.keys(keysUpdated), success: keysFailed.length === 0 };
  }

  updateRegKey(val: UpdateKeyReg) {
    return this.api.updateRegKey(val);
  }

  /**
   * Find an specific field in the form
   * @param fields The fields of the form
   * @param key Key of the field to be found
   */
  findField(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig {
    for (const f of fields) {
      if (f.key === key)
        return f;
    }
    return null;
  }

  /**
   * Set values to fields in the form
   * @param vars The field values to set
   */
  setVars(vars: any) {
    const keys = Object.keys(vars);
    const lastField = this.findLastField(keys, this.page.fields);
    if (!(this.page.loadedAllFields ?? true) && lastField) {
      this.loadFieldsUntilKey(lastField);
    }
    setTimeout(async () => {
      for (const key of keys) {
        if (!this.page.fields.map(f => f.key).includes(key)) {
          this.page.model[key] = vars[key];
        }
        else {
          const field = this.findField(this.page.fields, key);
          if (this.timeService.isTimeOrDate(field) || field?.type === Fields.TimePicker) {
            await this.ref.callReference(key, this.formId, this.formType, TriggerType.StopUpdate);
          }
          if (this.evalService.evalHideExpression(field.hideExpression, field.hide, this.page.model, this.page.formGroup)) {
            this.page.model[key] = vars[key];
          }
        }
      }
      this.page.formGroup.patchValue(vars);
    });
  }

  /**
   * Clear the form of data
   * @param clearFields (Optional) If fields should be removed, default: `false`
   */
  clearForm(clearFields = false) {
    if (clearFields) {
      this.page.fields = [];
      if (this.page.allFields) {
        this.page.allFields = [];
      }
      if (this.page.activeFields) {
        this.page.activeFields = [];
      }

    }
    const fields = this.page.allFields ? this.page.allFields : this.page.fields;
    if (fields) {
      for (const field of fields) {
        if (this.fieldsToClear.includes(field.type as any)) {
          this.ref.callReference(field.key as string, this.formId, this.formType, TriggerType.Clear);
        }
      }
    }
    this.page.model = {};
    this.page.formGroup = new UntypedFormGroup({});
    if (this.doAutosave && this.formId && this.currentProject && (this.formType === FormType.Form || this.formType === FormType.FormView)) {
      this.autosave.startAutosave(this.formId, this.currentProject.id, this.page.formGroup, this.page.model, fields, this.hasSteps);
    }
  }

  /**
   * Clear references in form
   */
  clearRefs() {
    this.stateService.removeFields(this.formType, this.formId);
    this.ref.clearReferences(this.formId, this.formType);
  }

  /**
   * Open an form. Will give error for invalid formId or if trying to open the same form as the current
   * @param formId Id of the form to be opened
   * @param formData (Optional) Formdata to pass along which will be used during init
   */
  async openForm(formId: number, formData: any = {}): Promise<boolean> {
    if (!formId) {
      this.popup.showMessage('NotValidFormId', true);
      return false;
    }

    if (this.ref.formIsOpen(formId, FormType.Form)) {
      this.popup.showMessage('OpenSameForm', true);
      return false;
    }

    const pageRef = this.formType === FormType.Form ? PageRefs.Form : PageRefs.FormView;
    await this.ref.callReference(pageRef, this.formId, this.formType, TriggerType.AllowLeave);
    if (NgZone.isInAngularZone()) {
      this.router.navigate(['forms', formId], {state: {formData}});
    }
    else {
      this.ngZone.run(() => this.router.navigate(['forms', formId], {state: {formData}}));
    }
    return true;
  }

  /**
   * Save an offline registration
   * @param reg The reg to save
   * @param stayInForm If the app will stay in the form aftere registration
   */
  private async saveOfflineReg(reg: NewReg, stayInForm: boolean) {
    reg.RegTime = new Date();
    this.stateService.addOfflineRegistration(reg);
    await this.storage.setOfflineRegs(this.stateService.offlineRegs);
    if (!stayInForm) {
      await this.clearAutosave();
    }
  }

  private shouldHaveUrl(buttonFunction: string): boolean {
    switch (buttonFunction) {
      case 'webPost':
      case 'webPut':
      case 'webDelete':
        return false;
      default:
        return true;
    }
  }

  /**
   * Check if an valu is empty
   * @param key The key of the value
   * @param value The value to check
   */
  private isEmptyValue(key: string, value: any) {
    if (typeof value === 'undefined' || value === null) {
      return true;
    }
    else if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    else if (value === '') {
      for (const metaKey of Object.values(ModelMetaData)) {
        if (key.includes(metaKey)) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Updates all gps fields in the form or only fields with key given in optional gpsKeys array
   * @param keys Array of keys for gps fields to update
   * @param fields The fields of the fomr
   */
  private async triggerGPSFields(keys: string[] | any[], fields: FormlyFieldConfig[]) {
    fields = fields.filter(f => keys.includes(f.key as string));

    if (fields.length > 0) {
      for (const field of fields) {
        if(!this.evalService.evalHideExpression(field.hideExpression, field.hide, this.page.model, this.page.formGroup))
          await this.ref.callReference(field.key as string, this.formId, this.formType, TriggerType.GPS);
      }
    }
  }

  /**
   * Find which of an given set of keys for fields is last
   * @param keys The list of keys to check
   * @param fields The fields of the form
   */
  private findLastField(keys: string[], fields: FormlyFieldConfig[]) {
    let lastFieldKey: string;
    for (const field of fields) {
      if (keys.includes(field.key as string)) {
        lastFieldKey = field.key as string;
      }
    }
    return lastFieldKey;
  }

  /**
   * Checks for unknown fields and replaces them with the 'unknown' field type
   * @param fields The fields to check
   */
  private fixUnknownFields(fields: FormlyFieldConfig[], formId: number) {
    for (const field of fields) {
      if (!isValidField(field.type as string)) {
        if (!field.templateOptions) {
          field.templateOptions = {};
        }
        let label: string;
        if (field.type === 'inline') {
          const refId = field.form as unknown as string;
          if (+refId === formId) {
            label = this.translate.instant('InlineSelfReference', {key: field.key});
          }
          else {
            label = this.translate.instant('UnknownInline', { key: field.key, form: field.form });
          }
        }
        else {
          label = this.translate.instant('UnknownField', { type: field.type, key: field.key });
        }
        field.templateOptions.label = label;
        field.type = 'unknown';
      }
    }
  }

  /**
   * Will check for input fields with month type, and convert them if they are not supported by the browser
   * @param fields The fields to check
   */
  private fixMonthInput(fields: FormlyFieldConfig[]) {
    if (this.device.supportsMonthInput()) {
      return;
    }

    for (const field of fields) {
      // Convert input-fields with month type to datetime-fields with suitable options
      if (field.type === Fields.Input && field.templateOptions.type === 'month') {
        field.type = Fields.Datetime;
        field.templateOptions.convertedMonthInput = true;
        field.templateOptions.displayFormat = 'MMMM YYYY';
        field.templateOptions.doneText = 'OK';
        field.templateOptions.cancelText = this.translate.instant('Cancel');
        field.templateOptions.max ??= new Date().getFullYear() + (field.templateOptions.additionalYears ?? 20);
        field.templateOptions.monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(m => this.translate.instant(m)).join(', ');
        if (!field.defaultValue) {
          if (field.templateOptions.daysFromNow) {
            field.defaultValue = this.timeService.formatDatetime(new Date(), 'full', false, field.templateOptions.daysFromNow, 'days');
          }
          else if (field.templateOptions.monthsFromNow) {
            field.defaultValue = this.timeService.formatDatetime(new Date(), 'full', false, field.templateOptions.monthsFromNow, 'months');
          }
          else if (field.templateOptions.yearsFromNow) {
            field.defaultValue = this.timeService.formatDatetime(new Date(), 'full', false, field.templateOptions.yearsFromNow, 'years');
          }
          else if (field.templateOptions.weeksFromNow) {
            field.defaultValue = this.timeService.formatDatetime(new Date(), 'full', false, field.templateOptions.weeksFromNow, 'weeks');
          }
          else {
            field.defaultValue = this.timeService.formatDatetime(new Date(), 'full');
          }
        }
      }
    }
  }

  /**
   * Uploads an registration
   * @param reg The reg to upload
   * @param loadId (Optional) Id of the loader to close when finished
   */
  private uploadReg(reg: NewReg, loadId?: number): Promise<RegSuccess> {
    return new Promise<RegSuccess>((resolve) => {
      this.api.doRegistration(reg).subscribe(res => {
        if (loadId) {
          this.loading.dismissLoader(loadId);
        }
        if (res.status === ApiStatus.Success) {
          this.api.getForm(reg.FormId).subscribe(); // Update storage with possible addition/removal of tmp stored values
          resolve({success: true});
        }
        else {
          resolve({
            success: false,
            errorMessage: res.value?.errorMessage ?? ''
          });
        }
      });
    });
  }

  /**
   * Wait for init to finish
   * @param clear (Optional) If form should be clear when init is finished, default: `false`
   * @param waitTime (Optional) Wait some extra time afer init is finished, default: `0`
   */
  private async waitForInit(clear: boolean = false, waitTime: number = 0) {
    if (this.isInitalizingForm.value) {
      await new Promise<void>(resolve => {
        const sub = this.isInitalizingForm.subscribe(async status => {
          if (!status) {
            sub.unsubscribe();
            if (clear) {
              this.clearForm();
            }
            if (waitTime) {
              setTimeout(() => {
                resolve();
              }, waitTime);
            }
            else {
              resolve();
            }
          }
        });
      });
    }
  }
}
