import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { InLoggerService } from '../services/in-logger.service';
import { StateService } from '../services/state.service';
import { NGXLogger } from 'ngx-logger';
import { FirebaseService } from '../services/firebase.service';
import * as StackTrace from 'stacktrace-js';

/**
 * Logs uncaught errors
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) {}

    async handleError(error: Error) {
        const firebase = this.injector.get(FirebaseService);
        const inLogger = this.injector.get(InLoggerService);
        const state = this.injector.get(StateService);
        const logger = this.injector.get(NGXLogger);

        const message = error.message ? error.message : error.toString();

        // Not rethrow or log these errors as they are caused by slow devices and the user multi clicks on the field
        if (message.includes('IonicSelectable is disabled or already opened') || message.includes('IonicSelectable is disabled or already closed')) {
            logger.error(error);
            return;
        }

        const location = error.stack?.split('\n').map(line => line.trim()).find(line => line.startsWith('at')) ?? '';

        // Not log these as it's an error in 3rd party package
        if (!location.includes('_strokeUpdate')) {
            const eventData = {message, currState: state.state, prevState: state.prevState, location};
            const stack = await StackTrace.fromError(error);
            firebase.logError(message, stack, eventData);
            inLogger.error('Uncaught error', error);
        }

        throw error;
    }
}
