<ion-item lines="none" *ngIf="!useModal && label">
  <ion-spinner *ngIf="!completed"></ion-spinner>
  <ion-label><b>{{label}}</b></ion-label>
  <ion-text *ngIf="onlyAggregate">{{table}}</ion-text>
</ion-item>
<ion-item lines="none" *ngIf="!useModal && !onlyAggregate">
  <ion-spinner *ngIf="loading"></ion-spinner>
  <markdown [class.overflowing]="isOverflowing" #markdownEl [data]="table" (swipe)="handleSwipe()" (ready)="checkOverflow()" (scroll)="hideScrollArrow()"></markdown>
  <ion-icon class="overflow-indicator aniHoverX" name="chevron-forward-sharp" *ngIf="showOverflowArrow"></ion-icon>
</ion-item>
<ion-button 
  class="ion-text-wrap ion-padding-start ion-padding-end"
  type="button"
  [color]="color"
  [fill]="fill"
  expand="block"
  (click)="openModal()"
  *ngIf="useModal && !onlyAggregate"
  [disabled]="!completed || !table"
>
  <ion-label class="ion-padding-top ion-padding-bottom" *ngIf="completed">{{buttonText}}</ion-label>
  <ion-spinner *ngIf="!completed"></ion-spinner>
</ion-button> 
