import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { OnlineService } from './online.service';
import { InStorageService } from './in-storage.service';
import { LogEntry } from '../models/models';
import * as moment from 'moment';
import { StateService } from './state.service';

/**
 * Service for logging to storage
 */
@Injectable({
  providedIn: 'root'
})
export class InLoggerService {
  currentDate: string;
  currentLog: LogEntry[];
  constructor(
    private online: OnlineService,
    private storage: InStorageService,
    private location: Location,
    private state: StateService
  ) {
    this.init();
  }

  /**
   * Init service with current date and log for that date
   */
  async init() {
    this.currentDate = moment().format('YYYY-MM-DD');
    this.currentLog = await this.storage.getLog(this.currentDate);
  }

  /**
   * Logs an entry into the log
   * @param title Title of log entry
   * @param data (Optional) Data to record in the log entry
   */
  async log(title: string, data?: any) {
    const user = this.state.currentUser || await this.storage.getUser();
    const entry: LogEntry = {
      title: title,
      error: false,
      data: data,
      user: user ? user.username : 'default',
      tenant: user ? user.tenant : 'default',
      time: new Date(),
      url: this.location.path(),
      online: {
        app: this.online.isOnline(),
        device: this.online.hasNetwork()
      }
    };
    await this.storeEntry(entry);
  }
  /**
   * Logs an error into the log
   * @param title Title of log entry
   * @param error The error to log
   * @param data (Optional) Data to record in the log entry
   */
  async error(title: string, error: any, data?: any) {
    let errorString: string;
    if (typeof error === 'string') {
      errorString = error;
    }
    else {
      try {
        errorString = JSON.stringify(error);
      }
      catch (e) {
        errorString = `Failed stringifying error (${e?.message})`;
        if (error?.message) {
          errorString += `: ${error.message}`;
        }
      }
    }
    const user = this.state.currentUser || await this.storage.getUser();
    const entry: LogEntry = {
      title: title,
      error: true,
      errorData: errorString,
      data: data,
      user: user ? user.username : 'default',
      tenant: user ? user.tenant : 'default',
      time: new Date(),
      url: this.location.path(),
      online: {
        app: this.online.isOnline(),
        device: this.online.hasNetwork()
      }
    };
    await this.storeEntry(entry);
  }

  /**
   * Update and store log
   * @param entry The entry to enter
   */
  async storeEntry(entry: LogEntry) {
    const date = moment().format('YYYY-MM-DD');
    if (date !== this.currentDate) {
      this.currentDate = date;
      this.currentLog = [];
    }
    this.currentLog.unshift(entry);
    await this.storage.setLog(this.currentDate, this.currentLog);
  }

  /**
   * Check if there exists an error in the log
   */
  async hasError(): Promise<boolean> {
    const log = await this.getLog();
    const index = log.findIndex(entry => entry.error);
    return index >= 0;
  }

  /**
   * Clears the entire log
   */
  async clearLog() {
    await this.storage.removeFullLog();
    this.currentLog = [];
    await this.log('User cleared log', false);
  }

  /**
   * Get the full log for all days
   */
  getLog() {
    return this.storage.getFullLog();
  }
}
