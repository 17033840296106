import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CachedData, Calendar, CalendarEvent, DataSource, DataStatus, FormType, Trigger, TriggerType } from 'src/app/models/models';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { DeviceService } from 'src/app/services/device.service';
import { UtilityService } from 'src/app/services/utility.service';
import * as moment from 'moment';
import { PopupService } from 'src/app/services/popup.service';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { Observable } from 'rxjs';
import { CalendarComponent } from 'ionic2-calendar';
import { MarkdownService } from 'ngx-markdown';
import { TimeService } from 'src/app/services/time.service';
import { FieldService } from 'src/app/services/field.service';
import { StateService } from 'src/app/services/state.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { SetVarsService } from 'src/app/services/set-vars.service';

@Component({
  selector: 'app-calendar-field',
  templateUrl: './calendar-field.component.html',
  styleUrls: ['./calendar-field.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class CalendarFieldComponent extends FieldType implements OnInit, AfterViewInit, Trigger {
  @ViewChild('calendarEl') calendarEl: CalendarComponent;
  calendar: Calendar;
  title: string;
  showNavButtons: boolean;
  addedDates: string[] = [];
  ranges: {id: string, name: string}[] = [];
  hasFetchedData = true;
  displayMode: 'day' | 'week' | 'work-week' | 'month';
  loadingData = false;

  constructor(
    private cachedApi: CachedApiService,
    private util: UtilityService,
    private translate: TranslateService,
    private device: DeviceService,
    private popup: PopupService,
    private watch: WatchgroupService,
    private markdown: MarkdownService,
    private timeService: TimeService,
    private elRef: ElementRef,
    private fieldService: FieldService,
    private state: StateService,
    private ref: ComponentRefService,
    private logger: NGXLogger,
    private setVars: SetVarsService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label ?? '';
  }

  get jsonPath(): string {
    return this.to.jsonPath ?? '';
  }

  get startProp(): string {
    return this.to.startProp || 'start';
  }

  get stopProp(): string {
    return this.to.stopProp || 'stop';
  }

  get allDayProp(): string {
    return this.to.allDayProp || 'allDay';
  }

  get titleProp(): string {
    return this.to.titleProp || 'title';
  }

  get descriptionProp(): string {
    return this.to.descriptionProp || 'description';
  }

  get bgColorProp(): string {
    return this.to.bgColorProp || 'bgColor';
  }
  get textColorProp(): string {
    return this.to.textColorProp || 'textColor';
  }

  get startDateApi(): string {
    return this.to.startDateApi ?? 'startDate';
  }

  get endDateApi(): string {
    return this.to.endDateApi ?? 'endDate';
  }

  get dateFormatApi(): string {
    return this.to.dateFormatApi || 'YYYY-MM-DD';
  }

  get mode(): 'month' | 'week' | 'day' {
    switch (this.to.mode) {
      case 'week':
      case 'day':
        return this.to.mode;
      case 'work-week':
        return 'week';
      default:
        return 'month';
    }
  }

  get url(): string {
    return this.to.url ?? '';
  }

  get events(): any[] {
    return this.to.events ?? [];
  }

  get allDayLabel(): string {
    return this.to.allDayLabel ?? this.translate.instant('AllDay');
  }

  get noEventsLabel(): string {
    return this.to.noEventsLabel ?? this.translate.instant('NoEvents');
  }

  get httpType(): 'GET' | 'POST' | 'PUT' | 'DELETE' {
    const type = this.to.httpType?.toUpperCase();
    switch (type) {
      case 'POST':
      case 'PUT':
      case 'DELETE':
        return type;
      default:
        return 'GET';
    }
  }

  get apiFetchMode(): 'all' | 'day' | 'range' {
    switch (this.to.apiFetchMode) {
      case 'day':
      case 'range':
        return this.to.apiFetchMode;
      default:
        return 'all';
    }
  }

  get queryMode(): 'local' | 'remote' {
    if (!this.url) {
      return 'local';
    }
    else {
      return this.apiFetchMode === 'range' ? 'remote' : 'local';
    }
  }

  get callDayMultipleTimes(): boolean {
    return this.to.callDayMultipleTimes ?? false;
  }

  get dayViewFormat(): string {
    if (this.to.dayViewFormat) {
      return this.to.dayViewFormat;
    }
    else if (this.translate.currentLang === 'no') {
      return 'EEEEEE dd.MM.yyyy';
    }
    else if (this.translate.currentLang === 'sv') {
      return 'EEEEEE yyyy-MM-dd';
    }
    else {
      return 'EEEEEE dd/MM/yyyy';
    }
  }

  get weekViewFormat(): string {
    return this.to.weekViewFormat || `MMMM yyy, '${this.translate.instant('Week')}' w`;
  }

  get monthViewFormat(): string {
    return this.to.monthViewFormat || 'MMMM yyy';
  }

  get allowedRanges(): string {
    return this.to.allowedRanges?.toLowerCase() ?? 'a';
  }

  get startTime(): number {
    const time = parseInt(this.to.startTime);
    if (!isNaN(time) && time >= 0 && time <= 23) {
      return time;
    }
    else {
      return 6;
    }
  }

  get endTime(): number {
    const time = parseInt(this.to.endTime);
    if (!isNaN(time) && time >= 1 && time <= 24) {
      return time;
    }
    else {
      return 18;
    }
  }

  get calendarHeight(): number {
    const height = 37 * (this.endTime - this.startTime) + 90;
    return this.showAllDayRow ? height : height - 50;
  }

  get showAllDayRow(): boolean {
    if (typeof this.to.showAllDayRow === 'boolean') {
      return this.to.showAllDayRow;
    }
    else if (this.calendar?.events.filter(e => e.allDay).length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get setFullPeriodOnModel(): boolean {
    return this.to.setFullPeriodOnModel ?? false;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  get modelFromDateProp(): string {
    return `_${this.key}_fromDate_`;
  }

  get modelToDateProp(): string {
    return `_${this.key}_toDate_`;
  }

  get modelFromDate(): Date {
    return this.model[this.modelFromDateProp] ? this.timeService.getDateFromString(this.model[this.modelFromDateProp], this.dateFormatApi)
                                              : null;
  }

  set modelFromDate(date: Date) {
    this.model[this.modelFromDateProp] = this.timeService.formatDatetime(date, this.dateFormatApi);
  }

  get modelToDate(): Date {
    return this.model[this.modelToDateProp] ? this.timeService.getDateFromString(this.model[this.modelToDateProp], this.dateFormatApi)
                                            : null;
  }

  set modelToDate(date: Date) {
    this.model[this.modelToDateProp] = this.timeService.formatDatetime(date, this.dateFormatApi);
  }

  get modelSelectedDate(): Date {
    return this.model[this.key as string] ? this.timeService.getDateFromString(this.model[this.key as string], this.dateFormatApi)
                                          : null;
  }

  get alertHeight(): string {
    switch(this.to.detailHeight) {
      case 100:
      case 150:
      case 200:
      case 250:
      case 300:
      case 350:
      case 400:
      case '100':
      case '150':
      case '200':
      case '250':
      case '300':
      case '350':
      case '400':
        return `height-${this.to.detailHeight}`;
      default:
        return '';
    }
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    if (this.to.mode === 'work-week') {
      this.hideWeekend();
      this.displayMode = 'work-week';
    }
    else {
      this.displayMode = this.mode;
    }
    if (typeof this.to.showNavButtons === 'boolean') {
      this.showNavButtons = this.to.showNavButtons;
    }
    else {
      this.showNavButtons = this.device.isMobile() ? false : true;
    }
    if (this.allowedRanges.includes('d') || this.allowedRanges.includes('a')) {
      this.ranges.push({id: 'day', name: 'Day'});
    }
    if (this.allowedRanges.includes('u') || this.allowedRanges.includes('a')) {
      this.ranges.push({id: 'work-week', name: 'WorkWeek'});
    }
    if (this.allowedRanges.includes('w') || this.allowedRanges.includes('a')) {
      this.ranges.push({id: 'week', name: 'Week'});
    }
    if (this.allowedRanges.includes('m') || this.allowedRanges.includes('a')) {
      this.ranges.push({id: 'month', name: 'Month'});
    }
    this.calendar = {
      mode: this.mode,
      locale: this.translate.currentLang,
      allDayLabel: this.allDayLabel,
      noEventsLabel: this.noEventsLabel,
      formatMonthTitle: this.monthViewFormat,
      formatWeekTitle:  this.weekViewFormat,
      formatDayTitle: this.dayViewFormat,
      startHour: this.startTime,
      endHour: this.endTime,
      currentDate: this.timeService.getDateFromString(this.model[this.key as string]),
      events: [],
      queryMode: this.queryMode
    };
    this.getEvents();
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (this.url && this.apiFetchMode === 'all' && watchGroup.fieldKeys.length > 0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => {
        this.getWebData();
      });
    }
  }

  ngAfterViewInit() {
    this.setCssValues([]);
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Update) {
      if (this.modelFromDate && this.modelToDate) {
        this.getWebData(this.modelFromDate, this.modelToDate);
      }
      else if (this.modelSelectedDate) {
        this.getWebData(this.modelSelectedDate);
      }
      else {
        this.getWebData();
      }
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  onViewTitleChanged(title: string) {
    this.title = title;
  }

  async onEventSelected(event: CalendarEvent) {
    this.setVars.shouldCloseOverlays = true;
    await this.popup.showAlert(event.title, event.description, false, true, this.alertHeight);
    this.setVars.shouldCloseOverlays = false;
  }

  onRangeChange(event: { startTime: Date, endTime: Date }) {
    if (!event?.startTime || !event?.endTime) {
      return;
    }
    let {startTime, endTime} = this.util.createCopyOfObject(event);
    if (this.displayMode === 'month' && !this.setFullPeriodOnModel) {
      if (startTime.getDate() !== 1) {
        startTime = this.timeService.getStartOrEndOfPeriod(startTime, 'month', 'start', 1);
      }
      endTime = this.timeService.getStartOrEndOfPeriod(startTime, 'month', 'end');
    }
    else if (this.displayMode === 'work-week') {
      endTime = this.timeService.getStartOrEndOfPeriod(startTime, 'work-week', 'end');
    }
    this.modelFromDate = startTime;
    this.modelToDate = endTime;

    if (this.apiFetchMode === 'range' && this.url) {
      this.getWebData(event.startTime, event.endTime);
    }
  }

  onDateSelected(date: Date) {
    if (!date) {
      return;
    }

    this.fieldService.setValue(this.timeService.formatDatetime(date, this.dateFormatApi));
    const dateString = this.timeService.formatDatetime(date, 'date');
    if (this.apiFetchMode === 'day' && this.url && (!this.addedDates.includes(dateString) || this.callDayMultipleTimes)) {
      this.addedDates.push(dateString);
      this.getWebData(date);
    }
  }

  switchedMode(mode: 'day' | 'week' | 'work-week' | 'month') {
    if (mode === 'work-week') {
      this.hideWeekend();
      this.calendar.mode = 'week';
    }
    else {
      this.showWeekend();
      this.calendar.mode = mode;
    }
  }

  next() {
    this.calendarEl?.slideNext();
  }

  back() {
    this.calendarEl?.slidePrev();
  }

  getEvents() {
    if (this.url && this.apiFetchMode === 'all') {
      this.getWebData();
    }
    else if (this.events.length > 0) {
      this.unpackEvents(this.events, true);
    }
  }

  getWebData(startDate?: Date, endDate?: Date) {
    this.startLoading();
    this.hasFetchedData = true;
    let url = this.util.parseText(this.url, this.model, this.fields);
    if (startDate) {
      url = this.addDateToUrl(url, this.startDateApi, startDate);
    }
    if (endDate) {
      url = this.addDateToUrl(url, this.endDateApi, endDate);
    }
    let obs: Observable<CachedData<any>>;
    if (this.httpType === 'POST') {
      obs = this.cachedApi.getWebJsonWithPost(url, this.jsonPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    else if (this.httpType === 'PUT') {
      obs = this.cachedApi.getWebJsonWithPut(url, this.jsonPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    else if (this.httpType === 'DELETE') {
      obs = this.cachedApi.getWebJsonWithDelete(url, this.jsonPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    else {
      obs = this.cachedApi.getWebJSON(url, this.jsonPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    obs.subscribe(({value, status, source}) => {
      if (status === DataStatus.Updated && Array.isArray(value)) {
        this.unpackEvents(value, this.apiFetchMode !== 'day');
      }
      if (source === DataSource.API) {
        this.stopLoading();
      }
    });
  }

  unpackEvents(data: any[], clearExistingEvents: boolean) {
    const events: CalendarEvent[] = [];
    for (const event of data) {
      const fixedEvent: CalendarEvent = {
        dateString: '',
        title: this.util.dotRef(event, this.titleProp, ''),
        description: this.util.dotRef(event, this.descriptionProp, ''),
        bgColor: this.util.dotRef(event, this.bgColorProp) ?? 'rgb(58, 135, 173)',
        textColor: this.util.dotRef(event, this.textColorProp) ?? 'rgb(255, 255, 255)',
        startTime: this.getDate(event, this.startProp),
        endTime: this.getDate(event, this.stopProp),
        allDay: this.util.dotRef(event, this.allDayProp) ?? false
      };
      fixedEvent.dateString = this.timeService.formatDatetime(fixedEvent.startTime, 'date');
      if (!fixedEvent.allDay) {
        const time = `${moment(fixedEvent.startTime).format('HH:mm')} - ${moment(fixedEvent.endTime).format('HH:mm')}`;
        if (!fixedEvent.title) {
          fixedEvent.title = time;
          if (fixedEvent.description) {
            fixedEvent.description = this.markdown.parse(fixedEvent.description);
          }
        }
        else {
          if (!fixedEvent.description) {
            fixedEvent.description = `<b>${time}</b>`;
          }
          else {
            const compiledDesc = this.markdown.parse(fixedEvent.description);
            fixedEvent.description = `<b>${time}</b>${compiledDesc}`;
          }
        }
      }
      else if (fixedEvent.description) {
        fixedEvent.description = this.markdown.parse(fixedEvent.description);
      }
      if (fixedEvent.allDay && fixedEvent.startTime.getHours() >= fixedEvent.endTime.getHours()) {
        fixedEvent.startTime.setHours(1);
        fixedEvent.endTime.setHours(23);
      }
      events.push(fixedEvent);
    }
    if (clearExistingEvents) {
      this.calendar.events = events;
    }
    else if (events.length > 0) {
      this.calendar.events = this.calendar.events.filter(e => e.dateString !== events[0].dateString).concat(events);
    }
    this.setCssValues(events);
    this.calendarEl?.update();
    this.fieldService.triggerChange();
  }

  private setCssValues(events: CalendarEvent[]) {
    this.elRef.nativeElement.style.setProperty('--ion-calendar-height', `${this.calendarHeight}px`);
    if (this.showAllDayRow) {
      this.elRef.nativeElement.style.setProperty('--ion-calendar-week-top-margin', '87px');
      this.elRef.nativeElement.style.setProperty('--ion-calendar-day-top-margin', '50px');
      this.elRef.nativeElement.style.setProperty('--ion-calendar-allday-display', 'block');
    }
    else {
      this.elRef.nativeElement.style.setProperty('--ion-calendar-week-top-margin', '37px');
      this.elRef.nativeElement.style.setProperty('--ion-calendar-day-top-margin', '0px');
      this.elRef.nativeElement.style.setProperty('--ion-calendar-allday-display', 'none');
    }

    if (events.length > 0 && this.modelSelectedDate && this.displayMode === 'month') {
      setTimeout(() => {
        const year = this.modelSelectedDate.getFullYear();
        const month = this.modelSelectedDate.getMonth() + 1;
        const primaryDates = document.getElementsByClassName('monthview-primary-with-event');
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < primaryDates.length; i++) {
          const dayElement = primaryDates[i] as HTMLTableCellElement;
          const day = dayElement.innerText.trim();
          const event = this.getFirstEventOfDay(events, year, month, day);
          if (event) {
            dayElement.style.backgroundColor = event.bgColor;
          }
        }
      });
    }
  }

  private hideWeekend() {
    this.elRef.nativeElement.style.setProperty('--ion-calendar-weekend-display', 'none');
  }

  private showWeekend() {
    this.elRef.nativeElement.style.setProperty('--ion-calendar-weekend-display', 'table-cell');
  }

  private getDate(event: any, path: string): Date {
    let date = this.util.dotRef(event, path);
    if (!date) {
      date = new Date();
    }
    else if (typeof date === 'string') {
      date = new Date(date);
    }
    return date;
  }

  private addDateToUrl(url: string, dateProp: string, date: Date) {
    if (!dateProp) {
      return url;
    }
    const dateString = this.timeService.formatDatetime(date, this.dateFormatApi);
    return url.includes('?') ? `${url}&${dateProp}=${dateString}` : `${url}?${dateProp}=${dateString}`;
  }

  private getFirstEventOfDay(events: CalendarEvent[], year: number | string, month: number | string, day: number | string): CalendarEvent {
    if ((typeof month === 'number' && month < 10) || (typeof month === 'string' && month.length === 1)) {
      month = '0' + month;
    }
    if ((typeof day === 'number' && day < 10) || (typeof day === 'string' && day.length === 1)) {
      day = '0' + day;
    }
    return events.filter(e => e.dateString === `${year}-${month}-${day}`)
                .sort((a, b) => {
                  if (a.allDay) {
                    return -1;
                  }
                  else if (b.allDay) {
                    return 1;
                  }
                  else if (a.startTime < b.startTime) {
                    return -1;
                  }
                  else {
                    return 1;
                  }
                })[0];
  }

  private startLoading() {
    this.loadingData = true;
    this.fieldService.triggerChange();
  }

  private stopLoading() {
    this.loadingData = false;
    this.fieldService.triggerChange();
  }
}
