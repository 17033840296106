import { first } from 'rxjs/operators';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { switchMap, map, catchError, Observable, EMPTY, Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    isCheckingAuth = false;
    refreshIsDone = new Subject<any>();

    constructor(
    private auth: AuthService,
    private logger: NGXLogger
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
            //Create an error to catch it in catchError().
            if(event instanceof HttpResponse && event.body?.error && (event.body?.unAuthorizedRequest)) {
                throw new HttpErrorResponse({
                    error: 'User not logged inn',
                    headers: event.headers,
                    status: 401,
                    statusText: 'Unauthorized',
                    url: event.url
                });
            }

            return event;
            }
        ),
        catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(req, next);
            }
            return EMPTY;
        })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return new Observable((obs) => {
        let sub = null;
        if(this.isCheckingAuth) {
            sub = this.refreshIsDone.pipe(first()).subscribe( r => obs.next(r));
        }
        else {
            this.isCheckingAuth = true;
            this.auth.refreshSession().then(res => {
                sub = res.subscribe({
                    next: (v) => {
                        this.logger.fatal('Tried to refresh session-cookie. Success: ' + v); //Use fatal to log how often this happens.
                        obs.next(v);
                    }, error: (err) => {
                        this.logger.fatal('Error refreshing session-cookie. ' + err, {error: err});
                    }, complete: () => {
                        this.isCheckingAuth = false;
                        this.refreshIsDone.next(true);
                        obs.complete();
                    }});
            });
        }
        return () => {
            sub?.unsubscribe();
        };
    }).pipe(
        switchMap(res => next.handle(request))
    );

  }
}
