/**For sorting projects */
export enum SortBy {
    /**(New) Sort by project label/name */
    Label = 'label',

    /**(Old) Sort by project name */
    Name = 'name',

    /** (New) Sort by project value/number */
    Value = 'value',

    /**(Old) Sort by project number*/
    Number = 'number',

    /**Don't sort projects */
    NoSort = 'noSort'
}
