<ion-header>
  <ion-toolbar color="header">
    <ion-title class="ion-text-wrap" *ngIf="useCamera">{{'TakeCameraPicture' | translate}}</ion-title>
    <ion-title class="ion-text-wrap" *ngIf="!useCamera">{{'SelectPicture' | translate}}</ion-title>
    <ion-button fill="clear" slot="end" (click)="close(false)">
      <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="in-background">
  <ion-card>
    <ion-list>
      <ion-grid>
        <ion-row>
          <ion-col size="12" *ngIf="useCamera">
            <input type="file" accept="image/*" capture="environment" (change)="setImage($event)" />
          </ion-col>
          <ion-col size="12" *ngIf="!useCamera">
            <input type="file" accept="image/*" (change)="setImage($event)" />
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-item lines="full" *ngIf="imagePath">
        <ion-img [src]="imagePath"></ion-img>
      </ion-item>
      <ion-button color="primary" expand="block" (click)="close(true)" [disabled]="!imagePath">
        <ion-label>{{'Save' | translate}}</ion-label>
      </ion-button>
    </ion-list>
  </ion-card>
</ion-content>