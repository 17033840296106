import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { ModalController } from '@ionic/angular';
import { ImageModalComponent } from 'src/app/custom-components/directives/formly-directives/image-modal/image-modal.component';
import { UtilityService } from 'src/app/services/utility.service';
import { FormType } from 'src/app/models/models';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-field-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  providers: [WatchgroupService]
})
export class ImageComponent extends FieldType implements OnInit {
  label = '';
  src = '';

  constructor(
    private watch: WatchgroupService,
    private modalCtrl: ModalController,
    private util: UtilityService,
    private state: StateService
  ) {
    super();
  }

  get fontSize(): string {
    switch (this.to.textSize) {
      case 1:
      case '1':
        return 'text1';
      case 3:
      case '3':
        return 'text3';
      default:
        return 'text2';
    }
  }

  get imageSize(): string {
    switch (this.to.imageSize) {
      case 'small':
      case 'medium':
        return this.to.imageSize;
      default:
        return 'large';
    }
  }

  get allowZoom(): boolean {
    return (typeof this.to.allowZoom === 'boolean') ? this.to.allowZoom : true;
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  public static getHtml(config: FormlyFieldConfig, _value: any) {
    const label = config.templateOptions.label ?? '';
    // eslint-disable-next-line eqeqeq
    const fontSize = config.templateOptions.textSize == '1' ? '20px' : config.templateOptions.textSize == '3' ? '12px' : '16px';
    const imageSize = config.templateOptions.imageSize === 'small' ? '200px' :
                      config.templateOptions.imageSize === 'medium' ? '400px'
                                                                   : '1000px';
    return `<ion-item lines="none" class="transparent">
              <ion-list>
                <div style="font-size: ${fontSize}; margin: 10px 0;">${label}</div>
                <img src="${config.templateOptions.src}" style="width=100%; max-width: ${imageSize}; max-height: ${imageSize};"/>
              </ion-list>
            </ion-item>`;
  }

  ngOnInit() {
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length === 0) {
      this.label = this.to.label || '';
      this.src = this.to.src || '';
    }
    else {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => {
        this.parseData();
      });
      this.parseData();
    }
  }

  async openModal() {
    if (!this.allowZoom) return;

    const modal = await this.modalCtrl.create({
      component: ImageModalComponent,
      componentProps: {
        inputUrls: [this.src]
      }
    });
    await modal.present();
  }

  private parseData() {
    const url = this.util.parseText(this.to.src, this.model, this.fields);
    const label = this.util.parseText(this.to.label, this.model, this.fields);
    if (this.util.textIsParsed(url) && this.util.textIsParsed(label)) {
      this.src = url;
      this.label = label;
    }
  }
}
