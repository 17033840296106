import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Fields } from '../models/models';

/**
 * Service for doing time and/or date related operatioms
 */
@Injectable({
  providedIn: 'root'
})
export class TimeService {
  /**
   * Check if an field contains time data.
   * @param field The field to check
   */
  isTime(field: FormlyFieldConfig): boolean {
    if (!field) {
      return false;
    }
    else if (field.type === Fields.Input && field.templateOptions?.type === 'time') {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Check if an field contains just date data.
   * @param field The field to check
   */
  isJustDate(field: FormlyFieldConfig): boolean {
    if (!field) {
      return false;
    }
    else if (field.type === Fields.Input && field.templateOptions?.type === 'date') {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Checks if an field contains date and optional time data, but not only time
   * @param field The field to check
   */
  isDate(field: FormlyFieldConfig): boolean {
    if (!field) {
      return false;
    }
    else if (this.isDatetime(field, true) || this.isJustDate(field)) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Checks if an field contains date and/or time data
   * @param field The field to check
   */
  isTimeOrDate(field: FormlyFieldConfig): boolean {
    if (!field) {
      return false;
    }
    else if (this.isDate(field) || this.isTime(field)) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Checks if an field contains both date and time data.
   * @param field The field to check
   * @param includeDatetimeField Whether to include the `datetime` field as well as `input` fields.
   */
  isDatetime(field: FormlyFieldConfig, includeDatetimeField: boolean): boolean {
    if (!field) {
      return false;
    }
    else if (field.type === Fields.Datetime) {
      return includeDatetimeField;
    }
    else if (field.type === Fields.Input) {
      return field.templateOptions?.type === 'datetime-local';
    }
    else {
      return false;
    }
  }

  /**
   * Checks if field contains month data
   * @param field The field to check
   */
  isMonth(field: FormlyFieldConfig) {
    if (!field) {
      return false;
    }
    else if (field.type === Fields.Input) {
      return field.templateOptions?.type === 'month';
    }
    else {
      return false;
    }
  }

  /**
   * Checks if an field contains date or month data
   * @param field The field to check
   */
  isDateOrMonth(field: FormlyFieldConfig) {
    return this.isDate(field) || this.isMonth(field);
  }

  /**
   * Checks if an field contains time, date and/or month data
   * @param field The field to check
   */
  isTimeDateOrMonth(field: FormlyFieldConfig) {
    return this.isTimeOrDate(field) || this.isMonth(field);
  }

  /**
   * Get's the type of time/date the field is
   * @param field The field to get time type from
   */
  getTimeType(field: FormlyFieldConfig): string {
    if (!this.isTimeDateOrMonth(field)) return '';

    if (field.templateOptions.type === 'time' || field.templateOptions.type === 'date' || field.templateOptions.type === 'month') {
      return field.templateOptions.type;
    }
    else {
      return 'datetime';
    }
  }

  /**
   * Format an datetime correctly
   * @param datetime The datetime to format
   * @param type The type of datetime
   * @param setCurrentTime If it should set the time part to current time, default: `false`
   */
  formatDatetime(datetime: moment.Moment | string | number | Date, type: string, setCurrentTime = false, unitFromNow?: number | string, unit: 'days' | 'weeks' | 'months' | 'years' = 'days'): string {
    if (!datetime) return '';
    // eslint-disable-next-line max-len
    const formats = ['MMM DD YYYY h:mma', 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm', 'DD/MM/YYYY', 'DD/MM/YYYY HH:mm', 'MM/DD/YYYY', 'MM/DD/YYYY HH:mm', 'HH:mm'];

    let momentTime: moment.Moment;
    if (typeof datetime === 'string') {
      if (datetime.length === 5) {
        momentTime = moment(datetime, formats[7]);
      }
      else if (datetime.includes('.')) {
        if (datetime.length === 10) {
          momentTime = moment(datetime, formats[1]);
        }
        else if (datetime.length === 16) {
          momentTime = moment(datetime, formats[2]);
        }
        else {
          momentTime = moment(datetime);
        }
      }
      else if (datetime.includes('/')) {
        const day = +datetime.slice(0, 2);
        if (datetime.length === 10) {
          if (day > 12) {
            momentTime = moment(datetime, formats[3]);
          }
          else {
            momentTime = moment(datetime, formats[5]);
          }
        }
        else {
          if (day > 12) {
            momentTime = moment(datetime, formats[4]);
          }
          else {
            momentTime = moment(datetime, formats[6]);
          }
        }
      }
      else if (datetime.endsWith('am') || datetime.endsWith('pm')) {
        momentTime = moment(datetime, formats[0]);
      }
      else {
        momentTime = moment(datetime);
      }
      let i = 0;
      while (!momentTime.isValid()) {
        momentTime = moment(datetime, formats[i]);
        i++;
      }
    }
    else if (typeof datetime === 'number') {
      momentTime = moment(datetime);
    }
    else if (datetime instanceof Date) {
      momentTime = moment(datetime);
    }
    else {
      momentTime = datetime;
    }
    if (setCurrentTime) {
      const current = moment();
      momentTime = momentTime.set('hour', current.get('hour')).set('minute', current.get('minute'));
    }
    if (unitFromNow) {
      if (typeof unitFromNow === 'string') {
        unitFromNow = parseInt(unitFromNow);
      }
      if (!isNaN(unitFromNow)) {
        momentTime.add(unitFromNow, unit);
      }
    }
    switch (type) {
      case 'date':
        return momentTime.format('YYYY-MM-DD');
      case 'time':
        return momentTime.format('HH:mm');
      case 'full':
        return momentTime.format('YYYY-MM-DDTHH:mm:ss');
      case 'datetime':
      case 'datetime-local':
        return momentTime.format('YYYY-MM-DDTHH:mm');
      case 'month':
        return momentTime.format('YYYY-MM');
      default: {
        const format = type.match(/^[yYmMDHhsT:\-\.\s\/,]+$/) ? type : 'YYYY-MM-DDTHH:mm';
        return momentTime.format(format);
      }
    }
  }

  /**
   * Converts an Date to an ISO-string i local time. If date isn't given it uses the curent time
   * @param date (Optional) The date to convert
   */
  dateToLocalISO(date?: Date): string {
    if (date) {
      return moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }
    else {
      return moment().format('YYYY-MM-DDTHH:mm:ss');
    }
  }

  /**
   * Convert a datetime string to an date object. Returns current time if string is invalid.
   * @param datestring The string containing the date
   * @param format (Optional) Format of the datetime string. If not given, it will be asumed it's ISO format
   */
  getDateFromString(datestring: string, format?: string): Date {
    if (!datestring) {
      return new Date();
    }
    let date: Date;
    if (format) {
      date = moment(datestring, format).toDate();
    }
    else {
      date = new Date(datestring);
    }
    if (isNaN(date.getTime())) {
      date = new Date();
    }
    return date;
  }


  /**
   * Find the start or end of an given period from an given date
   * @param date The date to find start or end from
   * @param periodType The type of period, `year`, `month` or `week`
   * @param startOrEnd To find the `start` or `end`
   * @param offset (Optional) Offset for year (when type is year) or for month (when type is month), default: 0
   */
  getStartOrEndOfPeriod(date: Date, periodType: 'year' | 'month' | 'week' | 'work-week', startOrEnd: 'start' | 'end', offset = 0): Date {
    if (periodType === 'year') {
      if (startOrEnd === 'start') {
        return new Date(date.getFullYear() + offset, 0, 1, date.getHours(), date.getMinutes(), date.getSeconds());
      }
      else if (startOrEnd === 'end') {
        return new Date(date.getFullYear() + offset, 11, 31, date.getHours(), date.getMinutes(), date.getSeconds());
      }
      else {
        return null;
      }
    }
    else if (periodType === 'month') {
      if (startOrEnd === 'start') {
        return new Date(date.getFullYear(), date.getMonth() + offset, 1, date.getHours(), date.getMinutes(), date.getSeconds());
      }
      else if (startOrEnd === 'end') {
        return new Date(date.getFullYear(), date.getMonth() + 1 + offset, 0, date.getHours(), date.getMinutes(), date.getSeconds());
      }
      else {
        return null;
      }
    }
    else if (periodType === 'week') {
      if (startOrEnd === 'start') {
        const day = date.getDay();
        const diff = day === 0 ? -6 : 1 - day;
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff, date.getHours(), date.getMinutes(), date.getSeconds());
      }
      else if (startOrEnd === 'end') {
        const day = date.getDay();
        const diff = day === 0 ? 0 : 7 - day;
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff, date.getHours(), date.getMinutes(), date.getSeconds());
      }
      else {
        return null;
      }
    }
    else if (periodType === 'work-week') {
      if (startOrEnd === 'start') {
        const day = date.getDay();
        const diff = day === 0 ? -6 : 1 - day;
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff, date.getHours(), date.getMinutes(), date.getSeconds());
      }
      else if (startOrEnd === 'end') {
        const day = date.getDay();
        const diff = day === 0 ? -2 : 5 - day;
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff, date.getHours(), date.getMinutes(), date.getSeconds());
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  /**
   * Create a date with X units from now.
   * @param date The date to start with. Default now();
   * @param duration create date X units from current date
   * @param unit The type of period, `years`, `months`, `weeks`, `days`, `hours`, `minutes`. Default `days`.
   * @returns Date object with the calculated date.
   */
  createDate(date?: string | Date, duration?: number, unit?: moment.unitOfTime.DurationConstructor): Date {
    const m = date ? moment(date) : moment();
    if (duration) {
      unit ||= 'days';
      m.add(duration, unit);
    }

    return m.toDate();
  }

  convertFromDay1900(day: number): Date {
    return moment([1899, 11, 30]).add(day, 'days').toDate();
  }
  convertToDay1900(date: Date): number {
    return moment(date).diff(moment([1899, 11, 30]), 'days');
  }
}
