<ion-content>
  <ion-list>
    <ion-list-header *ngIf="title">{{title}}</ion-list-header>
    <ion-item button lines="full" (click)="toggleOnline()" *ngIf="showOnlineButton">
      &nbsp;<app-online-status #online [fill]="'clear'" [slot]="'start'" [show]="true" [noClick]="true"></app-online-status>
      <ion-label>&nbsp;&nbsp;{{onlineText}}</ion-label>
    </ion-item>
  <ng-container *ngFor="let item of items; let index = index">
    <ng-container *ngTemplateOutlet="item.type === 'divider' ? divider : button; context: {item: item, index: index}"></ng-container>
  </ng-container>
</ion-list>
</ion-content>

<ng-template #divider let-name="item.name">
  <ion-item-divider color="light">
    <ion-label>{{name}}</ion-label>
  </ion-item-divider>
</ng-template>

<ng-template #button let-style="item.style" let-name="item.name" let-active="item.active" let-disabled="item.disabled" let-index="index">
  <ion-item button lines="{{style.haveLine ? 'inset': 'none'}}" (click)="itemClick(index)" [ngClass]="{disableButton: disabled | async}">
    <ion-icon *ngIf="style.active.icon" name="{{active ? style.active.icon : style.inactive.icon }}" slot="start" color="{{active ? style.active.color : style.inactive.color }}"class="ion-padding-start"></ion-icon>
    <ion-label class="ion-text-wrap" [innerHTML]="name"></ion-label>
  </ion-item>
</ng-template>