import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { FieldService } from 'src/app/services/field.service';
import { FieldType } from '@ngx-formly/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { DeviceService } from 'src/app/services/device.service';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import * as moment from 'moment';
import { FormService } from 'src/app/services/form.service';
import { ModalController, Platform } from '@ionic/angular';
import { UtilityService } from 'src/app/services/utility.service';
import { DataSource, DataStatus, FirebaseEvent, FormType, OldReg, PageRefs } from 'src/app/models/models';
import { TranslateService } from '@ngx-translate/core';
import { FormPage } from 'src/app/pages/form/form.page';
import { FormViewComponent } from 'src/app/custom-components/directives/page-directives/form-view/form-view.component';
import { SubFormComponent } from 'src/app/custom-components/directives/formly-directives/sub-form/sub-form.component';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { RegistrationModalComponent } from 'src/app/custom-components/directives/shared-directives/registration-modal/registration-modal.component';
import { TimeAgoPipe } from 'src/app/pipes/time-ago.pipe';
import { NGXLogger } from 'ngx-logger';
import { StatisticsService } from 'src/app/services/statistics.service';
import { PopupService } from 'src/app/services/popup.service';
import { isArray } from 'lodash';

@Component({
  selector: 'app-field-d4go',
  templateUrl: './d4-go.component.html',
  styleUrls: ['./d4-go.component.scss'],
  providers: [WatchgroupService, FieldService, TimeAgoPipe]
})
export class D4GoComponent extends FieldType implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('prevChoicesContainer', { static: false }) prevChoicesContainer: ElementRef;

  jsonModel = {
    datetime: '',
    dateOnly: '',
    timeOnly: '',
    buttonPressed: '',
    submitDate: '',
    tabs: {
      Tab2: { Nr: '', Navn: '' },
      Tab3: { Nr: '', Navn: '' },
      Tab4: { Nr: '', Navn: '' },
      Tab5: { Nr: '', Navn: '' },
      Tab6: { Nr: '', Navn: '' },
      Tab7: { Nr: '', Navn: '' },
      Tab8: { Nr: '', Navn: '' },
      Tab9: { Nr: '', Navn: '' },
      Kommentar: ''
    },
    timeStopped: false
  };
  jsonModalEmpty = this.util.createCopyOfObject(this.jsonModel);
  jsonModalInit = this.util.createCopyOfObject(this.jsonModel);
  tabsLabel = { Tab2: '', Tab3: '', Tab4: '', Tab5: '', Tab6: '', Tab7: '', Tab8: '', Tab9: '' };
  btnType = {
    startBtn: 1,
    endBtn: 3
  };
  format = { time: 'HH:mm', date: 'DD.MM.YY', dateformat: 'YYYY-MM-DD', datetime: 'YYYY-MM-DDTHH:mm:ss' };
  currDateTime = moment();
  currTime = this.currDateTime.format(this.format.time);
  currDate = this.currDateTime.format(this.format.date);
  currDateVal = this.currDateTime.format(this.format.dateformat);

  time: BehaviorSubject<string> = new BehaviorSubject(this.currDateTime.format(this.format.time));
  timeHour: BehaviorSubject<string> = new BehaviorSubject(this.currTime.split(':')[0]);
  timeMin: BehaviorSubject<string> = new BehaviorSubject(this.currTime.split(':')[1]);

  showLastReg = false;
  showSumTabs = false;
  showTabs = false;
  showPrevChoices = true;

  prevChoicesLoading = false;
  subTabsUpdated = false;
  subLastRegUpdated = false;

  prevChoices = [];
  projectTab = 0; // first visible tab.

  sumTabsLabel = '';
  lastRegSumValues = '';

  allowEditDatetime = false;

  shouldUpdateTime = true;
  showMarker = true;
  intervalSub: any;
  resumeSub: Subscription;

  isSubmitting = false;
  btnPressed = { type: '', time: '' };

  lastReg: OldReg;
  lastRegValues = this.jsonModel;
  lastRegLoading = false;
  lastRegFound = false;

  prevChoicesIsOpened = false;
  hasDefaultTabs = false;
  defaultApiUrl = '';
  tabState: 'empty' | 'model' | 'lastReg'  | 'defaults' | 'user' = 'empty';
  requiredTabs: string[] = [];

  constructor(
    private formService: FormService,
    private fieldService: FieldService,
    private ref: ComponentRefService,
    private device: DeviceService,
    private plt: Platform,
    private util: UtilityService,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private cachedApi: CachedApiService,
    private timeAgo: TimeAgoPipe,
    private logger: NGXLogger,
    private changeRef: ChangeDetectorRef,
    private statistics: StatisticsService,
    private popup: PopupService
  ) {
    super();
    this.fieldService.setField(this);
  }


  get isMobile(): boolean {
    return this.device.isMobile();
  }
  get hasSteps(): boolean {
    return this.formService.hasSteps;
  }

  get getDate(): string {
    return this.currDate || 'dd.mm.yy';
  }

  get url(): string {
    return this.to.url;
  }
  get postData(): any {
    return this.jsonModel;
  }
  get isMissingRequiredValues(): boolean {
    return !this.hasRequiredTabs();
  }

  get isDisabled(): boolean {
    return this.to.disabled || this.isSubmitting || false;
  }

  get sumTabs(): string {
    let v: string;
    v = this.sumTabsLabel;
    if(!v && this.projectTab)
      v = this.translate.instant('NoneSelected', {project: this.tabsLabel['Tab' + this.projectTab]});
    else if(!v)
      v = this.translate.instant('NoneSelected', {project: ''});

    return v;
  }

  get clickedAt(): string {
    return this.btnPressed.time;
  }
  get startBtnLabel(): string {
    return this.to.startBtnLabel || this.translate.instant('StartBtn');
  }
  get endBtnLabel(): string {
    return this.to.endBtnLabel ||  this.translate.instant('EndBtn');
  }
  get startBtnWasPressed(): string {
    return this.lastRegValues.buttonPressed === 'startBtn' ? this.timeAgo.transform(this.lastRegValues.submitDate) : '';
  }
  get endBtnWasPressed(): string {
    return this.lastRegValues.buttonPressed === 'endBtn' ? this.timeAgo.transform(this.lastRegValues.submitDate) : '';
  }

  get subBtnLabel(): string {
    return (this.btnType[this.lastRegValues.buttonPressed] === 1) ? this.startBtnLabel : this.endBtnLabel;
  }
  get lastRegText(): string {
    if (!this.lastRegFound)
      return '';
    else {
      return this.lastRegSumValues;
    }
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get pageRef(): PageRefs {
    return this.to.currentForm?.ref;
  }
  get tabsLabelApi(): string {
    return this.to.tabsLabelApi;
  }
  get labelSplit(): string {
    return this.to.labelSplit || '<br/>';
  }

  get toggleBtnText(): string {
    return this.prevChoicesIsOpened ? this.translate.instant('PrevHistClose') : this.translate.instant('PrevHistOpen');
  }

  get isHistory(): boolean {
    return this.to?.disabled ?? false;
  }

  get hasSubForm(): boolean {
    return (this.to?.tabsFormId > 0) ?? false;
  }

  get subFormId(): string {
    return this.hasSubForm ? this.to.tabsFormId : null;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    this.logger.debug('init');
    this.defaultApiUrl = this.to.url;
    this.logger.debug(this.model[this.key as string]);
    if (this.model[this.key as string]) {
      this.jsonModel = this.model[this.key as string];
      this.tabState = 'model';
    }
    //console.log('jsonModel: ', this.jsonModel);
    if (this.jsonModel?.datetime) {
      this.setCurrentTime(this.jsonModel.datetime);
    }
    if (this.jsonModel?.datetime || this.jsonModel?.timeStopped || this.to?.disabled) {
      this.jsonModel['timeStopped'] = true;
      this.shouldUpdateTime = false;

      if (this.jsonModel.buttonPressed)
        this.btnPressed = { type: this.jsonModel.buttonPressed, time: moment(this.jsonModel.submitDate).format(this.format.time) };
    }

    const requiredTabs = this.to.mandatoryTabs ?? [];
    if(requiredTabs && !isArray(requiredTabs)) { //fix in case of mal varible not set.
      const arr = requiredTabs.split(',') ?? []; //e.g. "Tab2,Tab3,Tab4,Comment"
      this.requiredTabs = arr.map(v => v.trim());
    }
    else if(requiredTabs && isArray(requiredTabs)) {
      this.requiredTabs = requiredTabs;
    }
    if(this.requiredTabs.length > 0) {
      const keys = Object.keys(this.tabsLabel);
      keys.push('Kommentar');
      this.requiredTabs = this.requiredTabs.map(v => v === 'Comment' ? 'Kommentar': v.charAt(0)?.toLocaleUpperCase() + v.slice(1));
      this.requiredTabs = this.requiredTabs.filter(v => keys.includes(v));
    }

    this.allowEditDatetime = (this.to.allowEditDatetime) ? this.to.allowEditDatetime : false;
    this.showLastReg = ((this.to.disabled) ? false : this.to.showLastReg || false);
    this.showTabs = (this.hasSubForm || this.hasRequiredTabs()) ?? false; // Can set tabs or tabsvalue already set.
    this.showPrevChoices = (!this.isHistory && this.to.prevChoiceApi && this.to.showPrevChoice) || false;
    this.logger.debug('jsonModel',this.jsonModel);
    this.logger.debug('state', this.tabState);
    this.logger.debug('isHistory', this.isHistory);

    if (this.showTabs) {
      this.getTabLabels();
    }
    if (this.showLastReg) {
      this.getLastReg();
    }
    if(this.showPrevChoices) {
      this.getPrevChoiches();
    }

  }

  ngAfterViewInit() {

    if (this.shouldUpdateTime) {
      this.logger.debug('shouldupdate - afterInit');
      this.setCurrentTime();
      this.startLiveUpdate();
    }

    this.resumeSub = this.plt.resume.subscribe(() => {
      this.logger.debug('Resume! Welcome back!');
      if (this.shouldUpdateTime) {
        this.logger.debug('shouldupdate -from resume');
        this.setCurrentTime();
        this.startLiveUpdate();
      }
    });
  }

  //Used in Dom.
  setTabs(event: MouseEvent, index: number, editClick: boolean) {
    event.stopPropagation();

    if(!this.prevChoices[index])
      return;

    const v = this.prevChoices[index];
//    console.log(v);
    this.jsonModel.tabs = v.tabs;
    this.tabState = 'user';
    this.buildTabsLabel();
    this.subTabsUpdated = true;
    setTimeout(x => {
      this.subTabsUpdated = false;
    },500);
/*    if(editClick) {
      this.showTabsModal(v.tabs);
    }
    else {
      this.jsonModel.tabs = v.tabs;
      this.tabState = 'user';
      this.buildTabsLabel();
      this.doSubmit('startBtn');
    }*/
  }

  trackbyId(index: number, item: any) {
    if (!item)
      return null;
    if (item.id)
      return item.id;
    if (item.regId) // history regs.
      return item.regId;
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  setTime(type: string, data: any) {
    if (!this.allowEditDatetime)
      return;
    const val = data.target.value; // data is an $event
    const currTime = moment();

    //    this.setModel(type,val);

    let t = null;
    if (type === 'time') {
      t = moment(this.currDateVal + ' ' + val);
      this.statistics.logEvent(FirebaseEvent.D4GoSetTime);
    }
    else if (type === 'date') {
      t = moment(val + ' ' + this.currTime);
      this.statistics.logEvent(FirebaseEvent.D4GoSetDate);
    }
    else {
      t = moment(val);
    }
    this.logger.debug(t);
    this.logger.debug(currTime);
    if (currTime.diff(t, 'minute') > 1) {
      this.logger.debug('stop real time');
      this.setModel('timeStopped', true);
      this.stopLiveUpdate();
    }
    else if (!this.shouldUpdateTime) {
      this.logger.debug('start real time');
      this.startLiveUpdate();
    }
    this.setCurrentTime(t.toISOString(true));
  }

  stopLiveUpdate() {
    this.logger.debug('Stop startLiveupdate');
    this.shouldUpdateTime = false;
    this.showMarker = true;
    this.clearIntervals();
    this.resumeSub?.unsubscribe();
  }
  startLiveUpdate() {
    this.logger.debug('startLiveupdate');
    this.shouldUpdateTime = true;

    if (!this.intervalSub || this.intervalSub.closed)
      this.intervalSub = interval(1000).subscribe(x => {
        this.showMarker = !this.showMarker;
        if (this.shouldUpdateTime) {
          this.setCurrentTime();
        }
      });

  }

  hasEmptyTabs() {
    let hasValue = false;
      for(const t of Object.keys(this.jsonModel.tabs)) {
        if(this.jsonModel.tabs[t]?.Nr) {
          hasValue = true;
          break;
        }
      }
    return !hasValue;
  }
  hasRequiredTabs() {
    let isMissing = false;
    this.requiredTabs?.every(tab => {
      if((tab !== 'Kommentar' && !this.jsonModel.tabs[tab]?.Nr) || (tab === 'Kommentar' && !this.jsonModel.tabs[tab])) {
        isMissing = true;
        return false;
      }
      return true;
    });
    return !isMissing;
  }
  missingTabsLabel() {
    const missing = [];
    this.requiredTabs?.forEach(tab => {
      if(tab === 'Kommentar' && !this.jsonModel.tabs[tab])
        missing.push(this.translate.instant('Comment'));
      else if(tab !== 'Kommentar' && !this.jsonModel.tabs[tab]?.Nr) {
        missing.push(this.tabsLabel[tab] ? this.tabsLabel[tab]: tab);
      }
    });
    return missing;
  }

  ngOnDestroy() {
    this.clearIntervals();
    this.resumeSub?.unsubscribe();
  }

  clearIntervals() {
    this.logger.debug('clearIntervals');
    if (this.intervalSub && !this.intervalSub.closed) {
      this.intervalSub.unsubscribe();
    }
    this.logger.debug('clearIntervals done');
  }

  setCurrentTime(preset?: string) {
    this.currDateTime = moment(preset);
    this.currTime = this.currDateTime.format(this.format.time);
    this.currDate = this.currDateTime.format(this.format.date);
    this.currDateVal = this.currDateTime.format(this.format.dateformat);
    this.setModel('timeOnly', this.currTime);
    this.setModel('dateOnly', this.currDateVal);
    this.setModel('datetime', this.currDateTime.format(this.format.datetime));

    this.updateTime();
  }

  updateTime() {
    const v = this.currTime.split(':');
    const t = (this.showMarker) ? v[0] + ':' + v[1] : v[0] + '  ' + v[1];
    this.time.next(t);
    this.timeHour.next(v[0]);
    this.timeMin.next(v[1]);
    this.changeRef.detectChanges();
  }

  setModel(key: string, val: any) {
    this.jsonModel[key] = val;
  }

  async showTabsModal(tabs?) {
    if (this.isHistory || this.subFormId === null)
      return;
    const val = (tabs) ? tabs : this.jsonModel.tabs;
    const modal = await this.modalCtrl.create({
      component: SubFormComponent,
      componentProps: {
        values: val,
        formId: this.subFormId
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.jsonModel.tabs = data;
      this.tabState = 'user';
      this.buildTabsLabel();
    }
  }

  getLastReg(highlight = false) {
    if (this.formService.currentProject.id && this.formService.formId) {
      this.lastRegLoading = true;
      this.cachedApi.getLastRegistration(this.formService.formId, this.formService.currentProject.id).subscribe(data => {
        if (!data.value) {
          this.lastRegSumValues = '';
        }
        else if (data.value && data.status === DataStatus.Updated) {
          this.lastReg = data.value;
          this.cachedApi.getRegistration(data.value.regId).subscribe(res => {
            const reg = res.value;
            if (reg && res.status === DataStatus.Updated) {
              this.lastRegFound = true;
              this.lastRegLoading = false;
              //Tabs values
              const val = reg.values.filter(obj => obj.key === this.key);

              if (val.length > 0)
                this.lastRegValues = JSON.parse(val[0].value);
              this.jsonModel.tabs = this.lastRegValues.tabs;
              this.tabState = 'lastReg';
              this.buildTabsLabel();
              this.buildLastRegLabel();
            }
          });
        }
        if (data.source === DataSource.API) {
          this.lastRegLoading = false;
        }
        if(highlight) {
          this.subLastRegUpdated = true;
          setTimeout(x => {
            this.subLastRegUpdated = false;
          },700);
        }
      });
    }
  }

  getPrevChoiches() {
    if (this.formService.currentProject.id && this.formService.formId) {
      this.prevChoicesLoading = true;
      const url = this.to.prevChoiceApi;
      this.cachedApi.getWebJSON(url, '', this.formId, this.to.headers, this.to.useCredentials).subscribe(data => {
        if (!data.value) {
          this.lastRegSumValues = '';
        }
        else if (data.value && data.status === DataStatus.Updated) {
          const d = data.value.map(res => {
            const terminal = (/^IN/.test(res.KommerFra)) ? 'IN':'TERMINAL';
            const r = {label: res.Navn, deviceType: terminal, submitted: res.Opprettet, tabs: {
              Tab2: { Nr: res.Tab2Nr, Navn: res.Tab2Navn},
              Tab3: { Nr: res.Tab3Nr, Navn: res.Tab3Navn},
              Tab4: { Nr: res.Tab4Nr, Navn: res.Tab4Navn},
              Tab5: { Nr: res.Tab5Nr, Navn: res.Tab5Navn},
              Tab6: { Nr: res.Tab6Nr, Navn: res.Tab6Navn},
              Tab7: { Nr: res.Tab7Nr, Navn: res.Tab7Navn},
              Tab8: { Nr: res.Tab8Nr, Navn: res.Tab8Navn},
              Tab9: { Nr: res.Tab9Nr, Navn: res.Tab9Navn},
              Kommentar: res.Kommentar
            }};
            return r;
          });
          this.prevChoices = d;
        }
        if (data.source === DataSource.API) {
          this.prevChoicesLoading = false;
        }
      });
    }
  }

  buildLastRegLabel() {
    this.lastRegSumValues = '[' + this.subBtnLabel + '] ' + moment(this.lastRegValues.datetime).format('HH:mm, DD.MM.YY') + '.';
  }

  async openRegistration() {
    if (!this.lastRegFound) {
      return;
    }
    this.statistics.logEvent(FirebaseEvent.D4GoOpenLastReg);
    const modal = await this.modalCtrl.create({
      component: RegistrationModalComponent,
      componentProps: {
        reg: this.lastReg
      }
    });
    await modal.present();
  }

  toggleView() {
    this.prevChoicesIsOpened = !this.prevChoicesIsOpened;
    this.prevChoicesContainer.nativeElement.style.maxHeight = (this.prevChoicesIsOpened) ? '100%': '0px';
  }

  getTabLabels() {
    if (!this.tabsLabelApi) {
      return false;
    }
    const paths = this.formService.getStylePaths(this.to.tabsLabelProps);
    const keys = Object.keys(this.tabsLabel);
    this.cachedApi.getStyleData(this.tabsLabelApi, paths, keys, this.formId).subscribe(({value: tabsLabel, status}) => {

      if (Array.isArray(tabsLabel) && status === DataStatus.Updated) {
        tabsLabel = tabsLabel.filter(t => t.visible);
        if (tabsLabel.length > 0) {
          this.projectTab = +tabsLabel[0].key.slice(-1);
        }
        tabsLabel.forEach(t => {
          this.tabsLabel[t.key] = t.label;
          if(t.value) {
            this.jsonModalInit.tabs[t.key].Nr = t.value;
            this.jsonModalInit.tabs[t.key].Navn = t.valueName;
            this.hasDefaultTabs = true;
          }
        });
        if(this.hasDefaultTabs && this.tabState === 'empty') {
            this.jsonModel.tabs = this.jsonModalInit.tabs;
            this.tabState = 'defaults';
        }
        this.buildTabsLabel();
      }
    });
  }

  buildTabsLabel() {
    //split = "<br>", ","
//    console.log('state',this.tabState);
    let txt = '';
    const t = this.jsonModel.tabs;
    if(!t || this.tabState === 'empty') {
      this.sumTabsLabel = txt;
      return txt;
    }

    Object.keys(this.tabsLabel).forEach(tab => {
      const label = '<strong>' + this.tabsLabel[tab] + ':</strong> ';
      txt += (txt === '') ?
        ((t[tab]?.Navn) ? label + t[tab].Navn : '') :
        ((t[tab]?.Navn) ? this.labelSplit + label + t[tab]?.Navn : '');
    });

    if(t.Kommentar !== undefined && t.Kommentar !== '')
      txt += (txt === '') ? '"' + t.Kommentar + '"' : this.labelSplit + '"' + t.Kommentar + '"';
    else
      t.Kommentar = '';

    if(txt === '')
      this.tabState = 'empty';
//    console.log('state',this.tabState);
    this.sumTabsLabel = txt;
    this.changeRef.detectChanges();
  }
  // == BUTTONS ==

  async doSubmit(buttonSubmitting: 'startBtn' | 'endBtn') {
    if (!this.pageRef || !this.formId || !this.formType) return;

    const page: FormPage | FormViewComponent | SubFormComponent = this.ref.getReference(this.pageRef, this.formId, this.formType)[0];
    if (!page) {
      return;
    }
    this.logger.debug(page);
    if (buttonSubmitting === 'startBtn' && this.isMissingRequiredValues) {
      await this.popup.showAlert('MissingValues', this.missingTabsLabel().join(', '), true);
      this.showTabsModal();
      return;
    }

    this.setModel('buttonPressed', buttonSubmitting);
    this.setModel('submitDate', moment().toISOString(true));

    this.model['buttonfunction'] = 'reg';//this.buttonFunction;
    this.model['pressed'] = this.key;
    if(!this.to.muteSuccessPopup)
      this.to.showMsg = {msg: this.translate.instant('SubmitUploaded'), duration: 1500, delay: 0};
    const res = this.util.createCopyOfObject(this.jsonModel);
    this.fieldService.setValue(res);
    //      this.model[this.key as string] = this.jsonModel;
    this.to.url = this.util.parseText(this.to.url, res); // time&date.
    this.to.url = this.util.parseText(this.to.url, res.tabs); // subFormData.
    this.to.url = this.util.parseText(this.to.url, { btnType: this.btnType[res.buttonPressed] }); // which btn was used.

    this.btnPressed = { type: res.buttonPressed, time: moment(res.submitDate).format(this.format.time) };
    this.isSubmitting = true;
    await page.submit(this.model);
    this.isSubmitting = false;
    this.to.url = this.defaultApiUrl; //reset parsed url

    if(this.to.externalTrigger) {
      const ex = this.to.externalTrigger;
//      const data = (ex.type === TriggerType.Set) ? ((ex.dataParse) ? this.util.dotRef(res, ex.dataParse) : res) : '';
      this.ref.callReference(ex.key, this.formId, this.formType, ex.type, res);
    }
    if (this.showLastReg)
      this.getLastReg(true);
    if(this.showPrevChoices)
      this.getPrevChoiches();
    this.startLiveUpdate(); //restart timer
    this.statistics.logEvent(buttonSubmitting === 'startBtn' ? FirebaseEvent.D4GoSubmitStart : FirebaseEvent.D4GoSubmitEnd);
  }



}
