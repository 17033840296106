import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-text-popover',
  templateUrl: './text-popover.component.html',
  styleUrls: ['./text-popover.component.scss'],
})
export class TextPopoverComponent implements OnInit {
  @Input() size: number;
  text = '';
  constructor(
    private popCtrl: PopoverController,
    private logger: NGXLogger
  ) { }

  ngOnInit() {}

  async closePopover(save: boolean) {
    try {
      if (save) {
        await this.popCtrl.dismiss({text: this.text, size: this.size});
      }
      else {
        await this.popCtrl.dismiss();
      }
    }
    catch (err) {
      this.logger.warn('Failed dismissing text popover', err);
    }
  }

}
