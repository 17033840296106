/**
 * Keys for meta data on model
 */
export enum ModelMetaData {
    OfflineReg = '_offlineReg_',
    DeviceInfo = '_deviceinfo_',
    AppVersion = '_appversion_',
    SubmitDateTime = '_submitdatetime_',
    ModalSearch = '_modalsearch_',
    Language = '_InLanguage_'
}

