import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { environment } from 'src/environments/environment';
import { InStorageService } from './in-storage.service';
import { StatisticsService } from './statistics.service';
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { OnlineService } from './online.service';
import { ValidationService } from './validation.service';
import { IconMap } from '../helpers/iconMap';
import { OldUser, User, NewReg, IonColor, Fields, FirebaseEvent } from '../models/models';
import { UtilityService } from './utility.service';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from './device.service';

enum IconType {
  android, iOS, generic
}

/**
 * Service that fixes things that have changed in different frameworks/libraries
 */
@Injectable({
  providedIn: 'root'
})
export class LegacyService {
  ionIcons: string[] = [];
  httpEnd: HttpClient;

  constructor(
    private httpBackend: HttpBackend,
    private statistics: StatisticsService,
    private storage: InStorageService,
    private logger: NGXLogger,
    private online: OnlineService,
    private validation: ValidationService,
    private util: UtilityService,
    private nativeStorage: NativeStorage,
    private device: DeviceService,
    private translate: TranslateService
  ) {
    // Get list of legal ionicons to check against
    this.httpEnd = new HttpClient(httpBackend); // Avoid interceptor endless watch-loop.
    this.getIonIcons().subscribe(icons => this.ionIcons = icons);
  }

  /**
   * Get old user from native storage and store
   */
  async getAndStoreLocalUser(): Promise<void> {
    if (!this.device.isDevice()) {
      return;
    }
    let oldUser: OldUser;
    try {
      oldUser = await this.nativeStorage.getItem('user');
    }
    catch {
      oldUser = null;
    }
    this.nativeStorage.remove('user');
    if (oldUser?.authenticated) {
      const existingUser = await this.storage.getUser();
      if (!existingUser) {
        const newUser: User = {
          tenant: oldUser.TenancyName,
          username: oldUser.UsernameOrEmailAddress,
          password: oldUser.Password
        };
        await this.storage.setUser(newUser);
      }
    }
  }

  /**
   * Get old offline registrations from native storage
   */
  async readOfflineRegs(): Promise<NewReg[]> {
    if (!this.device.isDevice()) {
      return [];
    }
    let regs: NewReg[];
    try {
      regs = await this.nativeStorage.getItem('outQueu');
    }
    catch {
      regs = [];
    }
    this.nativeStorage.remove('outQueu');
    if (regs?.length > 0) {
      return regs.filter(r => r.IsSigned);
    }
    else {
      return [];
    }
  }

  /**
   * Fixes changes between formly 1 and formly 5, and ionic 1 and ionic 5
   * @param fields The fields to be fixed. If it is a definition string it will first parse it into fields
   */
  fixFields(fields: FormlyFieldConfig[]): FormlyFieldConfig[] {
    for (const field of fields) {
      const type = (field.type as string)?.toLowerCase();
      if (field['noFormControl'] && field.template.includes('#sign_form{display:none;}')) {
        delete field.template;
        delete field['noFormControl'];
        field.type = 'styleForm';
        field.key = 'legacyStyleform';
        field.templateOptions = {
          hideSignArea: true
        };
      }
      else if (type === Fields.TextArea) {
        this.fixTextarea(field);
      }
      else if (type === Fields.Radio) {
        this.fixRadio(field);
      }
      else if (type === Fields.LabelRadio) {
        field.type = 'radio';
        this.fixRadio(field);
      }
      else if (this.isSelect(field)) {
        this.fixSelect(field);
      }
      else if (type === Fields.LabelSelect) {
        field.type = 'select';
        this.fixSelect(field);
      }
      else if (this.isModalselect(field)) {
        this.fixModalselect(field);
        if (field.type === Fields.ServerQueryModal) {
          this.fixServerQueryModal(field);
        }
      }
      else if (this.isInput(field)) {
        this.fixInput(field);
      }
      else if (this.usesColorType(field)) {
        if (field.templateOptions.colorType) {
          field.templateOptions.colorType = this.fixColorClass(field.templateOptions.colorType);
        }
        if (field.templateOptions.clickedColor) {
          field.templateOptions.clickedColor = this.fixColorClass(field.templateOptions.clickedColor);
        }
      }
      else if (this.usesButtonColor(field)) {
        field.templateOptions.colorType = this.fixColorClass(field.templateOptions.buttonColor);
        delete field.templateOptions.buttonColor;
      }
      else if (field.type === Fields.Datetime) {
        this.fixDatetimeField(field);
      }
      if (typeof field.hideExpression === 'string' && field.hideExpression !== '') {
        field.hideExpression = this.fixHideExpression(field.hideExpression);
      }
      if (field.hide && !field.hideExpression) {
        field.hideExpression = true;
      }
      if (field.fieldGroup) {
        this.fixFields(field.fieldGroup);
      }
    }

    return fields;
  }

  /**
   * Removes 'model.' from the watchGroup keys (not needed in new version)
   * @param keys The watchgroup keys
   */
  fixWatchGroup(keys: string[]): string[] {
    return keys.map(key => this.removeModel(key));
  }

  /**
   * Fixes hideExpression that causes eval-errors. E.g. model.test.include('test') when model.test isn't defined
   *
   * Doesn't do anything if the expression is already fixed
   *
   * `"model.test.includes('test)"` -> `"(model.test && model.test.includes('test')"`
   *
   * `"!model.test.includes('test)"` -> `"(!model.test || !model.test.includes('test')"`
   * @param expression The hide expression to fix
   */
  fixHideExpression(expression: string): string {
    return expression.replace(/(model|!model)\.(\S+\(.+\)|\S+)?/g, (expr: string) => {
      const parts = expr.split('.');
      if (parts.length === 3) {
        const firstPart = `${parts[0]}.${parts[1]}`;
        if (parts[0].startsWith('!')) {
          if (expression.includes(`${firstPart} ||`)) {
            return expr;
          }
          else {
            return `(${firstPart} || ${expr})`;
          }
        }
        else {
          if (expression.includes(`${firstPart} &&`)) {
            return expr;
          }
          else {
            return `(${firstPart} && ${expr})`;
          }
        }
      }
      else {
        return expr;
      }
    });
  }

  /**
   * Fixes the iconClass difference between ionic 1 and 5
   * @param iconClass The icon class to be fixed
   * @param shouldBeOutline (Optional) If the the icon should always be the outline variant, default: true
   * @param defaultIcon (Optional) What icon to use if the icon isn't found, default: The icon set in environment files
   */
  fixIcon(iconClass: string, shouldBeOutline = true, defaultIcon = environment.defaultIcon): string {
    if (!this.checkIcon(defaultIcon)) {
      defaultIcon = environment.defaultIcon;
    }
    if (!iconClass) {
      return defaultIcon;
    }
    if (iconClass.startsWith('ion ')) {
      iconClass = iconClass.replace('ion ', '').trim();
    }
    if (iconClass.startsWith('ion-')) {
      iconClass = iconClass.replace('ion-', '').trim();
    }
    iconClass = iconClass.replace('android', 'md').replace('social', 'logo');

    let iconType: IconType = IconType.generic;
    if (iconClass.startsWith('md-')) {
      iconType = IconType.android;
      iconClass = iconClass.slice(3);
    }
    else if (iconClass.startsWith('ios-')) {
      iconType = IconType.iOS;
      iconClass = iconClass.slice(4);
    }

    if (!this.checkIcon(iconClass)) {
      iconClass = this.switchIconName(iconClass, iconType, defaultIcon);
    }

    if (iconClass.endsWith('-sharp')) {
      iconClass = iconClass.slice(0, -6);
    }
    if (!iconClass.endsWith('-outline') && !iconClass.startsWith('logo-') && shouldBeOutline) {
      iconClass += '-outline';
    }

    return iconClass;
  }



  /**
   * Fixes that colorclasses have changed name from ionic 1 to ionic 5
   * @param color The color class to fix
   */
  fixColorClass(color: string): string {
    if (color && color.includes('-')) {
      color = color.split('-')[1];
    }
    if (this.validation.validColor(color)) {
      return color;
    }
    switch (color) {
      case 'stable':
        return IonColor.Medium;
      case 'positive':
        return IonColor.Tertiary;
      case 'calm':
        return IonColor.Primary;
      case 'balanced':
        return IonColor.Success;
      case 'energized':
        return IonColor.Warning;
      case 'assertive':
        return IonColor.Danger;
      case 'royal':
        return IonColor.Secondary;
      default:
        return IonColor.Primary;
    }
  }

  /**
   * Fix old style ionic-1 buttons in markdown
   * @param markdown The markdown to fix
   */
  fixMarkdownButtons(markdown: string) {
    if (!markdown.includes('<button')) {
      return markdown;
    }

    markdown = this.util.parseText( markdown,
                                    `<ion-button expand="block" color="${IonColor.Primary}" style="height: unset;" class="ion-no-margin"`,
                                    null, null,
                                    /(<button type="submit" class="button\b.+\bbutton-positive\b.*?")/g
                                  );
    markdown = this.util.parseText( markdown,
                                    `<ion-button expand="block" color="${IonColor.Secondary}" style="height: unset;" class="ion-no-margin"`,
                                    null, null,
                                    /(<button type="submit" class="button\b.+\bbutton-calm\b.*?")/g
                                  );
    markdown = this.util.parseText( markdown,
                                    `<ion-button expand="block" color="${IonColor.Tertiary}" style="height: unset;" class="ion-no-margin"`,
                                    null, null,
                                    /(<button type="submit" class="button\b.+\bbutton-royal\b.*?")/g
                                  );
    markdown = this.util.parseText( markdown,
                                    `<ion-button expand="block" color="${IonColor.Success}" style="height: unset;" class="ion-no-margin"`,
                                    null, null,
                                    /(<button type="submit" class="button\b.+\bbutton-balanced\b.*?")/g
                                  );
    markdown = this.util.parseText( markdown,
                                    `<ion-button expand="block" color="${IonColor.Warning}" style="height: unset;" class="ion-no-margin"`,
                                    null, null,
                                    /(<button type="submit" class="button\b.+\bbutton-energized\b.*?")/g
                                  );
    markdown = this.util.parseText( markdown,
                                    `<ion-button expand="block" color="${IonColor.Danger}" style="height: unset;" class="ion-no-margin"`,
                                    null, null,
                                    /(<button type="submit" class="button\b.+\bbutton-assertive\b.*?")/g
                                  );
    markdown = this.util.parseText( markdown,
                                    `<ion-button expand="block" color="${IonColor.Dark}" style="height: unset;" class="ion-no-margin"`,
                                    null, null,
                                    /(<button type="submit" class="button\b.+\bbutton-dark\b.*?")/g
                                  );
    markdown = this.util.parseText( markdown,
                                    `<ion-button expand="block" color="${IonColor.Medium}" style="height: unset;" class="ion-no-margin"`,
                                    null, null,
                                    /(<button type="submit" class="button\b.+\bbutton-stable\b.*?")/g
                                  );
    markdown = this.util.parseText( markdown,
                                    `<ion-button expand="block" color="${IonColor.Light}" style="height: unset;" class="ion-no-margin"`,
                                    null, null,
                                    /(<button type="submit" class="button\b.+\bbutton-light\b.*?")/g
                                  );
    markdown = this.util.parseText( markdown,
                                    `<ion-button expand="block" style="height: unset;" class="ion-no-margin"`,
                                    null, null,
                                    /(<button type="submit" class="button\b.*?")/g
                                  );
    markdown = this.util.parseText(markdown, '</ion-button>', null, null, /(<\/button>)/g);
    return markdown;
  }

  /**
   * Checks if an icon is an legal ionicon
   * @param icon The icon to check
   */
  private checkIcon(icon: string): boolean {
    return (this.ionIcons.length === 0) ? true : this.ionIcons.includes(icon);
  }

  /**
   * Translates from old icon names to new icon names
   * @param icon The icon name
   * @param iconType If it's an ios, android or generic icon
   * @param defaultIcon What icon to use as default
   */
  private switchIconName(icon: string, iconType: IconType, defaultIcon: string) {
    switch (icon) {
      case 'arrow-dropdown-circle':
      case 'arrow-dropdown':
        return iconType === IconType.iOS ? 'chevron-down-circle' : 'caret-down-circle';
      case 'arrow-dropleft-circle':
      case 'arrow-dropleft':
        return iconType === IconType.iOS ? 'chevron-back-circle' : 'caret-back-circle';
      case 'arrow-dropright-circle':
      case 'arrow-dropright':
        return iconType === IconType.iOS ? 'chevron-forward-circle' : 'caret-forward-circle';
      case 'arrow-dropup-circle':
      case 'arrow-dropup':
        return iconType === IconType.iOS ? 'chevron-up-circle' : 'caret-up-circle';
      case 'more':
        return iconType === IconType.iOS ? 'ellipsis-horizontal' : 'ellipsis-vertical';
    }

    const newIcon = IconMap[icon];
    if (newIcon) {
      return newIcon;
    }
    else {
      this.statistics.logEvent(FirebaseEvent.WrongIcon, {iconName: icon});
      return defaultIcon;
    }
  }

  /**
   * Changes property name
   * @param list List of values
   * @param fromProp Old property name
   * @param toProp New property name
   */
  private switchPropNames(list: any[], fromProp: string, toProp: string): any[] {
    if (!list || list.length === 0) {
      return list;
    }
    for (const el of list) {
      if (typeof el[fromProp] !== 'undefined') {
        el[toProp] = el[fromProp];
        delete el[fromProp];
      }
    }
    return list;
  }

  /**
   * Sets default label position of textarea to stacked
   * @param field The textarea field to fix
   */
  private fixTextarea(field: FormlyFieldConfig) {
    if (!field.templateOptions) {
      return;
    }

    if ( typeof field.templateOptions.label === 'string'
      && field.templateOptions.label.match(/^textareafield\d+(Copy)*$/)
      && !field.templateOptions.noLabelCorrect
    ) {
      field.templateOptions.label = '';
    }

    if (!field.templateOptions?.labelPosition) {
      field.templateOptions.labelPosition = field.templateOptions.label ? 'stacked' : '';
    }
    else if (field.templateOptions.labelPosition === 'inline') {
      field.templateOptions.labelPosition = '';
    }
  }

  /**
   * Fixes that property names have changed between formly 1 and 5
   * @param field The radio field to fix
   */
  private fixRadio(field: FormlyFieldConfig) {
    const regex = /\brow\b/;
    if (field.className?.match(regex)) {
      field.className = field.className.replace(regex, '');
      field.type = Fields.SegmentButtons;
    }
    if (field.templateOptions) {
      field.templateOptions.options = this.switchPropNames(field.templateOptions.options as any[], 'text', 'label');
      if ( typeof field.templateOptions.label === 'string'
        && field.templateOptions.label.match(/^radiofield\d+(Copy)*$/)
        && !field.templateOptions.noLabelCorrect
      ) {
        field.templateOptions.label = '';
      }
    }
  }

  /**
   * Fixes that property names have changed between formly 1 and 5, and set interface to 'action-sheet'
   * @param field The select field to fix
   */
  private fixSelect(field: FormlyFieldConfig) {
    if (field.templateOptions) {
      field.templateOptions.options = this.switchPropNames(field.templateOptions.options as any[], 'name', 'label');
      field.templateOptions.cancelText ||= this.translate.instant('Cancel');
      field.templateOptions.okText ||= 'OK';
      if (field.templateOptions.multiple) {
        field.templateOptions.interface = 'alert';
      }
      else if (!field.templateOptions.interface) {
        field.templateOptions.interface = 'popover';
      }
    }
  }

  /**
   * Fixes that property names have changed between formly 1 and 5
   * @param field The modalselect field to fix
   */
  private fixModalselect(field: FormlyFieldConfig) {
    if (field.templateOptions) {
      field.templateOptions.options = this.switchPropNames(field.templateOptions.options as any[], 'name', 'label');
    }
  }

  /**
   * Fixes that input has changed between formly 1 and 5
   * @param field The input field to fix
   */
  private fixInput(field: FormlyFieldConfig) {
    if (!field.templateOptions) return;

    switch (field.type) {
      case 'inline-input':
        field.type = 'input';
        break;
      case 'stacked-input':
        field.type = 'input';
        field.templateOptions.labelPosition = 'stacked';
        break;
      case 'floating-input':
        field.type = 'input';
        field.templateOptions.labelPosition = 'floating';
        break;
      case 'init-input':
        if (field.templateOptions.formId) {
          field.templateOptions.form = 'id';
          delete field.templateOptions.formId;
        }
        break;
    }
    if (field.templateOptions.type === 'user') {
      field.type = 'user';
      field.templateOptions.type = 'username';
    }
  }

  /**
   * Fix that serverQueryModal should have serverSearch=true
   * @param field The field to fix
   */
  private fixServerQueryModal(field: FormlyFieldConfig) {
    if (field.templateOptions && typeof field.templateOptions.serverSearch !== 'boolean') {
      field.templateOptions.serverSearch = true;
      if (!field.templateOptions.serverSearchName)
        field.templateOptions.serverSearchName = 'query';
    }
  }

  /**
   * Set translated cancel/ok text of datetime field if not set
   * @param field The field to fix
   */
  private fixDatetimeField(field: FormlyFieldConfig) {
    if (!field.templateOptions) {
      field.templateOptions = {};
    }
    field.templateOptions.cancelText ||= this.translate.instant('Cancel');
    field.templateOptions.doneText ||= 'OK';
  }

  /**
   * Removes 'model.'
   * @param key Key to fix
   */
  private removeModel(key: string) {
    if (key.startsWith('model.'))
      key = key.split('.')[1];

    return key;
  }

  /**
   * Checks if the field is an input type field
   * @param field The field to check
   */
  private isInput(field: FormlyFieldConfig): boolean {
    switch (field.type) {
      case 'input':
      case 'inline-input':
      case 'stacked-input':
      case 'floating-input':
      case 'init-input':
        return true;
      default:
        return false;
    }
  }

  /**
   * Checks if the field is an select type field
   * @param field The field to check
   */
  private isSelect(field: FormlyFieldConfig): boolean {
    switch (field.type) {
      case Fields.Select:
      case Fields.HistorySelect:
      case Fields.SubSelect:
        return true;
      default:
        return false;
    }
  }

  /**
   * Checks if the field is of modalselect type
   * @param field The field to check
   */
  private isModalselect(field: FormlyFieldConfig): boolean {
    switch (field.type) {
      case Fields.Modalselect:
      case Fields.Lookup:
      case Fields.WebLookup:
      case Fields.ServerQueryModal:
        return true;
      default:
        return false;
    }
  }

  /**
   * Checks if the field uses to.buttonColor
   * @param field The field to check
   */
  private usesButtonColor(field: FormlyFieldConfig): boolean {
    switch (field.type) {
      case Fields.ValueList:
      case Fields.ItemList:
      case Fields.PdfModal:
      case Fields.ImageModal:
        return !!field.templateOptions?.buttonColor;
      default:
        return false;
    }
  }

  /**
   * Checks if an field uses to.colorType
   * @param field The field to check
   */
  private usesColorType(field: FormlyFieldConfig): boolean {
    switch (field.type) {
      case Fields.Button:
      case Fields.TimePicker:
      case Fields.OpenForm:
        return !!field.templateOptions;
      case Fields.ValueList:
      case Fields.ItemList:
      case Fields.PdfModal:
      case Fields.ImageModal:
        return !!field.templateOptions?.colorType;
      default:
        return false;
    }
  }

  /**
   * Get list of valid IonIcons from IonIcon web page
   */
  private getIonIcons(): Observable<string[]> {
    if (this.online.isOnline()) {
      return this.httpEnd.get('https://infeaturescode.azurewebsites.net/api/getvalidicons?code=ESRu17a13daiiwvIvhFFnLuyKhDbdaujr4ts05%2FAEZ0W1ui3gkSKjA%3D%3D').pipe(
        map((icons: string[]) => {
          if (Array.isArray(icons)) {
            this.storage.setIonIcons(icons);
            return icons;
          }
          else {
            return [];
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.logger.error('Error getting ion icons', err);
          return of([]);
        })
      );
    }
    else {
      return from(this.storage.getIonIcons());
    }
  }
}
