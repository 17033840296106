import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Fields, FormlyPage, FormType, PageRefs, TriggerType } from '../models/models';
import { ComponentRefService } from './component-ref.service';
import { PopupService } from './popup.service';
import { StateService } from './state.service';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root'
})
export class SetVarsService {
  private _formStack: {type: FormType, id: number, closeOverlays: boolean}[] = [];
  private model: any;
  private formGroup: UntypedFormGroup;

  constructor(
    private modalCtrl: ModalController,
    private stateService: StateService,
    private timeService: TimeService,
    private logger: NGXLogger,
    private ref: ComponentRefService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private popup: PopupService
  ) {
    const that = this;
    window['setVars'] = (obj: any, buttonKey?: string, confirmTitle?: string, confirmText?: string, inputKey?: string) => {
      that.setVars(obj, buttonKey, confirmTitle, confirmText, inputKey);
    };
  }

  get currentForm(): {type: FormType, id: number, closeOverlays: boolean} {
    if (this._formStack.length === 0) {
      return null;
    }
    else {
      return this._formStack[this._formStack.length - 1];
    }
  }

  get shouldCloseOverlays(): boolean {
    return this.currentForm?.closeOverlays ?? false;
  }

  set shouldCloseOverlays(value: boolean) {
    if (this.currentForm) {
      this.currentForm.closeOverlays = value;
    }
  }

  pushForm(type: FormType, id: number) {
    if (!this.currentForm || this.currentForm.type !== type || this.currentForm.id !== id) {
      this._formStack.push({type, id, closeOverlays: false});
    }
  }

  popForm() {
    this._formStack.pop();
  }

  async setVars(obj: any, buttonKey?: string, confirmTitle?: string, confirmText?: string, inputKey?: string) {
    if (!this.currentForm) {
      this.logger.error('No form set');
      return;
    }
    const ref = this.getPageRef(this.currentForm.type);
    if (!ref) {
      this.logger.warn(`Not a valid form type: ${this.currentForm.type}`);
      return;
    }
    const page: FormlyPage = this.ref.getReference(ref, this.currentForm.id, this.currentForm.type)[0];
    if (!page) {
      this.logger.error(`'Didn't find page: ${this.currentForm.type}-${this.currentForm.id}`);
      return;
    }
    this.model = page.model;
    this.formGroup = page.formGroup;
    if (!this.model || !this.formGroup) {
      this.logger.error(`No model and/or formgroup: ${this.currentForm.type}-${this.currentForm.id}`);
      return;
    }

    const openModal = await this.modalCtrl.getTop();
    if(openModal && ((openModal.component as any)?.name === 'TextModalComponent')) {
        await openModal.dismiss();
    }

    const vars: any = {};
    const rebuildKeys: string[] = [];
    for (const [key, value] of Object.entries(obj)) {
      let val: any;
      const field = this.stateService.findField(key, this.currentForm.type, this.currentForm.id);
      if (this.timeService.isTimeOrDate(field) || field?.type === Fields.TimePicker) {
        await this.ref.callReference(key, this.currentForm.id, this.currentForm.type, TriggerType.StopUpdate);
      }
      if (typeof value === 'string' && value.startsWith('new Date(')) {
        const str = value;
        this.logger.debug(str);
        let dateformat = str.substr(10);
        dateformat = dateformat.slice(0, -2);
        this.logger.debug(dateformat);
        val = this.timeService.formatDatetime(dateformat, 'date');
      }
      else if (typeof value === 'string' && this.timeService.isTimeOrDate(field)) {
        const type = this.timeService.getTimeType(field);
        val = this.timeService.formatDatetime(value, type);
      }
      else {
        val = value;
      }

      this.logger.debug(`Will set ${key}: ${val}`);
      this.model[key] = val;
      vars[key] = val;
      if (this.needsRebuild(field)) {
        rebuildKeys.push(key);
      }
    }
    this.formGroup.patchValue(vars);

    // Click button.
    if (buttonKey && this.currentForm) {
      const button = this.stateService.findField(buttonKey, this.currentForm.type, this.currentForm.id);
      if (button) {
        if (confirmTitle) {
          if (inputKey) {
            await this.getButtonInput(confirmTitle, confirmText, inputKey, buttonKey);
          }
          else {
            const confirmed = await this.popup.showConfirm(confirmTitle, confirmText, false);
            if (confirmed) {
              this.logger.debug(`Will click on ${buttonKey}`);
              if (this.shouldCloseOverlays) {
                await this.popup.closeOpenOverlays(false);
              }
              await this.ref.callReference(buttonKey, this.currentForm.id, this.currentForm.type, TriggerType.Click);
            }
          }
        }
        else {
          this.logger.debug(`Will click on ${buttonKey}`);
          if (this.shouldCloseOverlays) {
            await this.popup.closeOpenOverlays(false);
          }
          await this.ref.callReference(buttonKey, this.currentForm.id, this.currentForm.type, TriggerType.Click);
        }
      }
    }

    if (rebuildKeys.length > 0) {
      for (const key of rebuildKeys) {
        this.ref.callReference(key, this.currentForm.id, this.currentForm.type, TriggerType.Rebuild);
      }
    }
  }

  private async getButtonInput(confirmTitle: string, confirmText: string, inputKey: string, buttonKey: string) {
    let confirmed = false;
    const trans = this.translate.instant(['Cancel', 'Confirm']);
    const alert = await this.alertCtrl.create({
      header: confirmTitle,
      message: confirmText,
      inputs: [
        {
          name: inputKey,
          type: 'text'
        }
      ],
      buttons: [
        {
          text: trans['Cancel'],
          role: 'cancel'
        },
        {
          text: trans['Confirm'],
          handler: async (data) => {
            confirmed = true;
            this.logger.debug(`Will set ${inputKey}: ${data[inputKey]} and click on button ${buttonKey}`);
            this.model[inputKey] = data[inputKey];
            await this.ref.callReference(buttonKey, this.currentForm.id, this.currentForm.type, TriggerType.Click);
          }
        }
      ]
    });
    await alert.present();
    await alert.onDidDismiss();
    if (confirmed && this.shouldCloseOverlays) {
      await this.popup.closeOpenOverlays(false);
    }
  }

  private needsRebuild(field: FormlyFieldConfig) {
    switch (field?.type) {
      case Fields.Barcode:
      case Fields.ItemList:
      case Fields.ValueList:
      case Fields.Photo:
        return true;
      default:
        return false;
    }
  }

  private getPageRef(type: FormType) {
    switch(type) {
      case FormType.Form:
        return PageRefs.Form;
      case FormType.FormView:
        return PageRefs.FormView;
      case FormType.Setup:
        return PageRefs.Setup;
      default:
        return null;
    }
  }
}
