import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { PopoverController, Platform } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { PaintSelectComponent } from 'src/app/custom-components/directives/formly-directives/paint-select/paint-select.component';
import { UtilityService } from 'src/app/services/utility.service';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { fromEvent, Subscription } from 'rxjs';
import { map, switchMap, takeUntil, pairwise } from 'rxjs/operators';
import { FormType, TriggerType } from 'src/app/models/models';
import * as pdfjsLib from 'pdfjs-dist';
import { DeviceService } from 'src/app/services/device.service';
import { StateService } from 'src/app/services/state.service';

// Set like this to be enable to mock pdfjs in tests
export const drawSelectDeps = {
  pdfjsLib
};

@Component({
  selector: 'app-field-drawing-select',
  templateUrl: './drawing-select.component.html',
  styleUrls: ['./drawing-select.component.scss'],
  providers: [WatchgroupService]
})
export class DrawingSelectComponent extends FieldType implements AfterViewInit, OnDestroy {
  @ViewChild('canvas') canvas: ElementRef;
  canvasEl: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  loading = false;
  hasError = false;
  pageNumber = '0';
  image: HTMLImageElement | HTMLCanvasElement;
  orgSize: {width: number, height: number};
  screenSize: {width: number, height: number};
  pdfPage: any;
  scrollSpeed: number;
  scale: number;
  xDiff: number;
  yDiff: number;
  eventCache: PointerEvent[] = [];
  prevDiff: number; // used by pinch zoom
  resizeSubscription: Subscription;

  constructor(
    private popCtrl: PopoverController,
    private logger: NGXLogger,
    private ref: ComponentRefService,
    private util: UtilityService,
    private watch: WatchgroupService,
    private plt: Platform,
    private device: DeviceService,
    private state: StateService
  ) {
    super();
    drawSelectDeps.pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdfjs/pdf.worker.min.js';
  }

  get label(): string {
    return this.to.label || '';
  }

  get url(): string {
    return this.to.url || '';
  }

  get paintFields(): {name: string, key: string}[] {
    return Array.isArray(this.to.paintFields) ? this.to.paintFields : [];
  }

  get allowPinch(): boolean {
    return this.to.allowPinch || false;
  }

  get pdf(): boolean {
    return this.to.pdf || false;
  }

  get pages(): {name: string, number: string}[] {
    return Array.isArray(this.to.pages) ? this.to.pages : [];
  }

  get quality(): number {
    return this.to.quality || 1.0;
  }

  get useCredentials(): boolean {
    return this.to.useCredentials || false;
  }

  get paddingOffset(): number {
    if (this.to.currentForm.type === FormType.FormView) {
      return 80;
    }
    else {
      return 130;
    }
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  ngAfterViewInit() {
    if (this.pages.length === 0) {
      this.pageNumber = this.to.pageNumber || '1';
    }
    this.canvasEl = this.canvas.nativeElement;
    this.ctx = this.canvasEl.getContext('2d');
    const scale = 1;
    const form = document.querySelectorAll('form');

    const contentBound = form[form.length - 1].getBoundingClientRect();
    this.orgSize = {
      width: Math.round((contentBound.width - this.paddingOffset) * scale),
      height: Math.round(window.innerHeight * 0.7)
    };
    this.screenSize = {
      width: this.orgSize.width,
      height: window.innerHeight
    };
    this.setCanvas(this.orgSize.width, 200);
    if (this.device.isMobile()) {
      this.setTouchEvents();
    }
    else {
      this.setMouseEvents();
    }
    if (this.url) {
      this.init();
      const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
      if (watchGroup.fieldKeys.length >0) {
        this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => this.init());
      }
    }
    this.resizeSubscription = this.plt.resize.subscribe(() => this.resizeCanvas());
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  resizeCanvas() {
    const scale = 1;
    const form = document.querySelectorAll('form');

    const contentBound = form[form.length - 1].getBoundingClientRect();
    this.orgSize = {
      width: Math.round((contentBound.width - this.paddingOffset) * scale),
      height: Math.round(window.innerHeight * 0.7)
    };
    this.screenSize = {
      width: this.orgSize.width,
      height: window.innerHeight
    };
    if (this.image) {
      this.drawImage(this.image);
    }
  }

  reset() {
    this.scale = 1;
    this.xDiff = 0;
    this.yDiff = 0;

    this.drawImage(this.image);
  }

  zoomIn(zoom = 0.1, pinch = false) {
    if (pinch && !this.allowPinch) return;

    this.scale += zoom * this.scale;
    this.drawImage(this.image);
  }

  zoomOut(zoom = 0.1, pinch = false) {
    if (pinch && !this.allowPinch) return;

    this.scale -= zoom * this.scale;
    if (this.scale < 1) {
      this.scale = 1;
    }
    this.drawImage(this.image);
  }

  changePage() {
    const url = this.util.parseText(this.url, this.model, this.fields);
    setTimeout(() => this.loading = true);
    this.loadPdf(url);
  }

  setMouseEvents() {
    fromEvent(this.canvasEl, 'mousedown').pipe(
      map(this.preventMove),
      switchMap(e => fromEvent(this.canvasEl, 'mousemove').pipe(
          map(this.preventMove),
          takeUntil(fromEvent(this.canvasEl, 'mouseup').pipe(map(this.preventMove))),
          takeUntil(fromEvent(this.canvasEl, 'mouseleave').pipe(map(this.preventMove))),
          pairwise()
        ))
    ).subscribe((events: [MouseEvent, MouseEvent]) => {
      const curPos = {
        x: events[1].clientX,
        y: events[1].clientY
      };
      const prevPos = {
        x: events[0].clientX,
        y: events[0].clientY
      };
      this.xDiff -= this.scrollSpeed * (curPos.x - prevPos.x) / this.scale;
      this.yDiff -= this.scrollSpeed * (curPos.y - prevPos.y) / this.scale;

      this.drawImage(this.image);
    });

    fromEvent(this.canvasEl, 'wheel').subscribe((event: WheelEvent) => {
      if (!event.ctrlKey && !event.altKey && !event.shiftKey) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();

      if (event.deltaY < 0)
        this.zoomIn();
      else
        this.zoomOut();
    });
  }

  setTouchEvents() {
    fromEvent(this.canvasEl, 'touchstart').pipe(
      map(this.preventMove),
      switchMap(e => fromEvent(this.canvasEl, 'touchmove').pipe(
          map(this.preventMove),
          takeUntil(fromEvent(this.canvasEl, 'touchcancel').pipe(map(this.preventMove))),
          takeUntil(fromEvent(this.canvasEl, 'touchend').pipe(map(this.preventMove))),
          pairwise()
        ))
    ).subscribe((events: [TouchEvent, TouchEvent]) => {
      if (events[0].touches.length > 1 || events[1].touches.length > 1) {
        return;
      }
      const curPos = {
        x: events[1].touches[0].clientX,
        y: events[1].touches[0].clientY
      };
      const prevPos = {
        x: events[0].touches[0].clientX,
        y: events[0].touches[0].clientY
      };
      this.xDiff -= this.scrollSpeed * (curPos.x - prevPos.x) / this.scale;
      this.yDiff -= this.scrollSpeed * (curPos.y - prevPos.y) / this.scale;

      this.drawImage(this.image);
    });
  }

  /**Prevent touch/mouse from scrolling */
  preventMove(event: Event): Event {
    event.preventDefault();
    return event;
  }

  init() {
    this.scale = 1;
    this.xDiff = 0;
    this.yDiff = 0;
    this.hasError = false;
    const url = this.util.parseText(this.url, this.model, this.fields);
    if (!this.util.textIsParsed) {
      return;
    }
    if (this.pdf) {
      this.loadPdf(url);
    }
    else {
      setTimeout(() => this.loading = true);
      const image = new Image();
      image.onload = () => {
        setTimeout(() => this.loading = false);
        this.image = image;
        this.drawImage(image);
      };
      image.onerror = () => {
        setTimeout(() => {
          this.loading = false;
          this.hasError = true;
        });
      };
      image.setAttribute('crossorigin', 'anonymous');
      image.src = url;
    }
  }

  async loadPdf(url: string) {
    setTimeout(() => {
      this.loading = true;
    });
    const pageNr = parseInt(this.pageNumber, 10);

    if (isNaN(pageNr) || pageNr === 0) {
      setTimeout( () => {
        this.loading = false;
      });
      return;
    }
    try {
      const pdf = await drawSelectDeps.pdfjsLib.getDocument({url: url, withCredentials: this.useCredentials}).promise;
      const page = await pdf.getPage(pageNr);
      const viewport = page.getViewport({ scale: this.quality});

      const tmpCanvas = document.createElement('canvas');
      tmpCanvas.height = viewport.height;
      tmpCanvas.width = viewport.width;
      const ctx = tmpCanvas.getContext('2d');
      const renderCtx = {
        canvasContext: ctx,
        viewport: viewport
      };
      await page.render(renderCtx).promise;
      setTimeout( () => {
        this.loading = false;
      });
      this.image = tmpCanvas;
      this.drawImage(tmpCanvas);
    }
    catch (err) {
      this.logger.error(`Error getting pdf:`, err);
      setTimeout(() => {
        this.loading = false;
        this.hasError = true;
      });
    }
  }

  drawImage(image: HTMLImageElement | HTMLCanvasElement) {
    if (!image) {
      return;
    }
    let size = 1;
    size = image.width / this.screenSize.width;

    this.scrollSpeed = size;

    const {nx, ny} = this.findCanvasSize(image.width, image.height, size);

    this.setCanvas(nx, ny);
    const ctx = this.canvasEl.getContext('2d');

    const {nx: newNx, ny: newNy, sx, sy, dx, dy, sWidth, sHeight} = this.calcCoordinates(image.width, image.height, nx, ny, size);

    ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, newNx, newNy);
  }

  /**
   * Find how large the canvas should be
   * @param imageWidth The width of the image to draw
   * @param imageHeight The height of the image to draw
   * @param size The ratio between image and screen
   */
  findCanvasSize(imageWidth: number, imageHeight: number, size: number) {
    let nx = imageWidth / size;
    let ny = imageHeight / size;

    if (nx > this.screenSize.width) {
      const tmpsize = nx - this.screenSize.width;
      nx = nx - tmpsize;
      ny = ny - tmpsize;
    }
    if (ny > this.screenSize.height * 2) {
      const tmpsize = ny - 2 * this.screenSize.height;
      nx = nx - tmpsize;
      ny = ny - tmpsize;
    }
    return {nx, ny};
  }

  /**
   * Calculate the coordinate transformation for drawing the image correctly on to the canvas
   * @param imageWidth The width of the image to draw
   * @param imageHeight The height of the image to draw
   * @param nx The original canvas width
   * @param ny The original canvas height
   * @param size The ratio between image and screen
   */
  calcCoordinates(imageWidth: number, imageHeight: number, nx: number, ny: number, size: number) {
    let sWidth = imageWidth / this.scale;
    let sHeight = imageHeight / this.scale;
    let sx = (imageWidth - sWidth) / 2 + this.xDiff;
    let sy = (imageHeight - sHeight) / 2 + this.yDiff;
    let dx = 0;
    let dy = 0;

    // Safari/iOS can't handle negative sx/sy values
    if (sx < 0) {
      sWidth += sx;
      dx -= sx * this.scale / size;
      nx += sx * this.scale / size;
      sx = 0;
    }
    else if (sx + sWidth > imageWidth) {
      const diff = (sx + sWidth) - imageWidth;
      sWidth = imageWidth - sx;
      dx = 0;
      nx -= diff * this.scale / size;
    }
    if (sy < 0) {
      sHeight += sy;
      dy -= sy * this.scale / size;
      ny += sy * this.scale / size;
      sy = 0;
    }
    else if (sy + sHeight > imageHeight) {
      const diff = (sy + sHeight) - imageHeight;
      sHeight = imageHeight - sy;
      dy = 0;
      ny -= diff * this.scale / size;
    }
    return {nx, ny, sx, sy, dx, dy, sWidth, sHeight};
  }

  setCanvas(width: number, height: number) {
    this.canvasEl.width = width;
    this.canvasEl.height = height;
  }

  async setPaint() {
    if (this.paintFields.length === 1) {
      const imageData = this.canvasEl.toDataURL();
      this.ref.callReference(this.paintFields[0].key, this.formId, this.formType, TriggerType.Set, imageData);
      return;
    }
    const popover = await this.popCtrl.create({
      component: PaintSelectComponent,
      componentProps: {
        paintfields: this.paintFields
      }
    });
    await popover.present();
    const {data} = await popover.onWillDismiss();
    if (data && data.selectedPaint) {
      this.logger.debug(data);
      const imageData = this.canvasEl.toDataURL();
      this.ref.callReference(data.selectedPaint, this.formId, this.formType, TriggerType.Set, imageData);
    }
  }

}
