<ion-list>
  <ion-item lines="none" *ngIf="label">
    <ion-label>{{label}}</ion-label>
  </ion-item>
  <ion-segment [mode]="mode" [color]="color" [scrollable]="scrollable" [formControl]="$any(formControl)">
    <ion-segment-button *ngFor="let option of segmentOptions" [value]="option.value">
      <ion-icon *ngIf="option.icon" [name]="option.icon"></ion-icon>
      <ion-label *ngIf="option.label">{{option.label}}</ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-list>
