import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Fields } from 'src/app/models/models';
import { TimeService } from 'src/app/services/time.service';

@Component({
  selector: 'app-wrapper-label',
  templateUrl: './label-wrapper.component.html',
  styleUrls: ['./label-wrapper.component.scss'],
})
export class LabelWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;
  isNormalInput: boolean;

  constructor(private time: TimeService) {
    super();
  }

  get labelPosition(): string {
    return this.to.labelPosition ?? '';
  }

  get hideRequiredMarker(): boolean {
    return this.to.hideRequiredMarker ?? false;
  }

  /**Select with popover should have ios mode to look like project select */
  get mode(): '' | 'ios' | 'md' {
    if (this.isSelect) {
      switch (this.to.mode) {
        case 'md':
        case 'android':
          return 'md';
        case 'ios':
          return 'ios';
        default:
          return this.to.interface === 'popover' ? 'ios' : '';
      }
    }
    else if (this.field.type === Fields.Input) {
      return 'ios';
    }
    else {
      return '';
    }
  }

  get isSelect(): boolean {
    switch (this.field.type) {
      case Fields.Select:
      case Fields.SubSelect:
      case Fields.HistorySelect:
        return true;
      default:
        return false;
    }
  }

  ngOnInit() {
    this.isNormalInput = !this.time.isTimeOrDate(this.field);
  }
}
