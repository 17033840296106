import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { PopupService } from 'src/app/services/popup.service';
import { NGXLogger } from 'ngx-logger';
import { ApiStatus } from 'src/app/models/models';
import { DeviceService } from 'src/app/services/device.service';
import { CameraService } from 'src/app/services/camera.service';
import { BrowserService } from 'src/app/services/browser.service';

@Component({
  selector: 'app-change-picture',
  templateUrl: './change-picture.component.html',
  styleUrls: ['./change-picture.component.scss'],
})
export class ChangePictureComponent {
  imagePath: string;
  image: Blob;
  isLoading = false;

  constructor(
    private popCtrl: PopoverController,
    private cameraService: CameraService,
    private api: ApiService,
    private popup: PopupService,
    private logger: NGXLogger,
    private device: DeviceService,
    private browser: BrowserService
  ) { }

  get isDevice(): boolean {
    return this.device.isDevice();
  }

  close(save: boolean) {
    if (save && this.imagePath) {
      this.uploadAndClose();
    }

    else {
      this.dismiss();
    }
  }

  getFromCamera() {
    this.takePhoto(true);
  }

  getFromGallery() {
    this.takePhoto(false);
  }

  setImage(event: any) {
    this.image = event.target.files[0];
    this.imagePath = this.browser.createImageUrl(this.image);
  }

  private async takePhoto(useCamera: boolean) {
    const {image, url} = useCamera ? await this.cameraService.getFromCamera(true)
                                   : await this.cameraService.getFromGallery();
    if (image) {
      this.image = image;
      this.imagePath = url;
    }
    else {
      this.imagePath = '';
      this.image = null;
    }
  }


  private async uploadAndClose() {
    this.isLoading = true;
    this.api.changeProfilePicture(this.image).subscribe(async result => {
      this.isLoading = false;
      if (result.status !== ApiStatus.Success) {
        this.logger.error('Error changing picture in api', result.error);
        const msg = result.error ? result.error.message : '';
        await this.popup.showAlert('PictureChangeFailed', msg, true);
        this.close(false);
      }
      else {
        await this.popup.showAlert('PictureChangeSuccess', '', true);
        this.dismiss({
          changed: true
        });
      }
    });
  }

  private dismiss(data?: {changed: true}) {
    this.popCtrl.dismiss(data).then(
      () => this.logger.debug('Successfully dismissed change picture popover'),
      (err) => this.logger.warn('Failed dismissing change picture popover', err)
    );
  }
}
