import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormType, PlatformType, TriggerType } from '../models/models';
import { SplashScreen } from '@capacitor/splash-screen';
import { ActionSheetController, MenuController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { App } from '@capacitor/app';
import { Subject, Subscription } from 'rxjs';
import { ComponentRefService } from './component-ref.service';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private backButton = new Subject<void>();
  private backSub: Subscription;
  private _openScanner: {key: string, formType: FormType, formId: number};
  constructor(
    private plt: Platform,
    private modalCtrl: ModalController,
    private popCtrl: PopoverController,
    private actionCtrl: ActionSheetController,
    private menuCtrl: MenuController,
    private logger: NGXLogger,
    private ref: ComponentRefService,
    private popup: PopupService
  ) {
    this.init();
  }

  get openScanner(): {key: string, formType: FormType, formId: number} {
    return this._openScanner;
  }

  /**Link to app in AppStore/Google Play */
  get storeLink(): string {
    if (this.isAndroid()) {
      return environment.googlePlayLink;
    }
    else if (this.isIOS()) {
      return environment.appStoreLink;
    }
    else {
      return null;
    }
  }

  /**
   * Screen width of the device
   */
  get deviceWidth(): number {
    return this.plt.width();
  }

  /**
   * Screen height of the device
   */
  get deviceHeight(): number {
    return this.plt.height();
  }

  /**
   * If it's on an mobile device, e.g. touch events
   */
  isMobile(): boolean {
    return this.plt.is('mobile');
  }

  /**
   * If it's an device with access to native features
   */
  isDevice(): boolean {
    return this.plt.is('cordova');
  }

  /**
   * Checks if the app is on Android, and by default on a native build
   *
   * @param nativeOnly (Optional) If it should also test if it's on native build, default: `true`
   */
  isAndroid(nativeOnly = true) {
    if (nativeOnly) {
      return this.isDevice() && this.plt.is('android');
    }
    else {
      return this.plt.is('android');
    }
  }

  /**
   * Checks if the app is on iOS, and by default on a native build
   *
   * @param nativeOnly (Optional) If it should also test if it's on native build, default: `true`
   */
  isIOS(nativeOnly = true) {
    if (nativeOnly) {
      return this.isDevice() && this.plt.is('ios');
    }
    else {
      return this.plt.is('ios');
    }
  }

  /**
   * Checks if the app is on web, and not on native
   */
  isPWA(): boolean {
    return this.plt.is('pwa');
  }

  /**
   * Get the type of platform it's on
   * @param includePWA (Optional) If it should set `'PWA'` as type instead of `'Browser'`, default `false`
   */
  getType(includePWA: boolean = false): PlatformType {
    if (this.isAndroid()) {
      return PlatformType.Android;
    }
    else if (this.isIOS()) {
      return PlatformType.iOS;
    }
    else if (this.isPWA() && includePWA) {
      return PlatformType.PWA;
    }
    else {
      return PlatformType.Browser;
    }
  }

  /**
   * Hide the splash screen. Will wait an period defined in environment before doing so.
   */
  hideSplashscreen() {
    if (this.isDevice()) {
      setTimeout(() => {
        SplashScreen.hide();
      }, environment.splashScreenWait);
    }
  }

  /**
   * Checks if the device/browser supports input-fields with month type
   */
  supportsMonthInput(): boolean {
    const input = document.createElement('input');
    input.setAttribute('type', 'month');
    return input.type === 'month';
  }

  openedBarcodeScanner(key: string, formType: FormType, formId: number) {
    this._openScanner = {key, formType, formId};
  }

  closedBarcodeScanner() {
    this._openScanner = null;
  }

  subscribeToBack(callback: () => any): Subscription {
    if (this.backSub) {
      this.backSub.unsubscribe();
    }
    const backSub = this.backButton.subscribe(callback);
    this.backSub = backSub;
    return backSub;
  }

  async exitApp(confirmed = false) {
    if (this.isAndroid()) {
      if (!confirmed) {
        confirmed = await this.popup.showConfirm('ExitApp', 'ExitAppMsg', true);
      }
      if (confirmed) {
        App.exitApp();
      }
    }
  }

  private async init() {
    if (this.isAndroid()) {
      this.plt.backButton.subscribeWithPriority(100, this.handleBack.bind(this));
    }
  }

  private async handleBack() {
    if (this.openScanner) {
      this.ref.callReference(this.openScanner.key, this.openScanner.formId, this.openScanner.formType, TriggerType.StopScan);
    }
    else {
      const [topModal, topPop, topAction, openMenu] = await Promise.all([
        this.modalCtrl.getTop(),
        this.popCtrl.getTop(),
        this.actionCtrl.getTop(),
        this.menuCtrl.isOpen()
      ]);
      if (topPop) {
        this.logger.debug('Open popover');
        topPop.dismiss();
      }
      else if (topModal) {
        this.logger.debug('Open Modal');
        topModal.dismiss();
      }
      else if (topAction) {
        this.logger.debug('Open actionsheet');
        topAction.dismiss();
      }
      else if (openMenu) {
        this.logger.debug('Open menu');
        this.menuCtrl.close();
      }
      else {
        this.backButton.next();
      }
    }

  }
}
