import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { BrowserService } from 'src/app/services/browser.service';
import { DeviceService } from 'src/app/services/device.service';
import { FieldService } from 'src/app/services/field.service';
import { InLoggerService } from 'src/app/services/in-logger.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-version-check',
  templateUrl: './version-check.component.html',
  styleUrls: ['./version-check.component.scss'],
  providers: [FieldService]
})
export class VersionCheckComponent extends FieldType implements OnInit {
  message: string;
  error = false;
  mayUpdate = false;
  constructor(
    private util: UtilityService,
    private translate: TranslateService,
    private device: DeviceService,
    private browser: BrowserService,
    private fieldService: FieldService,
    private logger: NGXLogger,
    private inLogger: InLoggerService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get version(): string {
    return this.to.version ?? '';
  }

  get disableForm(): boolean {
    return this.to.disableForm ?? true;
  }

  ngOnInit() {
    if (!this.version) {
      return;
    }
    this.mayUpdate = !this.device.isDevice() || !!this.device.storeLink;
    try {
      if (!this.util.isNewerOrSameVersion(this.version, environment.appVersion)) {
        this.message = this.translate.instant('VersionCheckFail', {current: environment.appVersion, required: this.version});
        this.failedCheck();
      }
    }
    catch (error) {
      this.error = true;
      this.message = this.translate.instant('VersionError');
      this.logger.error('Error checking app version', error);
      this.inLogger.error('Error checking app version', error, {current: environment.appVersion, required: this.version});
      this.failedCheck();
    }
  }

  updateApp() {
    if (!this.device.isDevice()) {
      this.browser.reload();
    }
    else if (this.device.storeLink) {
      this.browser.openUrl(this.device.storeLink);
    }
  }

  failedCheck() {
    setTimeout(() => {
      this.fieldService.setValue(true);
      if (this.disableForm) {
        this.form.disable();
      }
    });
  }

}
