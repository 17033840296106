<ion-item lines="none" *ngIf="!useModal">
  <span class="info-label" [innerHTML]="label" *ngIf="!hasMarkdown" style="font-weight: bold"></span>&nbsp;
  <span class="info-text" [innerHTML]="infoText" *ngIf="!hasMarkdown"></span>
  <markdown #markdownEl [class.overflowing]="isOverflowing" [data]="infoText" *ngIf="hasMarkdown" (swipe)="handleSwipe()" (ready)="checkOverflowing()" (scroll)="hideScrollArrow()"></markdown>
  <ion-icon class="overflow-indicator aniHoverX" name="chevron-forward-sharp" *ngIf="showOverflowArrow"></ion-icon>
</ion-item>
<ion-button 
  class="ion-text-wrap ion-padding-start ion-padding-end"
  type="button"
  [color]="color"
  [fill]="fill"
  expand="block"
  (click)="openModal()"
  *ngIf="useModal"
  [disabled]="!hasMarkdown || isLoading"
>
  <ion-label class="ion-padding-top ion-padding-bottom" *ngIf="!isLoading">{{buttonText}}</ion-label>
  <ion-spinner *ngIf="isLoading"></ion-spinner>
</ion-button> 