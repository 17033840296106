import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { LegacyService } from 'src/app/services/legacy.service';
import { UtilityService } from 'src/app/services/utility.service';
import { FieldService } from 'src/app/services/field.service';
import { FormType } from 'src/app/models/models';
import { StateService } from 'src/app/services/state.service';
import { WatchOptions } from 'src/app/models/interfaces/watchOptions';

@Component({
  selector: 'app-field-textmerge',
  templateUrl: './textmerge.component.html',
  styleUrls: ['./textmerge.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class TextmergeComponent extends FieldType implements OnInit {
  watchGroup: WatchOptions;
  hideLabel: boolean;

  constructor(
    private watch: WatchgroupService,
    private legacy: LegacyService,
    private util: UtilityService,
    private fieldService: FieldService,
    private state: StateService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }

  get total(): string {
    return this.model[this.key as string | number] || '';
  }

  get template(): string {
    return (typeof this.to.template === 'string') ?  this.to.template : '';
  }

  get delimiter(): string {
    return this.to.delimiter || ' ';
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  public static getHtml(config: FormlyFieldConfig, value: string) {
    const label = config.templateOptions.label ?? '';
    return `<ion-card><ion-item>
              <ion-label>${label}</ion-label>
              <span style="text-align: left">${value}</span>
            </ion-item></ion-card>`;
  }

  ngOnInit() {
    this.hideLabel = this.to.hideLabel || false;
    this.watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (this.watchGroup.fieldKeys.length > 0) {
      this.watch.watchGroup(this.form, this.model, this.watchGroup).subscribe(() => this.evalField());
      this.evalField();
    }
    else if (this.template) {
      this.evalTemplate();
    }
  }

  evalField() {
    if (this.template) {
      this.evalTemplate();
    }
    else {
      this.evalWatchGroup();
    }
  }

  evalWatchGroup() {
    const texts: string[] = [];
    for (const key of this.watchGroup.fieldKeys) {
      const t = (this.watchGroup.paths && this.watchGroup.paths[key]) ? this.util.dotRef(this.model[key], this.watchGroup.paths[key]): this.model[key];
      if (Array.isArray(t)) {
        texts.push(this.util.convertArrayValue(t, key, this.fields));
      }
      else if (t) {
        texts.push(t);
      }
    }
    const value = (texts.length > 0) ? texts.join(this.delimiter) : '';
    this.fieldService.setValue(value);
  }

  evalTemplate() {
    let tmpModel = {};
    if(this.watchGroup?.paths) {
      Object.entries(this.model).forEach(([key, val]) => {
        tmpModel[key] = (this.watchGroup.paths[key]) ? this.util.dotRef(val, this.watchGroup.paths[key]): val;
    });
    }
    else {
      tmpModel = this.model;
    }

    const regEx = /__(.*?)__/g;
    const text = this.util.parseText(this.template, tmpModel, this.fields, '', regEx);
    this.fieldService.setValue(text);
  }

}
