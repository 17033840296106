import { NgxLoggerLevel } from 'ngx-logger';
import { commonEnvironment } from './environment.common';

export const environment = Object.assign({
  production: true,
  useServiceWorker: true, // If it should use PWA service worker
  loadingTimeout: 30000, // For how long (in ms) loading spinner should default timeout
  maxLoadingRetries: 5, // How many times loading service should try dismissing loading spinner
  consoleLogLevel: NgxLoggerLevel.ERROR, // Level for logging to console
  serverLogLeveL: NgxLoggerLevel.FATAL, // Level for logging to server
  maxStorageAge: 30, // How long to store things in storage
  maxApiRetries: 2, // How many times the ApiService should retry an call
  maxExternalApiFails: 5, // The treshold for fails before sending warning to slack
  apiErrorSlackChannel: 'in-errors', // The name of the slack channel for api fails
  externalWarningFrequency: 24, // Minimum time (in hours) between warnings on a specific URL
  noResultWarningFrequency: 6, // Minium time (in hours) between warnings for backend error
  maxOverlayClose: 5, // How many times popup serviced should try to close overlays
  useNotifications: false, // If the app should init and listen for notifications
  maxStoredNotifications: 50, // How many notifications should be stored
  splashScreenWait: 0 // How long the app should wait before hiding splash screen
}, commonEnvironment);
