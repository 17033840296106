/**Color theme */
export interface Theme {
    /**The CSS class for the color theme */
    className: string;

    /**Value for the color theme, by select etc. */
    value: string;

    /**The label to present to the user for the color theme. Will be translated */
    label: string;
}

/**Themes available*/
// eslint-disable-next-line @typescript-eslint/naming-convention
export const InThemes: Theme[] = [
    {
        className: '',
        value: 'light',
        label: 'PrimaryTheme'
    },
    {
        className: 'dark',
        value: 'dark',
        label: 'DarkMode'
    }
];

