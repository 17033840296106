<ion-header>
    <ion-toolbar color="header">
      <ion-title>{{'UserSettings' | translate}}</ion-title>
      <ion-button fill="clear" slot="end" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  
  <app-online-status template="banner" ></app-online-status>

  <ion-content class="in-background">
    <ion-card>
      <ion-spinner *ngIf="loading"></ion-spinner>
      <ion-list *ngIf="!loading">
        <ion-item *ngIf="user" lines="none" (click)="changeProfilePicture()">
          <ion-img [src]="profilePic"></ion-img>
          <ion-button slot="end" fill="clear" >
            <ion-icon slot="icon-only" name="create"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item *ngIf="user" lines="none" (click)="editName()">
          <ion-label (pressup)="switchToDev($event)">{{user.name + ' ' + user.surname}}</ion-label>
          <ion-button slot="end" fill="clear" >
            <ion-icon slot="icon-only" name="create"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item *ngIf="user" lines="none" (click)="editEmail()">
          <ion-label>{{user.emailAddress}}</ion-label>
          <ion-button slot="end" fill="clear" >
            <ion-icon slot="icon-only" name="create"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
      <br/>
      <ion-list>
        <ion-button expand="block" fill="solid" (click)="changePassword()" *ngIf="user">
          {{'ChangePassword' | translate}}
        </ion-button>
      </ion-list>
    </ion-card>
    <ion-card>
      <ion-list>
        <ion-button expand="block" color="danger" fill="solid" (click)="signOut()">
          {{'SignOut' | translate}}
        </ion-button>  
      </ion-list>
    </ion-card>
  </ion-content>