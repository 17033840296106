/**Interface for defined language */
export interface Language {
    code: string;
    name: string;
}

/**Available languages in the app */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const DefinedLanguages: Language[] = [
    {code: 'en', name: 'English'},
    {code: 'no', name: 'Norsk'},
    {code: 'sv', name: 'Svenska'}
];
