import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { PopupService } from 'src/app/services/popup.service';
import SwiperCore, { Swiper, SwiperOptions, Zoom } from 'swiper';
import { IonicSwiper } from '@ionic/angular';

SwiperCore.use([IonicSwiper, Zoom]);

@Component({
  selector: 'app-photo-modal',
  templateUrl: './photo-modal.component.html',
  styleUrls: ['./photo-modal.component.scss'],
})
export class PhotoModalComponent {
 // @ViewChild('slider', { read: ElementRef }) slider: ElementRef;
  @Input() src: string;
  @Input() id: number;
  @Input() disabled: boolean;

  slider: Swiper;
  sliderOpts: SwiperOptions = {
    zoom: {
      maxRatio: 3
    }
  };

  constructor(
    private modalCtrl: ModalController,
    private popup: PopupService,
    private logger: NGXLogger
  ) { }

  setSwiperInstance(ev: Swiper) {
    this.slider = ev;
  }

  zoom(zoomIn: boolean) {
    const zoom = this.slider.zoom;
    if (zoomIn) {
      zoom.in();
    }
    else {
      zoom.out();
    }
  }

  async closeModal(remove: boolean) {
    try {
      if (remove) {
        remove = await this.popup.showConfirm('RemovePhoto', 'RemovePhotoMsg', true);
        if (remove) {
          await this.modalCtrl.dismiss({
            deletePhoto: remove
          });
        }
      }
      else {
        await this.modalCtrl.dismiss({
          deletePhoto: remove
        });
      }
    }
    catch (err) {
      this.logger.warn('Failed dismissing photo modal', err);
    }
  }

}
