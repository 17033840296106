import { Component, ViewChild, ViewContainerRef, AfterViewInit, ElementRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-wrapper-disable-radio',
  templateUrl: './disable-radio.component.html',
  styleUrls: ['./disable-radio.component.scss'],
})
export class DisableRadioComponent extends FieldWrapper implements AfterViewInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  constructor(private el: ElementRef) {
    super();
  }

  ngAfterViewInit() {
    if (this.to.disabled) {
      const elRef: HTMLElement = this.el.nativeElement;
      const radios = elRef.querySelectorAll('ion-radio');
      radios.forEach((radio) => {
        radio.disabled = true;
      });
    }
  }

}
