import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EvalFunction, parse } from 'mathjs';
import * as _ from 'lodash';

/**
 * Service for evaluationg expressions
 * - **evalHideExpression**: Evaluate the hide expression of an field
 * - **evalStringExpression**: Evaluate an expression using values from model
 */
@Injectable({
  providedIn: 'root'
})
export class EvaluationService {

  constructor(private logger: NGXLogger) { }

  /**
   * Evaluate the hide expression of an field
   * @param expression The hide expression to evaluate
   * @param hide The hide value of the field
   * @param model The model to use while evaluationg
   * @param formState The formstate to use while evaluating expression function
   */
  evalHideExpression(
    expression: string | boolean | ((model: any, formState: any, field?: FormlyFieldConfig) => boolean),
    hide: boolean,
    model: any,
    formState: any
  ): boolean {
    if (typeof expression === 'boolean') {
      return hide || expression;
    }
    else if (typeof expression === 'function') {
      return expression(model, formState);
    }
    else if (typeof expression === 'string') {
      return !!this.evalBooleanExpression(expression, model);
    }
    else {
      return !!hide;
    }
  }

  /**
   * Evaluate an boolean expression using values from model
   * @param expression The hide expression to evaluate
   * @param model The model to use when evaluation the hide expression
   */
  evalBooleanExpression(expression: string, model: any): any {
    const newExpr = expression.replace(/\^/g, '**').replace(/model\.([^\.!=\s\[\]\(\)\+\*\/,\|\&]+)/g, (s: string, m1: string) => {
      let value = model[m1];
      if (typeof value === 'string') {
        value = value.replace(',', '.');
      }

      if (typeof value === 'string') {
        return `'${value}'`;
      }
      else if (typeof value === 'undefined' || value === null || value === '') {
        return '""';
      }
      else if (Array.isArray(value)) {
        return JSON.stringify(value);
      }
      else {
        return value;
      }
    });
    try {
      // eslint-disable-next-line no-eval
      return eval(newExpr);
    }
    catch (err) {
      this.logger.error(`Error evaluating string expression: ${expression} (${newExpr})`, err);
      return false;
    }
  }

  /**
   * Compile an expression to and mathjs.EvalFunction and find used fields
   * @param expression The expression to compile
   * @param fields The fields of the form
   */
  compileMathExpression(expression: string, fields: FormlyFieldConfig[]): {compiledExpr: EvalFunction, fieldsUsed: string[]} {
    try {
      expression = expression.replace(/Math\./g, '').replace(/\*\*/g, '^').replace(/model\./g, '');
      const nodes = parse(expression);
      const fieldKeys = fields.map(f => f.key as string).filter(key => !!key);
      const fieldsUsed: string[] = [];
      nodes.traverse((node) => {
        if (node.type === 'SymbolNode' && fieldKeys.includes(node.name)) {
          fieldsUsed.push(node.name);
        }
      });
      const func = nodes.compile();
      return {fieldsUsed: fieldsUsed, compiledExpr: func};
    }
    catch (err) {
      this.logger.error(`Error compiling expression: ${expression}`, err);
      return {fieldsUsed: [], compiledExpr: null};
    }
  }

  /**
   * Evaluate an compiled expression
   * @param expression The expression to evaluate
   * @param model The model of the form
   * @param fieldsUsed The fields used by the expression
   * @param errorValue What to return if something goes wrong
   */
  evalCompiledMathExpression(expression: EvalFunction, model: any, fieldsUsed: string[], errorValue: number): number {
    if (!expression) {
      return errorValue;
    }
    const context: any = _.cloneDeep(model);
    for (const key of fieldsUsed) {
      const val = context[key];
      if (typeof val === 'undefined' || val === null || val === '') {
        context[key] = 0;
      }
    }
    try {
      const val: number = expression.evaluate(context);
      return val;
    }
    catch (err){
      this.logger.error(`Error evaluating compiled expression`, err);
      return errorValue;
    }
  }
}
