import { Component, OnInit, ViewChild } from '@angular/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { UtilityService } from 'src/app/services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { FieldService } from 'src/app/services/field.service';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { CachedData, DataSource, DataStatus, FormType, IonColor, Trigger, TriggerType } from 'src/app/models/models';
import { HttpErrorResponse } from '@angular/common/http';
import { MarkdownComponent } from 'ngx-markdown';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ModalController } from '@ionic/angular';
import { TextModalComponent } from 'src/app/custom-components/directives/shared-directives/text-modal/text-modal.component';
import { Observable } from 'rxjs';
import { StateService } from 'src/app/services/state.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { WatchOptions } from 'src/app/models/interfaces/watchOptions';

@Component({
  selector: 'app-field-infolookup',
  templateUrl: './infolookup.component.html',
  styleUrls: ['./infolookup.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class InfolookupComponent extends FieldType implements OnInit, Trigger {
  @ViewChild('markdownEl') markdownEl: MarkdownComponent;
  lastCall: Date;
  infoText = '';
  hasMarkdown = false;
  count = 0;
  intervalId: any;
  isOverflowing = false;
  showOverflowArrow = false;
  isLoading = false;
  watchGroup: WatchOptions;

  constructor(
    private watch: WatchgroupService,
    private util: UtilityService,
    private translate: TranslateService,
    private fieldService: FieldService,
    private cachedApi: CachedApiService,
    private domOp: DomOperationsService,
    private validation: ValidationService,
    private modalCtrl: ModalController,
    private state: StateService,
    private ref: ComponentRefService,
    private logger: NGXLogger
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label ?? '';
  }

  get url(): string {
    return this.to.lookupUrl ?? '';
  }

  get jsonPath(): string {
    return this.to.jsonPath ?? '0';
  }

  get labelProp(): string | {label: string, key: string}[]  {
    return this.to.labelProp ?? 'name';
  }

  get valueProp(): string | string[] {
    return this.to.valueProp ?? '';
  }

  get splitter(): string {
    return this.to.splitter ?? ';';
  }

  get tableName(): string {
    return this.to.lookupName ?? '';
  }

  get emptyText(): string {
    return this.to.notFoundLabel || this.translate.instant('Unknown');
  }

  get delay(): number {
    if (typeof this.to.delay === 'undefined') {
      return 0;
    }
    else {
      try {
        return parseInt(this.to.delay);
      }
      catch {
        return 0;
      }
    }
  }

  get template(): string {
    return this.to.template || '';
  }

  get useModal(): boolean {
    return this.to.openInModal ?? false;
  }

  get showPrint(): boolean {
    return this.to.showPrint ?? false;
  }

  get buttonText(): string {
    return this.label || this.translate.instant('Open');
  }

  get color(): IonColor {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Primary;
  }

  get fill(): 'clear' | 'solid' | 'outline' {
    switch (this.to.fill) {
      case 'clear':
      case 'solid':
        return this.to.fill;
      default:
        return 'outline';
    }
  }

  get httpType(): 'GET' | 'POST' | 'PUT' | 'DELETE' {
    const type = this.to.httpType?.toUpperCase();
    switch (type) {
      case 'POST':
      case 'PUT':
      case 'DELETE':
        return type;
      default:
        return 'GET';
    }
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  get watchValue(): any {
    if (this.watchGroup.fieldKeys.length > 0) {
      const key = this.watchGroup.fieldKeys[0];
      return this.model[key];
    }
    else {
      return null;
    }
  }

  get hasParent(): boolean {
    return this.to.parent ?? false;
  }

  get parentValue(): string {
    if (this.hasParent) {
      const value = this.model[this.to.parent] ?? this.model[this.watchGroup.fieldKeys[0]] ?? null; //if parent is just marked boolean 'true', get the first in watch.
      return value || null;
    }
    else {
      return null;
    }
  }

  public static getHtml(config: FormlyFieldConfig, value: string) {
    if (config.templateOptions.template) {
      return `<ion-card style="padding: 10px">${value}</ion-card>`;
    }
    else {
      const label = config.templateOptions.label ?? '';
      return `<ion-card><ion-item lines="none">
                <span style="font-weight: bold">${label}</span>&nbsp;
                <span>${value}</span>
              </ion-item></ion-card>`;
    }
  }

  ngOnInit() {
    this.watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    this.setReference(this.key as string, this.formId, this.formType);
    this.infoText = this.emptyText;
    if (this.watchGroup.fieldKeys.length > 0) {
      this.runLookup(this.watchValue);
      this.watch.watchGroup(this.form, this.model, this.watchGroup).subscribe((vc) => {
        const val = vc ? vc.newValue : null;
        if (this.delay === 0) {
          this.runLookup(val);
        }
        else {
          this.count += 1;
          const that = this;
          setTimeout(() => {
            that.count -= 1;
            if (this.count === 0) that.runLookup(val);
          }, that.delay);
        }
      });
    }
    else {
      this.runLookup();
    }
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Update) {
      this.runLookup(this.watchValue);
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  checkOverflowing() {
    setTimeout(() => {
        this.isOverflowing = this.domOp.isOverflowing(this.markdownEl?.element?.nativeElement);
        this.showOverflowArrow = this.isOverflowing;
    });
  }

  hideScrollArrow() {
    setTimeout(() => {
      this.showOverflowArrow = false;
    }, 1000);
  }

  runLookup(newValue?: any) {
    if (this.to.disabled && !this.valueProp) {
      if (this.template) {
        this.hasMarkdown = true;
      }
      this.infoText = this.model[this.key as string | number];
    }
    else if (this.to.lookupUrl) {
      this.runLookupWeb();
    }
    else if (this.tableName) {
      this.runTableLookup(newValue);
    }
  }

  runTableLookup(value?: string) {
    this.loading(true);
    const table = (typeof value !== 'undefined' && value !== null) ? this.tableName + value : this.tableName;
    const label = this.labelProp as string;
    this.cachedApi.getLookupJson(table, this.parentValue).subscribe(data => {
      const vals = data.value;
      this.loading(false);
      if (data.status === DataStatus.Error) {
        this.setError(data.error);
      }
      else if (vals.length > 0) {
        this.infoText = vals[0][label];
        this.fieldService.setValue(this.infoText);
      }
      else {
        this.setEmpty();
      }
    });
  }

  runLookupWeb() {
    this.loading(true);
    this.lastCall = new Date();
    let thisCall = new Date();

    const url = this.util.parseText(this.url, this.model, this.fields);
    let obs: Observable<CachedData<any>>;
    if (this.httpType === 'POST') {
      obs = this.cachedApi.getWebJsonWithPost(url, this.jsonPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    else if (this.httpType === 'PUT') {
      obs = this.cachedApi.getWebJsonWithPut(url, this.jsonPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    else if (this.httpType === 'DELETE') {
      obs = this.cachedApi.getWebJsonWithDelete(url, this.jsonPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    else {
      obs = this.cachedApi.getWebJSON(url, this.jsonPath, this.formId, this.to.headers, this.to.useCredentials);
    }
    obs.subscribe(data => {
      if (thisCall < this.lastCall) return;

      if (data.source === DataSource.Storage) {
        this.lastCall = new Date();
        thisCall = new Date();
      }
      this.loading(false);
      if (data.status === DataStatus.Error) {
        this.setError(data.error);
      }
      else if (data.status === DataStatus.Updated) {
        this.unpackResult(data.value);
      }
    });
  }

  unpackResult(data: any) {
    this.hasMarkdown = false;
    if (data) {
      //console.log("🚀 ~ InfolookupComponent ~ unpackResult ~ data:", data)
      if (this.template) {
        const regEx = /__(.*?)__/g;
        const text = this.util.parseText(this.template, data, this.fields, '', regEx, true);
        if (text) {
          this.hasMarkdown = true;
          this.infoText = text;
          this.setModel(data);
        }
        else {
          this.hasMarkdown = false;
          this.setEmpty();
        }
      }
      else if (Array.isArray(this.labelProp)) {
        let text = '';
        for (const prop of this.labelProp) {
          const label = prop.label;
          const value = this.util.dotRef(data, prop.key);
          text += `**${label}:** ${value}\n\n`;
        }
        if (text) {
          this.hasMarkdown = true;
          this.infoText = text;
          this.setModel(data);
        }
        else {
          this.hasMarkdown = false;
          this.setEmpty();
        }
      }
      else {
        let text = this.util.dotRef(data, this.labelProp);
        if (typeof text === 'number') {
          text = text.toString();
        }
        if (text) {
          this.infoText = text;
          this.setModel(data);
        }
        else {
          this.setEmpty();
        }
      }
    }
    else {
      this.setEmpty();
    }
  }

  setModel(data: any) {
    if (Array.isArray(this.valueProp) && this.valueProp.length > 0) {
      const values: string[] = [];
      for (const vp of this.valueProp) {
        const val = this.util.dotRef(data, vp);
        if (val) values.push(val);
      }
      this.fieldService.setValue(values.join(this.splitter));
    }
    else if (this.valueProp && !Array.isArray(this.valueProp)) {
      const value = this.util.dotRef(data, this.valueProp);
      this.fieldService.setValue(value);
    }
    else {
      this.fieldService.setValue(this.infoText);
    }
  }

  setEmpty() {
    this.infoText = this.emptyText;
    this.fieldService.setValue('');
  }

  setError(e?: HttpErrorResponse) {
    const error = this.translate.instant('Error');
    this.infoText = e ? `${error}: ${e.status}` : error;
    this.fieldService.setValue('');
  }

  loading(start: boolean) {
    if (typeof this.intervalId !== 'undefined') {
      clearInterval(this.intervalId);
    }
    if (start) {
      this.isLoading = true;
      this.infoText = 'Loading';
      const that = this;
      this.intervalId = setInterval(() => {
        setTimeout(() => {
          if (that.infoText.length > 10) that.infoText = 'Loading';

          that.infoText += '.';
        }, 0);
      }, 1050);
    }
    else {
      this.isLoading = false;
    }
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: TextModalComponent,
      componentProps: {
        title: this.label,
        text: this.infoText,
        showPrint: this.showPrint
      }
    });
    modal.present();
  }

  handleSwipe() {}
}
