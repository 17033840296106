import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';


@Component({
  selector: 'app-wrapper-required',
  templateUrl: './required-wrapper.component.html',
  styleUrls: ['./required-wrapper.component.scss'],
})
export class RequiredWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  ngOnInit() {
    if (this.to.required && !this.to.hideRequiredMarker && this.to.label && !this.to.label.endsWith('*')) {
      this.to.label += '*';
    }
  }
}
