export enum Fields {
    AdmVerify = 'adm_verify',
    Barcode = 'barcodescanner',
    Button = 'button',
    Calendar = 'calendar',
    Checkbox = 'checkbox',
    Datetime = 'datetime',
    DocumentLink = 'documentLink',
    DrawingSelect = 'drawingSelect',
    GPS = 'gpsposition',
    HistorySelect = 'historyselect',
    Image = 'image',
    ImageModal = 'imageModal',
    InfoLookup = 'infoLookup',
    InitInput = 'init-input',
    Input = 'input',
    ItemList = 'itemlist',
    LabelRadio = 'labelradio',
    LabelSelect = 'labelselect',
    LastReg = 'lastReg',
    Lookup = 'lookup',
    Modalselect = 'modalselect',
    NFC = 'nfc',
    OpenForm = 'openForm',
    Paint = 'paint',
    PdfModal = 'pdfModal',
    Photo = 'photo',
    Projects = 'projects',
    Radio = 'radio',
    Range = 'range',
    Rating = 'rating',
    SegmentButtons = 'segmentButtons',
    Select = 'select',
    ServerMessage = 'servermessage',
    ServerQueryModal = 'serverQueryModal',
    Signature = 'signature',
    Space = 'space',
    Step = 'step',
    Stream = 'stream',
    StyleForm = 'styleForm',
    SubSelect = 'subselect',
    Sum = 'sum',
    Table = 'table',
    Text = 'text',
    TextArea= 'textarea',
    Textmerge = 'textmerge',
    TimeDiff = 'timediff',
    TimePicker= 'timepicker',
    Title = 'title',
    Toggle = 'toggle',
    Unknown = 'unknown',
    User = 'user',
    ValueList = 'valuelist',
    Watch = 'watch',
    WebLookup = 'webLookup',
    D4Go = 'd4Go',
    D4Saldi = 'd4Saldi',
    Chart = 'chart',
    D4Time = 'd4Time',
    Status = 'status',
    DateHelper = 'dateHelper',
    CalendarView = 'calendarView',
    VersionCheck = 'versionCheck'
}
