import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { NGXLogger } from 'ngx-logger';
import { FormType, Trigger, TriggerType } from 'src/app/models/models';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { FieldService } from 'src/app/services/field.service';
import { PopupService } from 'src/app/services/popup.service';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-field-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
  providers: [FieldService]
})
export class SignatureComponent extends FieldType implements AfterViewInit, OnInit, Trigger, OnDestroy {
  @ViewChild('signature') signaturePad: SignaturePadComponent;

  dataUrl: string;
  resizeSubscription: Subscription;

  constructor(
    private logger: NGXLogger,
    private ref: ComponentRefService,
    private fieldService: FieldService,
    private popup: PopupService,
    private plt: Platform
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }

  get color(): string {
    return this.to.color || '#000000';
  }

  get disabled(): boolean {
    return this.to.disabled || false;
  }

  get paddingOffset(): number {
    if (this.to.currentForm.type === FormType.FormView) {
      return 50;
    }
    else {
      return 105;
    }
  }

  get hasContent(): boolean {
    return this.formControl.dirty;
  }

  get disableButton(): boolean {
    return !this.dataUrl && !this.model[this.key as string | number];
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  public static getHtml(config: FormlyFieldConfig, value: string) {
    const label = config.templateOptions.label ?? '';
    const borderColor = getComputedStyle(document.documentElement).getPropertyValue(`--ion-color-step-300`);
    if (label && value) {
      return `<ion-card style="padding: 10px">
                <ion-card-header>${label}</ion-card-header>
                <img src="${value}" style="border: 1px dashed ${borderColor}"/>
              </ion-card>`;
    }
    else if (!label && value) {
      return `<ion-card style="padding: 10px"><img src="${value}" style="border: 1px dashed ${borderColor}"/></ion-card>`;
    }
    else if (label && !value) {
      return `<ion-card style="padding: 10px"><ion-card-header>${label}</ion-card-header></ion-card>`;
    }
    else {
      return '';
    }
  }

  ngOnInit() {
    if (this.model[this.key as string | number]) {
      this.dataUrl = this.model[this.key as string | number];
    }
  }

  ngAfterViewInit() {
    this.initField();
    this.setReference(this.key as string, this.formId, this.formType);
    this.resizeSubscription = this.plt.resize.subscribe(() => this.initField());
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Set || type === TriggerType.Edit) {
      setTimeout(() => {
        this.fieldService.setValue(data);
        this.dataUrl = data;
      });
    }
    else if (type === TriggerType.Clear) {
      this.clear(true);
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  initField() {
    if (this.signaturePad) {
      const scale = 1;
      const form = document.querySelectorAll('form');
      if (form.length > 0) {
        const contentBound = form[form.length - 1].getBoundingClientRect();
        this.signaturePad.set('canvasWidth', contentBound.width * scale - this.paddingOffset);
        this.signaturePad.set('penColor', this.color);
        this.signaturePad.clear();
      }

    }
  }

  drawStart() {
    this.logger.debug('started signing');
    const input: HTMLIonInputElement = document.querySelector('input:focus');
    if (input) {
      this.logger.debug('Focus input', input);
      input.blur();
    }
  }

  drawComplete() {
    this.logger.debug('stopped signing');
    this.fieldService.setValue(this.signaturePad.toDataURL());
    this.formControl.markAsDirty();
  }

  async clear(confirmed = false) {
    if (!confirmed) {
      confirmed = await this.popup.showConfirm('ClearSign', 'ClearSignMsg', true);
    }
    if (confirmed) {
      this.dataUrl = '';
      this.fieldService.clearValue();
      setTimeout(() => {
        this.initField();
      });
    }
  }

}
