import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-paint-select',
  templateUrl: './paint-select.component.html',
  styleUrls: ['./paint-select.component.scss'],
})
export class PaintSelectComponent implements OnInit {
  @Input() paintfields: {name: string, key: string}[];
  selectedPaint: string;

  constructor(
    private popoverCtrl: PopoverController,
    private logger: NGXLogger
  ) { }

  ngOnInit() {}

  async closePopover(save: boolean)  {
    try {
      if (save) {
        await this.popoverCtrl.dismiss({selectedPaint: this.selectedPaint});
      }
      else {
        await this.popoverCtrl.dismiss();
      }
    }
    catch (err) {
      this.logger.warn('Failed dismissing paint select popover', err);
    }
  }
}
