import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ModalController } from '@ionic/angular';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { ImageModalComponent } from 'src/app/custom-components/directives/formly-directives/image-modal/image-modal.component';
import { UtilityService } from 'src/app/services/utility.service';
import { FormType, IonColor } from 'src/app/models/models';
import { ValidationService } from 'src/app/services/validation.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-field-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  providers: [WatchgroupService]
})
export class ImageViewerComponent extends FieldType implements OnInit {
  parsedUrls: string[] = [];
  parsedLabel = '';
  error = false;

  constructor(
    private modalCtrl: ModalController,
    private watch: WatchgroupService,
    private util: UtilityService,
    private validation: ValidationService,
    private state: StateService
  ) {
    super();
  }

  get label(): string {
    return this.to.label || '';
  }

  get src(): string[] {
    if (Array.isArray(this.to.src)) {
      return this.to.src;
    }
    else if (this.to.src) {
      return [this.to.src];
    }
    else {
      return [];
    }
  }

  get color(): string {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Primary;
  }

  get errorMsg(): string {
    return this.to.errorMsg || '';
  }

  get missing(): string {
    return this.to.missing || '[missing]';
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  ngOnInit() {
    this.parseData();
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length >0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => this.parseData());
    }
  }

  parseData() {
    this.error = false;
    this.parsedLabel = this.util.parseText(this.label, this.model, this.fields, this.missing);
    if (!this.util.textIsParsed(this.parsedLabel)) {
      this.error = true;
    }
    this.parsedUrls = [];
    for (const url of this.src) {
      const parsedUrl = this.util.parseText(url, this.model, this.fields, this.missing);
      if (!this.util.textIsParsed(parsedUrl, this.missing)) {
        this.error = true;
        break;
      }
      this.parsedUrls.push(parsedUrl);
    }
  }

  async openModal() {
    if (this.parsedUrls.length > 0) {
      const modal = await this.modalCtrl.create({
        component: ImageModalComponent,
        componentProps: {
          inputUrls: this.parsedUrls,
          errorMsg: this.errorMsg
        }
      });
      await modal.present();
    }
  }
}
