
<div id="day" class="wrapper" (swipe)="handleSwipe($event)">
  <table class="workweek" *ngIf="showWeeklyReport">
    <tr class="dayName">
      <ng-container
      *ngFor="let r of weeklyReportList; let i = index;"
      >
        <ng-container
          *ngTemplateOutlet="weekdayname; context: { item: r, index: ++i }"
        ></ng-container>
      </ng-container>
    </tr>
    <tr>
   
  <ng-container
  *ngFor="let r of weeklyReportList; let i = index;"
  >
    <ng-container
      *ngTemplateOutlet="report; context: { item: r, index: i }"
    ></ng-container>
  </ng-container>
  </tr>
  </table>

  <div class="dateArea">
    <ion-icon class="arrow" name="chevron-back-outline" (click)="setNextDate(-1)"></ion-icon>

    <span class="datepicker-toggle">
      <span class="datepicker-toggle-button"></span>
      <input type="date" [disabled]="isDisabled" [ngClass]="{'disabled': isDisabled}" value="{{currDateVal}}" (change)="setDate($event)" class="datepicker-input">
      <span id="dateOnly" [innerHtml]="getDate"></span>
    </span>

    <ion-icon class="arrow" name="chevron-forward-outline" (click)="setNextDate(1)"></ion-icon>
  </div>
  
  
  <div id="content" [class.disabled]="isLoading.regs">
    <!--
    <div class="shade" [class.areaDisabled]="isLoading.regs">

      <ion-skeleton-text animated class=" skeleton-text" ></ion-skeleton-text>
      <ion-skeleton-text animated class=" skeleton-text" ></ion-skeleton-text>
      <ion-skeleton-text animated class=" skeleton-text" ></ion-skeleton-text>

  </div>
-->
  <div id="hourInfo"> <span (click)="addRegItem(sumUnused)" *ngIf="mode === 'timefordeling'">{{'HoursLeft' | translate}}: <span class="number">{{sumUnused | number: '1.0-2'}}</span>{{'TimeAgoHour' | translate}} |</span> {{hoursDoneText | translate}}: <span class="number">{{sum | number: '1.0-2'}}</span>{{'TimeAgoHour' | translate}}</div>

  <ng-container
  *ngFor="let r of reglist; let i = index; trackBy: trackbyId"
  >
    <ng-container
      *ngTemplateOutlet="regline; context: { item: r, index: i }"
    ></ng-container>
  </ng-container>

  <div class="regItemEmpty" *ngIf="noRegs">
    <ion-skeleton-text *ngIf="isLoading.regs" animated class="skeleton-text-small" ></ion-skeleton-text>
    <div class="projectLine" *ngIf="!isLoading.regs" >
      <span class="projectName noRegs">
        <span class="highlight" (click)="addRegItem()">{{'NothingFound' | translate}}</span>
      </span>
  <!--    <ion-spinner class="regLoading" *ngIf="isLoading.regs"></ion-spinner>-->
    </div>
  </div>

  <div *ngIf="!isLoading.regs" class="projectLine lineBtn" >
    <ion-button size="small" color="success" class="" (click)="addRegItem()">
      <ion-icon slot="start" name="add-outline"></ion-icon>
      <span class="lineBtnName">{{'AddLine' | translate}}</span>
    </ion-button>
  
  </div>
  
</div>

<ng-template
  button
  #weekdayname
  let-index="index"
  let-nr="item.Nr"
  let-pos="item.PositivValue"
  let-name="item.Name"
>

<td>
  <span class="name" (click)="jumpToDate(nr)" [class.currDayPos]="pos" [class.currDay]="weekday===nr">{{name}}</span>
</td>

</ng-template>

<ng-template
  button
  #report
  let-index="index"
  let-plan="item.Plan"
  let-arb="item.Arb"
  let-pos="item.PositivValue"
>

<td class="day" >
  <span class="plan">{{plan}}</span>
  <span class="worked" [class.lessThenPlanned]="!pos" [class.moreThenPlanned]="pos">{{arb}}</span>
</td>

</ng-template>

<ng-template
  button
  #regline
  let-index="index"
  let-id="item.id"
  let-tabs="item.tabs"
  let-label="item.label"
  let-labelHighlight="item.labelHighlight"
  let-value="item.value"
  let-disabled="item.disabled"
>

  <div class="regItem" [class.emptyValue]="!value || value === '0' || value <= 0">
    <ion-skeleton-text *ngIf="isLoading.regs" animated class=" skeleton-text" ></ion-skeleton-text>

    <div class="projectLine" [class.disabled]="disabled" [class.emptyValue]="!id" [class.hide]="isLoading.regs">
      <span class="projectBtns"><ion-icon (click)="removeRegItem(index, true)" name="close-outline"></ion-icon></span>
      <span class="projectName" (click)="showTabsModal(index, tabs)">
        <span class="highlight" [innerHtml]="labelHighlight"></span>
        <span class="tabsValues" [innerHtml]="label"></span>
      </span>
      <span class="valueInput"><input placeholder="0" value="{{value}}" (change)="updateValue(index, $event)" type="number"/> t</span>
    </div>
  </div>

</ng-template>
