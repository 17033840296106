import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { UtilityService } from 'src/app/services/utility.service';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { ModalController } from '@ionic/angular';
import { PdfModalComponent } from 'src/app/custom-components/directives/formly-directives/pdf-modal/pdf-modal.component';
import { FormType, IonColor } from 'src/app/models/models';
import { ValidationService } from 'src/app/services/validation.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-field-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.scss'],
  providers: [WatchgroupService]
})
export class PdfviewerComponent extends FieldType implements OnInit {
  parsedUrl: string;
  parsedLabel: string;

  constructor(
    private util: UtilityService,
    private watch: WatchgroupService,
    private modalCtrl: ModalController,
    private validation: ValidationService,
    private state: StateService
  ) {
    super();
  }

  get label(): string {
    return this.to.label || '';
  }

  get url(): string {
    return this.to.url || '';
  }

  get color(): string {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Primary;
  }

  get disableButton(): boolean {
    return !this.parsedUrl || !this.util.textIsParsed(this.parsedUrl);
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  ngOnInit() {
    this.parseInfo();
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length >0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => this.parseInfo());
    }
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: PdfModalComponent,
      componentProps: {
        url: this.parsedUrl,
        label: this.parsedLabel
      }
    });
    await modal.present();
  }

  private parseInfo() {
    this.parsedUrl = this.util.parseText(this.url, this.model, this.fields);
    this.parsedLabel = this.util.parseText(this.label, this.model, this.fields);
  }
}
