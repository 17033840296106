<ion-header>
  <ion-toolbar>
    <ion-button fill="clear" slot="end" color="danger" (click)="closeModal(true)" *ngIf="!disabled">
      <ion-icon name="trash" slot="icon-only"></ion-icon>
    </ion-button>
    <ion-button fill="clear" slot="end" color="secondary" (click)="closeModal(false)">
      <ion-icon name="close-circle-outline" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen>

   <ion-grid>
     <ion-row>
       <ion-col size="1" offset="4">
          <ion-button class="zoom-in" (click)="zoom(true)" fill="" color="dark">
            <ion-icon name="add" slot="icon-only"></ion-icon>
          </ion-button>
       </ion-col>
       <ion-col size="1" offset="1">
          <ion-button class="zoom-out" (click)="zoom(false)" fill="" color="dark">
            <ion-icon name="remove" slot="icon-only"></ion-icon>
          </ion-button>
       </ion-col>
     </ion-row>
     <ion-row>
       <ion-col size="12">
         <swiper [config]="sliderOpts" (swiper)="setSwiperInstance($event)" #slider>
           <ng-template swiperSlide class="slideroption">
             <div class="swiper-zoom-container slide-zoom">
               <img src="{{src}}">
             </div>
           </ng-template>
         </swiper>
       </ion-col>
     </ion-row>
   </ion-grid>

 
</ion-content>