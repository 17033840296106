import { Component, OnInit, Input, HostListener } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ModalController } from '@ionic/angular';
import { FormService } from 'src/app/services/form.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from 'src/app/services/popup.service';
import { NGXLogger } from 'ngx-logger';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { FormType, DataStatus, DataSource, FormlyPage, OldReg, Form, LinkSource } from 'src/app/models/models';
import { StatisticsService } from 'src/app/services/statistics.service';

@Component({
  selector: 'app-registration-modal',
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss'],
  providers: [FormService]
})
export class RegistrationModalComponent implements OnInit, FormlyPage {
  @Input() reg: OldReg;
  foundReg = false;
  loading = false;
  untouchedForm: Form;
  form: Form;
  fields: FormlyFieldConfig[];
  model = {};
  formGroup = new UntypedFormGroup({});
  options: FormlyFormOptions = {};

  constructor(
    private modalCtrl: ModalController,
    private cachedApi: CachedApiService,
    private formService: FormService,
    private translate: TranslateService,
    private popup: PopupService,
    private logger: NGXLogger,
    private statistics: StatisticsService
  ) {
    this.formService.init(this, FormType.Registration);
  }

  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.logger.debug('disable keys');
    event.returnValue = false;
    event.preventDefault();
  }

  ngOnInit() {
    this.loading = true;
    this.cachedApi.getRegistration(this.reg.regId).subscribe(data => {
      const reg = data.value;
      if (reg && data.status === DataStatus.Updated) {
        this.loading = false;
        this.formService.initForm(reg);
        this.foundReg = true;
      }
      if (data.source === DataSource.API) {
        if (!this.foundReg) {
          this.regError();
        }
        else {
          this.statistics.openedReg(this.reg.formId, this.reg.regId, LinkSource.LastReg);
        }
      }
    });
  }

  close() {
    this.statistics.closedReg(this.reg.formId, this.reg.regId);
    this.modalCtrl.dismiss().then(
      () => this.logger.debug('Successfully dismissed registration modal'),
      (err) => this.logger.warn('Failed dismissing registration modal', err)
    );
  }


  disabled(event: Event) {
    this.logger.debug('disabled click..');
    event.preventDefault();
    event.stopPropagation();
  }

  private async regError() {
    const text = this.translate.instant(['RegNotFound', 'RegNotFoundMsg'], {reg: this.reg.regId});
    await this.popup.showAlert(text['RegNotFound'], text['RegNotFoundMsg'], false);
    this.close();
  }

}
