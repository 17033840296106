import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { FormType, FormlyPage, NewReg, Form } from 'src/app/models/models';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from 'src/app/services/popup.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-offline-reg',
  templateUrl: './offline-reg.component.html',
  styleUrls: ['./offline-reg.component.scss'],
  providers: [FormService]
})
export class OfflineRegComponent implements OnInit, FormlyPage {
  @Input() reg: NewReg;
  @Input() formName: string;
  @Input() showDelete = true;
  foundReg = false;
  loading = false;
  untouchedForm: Form;
  form: Form;
  fields: FormlyFieldConfig[];
  model = {};
  formGroup = new UntypedFormGroup({});
  options: FormlyFormOptions = {};

  constructor(
    private modalCtrl: ModalController,
    private api: ApiService,
    private formService: FormService,
    private translate: TranslateService,
    private popup: PopupService,
    private logger: NGXLogger
  ) {
    this.formService.init(this, FormType.Registration);
  }

  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.logger.debug('disable keys');
    event.returnValue = false;
    event.preventDefault();
  }

  async ngOnInit() {
    if (!this.reg) {
      await this.formError();
      return;
    }
    this.loading = true;
    this.api.getForm(this.reg.FormId).subscribe(async data => {
      const form = data.value;
      this.loading = false;
      if (form) {
        this.foundReg = true;
        await this.formService.initForm(form, null, this.reg.ValueList);
      }
      else {
        await this.formError();
      }
    });
  }

  close() {
    this.dismiss();
  }

  delete() {
    this.dismiss({
      delete: true
    });
  }



  disabled(event: Event) {
    this.logger.debug('disabled click..');
    event.preventDefault();
    event.stopPropagation();
  }

  private async formError() {
    const text = this.translate.instant(['FormNotFound', 'FormNotFoundMsg'], {formId: this.reg?.FormId});
    await this.popup.showAlert(text['FormNotFound'], text['FormNotFoundMsg'], false);
    this.dismiss();
  }

  private dismiss(data?: {delete: boolean}) {
    this.modalCtrl.dismiss(data).then(
      () => this.logger.debug('Successfully dismissed offline reg modal'),
      (err) => this.logger.warn('Failed dismissing offline reg modal', err)
    );
  }
}
