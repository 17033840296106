import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ApiService } from 'src/app/services/api.service';
import { NGXLogger } from 'ngx-logger';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { PhotoModalComponent } from 'src/app/custom-components/directives/formly-directives/photo-modal/photo-modal.component';
import { FieldService } from 'src/app/services/field.service';
import { PopupService } from 'src/app/services/popup.service';
import { Trigger, TriggerType, FilePath, FormType } from 'src/app/models/models';
import { OnlineService } from 'src/app/services/online.service';
import { SelectPictureComponent } from 'src/app/custom-components/directives/formly-directives/select-picture/select-picture.component';
import { DeviceService } from 'src/app/services/device.service';
import { BrowserService } from 'src/app/services/browser.service';
import { CameraService } from 'src/app/services/camera.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-field-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
  providers: [FieldService]
})
export class PhotoComponent extends FieldType implements OnInit, OnDestroy, Trigger {
  photoId = 0;
  photos: {id: number, src: string}[] = [];
  isLoading = false;
  hasOfflinePhotos = false;
  isOnline = true;
  onlineSub: Subscription;

  constructor(
    private cameraService: CameraService,
    private browser: BrowserService,
    private api: ApiService,
    private logger: NGXLogger,
    private device: DeviceService,
    private translate: TranslateService,
    private ref: ComponentRefService,
    private modalCtrl: ModalController,
    private fieldService: FieldService,
    private popup: PopupService,
    private online: OnlineService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }

  get photolimit(): number {
    return this.to.photolimit || 5;
  }

  get allowGallery(): boolean {
    if (this.to.disabled) {
      return false;
    }
    else if (this.device.isDevice() || this.isOnline || !environment.production) {
      return this.to.allowGallery ?? false;
    }
    else {
      return false;
    }
  }

  get disableCamera(): boolean {
    if (this.to.disabled) {
      return true;
    }
    else if (this.device.isDevice() || (this.isOnline && this.device.isMobile()) || !environment.production) {
      return this.to.disableCamera ?? false;
    }
    else {
      return true;
    }
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  public static getHtml(config: FormlyFieldConfig, value: string) {
    const label = config.templateOptions.label ?? '';
    if (value) {
      const photos = value.split('|');
      let html =  `<ion-card>
                    <ion-item>
                      <ion-label>${label}</ion-label>
                    </ion-item>
                    <ion-grid><ion-row>`;
      for (const photo of photos) {
        html += `<ion-col size="6"><img src="${photo}"/></ion-col>`;
      }
      html += `</ion-row></ion-grid></ion-card>`;
      return html;
    }
    else {
      return `<ion-card><ion-item>
                <ion-label>${label}</ion-label>
              </ion-item></ion-card>`;
    }
  }

  ngOnInit() {
    this.onlineSub = this.online.online.subscribe(status => this.isOnline = status);

    if (this.model[this.key as string | number]) {
      if (this.model[this.key as string | number].startsWith('edit:')) {
        const src = this.model[this.key as string | number].slice(5);
        this.fieldService.setValue(src);
      }
      this.rebuildFromModel();
    }
    this.setReference(this.key as string, this.formId, this.formType);
    this.formControl.valueChanges.subscribe(newVal => {
      if (newVal === '') {
        this.rebuildFromModel();
      }
    });
  }

  ngOnDestroy() {
    this.onlineSub?.unsubscribe();
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Edit || type === TriggerType.Set) {
      this.fieldService.setValue(data);
      this.rebuildFromModel();
    }
    else if (type === TriggerType.Clear) {
      this.photos = [];
    }
    else if (type === TriggerType.Rebuild) {
      this.rebuildFromModel();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  /**
   * Take picture with camera
   */
  async takePicture() {
    if (this.reachedPhotoLimit()) {
      return;
    }

    if (this.device.isDevice()) {
      await this.getPhoto(true);
    }
    else if (this.isOnline && this.device.isMobile()) {
      this.selectPicture(true);
    }
    else if (!environment.production) {
      this.addTestPhoto(true);
    }
  }

  /**
   * Get picture from gallery
   */
  async getFromGallery() {
    if (this.reachedPhotoLimit()) {
      return;
    }
    if (this.device.isDevice()) {
      await this.getPhoto(false);
    }
    else if (this.isOnline) {
      this.selectPicture(false);
    }
    else if (!environment.production) {
      this.addTestPhoto(false);
    }
  }

  /**
   * Open modal with clicked photo
   * @param id Id of photo
   * @param src Photo src
   */
  async openPreview(id: number, src: string) {
    const modal = await this.modalCtrl.create({
      component: PhotoModalComponent,
      componentProps: {
        id: id,
        src: src,
        disabled: this.to.disabled
      }
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();
    if (data && data.deletePhoto) {
      this.removePhoto(id);
    }
  }

  /**
   * Remove photo
   * @param id Id of photo to remove
   */
  removePhoto(id: number) {
    if (this.to.disabled) return;

    this.photos = this.photos.filter(image => image.id !== id);
    this.updateModel();
  }

  convertUrlToBlob(url: string) {
    return new Promise<Blob>(resolve => {
      const img = new Image();
      const c = document.createElement('canvas');
      const ctx = c.getContext('2d');
      img.onload = () => {
        c.width = img.naturalWidth;
        c.height = img.naturalHeight;
        ctx.drawImage(img, 0, 0);
        c.toBlob((blob) => resolve(blob), 'image/jpeg', 0.75);
      };
      img.onerror = () => resolve(null);
      img.crossOrigin = '';
      img.src = url;
    });
  }

  /**
   * Used for testing in browser
   * @param camera True for camera, false for gallery
   */
  addTestPhoto(camera: boolean) {
    if (camera) {
      this.photos.push({id: this.photoId++, src: 'http://webfiler.tempus.no/muffinorder.jpg'});
    }
    else {
      this.photos.push({id: this.photoId++, src: 'http://webfiler.tempus.no/kropp.png'});
    }
    this.updateModel();
  }

  private reachedPhotoLimit() {
    if (this.photos.length >= this.photolimit) {
      this.translate.get(['PhotoLimit', 'PhotoLimitMsg'], {count: this.photolimit}).subscribe(trans => {
        this.popup.showAlert(trans['PhotoLimit'], trans['PhotoLimitMsg'], false);
      });
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Select picture using input with file selector
   * @param useCamera If it should set to use the camera
   */
  private async selectPicture(useCamera: boolean) {
    const modal = await this.modalCtrl.create({
      component: SelectPictureComponent,
      componentProps: {
        useCamera: useCamera
      }
    });
    await modal.present();
    this.isLoading = true;
    const {data} = await modal.onWillDismiss();
    if (data?.imagePath && data?.fileName) {
      const filepath: FilePath = {path: '', filename: data.fileName};
      const blob = await this.convertUrlToBlob(data.imagePath);
      if (blob) {
        this.uploadPicture(blob, filepath, true);
      }
      else {
        this.popup.showMessage('PictureUploadFailed', true);
        this.isLoading = false;
      }
    }
    else {
      this.isLoading = false;
    }
  }

  /**
   * Get picture from user and upload it. Stores it if uploads fails, e.g. not online
   * @param useCamera If the picture should be from camera or gallery
   */
  private async getPhoto(useCamera: boolean) {
    const {path: filePath, image} = useCamera ? await this.cameraService.getFromCamera(false)
                                              : await this.cameraService.getFromGallery();
    if (filePath) {
      this.uploadPicture(image, filePath, true);
    }
  }


  /**
   * Upload picture to server
   * @param blob The picture to upload
   * @param filePath The filepath object containing filename and path
   * @param storeOnFail If it should store the image in local storage on fail, or if it should just show error message
   */
  private async uploadPicture(blob: Blob, filePath: FilePath, storeOnFail: boolean) {
    this.isLoading = true;
    this.api.uploadPhoto(blob, filePath.filename).subscribe(async ({value: serverUrl}) => {
      this.isLoading = false;
      if (serverUrl) {
        this.logger.debug('Server url', serverUrl);
        this.photos.push({id: this.photoId++, src: serverUrl});
        this.updateModel();
      }
      else if (storeOnFail) {
        await this.storePhoto(filePath);
      }
      else {
        this.popup.showMessage('PictureUploadFailed', true);
      }
    });
  }

  /**
   * Store picture on phone
   * @param filePath The filepath object containing filename and path
   */
  private async storePhoto(filePath: FilePath) {
    try {
      const photoSrc = await this.cameraService.storePhoto(filePath);
      this.photos.push({id: this.photoId++, src: photoSrc});
      this.hasOfflinePhotos = true;

      this.updateModel();
    }
    catch (err) {
      this.logger.error('Error storing photo', err);
    }
  }

  /**
   * Update the model from the photos array
   */
  private updateModel() {
    let photoUrls = (this.photos.length === 0) ? '' : this.photos.map(p => p.src).join('|');
    if (this.hasOfflinePhotos) {
      photoUrls = 'offlinePhoto:' + photoUrls;
    }
    this.fieldService.setValue(photoUrls);
  }

  /**
   * Rebuild field from init model
   */
  private rebuildFromModel() {
    this.photos = [];
    let src: string = this.model[this.key as string | number];
    if (!src) {
      return;
    }
    if (src.startsWith('offlinePhoto:')) {
      this.hasOfflinePhotos = true;
      src = src.replace('offlinePhoto:', '');
    }
    for (const s of src.split('|')) {
      this.photos.push({id: this.photoId++, src: s});
    }
  }
}
