import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { FormType, Trigger, TriggerType } from 'src/app/models/models';
import { FieldWrapper } from '@ngx-formly/core';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { NGXLogger } from 'ngx-logger';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wrapper-scroll',
  templateUrl: './scroll-wrapper.component.html',
  styleUrls: ['./scroll-wrapper.component.scss'],
})
export class ScrollWrapperComponent extends FieldWrapper implements OnInit, Trigger {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) fieldComponent: ViewContainerRef;

  constructor(
    private ref: ComponentRefService,
    private logger: NGXLogger,
    private dom: DomOperationsService,
    private el: ElementRef
  ) {
    super();
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Focus) {
      const element = this.dom.findFieldElement(this.el.nativeElement);
      this.dom.scrollIntoViewAndExecute(element);
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong triggertype: ${type}`);
    }
  }

}
