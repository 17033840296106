import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { UtilityService } from 'src/app/services/utility.service';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { FieldService } from 'src/app/services/field.service';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { CachedData, DataSource, DataStatus, FormType, IonColor, Trigger, TriggerType } from 'src/app/models/models';
import { MarkdownComponent } from 'ngx-markdown';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ModalController } from '@ionic/angular';
import { TextModalComponent } from 'src/app/custom-components/directives/shared-directives/text-modal/text-modal.component';
import { Observable } from 'rxjs';
import { StateService } from 'src/app/services/state.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { environment } from 'src/environments/environment';

type Aggregate = {
  type: string,
  digits?: number,
  label?: string,
  includeBlanks?: boolean
} | string;

interface Header {
  key: string;
  label: string;
  sum?: boolean;
  avg?: boolean;
  aggregate?: Aggregate;
}

interface ValueCount {
  value: string;
  count: number;
}

@Component({
  selector: 'app-field-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class TableComponent extends FieldType implements OnInit, Trigger {
  @ViewChild('markdownEl') markdownEl: MarkdownComponent;
  lastCall: Date;
  table = '';
  loading = false;
  completed = true;
  isOverflowing = false;
  showOverflowArrow = false;
  private _header: Header[];

  constructor(
    private watch: WatchgroupService,
    private util: UtilityService,
    private logger: NGXLogger,
    private translate: TranslateService,
    private fieldService: FieldService,
    private cachedApi: CachedApiService,
    private domOp: DomOperationsService,
    private validation: ValidationService,
    private modalCtrl: ModalController,
    private state: StateService,
    private ref: ComponentRefService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get label(): string {
    return this.to.label || '';
  }

  get url(): string {
    return this.to.lookupUrl || '';
  }

  get path(): string {
    return this.to.jsonPath || '';
  }

  get header(): Header[] {
    return this._header;
  }

  set header(header: Header[]) {
    if (Array.isArray(header)) {
      this._header = header.map((h: Header) => {
        if (h.sum) {
          h.aggregate = {
            type: 'sum',
            label: ''
          };
        }
        else if (h.avg) {
          h.aggregate = {
            type: 'avg',
            label: ''
          };
        }
        return h;
      });
    }
    else {
      this._header = [];
    }
  }

  get reverse(): boolean {
    return this.to.reverseData ?? false;
  }

  get useModal(): boolean {
    return this.to.openInModal ?? false;
  }

  get onlyAggregate(): boolean {
    return !!this.to.onlyAggregate && this.header.length === 1 && this.legalAggregate(this.header[0].aggregate);
  }

  get showPrint(): boolean {
    return this.to.showPrint ?? false;
  }

  get buttonText(): string {
    return this.label || this.translate.instant('Open');
  }

  get color(): IonColor {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Primary;
  }

  get fill(): 'clear' | 'solid' | 'outline' {
    switch (this.to.fill) {
      case 'clear':
      case 'solid':
        return this.to.fill;
      default:
        return 'outline';
    }
  }

  get httpType(): 'GET' | 'POST' | 'PUT' | 'DELETE' {
    const type = this.to.httpType?.toUpperCase();
    switch (type) {
      case 'POST':
      case 'PUT':
      case 'DELETE':
        return type;
      default:
        return 'GET';
    }
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  public static getHtml(config: FormlyFieldConfig, value: string) {
    const label = config.templateOptions.label ?? '';
    return `<ion-card style="padding: 10px">
              <div><ion-label style="margin-bottom: 10px">${label}</ion-label></div>
              ${value}
            </ion-card>`;
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    this.header = this.to.header;
    this.runLookup();
    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);
    if (watchGroup.fieldKeys.length >0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe(() => this.runLookup());
    }
  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Update) {
      this.runLookup();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  checkOverflow() {
    setTimeout(() => {
      this.isOverflowing = this.domOp.isOverflowing(this.markdownEl?.element?.nativeElement);
      this.showOverflowArrow = this.isOverflowing;
    });
  }

  hideScrollArrow() {
    setTimeout(() => {
      this.showOverflowArrow = false;
    }, 1000);
  }

  runLookup() {
    if (this.to.disabled) {
      this.table = this.model[this.key as string | number];
    }
    else if (this.url) {
      this.runLookupWeb();
    }
    else if (this.to.data) {
      this.dataFromForm();
    }
  }

  dataFromForm() {
    const data = this.path ? this.util.dotRef(this.to.data, this.path) : this.to.data;
    if (Array.isArray(data) && data.length > 0) {
      this.table = this.createTable(data);
      this.fieldService.setValue(this.table);
    }
    else {
      this.fieldService.setValue('');
      this.translate.get('NoData').subscribe(trans => this.table = trans);
    }
  }

  runLookupWeb() {
    this.loading = true;
    this.completed = false;
    this.lastCall = new Date();
    let thisCall = new Date();

    const url = this.util.parseText(this.url, this.model, this.fields);
    let obs: Observable<CachedData<any>>;
    if (this.httpType === 'POST') {
      obs = this.cachedApi.getWebJsonWithPost(url, this.path, this.formId, this.to.headers, this.to.useCredentials);
    }
    else if (this.httpType === 'PUT') {
      obs = this.cachedApi.getWebJsonWithPut(url, this.path, this.formId, this.to.headers, this.to.useCredentials);
    }
    else if (this.httpType === 'DELETE') {
      obs = this.cachedApi.getWebJsonWithDelete(url, this.path, this.formId, this.to.headers, this.to.useCredentials);
    }
    else {
      obs = this.cachedApi.getWebJSON(url, this.path, this.formId, this.to.headers, this.to.useCredentials);
    }
    obs.subscribe(data => {

      if (thisCall < this.lastCall) return;
//console.log(data);
      if (data.source === DataSource.Storage) {
        this.lastCall = new Date();
        thisCall = new Date();
      }
      else {
        this.completed = true;
      }
      if (data.status === DataStatus.Updated) {
        if(data.value?.length > 0)
          this.loading = false;
        if (Array.isArray(data.value) && data.value.length > 0) {
          if (this.reverse) {
            data.value.reverse();
          }
          this.table = this.createTable(data.value);
          this.fieldService.setValue(this.table);
        }
        else if (data.source === DataSource.API) {
          this.loading = false;
          this.fieldService.setValue('');
          this.translate.get('NoData').subscribe(trans => this.table = trans);
        }
      }
      else if(data.status === DataStatus.Unchanged) {
        if (Array.isArray(data.value) && data.value.length > 0) {
          // already displayed
        }
        else {
          this.loading = false;
          this.fieldService.setValue('');
          this.translate.get('NoData').subscribe(trans => this.table = trans);
        }
      }
    });
  }

  createTable(data: any[]): string {
    data = this.util.filterList(data, this.to.jsonFilter);

    if (this.onlyAggregate) {
      return this.getAggregateValue(data, this.header[0].key, this.header[0].aggregate);
    }
    else {
      let table = '|' + this.header.map(h => h.label).join('|') + '|\n';
      table += '|:----'.repeat(this.header.length) + '|\n';
      if(data && data.length > 0) {

        for (const row of data) {
          table += '|' + this.header.map(h => this.util.dotRef(row, h.key, null, true)).join('|') + '|\n';
        }
      }
      else {
        const noData = (this.to.labelFilterNoData) ? this.to.labelFilterNoData:this.translate.instant('NoData');
        table += '|' + noData + '|\n';
      }
      if (this.header.find(h => this.legalAggregate(h.aggregate))) {
        this.logger.debug('Has legal aggregate function');
        table += '|-----'.repeat(this.header.length) + '|\n|';
        table += this.header.map(h => this.getAggregateValue(data, h.key, h.aggregate)).join('|');
        table += '|\n';
      }

      return table;
    }
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: TextModalComponent,
      componentProps: {
        title: this.label,
        text: this.table,
        showPrint: this.showPrint
      }
    });
    modal.present();
  }

  handleSwipe() {}

  /**
   * Get aggregated value for column
   * @param data The data array
   * @param key Key to the value in each data row
   * @param aggregate The aggregate definition
   */
  private getAggregateValue(data: any[], key: string, aggregate: Aggregate): string {
    if (!aggregate) {
      return '';
    }
    const includeBlanks = typeof aggregate === 'string' ? false : aggregate.includeBlanks;
    let value: number | ValueCount;
    switch (this.getAggregateType(aggregate)) {
      case 'sum':
        value = this.findSum(data, key);
        break;
      case 'avg':
        value = this.findAverage(data, key, includeBlanks);
        break;
      case 'max':
        value = this.findMaximum(data, key, includeBlanks);
        break;
      case 'min':
        value = this.findMinimum(data, key, includeBlanks);
        break;
      case 'median':
        value = this.findMedian(data, key, includeBlanks);
        break;
      case 'count':
        value = this.findCount(data, key, aggregate);
        break;
      case 'mostFreq':
        value = this.findMostFrequent(data, key);
        break;
      default:
        value = NaN;
    }
    return typeof value === 'number' ? this.formatNumberValue(value, aggregate) : this.formatValueCountValue(value, aggregate);
  }

  /**
   * Format aggregated number value with optional digits restriction and label
   * @param value The aggregated value to format
   * @param aggregate The aggregae definition
   */
  private formatNumberValue(value: number, aggregate: Aggregate) {
    if (isNaN(value)) {
      return '';
    }
    else {
      let valueString: string;
      let funcName: string;
      if (typeof aggregate === 'string') {
        valueString = value.toString(10);
        funcName = this.getFuncLabel(aggregate);
      }
      else {
        valueString = typeof aggregate.digits === 'number' ? value.toFixed(aggregate.digits) : value.toString(10);
        funcName = typeof aggregate.label === 'string' ? aggregate.label : this.getFuncLabel(aggregate.type);
      }
      return funcName ? `*${funcName}*: ${valueString}` : valueString;
    }
  }

  private formatValueCountValue(value: ValueCount, aggregate: Aggregate): string {
    const valueString = `${value.value} (${value.count})`;
    let funcName: string;
    if (typeof aggregate === 'string') {
      funcName = this.getFuncLabel(aggregate);
    }
    else {
      funcName = typeof aggregate.label === 'string' ? aggregate.label : this.getFuncLabel(aggregate.type);
    }
    return funcName ? `*${funcName}*: ${valueString}` : valueString;
  }

  private getFuncLabel(funcType: string) {
    if (this.onlyAggregate) {
      return '';
    }
    switch (funcType) {
      case'sum':
        return this.translate.instant('AggrSum');
      case 'avg':
        return this.translate.instant('AggrAvg');
      case 'min':
        return this.translate.instant('AggrMin');
      case 'max':
        return this.translate.instant('AggrMax');
      case 'median':
        return this.translate.instant('AggrMedian');
      case 'mostFreq':
        return this.translate.instant('AggrMostFreq');
      default:
        return '';
    }
  }

  /**
   * Sum the values of an column
   * @param data The data array
   * @param key Key to the value in each row
   */
  private findSum(data: any[], key: string): number {
    const values = this.getSortedNonNaNValues(data, key);
    if (values.length === 0) {
      return NaN;
    }
    else {
      return values.reduce((a, b) => a + b);
    }
  }

  /**
   * Find the average of the values in a column
   * @param data The data array
   * @param key Key to the value in each row
   */
  private findAverage(data: any[], key: string, includeBlanks: boolean): number {
    const values = this.getSortedNonNaNValues(data, key, includeBlanks);
    if (values.length === 0) {
      return NaN;
    }
    else {
      return values.reduce((a, b) => a + b) / values.length;
    }
  }

  /**
   * Find the maximun of the values in a column
   * @param data The data array
   * @param key Key to the value in each row
   */
  private findMaximum(data: any[], key: string, includeBlanks: boolean): number {
    const values = this.getSortedNonNaNValues(data, key, includeBlanks);
    return values.length === 0 ? NaN : values[values.length - 1];
  }

  /**
   * Find the minimum of the values in a column
   * @param data The data array
   * @param key Key to the value in each row
   */
  private findMinimum(data: any[], key: string, includeBlanks: boolean): number {
    const values = this.getSortedNonNaNValues(data, key, includeBlanks);
    return values.length === 0 ? NaN : values[0];
  }

  /**
   * Find the median of the values in a column
   * @param data The data array
   * @param key Key to the value in each row
   */
  private findMedian(data: any[], key: string, includeBlanks: boolean): number {
    const values = this.getSortedNonNaNValues(data, key, includeBlanks);
    if (values.length === 0) {
      return NaN;
    }
    else if (values.length % 2 === 0) {
      const index = values.length / 2;
      return (values[index] + values[index - 1]) / 2;
    }
    else {
      const index = Math.floor(values.length / 2);
      return values[index];
    }
  }

  /**
   * Find how many times an value occurs in an column, finds it by using aggregate.label
   * @param data The data array
   * @param key Key to the value in each row
   * @param aggregate The aggregate definition
   */
  private findCount(data: any[], key: string, aggregate: Aggregate): number {
    if (typeof aggregate === 'string' || !aggregate.label) {
      return NaN;
    }
    let count = 0;
    for (const row of data) {
      const value = `${this.util.dotRef(row, key)}`.trim();
      if (value === aggregate.label.trim()) {
        count++;
      }
    }
    return count;
  }

  /**
   * Find the value that is most frequent and how many times it occurs
   * @param data The data array
   * @param key Key to the value in each row
   */
  private findMostFrequent(data: any[], key: string): ValueCount {
    let values = data.map(d => `${this.util.dotRef(d, key)}`);
    values = this.util.uniqueValues(values);
    const counts: ValueCount[] = values.map<ValueCount>(value => ({
      value: value,
      count: this.findCount(data, key, {label: value, type: 'count'})
    })).sort((a, b) => b.count - a.count);
    return counts[0];
  }

  /**
   * Get all the number values from an column sorted in ascending order
   * @param data The data array
   * @param key Key to the value in each column
   */
  private getSortedNonNaNValues(data: any[], key: string, includeBlanks = false): number[] {
    const defaultValue = includeBlanks ? 0 : NaN;
    return data.map(d => {
      const value = this.util.dotRef(d, key);
      return (typeof value === 'undefined' || value === null) ? defaultValue : +value;

    }).filter(d => !isNaN(d)).sort((a, b) => a - b);
  }

  /**
   * Check if an aggregate definition has an legal aggregate function type
   * @param aggregate The aggregate definition
   */
  private legalAggregate(aggregate: Aggregate): boolean {
    switch (this.getAggregateType(aggregate)) {
      case 'sum':
      case 'avg':
      case 'min':
      case 'max':
      case 'median':
      case 'count':
      case 'mostFreq':
        return true;
      default:
        return false;
    }
  }

  /**
   * Get the aggregate function type
   * @param aggregate The aggregate definition
   */
  private getAggregateType(aggregate: Aggregate): string {
    if (typeof aggregate === 'string') {
      return aggregate;
    }
    else {
      return aggregate?.type ?? '';
    }
  }

}
