
<ion-header >
  <ion-toolbar color="header">
    <ion-title>{{formNameTitle}}</ion-title>
    <ion-buttons slot="end">
      <ion-button id="clearBtn" fill="clear" slot="end" (click)="clearForm()" *ngIf="showClearBtn && formFound">
        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>  
      </ion-button>
      <ion-button id="closeBtn" fill="clear" slot="end" (click)="closeModal(false)">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>  
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content id="formWrapper" class="in-background" (swipe)="handleSwipe($event)">
  <ion-card class="formCard">
    <ion-card-content>
      <form *ngIf="formFound" [formGroup]="formGroup" id="subForm" >
        <formly-form [form]="formGroup" [fields]="fields" [model]="model"></formly-form>
      </form>
      <div *ngIf="!fields && !formNotFound" class="">
        <ion-skeleton-text animated class="skeleton-title" ></ion-skeleton-text>
        <ion-skeleton-text  class="skeleton-text" ></ion-skeleton-text>
        <ion-skeleton-text  class="skeleton-text" ></ion-skeleton-text>
        <ion-skeleton-text  class="skeleton-text" ></ion-skeleton-text>
        <ion-skeleton-text animated class="skeleton-title" ></ion-skeleton-text>
        <ion-skeleton-text class="skeleton-text" ></ion-skeleton-text>
        <ion-skeleton-text  class="skeleton-text" ></ion-skeleton-text>
        <ion-skeleton-text  class="skeleton-text" ></ion-skeleton-text>
      </div>
    </ion-card-content>
      <ion-item *ngIf="formNotFound">
        <span>{{'FormNotFound' | translate}}</span>
      </ion-item>
  </ion-card>

  <ion-footer class="ion-no-border" *ngIf="showSaveBtn && formFound">
    <div style="text-align: center;" >

        <ion-button class="saveModalBtn" (click)="closeModal(true)">{{saveBtnLabel | translate}}</ion-button>
    </div>
  </ion-footer>
</ion-content>