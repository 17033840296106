import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { DeviceService } from './device.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(
    private browser: InAppBrowser,
    private webview: WebView,
    private device: DeviceService,
    private clipboard: Clipboard
  ) { }

  /**
   * Opens an URL in mobiles browser or in a new tab
   * @param url The url to open
   */
  openUrl(url: string) {
    if (this.device.isDevice()) {
      this.browser.create(url, '_system', 'hidden=yes;location:no');
    }
    else {
      window.open(url, '_blank');
    }
  }

  /**
   * Copy an text to the clipboard
   * @param text The text to copy
   */
  copyToClipboard(text: string): Promise<void> {
    if (this.device.isDevice()) {
      return this.clipboard.copy(text);
    }
    else if (navigator.clipboard) {
      return navigator.clipboard.writeText(text);
    }
    else {
      return Promise.reject(new Error('No access to clipboard'));
    }
  }

  /**
   * Converts file:// to http:// on localhost
   * @param fileUrl The file url
   */
  getBrowserUrlFromFile(fileUrl: string) {
    return this.webview.convertFileSrc(fileUrl);
  }

  /**
   * Create an local URL for an image.
   * @param image  The image to create an URL for
   */
  createImageUrl(image: Blob) {
    return URL.createObjectURL(image);
  }

  /**
   * Get the value of an query parameter from an URL
   * @param url The full url or just the query string
   * @param name The name of the parameter
   */
  getQueryParameter(url: string, name: string) {
    if (!url || !name) {
      return null;
    }
    if (url.includes('?')) {
      url = url.split('?').slice(1).join('?');
    }
    const param = url.split('&').find(p => p.startsWith(name));
    return param?.split('=')[1];
  }

  /**
   * Reload app/website using page refresh
   */
  reload() {
    window.location.reload();
  }
}
