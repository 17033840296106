import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormType, IonColor, Trigger, TriggerType } from 'src/app/models/models';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { NGXLogger } from 'ngx-logger';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MarkdownComponent } from 'ngx-markdown';
import { DomOperationsService } from 'src/app/services/dom-operations.service';
import { LegacyService } from 'src/app/services/legacy.service';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'src/app/services/validation.service';
import { TextModalComponent } from 'src/app/custom-components/directives/shared-directives/text-modal/text-modal.component';

@Component({
  selector: 'app-field-servermessage',
  templateUrl: './servermessage.component.html',
  styleUrls: ['./servermessage.component.scss'],
})
export class ServermessageComponent extends FieldType implements OnInit, Trigger {
  @ViewChild('markdownEl') markdownEl: MarkdownComponent;
  isOverflowing = false;
  showOverflowArrow = false;

  constructor(
    private ref: ComponentRefService,
    private logger: NGXLogger,
    private translate: TranslateService,
    private domOp: DomOperationsService,
    private legacy: LegacyService,
    private validation: ValidationService,
    private modalCtrl: ModalController
  ) {
    super();
  }

  get text(): string {
    return this.model[this.key as string | number] || '';
  }

  get label(): string {
    return this.to.label ?? '';
  }

  get useModal(): boolean {
    return this.to.openInModal ?? false;
  }

  get showPrint(): boolean {
    return this.to.showPrint ?? false;
  }

  get buttonText(): string {
    return this.label || this.translate.instant('Open');
  }

  get color(): IonColor {
    return this.validation.validColor(this.to.colorType) ? this.to.colorType : IonColor.Primary;
  }

  get formId(): number {
    return this.to?.currentForm.id;
  }

  get formType(): FormType {
    return this.to?.currentForm?.type;
  }

  get fill(): 'clear' | 'solid' | 'outline' {
    switch (this.to.fill) {
      case 'clear':
      case 'solid':
        return this.to.fill;
      default:
        return 'outline';
    }
  }


  public static getHtml(_config: FormlyFieldConfig, value: string) {
    return value ? `<ion-card style="padding: 10px">${value}</ion-card>` : '';
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
  }


  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Set) {
      this.model[this.key as string | number] = this.fixData(data);
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  checkOverflowing() {
    setTimeout(() => {
      this.isOverflowing = this.domOp.isOverflowing(this.markdownEl?.element?.nativeElement);
      this.showOverflowArrow = this.isOverflowing;
    });
  }

  hideScrollArrow() {
    setTimeout(() => {
      this.showOverflowArrow = false;
    }, 1000);
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: TextModalComponent,
      componentProps: {
        title: this.label,
        text: this.text,
        showPrint: this.showPrint
      }
    });
    modal.present();
  }

  handleSwipe() {}


  /**
   * Fix incoming data
   * @param data The data to fix
   */
  private fixData(data: string): string {
    data = this.legacy.fixMarkdownButtons(data);
    return data;
  }
}
