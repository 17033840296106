import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfflineModalComponent } from './offline-modal/offline-modal.component';
import { OnlineStatusComponent } from './online-status/online-status.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SetupModalComponent } from './setup-modal/setup-modal.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ChangePictureComponent } from './change-picture/change-picture.component';
import { FormViewComponent } from './form-view/form-view.component';
import { LoggerModule } from 'ngx-logger';
import { OfflineRegComponent } from './offline-reg/offline-reg.component';
import { SendFeedbackComponent } from './send-feedback/send-feedback.component';
import { PageMenuComponent } from './page-menu/page-menu.component';
import { PageMenuButtonComponent } from './page-menu-button/page-menu-button.component';
import { ItemInfoComponent } from './item-info/item-info.component';
import { BoxListComponent } from './box-list/box-list.component';
import { RouterModule } from '@angular/router';
import { EllipsisModule } from 'ngx-ellipsis';
import { ChangeProjectComponent } from './change-project/change-project.component';
import { NoArrowIonselectDirective } from './no-arrow-ionselect/no-arrow-ionselect.directive';
import { BoxHeightPopoverComponent } from './box-height-popover/box-height-popover.component';
import { HintsComponent } from './hints/hints.component';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { FormlyModule } from '@ngx-formly/core';
import { SharedDirectivesModule } from '../shared-directives/shared-directives.module';
import { CopyModalComponent } from './copy-modal/copy-modal.component';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { PrivacyInfoComponent } from './privacy-info/privacy-info.component';
import { NotificationsModalComponent } from './notifications-modal/notifications-modal.component';
import { SwiperModule } from 'swiper/angular';
import { AppSettingsItemComponent } from './app-settings-item/app-settings-item.component';

@NgModule({
  declarations: [
    SetupModalComponent,
    OfflineModalComponent,
    OnlineStatusComponent,
    UserSettingsComponent,
    AppSettingsComponent,
    ChangePasswordComponent,
    ChangePictureComponent,
    FormViewComponent,
    OfflineRegComponent,
    SendFeedbackComponent,
    PageMenuComponent,
    PageMenuButtonComponent,
    ItemInfoComponent,
    BoxListComponent,
    ChangeProjectComponent,
    NoArrowIonselectDirective,
    BoxHeightPopoverComponent,
    HintsComponent,
    CopyModalComponent,
    MenuButtonComponent,
    PrivacyInfoComponent,
    NotificationsModalComponent,
    AppSettingsItemComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    LoggerModule.forChild(),
    RouterModule,
    EllipsisModule,
    FormlyIonicModule,
    FormlyModule.forChild(),
    SharedDirectivesModule,
    SwiperModule
  ],
  exports: [
    SetupModalComponent,
    OfflineModalComponent,
    OnlineStatusComponent,
    UserSettingsComponent,
    AppSettingsComponent,
    ChangePasswordComponent,
    ChangePictureComponent,
    FormViewComponent,
    OfflineRegComponent,
    SendFeedbackComponent,
    PageMenuComponent,
    PageMenuButtonComponent,
    ItemInfoComponent,
    BoxListComponent,
    ChangeProjectComponent,
    BoxHeightPopoverComponent,
    HintsComponent,
    CopyModalComponent,
    MenuButtonComponent,
    PrivacyInfoComponent,
    AppSettingsItemComponent
  ],
  providers: []
})
export class PageDirectivesModule { }
