import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { NGXLogger } from 'ngx-logger';
import { FormType, TriggerType, Trigger } from 'src/app/models/models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-field-last-reg',
  templateUrl: './last-reg-field.component.html',
  styleUrls: ['./last-reg-field.component.scss'],
})
export class LastRegFieldComponent extends FieldType implements OnInit, Trigger {
  lastUpdate: Date;

  get formId(): number {
    return this.to.currentForm?.id;
  }
  get projectId(): number {
    return this.to.currentForm?.projectId;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  constructor(
    private ref: ComponentRefService,
    private logger: NGXLogger
  ) {
    super();
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
  }

  setReference(key: string, id: number, type: FormType) {
    if (this.formType) {
      this.ref.addReference(key, id, type, this);
    }
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Update) {
      this.lastUpdate = new Date();
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

}
