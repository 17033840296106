import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormService } from 'src/app/services/form.service';
import { OnlineService } from 'src/app/services/online.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { PopupService } from 'src/app/services/popup.service';
import { InStorageService } from 'src/app/services/in-storage.service';
import { StateService } from 'src/app/services/state.service';
import { CachedApiService } from 'src/app/services/cached-api.service';
import { FormType, DataStatus, FormlyPage, RegSuccess, Form, DataSource, FormWithValues } from 'src/app/models/models';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-setup-modal',
  templateUrl: './setup-modal.component.html',
  styleUrls: ['./setup-modal.component.scss'],
  providers: [FormService]
})
export class SetupModalComponent implements OnInit, OnDestroy, FormlyPage {
  @Input() hasChanged = false;
  untouchedForm: Form;
  form: Form;
  fields: FormlyFieldConfig[];
  foundForm = false;
  model = {};
  formGroup = new UntypedFormGroup({});
  options: FormlyFormOptions = {};
  loading = false;
  setupStoreDate: string;

  isOnline: boolean;
  onlineSub: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private formService: FormService,
    private onlineService: OnlineService,
    private translate: TranslateService,
    private popup: PopupService,
    private storage: InStorageService,
    private stateService: StateService,
    private cachedApi: CachedApiService,
    private logger: NGXLogger
  ) {
    this.formService.init(this, FormType.Setup);
  }

  ngOnInit() {
    this.onlineSub = this.onlineService.online.subscribe(status => this.isOnline = status);

    this.foundForm = false;
    this.formGroup = new UntypedFormGroup({});

    this.loading = true;
    this.cachedApi.getSetupForm().subscribe(async ({value: form, source, status}) => {

      if (source === DataSource.Storage && form?.formSavedAt) {
        await this.initForm(form);
        this.loading = false;
      }
      else if (source === DataSource.API && form && (status === DataStatus.Updated || !this.foundForm)) {
        await this.initForm(form);
        this.loading = false;
      }
      else if (source === DataSource.API) {
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.onlineSub?.unsubscribe();
  }

  /**
   * Initialize a form
   * @param form The form to initialize
   */
  async initForm(form: FormWithValues) {
    try {
      this.model = {};
      this.formGroup = new UntypedFormGroup({});
      await this.formService.initForm(form);
      this.setupStoreDate = form.formSavedAt;
      this.foundForm = true;
    }
    catch (err) {
      this.logger.error('Error initializing setup form', err);
    }
  }

  async closeModal(save: boolean) {
    if (!save) {
      this.dismiss(false);
    }
    else if (this.isOnline) {
      const changed = !this.formGroup.pristine;
//      console.log('changed ',changed); //TODO not correct?
      const reg = await this.formService.createReg(this.model, this.fields, false);
      if (reg) {
        const res: RegSuccess = await this.formService.doReg(reg.reg, false);
        if (!res.success) {
          const header: string = this.translate.instant('SetupFailed');
          await this.popup.showAlert(header, res.errorMessage, false);
          this.dismiss(false);
        }
        else {
          const project = await this.storage.getProjectFromNumber(this.model['Prosjekt']);
          if (project) {
            this.stateService.currentProject = project;
            this.storage.setCurrentProject(project);
          }
          if (this.setupStoreDate) {
            this.storage.setSetupStoreDate(this.setupStoreDate);
          }
          this.dismiss(changed);
        }
      }
    }
    else if (!this.isOnline) {
      this.popup.showMessage('SetupOffline', true, 'secondary', 2000);
      this.dismiss(false);
    }
    else {
      this.dismiss(false);
    }
  }

  // Disable click in offline
  disabled(event?: any) {
    if (event && !this.isOnline) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private dismiss(changed: boolean) {
    this.modalCtrl.dismiss({changed}).then(
      () => this.logger.debug('Successfully dismissed setup modal'),
      (err) =>  this.logger.warn('Error dismissing setup modal', err)
    );
  }
}
