import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { InStorageService } from './in-storage.service';
import { Observable } from 'rxjs';
import { UtilityService } from './utility.service';
import { Form, CachedData, FormWithValues, DataSource, DataStatus, ApiStatus, OldReg, Project, SortBy,
         StylePaths, StyleData, LookupValue, UserProfile, ApiData, StorageValue, Context, OnlineStatus } from '../models/models';
import { isFormWithValues, isStorageValue } from '../helpers/helperFunctions';
import { OnlineService } from './online.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { StateService } from './state.service';

/**
 * Service for getting data from storage first and then possible from API
 */
@Injectable({
  providedIn: 'root'
})
export class CachedApiService {

  constructor(
    private api: ApiService,
    private storage: InStorageService,
    private util: UtilityService,
    private online: OnlineService,
    private auth: AuthService,
    private state: StateService
  ) { }

  /**
   * First get  form from storage and then get form from API
   * @param id The id of the form
   */
  getForm(id: number): Observable<CachedData<FormWithValues>> {
    return this.getValues(this.storage.getForm(id),
                          this.api.getForm(id, false),
                          (value) => this.storage.setForm(id, value),
                          '');
  }

  /**
   * Get field count of an form
   * @param id The id of the form
   */
  getFieldCount(id: number): Observable<CachedData<number>> {
    return this.getValues(this.storage.getFieldCount(id),
                          this.api.getFieldCount(id, false),
                          (value) => this.storage.addFieldCount(id, value),
                          '');
  }

  /**
   * First get setup form from storage and then get setup form from API
   */
  getSetupForm(): Observable<CachedData<FormWithValues>> {
    return this.getValues(this.storage.getSetup(),
                          this.api.getSetupForm(false),
                          (value) => this.storage.setSetup(value),
                          '',
                          null,
                          null,
                          true);
  }

  /**
   * First get forms from storage and then get forms from API
   * @param projectId (Optional) Only get forms from storage set under this project
   * @param modifiedAfter (Optional) If given it will give storage unchanged status if the storage value was saved before this
   */
  getForms(projectId?: number, modifiedAfter?: Date | string): Observable<CachedData<Form[]>> {
    modifiedAfter = this.checkTimestamp(modifiedAfter);
    return this.getValues(modifiedAfter ? this.storage.getFormsWithTimestamp(projectId) : this.storage.getForms(projectId),
                          this.api.getForms(false),
                          (value) => this.storage.setForms(value, projectId),
                          '',
                          null,
                          modifiedAfter);
  }

  /**
   * First get last used forms from storage and then get last used forms from API
   * @param projectId Id of project to filter by
   * @param modifiedAfter (Optional) If given it will give storage unchanged status if the storage value was saved before this
   */
  getLastUsedForms(projectId: number, modifiedAfter?: Date | string): Observable<CachedData<Form[]>> {
    modifiedAfter = this.checkTimestamp(modifiedAfter);
    return this.getValues(modifiedAfter ? this.storage.getLastUsedFormsWithTimestamp(projectId) : this.storage.getLastUsedForms(projectId),
                          this.api.getLastUsedForms(projectId, false),
                          (value) => this.storage.setLastUsedForms(value, projectId),
                          '',
                          null,
                          modifiedAfter);
  }

  /**
   * First get favorite forms from storage, then get forms by ids from API
   * @param ids Get a list of forms with given formIds.
   * @param projectId (Optional) Only get forms from storage set under this project
   * @param modifiedAfter (Optional) If given it will give storage unchanged status if the storage value was saved before this
   */
  getFormsById(ids: string, projectId?: number, modifiedAfter?: Date | string): Observable<CachedData<Form[]>> {
    modifiedAfter = this.checkTimestamp(modifiedAfter);
    return this.getValues(modifiedAfter ? this.storage.getFavoriteFormsWithTimestamp(projectId) : this.storage.getFavoriteForms(projectId),
                          this.api.getFormsById(ids, false),
                          (value) => this.storage.setFavoriteForms(value, projectId),
                          '',
                          null,
                          modifiedAfter);
  }

  /**
   * First get registration from storage and then get registration from API
   * @param id The id of the registration
   */
  getRegistration(id: number): Observable<CachedData<FormWithValues>> {
    return this.getValues(this.storage.getRegistration(id),
                          this.api.getRegistration(id, false),
                          (value) => this.storage.setRegistration(id, value),
                          '');
  }

  /**
   * First get registrations from storage and then get registrations from API
   * @param projectId (Optional) Project id to filter registrations by
   * @param modifiedAfter (Optional) If given it will give storage unchanged status if the storage value was saved before this
   */
  getRegistrations(projectId?: number, modifiedAfter?: Date | string): Observable<CachedData<OldReg[]>> {
    modifiedAfter = this.checkTimestamp(modifiedAfter);
    return this.getValues(modifiedAfter ? this.storage.getHistoryWithTimestamp(projectId) : this.storage.getHistory(projectId),
                          this.api.getRegistrations(projectId, false),
                          (value) => this.storage.setHistory(value, projectId),
                          '',
                          null,
                          modifiedAfter);
  }

  /**
   * First get the last registration for an given form from storage, then from API
   * @param formId Id of the form
   * @param projectId (Optional) Give this to filter registrations by project id
   */
  getLastRegistration(formId: number, projectId?: number): Observable<CachedData<OldReg>> {
    return this.getValues(this.storage.getLastRegistration(formId, projectId),
                          this.api.getLastRegistration(formId, projectId, false),
                          (value) => this.storage.setLastRegistration(value, formId, projectId),
                          '');
  }

  /**
   * First get current project from storage and then get current project from API
   */
  getCurrentProject(): Observable<CachedData<Project>> {
    return this.getValues(this.state.currentProject ? Promise.resolve(this.state.currentProject) : this.storage.getCurrentProject(),
                          this.api.getCurrentProject(false),
                          (value) => this.storage.setCurrentProject(value),
                          '');
  }

  /**
   * First get projects from storage and then get projects from API
   * @param sortBy (Optional) How the projects should be sorted, default: NoSort
   * @param modifiedAfter (Optional) If given it will give storage unchanged status if the storage value was saved before this
   */
  getProjects(sortBy: SortBy = SortBy.NoSort, modifiedAfter?: Date | string): Observable<CachedData<Project[]>> {
    modifiedAfter = this.checkTimestamp(modifiedAfter);
    return this.getValues(modifiedAfter ? this.storage.getProjectsWithTimestamp(sortBy) : this.storage.getProjects(sortBy),
                          this.api.getProjects(sortBy, false),
                          (value) => this.storage.setProjects(value),
                          '',
                          null,
                          modifiedAfter);
  }

  /**
   *  First get label for project field from storage, then get from API
   * @param modifiedAfter (Optional) If given it will give storage unchanged status if the storage value was saved before this
   */
  getProjectLabel(modifiedAfter?: Date | string): Observable<CachedData<string>> {
    modifiedAfter = this.checkTimestamp(modifiedAfter);
    return this.getValues(modifiedAfter ? this.storage.getProjectLabelWithTimestamp() : this.storage.getProjectLabel(),
                          this.api.getProjectlabel(false),
                          (value) => this.storage.setProjectLabel(value),
                          '',
                          null,
                          modifiedAfter);
  }

  /**
   * First get data for use when styling with API from styleForm from storage, then get from API
   * @param url The url to where to find style data
   * @param paths The paths to the different parts of the style
   * @param keys Keys of the fields in the form
   */
  getStyleData(url: string, paths: StylePaths, keys: string[], formId: number): Observable<CachedData<StyleData[]>> {
    return this.getValues(this.storage.getStyleData(url),
                          this.api.getStyleData(url, paths, keys, formId, false),
                          (value) => this.storage.setStyleData(url, value, formId),
                          this.util.getEndpoint(url));
  }

  /**
   * First get JSON from storage, then get JSON from an API using GET request
   * @param url Url to the API
   * @param jsonPath Path to object in JSON
   * @param headers (Optional) HTTP headers to use, default is not including any headers
   * @param useCredentials (Optional) If the call should use credentials, default: false
   */
  getWebJSON(url: string, jsonPath: string, formId: number, headers?: any, useCredentials?: boolean): Observable<CachedData> {
    return this.getValues(this.storage.getWithKey(url),
                          this.api.getWebJSON(url, formId, headers, useCredentials, false),
                          (value) => this.storage.setWithKey(url, value, formId),
                          this.util.getEndpoint(url),
                          jsonPath);
  }

  /**
   * First get JSON from storage, then get JSON from an API using POST request
   * @param url Url to the API
   * @param jsonPath Path to object in JSON
   * @param headers (Optional) HTTP headers to use, default is not including any headers
   * @param useCredentials (Optional) If the call should use credentials, default: false
   */
  getWebJsonWithPost(url: string, jsonPath: string, formId: number, headers?: any, useCredentials?: boolean): Observable<CachedData> {
    return this.getValues(this.storage.getWithKey(url),
                          this.api.postWebJSON(url, {}, headers, useCredentials),
                          (value) => this.storage.setWithKey(url, value, formId),
                          this.util.getEndpoint(url),
                          jsonPath);
  }

  /**
   * First get JSON from storage, then get JSON from an API using PUT request
   * @param url Url to the API
   * @param jsonPath Path to object in JSON
   * @param headers (Optional) HTTP headers to use, default is not including any headers
   * @param useCredentials (Optional) If the call should use credentials, default: false
   */
  getWebJsonWithPut(url: string, jsonPath: string, formId: number, headers?: any, useCredentials?: boolean): Observable<CachedData> {
    return this.getValues(this.storage.getWithKey(url),
                          this.api.putWebJSON(url, {}, headers, useCredentials),
                          (value) => this.storage.setWithKey(url, value, formId),
                          this.util.getEndpoint(url),
                          jsonPath);
  }

  /**
   * First get JSON from storage, then get JSON from an API using DELETE request
   * @param url Url to the API
   * @param jsonPath Path to object in JSON
   * @param headers (Optional) HTTP headers to use, default is not including any headers
   * @param useCredentials (Optional) If the call should use credentials, default: false
   */
  getWebJsonWithDelete(url: string, jsonPath: string, formId: number, headers?: any, useCredentials?: boolean): Observable<CachedData> {
    return this.getValues(this.storage.getWithKey(url),
                          this.api.deleteWebJSON(url, headers, useCredentials),
                          (value) => this.storage.setWithKey(url, value, formId),
                          this.util.getEndpoint(url),
                          jsonPath);
  }

  /**
   * First get JSON from an lookup table, then get from API
   * @param table Name of the lookup table
   * @param parent (Optional) Name of parent table to filter by
   */
  getLookupJson(table: string, parent?: string): Observable<CachedData<LookupValue[]>> {
    return this.getValues(this.storage.getLookups(table, parent),
                          this.api.getLookupJson(table, parent, false),
                          (value) => this.storage.setLookups(value, table, parent),
                          '');
  }

  /**
   * First get user profile from storage, then from API
   */
  getCurrentUserProfile(): Observable<CachedData<UserProfile>> {
    return this.getValues(this.storage.getUserProfile(),
                          this.api.getCurrentUserProfile(false),
                          (value) => this.storage.setUserProfile(value),
                          '');
  }

  /**
   * Get user context
   */
  getUserContext(): Observable<CachedData<Context>> {
    return this.getValues(this.storage.getUserContext(),
                          this.api.getUserContext(false),
                          (value) => this.storage.setUserContext(value),
                          '');
  }

  getAllowHomeEdit(): Observable<CachedData<boolean>> {
    return this.getValues(this.storage.getAllowHomeEdit(),
                          this.api.getAllowHomeEdit(undefined, false),
                          (value) => this.storage.setAllowHomeEdit(value),
                          '');
  }

  /**
   * Get first stored value and then API value. Will store the API value if it's different than the stored value
   * @param storage The storage promise getting the stored value
   * @param api The API observable getting the API value
   * @param setStorage Callback for setting value in storage if changed
   * @param endpoint Endpoint for external APIs, give `'` for internal APIs
   * @param jsonPath (Optional) Path to sub-object in returned API object
   * @param timestamp (Optional) If given it will give storage unchanged status if the storage value was saved before this
   * @param isForm (Optional) If it is of form type with form version number, default: false
   */
  private getValues<T>(
    storage: Promise<T | StorageValue<T>>,
    api: Observable<ApiData<T>>,
    setStorage: (value: T) => void,
    endPoint: string,
    jsonPath?: string,
    timestamp?: Date,
    isForm: boolean = false
  ): Observable<CachedData<T>> {
    return new Observable<CachedData<T>>(obs => {
      storage.then((storedData: T | StorageValue<T>) => {
        const storageVal = this.getReturnStorageVal<T>(storedData, timestamp);
        const storeCopy = this.util.createCopyOfObject(storageVal.value);
        obs.next(storageVal);
        this.auth.waitForAuth().then(() => {
          api.subscribe(data => {
            if (jsonPath) {
              data.value = this.util.dotRef(data.value, jsonPath);
            }
            const returnVal = this.getReturnApiVal(storeCopy, data, isForm, endPoint);
            if (returnVal.status === DataStatus.Updated) {
              setStorage(returnVal.value);
            }
            obs.next(returnVal);
          });
        });
      });
    });
  }

  /**
   * Get return value for the Storage source
   * @param data The returned data from storage
   * @param timestamp (Optional) Timestamp to check if value from storage contains timestamp
   */
  private getReturnStorageVal<T>(data: T | StorageValue<T>, timestamp?: Date): CachedData<T> {
    if (isStorageValue(data)) {
      return {
        value: data.value,
        source: DataSource.Storage,
        status: data.timestamp > timestamp ? DataStatus.Updated : DataStatus.Unchanged,
        storeDate: data.timestamp
      };
    }
    else {
      return {
        value: data,
        source: DataSource.Storage,
        status: DataStatus.Updated
      };
    }
  }

  /**
   * Get return value for the API source
   * @param storedVal The value from storage
   * @param apiVal The value returned from the ApiService
   * @param isForm If it is of form type with form saved at
   * @param internalApi Whether the API called is an internal API (e.g. TempusIN backend)
   */
  private getReturnApiVal<T>(storedVal: T, apiVal: ApiData<T>, isForm: boolean, endpoint: string): CachedData<T> {
    let val: CachedData<T>;
    if (apiVal.status === ApiStatus.Failed) {
      let value: T;
      let status: DataStatus;

      let error: HttpErrorResponse;
      if (!this.isEmpty(storedVal) && apiVal.error) {
        value = storedVal;
        status = DataStatus.Unchanged;
        const onlineStatus = !endpoint ? OnlineStatus.InternalFail : OnlineStatus.ExternalFail;
        this.online.goOffline(onlineStatus, apiVal.error.status, endpoint);
      }
      else {
        value = apiVal.value;
        status = DataStatus.Error;
        error = apiVal.error;
      }
      val = {
        value: value,
        status: status,
        source: DataSource.API
      };
      if (error) {
        val.error = error;
      }
    }
    else if (apiVal.status === ApiStatus.Offline || this.isEqual(storedVal, apiVal.value, isForm)) {
      val = {
        value: this.isEmpty(apiVal.value) ? storedVal : apiVal.value,
        status: DataStatus.Unchanged,
        source: DataSource.API
      };
    }
    else {
      val = {
        value: apiVal.value,
        status: DataStatus.Updated,
        source: DataSource.API
      };
    }
    return val;
  }

  /**
   * Check if two values are equal
   * @param val1 The first value
   * @param val2 The second value
   * @param useFormSavedAt If the values are forms with form saved at
   */
  private isEqual(val1: any, val2: any, useFormSavedAt: boolean) {
    if (val1 === val2) {
      return true;
    }
    else if (useFormSavedAt && isFormWithValues(val1) && isFormWithValues(val2)) {
      return val1.formSavedAt === val2.formSavedAt && this.util.deepEqual(val1.values, val2.values);
    }
    else {
      return this.util.deepEqual(val1, val2);
    }
  }

  /**
   * If necessary convert string to Date and then check if the timestamp is legal.
   * @param timestamp The timstamp to check
   */
  private checkTimestamp(timestamp: Date | string): Date {
    if (typeof timestamp === 'string') {
      timestamp = new Date(timestamp);
    }
    if (isNaN(timestamp?.getTime())) {
      return null;
    }
    else {
      return timestamp;
    }
  }

  /**
   * Check it it as an empy value, e.g. `undefined`, `null` or `[]`
   * @param value The value to check
   */
  private isEmpty(value: any) {
    if (typeof value === 'undefined' || value === null) {
      return true;
    }
    else if (Array.isArray(value)) {
      return value.length === 0;
    }
    else {
      return false;
    }
  }
}
