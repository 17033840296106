<ion-header>
  <ion-toolbar color="header">
    <ion-title>{{'OfflineAppTitle' | translate}}</ion-title>
    <ion-button fill="clear" slot="end" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="in-background">
  <ion-card>
    <ion-list class="wrapper">
      <ion-item lines="none">
        <ion-label>{{'OfflineAppMsg' | translate:translateParam}}</ion-label>
      </ion-item>
      <ion-item button lines="full" *ngFor="let reg of regs" class="form-item" (click)="openReg(reg.index)">
        <ion-icon class="formIcon" name="{{reg.icon}}" slot="start"></ion-icon>
        <ion-label><b>{{reg.time | date:'dd.MM HH:mm'}}</b>&nbsp;<span>{{reg.name}}</span></ion-label>
        <ion-button slot="end" fill="clear" color="danger" (click)="removeReg(reg.index, $event)">
          <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
        </ion-button>
      </ion-item>
      <br/>
      <ion-button expand="block" color="warning" (click)="doAuthAndUpload(true)" *ngIf="hasNetwork && regs.length > 0">{{'UploadOffline' | translate}}</ion-button>
      <br/>
      <ion-button expand="block" color="primary" (click)="doAuthAndUpload(false)" *ngIf="hasNetwork">{{(regs.length > 0 ? 'UploadOnline' : 'GoOnline') | translate}}</ion-button>

      <ion-item lines="none" *ngIf="!hasNetwork">
        <ion-text color="danger"><b>{{'NoNetwork' | translate}}</b></ion-text>
      </ion-item>
    </ion-list>
  </ion-card>
</ion-content>
