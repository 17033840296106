/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { InStorageService } from './in-storage.service';
import { OnlineService } from './online.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ComponentRefService } from './component-ref.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { State, Project, Tenant, NewReg, FormAction, FormType, TriggerType, User, OnlineStatus } from '../models/models';
import { BehaviorSubject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  fields: {[key: string]: FormlyFieldConfig[]} = {};
  openPlugins = 0; // Count of open cordova plugins
  openModalselects: {key: string, type: FormType, id: number}[] = [];
  offlineRegCount = new BehaviorSubject(0);
  private _state: State;
  private _prevState: State;
  private _currentUser: User;
  private _currentProject: Project;
  private _currentTenant: Tenant;
  private _offlineRegs: NewReg[] = [];
  private _lastAction: FormAction;
  private _prevLastAction: FormAction;
  private _lastSubmittedForm: number;
  private _prevLastSubmittedForm: number;
  private _currentForm: {id: number, name: string};
  private _previousForm: {id: number, name: string};
  private _slackBotname = 'TempusIN';
  private _openScanner: {key: string, formType: FormType, formId: number};


  constructor(
    private storage: InStorageService,
    private online: OnlineService,
    private ref: ComponentRefService,
    private logger: NGXLogger
  ) {}

  /**Last action performed in Form Page */
  get lastAction(): FormAction {
    return this._lastAction ?? FormAction.NotSet;
  }

  set lastAction(action: FormAction) {
    this._prevLastAction = this.lastAction;
    this._lastAction = action;
  }

  /**The second last action performed in Form Page */
  get prevLastAction(): FormAction {
    return this._prevLastAction ?? FormAction.NotSet;
  }

  /**Last actions that may trigger an refresh of Home */
  get refreshableAction(): FormAction {
    if (this.prevState !== State.Form) {
      return FormAction.NotSet;
    }
    else if (this.lastAction === FormAction.ChangedFavOrFV || this.prevLastAction === FormAction.ChangedFavOrFV) {
      return FormAction.ChangedFavOrFV;
    }
    else if (this.lastAction === FormAction.Submit) {
      return FormAction.Submit;
    }
    else if (this.lastAction === FormAction.TmpStore) {
      return FormAction.TmpStore;
    }
    else if (this.prevLastAction === FormAction.Submit) {
      return FormAction.Submit;
    }
    else if (this.prevLastAction === FormAction.TmpStore) {
      return FormAction.TmpStore;
    }
    else if (this.lastAction === FormAction.ClearForm || this.prevLastAction === FormAction.ClearForm) {
      return FormAction.ClearForm;
    }
    else {
      return FormAction.Exit;
    }
  }

  /**The id of the last submitted form */
  get lastSubmittedForm(): number {
    return this._lastSubmittedForm ?? -1;
  }

  set lastSubmittedForm(id: number) {
    this._prevLastSubmittedForm = this._lastSubmittedForm;
    this._lastSubmittedForm = id < 0 ? -1 : id;
  }

  /**The id of the second last submitted form */
  get prevLastSubmittedForm(): number {
    return this._prevLastSubmittedForm ?? -1;
  }

  /**The current state of the App */
  get state(): State {
    return this._state ?? State.Other;
  }

  set state(state: State) {
    this._prevState = this.state;
    this._state = state;
  }

  /**The previous state of the App */
  get prevState(): State {
    return this._prevState ?? State.Other;
  }

  /**The currently opened form */
  get currentForm(): {id: number, name: string} {
    return this._currentForm;
  }

  set currentForm(form: {id: number, name: string}) {
    if (this._currentForm) {
      this._previousForm = this._currentForm;
    }
    this._currentForm = form;
  }

  /**The previous opened form */
  get previousForm(): {id: number, name: string} {
    return this._previousForm;
  }

  /**The current user */
  get currentUser(): User {
    return this._currentUser || null;
  }

  set currentUser(user: User) {
    this._currentUser = user;
  }

  /**The current project */
  get currentProject(): Project {
    return this._currentProject || null;
  }

  set currentProject(project: Project) {
    this._currentProject = project;
  }

  /**The current tenant */
  get currentTenant(): Tenant {
    return this._currentTenant || null;
  }

  set currentTenant(tenant: Tenant) {
    this._currentTenant = tenant;
  }

  /**Offline registrations */
  get offlineRegs(): NewReg[] {
    return this._offlineRegs || [];
  }

  set offlineRegs(regs: NewReg[]) {
    this._offlineRegs = regs;
    this.offlineRegCount.next(this._offlineRegs.length);
  }

  /**Headers for slack logging */
  get logHeaders(): HttpHeaders {
    return new HttpHeaders({
      'x-slack-channel': environment.apiErrorSlackChannel,
      'x-slack-botname': this._slackBotname
    });
  }

  get openScanner(): {key: string, formType: FormType, formId: number} {
    return this._openScanner;
  }

  /**
   * Set name of slack bot
   * @param name The name to set
   */
  setSlackBotname(name: string) {
    this._slackBotname = name;
  }

  /**
   * Adds fields belonging to an form, typically called when an form is initalized
   * @param type The form type
   * @param formId The id of the form
   * @param fields The fields to add
   */
  addFields(type: FormType, formId: number, fields: FormlyFieldConfig[]) {
    const key = `${type}:${formId}`;
    this.fields[key] = fields;
  }

  /**
   * Get fields belonging to an form
   * @param type The form type
   * @param formId The id of the form
   */
  getFields(type: FormType, formId: number): FormlyFieldConfig[] {
    const key = `${type}:${formId}`;
    return this.fields[key] ?? [];
  }

  /**
   * Remove fields belonging to an form, typically called when an form is destroyed
   * @param type The form type
   * @param formId The id of the form
   */
  removeFields(type: FormType, formId: number) {
    const key = `${type}:${formId}`;
    if (this.fields[key]) {
      delete this.fields[key];
    }
  }

  /**
   * Find and get an specific field of the forms fields
   * @param key Key of the field
   * @param type The form type
   * @param formId The id of the form
   */
  findField(key: string, type: FormType, formId: number): FormlyFieldConfig {
    for (const f of this.getFields(type, formId)) {
      if (f.key === key)
        return f;
    }
    return null;
  }

  /**
   * Get all offline registrations
   */
  async getOfflineRegs() {
    const regs = await this.storage.getOfflineRegs();
    if (regs.length > 0) {
      this.online.goOffline(OnlineStatus.HasOfflineRegs);
    }
    this.offlineRegs = regs;
  }

  /**
   * Add an offline registration
   * @param reg The offline registration to add
   */
  addOfflineRegistration(reg: NewReg) {
    this._offlineRegs.push(reg);
    this.offlineRegCount.next(this._offlineRegs.length);
  }

  /**
   * Remove an offline registration
   * @param index The index of the offline registration
   */
  removeOfflineRegistration(index: number) {
    if (index < this._offlineRegs.length && index >= 0) {
      this._offlineRegs.splice(index, 1);
      this.offlineRegCount.next(this._offlineRegs.length);
    }
    else {
      this.logger.warn(`Illegal index (${index}) for offline registrations (length: ${this._offlineRegs.length})`);
    }
  }

  /**
   * Register that an modalselect was opened
   * @param key Key of modalselect field
   * @param type Formtype of the field (e.g. Form or FormView)
   */
  modalselectOpened(key: string, id: number, type: FormType) {
    if (this.openModalselects.findIndex(field => field.key === key && field.type === type) === -1) {
      this.openModalselects.push({key, type, id});
    }
  }

  /**
   * Register that an modalselect was closed
   * @param key Key of modalselect field
   * @param type Formtype of the field (e.g. Form or FormView)
   */
  modalselectClosed(key: string, id: number, type: FormType) {
    this.openModalselects = this.openModalselects.filter(field => field.key !== key || field.type !== type || field.id !== id);
  }

  /**
   * Checks if there are open modalselects
   */
  hasOpenModalselect(): boolean {
    return this.openModalselects.length > 0;
  }

  /**
   * Close all open modalselects
   */
  async closeModalselects() {
    for (const field of this.openModalselects) {
      await this.ref.callReference(field.key, field.id,  field.type, TriggerType.CloseModal);
    }
    this.openModalselects = [];
  }

  /**
   * Clear the current state
   */
  clearState() {
    this.currentTenant = null;
    this.currentUser = null;
    this.currentProject = null;
  }

  openBarcodeScanner(key: string, formType: FormType, formId: number) {
    this._openScanner = {key, formType, formId};
    this.openPlugins++;
  }

  closeBarcodeScanner() {
    this._openScanner = null;
    setTimeout(() => {
      this.openPlugins--;
    }, 500);
  }
}
