<ion-card>
  <ion-item lines="none" [mode]="mode">
    <ion-label [position]="labelPosition" class="ion-text-wrap">
      {{ to.label }}
      <span *ngIf="to.required && !hideRequiredMarker">*</span>
    </ion-label>
    <ng-template #fieldComponent></ng-template>
  </ion-item>
  <ion-item lines="none" *ngIf="showError">
    <ion-label>
      <ion-text color="danger">
        <p>
          <formly-validation-message [field]="field"></formly-validation-message>
        </p>
      </ion-text>
    </ion-label>
  </ion-item>
</ion-card>