import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeviceService } from './device.service';
import { FirebaseNotification, FirebaseUserProperties } from '../models/models';
import { Observable, of, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private topics: string[] = [];
  private notifications: Subject<FirebaseNotification>;

  get isWeb(): boolean {
    return !this.device.isDevice();
  }

  constructor(
    private device: DeviceService,
    private logger: NGXLogger
  ) {
    if (this.isWeb) {
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }

  /**
   * ### Analytics
   *
   * Log event
   * @param eventName The name of the event
   * @param data (Optional) Data include with the event
   */
  async logEvent(eventName: string, data?: any) {
    try {
      await FirebaseAnalytics.logEvent({name: eventName, params: data});
    }
    catch (e) {
      this.logger.error('FIREBASE: Failed logging event', e);
    }
  }

  /**
   * ### Analytics
   *
   * Set user properties
   * @param userProps The user properties to set
   */
  async setUserProperties(userProps: FirebaseUserProperties) {
    try {
      for (const [name, value] of Object.entries(userProps)) {
        await FirebaseAnalytics.setUserProperty({name, value});
      }
    }
    catch (e) {
      this.logger.error('FIREBASE: Failed setting user properties', e);
    }
  }

  /**
   * ### Analytics
   *
   * Set the user id
   * @param userId The user id to set
   */
  async setUserId(userId: string) {
    try {
      await FirebaseAnalytics.setUserId({userId});
    }
    catch (e) {
      this.logger.error('FIREBASE: Failed setting user ID', e);
    }
  }

  /**
   * ### Crashlytics/Analytics
   *
   * **On device:** Will log non-fatal error to Crashlytics
   *
   * **On web:** Will log event called `'uncaught_error'` with data to Analytics
   * @param message The error message
   * @param stack Stack of error
   * @param data (Optional) Data for web
   */
  async logError(message: string, stack: any, data?: any) {
    if (this.isWeb) {
      if (!data) {
        data = {message};
      }
      else if (!data.message) {
        data.message = message;
      }
      await this.logEvent('uncaught_error', data);
    }
    else {
      try {
        await FirebaseCrashlytics.recordException({message, stacktrace: stack});
      }
      catch (e) {
        this.logger.error('FIREBASE: Failed logging error', e);
      }
    }
  }

  /**
   * ### Crashlytics
   *
   * Simulates crash for testing
   *
   * **NOTE:** This is only on device
   */
  async sendCrash() {
    if (!environment.production && !this.isWeb) {
      await FirebaseCrashlytics.crash({message: 'Test'});
    }
  }

  async initNotifications(): Promise<boolean> {
    try {
      let status = await PushNotifications.checkPermissions();
      if (status.receive === 'prompt') {
        status = await PushNotifications.requestPermissions();
      }
      if (status.receive !== 'granted') {
        return false;
      }

      this.notifications = new Subject<FirebaseNotification>();
      PushNotifications.addListener('registration', (data) => this.logger.debug(data));
      PushNotifications.addListener('pushNotificationReceived', (data: PushNotificationSchema) => {
        const notification: FirebaseNotification = {
          messageType: data.data ? 'data' : 'notification',
          title: data.title,
          body: data.body,
          notificationType: data.data?.notificationType,
          markdown: data.data?.markdown,
          formId: data.data?.formId,
          formName: data.data?.formName,
          projectNr: data.data?.projectNr,
          appVersion: data.data?.appVersion,
        };
        this.notifications.next(notification);
      });
      return true;
    }
    catch {
      return false;
    }
  }

  /**
   * ### Notifications
   *
   * Get token for notifications
   *
   * **NOTE:** This is only on device. Web returns `null`.
   */
  async getToken(): Promise<string> {
    if (this.isWeb) {
      return null;
    }
    else {
      try {
        const isAutoInit = await FCM.isAutoInitEnabled();
        if (!isAutoInit?.enabled) {
          await FCM.setAutoInit({enabled: true});
        }
        const data = await FCM.getToken();
        return data?.token;
      }
      catch (e) {
        this.logger.error('FIREBASE: Failed getting token', e);
        return null;
      }
    }
  }

  /**
   * ### Notifications
   *
   * Subscribe to an topic
   *
   * **NOTE:** This is only on device. Web does nothing
   * @param topic The topic to subscribe to
   */
  async subscribeToTopic(topic: string): Promise<boolean> {
    if (!topic || this.isWeb) {
      return false;
    }
    else {
      try {
        await FCM.subscribeTo({topic});
        this.topics.push(topic);
        return true;
      }
      catch (err) {
        this.logger.error(`FIREBASE: Error subscribing to topic: ${topic}`, err);
        return false;
      }
    }
  }

  /**
   * ### Notifications
   *
   * Unsbuscribe from an topic
   *
   * **NOTE:** This is only on device. Web does nothing
   * @param topic The topic to unsubscribe from
   */
  async unsubscribeFromTopic(topic: string): Promise<boolean> {
    if (!topic || this.isWeb) {
      return false;
    }
    else {
      try {
        await FCM.unsubscribeFrom({topic});
        this.topics = this.topics.filter(t => t !== topic);
        return true;
      }
      catch (err) {
        this.logger.error(`FIREBASE: Error unsubscribing from topic: ${topic}`, err);
        return false;
      }
    }
  }

  /**
   * ### Notifications
   *
   * Get observable with incoming notifications
   *
   * **NOTE:** This is only on device. Web returns `null`.
   */
  onMessageReceived(): Observable<FirebaseNotification> {
    if (this.isWeb) {
      return of(null);
    }
    else {
      return this.notifications;
    }
  }

  /**
   * ### Notifications
   *
   * Unregister/delete token from Firebase
   *
   * **NOTE:** This is only on device
   */
  async unregister() {
    if (!this.isWeb) {
      try {
        for (const topic of this.topics) {
          await this.unsubscribeFromTopic(topic);
        }
        await FCM.setAutoInit({enabled: false});
        await FCM.deleteInstance();
      }
      catch (e) {
        this.logger.error('FIREBASE: Failed unregistering', e);
      }
    }
  }
}
