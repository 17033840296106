import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ApiStatus, FormType, Trigger, TriggerType } from 'src/app/models/models';
import { ApiService } from 'src/app/services/api.service';
import { ComponentRefService } from 'src/app/services/component-ref.service';
import { FieldService } from 'src/app/services/field.service';
import { PopupService } from 'src/app/services/popup.service';
import { StateService } from 'src/app/services/state.service';
import { UtilityService } from 'src/app/services/utility.service';
import { WatchgroupService } from 'src/app/services/watchgroup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  providers: [WatchgroupService, FieldService]
})
export class StatusComponent extends FieldType implements OnInit, Trigger, OnDestroy {


  obsStatus: Subscription;
  isLoading = false;
  lookupDelay = 0;

  url: string;
  jsonPathError = '0';
  jsonPathSuccess = '0';
  loadingLabel = '';

  loadingTakesTime = false;

  watchData = null;

  maxTries = 5;
  statusTries = 0;
  errorLabel = '';
  hideError: boolean;

  timeoutId = null;

  constructor(
    private fieldService: FieldService,
    private api: ApiService,
    private watch: WatchgroupService,
    private logger: NGXLogger,
    private util: UtilityService,
    private translate: TranslateService,
    private state: StateService,
    private ref: ComponentRefService,
    private popup: PopupService
  ) {
    super();
    this.fieldService.setField(this);
  }

  get showLoading() {
    return this.isLoading && this.loadingLabel && this.loadingTakesTime;
  }

  get hasError() {
    return this.errorLabel !== '';
  }

  get formId(): number {
    return this.to.currentForm?.id ?? 0;
  }

  get formType(): FormType {
    return this.to.currentForm?.type;
  }

  get fields(): FormlyFieldConfig[] {
    return this.state.getFields(this.formType, this.formId);
  }

  ngOnInit() {
    this.setReference(this.key as string, this.formId, this.formType);
    this.lookupDelay = this.to.lookupDelay || 0;
    this.url = this.to.url || '';
    this.loadingLabel = this.to.loadingLabel || '';
    this.jsonPathError = this.to.jsonPathError || '0.Feil';
    this.jsonPathSuccess = this.to.jsonPathSuccess || '0.OK';
    this.maxTries = this.to.maxTries || 5;
    this.hideError = this.to.hideError ?? false;

    this.buildWatch();
  }

  ngOnDestroy(): void {
    if (this.obsStatus)
      this.obsStatus.unsubscribe();
    if(this.timeoutId)
      clearTimeout(this.timeoutId);
  }

  buildWatch() {
    //watchData will forward the data it looked at to the field watching this field.

    const watchGroup = this.watch.getWatchGroupFromOptions(this.to);

    if (watchGroup.fieldKeys.length > 0) {
      this.watch.watchGroup(this.form, this.model, watchGroup).subscribe((v) => {
        if (v === null) return;
        this.triggerStatus(v.newValue);
      });
    }
  }

  resetAndRetry() {
    this.statusTries = 0;
    this.errorLabel = '';
    this.getStatus();
  }

  setError(message: string, useTranslate = false) {
    this.logger.error(message);
    if(!this.hideError)
      this.errorLabel = (useTranslate) ? this.translate.instant(message) : message;
    //this.popup.showAlert('Status', 'StatusTooManyAttempts', true);
  }

  triggerStatus(data) {
    if (data) {
      this.watchData = data;
      if (this.to?.triggerLoadingOnKey)
        this.ref.callReference(this.to.triggerLoadingOnKey, this.formId, this.formType, TriggerType.ShowLoading, true);

      setTimeout(() => {
        this.getStatus();
      }, this.lookupDelay);
    }
  }

  getStatus() {

    if (this.obsStatus)
      this.obsStatus.unsubscribe();

    this.setLoading(true);
    const url = this.util.parseText(this.url, this.model, this.fields);
    const obs = this.api.getWebJSON(url, this.formId, this.to.headers, this.to.useCredentials, false);
    this.obsStatus = obs.subscribe(({ value, status }) => {

      //console.log(value, status);
      if (status === ApiStatus.Success) {

        const err = this.util.dotRef(value, this.jsonPathError);
        //console.log(err);
        if (err) {
          this.setError(err);
          this.setLoading(false);
        }
        else {
          const val = this.util.dotRef(value, this.jsonPathSuccess);
          //console.log(val);
          if (val) {
            this.statusTries = 0;
            this.errorLabel = '';
            const data = this.watchData;
//            console.log(data);
            this.fieldService.setValue(data);
            this.setLoading(false);
          }
          else if (this.maxTries > this.statusTries) {
            this.logger.debug('retry', this.statusTries);

            this.statusTries++;
            this.getStatus(); //retry
          }
          else {
            this.setError('StatusTooManyAttempts', true);
          }
        }

      }
      else if (status === ApiStatus.Offline) {
        this.setLoading(false);
        //this.setError('StatusTooManyAttempts', true);
      }
      else {
        this.setLoading(false);
        this.setError('StatusTooManyAttempts', true);
      }

    });

  }

  setReference(key: string, id: number, type: FormType) {
    this.ref.addReference(key, id, type, this);
  }

  async externalTrigger(type: TriggerType, data?: any) {
    if (type === TriggerType.Update) {
      const d = data || new Date(); // If watchData is empty (when triggerAPI is used with no data), a new Date() is added to trigger Watch (newValue).
      this.triggerStatus(d);
    }
    else if (!environment.production) {
      this.logger.warn(`Wrong trigger type: ${type}`);
    }
  }

  setLoading(isOn: boolean) {
    this.isLoading = isOn;

    if (!isOn) {
      if (this.timeoutId)
        clearTimeout(this.timeoutId);
      this.loadingTakesTime = false;
    }
    else { // if less then 1 sec, no point of showing loading text.
      this.timeoutId = setTimeout(() => {
        this.loadingTakesTime = true;
      }, 5000);
    }
  }

}
