<ion-header>
  <ion-toolbar>
      <ion-button fill="clear" color="dark" slot="end" (click)="closeModal()">
        <ion-icon name="close-circle-outline" slot="icon-only"></ion-icon>
      </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="front-background">
  <ion-grid>
    <ion-row>
      <ng-container *ngFor="let imgUrl of imageUrls">
        <ng-container *ngTemplateOutlet="col; context: imgUrl"></ng-container>
      </ng-container>
    </ion-row>
  </ion-grid>
</ion-content>

<ng-template #col let-url="url">
  <ion-col size="12" [sizeSm]="sizeSm">
    <swiper [config]="sliderOpts">
      <ng-template swiperSlide >
        <div class="swiper-zoom-container">
          <img [src]="url" [alt]="errorMsg || ''"/>
        </div>
      </ng-template >
    </swiper>
  </ion-col>
</ng-template>
