import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ValidationService } from 'src/app/services/validation.service';
import { LegacyService } from 'src/app/services/legacy.service';

interface SegmentOption {
  value: any;
  label?: string;
  icon?: string;
}

@Component({
  selector: 'app-segment-buttons',
  templateUrl: './segment-buttons.component.html',
  styleUrls: ['./segment-buttons.component.scss'],
})
export class SegmentButtonsComponent extends FieldType implements OnInit {
  color: string;
  segmentOptions: SegmentOption[] = [];

  constructor(
    private validation: ValidationService,
    private legacy: LegacyService
  ) {
    super();
  }

  get label(): string {
    return this.to.label ?? '';
  }

  get scrollable(): boolean {
    return this.to.scrollable ?? true;
  }

  get mode(): string {
    switch (this.to.mode) {
      case 'android':
      case 'md':
        return 'md';
      case '':
        return '';
      default:
        return 'ios';
    }
  }

  public static getHtml(config: FormlyFieldConfig, value: any) {
    const label = config.templateOptions.label ?? '';
    const options: SegmentOption[] = config.templateOptions.options as any[] ?? [];
    let mode = config.templateOptions.mode;
    mode = (mode === 'android' || mode === 'md') ? 'md'
                                   : mode === '' ? ''
                                                 : 'ios';
    let html = '<ion-card style="padding: 10px;"><ion-list>';
    if (label) {
      html += `<ion-label>${label}</ion-label>`;
    }
    html += value ? `<ion-segment mode="${mode}" value="${value}">` : `<ion-segment mode="${mode}">`;
    for (const option of options) {
      html += `<ion-segment-button value="${option.value}">`;
      if (option.icon) {
        html += `<ion-icon name="${option.icon}"></ion-icon>`;
      }
      if (option.label) {
        html += `<ion-label>${option.label}</ion-label>`;
      }
      html += '</ion-segment-button>';
    }
    html += '</ion-segment></ion-list></ion-card>';
    return html;
  }

  ngOnInit() {
    this.color = this.validation.validColor(this.to.colorType) ? this.to.colorType : '';
    if (Array.isArray(this.to.options)) {
      for (const option of this.to.options as SegmentOption[]) {
        if (option.icon) {
          option.icon = this.legacy.fixIcon(option.icon, false);
        }
      }
      this.segmentOptions = this.to.options;
    }
  }

}
