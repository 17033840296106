
/**
 * Types of triggers for externalTrigger in components implementing Trigger interface
 */
export enum TriggerType {
    Click = 'click',
    Edit = 'edit',
    Swipe = 'swipe',
    Focus = 'focus',
    Set = 'set',
    GPS = 'gps',
    AllowLeave = 'allowLeave',
    Update = 'update',
    ToggleClass = 'toggleClass',
    RemoveClass = 'removeClass',
    AddClass = 'addClass',
    Menu = 'menu',
    CloseModal = 'closeModal',
    Clear = 'clear',
    TogglePartial = 'togglePartial',
    GetOptions = 'getOptions',
    Rebuild = 'rebuild',
    RefreshPage = 'refreshPage',
    StopUpdate = 'stopUpdate',
    GoBack = 'goBack',
    ChangedHome = 'changedHome',
    ShowLoading = 'showLoading',
    StopScan = 'stopScan'
}
