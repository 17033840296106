import { Component, OnInit, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormType } from 'src/app/models/models';
import { ThemeService } from 'src/app/services/theme.service';

enum StyleTypes {
  Background = 'background',
  FontColor = 'font-color',
  FontSize = 'font-size',
  CustomLight = 'custom-css-light',
  CustomDark = 'custom-css-dark'
}

@Component({
  selector: 'app-field-style-form',
  templateUrl: './style-form.component.html',
  styleUrls: ['./style-form.component.scss'],
})
export class StyleFormComponent extends FieldType implements OnInit, OnDestroy {
  addedStyleTypes: StyleTypes[] = [];

  constructor(private theme: ThemeService) {
    super();
  }

  get background(): string {
    let bg: string;
    if (this.theme.isDarkmode) {
      bg = this.to.dark?.background;
    }
    if (!bg) {
      bg = this.to.background;
    }
    if (bg) {
      return bg.includes('!important') ? bg : `${bg} !important`;
    }
    else {
      return null;
    }
  }

  get fontColor(): string {
    let color: string;
    if (this.theme.isDarkmode) {
      color = this.to.dark?.fontColor;
    }
    if (!color) {
      color = this.to.fontColor;
    }
    if (color) {
      return color.includes('!important') ? color : `${color} !important`;
    }
    else {
      return null;
    }
  }

  get fontSize(): string {
    let size: string | number;
    if (this.theme.isDarkmode) {
      size = this.to.dark?.fontSize;
    }
    if (!size) {
      size = this.to.fontSize;
    }
    if (typeof size === 'number') {
      return `${size}pt !important`;
    }
    else if (size) {
      return size.includes('!important') ? size : `${size} !important`;
    }
    else {
      return null;
    }
  }

  get formType(): FormType {
    return this.to.currentForm.type ?? FormType.Form;
  }

  get cssBackgroundProperty(): string {
    switch (this.formType) {
      case FormType.Form:
      case FormType.Registration:
        return '--main-bg';
      case FormType.FormView:
        return 'background-color';
      default:
        return null;
    }
  }

  get contentSelector(): string {
    switch (this.formType) {
      case FormType.Form:
        return 'app-form ion-content.in-background';
      case FormType.Registration:
        return 'app-registration ion-content.in-background';
      case FormType.FormView:
        return 'app-form-view div.container';
      case FormType.SubForm:
        return 'app-sub-form ion-content#formWrapper';
      default:
        return null;
    }
  }

  get customCss(): string {
    if (typeof this.to.custom === 'object') {
      return this.to.custom.style;
    }
    else {
      return this.to.custom;
    }
  }

  get modifyCustom(): boolean {
    if (typeof this.to.custom === 'object') {
      return this.to.custom.modify ?? true;
    }
    else {
      return true;
    }
  }

  get customDarkCss(): string {
    if (typeof this.to.dark?.custom === 'object') {
      return this.to.dark.custom.style;
    }
    else {
      return this.to.dark?.custom;
    }
  }

  get modifyCustomDark(): boolean {
    if (typeof this.to.dark?.custom === 'object') {
      return this.to.dark.custom.modify ?? true;
    }
    else {
      return true;
    }
  }

  ngOnInit() {
    if (this.field.hide) {
      return;
    }
    if (this.background && this.contentSelector && this.cssBackgroundProperty) {
      const style = document.createElement('style');
      style.id = this.getStyleId(StyleTypes.Background);
      style.innerHTML = `
        ${this.contentSelector} {
          ${this.cssBackgroundProperty}: ${this.background};
        }`;
      document.getElementsByTagName('head')[0].appendChild(style);
      this.addStyleType(StyleTypes.Background);
    }
    if (this.fontColor && this.contentSelector) {
      const style = document.createElement('style');
      style.id = this.getStyleId(StyleTypes.FontColor);
      style.innerHTML = `
        ${this.contentSelector} {
          --ion-text-color: ${this.fontColor};
        }`;
      document.getElementsByTagName('head')[0].appendChild(style);
      this.addStyleType(StyleTypes.FontColor);
    }
    if (this.fontSize) {
      const style = document.createElement('style');
      style.id = this.getStyleId(StyleTypes.FontSize);
      style.innerHTML = `
        ${this.contentSelector} ion-label,
        ${this.contentSelector} input,
        ${this.contentSelector} textarea,
        ${this.contentSelector} ion-button,
        ${this.contentSelector} markdown,
        ${this.contentSelector} span {
          font-size: ${this.fontSize};
        }`;
      document.getElementsByTagName('head')[0].appendChild(style);
      this.addStyleType(StyleTypes.FontSize);
    }
    if (this.customCss) {
      this.addCustomCss(StyleTypes.CustomLight, this.customCss, this.modifyCustom);
    }

    if (this.customDarkCss) {
      this.addCustomCss(StyleTypes.CustomDark, this.customDarkCss, this.modifyCustomDark);
    }
  }

  ngOnDestroy() {
    for (const type of this.addedStyleTypes) {
      const elm = document.getElementById(this.getStyleId(type));
      if (elm) {
        elm.parentNode.removeChild(elm);
      }
    }
  }

  private addCustomCss(type: StyleTypes.CustomDark | StyleTypes.CustomLight, css: string, modify: boolean) {
    const style = document.createElement('style');
    style.id = this.getStyleId(type);
    style.innerHTML = css.replace(/(.*){/g, (s: string, m1: string) => {
      const parts = m1.trim().split(',');
      for (let i = 0; i < parts.length; i++) {
        if (type === StyleTypes.CustomDark && modify) {
          parts[i] = `.dark ${this.contentSelector} ${parts[i].trim()}`;
        }
        else if (type === StyleTypes.CustomDark && !modify) {
          parts[i] = `.dark ${parts[i].trim()}`;
        }
        else if (type === StyleTypes.CustomLight && modify) {
          parts[i] = `${this.contentSelector} ${parts[i].trim()}`;
        }
        else {
          parts[i] = parts[i].trim();
        }
      }
      return `${parts.join(', ')} {`;
    });

    document.getElementsByTagName('head')[0].appendChild(style);
    this.addStyleType(type);
  }

  private addStyleType(type: StyleTypes) {
    this.addedStyleTypes.push(type);
  }

  private getStyleId(type: StyleTypes) {
    return  `${type}-${this.key}-${this.formType}`;
  }
}
