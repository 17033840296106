import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController, AlertController, ModalController, PopoverController, MenuController, IonicSafeString, AlertOptions } from '@ionic/angular';
import { environment } from 'src/environments/environment';

/**
 * Service for showing popups with information to user
 */
@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    private translate: TranslateService,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private popCtrl: PopoverController,
    private menuCtrl: MenuController
  ) { }

  /**
   * Shows an message to the user
   * @param text The text for what should be in the message
   * @param translate If the text should be translated
   * @param colorClass (Optional) The ionic color class for background (default: 'danger')
   * @param duration (Optional) How long the message should be displayed in ms (default: 1500)
   * @param position (Optional) The screen position of the message (`'top' | 'middle' | 'bottom'`), default: `'top'`
   * @param cssClass (Optional) Css class(es) to put on the underlying ion-toast.
   */
  async showMessage(text: string, translate: boolean, colorClass: string = 'danger', duration: number = 1500, position: 'top' | 'middle' | 'bottom' = 'top', cssClass?: string | string[]) {
    if (translate) {
      text = await this.translate.instant(text);
    }
    let css = ['ion-text-center'];
    if (cssClass) {
      css = css.concat(cssClass);
    }
    const alert = await this.toastCtrl.create({
      message: text,
      duration: duration,
      position: position,
      color: colorClass,
      cssClass: css
    });
    await alert.present();
    await alert.onDidDismiss();
  }

  /**
   * Shows an alert to the user
   * @param header Header of the alert
   * @param message Message of the alert
   * @param translate If header/message should be translated
   * @param bypassSecurity (Optional) If it should bypass sanitizing etc., default: `false`
   * @param cssClass (Optional) Css class(es) to put on the underlying ion-alert.
   */
  async showAlert(header: string, message: string, translate: boolean, bypassSecurity = false, cssClass?: string | string[]) {
    if (translate) {
      const trans = this.translate.instant([header, message]);
      header = trans[header];
      message = trans[message];
    }
    const confirm = this.translate.instant('Confirm');
    const options: AlertOptions = {
      header: header,
      message: bypassSecurity ? new IonicSafeString(message) : message,
      buttons: [confirm]
    };
    if (cssClass) {
      options.cssClass = cssClass;
    }
    const alert = await this.alertCtrl.create(options);
    await alert.present();
    await alert.onDidDismiss();
  }

  /**
   * Shows an confirm popup to the user and return true/false if the user confirmed or not
   * @param header Header for the confirm popup
   * @param message Message of the confirm popup
   * @param translate If header/message/cancelText/okText should be translated
   * @param cancel (Optional) Cancel text. Default: 'Cancel' (translated)
   * @param confirm (Optional) Confirm text. Default: 'Confirm' (translated)
   * @param cssClass (Optional) Css class(es) to put on the underlying ion-alert.
   * @param (Optional) If it should be allowed to dismiss/cancel by clicking on backdrop, default `true`
   */
  async showConfirm(header: string, message: string, translate: boolean, cancel?: string, confirm?: string, cssClass?: string | string[], allowBackdropDismiss = true): Promise<boolean> {
    let confirmed = false;
    if (translate) {
      const trans = this.translate.instant([header, message]);
      header = trans[header];
      message = trans[message];
      if (cancel) {
        cancel = this.translate.instant(cancel);
      }
      if (confirm) {
        confirm = this.translate.instant(confirm);
      }
    }
    if (!cancel) {
      cancel = this.translate.instant('Cancel');
    }
    if (!confirm) {
      confirm = this.translate.instant('Confirm');
    }
    const options: AlertOptions = {
      header: header,
      message: message,
      backdropDismiss: allowBackdropDismiss,
      buttons: [
        {
          text: cancel,
          role: 'cancel'
        },
        {
          text: confirm,
          handler: () => {
            confirmed = true;
          }
        }
      ]
    };
    if (cssClass) {
      options.cssClass = cssClass;
    }
    const alert = await this.alertCtrl.create(options);
    await alert.present();
    await alert.onDidDismiss();
    return confirmed;
  }

  /**
   * Close open alerts, modals, popovers and menu
   */
  async closeOpenOverlays(closeModals = true) {
    if (closeModals) {
      await Promise.all([
        this.closeAlerts(),
        this.closeModals(),
        this.closePopovers(),
        this.closeMenu()
      ]);
    }
    else {
      await Promise.all([
        this.closeAlerts(),
        this.closePopovers(),
        this.closeMenu()
      ]);
    }
  }

  /**
   * Close open menu
   */
   async closeMenu() {
    const isOpen = await this.menuCtrl.isOpen();
    if (isOpen) {
      await this.menuCtrl.close();
    }
  }

  /**
   * Close open alerts
   */
  private async closeAlerts() {
    let count = 0;
    let alert = await this.alertCtrl.getTop();
    while (alert && count < environment.maxOverlayClose) {
      await alert.dismiss();
      alert = await this.alertCtrl.getTop();
      count++;
    }
  }

  /**
   * Close open modals
   */
  private async closeModals() {
    let count = 0;
    let modal = await this.modalCtrl.getTop();
    while (modal && count < environment.maxOverlayClose) {
      await modal.dismiss();
      modal = await this.modalCtrl.getTop();
      count++;
    }
  }

  /**
   * Close open popovers
   */
  private async closePopovers() {
    let count = 0;
    let popover = await this.popCtrl.getTop();
    while (popover && count < environment.maxOverlayClose) {
      await popover.dismiss();
      popover = await this.popCtrl.getTop();
      count++;
    }
  }
}
