import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { DeviceService } from 'src/app/services/device.service';
import { BrowserService } from 'src/app/services/browser.service';
import { NgxExtendedPdfViewerComponent } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss'],
})
export class PdfModalComponent implements OnInit {
  @ViewChild('viewer') viewer!: NgxExtendedPdfViewerComponent;

  @Input() url: string;
  @Input() label = '';
  trustedUrl: SafeUrl;
  hasError = false;

  get isMobile(): boolean {
    return this.device.isMobile();
  }

  constructor(
    private modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    private device: DeviceService,
    private logger: NGXLogger,
    private browser: BrowserService
  ) { }

  ngOnInit() {
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  closeModal() {
    this.modalCtrl.dismiss().then(
      () => this.logger.debug('Successfully dismissed pdf modal'),
      (err) => this.logger.warn('Failed dismissing pdf modal', err)
    );
  }

  pdfError(error: Error) {
    this.logger.error('Error opening PDF', error);
    this.hasError = true;
  }

  openPdfInBrowser(url: string) {
    this.browser.openUrl(url);
  }

  checkSize() {
    this.viewer?.onResize();
  }
}
