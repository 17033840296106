import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-field-admin-verify',
  templateUrl: './admin-verify.component.html',
  styleUrls: ['./admin-verify.component.scss']
})
export class AdminVerifyComponent extends FieldType implements OnInit {
  ngOnInit() {
    if (!this.field.defaultValue || this.field.defaultValue !== 1) {
      this.formControl.setValue(0);
    }
    else {
      this.formControl.setValue(1);
    }
  }

}
